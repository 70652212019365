(<template>
  <div class="fai-admin-actions">
    <template v-if="discussionStatus === 'invited'">
      <button :title="inviteTitle"
              class="fai-admin-actions__btn fai-admin-actions__btn--invite"
              @click="$emit('invited')">
        <span class="fai-admin-actions__btn-content">{{ invitationsCount }}</span>
      </button>
      <button :title="applyTitle"
              class="fai-admin-actions__btn fai-admin-actions__btn--apply"
              @click="$emit('applied')"></button>
      <button :title="declineTitle"
              class="fai-admin-actions__btn fai-admin-actions__btn--decline"
              @click="$emit('declined')"></button>
      <button :title="blockTitle"
              class="fai-admin-actions__btn fai-admin-actions__btn--block"
              @click="$emit('blocked')"></button>
    </template>

    <button v-if="discussionStatus === 'applied'"
            class="sk-link fai-admin-actions__link fai-admin-actions__link--inactive">
      {{ $gettext('Applied') }}
    </button>

    <template v-if="discussionStatus === 'opened'">
      <div class="fai-admin-actions__info-wrapper">
        <template v-if="showInvitationQueue">
          <div class="fai-admin-actions__arrows-wrapper">
            <button :title="increaseRatingTitle"
                    class="fai-admin-actions__arrow fai-admin-actions__arrow--up"
                    @click="changeInterpreterRating('increase')"></button>
            <button :title="decreaseRatingTitle"
                    class="fai-admin-actions__arrow fai-admin-actions__arrow--down"
                    @click="changeInterpreterRating('decrease')"></button>
          </div>
        </template>
        <div class="fai-admin-actions__invite-info">
          <p class="fai-admin-actions__text">{{ $gettext('Next auto-invite') }}</p>
          <button class="sk-link fai-admin-actions__link"
                  @click="$emit('invited')">{{ $gettext('Invite now') }}</button>
        </div>
      </div>
      <button :title="blockTitle"
              class="fai-admin-actions__btn fai-admin-actions__btn--block"
              @click="$emit('blocked')"></button>
    </template>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';
  import SuitableSupplierPrototype from '@/prototypes/SuitableSupplierPrototype';

  export default {
    extends: SuitableSupplierPrototype,
    props: {
      interpreter: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('FAIFiltersStore', {
        showInvitationQueue: ({showInvitationQueue}) => showInvitationQueue
      }),
      applyTitle() { return this.$gettext('Confirm/Apply'); },
      declineTitle() { return this.$gettext('Decline'); },
      blockTitle() { return this.$gettext('Block this interpreter'); },
      inviteTitle() {
        return this.$gettextInterpolate(this.$gettext('Invited (%{num})'), {num: this.invitationsCount});
      },
      increaseRatingTitle() { return this.$gettext('Increase the interpreter queue position'); },
      decreaseRatingTitle() { return this.$gettext('Decrease the interpreter queue position'); }
    },
    methods: {
      changeInterpreterRating(action) {
        this.$emit('changerating', action);
      }
    }
  };
</script>

<style scoped>
.fai-admin-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 36px;
}

.fai-admin-actions__btn {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  margin-right: 5px;
  border: 1px solid #646d8c;
  border-radius: 3px;
  background-position: center center;
  background-size: 22px;
  background-repeat: no-repeat;
}

.fai-admin-actions__btn:hover {
  opacity: 0.7;
}

.fai-admin-actions__btn--status {
  display: flex;
  align-items: center;
  border-color: transparent;
}

.fai-admin-actions__btn--status:hover {
  opacity: 1;
}

.fai-admin-actions__btn--invite {
  width: auto;
  min-width: 36px;
  padding-right: 5px;
  padding-left: 30px;
  border: 1px solid #ff5b24;
  background-image: url(~@assets/imgs/undefined_imgs/sms_icon_orange.svg);
  background-position: 5px center;
  color: #ff5b24;
  font-size: 12px;
  text-align: right;
}

.fai-admin-actions__btn--apply {
  border: 1px solid #ff5b24;
  background-color: #ff5b24;
  background-image: url(~@assets/imgs/undefined_imgs/check_icon.svg);
  background-size: 40px;
}

.fai-admin-actions__btn--decline {
  background-color: #fff;
  background-image: url(~@assets/imgs/undefined_imgs/close_x_icon_blue.svg);
}

.fai-admin-actions__btn--block {
  border-color: #ff255f;
  background-image: url(~@assets/imgs/undefined_imgs/block_inter_icon_active_mob.svg);
  background-size: 30px;
}

.fai-admin-actions__info-wrapper {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}

.fai-admin-actions__text {
  width: 70px;
}

.fai-admin-actions__link {
  font-weight: normal;
  text-decoration: none;
}

.fai-admin-actions__link--inactive {
  color: #008489;
  pointer-events: none;
}

.fai-admin-actions__arrows-wrapper {
  position: relative;
  width: 20px;
  height: 100%;
}

.fai-admin-actions__arrow {
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  background-image: url(~@assets/imgs/arrows/wide_arrow_to_left.svg);
  background-position: 0 50%;
  background-size: 8px;
  background-repeat: no-repeat;
}

.fai-admin-actions__arrow--up {
  top: 0;
  transform: rotate(90deg);
}

.fai-admin-actions__arrow--down {
  bottom: 0;
  transform: rotate(-90deg);
}
</style>
