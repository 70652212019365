export default () => {
  return {
    namespaced: true,
    state: {
      qualification: 'suitable',
      gender: 'suitable',
      name: '',
      distance: 30,
      showAll: false,
      showInvitationQueue: false,
      showSuitableNotInvited: false
    },
    mutations: {
      setQualificationFilter(state, value) { state.qualification = value; },
      setGenderFilter(state, value) { state.gender = value; },
      setNameFilter(state, value) { state.name = value; },
      setDistanceFilter(state, value) { state.distance = value; },
      setShowAllFilter(state, value) { state.showAll = value; },
      setShowInvitationQueueFilter(state, value) { state.showInvitationQueue = value; },
      setShowSuitableNotInvitedFilter(state, value) { state.showSuitableNotInvited = value; },
      clearStore(state) {
        state.qualification = 'suitable';
        state.gender = 'suitable';
        state.distance = 30;
        state.name = '';
        state.showAll = false;
        state.showInvitationQueue = false;
        state.showSuitableNotInvited = false;
      }
    }
  };
};
