export default [
  {
    path: '/:lang/:country/people/sign_in',
    name: 'OldSignIn',
    component: () => import('@/pages/shared_pages/redirect_pages/sh_RedirectRoot')
  },
  {
    path: '/:lang/:country/people/sign_up',
    name: 'OldSignUp',
    component: () => import('@/pages/shared_pages/redirect_pages/sh_RedirectRoot')
  },
  {
    path: '/:lang/:country/people/password/edit',
    name: 'OldChangePassword',
    component: () => import('@/pages/shared_pages/redirect_pages/sh_RedirectRoot')
  },
  {
    path: '/:lang/:country/people/password/new',
    name: 'OldForgotPassword',
    component: () => import('@/pages/shared_pages/redirect_pages/sh_RedirectRoot')
  },
  {
    path: '/:lang/:country/people/:id/calendar',
    name: 'OldCalendar',
    component: () => import('@/pages/shared_pages/redirect_pages/sh_RedirectRoot')
  },
  {
    path: '/:lang/:country/people/id/transaction_history',
    name: 'OldTransactionsHistory',
    component: () => import('@/pages/shared_pages/redirect_pages/sh_RedirectRoot')
  },
  {
    path: '/:lang/:country/people/:id/payout_methods',
    name: 'OldPayoutMethods',
    component: () => import('@/pages/shared_pages/redirect_pages/sh_RedirectRoot')
  },
  {
    path: '/:lang/:country/people/:id/payment_methods',
    name: 'OldPaymentMethods',
    component: () => import('@/pages/shared_pages/redirect_pages/sh_RedirectRoot')
  },
  {
    path: '/:lang/:country/jobs',
    name: 'OldAllJobs',
    component: () => import('@/pages/shared_pages/redirect_pages/sh_RedirectRoot')
  },
  {
    path: '/:lang/:country/jobs/new',
    name: 'OldCreateJob',
    component: () => import('@/pages/shared_pages/redirect_pages/sh_RedirectRoot')
  },
  {
    path: '/:lang/:country/jobs/:id',
    name: 'OldJobShow',
    component: () => import('@/pages/shared_pages/redirect_pages/sh_RedirectRoot')
  },
  {
    path: '/:lang/:country/interpreters',
    name: 'OldFAI',
    component: () => import('@/pages/shared_pages/redirect_pages/sh_RedirectRoot')
  },
  {
    path: '/:lang/:country/enterprises/:id/employees',
    name: 'OldManageEmployees',
    component: () => import('@/pages/shared_pages/redirect_pages/sh_RedirectRoot')
  },
  {
    path: '/:lang/:country/enterprises/new',
    name: 'OldCreateEnterprise',
    component: () => import('@/pages/shared_pages/redirect_pages/sh_RedirectRoot')
  },
  {
    path: '/:lang/:country/enterprises/:id',
    name: 'OldCompanyInfo',
    component: () => import('@/pages/shared_pages/redirect_pages/sh_RedirectRoot')
  }
];
