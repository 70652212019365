import { render, staticRenderFns } from "./PostingTogglePrototype.vue?vue&type=template&id=5972f582&scoped=true&"
import script from "./PostingTogglePrototype.vue?vue&type=script&lang=js&"
export * from "./PostingTogglePrototype.vue?vue&type=script&lang=js&"
import style0 from "@assets/css/toggle_section.css?vue&type=style&index=0&id=5972f582&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5972f582",
  null
  
)

export default component.exports