<script>
  import {mapState} from 'vuex';
  import helpers from '@/helpers';
  import AvailabilityTimeline from '@/components/shared_components/availability/AvailabilityTimeline';

  export default {
    components: {
      'availability-timeline': AvailabilityTimeline
    },
    computed: {
      ...mapState('PostingStore/AssignmentTypeStore', {
        assignmentType: ({assignmentType}) => assignmentType
      }),
      ...mapState('PostingStore/DateTimeStore', {
        startTime: ({startTime}) => startTime,
        finishTime: ({finishTime}) => finishTime
      }),
      assignmentStartTime() { return this.startTime ? this.$moment(this.startTime).format('YYYY-MM-DD HH:mm') : ''; },
      assignmentFinishTime() { return this.finishTime ? this.$moment(this.finishTime).format('YYYY-MM-DD HH:mm') : ''; },
      selectedEvent() {
        const startTime = this.assignmentStartTime;
        const finishTime = this.assignmentFinishTime;
        const sessionType = this.assignmentType;
        if (startTime && finishTime && sessionType) {
          return {
            startTime,
            finishTime,
            sessionType
          };
        }
      },
      selectedEventData() {
        return this.selectedEvent ? [this.selectedEvent, 'job'] : null;
      },
    },
    methods: {
      getTimelineTitle(name) {
        const template = this.$gettext('Invite %{name}');

        return this.$gettextInterpolate(template, {name});
      },
      getTimelineEvents(timelineEvents = {}) {
        return helpers.interpreterInfo.timelineEvents(timelineEvents) || [];
      }
    }
  };
</script>

<style scoped>
  .suppliers-list__list-item {
    margin-top: 30px;
  }
</style>
