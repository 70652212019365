export default () => {
  return {
    namespaced: true,
    state: {
      isHelperVisible: false,
      popoverReference: null,
      context: '',
      currentFocusedItem: ''
    },
    mutations: {
      closeHelper(state) {
        state.isHelperVisible = false;
        state.popoverReference = null;
      },
      openHelper(state, value) {
        state.currentFocusedItem = value;
      },
      setPopoverReference(state, value) {
        state.popoverReference = value;
      },
      setContext(state, value) {
        state.context = value;
      },
      setIsHelperVisible(state, value) {
        state.isHelperVisible = value;
      },
      setReferenceToBuyerInfoSection(state) {
        state.popoverReference = document.querySelector('.post-assignment__buyer-section');
      },
      clearStore(state) {
        state.isHelperVisible = false;
        state.popoverReference = null;
        state.context = '';
        state.currentFocusedItem = '';
      }
    }
  };
};
