export default {
  path: 'statistics',
  name: 'BuyerStatistics',
  component: () => import('@/pages/shared_pages/statistics_pages/sh_StatisticsRoot'),
  children: [
    {
      path: ':chartId',
      name: 'BuyerStatisticsChart',
      component: () => import('@/pages/shared_pages/statistics_pages/sh_StatisticsChart')
    }
  ]
};
