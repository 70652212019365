(<template>
  <section class="direct-interpreter post-form__section">
    <interpreter-info-section />
    <lang-pairs-section />
    <direct-availability-section class="post-form__field direct-interpreter__availability" />
    <direct-processing-section class="post-form__field" />
  </section>
</template>)

<script>
  import DirectProcessingSection from '@/components/booking_components/parts/direct_booking/DirectProcessingSection';
  import InterpreterInfoSection from '@/components/booking_components/parts/direct_booking//InterpreterInfoSection';
  import LangPairsSection from '@/components/booking_components/parts/direct_booking//LangPairsSection';
  import DirectAvailabilitySection from '@/components/booking_components/parts/direct_booking//DirectAvailabilitySection';

  export default {
    components: {
      'direct-availability-section': DirectAvailabilitySection,
      'lang-pairs-section': LangPairsSection,
      'interpreter-info-section': InterpreterInfoSection,
      'direct-processing-section': DirectProcessingSection,
    }
  };
</script>

<style scoped>
  .direct-interpreter__availability {
    overflow: auto;
    margin-top: 10px;
    margin-bottom: 0;
  }
</style>
