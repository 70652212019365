export default () => {
  return {
    namespaced: true,
    state: {
      sidebar: {
        component: '',
        progress: false,
        classes: [],
        data: {},
        errors: []
      }
    },
    getters: {
      sidebarComponent(state) {
        return state.sidebar.component;
      },
      sidebarData(state) {
        return state.sidebar.data;
      },
      sidebarProgress(state) {
        return state.sidebar.progress;
      },
      sidebarClasses(state) {
        return state.sidebar.classes;
      }
    },
    actions: {
      closeSidebar({commit}) {
        commit('removeSidebar');
        commit('stopSidebarProgress');
      }
    },
    mutations: {
      startSidebarProgress(state) {
        state.sidebar.progress = true;
      },
      stopSidebarProgress(state) {
        state.sidebar.progress = false;
      },
      setSidebar(state, {component, data, classes}) {
        state.sidebar.component = component;
        state.sidebar.data = data;
        state.sidebar.classes = classes || [];
      },
      removeSidebar(state) {
        state.sidebar.progress = false;
        state.sidebar.component = '';
        state.sidebar.data = {};
      }
    }
  };
};
