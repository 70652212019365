(<template>
  <div class="post-form__field">
    <sk-checkbox :preselected-value="showTemplateSelectors"
                 :item-text="showTemplateSelectorsText"
                 @checkboxvaluechanged="setShowTemplateSelectors" />
    <pricing-template-selectors v-if="showTemplateSelectors" />
  </div>
</template>)

<script>
  import PricingTemplateSelectors from '@/components/shared_components/PricingTemplateSelectors';
  import {mapMutations} from 'vuex';

  export default {
    components: {
      'pricing-template-selectors': PricingTemplateSelectors
    },
    data() {
      return {
        showTemplateSelectors: false
      };
    },
    computed: {
      showTemplateSelectorsText() {
        return this.$gettext('Set custom price templates for this assignment');
      }
    },
    methods: {
      ...mapMutations('PricingTemplatesStore', ['setTemplate']),
      setShowTemplateSelectors(value) {
        this.showTemplateSelectors = value;
        if (!value) {
          this.setTemplate({roleType: 'demander', id: '', financeType: 'internal'});
          this.setTemplate({roleType: 'demander', id: '', financeType: 'external'});
          this.setTemplate({roleType: 'supplier', id: '', financeType: 'honorar'});
          this.setTemplate({roleType: 'supplier', id: '', financeType: 'company'});
          this.setTemplate({roleType: 'supplier', id: '', financeType: 'employee'});
        }
      }
    }
  };
</script>
