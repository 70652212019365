<template>
  <div :class="{'in-progress': progressActive, 'block-interpreter-modal__unblock': currentState === 'blocked'}"
       class="block-interpreter-modal is-progress-bar">
    <template v-if="currentState !== 'blocked'">
      <p class="block-interpreter-modal__message"
         v-html="messageToCustomers"></p>
      <sk-textarea :description="feedbackInputDesc"
                   :preselected-value="feedbackMessage"
                   :placeholder="feedbackPlaceholder"
                   class="block-interpreter-feedback__textarea"
                   @fieldvaluechanged="setFeedback" />
    </template>
    <div class="block-interpreter__btns-cont">
      <button class="sk-btn sk-btn--white block-interpreter__btn"
              @click="closeModal">{{ $gettext('Cancel') }}</button>
      <button :class="{'block-interpreter__btn--block--chosen': currentState === 'blocked'}"
              class="sk-btn sk-btn--white block-interpreter__btn"
              @click="blockHandler">{{ blockButtonText }}</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'block-interpreter-modal',
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        feedbackMessage: '',
        progressActive: false
      };
    },
    computed: {
      currentState() {
        return this.$store.state.EditProfileStore?.profileData.relation;
      },
      messageToCustomers() {
        const template = this.$gettext(''
          + 'We are sorry to learn that you are unhappy with one of our interpreters. We take such reports seriously and will include <b>Interpreter %{interpreterName}</b> in a training program to help improve their skills and abilities.'
          + '\n'
          + '<br><br>We will immediately block the interpreter from all new orders from all your departments.\n'
          + '\n'
          + '<br><br>These reports are important for us and we appreciate the time you take to report this. Please share why you would like to block this interpreter. This will not be shared with the interpreter.');

        return this.$gettextInterpolate(template, {interpreterName: this.data.interpreterName});
      },
      feedbackPlaceholder() { return this.$gettext('Write here...'); },
      feedbackInputDesc() { return this.$gettext('Your feedback about the interpreter'); },
      blockButtonText() {
        const action = this.currentState == 'blocked' ? this.$gettext('Unblock') : this.$gettext('Block');
        const template = this.$gettext('%{action} %{interpreterName}');
        return this.$gettextInterpolate(template, {action, interpreterName: this.data.interpreterName});
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      setFeedback(value) {
        this.feedbackMessage = value;
      },
      removeRelation() {
        this.progressActive = true;
        this.$store.dispatch('EditProfileStore/removeRelation', this.$route.params.id).then(() => {
          this.progressActive = false;
          this.$store.dispatch('ModalStore/closeModal');
        }).catch((e) => {
          // if already blocked removed refresh page
          this.$store.dispatch('ModalStore/closeModal');
          if (e.errors.interpreter[0] == 'You have already unblocked this interpreter') this.$router.go();
        });
      },
      setBlock() {
        const form = new FormData();
        form.append('relation[interpreter]', this.$route.params.id);
        form.append('relation[attitude_type]', 'blocked');
        if (this.feedbackMessage) form.append('relation[reason]', this.feedbackMessage);
        this.progressActive = true;
        this.$store.dispatch('EditProfileStore/sendUpdatedRelation', {form, relation: 'blocked'}).then(() => {
          this.progressActive = false;
          this.$store.dispatch('ModalStore/closeModal');
        }).catch((e) => {
          // if already blocked refresh page
          this.$store.dispatch('ModalStore/closeModal');
          if (e.errors.interpreter[0] == 'You have already add this interpreter') this.$router.go();
        });
      },
      blockHandler() {
        if (this.currentState == 'blocked') {
          this.removeRelation();
        } else {
          this.setBlock();
        }
      }
    }
  };
</script>

<style>
.block-interpreter-modal .sk-input__input-wrapper--textarea {
  height: 80px;
}
</style>

<style scoped>
.block-interpreter-modal {
  padding: 10px 30px;
}

.block-interpreter-modal__unblock {
  margin-top: 50px;
}

.block-interpreter-modal__message {
  padding-bottom: 15px;
}

.block-interpreter__btns-cont {
  display: flex;
  justify-content: flex-end;
}

.block-interpreter__btn {
  width: auto;
  margin-left: 10px;
  padding: 0 10px;
}

.block-interpreter__btn--block--chosen {
  border: 1px solid #979797 !important;
  background-color: #979797 !important;
  color: white !important;
}

@media (max-width: 767px) {
  .block-interpreter-modal {
    padding: 15px;
  }

  .block-interpreter__btn {
    width: 100%;
  }

  .block-interpreter__btn:first-child {
    margin-left: 0;
  }
}
</style>
