(<template>
  <article class="assign-award">
    <template v-if="userCanSeePrices || $isGodmode()">
      <invoice-draft-info :invoice="invoiceDraftModalData" />
    </template>
    <terms-check class="assign-award__checkbox"
                 @checkboxvaluechanged="termsValueChanged" />
    <button :class="{'is-disabled': !acceptedTerms}"
            class="sk-btn sk-btn--default assign-award__btn"
            @click="sendAward">{{ $gettext('Accept offer') }}</button>
  </article>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import InvoiceDraftInfo from '@/components/assignment_components/one_assignment/interactive_info/shared_components/InvoiceDraftInfo';

  export default {
    components: {
      'invoice-draft-info': InvoiceDraftInfo
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        acceptedTerms: ''
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userCanSeePrices'
      ]),
      ...mapGetters('AssignmentFlowStore', ['invoiceDraftModalData'])
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      sendAward() {
        this.$store.commit('ModalStore/removeModalErrors');
        if (this.acceptedTerms) {
          this.data.context.makeAward();
          this.closeModal();
        } else {
          this.$scrollToErrors();
          this.$store.commit('ModalStore/setModalErrors', this.$gettext('Please accept our terms and conditions.'));
        }
      },
      termsValueChanged(val) {
        this.acceptedTerms = val;
      }
    },
    beforeDestroy() {
      this.$store.commit('AssignmentFlowStore/clearModalData');
    }
  };
</script>

<style scoped>
  .assign-award {
    display: block;
    width: 100%;
    padding: 20px 50px;
  }

  .assign-award__checkbox {
    margin: 10px 0 20px 0;
  }

  .assign-award__btn {
    width: auto;
    margin-left: auto;
    padding: 0 30px;
  }

  @media (max-width: 768px) {
    .assign-award {
      padding: 15px;
    }

    .assign-award__checkbox {
      margin: 10px 0 20px 0;
    }
  }
</style>
