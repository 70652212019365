(<template>
  <section class="prof-avatar-name-modal__wrapper">
    <section class="prof-avatar-name-modal__avatar-section">
      <figure class="prof-avatar-name-modal__avatar-figure">
        <tt-avatar :rotation="userData.image_rotation"
                   :avatar="userData.avatar"
                   class="prof-avatar-name-modal__avatar" />
        <button class="rotate-btn prof-avatar-name-modal__rotate-btn"
                autofocus="true"
                @click="rotatePicture"></button>
      </figure>
      <div class="upload-btn__container">
        <input id="upload-avatar"
               class="prof-avatar-name-modal__file-input"
               type="file"
               accept="image/*"
               @change="changeAvatarLogic($event)">
        <label for="upload-avatar"
               class="sk-btn sk-btn--white prof-avatar-name-modal__upload-avatar-label">{{ $gettext('Select picture') }}</label>
      </div>
    </section>
    <hr class="prof-avatar-name-modal__divider">
    <section class="prof-avatar-name-modal__name-section">
      <p v-if="!userCanEditName"
         class="prof-avatar-name-modal__note"
         v-html="disabledEditingNote"></p>
      <sk-input :description="text1"
                :preselected-value="userData.firstName"
                :margin-bottom="true"
                :placeholder="text1"
                :disabled="!userCanEditName"
                :autofocus="true"
                :validation-name="firstNameErrorName"
                @fieldvaluechanged="catchFirstName" />
      <sk-input :description="text2"
                :preselected-value="userData.lastName"
                :margin-bottom="true"
                :placeholder="text2"
                :disabled="!userCanEditName"
                :validation-name="lastNameErrorName"
                @fieldvaluechanged="catchLastName" />
      <sk-input v-if="userIsInterpreter"
                :description="text3"
                :preselected-value="userData.tolkId"
                :margin-bottom="true"
                :placeholder="text3"
                :reg-exp="/[^0-9]/g"
                :max-length="tolkIdLimit"
                :validation-name="tolkIdErrorName"
                class="prof-avatar-name-modal__tolk-label"
                @fieldvaluechanged="catchTolkId" />
      <submit-btns :submit-handler="submitHandler"
                   :cancel-handler="closeModal" />
    </section>
  </section>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import SubmitBtns from '@/components/profile_components/edit/ModalSubmitBtns';

  export default {
    components: {
      'submit-btns': SubmitBtns
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        userData: {
          firstName: this.data.data.firstName || '',
          lastName: this.data.data.lastName || '',
          tolkId: this.data.data.tolkId || '',
          avatar: this.data.data.avatar,
          image_rotation: 0
        },
        newAvatar: '',
        firstNameErrorName: 'profileFirstName',
        lastNameErrorName: 'profileLastName',
        tolkIdErrorName: 'profileTolkId',
        firstNameErrors: [],
        lastNameErrors: [],
        tolkIdNameErrors: []
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter',
        'userIsNotInterpreter',
        'userIsManager'
      ]),
      text1() { return this.$gettext('First name*'); },
      text2() { return this.$gettext('Last name*'); },
      text3() { return this.$gettext('Tolkeportalen ID'); },
      isAnyErrors() {
        return this.firstNameErrors.length || this.lastNameErrors.length || this.tolkIdNameErrors.length;
      },
      userCanEditName() {
        return this.$isGodmode() || this.userIsNotInterpreter || this.userIsManager;
      },
      disabledEditingNote() {
        const template = this.$gettext('Names on %{domain} must match your identity card/passport. Please contact us at <a class="sk-link sk-link--gray-bg" href="mailto:%{supportEmail}">%{supportEmail}</a> if your legal name has changed.');

        return this.$gettextInterpolate(template, {
          domain: this.$getString('domainData', 'platformName'),
          supportEmail: this.$getString('domainData', 'supportFinancePersonEmail')
        });
      },
      tolkIdLimit() { return 4; }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      catchFirstName(value) {
        this.userData.firstName = value.trim();
      },
      catchLastName(value) {
        this.userData.lastName = value.trim();
      },
      catchTolkId(value) {
        this.userData.tolkId = value.trim();
      },
      submitHandler() {
        this.$store.commit('ModalStore/removeModalErrors');
        const form = new FormData();
        const {firstName = '', lastName = '', tolkId = ''} = this.userData;
        const tolkIdLength = tolkId.length;

        this.firstNameErrors = [];
        this.lastNameErrors = [];
        this.tolkIdNameErrors = [];

        if (!firstName.trim()) {
          this.firstNameErrors.push(this.$gettext('Your first name is required.'));
        } else if (firstName.trim().length < 2) {
          this.firstNameErrors.push(this.$gettext('There should be at least 2 characters'));
        }
        if (!lastName.trim()) {
          this.lastNameErrors.push(this.$gettext('Your last name is required'));
        } else if (lastName.trim().length < 2) {
          this.lastNameErrors.push(this.$gettext('There should be at least 2 characters'));
        }
        if (tolkIdLength >= 1 && tolkIdLength < 4) {
          this.tolkIdNameErrors.push(this.$gettext('Tolk-ID must contain 4 characters'));
        }

        this.$store.commit('ErrorsStore/setError', {name: this.firstNameErrorName, errors: this.firstNameErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.lastNameErrorName, errors: this.lastNameErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.tolkIdErrorName, errors: this.tolkIdNameErrors});

        if (!this.isAnyErrors) {
          const startWord = this.userIsInterpreter ? 'interpreter[person]' : 'person';
          if (this.userIsInterpreter) {
            form.append('interpreter[tolk_id]', this.userData.tolkId);
          }
          form.append(`${startWord}[first_name]`, this.userData.firstName);
          form.append(`${startWord}[last_name]`, this.userData.lastName);
          if (this.newAvatar) {
            if (this.userIsInterpreter) {
              form.append('interpreter[person][avatar]', this.newAvatar);
            } else {
              form.append('person[avatar]', this.newAvatar);
            }
          }
          if (this.userData.image_rotation) {
            if (this.userIsInterpreter) {
              form.append('interpreter[person][image_rotation]', this.userData.image_rotation);
            } else {
              form.append('person[image_rotation]', this.userData.image_rotation);
            }
          }

          this.$emit('startprogress');
          this.data.context.updateAvatarName(form);
          this.data.context.$on('updatefailed', this.stopProgress);
          this.data.context.$on('updatesuccess', this.closeModal);
        }
      },
      closeModal() {
        this.$emit('closemodal');
      },
      stopProgress() {
        this.$emit('stopprogress');
      },
      changeAvatarLogic(eve) {
        const self = this;
        let converterResponse = '';
        this.$store.commit('ModalStore/removeModalErrors');
        this.userData.image_rotation = 0;

        if (eve.target && eve.target.files[0]) {
          converterResponse = this.$avatarConverter.convertAvatar(this, eve.target.files[0]);
          if (!(converterResponse.then) && Array.isArray(converterResponse)) {
            for (const error of converterResponse) {
              this.$scrollToErrors();
              this.$store.commit('ModalStore/setModalErrors', error);
            }
          } else {
            converterResponse.then((response) => {
              self.userData.avatar = response.target.result;
              self.newAvatar = eve.target.files[0];
            });
          }
        }
      },
      rotatePicture() {
        this.userData.image_rotation = (this.userData.image_rotation + 90) % 360;
      }
    }
  };
</script>

<style scoped>
  .prof-avatar-name-modal__wrapper {
    display: flex;
    padding: 35px;
    padding-top: 35px;
  }

  .upload-btn__container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .prof-avatar-name-modal__avatar-section {
    display: block;
    flex-basis: 170px;
    padding-right: 10px;
  }

  .prof-avatar-name-modal__avatar-figure {
    position: relative;
    display: block;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
  }

  .prof-avatar-name-modal__avatar {
    display: block;
    width: 135px;
    height: 135px;
  }

  .prof-avatar-name-modal__rotate-btn {
    margin: auto;
    margin-top: 4px;
  }

  .prof-avatar-name-modal__upload-avatar-label {
    display: inline-block;
    width: auto;
    padding: 0 10px;
  }

  .prof-avatar-name-modal__file-input {
    position: absolute;
    opacity: 0;
  }

  .prof-avatar-name-modal__name-section {
    display: block;
  }

  .prof-avatar-name-modal__note {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 3px;
    background-color: #e2d2bb;
    font-size: 12px;
  }

  .prof-avatar-name-modal__tolk-label {
    width: 150px;
  }

  .prof-avatar-name-modal__divider {
    margin-right: 20px;
    margin-left: 15px;
    opacity: 0.2;
  }

  @media (max-width: 767px) {
    .prof-avatar-name-modal__wrapper {
      margin: -30px;
      transform: scale(0.9);
    }
  }
</style>
