import { render, staticRenderFns } from "./DateTimeSection.vue?vue&type=template&id=48d64d7b&scoped=true&"
import script from "./DateTimeSection.vue?vue&type=script&lang=js&"
export * from "./DateTimeSection.vue?vue&type=script&lang=js&"
import style0 from "@assets/css/assignment_dates.css?vue&type=style&index=0&id=48d64d7b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48d64d7b",
  null
  
)

export default component.exports