<template>
  <div class="project-attachments-section__files-password attachment-password">
    <button v-if="!showPasswordInput"
            class="sk-link"
            @click="showPasswordInputHandler">{{ $gettext('Add password') }}</button>
    <div v-else
         class="attachment-password__inner">
      <sk-input class="attachment-password__input"
                :placeholder="passwordText"
                :preselected-value="''"
                :autocomplete="false"
                :type="'password'"
                @fieldvaluechanged="attachmentPasswordHandler" />
      <button class="attachment-password__button-close"
              @click="showPasswordInputHandler"></button>
    </div>
  </div>
</template>

<script>
  import {mapMutations} from 'vuex';

  export default {
    props: {
      attachmentIndex: {
        type: Number,
        default: null
      }
    },
    data() {
      return {
        showPasswordInput: false,
      };
    },
    computed: {
      passwordText() { return this.$gettext('Password'); },
    },
    methods: {
      ...mapMutations('PostingStore/AttachmentsStore', ['setAttachmentPassword']),
      showPasswordInputHandler() {
        this.showPasswordInput = !this.showPasswordInput;
      },
      attachmentPasswordHandler(password) {
        this.setAttachmentPassword({index: this.attachmentIndex, password});
      }
    }
  };
</script>

<style>
.attachment-password__input .sk-input__input {
  width: 100px;
  height: 25px;
}

.attachment-password__input.sk-input {
  margin-bottom: 0;
}
</style>

<style scoped>
.attachment-password__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.attachment-password__button-close {
  width: 12px;
  height: 12px;
  margin-left: 10px;
  background-image: url(~@assets/imgs/undefined_imgs/close_x_black.svg);
  background-position: center center;
  background-repeat: no-repeat;
}
</style>
