import Vue from 'vue';
import {ApolloClient} from 'apollo-client';
import {HttpLink} from 'apollo-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import constants from '@/modules/constants';
import 'isomorphic-fetch';

const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');

// Create the apollo client
export function createApolloClient(ssr = false, token = '') {
  const customFetch = (uri, options) => {
    const authToken = isBrowser() ? Vue.prototype.$cookieManager.getCookie('authToken') : token;
    if (authToken) options.headers.Authorization = `Bearer ${authToken}`;
    return fetch(uri, options);
  };
  const httpLink = new HttpLink({
    // You should use an absolute URL here
    uri: constants.GRAPHQL_API_PATH,
    fetch: customFetch
  });

  const cache = new InMemoryCache();

  // If on the client, recover the injected state
  if (!ssr) {
    if (typeof window !== 'undefined') {
      const state = window.__APOLLO_STATE__;
      if (state) {
        // If you have multiple clients, use `state.<client_id>`
        cache.restore(state.defaultClient);
      }
    }
  }

  const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
    ...(ssr ? {
      // Set this on the server to optimize queries when SSR
      ssrMode: true,
    } : {
      // This will temporary disable query force-fetching
      ssrForceFetchDelay: 100,
    }),
  });

  return apolloClient;
}
