(<template>
  <div class="toggle-section">
    <div :class="{
           'in-progress': progressActive,
           'toggle-section__wrapper--disabled': disableStandBySection
         }"
         class="toggle-section__wrapper is-progress-bar">
      <sk-toggler v-if="disableStandBySection"
                  :disabled="true"
                  :preselected-value="false"
                  @togglerchanged="toggleHandler" />
      <sk-toggler v-else
                  :preselected-value="toggleValue"
                  @togglerchanged="toggleHandler" />
      <p class="toggle-section__text">{{ toggleDescription }}</p>
      <span v-if="toggleStatus"
            :class="[`toggle-section__status--${toggleStatus}`]"
            :title="toggleStatusText"
            :alt="toggleStatusText"
            class="toggle-section__status"></span>
    </div>
    <p v-if="disableStandBySection"
       class="toggle-section__status-error"
       v-html="statusErrorText"></p>
  </div>
</template>)

<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';
  import helpers from '@/helpers';
  import PostingTogglePrototype from '@/prototypes/PostingTogglePrototype';

  export default {
    extends: PostingTogglePrototype,
    computed: {
      ...mapGetters('PostingNotifierStore', [
        'disableStandBySection'
      ]),
      ...mapState('PostingStore', {
        toggleValue: ({StandByStore}) => StandByStore.standBy
      }),
      toggleDescription() {
        return this.$gettext('Stand by');
      },
      statusErrorText() {
        return helpers.getJobInfo.disabledStandByText(this);
      }
    },
    methods: {
      ...mapMutations({
        toggleHandler: 'PostingStore/StandByStore/setStandBy'
      })
    }
  };
</script>

<style scoped src="@assets/css/toggle_section.css"></style>
