export default {
  path: 'preferences',
  name: 'ServerPreferences',
  component: () => import('@/pages/shared_pages/preferences/sh_PreferencesRoot'),
  children: [
    {
      path: 'interpretationNotifications',
      name: 'ServerInterpretationNotifications',
      meta: {subPage: true},
      component: () => import('@/pages/shared_pages/preferences/sh_NotificationSettings')
    },
    {
      path: 'translationNotifications',
      name: 'ServerTranslationNotifications',
      meta: {subPage: true},
      component: () => import('@/pages/shared_pages/preferences/sh_NotificationSettings')
    },
    {
      path: 'availability',
      name: 'ServerPreferencesAvailability',
      meta: {subPage: true},
      component: () => import('@/pages/ServerPages/preferences/s_AvailabilityPref')
    }
  ]
};
