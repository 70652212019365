export default {
    path: 'projects',
    component: () => import('@/pages/shared_pages/project_pages/sh_ProjectsRoot'),
    children: [
      {
        path: '/',
        redirect: {name: 'BuyerPostTranslationProject'}
      },
      {
        path: 'all',
        name: 'BuyerAllProjects',
        meta: {enterpriseOnly: true, subPage: true},
        component: () => import('@/pages/shared_pages/project_pages/sh_AllProjects')
      },
      {
        path: 'createTranslationProject',
        name: 'BuyerPostTranslationProject',
        meta: {
          enterpriseOnly: true,
          subPage: true,
          assignmentType: 'translation'
        },
        component: () => import('@/pages/BuyerPages/project_pages/b_PostTranslationProject')
      },
      {
        path: 'createCorrectionProject',
        name: 'BuyerPostCorrectionProject',
        meta: {
          enterpriseOnly: true,
          subPage: true,
          assignmentType: 'correction'
        },
        component: () => import('@/pages/BuyerPages/project_pages/b_PostTranslationProject')
      },
      {
        path: 'createInPersonProject',
        name: 'BuyerPostInPersonProject',
        meta: {
          enterpriseOnly: true,
          subPage: true,
          assignmentType: 'in_person'
        },
        component: () => import('@/pages/BuyerPages/project_pages/b_PostTranslationProject')
      },
      {
        path: 'all/:id',
        name: 'BuyerOneProject',
        meta: {enterpriseOnly: true, subPage: true, viewers: true},
        component: () => import('@/pages/shared_pages/project_pages/sh_OneProject'),
        props: true
      }
    ]
  };
