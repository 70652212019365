import { render, staticRenderFns } from "./Badges.vue?vue&type=template&id=7f2812b3&scoped=true&"
import script from "./Badges.vue?vue&type=script&lang=js&"
export * from "./Badges.vue?vue&type=script&lang=js&"
import style0 from "./Badges.vue?vue&type=style&index=0&id=7f2812b3&prod&scoped=true&lang=css&"
import style1 from "@/assets/tikktalk/css/badges.css?vue&type=style&index=1&id=7f2812b3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f2812b3",
  null
  
)

export default component.exports