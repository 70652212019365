<template>
  <div class="send-mail__inner-section"
       :class="[modalClass]">
    <div class="cover-letter-section">
      <p class="cover-letter-text">{{ upploadCoverLetterText }}</p>
      <sk-upload-file v-if="!showUploadCoverLetter"
                      :id="'coverLetter'"
                      :button-text="upploadCoverLetterTextPlaceholder"
                      :reset="fileInputReset"
                      :accept="'*'"
                      class="cover-letter-section__button"
                      @changefileinput="catchAttachment" />
    </div>
    <div v-if="attachmentsErrors && attachmentsErrors.length"
         class="sk-input__errors-cont">
      <span v-for="(error, errorIndex) in attachmentsErrors"
            :key="errorIndex"
            v-html="(attachmentsErrors.length === 1) ? error : `${errorIndex + 1}. ${error}`"></span>
    </div>
    <div v-if="showCoverLetterAttachments">
      <div v-for="(attachment, index) in attachments"
           :key="index"
           class="cover-letter-section__files">
        <button type="button"
                class="cover-letter-section__remove-btn"
                @click="deleteAttachment({index, id: attachment.id})"></button>
        <p class="cover-letter-section__file-name">{{ attachment.name }}</p>
      </div>
    </div>
    <p class="address__radio-title">{{ radioText }}</p>
    <sk-radio v-for="item in radioList"
              :key="item.id"
              :item-text="item.value"
              :item-value="item.value"
              :preselected-value="preselectedRadio"
              :item-name="radioText"
              class="address__radio"
              @radiochanged="catchRadioChange" />
    <div>
      <div class="address-input__field">
        <sk-input :preselected-value="preselectedAddress"
                  :description="addressText"
                  :disabled="isAddressInLetter"
                  :placeholder="addressTextPlaceholder"
                  @fieldvaluechanged="setAddress" />
        <div v-if="errors.address.errors && errors.address.errors.length"
             class="sk-input__errors-cont">
          <span v-for="(error, errorIndex) in errors.address.errors"
                :key="errorIndex"
                v-html="(errors.address.errors.length === 1) ? error : `${errorIndex + 1}. ${error}`"></span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {mapMutations, mapGetters, mapState} from 'vuex';

  export default {
    data() {
      return {
        deletedAttachments: true
      };
    },
    computed: {
      ...mapState('PostingStore/SendMailStore', {
        isAddressInLetter: (state) => state.isAddressInLetter,
        address: (state) => state.address,
        errors: (state) => state.errors
      }),
      ...mapGetters('PostingStore/CoverLetterStore', [
        'attachments'
      ]),
      ...mapState('PostingStore/CoverLetterStore', {
        fileInputReset: (state) => state.fileInputReset,
        // errors
        attachmentsErrors: (state) => state.errors.attachments.errors || []
      }),
      ...mapGetters('OneProjectStore', [
        'project'
      ]),
      ...mapGetters('UserInfoStore', ['translationCoverLetterRequired']),
      preselectedAddress() {
        if (!this.project.sendByPostAddress || this.project.sendByPostAddress === 'Address is in cover letter') {
          this.setIsAddressInLetter(true);
        }
        return this.project.sendByPostAddress ? this.project.sendByPostAddress : '';
      },
      modalClass() {
        if (this.project) {
          return 'modal-class';
        } else return '';
      },
      radioList() {
        return [
          {id: 1,
           value: this.$gettext('Yes')},
          {id: 2,
           value: this.$gettext('No')}
        ];
      },
      preselectedRadio() {
        if (!this.project.sendByPostAddress || this.project.sendByPostAddress === 'Address is in cover letter') {
          this.setIsAddressInLetter(true);
          return this.radioList[0].value;
        }
        return this.radioList[1].value;
      },
      radioText() {
        return this.$pgettext('translation', 'Is the address on the cover letter?');
      },
      showUploadCoverLetter() {
        const statuses = ['quote_rejected', 'in_progress', 'finished', 'cancelled'];
        if (statuses.includes(this.project.status)) return true;
        return this.attachments.length;
      },
      showCoverLetterAttachments() {
        const statuses = ['draft', 'quote_send', 'quote_requested', 'quote_accepted'];
        return this.attachments
          && this.attachments.length
          && statuses.includes(this.project.status);
      },
      addressText() { return this.$pgettext('translation', 'Address'); },
      addressTextPlaceholder() { return this.$pgettext('translation', 'Enter delivery address'); },
      upploadCoverLetterText() { return this.$pgettext('translation', 'Upload Front Page/Cover Letter'); },
      upploadCoverLetterTextPlaceholder() { return this.$pgettext('translation', 'Upload follow page'); }
    },
    methods: {
      ...mapMutations('PostingStore/CoverLetterStore', [
        'setAttachments',
        'deleteAttachment',
        'setError',
        'removeErrors',
        'archiveFile'
      ]),
      ...mapMutations('PostingStore/SendMailStore', [
        'setAddress',
        'setIsAddressInLetter']),
      catchAttachment(target) {
        this.removeErrors();
        this.validFiles(target);
        if (target.files && target.files.length) {
          this.setAttachments(target);
        }
      },
      deleteCoverLetter() {
        this.archiveFile();
        this.deletedAttachments = false;
      },
      validFiles(target) {
        if (target.files.length >= 100 || this.attachments.length >= 100) {
          this.onError = this.$pgettext('translation', 'Max. file size: 128MB, Max. files: 100');
          return;
        }
        this.onError = '';
      },
      catchRadioChange() {
        this.setIsAddressInLetter(!this.isAddressInLetter);
        if (this.isAddressInLetter) {
          this.setAddress('Address is in cover letter');
        } else {
          this.setAddress('');
        }
      }
    }
  };
</script>

<style scoped>
.send-mail__inner-section {
  min-width: 430px;
}

.modal-class {
  margin: 20px;
}

.address__radio {
  margin: 10px 10px 10px 0;
}

.address-input__field {
  margin-top: 10px;
}

.address-scope-select {
  margin-bottom: 10px;
}

.cover-letter-text {
  font-weight: bold;
}

.cover-letter-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cover-letter-section__button {
  margin-left: 20px;
}

#cover-letter {
  min-width: 190px;
}

.cover-letter-section__files {
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 10px;
}

.cover-letter-section__remove-btn {
  display: block;
  width: 15px;
  margin-right: 10px;
  background-image: var(--image-trash-icon);
  background-position: 50% 50%;
  background-size: 12px auto;
  background-repeat: no-repeat;
}

.cover-letter-section__remove-btn:active {
  background-color: #e1d7eb;
}
</style>
