import helpers from '../../helpers';

export default ({$moment: moment}) => {
  return {
    namespaced: true,
    state: {
      isOnTranslation: false, // Interpretation or Translation
      searchDirectSupplierByName: true
    },
    getters: {
      mainDataShouldBeFetched(state, getters, rootState, rootGetters) {
        const employeesShouldBeFetched = !rootState.EnterpriseStore.employees;
        const qualificationsShouldBeFetched = !rootState.selectsData.qualification || !rootState.selectsData.qualification.length;
        const paymentMethodsShouldBeFetched = !rootState.PostingStore.PaymentMethodStore.allPaymentMethods;
        const contactAddressesShouldBeFetched = !rootState.PostingStore.AssignmentTypeStore.contactAddresses;

        return rootGetters['UserInfoStore/userIsEnterpriseMember']
          ? employeesShouldBeFetched || qualificationsShouldBeFetched || paymentMethodsShouldBeFetched
          : qualificationsShouldBeFetched || contactAddressesShouldBeFetched;
      },
      showCategoriesSelect(state, getters, rootState, rootGetters) {
        return (rootGetters['UserInfoStore/userIsEnterpriseMember'] || rootGetters['UserInfoStore/userIsManager'])
               && rootState.PostingStore.SubjectStore.categoriesList.length;
      },
      showBookerSection(state, getters, rootState, rootGetters) {
        return rootGetters['UserInfoStore/userIsEnterpriseMember'];
      },
      showDepartmentSelect(state, getters, rootState) {
        const {departmentsList} = rootState.PostingStore.DepartmentAttendersStore;
        return departmentsList.length && departmentsList.length > 0;
      },
      showAttendersSelect(state, getters, rootState) {
        return rootState.PostingStore.DepartmentAttendersStore.attendersList.length;
      },
      showOwnerRealNameSection(state, getters, rootState, rootGetters) {
        if (rootGetters['UserInfoStore/userIsEnterpriseMember']) {
          const employee = rootGetters['PostingStore/BookerStore/bookerData'] || {};
          return employee.isShared || false;
        } else {
          return rootGetters['UserInfoStore/isShared'];
        }
      },
      showDepartmentAttendersSection(state, getters, rootState, rootGetters) {
        return rootGetters['UserInfoStore/userIsEnterpriseMember']
          && (getters.showDepartmentSelect
            || getters.showAttendersSelect);
      },
      showBookingReferenceField(state, getters, rootState, rootGetters) {
        return rootGetters['UserInfoStore/userHasBookingRef'];
      },
      showPaymentBookingReferenceField(state, getters, rootState, rootGetters) {
        return rootGetters['UserInfoStore/userHasPaymentBookingRef'];
      },
      showBookingReferenceSection(state, getters, rootState, rootGetters) {
        return rootGetters['UserInfoStore/userIsEnterpriseMember']
          && (getters.showBookingReferenceField
            || getters.showPaymentBookingReferenceField);
      },
      showCaseNumberSection(state, getters, rootState, rootGetters) {
        return rootGetters['UserInfoStore/userHasCaseNumber'];
      },
      showClientNameField(state, getters, rootState, rootGetters) {
        return rootGetters['UserInfoStore/userHasClientName'];
      },
      showClientIdField(state, getters, rootState, rootGetters) {
        return rootGetters['UserInfoStore/userHasClientId'];
      },
      showClientSection(state, getters) {
        return getters.showClientNameField || getters.showClientIdField;
      },
      showPaymentMethod(state, getters, rootState, rootGetters) {
        return (rootGetters['UserInfoStore/userIsAdminOfEnterprise'] || rootGetters['UserInfoStore/userIsEnterpriseMember'])
          && rootGetters['PostingStore/PaymentMethodStore/currentPaymentMethods'].length;
      },
      showQualificationSection(state, getters, rootState, rootGetters) {
        return rootGetters['UserInfoStore/allowQualificationPreference'] || rootGetters['TTAuthStore/isGodmode'];
      },
      showParticipantsSection(state, getters, rootState, rootGetters) {
        return rootGetters['UserInfoStore/userIsEnterpriseMember'];
      },
      showAutoInviteSection(state, getters, rootState, rootGetters) {
        const isDirectBooking = rootState.PostInterpretationStore.job?.interpretation_requirement?.interpreter_uid;
        return rootGetters['TTAuthStore/isGodmode'] && !isDirectBooking;
      },
      showInfiniteAutoInviteSection(state, getters, rootState) {
        const autoInvite = rootState.PostingStore.AutoInviteStore.autoInvite;
        return getters.showAutoInviteSection && autoInvite;
      },
      showAutoApproveSection(state, getters, rootState, rootGetters) {
        return rootGetters['TTAuthStore/isGodmode'] && rootGetters['UserInfoStore/userIsEnterpriseMember'];
      },
      showStandBySection(state, getters, rootState, rootGetters) {
        return rootGetters['TTAuthStore/isGodmode'] && rootGetters['UserInfoStore/userIsEnterpriseMember'];
      },
      disableStandBySection(state, getters, rootState) {
        const subject = rootState.PostingStore.SubjectStore.subject || '';
        const extraInfo = rootState.PostingStore.ExtraInfoStore.extraInfo || '';

        return helpers.getJobInfo.disabledStandBy([subject, extraInfo]);
      },
      showConfirmationsSection(state, getters, rootState, rootGetters) {
        return rootGetters['TTAuthStore/isGodmode'] || rootGetters['UserInfoStore/userIsManager'];
      },
      showReplacementSection(state, getters, rootState, rootGetters) {
        return rootGetters['TTAuthStore/isGodmode'];
      },
      showPricingTemplatesSection(state, getters, rootState, rootGetters) {
        return rootGetters['TTAuthStore/isGodmode'] && rootState.PostInterpretationStore.job.job_id === '';
      },
      showManualFeeSection(state, getters, rootState, rootGetters) {
        return rootGetters['TTAuthStore/isGodmode'];
      },
      showSessionTypeProcessingSection(state, getters, rootState) {
        const assignmentType = rootState.PostingStore.AssignmentTypeStore.assignmentType;
        return ['in_person', 'video'].includes(assignmentType);
      },
      showDirectProcessingSection(state, getters, rootState) {
        return !!rootState.PostInterpretationStore.job?.interpretation_requirement?.interpreter_uid;
      },
      showDirectInterpreterSection(state, getters, rootState) {
        return rootState.PostingStore.DirectBookingStore.directInterpreter;
      },
      showGenderProcessingSection(state, getters, rootState) {
        return rootState.PostingStore.GenderStore.sex != '2';
      },
      showSeveralSuppliersSection(state, getters, rootState, rootGetters) {
        return rootGetters['UserInfoStore/userCanShowFakeBookingFeatures'] && false;
      },
      showSeveralSuppliersProcessingSection(state, getters, rootState) {
        return rootState.PostingStore.SeveralSuppliersStore.severalSuppliersSelect;
      },
      showQualificationProcessingSection(state, getters, rootState) {
        return rootState.PostingStore.QualificationStore.qualificationSelect && rootState.PostingStore.QualificationStore.qualification;
      },
      showAlternativeLanguageSection(state, getters, rootState, rootGetters) {
        return rootGetters['UserInfoStore/userCanShowAlternativeLanguageFeature'];
      },
      showAlternativeLanguageSelect(state, getters, rootState) {
        return rootState.PostingStore.LanguagesStore.alternativeLanguageToSelect;
      },
      showMultipleDatetimeInnerSection(state, getters, rootState, rootGetters) {
        return rootGetters['UserInfoStore/userCanShowSeriesOrderFeature'] && !rootState.PostInterpretationStore.job?.job_id;
      },
      showSameLocationSuppliersListSection(state, getters, rootState, rootGetters) {
        return rootGetters['UserInfoStore/userCanShowFakeBookingFeatures']
          && rootState.PostingStore.LanguagesStore.languageFrom
          && rootState.PostingStore.LanguagesStore.languageTo
          && rootState.PostingStore.AssignmentTypeStore.assignmentType === 'in_person';
      },
      showSeriesBooking(state, getters, rootState) {
        const {multipleDateTimesSelect, dateTimes} = rootState.PostingStore.MultipleDateTimeStore;

        return getters.showMultipleDatetimeInnerSection && multipleDateTimesSelect && dateTimes.length;
      },
      showFastInterpretationForm(state, getters, rootState, rootGetters) {
        const fastBookingSessionTypes = ['phone', 'video'];
        const allowedSessionTypes = rootGetters['UserInfoStore/userHasAllowedSessionTypes']
          ? rootGetters['UserInfoStore/userAllowedSessionTypes']
          : fastBookingSessionTypes;

        return allowedSessionTypes.find((type) => fastBookingSessionTypes.includes(type));
      },
      showSameLocationSuppliersList(state, getters, rootState) {
        return getters.showSameLocationSuppliersListSection
          && rootState.PostingStore.SuppliersListStore.sameLocationSuppliersListSelect;
      },
      showLatestSuppliersListSection(state, getters, rootState, rootGetters) {
        return rootGetters['UserInfoStore/userCanShowFakeBookingFeatures']
          && rootState.PostingStore.LanguagesStore.languageFrom
          && rootState.PostingStore.LanguagesStore.languageTo;
      },
      showLatestSuppliersList(state, getters, rootState) {
        return getters.showLatestSuppliersListSection
          && rootState.PostingStore.SuppliersListStore.latestSuppliersListSelect;
      },
      showInvoiceBlock(state, getters) {
        return getters.showBookingReferenceSection
          || getters.showCaseNumberSection
          || getters.showClientSection
          || getters.showPaymentMethod;
      },
      showDirectAward(state, getters, rootState) {
        return rootState.TTAuthStore.godmode && !!rootState.PostInterpretationStore.job?.interpretation_requirement?.interpreter_uid;
      }
    },
    actions: {
      refetchCategories({dispatch, rootState}, {headers}) {
        return new Promise((resolve, reject) => {
          const {department} = rootState.PostingStore.DepartmentAttendersStore;
          dispatch('PostingStore/SubjectStore/getTranslationCategories', null, {root: true});
          dispatch('PostingStore/SubjectStore/getCategories', {department, headers}, {root: true})
            .then(() => {
              dispatch('preselectCategory');
              resolve();
            }).catch((error) => {
              reject(error);
            });
        });
      },
      checkExistingCategory({commit, rootState}) {
        const {categoriesList = [], category} = rootState.PostingStore.SubjectStore;
        const categoryInList = categoriesList.find((item = {}) => {
          const items = item.items || [];
          return items.find((i = {}) => i.id == category) || item.id == category;
        });
        if (!categoryInList) commit('PostingStore/SubjectStore/setCategory', '', {root: true});
      },
      preselectCategory({commit, rootState}) {
        const res = [];
        const categoriesList = rootState.PostingStore.SubjectStore.categoriesList || [];
        const category = rootState.PostingStore.SubjectStore.category || '';

        categoriesList.forEach((item) => {
          res.push(item);
          if (item && item.items && item.items.length) {
            item.items.forEach((subItem) => {
              res.push(subItem);
            });
          }
        });
        if (!res.find((item) => item.id == category)) {
          commit('executeNotTrackedMutation', {
            mutationName: 'PostingStore/SubjectStore/setCategory',
            payload: ''
          }, {root: true});
        }
      },
      preselectProjectStaticData({commit}) {
        // Deadline Should Be Empty on Init for Trans-Projects
        commit('PostingStore/DeadlineStore/emptyDeadline', null, {root: true});
        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/LanguagesStore/setLangFrom',
          payload: ''
        }, {root: true});
        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/LanguagesStore/setLangTo',
          payload: 3
        }, {root: true});
      },
      preselectProjectDefaultAccessLevel({rootGetters, commit, dispatch}) {
        //  Default Access Level based on Setting
        if (rootGetters['UserInfoStore/showEnterpriseOrdersToAllMembers']) {
          commit('PostingStore/AccessLevelStore/setAccessLevel', 'enterprise', {root: true});
        } else {
          dispatch('PostingStore/AccessLevelStore/showDepartmentOrdersToAllMembers', null, {root: true}).then((showDepartmentOrdersToAllMembers) => {
            if (showDepartmentOrdersToAllMembers) commit('PostingStore/AccessLevelStore/setAccessLevel', 'department', {root: true});
            else commit('PostingStore/AccessLevelStore/setAccessLevel', 'owner', {root: true});
          });
        }
        commit('PostingStore/AccessLevelStore/setAccessLevelSelectState', true, {root: true});
      },
      preselectStaffingStaticData({commit, dispatch}) {
        dispatch('PostingStore/MultipleDateTimeStore/preselectDateTimes', null, {root: true});
        dispatch('PostingNotifierStore/preselectStandByValue', null, {root: true});
        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/LanguagesStore/setLangTo',
          payload: 'not_important'
        }, {root: true});
      },
      preselectInterpretationStaticData({commit, dispatch, rootState}, {preselectedStartTime, preselectedFinishTime} = {}) {
        if (!rootState.PostInterpretationStore.copiedJob) {
          dispatch('preselectGender');
          dispatch('preselectActualCreatedData');
          dispatch('preselectManualFees');
          dispatch('setFirstSavedInPersonInfo');
          dispatch('PostingNotifierStore/preselectStandByValue', null, {root: true});
          commit('executeNotTrackedMutation', {
            mutationName: 'PostingStore/LanguagesStore/setLangFrom',
            payload: 3
          }, {root: true});
          dispatch('PostingStore/AssignmentTypeStore/setAllowedAssignmentTypes', null, {root: true});
          dispatch('PostingStore/MultipleDateTimeStore/changeMultipleDateTimesOneSupplierSelectState', true, {root: true});
          dispatch('preselectAssignmentType').then(() => {
            // because of minimum job duration of assignment types is based on enterprise settings
            // first assignment type should be preselected
            dispatch('preselectDateTime', {
              preselectedStartTime,
              preselectedFinishTime
            });
          });
        }
      },
      preselectFastInterpretationStaticData({dispatch, commit}) {
        dispatch('preselectInterpretationStaticData');
        commit('PostingStore/AutoInviteStore/setAutoInvite', true, {root: true});
        commit('PostingStore/AutoApproveStore/setAutoApprove', true, {root: true});
        dispatch('setFastInterpretationSessionTypes');
      },
      preselectSecretaryInterpretationStaticData({dispatch, commit}) {
        dispatch('preselectInterpretationStaticData');
        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/AssignmentTypeStore/setAssignmentType',
          payload: 'phone'
        }, {root: true});
        dispatch('preselectAutoApprove');
        dispatch('preselectSecretaryDeadline');
        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/LanguagesStore/setLangFrom',
          payload: 3
        }, {root: true});
      },
      setFastInterpretationSessionTypes({rootGetters, commit}) {
        const fastBookingSessionTypes = ['phone', 'video'];
        let allowedSessionTypes = rootGetters['UserInfoStore/userHasAllowedSessionTypes']
          ? rootGetters['UserInfoStore/userAllowedSessionTypes']
          : ['phone', 'video'];
        allowedSessionTypes = allowedSessionTypes.filter((type) => fastBookingSessionTypes.includes(type)) || [];
        commit('PostingStore/AssignmentTypeStore/setAllowedAssignmentTypes', allowedSessionTypes, {root: true});
      },
      preselectInterpretationEditStaticData({dispatch}) {
        dispatch('PostingStore/AssignmentTypeStore/setAllowedAssignmentTypes', null, {root: true});
      },
      preselectManualFees({commit}) {
        commit('PostingStore/ManualFeesStore/setManualFee', {key: 'booking', value: true}, {root: true});
        commit('PostingStore/ManualFeesStore/setManualFee', {key: 'editing', value: false}, {root: true});
      },
      preselectDateTime({commit, rootGetters}, {preselectedStartTime, preselectedFinishTime} = {}) {
        const duration = rootGetters['UserInfoStore/defaultJobDuration'];
        commit('PostingStore/DateTimeStore/setPreselectedTime', {
          duration,
          preselectedStartTime,
          preselectedFinishTime
        }, {
          root: true
        });
      },
      preselectSecretaryDeadline({commit, rootGetters}) {
        const duration = rootGetters['UserInfoStore/defaultJobDuration'];
        commit('PostingStore/DeadlineStore/setInitialDeadlineTime', {duration}, {
          root: true
        });
      },
      preselectActualCreatedData({commit, rootGetters}) {
        commit('PostingStore/ReplacementStore/setActualCreatedDate', moment()
          .format('YYYY-MM-DD'), {root: true});
        commit('PostingStore/ReplacementStore/setActualCreatedTime', moment()
          .format('HH:mm'), {root: true});
        commit('PostingStore/ReplacementStore/setBookingMechanism', rootGetters['TTAuthStore/isGodmode'] ? 'phone' : 'platform', {root: true});
      },
      clearActualCreatedTime({commit}) {
        commit('PostingStore/ReplacementStore/setActualCreatedTime', '', {root: true});
      },
      preselectAutoApprove({rootState, commit, rootGetters}) {
        const assignmentType = rootState.PostingStore.AssignmentTypeStore.assignmentType || '';
        commit('PostingStore/AutoApproveStore/setAutoApprove', rootGetters['UserInfoStore/userAutoApproveSessionTypes'].includes(assignmentType), {root: true});
      },
      preselectStandByValue({commit, rootGetters}) {
        commit('PostingStore/StandByStore/setStandBy', rootGetters['UserInfoStore/defaultStandByValue'], {root: true});
      },
      getInterpreterProfile({rootState, rootGetters, dispatch, commit}, {id, country, slug}) {
        return new Promise((resolve, reject) => {
          dispatch('EditProfileStore/getUserProfile', {id, country, slug}, {root: true})
            .then((data) => {
              const profileData = rootState.EditProfileStore.profileData || {};
              const supplierAllowedSessionTypes = rootGetters['EditProfileStore/supplierAllowedSessionTypes'].map((type) => type.name) || [];
              const {languageFrom, languageTo} = rootState.PostingStore.LanguagesStore;
              const currentAssignmentType = rootState.PostingStore.AssignmentTypeStore.assignmentType;
              const skills = profileData.skills || [];
              const supplierQualification = skills
                .find((skill) => skill.langFromId == languageFrom && skill.langToId == languageTo)?.qualificationId;
              if (supplierQualification) commit('PostingStore/QualificationStore/setQualification', supplierQualification, {root: true});
              dispatch('PostingStore/AssignmentTypeStore/setAllowedAssignmentTypes', supplierAllowedSessionTypes, {root: true});
              if (!supplierAllowedSessionTypes.includes(currentAssignmentType)) dispatch('preselectAssignmentType');
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      preselectAssignmentType({dispatch, commit, rootState, rootGetters}) {
        const defaultSessionType = rootGetters['UserInfoStore/userDefaultSessionType'];
        const allowedSessionTypes = rootState.PostingStore.AssignmentTypeStore.allowedAssignmentTypes;
        let preselectSessionType = '';

        if (allowedSessionTypes.includes(defaultSessionType)) {
          preselectSessionType = defaultSessionType;
        } else if (allowedSessionTypes.length) {
          preselectSessionType = allowedSessionTypes[0];
        }
        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/AssignmentTypeStore/setAssignmentType',
          payload: preselectSessionType
        }, {root: true});
        dispatch('preselectAutoApprove');
      },
      preselectGender({commit, rootGetters}) {
        const defaultInterpreterGender = rootGetters['UserInfoStore/userDefaultInterpreterGender'];
        if (defaultInterpreterGender || defaultInterpreterGender === 0) {
          commit('PostingStore/GenderStore/setDefaultGender', defaultInterpreterGender, {root: true});
        }
      },
      preselectDepartment({dispatch, commit, rootState, rootGetters}, {headers = {}} = {}) {
        return new Promise((resolve) => {
          const {departmentsList} = rootState.PostingStore.DepartmentAttendersStore;
          const bookerData = rootGetters['PostingStore/BookerStore/bookerData'] || {};
          const isInPerson = rootState.PostingStore.AssignmentTypeStore.assignmentType === 'in_person';

          if (departmentsList.length) {
            const departmentId = bookerData.departments && bookerData.departments.length ? bookerData.departments[0].id : '';
            commit('executeNotTrackedMutation', {
              mutationName: 'PostingStore/DepartmentAttendersStore/setDepartment',
              payload: departmentId
            }, {root: true});
            dispatch('departmentChanged', {headers})
              .then(() => {
                resolve();
              });
          } else {
            return Promise.all([
              dispatch('preselectPaymentMethod'),
              dispatch('refetchAttenders', {headers}),
              dispatch('PostingStore/SubjectStore/getCategories', null, {root: true}),
              isInPerson ? dispatch('refetchContactsInfo') : null,
            ])
              .then(() => {
                resolve();
              });
          }
        });
      },
      refetchAttenders({dispatch, commit, rootState}, {headers}) {
        return new Promise((resolve, reject) => {
          const {department} = rootState.PostingStore.DepartmentAttendersStore;
          const {booker} = rootState.PostingStore.BookerStore;

          dispatch('PostingStore/DepartmentAttendersStore/fetchAttenders', {department, headers}, {root: true})
            .then(() => {
              const {attendersList} = rootState.PostingStore.DepartmentAttendersStore;
              const res = attendersList.length && attendersList.find((attender) => attender.uid === booker) ? [booker] : [];

              commit('executeNotTrackedMutation', {
                mutationName: 'PostingStore/DepartmentAttendersStore/setAttenders',
                payload: res
              }, {root: true});

              resolve();
            }).catch((error) => {
              reject(error);
            });
        });
      },
      refetchContactsInfo({rootState, dispatch}, {headers}) {
        const {department} = rootState.PostingStore.DepartmentAttendersStore;
        return new Promise((resolve, reject) => {
          dispatch('PostingStore/AssignmentTypeStore/getPostingSavedContactInfo', {departmentId: department, headers}, {root: true}).then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      departmentChanged({state, dispatch, rootState}, {headers = {}} = {}) {
        return new Promise((resolve) => {
          let promiseArr = [
            dispatch('preselectPaymentMethod'),
            dispatch('preselectBookingReferences'),
            dispatch('refetchCategories', {headers})
          ];
          const isInPerson = rootState.PostingStore.AssignmentTypeStore.assignmentType === 'in_person';

          if (!state.isOnTranslation) {
            promiseArr = [...promiseArr,
              isInPerson ? dispatch('refetchContactsInfo', {headers}) : null,
              dispatch('refetchAttenders', {headers})];
              dispatch('preselectProjectDefaultAccessLevel');
          }

          return Promise.all(promiseArr)
            .then(() => {
              resolve();
            });
        });
      },
      checkCopiedPaymentMethod({rootGetters, rootState, dispatch}) {
        const currentPaymentMethods = rootGetters['PostingStore/PaymentMethodStore/currentPaymentMethods'];
        if (currentPaymentMethods.length) {
          const currentPaymentMethod = currentPaymentMethods.find((payment) => {
            return payment.id == rootState.PostingStore.PaymentMethodStore.paymentMethod;
          });
          if (!currentPaymentMethod) {
            dispatch('preselectPaymentMethod');
          }
        }
      },
      preselectPaymentMethod({rootGetters, commit}) {
        const currentPaymentMethods = rootGetters['PostingStore/PaymentMethodStore/currentPaymentMethods'];
        if (currentPaymentMethods.length) {
          const currentDepartment = rootGetters['PostingStore/DepartmentAttendersStore/currentDepartment'];
          const defaultEnterprisePaymentMethod = currentPaymentMethods.find((payment) => payment.default);
          const defaultPaymentMethod = currentDepartment?.paymentMethod?.id || defaultEnterprisePaymentMethod?.id || '';
          commit('executeNotTrackedMutation', {
            mutationName: 'PostingStore/PaymentMethodStore/setPaymentMethod',
            payload: defaultPaymentMethod
          }, {root: true});
        }
      },
      preselectContactPersonInfo({rootGetters, commit}) {
        const employee = rootGetters['UserInfoStore/userIsEnterpriseMember']
          ? rootGetters['PostingStore/BookerStore/bookerData'] || {}
          : rootGetters['UserInfoStore/mainUserInfo'];
        const fullName = `${employee.firstName || ''} ${employee.lastName || ''}`.trim();
        const phoneCode = `${employee.phoneCode || employee.phone?.code || ''}`;
        const phoneNumber = `${employee.phoneNumber || employee.phone?.number || ''}`;
        const phone = `${phoneCode}${phoneNumber}`.trim();

        commit('PostingStore/AssignmentTypeStore/setContactPerson', fullName, {root: true});
        commit('PostingStore/AssignmentTypeStore/setContactPhone', phone, {root: true});
      },
      preselectBookingReferences({getters, commit, rootGetters}) {
        const bookerData = rootGetters['PostingStore/BookerStore/bookerData'] || {};
        const departmentData = rootGetters['PostingStore/DepartmentAttendersStore/currentDepartment'] || {};
        const enterpriseSetting = rootGetters['UserInfoStore/enterpriseSettings'] || {};

        if (getters.showPaymentBookingReferenceField) {
          const bookerPaymentBookingRef = bookerData?.paymentBookingReference || '';
          const departmentPaymentBookingRef = departmentData?.defaultPaymentBookingReference || '';
          const enterprisePaymentBookingRef = enterpriseSetting?.defaultPaymentBookingReference || '';

          let paymentBookingRef = '';
          let fromPaymentBookingRef = '';
          if (bookerPaymentBookingRef) {
            paymentBookingRef = bookerPaymentBookingRef;
            fromPaymentBookingRef = 'owner';
          } else if (departmentPaymentBookingRef) {
            paymentBookingRef = departmentPaymentBookingRef;
            fromPaymentBookingRef = 'department';
          } else if (enterprisePaymentBookingRef) {
            paymentBookingRef = enterprisePaymentBookingRef;
            fromPaymentBookingRef = 'enterprise';
          }
          commit('executeNotTrackedMutation', {
            mutationName: 'PostingStore/BookingRefStore/setPaymentBookingReference',
            payload: paymentBookingRef || ''
          }, {root: true});
          commit('executeNotTrackedMutation', {
            mutationName: 'PostingStore/BookingRefStore/setFromPaymentBookingReference',
            payload: fromPaymentBookingRef
          }, {root: true});
          commit('executeNotTrackedMutation', {
            mutationName: 'PostingStore/BookingRefStore/setInitialPaymentBookingReference',
            payload: paymentBookingRef || ''
          }, {root: true});
        }
        if (getters.showBookingReferenceField) {
          const bookerBookingRef = bookerData?.bookingReference || '';
          const departmentBookingRef = departmentData?.defaultBookingReference || '';
          const enterpriseBookingRef = enterpriseSetting?.defaultBookingReference || '';

          let bookingReference = '';
          let fromBookingRef = '';
          if (bookerBookingRef) {
            bookingReference = bookerBookingRef;
            fromBookingRef = 'owner';
          } else if (departmentBookingRef) {
            bookingReference = departmentBookingRef;
            fromBookingRef = 'department';
          } else if (enterpriseBookingRef) {
            bookingReference = enterpriseBookingRef;
            fromBookingRef = 'enterprise';
          }
          commit('executeNotTrackedMutation', {
            mutationName: 'PostingStore/BookingRefStore/setBookingReference',
            payload: bookingReference || ''
          }, {root: true});
          commit('executeNotTrackedMutation', {
            mutationName: 'PostingStore/BookingRefStore/setFromBookingReference',
            payload: fromBookingRef
          }, {root: true});
          commit('executeNotTrackedMutation', {
            mutationName: 'PostingStore/BookingRefStore/setInitialBookingReference',
            payload: bookingReference || ''
          }, {root: true});
        }
      },
      employeesFetched({commit, dispatch, rootGetters, rootState}) {
        return new Promise((resolve) => {
          if (!rootState.PostInterpretationStore.copiedJob) {
            commit('executeNotTrackedMutation', {
              mutationName: 'PostingStore/BookerStore/setBooker',
              payload: rootGetters['UserInfoStore/userUid']
            }, {root: true});
            dispatch('bookerChanged')
              .then(() => {
                resolve();
              });
            return;
          }
          resolve();
        });
      },
      recalculateFinishTime({commit, rootState, rootGetters}) {
        const newVal = rootState.PostingStore.DateTimeStore.startTime;
        const duration = rootGetters['UserInfoStore/defaultJobDuration'];
        const expectedFinishTime = moment(newVal)
          .add(duration, 'minutes')
          .format('HH:mm');
        const expectedFinishDate = moment(newVal)
          .add(duration, 'minutes')
          .format('YYYY-MM-DD');
        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/DateTimeStore/setAssignmentFinishDate',
          payload: expectedFinishDate
        }, {root: true});
        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/DateTimeStore/setFinishTime',
          payload: expectedFinishTime == '00:00' ? '23:59' : expectedFinishTime
        }, {root: true});
      },
      bookerChanged({dispatch, commit}, {headers = {}} = {}) {
        return new Promise((resolve) => {
          const promisesArr = [];

          commit('PostingStore/OwnerRealNameStore/setOwnerRealName', '', {root: true});
          promisesArr.push(dispatch('preselectBookingReferences'));
          promisesArr.push(dispatch('preselectContactPersonInfo'));
          promisesArr.push(dispatch('PostingStore/DepartmentAttendersStore/fetchDepartments', null, {root: true}));
          Promise.all(promisesArr)
            .then(() => {
              dispatch('preselectDepartment', {headers})
                .then(() => {
                  resolve();
                });
            });
        });
      },
      langFromChanged({dispatch, rootGetters}) {
        const suitableSupplierSkill = rootGetters['PostingStore/DirectBookingStore/getSuitableSupplierSkill'];
        if (!suitableSupplierSkill) {
          dispatch('PostingStore/DirectBookingStore/changeDirectBookingWarning', 'langFrom', {root: true});
          dispatch('clearSupplier');
        }
        dispatch('languageChanged');
      },
      langToChanged({dispatch, rootGetters}) {
        const suitableSupplierSkill = rootGetters['PostingStore/DirectBookingStore/getSuitableSupplierSkill'];
        if (!suitableSupplierSkill) {
          dispatch('PostingStore/DirectBookingStore/changeDirectBookingWarning', 'langTo', {root: true});
          dispatch('clearSupplier');
        }
        dispatch('languageChanged');
      },
      languageChanged({rootState, dispatch, commit}) {
        const {languageFrom, languageTo} = rootState.PostingStore.LanguagesStore;
        commit('PostingStore/QualificationStore/setQualificationPresenceList', '', {root: true});
        if (languageFrom == 3 && languageTo) {
          dispatch('PostingStore/QualificationStore/getQualificationsPresence', languageTo, {root: true});
        }
      },
      genderChanged({dispatch, rootGetters}, gender) {
        const selectedSupplier = rootGetters['PostingStore/DirectBookingStore/getSelectedSupplierData'];
        if (selectedSupplier.sex != gender) {
          dispatch('PostingStore/DirectBookingStore/changeDirectBookingWarning', 'gender', {root: true});
          dispatch('clearSupplier');
        }
      },
      qualificationChanged({dispatch, rootGetters}) {
        const suitableSupplierSkill = rootGetters['PostingStore/DirectBookingStore/getSuitableSupplierSkill'];
        if (!suitableSupplierSkill) {
          dispatch('PostingStore/DirectBookingStore/changeDirectBookingWarning', 'qualification', {root: true});
          dispatch('clearSupplier');
        }
      },
      directBookingStateChanged({dispatch, commit}) {
        commit('PostingStore/DateTimeStore/resetCustomDuration', null, {root: true});
        commit('PostInterpretationStore/setInterpreterUid', '', {root: true});
        commit('PostInterpretationStore/setDirectAward', false, {root: true});
        commit('EditProfileStore/clearStore', null, {root: true});
        dispatch('clearSupplier');
      },
      assignmentDateChanged({dispatch, rootState}) {
        if (rootState.PostingStore.DirectBookingStore.selectedSupplier) {
          dispatch('PostingStore/DirectBookingStore/fetchAvailabilityEvents', null, {root: true});
        }
      },
      clearSupplier({dispatch, commit}) {
        commit('PostingStore/DirectBookingStore/clearSupplierSearchResults', null, {root: true});
        commit('PostingStore/ProcessRequirementStore/setDirectProcessing', '', {root: true});
        dispatch('PostingStore/DirectBookingStore/changeSupplier', '', {root: true});
      },
      directSupplierChanged({dispatch, commit}, supplierUid) {
        return new Promise((resolve, reject) => {
          const promisesArr = [];

          commit('PostInterpretationStore/setInterpreterUid', supplierUid, {root: true});
          commit('PostInterpretationStore/setDirectAward', false, {root: true});
          if (supplierUid) {
            promisesArr.push(dispatch('PostingStore/DirectBookingStore/fetchAvailabilityEvents', null, {root: true}));
            promisesArr.push(dispatch('getInterpreterProfile', {id: supplierUid})
              .then(() => {
                commit('PostingStore/DirectBookingStore/setDirectBookingWarning', null, {root: true});
              }));
          } else {
            promisesArr.push(dispatch('PostingStore/AssignmentTypeStore/setAllowedAssignmentTypes', null, {root: true}));
          }
          Promise.all(promisesArr)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      preselectDirectInterpreter({commit, dispatch}, supplierUid) {
        commit('PostingStore/DirectBookingStore/setDirectInterpreter', true, {root: true});
        dispatch('preselectDirectBooking', supplierUid);
      },
      preselectDirectBooking({state, commit, dispatch, rootState}, supplierUid) {
        return new Promise((resolve, reject) => {
          commit('PostingStore/DirectBookingStore/setDirectBooking', true, {root: true});
          commit('PostingStore/DirectBookingStore/setSupplier', supplierUid, {root: true});
          dispatch('directSupplierChanged', supplierUid)
            .then(() => {
              const supplierName = rootState.EditProfileStore.profileData?.person?.firstName || '';
              if (state.searchDirectSupplierByName) {
                dispatch('PostingStore/DirectBookingStore/searchSupplierByName', supplierName, {root: true})
                  .then(() => {
                    resolve();
                  })
                  .catch((error) => {
                    reject(error);
                  });
              } else { resolve(); }
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      setFirstSavedInPersonInfo({rootState, commit}) {
        const addresses = rootState.PostingStore.AssignmentTypeStore.contactAddresses || [];
        const contactAddressChosen = rootState.FullAddressStore.city;

        if (addresses[0] && !contactAddressChosen) commit('PostingStore/AssignmentTypeStore/setAddressID', addresses[0].id, {root: true});
      },
      fetchDataForBuyerEdit({dispatch, rootState}) {
        return new Promise((resolve, reject) => {
          const promisesArr = [];
          const {selectedSupplier} = rootState.PostingStore.DirectBookingStore;
          const {assignmentType} = rootState.PostingStore.AssignmentTypeStore;

          promisesArr.push(dispatch('languageChanged'));
          if (!rootState.PostingStore.AssignmentTypeStore.contactAddresses && assignmentType === 'in_person') {
            promisesArr.push(dispatch('PostingStore/AssignmentTypeStore/getPostingSavedContactInfo', null, {root: true}));
          }
          if (!rootState.selectsData.qualification || !rootState.selectsData.qualification.length) {
            promisesArr.push(dispatch('fetchSelectList', 'qualification', {root: true}));
          }
          if (selectedSupplier) {
            promisesArr.push(dispatch('preselectDirectBooking', selectedSupplier));
          }
          Promise.all(promisesArr).then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      fetchMainDataForBuyer({dispatch, rootState}) {
        return new Promise((resolve, reject) => {
          const promisesArr = [];
          const {assignmentType} = rootState.PostingStore.AssignmentTypeStore;

          if (!rootState.selectsData.qualification || !rootState.selectsData.qualification.length) {
            promisesArr.push(dispatch('fetchSelectList', 'qualification', {root: true}));
          }
          if (!rootState.PostingStore.PaymentMethodStore.allPaymentMethods) {
            promisesArr.push(dispatch('PostingStore/PaymentMethodStore/getPayments', null, {root: true}));
          }
          if (!rootState.PostingStore.AssignmentTypeStore.contactAddresses && assignmentType === 'in_person') {
            promisesArr.push(dispatch('PostingStore/AssignmentTypeStore/getPostingSavedContactInfo', null, {root: true}));
          }
          Promise.all(promisesArr).then(() => {
            dispatch('preselectPaymentMethod');
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      fetchMainData({rootGetters, dispatch}) {
        if (rootGetters['UserInfoStore/userIsEnterpriseMember']) {
          return dispatch('fetchMainDataForEnterprise');
        } else {
         return dispatch('fetchMainDataForBuyer');
        }
      },
      fetchMainDataForEnterprise({rootState, dispatch}) {
        return new Promise((resolve, reject) => {
          let promisesArr = '';

          if (!rootState.EnterpriseStore.employees) {
            promisesArr = [...promisesArr, dispatch('PostingStore/BookerStore/getEmployees', null, {root: true})];
          }
          if (!rootState.selectsData.qualification || !rootState.selectsData.qualification.length) {
            promisesArr = [...promisesArr, dispatch('fetchSelectList', 'qualification', {root: true})];
          }
          if (!rootState.PostingStore.PaymentMethodStore.allPaymentMethods) {
            promisesArr = [...promisesArr, dispatch('PostingStore/PaymentMethodStore/getPayments', null, {root: true})];
          }

          if (promisesArr) {
            Promise.all(promisesArr)
              .then(() => {
                dispatch('employeesFetched')
                  .then(() => {
                    resolve();
                  });
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve();
          }
        });
      },
      fetchDataForInvoiceInformationSectionEdit({rootState, dispatch}) {
        return new Promise((resolve, reject) => {
          const promisesArr = [];

          if (!rootState.PostingStore.PaymentMethodStore.allPaymentMethods) {
            promisesArr.push(dispatch('PostingStore/PaymentMethodStore/getPayments', null, {root: true}));
          }
          Promise.all(promisesArr)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      fetchDataForAdminToolSectionEdit({rootState, dispatch}) {
        return new Promise((resolve, reject) => {
          const promisesArr = [];

          if (rootState.TTAuthStore.godmode && Object.keys(rootState.PricingTemplatesStore.demanderTemplates).length === 0) {
            promisesArr.push(dispatch('PricingTemplatesStore/getTemplates', 'demander', {root: true}));
          }
          if (rootState.TTAuthStore.godmode && Object.keys(rootState.PricingTemplatesStore.supplierTemplates).length === 0) {
            promisesArr.push(dispatch('PricingTemplatesStore/getTemplates', 'supplier', {root: true}));
          }
          Promise.all(promisesArr)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      fetchDataForPersonalInformationSectionEdit({state, rootState, dispatch, rootGetters}, preSelectDirect = false) {
        return new Promise((resolve, reject) => {
          const promisesArr = [];
          const {department} = rootState.PostingStore.DepartmentAttendersStore;
          const {selectedSupplier} = rootState.PostingStore.DirectBookingStore;

          if (rootGetters['UserInfoStore/userIsEnterpriseMember']) {
            if (rootState.PostingStore.BookerStore.booker && !rootState.EnterpriseStore.employees) {
              promisesArr.push(dispatch('PostingStore/BookerStore/getEmployees', null, {root: true}));
            }
            if (!rootState.PostingStore.DepartmentAttendersStore.attendersList) {
              promisesArr.push(dispatch('PostingStore/DepartmentAttendersStore/fetchAttenders', {department}, {root: true}));
            }
            if (department) {
              if (!rootState.PostingStore.DepartmentAttendersStore.departmentsList) {
                promisesArr.push(dispatch('PostingStore/DepartmentAttendersStore/fetchDepartments', null, {root: true}));
              }
            }
          }
          if (preSelectDirect && selectedSupplier) {
            state.searchDirectSupplierByName = false;
            promisesArr.push(dispatch('preselectDirectBooking', selectedSupplier));
          }
          Promise.all(promisesArr)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      fetchDataForMainAssignmentInformationSectionEdit({rootState, dispatch}, headers) {
        return new Promise((resolve, reject) => {
          const promisesArr = [];
          const {department} = rootState.PostingStore.DepartmentAttendersStore;

          dispatch('languageChanged');
          if (!rootState.selectsData.qualification || !rootState.selectsData.qualification.length) {
            promisesArr.push(dispatch('fetchSelectList', 'qualification', {root: true}));
          }
          if (!rootState.PostingStore.AssignmentTypeStore.contactAddresses) {
            promisesArr.push(dispatch('PostingStore/AssignmentTypeStore/getPostingSavedContactInfo', {
              departmentId: department || null,
              headers
          }, {root: true}));
          }
          if (department) {
            if (!rootState.PostingStore.SubjectStore.categoriesList || !rootState.PostingStore.SubjectStore.categoriesList.length) {
              promisesArr.push(dispatch('PostingStore/SubjectStore/getCategories', department || '', {root: true}));
            }
          }
          Promise.all(promisesArr)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      fetchDataForEdit({dispatch}) {
        return new Promise((resolve, reject) => {
          const promisesArr = [];

          promisesArr.push(dispatch('fetchDataForPersonalInformationSectionEdit', true));
          promisesArr.push(dispatch('fetchDataForMainAssignmentInformationSectionEdit'));
          promisesArr.push(dispatch('fetchDataForInvoiceInformationSectionEdit'));
          promisesArr.push(dispatch('fetchDataForAdminToolSectionEdit'));
          Promise.all(promisesArr)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      setIsTranslation({commit}, value) {
        commit('setIsOnTranslation', value);
      }
    },
    mutations: {
      setIsOnTranslation(state, value) {
        state.isOnTranslation = value;
      },
      clearStore(state) {
        state.isOnTranslation = false;
        state.searchDirectSupplierByName = true;
      }
    }
  };
};
