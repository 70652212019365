// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/close_x_icon_blue.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".sk-mobile-date-picker {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  width: 100%;\n  height: 100%;\n  min-height: 35px;\n}\n.sk-mobile-date-picker .sk-btn {\n  width: 80%;\n  margin: 0 auto;\n}\n.sk-mobile-date-picker__calendar {\n  margin-bottom: 40px;\n}\n.sk-mobile-date-picker__header {\n  position: relative;\n  display: block;\n  -ms-flex-negative: 0;\n      flex-shrink: 0;\n  width: 100%;\n  height: 35px;\n  padding: 12px 0;\n  border-bottom: solid 1px #e9ebef;\n  text-align: center;\n}\n.sk-mobile-date-picker__close-btn {\n  position: absolute;\n  top: 0;\n  right: 0;\n  display: block;\n  width: 35px;\n  height: 35px;\n  border-radius: 50%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 20px auto;\n  background-repeat: no-repeat;\n}\n.sk-mobile-date-picker__close-btn:hover {\n  background-color: #f0ecf6;\n}\n.sk-mobile-date-picker__close-btn:active {\n  background-color: #e1d7eb;\n}\n", ""]);
// Exports
module.exports = exports;
