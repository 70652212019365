(<template>
  <section :class="{'in-progress': modalProgress}"
           class="upgrade-buyer__main-wrapper is-progress-bar">
    <basic-customer-info class="upgrade-buyer__basic-info" />
    <div class="upgrade-buyer__enterprises-row">
      <autocomplete v-model="searchTerm"
                    class="upgrade-buyer__enterprises"
                    :description="enterpriseText"
                    :placeholder="organizationPlaceholder"
                    :suggestions="suggestions"
                    :validation-name="enterpriseErrorsName"
                    @search="searchEnterprise"
                    @suggestionselected="catchSuggestion" />
    </div>
    <!-----  Existing Enterprise ----->
    <div v-if="selectedSuggestion && !isNewEnterprise"
         :class="{'in-progress': departmentProgress}"
         class="upgrade-buyer__departments-row is-progress-bar">
      <sk-multiselect :items-list="departmentsList"
                      :preselected-value="departments"
                      :description="departmentText"
                      :default-value="departmentPlaceholder"
                      :validation-name="departmentErrorsName"
                      class="upgrade-buyer__departments"
                      @checkboxeschanged="catchDepartment" />
    </div>
    <!-----  New Enterprise ----->
    <template v-if="selectedSuggestion && isNewEnterprise">
      <sk-input :description="whitelistedText"
                :preselected-value="whitelistedDomain"
                :placeholder="whitelistedText"
                class="upgrade-buyer__whitelisted"
                :margin-bottom="false"
                @fieldvaluechanged="catchWhitelisted" />
    </template>
    <!-----  Warning Message ----->
    <sk-note class="upgrade-buyer__move-warning"
             type="danger"
             :text="moveWarningText" />

    <!-----  Modal Actions ----->
    <section class="upgrade-buyer__btns-wrapper">
      <button class="sk-btn sk-btn--white upgrade-buyer__btn"
              @click="closeModal">{{ $gettext('Cancel') }}</button>
      <button class="sk-btn sk-btn--default upgrade-buyer__btn"
              @click="validateForm">{{ submitText }}</button>
    </section>
  </section>
</template>)

<script>
  import Autocomplete from '@/components/shared_components/Autocomplete';
  import BasicCustomerInfo from '@/components/customers/modals/BasicCustomerInfo';
  import {mapState} from 'vuex';

  export default {
    components: {
      autocomplete: Autocomplete,
      'basic-customer-info': BasicCustomerInfo,
    },
    data() {
      return {
        departments: '',
        searchTerm: '',
        suggestions: {},
        selectedSuggestion: '',
        whitelistedDomain: '',
        whitelistedDomainErrorsName: 'whitelistedDomainError',
        whitelistedDomainErrors: [],
        departmentErrorsName: 'departmentError',
        departmentErrors: [],
        enterpriseErrorsName: 'enterpriseError',
        enterpriseErrors: [],
        departmentProgress: false,
        modalProgress: false,
      };
    },
    computed: {
      ...mapState('AllCustomersStore', {
        selectedCustomer: (state) => state.selectedCustomer || {}
      }),
      ...mapState('EnterpriseStore', {
        departmentsList: ({departments}) => departments || []
      }),
      isNewEnterprise() {
        if (this.selectedSuggestion) return this.selectedSuggestion?.id == null || false;
        else return false;
      },
      enterpriseText() { return this.$gettext('Enterprise*'); },
      departmentText() { return this.$gettext('Department*'); },
      departmentPlaceholder() { return this.$gettext('Select department'); },
      organizationPlaceholder() { return this.$gettext('Search by name or org number'); },
      moveWarningText() { return this.$gettext('This will move all private jobs to selected enterprise. You can\'t undo this action.'); },
      submitText() {
        if (this.isNewEnterprise) return this.$gettext('Upgrade');
        else if (!this.isNewEnterprise && this.selectedSuggestion) return this.$gettext('Convert');
        else return this.$gettext('Next');
      },
      whitelistedText() { return this.$gettext('Whitelisted domain'); }
    },
    methods: {
      validateForm() {
        let isValidForm = true;

        this.enterpriseErrors = [];
        this.departmentErrors = [];
        this.whitelistedDomainErrors = [];
        this.$store.commit('ModalStore/removeModalErrors');

        if (!this.selectedSuggestion) {
          isValidForm = false;
          this.enterpriseErrors.push(this.$gettext('Enterprise is required'));
        }

        if (!this.isNewEnterprise) {
          if (!this.departments.length > 0) {
            isValidForm = false;
            this.departmentErrors.push(this.$gettext('Department is required'));
          }
        }

        this.$store.commit('ErrorsStore/setError', {name: this.enterpriseErrorsName, errors: this.enterpriseErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.departmentErrorsName, errors: this.departmentErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.whitelistedDomainErrorsName, errors: this.whitelistedDomainErrors});

        if (isValidForm) {
          this.submitForm();
        }
      },
      submitForm() {
        let params = {};
        const actionName = this.isNewEnterprise ? 'upgradeBuyerToEnterprise' : 'convertBuyerToEnterprise';
        if (this.isNewEnterprise) {
          params = {
            enterprise: {
              owner_person_uid: this.selectedCustomer.uid,
              name: this.selectedSuggestion.name,
              org_number: parseInt(this.selectedSuggestion?.orgNumber)
            }
          };
          if (this.whitelistedDomain) params.enterprise.whitelisted_domain = this.whitelistedDomain;
        } else {
          params = {
            person_uid: this.selectedCustomer.uid,
            enterprise_id: this.selectedSuggestion.id,
            department_ids: this.departments,
          };
        }
        this.modalProgress = true;
        this.$store.dispatch(`AllCustomersStore/${actionName}`, params)
          .then(() => {
            this.modalProgress = false;
            this.closeModal();
            this.$store.commit('AllCustomersStore/refreshCustomerGrid');
            this.isNewEnterprise
              ? this.showToast(this.$gettext('Customer upgrading is in progress'))
              : this.showToast(this.$gettext('Customer converting is in progress'));
          }).catch((error) => {
            this.modalProgress = false;
            let message = this.$gettext('Something went wrong please try again');
            if (error?.data?.errors.includes('same enterprises')) message = this.$gettext('Can\'t upgrade to the same enterprise');
            this.showToast(message);
          });
      },
      catchSuggestion(value) {
        this.selectedSuggestion = value;
        // get enterprise departments
        if (this.selectedSuggestion.id) this.fetchEnterpriseDepartments();
      },
      searchEnterprise(term) {
        return this.$store.dispatch('EnterpriseStore/searchEnterprises', {query: term}).then((response) => {
          this.suggestions = response ? {
            enterprises: response.enterprises?.slice(0, 5) || [],
            brregSearch: response.brregSearch?.slice(0, 5) || []
          } : {};
        });
      },
      fetchEnterpriseDepartments() {
        const params = {
          enterpriseId: this.selectedSuggestion.id
        };
        this.departmentProgress = true;
        this.$store.dispatch('EnterpriseStore/getDepartments', params)
          .then(() => {
            this.departmentProgress = false;
          })
          .catch(() => {
            this.departmentProgress = false;
          });
      },
      showToast(message) {
        this.$store.commit('ToastStore/setToast', {
          data: {
            position: 'bottom-center',
            message: message,
            autoDeleteTime: 6000
          }
        });
      },
      catchDepartment(value) {
        this.departments = value;
      },
      catchWhitelisted(value) {
        this.whitelistedDomain = value;
      },
      closeModal() {
        this.$emit('closemodal');
      },
      clearErrors() {
        this.$store.commit('ModalStore/removeModalErrors');
        this.$store.commit('ErrorsStore/setError', {name: this.enterpriseErrorsName, errors: []});
        this.$store.commit('ErrorsStore/setError', {name: this.departmentErrorsName, errors: []});
        this.$store.commit('ErrorsStore/setError', {name: this.whitelistedDomainErrorsName, errors: []});
      }
    },
    beforeDestroy() {
      this.clearErrors();
      this.$store.commit('AllCustomersStore/clearSelectedCustomer');
    }
  };
</script>
<style scoped>
.upgrade-buyer__main-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 35px 30px;
}

.upgrade-buyer__basic-info {
  width: 100%;
}

.upgrade-buyer__departments-row {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.upgrade-buyer__enterprises-row {
  display: flex;
  width: 100%;
}

.upgrade-buyer__departments,
.upgrade-buyer__enterprises,
.upgrade-buyer__move-warning {
  width: 100%;
}

.upgrade-buyer__move-warning,
.upgrade-buyer__whitelisted {
  margin-top: 20px;
  margin-bottom: 0;
}

.upgrade-buyer__btns-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}

.upgrade-buyer__btn {
  width: auto;
  margin: 20px 5px 0;
  padding: 0 20px;
}

</style>
