(<template>
  <section :class="{'in-person-form--open': isOpenPage}"
           class="in-person-form">
    <div class="in-person-form__row in-person-form__row--inputs">
      <sk-input :description="contactPersonText"
                :placeholder="contactPersonPlaceholder"
                :preselected-value="contactPersonName"
                :validation-name="personNameValidation"
                class="in-person-form__field in-person-form__field--small"
                @fieldvaluechanged="setContactPerson" />
      <sk-input :description="contactPhoneText"
                :placeholder="contactPhonePlaceholder"
                :preselected-value="contactPersonPhone"
                :validation-name="personPhoneValidation"
                :type="'tel'"
                :reg-exp="/[^0-9+]/g"
                :mob-numeric-keyboard="true"
                class="in-person-form__field in-person-form__field--small"
                @fieldvaluechanged="setContactPhone" />
    </div>
    <div v-show="!isAddressSelectedFromList">
      <div class="in-person-form__address">
        <full-address ref="addressComponent"
                      :description="addressText"
                      :disabled="disabledAddress" />
        <sk-checkbox v-if="!isOpenPage"
                     :item-text="saveLocationText"
                     :preselected-value="saveContactAddress"
                     class="in-person-form__field"
                     @checkboxvaluechanged="setAddressSaving" />
      </div>
      <sk-textarea :description="wayDescriptionText"
                   :placeholder="wayDescriptionPlaceholder"
                   :preselected-value="contactWayDescription"
                   class="in-person-form__field"
                   @fieldvaluechanged="setAddressExtra" />
    </div>
    <div v-show="!isOpenPage && isAddressSelectedFromList"
         class="in-person-form__row">
      <sk-select :items-list="computedAddressList"
                 :description="locationsSelectText"
                 :default-value="locationsSelectText"
                 :preselected-value="contactAddressChosenID"
                 class="in-person-form__field"
                 @csvaluechanged="setAddressID" />
      <button class="sk-btn sk-btn--white in-person-form__btn"
              type="button"
              @click="addNewAddressClick">{{ $gettext('Add new address') }}</button>
    </div>
  </section>
</template>)

<script>
  import {mapGetters, mapState, mapMutations} from 'vuex';
  import FullAddress from '@/components/shared_components/FullAddress';
  import helpers from '@/helpers';

  export default {
    components: {
      'full-address': FullAddress
    },
    props: {
      isOpenPage: Boolean,
      disabledAddress: Boolean
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsNotInterpreter'
      ]),
      ...mapGetters('PostingStore/AssignmentTypeStore', [
        'isAddressSelectedFromList'
      ]),
      ...mapState('PostingStore/AssignmentTypeStore', {
        // in person contact
        contactPersonName: (state) => state.contactAttributes.person.name,
        contactPersonPhone: (state) => state.contactAttributes.person.phone,
        // in person address
        savedContactAddresses: (state) => state.contactAddresses || [],
        saveContactAddress: (state) => state.contactAttributes.address.save_address,
        contactWayDescription: (state) => state.contactAttributes.address.extra,
        contactAddressChosenID: (state) => state.contactAttributes.address.id,
        // in person errors names
        personNameValidation: (state) => state.errors.personName.name,
        personPhoneValidation: (state) => state.errors.personPhone.name
      }),
      contactPersonText() { return this.$gettext('Contact person'); },
      contactPersonPlaceholder() { return this.$gettext('Name'); },
      contactPhoneText() { return this.$gettext('Contact phone number'); },
      contactPhonePlaceholder() { return this.$gettext('Phone number'); },
      saveLocationText() { return this.$gettext('Save this location for next time'); },
      wayDescriptionText() { return this.$gettext('Directions (optional)'); },
      wayDescriptionPlaceholder() { return this.$gettext('Write here...'); },
      locationsSelectText() { return this.$gettext('Saved locations'); },
      addressText() { return this.$gettext('Location'); },
      computedAddressList() {
        return this.savedContactAddresses.map((address) => {
          return {
            id: address.id,
            name: helpers.getJobInfo.savedAddressName(address)
          };
        });
      }
    },
    methods: {
      ...mapMutations('PostingStore/AssignmentTypeStore', [
        // in person contact
        'setContactPerson',
        'setContactPhone',
        // in person address
        'setAddressID',
        'resetAddressID',
        'setAddressExtra',
        'setAddressSaving'
      ]),
      addNewAddressClick() {
        this.resetAddressID();
      },
    }
  };
</script>

<style>
  .in-person-form .sk-input__input,
  .in-person-form .sk-input__textarea,
  .in-person-form .sk-select {
    background-color: #fff;
  }

  .in-person-form .sk-input__textarea {
    height: 80px;
  }
</style>

<style scoped>
  .in-person-form {
    display: block;
    width: 100%;
    padding: 20px 0 5px 0;
    text-align: left;
  }

  .in-person-form__row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
  }

  .in-person-form__row--inputs {
    align-items: flex-start;
  }

  .in-person-form__btn {
    flex-shrink: 0;
    width: 150px;
    margin-top: 20px;
    margin-left: 15px;
    background-color: #fff;
  }

  .in-person-form__field {
    flex-grow: 1;
    max-width: calc(100% - 165px);
  }

  .in-person-form__field--small {
    max-width: 200px;
    margin-right: 15px;
  }

  @media (max-width: 767px) {
    .in-person-form__row {
      display: block;
    }

    .in-person-form__field {
      max-width: 100%;
    }

    .in-person-form__btn {
      margin-left: 0;
    }

    .in-person-form__field--small {
      max-width: 100%;
    }
  }
</style>
