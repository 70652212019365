(<template>
  <div class="post-section">
    <section class="post-section__container">
      <div v-if="errors && errors.length"
           class="error-cont post-section__errors-cont">
        <p v-for="(error, index) in errors"
           :key="index"
           class="error-cont__error">{{ error }}</p>
      </div>
      <h2 v-if="title"
          class="post-section__title"
          v-html="title"></h2>
      <p v-if="description"
         class="post-section__description"
         v-html="description"></p>
      <slot></slot>
    </section>
  </div>
</template>)

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      errors: {
        type: Array,
        default: () => []
      }
    }
  };
</script>
