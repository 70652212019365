import assignmentsRoutes from './manager_assignments_routes';
import projectsRoutes from './manager_project_routes';
import customerRoutes from './manager_customers_routes';

export default {
  path: '/:lang/:country/m',
  meta: {
    requiresAuth: true,
    managerOnly: true
  },
  component: () => import('@/pages/shared_pages/sh_Root'),
  children: [
    {
      path: '/',
      redirect: {name: 'ManagerAllAssignments'}
    },
    {
      path: 'home',
      name: 'ManagerHome',
      component: () => import('@/pages/BuyerPages/b_Home')
    },
    {
      path: 'change_password',
      name: 'ManagerChangePassword',
      meta: {subPage: true},
      component: () => import('@/pages/BuyerPages/b_ChangePassword')
    },
    {
      path: 'profile/:id',
      name: 'ManagerUserProfile',
      meta: {subPage: true},
      component: () => import('@/pages/shared_pages/profile/sh_ProfileShow')
    },
    {
      path: 'profile/:id/edit',
      name: 'ManagerUserProfileEdit',
      meta: {
        currentUserOnly: true,
        subPage: true
      },
      component: () => import('@/pages/shared_pages/profile/sh_ProfileEdit')
    },
    {
      path: 'suppliers/all',
      name: 'ManagerAllSuppliers',
      meta: {subPage: true},
      component: () => import('@/pages/ManagerPages/m_AllSuppliers')
    },
    {
      path: 'interpretation_skill/all',
      name: 'ManagerAllInterpretationSkills',
      meta: {subPage: true},
      component: () => import('@/pages/ManagerPages/m_AllInterpretationSkills')
    },
    {
      path: 'translation_skill/all',
      name: 'ManagerAllTranslationSkills',
      meta: {subPage: true},
      component: () => import('@/pages/ManagerPages/m_AllTranslationSkills')
    },
    {
      path: 'enterprises/all',
      name: 'ManagerAllEnterprises',
      meta: {subPage: true},
      component: () => import('@/pages/ManagerPages/m_AllEnterprises')
    },
    {
      path: 'app_android',
      name: 'DownloadApkManager',
      component: () => import('@/pages/shared_pages/sh_DownloadApk')
    },
    assignmentsRoutes,
    projectsRoutes,
    customerRoutes
  ]
};
