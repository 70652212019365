(<template>
  <article class="cancel-modal">
    <div class="cancel-modal__checkboxes-cont">
      <p class="cancel-modal__notes"
         v-html="data.message"></p>
    </div>
    <div class="cancel-modal__btn-cont">
      <button class="sk-btn sk-btn--white cancel-modal__btn"
              @click="closeModal">{{ $gettext('Back') }}</button>
      <button class="sk-btn sk-btn--white cancel-modal__btn"
              @click="emitCancel">{{ $pgettext('translation', 'Confirm cancellation') }}</button>
    </div>
  </article>
</template>)

<script>

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      emitCancel() {
        const projectId = this.$store.state.OneProjectStore.project.id;
        this.$store.commit('OneProjectStore/startProjectProgress');
        this.$store.dispatch('OneProjectStore/changeStatus', {id: projectId, status: 'cancelled'}).then(() => {
          this.$store.commit('OneProjectStore/stopProjectProgress');
          this.$store.commit('OneProjectStore/setProjectStatus', 'cancelled');
        }).catch(() => {
          this.$store.commit('OneProjectStore/stopProjectProgress');
          this.$store.commit('ModalStore/setModal', {
            component: 'error-modal'
          });
        });
        this.closeModal();
      },
    },
    beforeDestroy() {
      this.$store.commit('ErrorsStore/removeAllErrors');
    }
  };
</script>

<style scoped>
.cancel-modal {
  display: block;
  width: 100%;
  padding: 20px 30px;
}

.cancel-modal__checkboxes-cont {
  display: block;
  width: 100%;
}

.cancel-modal__notes {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 15px;
}

.cancel-modal__btn-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 25px -5px 0;
}

.cancel-modal__btn {
  display: inline-block;
  width: auto;
  margin: 0 5px;
  padding: 0 10px;
}

@media (max-width: 400px) {
  .cancel-modal__btn-cont {
    flex-wrap: wrap;
  }

  .cancel-modal__btn {
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0;
  }
}
</style>
