(<template>
  <div class="events-description">
    <p v-for="type in eventsTypesList"
       :key="type"
       :class="[`events-description__type--${type}`]"
       class="events-description__type">{{ getTypeDescription(type) }}</p>
    <p v-if="eventsDescription"
       class="events-description__text">{{ eventsDescription }}</p>
  </div>
</template>)

<script>
  export default {
    props: {
      eventsList: {
        type: Array,
        required: true
      },
      eventsDescription: {
        type: String,
        default: ''
      },
      isSelectedEvent: Boolean
    },
    computed: {
      eventsTypesList() {
        const types = this.eventsList.map(([, eventType]) => eventType);
        const filteredTypes = types.filter((type) => !['travelToTime', 'travelFromTime'].includes(type));
        if (types.includes('travelToTime' || 'travelFromTime')) {
          filteredTypes.push('travelTime');
        }
        if (this.isSelectedEvent) {
          filteredTypes.unshift('selected');
        }
        return filteredTypes;
      }
    },
    methods: {
      getTypeDescription(type) {
        switch (type) {
          case 'selected':
            return this.$gettext('Your selected time');
          case 'event':
            return this.$gettext('Private event');
          case 'job':
            return this.$gettext('Not available');
          case 'offtime':
            return this.$gettext('Offtime');
          case 'travelTime':
            return this.$gettext('Travel time');
          case 'standby':
            return this.$gettext('Standby interpreter');
        }
      }
    }
  };
</script>

<style scoped>
  .events-description {
    font-size: 10px;
  }

  .events-description__type {
    position: relative;
    display: inline-block;
    margin-right: 15px;
  }

  .events-description__text {
    margin-top: 5px;
    font-weight: bold;
  }

  .events-description__type::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 5px;
    margin-right: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #fff;
  }

  .events-description__type--event::before {
    border: solid 1px #fd6;
    background-color: #ffecb0;
  }

  #app.salita-facelifted .events-description__type--event::before {
    background-color: #fffaed;
  }

  #app.salita-facelifted .events-description__type--selected::before {
    border-color: var(--color-success-700);
    background-color: var(--color-success-100);
  }

  #app.salita-facelifted .events-description__type--job::before {
    border: 1px solid #949ab0;
    background-color: #f4f5f7;
  }

  .events-description__type--selected::before,
  .events-description__type--standby::before {
    border: solid 1px #ff5b23;
    background-color: rgba(255, 91, 35, 0.2);
  }

  .events-description__type--job::before {
    border: 1px solid #3f43ad;
    background-color: #3f43ad;
  }

  .events-description__type--offtime::before {
    background-image: url(~@assets/imgs/undefined_imgs/striped-bg.svg);
    background-position: 50% 50%;
    background-size: auto 40px;
  }

  .events-description__type--travelTime::before {
    background-color: #e5e5e5;
  }
</style>
