(<template>
  <sk-input :preselected-value="preselectedValue"
            :description="description"
            :disabled="disabled"
            :autofocus="autofocus"
            :placeholder="placeholder"
            :validation-name="validationName"
            :icon="false"
            :class="[className]"
            :reg-exp="orgNumberRegExp"
            :max-length="maxLength"
            :mob-numeric-keyboard="allowOnlyDigitalOrgNumber"
            @fieldvaluechanged="setValue" />
</template>)

<script>
  export default {
    props: {
      description: {
        type: String,
        default: ''
      },
      validationName: {
        type: String,
        default: ''
      },
      preselectedValue: {
        type: [String, Number],
        required: true
      },
      placeholder: {
        type: String,
        default: ''
      },
      country: {
        type: String,
        default: ''
      },
      className: {
        type: String,
        default: ''
      },
      autofocus: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean
      },
      maxLength: {
        type: Number,
        default: 0
      }
    },
    computed: {
      allowOnlyDigitalOrgNumber() {
        return this.country == 'Norway';
      },
      orgNumberRegExp() {
        return this.allowOnlyDigitalOrgNumber ? /[^0-9]/g : null;
      }
    },
    watch: {
      orgNumberRegExp(newVal) {
        if (newVal) {
          this.setValue(String(this.preselectedValue).replace(newVal, ''));
        }
      }
    },
    methods: {
      setValue(val, id) {
        this.$emit('fieldvaluechanged', val, id);
      }
    }
  };
</script>
