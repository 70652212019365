// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/interpreters_icons/favourit-avatar-ico.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/interpreters_icons/block-avatar-ico.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".favourite-icon::before {\n  content: '';\n  position: absolute;\n  top: 0;\n  left: 0;\n  display: block;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 100%;\n  background-repeat: no-repeat;\n}\n.blocked-icon::before {\n  content: '';\n  position: absolute;\n  top: 0;\n  left: 0;\n  display: block;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-position: 50% 50%;\n  background-size: 100%;\n  background-repeat: no-repeat;\n}\n", ""]);
// Exports
module.exports = exports;
