const jobId = ({id, seriesOrderId, seriesSequenceNumber}) => {
  return seriesOrderId && seriesSequenceNumber ? `${seriesOrderId}-${seriesSequenceNumber}` : id;
};

const jobStatusObj = (context, jobStatus) => {
  const userIsInterpreter = context.$store.getters['UserInfoStore/userIsInterpreter'];
  switch (jobStatus) {
    case 'published':
      return {
        text: userIsInterpreter ? context.$gettext('Invited') : context.$gettext('Received'),
        class: 'assignment__dark-blue-status'
      };
    case 'applied':
      return {
        text: userIsInterpreter ? context.$gettext('Sent offer') : context.$gettext('Offer'),
        class: userIsInterpreter ? 'assignment__purple-status' : 'assignment__yellow-status'
      };
    case 'proactive':
      return {
        text: context.$gettext('Proactive'),
        class: 'assignment__dark-blue-status'
      };
    case 'invited':
    case 'withdrawn':
      return {
        text: userIsInterpreter ? context.$gettext('Invited') : '',
        class: 'assignment__dark-blue-status'
      };
    case 'accepted':
    case 'in_progress':
      return {
        text: context.$gettext('Confirmed'),
        class: 'assignment__green-status'
      };
    case 'cancelled':
      return {
        text: context.$gettext('Cancelled'),
        class: 'assignment__red-status'
      };
    case 'needsRate':
    case 'needsFeedback':
      return {
        text: context.$gettext('Feedback'),
        class: 'assignment__purple-status'
      };
    default:
      return {};
  }
};

const jobStatusObjChip = (context, jobStatus) => {
  switch (jobStatus) {
    case 'draft':
      return {
        text: context.$gettext('Draft'),
        class: 'grey'
      };
    case 'published':
      return {
        text: context.$gettext('Published'),
        class: 'white'
      };
    case 'applied':
      return {
        text: context.$gettext('Applied'),
        class: 'yellow'
      };
    case 'invited':
      return {
        text: context.$gettext('Invited'),
        class: 'blue'
      };
    case 'proactive':
      return {
        text: context.$gettext('Proactive'),
        class: 'blue'
      };
    case 'withdrawn':
      return {
        text: context.$gettext('Withdrawn'),
        class: 'blue'
      };
    case 'accepted':
      return {
        text: context.$gettext('Accepted'),
        class: 'green'
      };
    case 'in_progress':
      return {
        text: context.$gettext('In progress'),
        class: 'yellow'
      };
    case 'cancelled':
      return {
        text: context.$gettext('Cancelled'),
        class: 'red'
      };
    case 'needsRate':
    case 'needsFeedback':
      return {
        text: context.$gettext('Feedback'),
        class: 'purple'
      };
    case 'closed':
      return {
        text: context.$gettext('Closed'),
        class: 'grey'
      };
    case 'finished':
      return {
        text: context.$gettext('Finished'),
        class: 'blue'
      };
    case 'rejected':
      return {
        text: context.$gettext('Rejected'),
        class: 'green'
      };
    case 'unsuitable':
      return {
        text: context.$gettext('Unsuitable'),
        class: 'green'
      };
    default:
      return {};
  }
};

const extendedJobStatusObj = (context, jobStatus) => {
  const mainJobStatusObj = jobStatusObj(context, jobStatus);

  if (Object.keys(mainJobStatusObj).length === 0) {
   switch (jobStatus) {
     case 'closed':
       return {
         text: context.$gettext('The assignment start time has passed'),
         class: 'assignment__green-status'
       };
     case 'finished':
       return {
         text: context.$gettext('Finished'),
         class: 'assignment__green-status'
       };
     case 'rejected':
       return {
         text: context.$gettext('Your offer has been rejected'),
         class: 'assignment__green-status'
       };
     case 'unsuitable':
       return {
         text: context.$gettext('This assignment is no longer available'),
         class: 'assignment__green-status'
       };
     default:
       return {};
   }
  } else {
   return mainJobStatusObj;
  }
};

const titleJobStatusObj = ({context, jobStatus, cancellerName, cancelledAt, jobLocked, bookingMechanism}) => {
  const userIsInterpreter = context.$store.getters['UserInfoStore/userIsInterpreter'];
  switch (jobStatus) {
    case 'draft': {
      const template = bookingMechanism === 'price_check'
        ? context.$gettext('Price Check')
        : context.$gettext('Draft');
      return {
        text: template,
        class: 'assignment__dark-blue-status'
      };
    }
    case 'published': {
      const template = userIsInterpreter
        ? context.$gettext('Apply')
        : context.$gettext('Received');
      return {
        text: template,
        class: 'assignment__dark-blue-status'
      };
    }
    case 'proactive': {
      const template = userIsInterpreter
        ? context.$gettext('Proactive')
        : context.$gettext('Received');
      return {
        text: template,
        class: 'assignment__dark-blue-status'
      };
    }
    case 'applied': {
      const template = userIsInterpreter
        ? context.$gettext('You applied for this assignment')
        : context.$gettext('Offer');
      return {
        text: template,
        class: userIsInterpreter ? 'assignment__purple-status' : 'assignment__yellow-status'
      };
    }
    case 'invited':
    case 'withdrawn': {
      const template = userIsInterpreter
        ? context.$gettext('Apply')
        : '';
      return {
        text: template,
        class: 'assignment__dark-blue-status'
      };
    }
    case 'accepted':
    case 'in_progress': {
      const template = userIsInterpreter
        ? context.$gettext('The assignment is confirmed')
        : context.$gettext('Confirmed');
      return {
        text: template,
        class: 'assignment__green-status'
      };
    }
    case 'cancelled': {
      const template = cancellerName
        ? context.$pgettext('not_fetched', 'This assignment is cancelled by %{cancellerName} at %{cancelledAt}')
        : context.$gettext('Cancelled');
      return {
        text: context.$gettextInterpolate(template, {cancellerName, cancelledAt}),
        class: 'assignment__red-status'
      };
    }
    case 'needsRate':
    case 'needsFeedback':
      return {
        text: context.$gettext('Feedback'),
        class: 'assignment__purple-status'
      };
    case 'closed':
      return {
        text: context.$gettext('The assignment start time has passed'),
        class: 'assignment__green-status'
      };
    case 'finished': {
      const template = userIsInterpreter && jobLocked
        ? ''
        : context.$gettext('Finished');
      return {
        text: template,
        class: 'assignment__green-status'
      };
    }
    case 'rejected':
      return {
        text: context.$gettext('Your offer has been rejected'),
        class: 'assignment__green-status'
      };
    case 'unsuitable':
      return {
        text: context.$gettext('This assignment is no longer available'),
        class: 'assignment__green-status'
      };
    default:
      return {};
  }
};

const jobStatus = (jobStatus, userIsInterpreter, context) => {
  switch (jobStatus) {
    case 'published':
      return context.$gettext('Received');
    case 'applied':
      return userIsInterpreter ? context.$gettext('Sent offer') : context.$gettext('Offer');
    case 'invited':
      return context.$gettext('Invited');
    case 'accepted':
      return context.$gettext('Accepted');
    case 'in_progress':
      return context.$gettext('Confirmed');
    case 'cancelled':
      return context.$gettext('Cancelled');
    case 'finished':
      return context.$gettext('Finished');
    case 'closed':
      return context.$gettext('Closed');
    case 'needsRate':
    case 'needsFeedback':
      return context.$gettext('Feedback');
    default:
      return '';
  }
};

const jobType = (sessionType, context) => {
  switch (sessionType) {
    case 'video':
      return context.$gettext('Video');
    case 'phone':
      return context.$gettext('Phone');
    case 'in_person':
      return context.$gettext('In-person');
    case 'videoroom':
      return context.$gettext('Video room');
    default:
      return '';
  }
};

const durationText = (duration, context) => {
  const hours = (duration / 60);
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  const hoursText = rhours ? context.$gettextInterpolate(context.$gettext('%{hours}h'), {hours: rhours}) : '';
  const minutesText = rminutes ? context.$gettextInterpolate(context.$gettext('%{minutes}min'), {minutes: rminutes}) : '';

  return `${hoursText} ${minutesText}`;
};

const distanceText = (distanceInMeters, context) => {
  if (distanceInMeters) {
    const kilometers = (distanceInMeters / 1000).toFixed(2);
    return context.$gettextInterpolate(context.$gettext('%{kilometers} km'), {kilometers});
  } else {
    return context.$gettext('unknown/unprovided');
  }
};

const getTravelMethodType = (context, travelMethod) => {
  switch (travelMethod) {
    case 'default':
      return context.$gettext('No travel');
    case 'public_transport':
      return context.$gettext('Public transport');
    case 'car':
      return context.$gettext('Driving');
    case 'flight':
      return context.$gettext('Flight');
    default:
      return '';
  }
};

const getTravelExpensesTypes = (context) => {
  return [{
    id: 'parking',
    name: context.$gettext('Parking')
  }, {
    id: 'public_transport',
    name: context.$gettext('Public transport')
  }, {
    id: 'toll',
    name: context.$gettext('Toll')
  }, {
    id: 'ferry',
    name: context.$gettext('Ferry')
  }, {
    id: 'taxi',
    name: context.$gettext('Taxi')
  }, {
    id: 'hotel',
    name: context.$gettext('Hotel')
  }, {
    id: 'flight',
    name: context.$gettext('Flight')
  }, {
    id: 'other',
    name: context.$gettext('Other')
  }];
};

const getTravelExpensesName = (context, id) => {
  const selectedExpense = getTravelExpensesTypes(context).find((expense) => expense.id === id);
  return selectedExpense?.name;
};

const getTravelExpensesStatus = (context, status) => {
  switch (status) {
    case 'pending':
      return context.$gettext('Pending');
    case 'approved':
      return context.$gettext('Approved');
    case 'rejected':
      return context.$gettext('Rejected');
    default:
      return status;
  }
};

const getTravelExpensesStatuses = (context) => {
  return [{
    id: 'pending',
    name: getTravelExpensesStatus(context, 'pending')
  }, {
    id: 'approved',
    name: getTravelExpensesStatus(context, 'approved')
  }, {
    id: 'rejected',
    name: getTravelExpensesStatus(context, 'rejected')
  }];
};

const savedAddressName = (address) => {
  return ''.concat(
    (address.line1 ? `${address.line1}` : ''),
    (address.line2 ? `, ${address.line2}` : ''),
    (address.postcode ? `, ${address.postcode}` : ''),
    (address.city ? `, ${address.city}` : ''),
    (address.country ? `, ${address.country}` : ''),
    (address.extra ? `, ${address.extra}` : '')
  );
};

const jobBookingMechanism = (context, bookingMechanism) => {
  switch (bookingMechanism) {
    case 'platform':
      return context.$gettext('Platform');
    case 'phone':
      return context.$gettext('Phone');
    case 'email':
      return context.$gettext('E-mail');
    case 'chat':
      return context.$gettext('Chat');
    case 'price_check':
      return context.$gettext('Price Check');
    case 'punch_out':
      return context.$gettext('Punch Out');
    default:
      return '';
  }
};

const jobBookingMechanismList = (context) => {
  return [{
    key: 'platform',
    text: jobBookingMechanism(context, 'platform')
  }, {
    key: 'phone',
    text: jobBookingMechanism(context, 'phone')
  }, {
    key: 'email',
    text: jobBookingMechanism(context, 'email')
  }, {
    key: 'chat',
    text: jobBookingMechanism(context, 'chat')
  }, {
    key: 'price_check',
    text: jobBookingMechanism(context, 'price_check')
  }, {
    key: 'punch_out',
    text: jobBookingMechanism(context, 'punch_out')
  }];
};

const jobManualFee = (context, manualBookingFee) => {
  switch (manualBookingFee) {
    case 'booking':
      return context.$gettext('Manual booking fee');
    case 'editing':
      return context.$gettext('Manual editing fee');
    case 'travelBooking':
      return context.$gettext('Travel booking fee');
    default:
      return '';
  }
};

const jobProcessRequirement = (context, processRequirement, assignmentType) => {
  const domain = context.$getString('domainData', 'platformName');
  const meetingType = assignmentType === 'video' ? context.$gettext('video meeting') : context.$gettext('in-person meeting');
  switch (processRequirement) {
    case 'direct_find_another': {
      return context.$gettext('Find another interpreter for this assignment');
    }
    case 'direct_cancel_said_no': {
      return context.$gettext('Cancel the assignment');
    }
    case 'session_type_auto_update_phone': {
      const template = context.$gettext('If a qualified interpreter is not available for this %{meetingType}, change this to a phone assignment.');
      return context.$gettextInterpolate(template, {meetingType});
    }
    case 'session_type_auto_update_video': {
      const template = context.$gettext('If a qualified interpreter is not available for this %{meetingType}, change this to a video assignment.');
      return context.$gettextInterpolate(template, {meetingType});
    }
    case 'session_type_cancel_not_found': {
      const template = context.$gettext('If a qualified interpreter is not available for this %{meetingType}, cancel this assignment.');
      return context.$gettextInterpolate(template, {meetingType});
    }
    case 'gender_auto_update': {
      const template = context.$gettext('If an interpreter of the specified gender is not available, %{domain} should find an interpreter of any gender for this assignment');
      return context.$gettextInterpolate(template, {domain});
    }
    case 'gender_cancel_not_found': {
      return context.$gettext('Cancel the assignment if interpreters of this gender refuses to take this assignment');
    }
    case 'qualification_auto_update': {
      const template = context.$gettext('If %{domain} is not able to find an interpreter for the chosen qualification level, %{domain} may find an interpreter with another qualification');
      return context.$gettextInterpolate(template, {domain});
    }
    case 'qualification_cancel_not_found': {
      const template = context.$gettext('%{domain} should cancel this assignment if it cannot find an interpreter for the chosen qualification level');
      return context.$gettextInterpolate(template, {domain});
    }
    case 'several_suppliers_auto_update': {
      const template = context.$gettext('If %{domain} does not have 2 interpreters for the assignment, 1 interpreter can be assigned');
      return context.$gettextInterpolate(template, {domain});
    }
    case 'several_suppliers_cancel_not_found': {
      return context.$gettext('This assignment should be cancelled if interpreters cannot be found');
    }
    case 'same_int': {
      return context.$gettext('I want one interpreter for all series assignments');
    }
    default:
      return '';
  }
};

const videoPlatformType = (context, type, platformName) => {
  switch (type) {
    case 'default':
      return context.$gettextInterpolate(
        context.$gettext('I want to use %{domain}\'s secure video solution'), {
          domain: context.$getString('domainData', 'platformName')
        }
      );
    case 'own': {
      const name = platformName ? `<${platformName}>` : context.$gettext('my own video solution');
      return context.$gettextInterpolate(
        context.$gettext('I want to use %{name}'), {
          name
        }
      );
    }
    default:
      return '';
  }
};

const getGenderName = (context, id) => {
  const selectedGender = context.$helperData.getSelectGenders(context).find((gender) => gender.id == id);
  return selectedGender?.name;
};

const getQualificationName = (context, {id, showShortName}) => {
  const qualificationsList = context.$store.state.selectsData.qualification || [];
  const qualification = qualificationsList.find((item) => item.id == id);
  return showShortName ? qualification?.shortName : qualification?.name;
};

const getPaymentMethodName = (context, {paymentMethodData = {}}) => {
  if (paymentMethodData.methodType === 'organization') {
    let orgName = paymentMethodData?.orgName;
    if (orgName && orgName.length > 30) {
      orgName = orgName.substring(0, 30);
      orgName = orgName.concat('...');
    }
    const orgNumber = paymentMethodData.orgNumber;
    return orgName && orgNumber
      ? `${orgName} / Org.:${orgNumber}`
      : orgNumber
        ? `Org.:${orgNumber}`
        : '';
  } else if (paymentMethodData.methodType === 'individual') {
      return paymentMethodData.email;
  }
};

// TODO: This method is ignored by $gettext, please invistigate
const getCancellationPolicyType = (context, type) => {
  switch (type) {
    case 'calendar_hours_12':
      return context.$gettext('12 hours before assignment');
    case 'calendar_hours_24':
      return context.$gettext('24 hours before assignment');
    case 'day_before_1400':
      return context.$gettext('14:00 on the previous calendar day');
    case 'day_before_1200':
      return context.$gettext('12:00 on the previous calendar day');
    case 'day_before_1500':
      return context.$gettext('15:00 on the previous calendar day');
    case 'working_days_3':
      return context.$gettext('3 working days before assignment');
    case 'working_hours_7':
      return context.$gettext('7 working hours before assignment');
    case 'calendar_hours_7_and_half':
      return context.$gettext('7.5 hours before assignment');
    default:
      return context.$gettext('24 hours before assignment');
  }
};

const jobLanguageName = (context, langId) => {
  const languagesList = context.$store.state.selectsData.language || [];
  const langObj = languagesList.find((lang) => lang.id == langId) || {};

  return langObj.name || '';
};

const keyWordsForDisablingStandBy = ['morokko', 'morrokko', 'morro'];

const disabledStandByText = (context) => {
  const keyWords = keyWordsForDisablingStandBy
    .map((keyword) => `<i>${keyword}</i>`)
    .join(', ');
  const template = context.$gettext('Standby feature disabled due to the following keywords in the job information:');

  return `${template} ${keyWords}`;
};

const disabledStandBy = (sources = []) => {
  return !!keyWordsForDisablingStandBy.find((word) => {
    return sources.find((source = '') => source.toLowerCase().includes(word));
  });
};

const interpreterJobStatus = (context, status) => {
  switch (status) {
    case 'no_invited':
        return {
          text: context.$gettext('No invited interpreters'),
          class: ''
        };
    case 'no_applied':
      return {
        text: context.$gettext('No applied interpreters'),
        class: ''
      };
    case 'withdrew':
      return {
        text: context.$gettext('Interpreter withdrew'),
        class: 'interpreter__red-status'
      };
    default:
      return '';
  }
};

const lastActivity = (context, lastActivityType) => {
  switch (lastActivityType) {
    case 'job_created':
      return context.$gettext('Created');
    case 'application_withdrawn':
      return context.$gettext('Withdrawn');
    case 'application_invited':
      return context.$gettext('Invited');
    case 'job_updated':
    case 'job_updated_fees_and_order':
    case 'job_updated_expenses':
    case 'job_updated_invoicing':
    case 'job_updated_assignment_type':
    case 'job_updated_references':
      return context.$gettext('Edited');
  }
};

const jobPriceTemplateStyle = (context, status) => {
  switch (status) {
    case 'red':
        return {
          backgroundColor: 'rgba(255, 54, 36, 0.2)'
        };
    case 'green':
      return {
        backgroundColor: 'rgba(84, 191, 84, 0.2)'
      };
    case 'blue':
      return {
        backgroundColor: 'rgba(36, 109, 255, 0.5)'
     };
   case 'yellow':
      return {
        backgroundColor: 'rgba(255, 237, 36, 0.2)'
      };
    case 'orange':
      return {
        backgroundColor: 'rgba(203,75,32,0.2)'
      };
    case 'lightblue':
      return {
        backgroundColor: 'rgba(36, 109, 255, 0.2)'
      };
    default:
      return '';
  }
};

export default {
  jobId,
  jobStatusObj,
  extendedJobStatusObj,
  titleJobStatusObj,
  jobStatus,
  jobType,
  jobBookingMechanism,
  jobBookingMechanismList,
  jobManualFee,
  jobProcessRequirement,
  jobLanguageName,
  durationText,
  distanceText,
  videoPlatformType,
  getTravelMethodType,
  getTravelExpensesTypes,
  getTravelExpensesName,
  getTravelExpensesStatus,
  getTravelExpensesStatuses,
  savedAddressName,
  getGenderName,
  getQualificationName,
  getPaymentMethodName,
  getCancellationPolicyType,
  disabledStandByText,
  disabledStandBy,
  jobStatusObjChip,
  interpreterJobStatus,
  jobPriceTemplateStyle,
  lastActivity
};
