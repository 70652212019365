<script>
  import {mapState, mapMutations, mapActions} from 'vuex';
  import InteractiveSearchMultiSelect from '@/components/shared_components/form_fields/InteractiveSearchMultiSelect';

  export default {
    extends: InteractiveSearchMultiSelect,
    computed: {
      ...mapState('TicketSystemStore', {
        searchQuery: ({searchQuery}) => searchQuery
      })
    },
    methods: {
      ...mapActions({
        submitAction: 'TicketSystemStore/searchJobsById',
        cancelSearch: 'TicketSystemStore/cancelSearchJobs'
      }),
      ...mapMutations({
        setSearchQueryMutation: 'TicketSystemStore/setSearchQuery'
      })
    }
  };
</script>
