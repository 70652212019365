import Vue from 'vue';
import sseRequest from '../api/sse-request';

export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      searchQuery: '',
      searchAssignments: '',
      myAllAssignments: '',
      allAvailableAssignments: '',
      allBookedAssignments: '',
      pastAssignments: '',
      canceledAssignments: '',
      managerAssignments: '',
      updateManagerJobGrid: ''
    },
    getters: {
      b_MyAllAssignments(state) {
        if (state.myAllAssignments && state.myAllAssignments.jobs) {
          return JSON.parse(JSON.stringify(state.myAllAssignments.jobs));
        } else {
          return [];
        }
      },
      s_ConfirmedAssignments(state) {
        if (state.allBookedAssignments && state.allBookedAssignments.jobs) {
          return JSON.parse(JSON.stringify(state.allBookedAssignments.jobs));
        } else {
          return [];
        }
      },
      s_AvailableAssignments(state) {
        if (state.allAvailableAssignments && state.allAvailableAssignments.jobs) {
          return JSON.parse(JSON.stringify(state.allAvailableAssignments.jobs));
        } else {
          return [];
        }
      },
      PastAssignments(state) {
        if (state.pastAssignments && state.pastAssignments.jobs) {
          return JSON.parse(JSON.stringify(state.pastAssignments.jobs));
        } else {
          return [];
        }
      },
      HiddenAssignments(state) {
        if (state.canceledAssignments && state.canceledAssignments.jobs) {
          return JSON.parse(JSON.stringify(state.canceledAssignments.jobs));
        } else {
          return [];
        }
      },
      m_Assignments(state) {
        if (state.managerAssignments && state.managerAssignments.jobs) {
          return JSON.parse(JSON.stringify(state.managerAssignments.jobs));
        } else {
          return [];
        }
      },
    },
    actions: {
      getMyAllAssignments({commit, dispatch}, params) {
        const fallBackRequest = (resolve, reject) => {
          // ------ Fall back Ajax Request  ------ //
          fetchData.getMyAssignments(params).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setAllMyAssignments', response);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        };

        return new Promise((resolve, reject) => {
          dispatch('getShortLivedToken', '', {root: true})
            .then((res) => {
              params.token = res.token;
              // ------ SSE Request  ------ //
              sseRequest(
                {
                  request: 'dashboardJobs',
                  onmessage: (event) => {
                    if (event) {
                      commit('setAllMyAssignments', JSON.parse(event.data));
                      resolve(event.data);
                    }
                  },
                  onerror: () => {
                    fallBackRequest(resolve, reject);
                  },
                  params
                }
              );
            })
            .catch(() => {
              fallBackRequest(resolve, reject);
            });
        });
      },
      getSearchAssignments({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getSearchAssignments(params).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setSearchAssignments', response);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      cancelSearchAssignments() {
        return new Promise((resolve, reject) => {
          fetchData.cancelSearchAssignments().then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getAllBookedAssignments({commit, dispatch}, params) {
        const fallBackRequest = (resolve, reject) => {
          // ------ Fall back Ajax Request  ------ //
          fetchData.getMyAssignments(params).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setSAllBookedAssignments', response);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        };

        return new Promise((resolve, reject) => {
          dispatch('getShortLivedToken', '', {root: true})
            .then((res) => {
              params.token = res.token;
              // ------ SSE Request  ------ //
              sseRequest(
                {
                  request: 'dashboardJobs',
                  onmessage: (event) => {
                    if (event) {
                      commit('setSAllBookedAssignments', JSON.parse(event.data));
                      resolve(event.data);
                    }
                  },
                  onerror: () => {
                    fallBackRequest(resolve, reject);
                  },
                  params
                }
              );
            })
            .catch(() => {
              fallBackRequest(resolve, reject);
            });
        });
      },
      getAllAvailableAssignments({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getAvailableAssignments(params).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setSAllAvailableAssignments', response);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getPastAssignments({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getAllPastAssignments(params).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setPastAssignments', response);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getHiddenAssignments({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getAllHiddenAssignments(params).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setHiddenAssignments', response);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getManagerAssignments({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getManagerAssignments(params).then((response) => {
            if (response.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setManagersAssignments', response);
            const resFormatted = response;
            resFormatted.data = response.jobs;
            resolve(resFormatted);
          }).catch(() => {
            reject();
          });
        });
      },
    },
    mutations: {
      updateServerJobInfo(state, data) {
        const availableAssignmentsData = state.allAvailableAssignments || {};
        const bookedAssignmentsData = state.allBookedAssignments || {};
        const availableJobs = availableAssignmentsData.jobs || [];
        const bookedJobs = bookedAssignmentsData.jobs || [];

        if (availableJobs.length) {
          for (let i = 0; i < availableJobs.length; i += 1) {
            if (+availableJobs[i].id === +data.id) {
              availableJobs[i].status = data.status;
              if (data.status === 'accepted') {
                bookedJobs.length
                  ? Vue.set(state.allBookedAssignments, 'jobs', bookedJobs.concat(availableJobs.splice(i, 1)))
                  : Vue.set(state.allBookedAssignments, 'jobs', availableJobs.splice(i, 1));
              }
              if (data.status === 'abandoned' || data.status === 'hidden' || data.status === 'expired') {
                availableJobs.splice(i, 1);
              }
              return;
            }
          }
        }
        if (bookedJobs.length) {
          for (let i = 0; i < bookedJobs.length; i += 1) {
            if (+bookedJobs[i].id === +data.id) {
              bookedJobs[i].status = data.status;
              if (data.status === 'hidden') {
                bookedJobs.splice(i, 1);
              }
              return;
            }
          }
        }
      },
      updateBuyerJobInfo(state, data) {
        const assignmentsData = state.myAllAssignments || {};
        const availableJobs = assignmentsData.jobs || [];

        if (availableJobs.length) {
          for (let i = 0; i < availableJobs.length; i += 1) {
            if (+availableJobs[i].id === +data.id) {
              availableJobs[i].status = data.status;
              return;
            }
          }
        }
      },
      setSearchQuery(state, value) {
        state.searchQuery = value;
      },
      setSearchAssignments(state, data) {
        state.searchAssignments = data;
      },
      setAllMyAssignments(state, data) {
        state.myAllAssignments = data;
      },
      setSAllBookedAssignments(state, data) {
        state.allBookedAssignments = data;
      },
      setSAllAvailableAssignments(state, data) {
        state.allAvailableAssignments = data;
      },
      setPastAssignments(state, data) {
        state.pastAssignments = data;
      },
      setHiddenAssignments(state, data) {
        state.canceledAssignments = data;
      },
      setManagersAssignments(state, data) {
        state.managerAssignments = data;
      },
      removeSAllBookedAssignments(state) {
        state.allBookedAssignments = '';
      },
      removeSAllAvailableAssignments(state) {
        state.allAvailableAssignments = '';
      },
      removeAllAssignmentsData(state) {
        state.myAllAssignments = '';
        state.allAvailableAssignments = '';
        state.allBookedAssignments = '';
        state.pastAssignments = '';
        state.canceledAssignments = '';
        state.searchQuery = '';
        state.searchAssignments = '';
        state.managerAssignments = '';
      }
    }
  };
};
