export default () => {
  return {
    namespaced: true,
    state: {
      blockedCities: [],
      blockedCitiesFullList: [],
      blockedCitiesSelect: false
    },
    actions: {
    },
    mutations: {
      setBlockedCities(state, data) {
        state.blockedCities = data;
        state.blockedCitiesFullList = state.blockedCitiesFullList.filter((city) => state.blockedCities.includes(city.id));
      },
      setBlockedCitiesSelect(state, value) {
        state.blockedCitiesSelect = value;
        state.blockedCities = [];
        state.blockedCitiesFullList = [];
      },
      addBlockedCity(state, cityObj = {}) {
        if (!state.blockedCities.some((cityId) => cityId === cityObj.id)) {
          state.blockedCities.push(cityObj.id);
          state.blockedCitiesFullList.push(cityObj);
        }
      },
      clearStore(state) {
        state.blockedCitiesSelect = false;
        state.blockedCities = [];
        state.blockedCitiesFullList = [];
      }
    }
  };
};
