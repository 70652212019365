(<template>
  <section class="add-ticket-success-modal">
    <p class="add-ticket-success-modal__text add-ticket-success-modal__text--bold"
       v-html="ticketNumberText"></p>
    <p v-for="(item, index) in contextItems"
       :key="index"
       class="add-ticket-success-modal__text"
       v-html="item"></p>
  </section>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    computed: {
      ticket() { return this.data.ticket || {}; },
      ticketId() { return this.ticket.id; },
      ticketNumberText() {
        const template = this.$gettext('Your Ticket number is <span class="add-ticket-success-modal__highlighted-text">#%{ticketId}</span>');
        return this.$gettextInterpolate(template, {ticketId: this.ticketId});
      },
      isFinanceTicket() { return this.ticket.category === 'finance'; },
      contextItems() {
        return this.isFinanceTicket
          ? [
            this.$gettext('You will receive an email to confirm that we have received your message.')
          ] : [
            this.$gettext('We have sent you a copy of the information you have provided by email.'),
            this.$gettext('Our customer center will follow up on this ticket as soon as possible.')
          ];
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
    }
  };
</script>

<style>
  .add-ticket-success-modal__highlighted-text {
    color: #ff5b24;
    color: var(--color-secondary);
  }
</style>

<style scoped>
  .add-ticket-success-modal {
    padding: 20px 30px 10px;
  }

  .add-ticket-success-modal__text {
    margin-bottom: 20px;
  }

  .add-ticket-success-modal__text--bold {
    font-weight: bold;
  }
</style>
