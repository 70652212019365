import BookingHelper from '@/store/booking_stores/BookingHelper';

export default () => {
  return {
    namespaced: true,
    state: {
      caseNumber: '',
      errors: {caseNumber: {name: 'caseNumberError', errors: []}}
    },
    getters: {
      getCaseNumber(state) {
        return state.caseNumber || '';
      }
    },
    actions: {
      changeCaseNumber({commit}, payload) {
        commit('setCaseNumber', payload);
      },
    },
    mutations: {
      ...BookingHelper().mutations,
      setCaseNumber(state, value) {
        state.caseNumber = value;
      },
      clearStore(state) {
        state.caseNumber = '';
      }
    }
  };
};
