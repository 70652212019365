(<template>
  <section class="assign-withdraw">
    <p v-html="emailLinkText"></p>
  </section>
</template>)

<script>
  import getProductConfig from '@/modules/productConfig';

  export default {
    computed: {
      supportInterpreterEmail() {
        return getProductConfig(this.$gettext, 'salita', 'domainData', 'subSupportEmail');
      },
      emailLinkText() {
        const template = this.$gettext('Please contact us at <a class="sk-link sk-link--gray-bg" href="mailto:%{email}">%{email}</a> to withdraw from this assignment.');
        return this.$gettextInterpolate(template, {email: this.supportInterpreterEmail});
      },
    }
  };
</script>

<style scoped>
.assign-withdraw {
  display: block;
  width: 100%;
  padding: 20px 30px;
}
</style>
