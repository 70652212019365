export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      // CREATION
      form: {
        rawMetadata: '',
      },
      errors: {
        rawMetadata: {errors: [], name: 'rawMetadataError'},
      },
      // SHOW
      securityInfo: ''
    },
    actions: {
      // CREATION
      createForm({state}) {
        const form = new FormData();

        form.append('encodedRawMetadata', btoa(state.form.rawMetadata));

        return Promise.resolve(form);
      },
      getEnterpriseSecurity({commit}, id) {
        return new Promise((resolve, reject) => {
          fetchData.getEnterpriseSecurity(id).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setSecurityInfo', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      updateEnterpriseSecurity({commit, rootGetters}, form) {
        return new Promise((resolve, reject) => {
          fetchData.updateEnterpriseSecurity(rootGetters['UserInfoStore/enterpriseId'], form).then((data) => {
            commit('setSecurityInfo', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      }
    },
    mutations: {
      // CREATION
      setError(state, {fieldName, errorText}) {
        state.errors[fieldName].errors = [...state.errors[fieldName].errors, errorText];
      },
      removeOneError(state, fieldName) {
        state.errors[fieldName].errors = [];
      },
      removeErrors(state) {
        for (const key of Object.keys(state.errors)) {
          state.errors[key].errors = [];
        }
      },
      setRawMetadata(state, value) { state.form.rawMetadata = value; },
      resetForm(state) {
        state.form = {
          rawMetadata: '',
        };
      },
      // SHOW
      setSecurityInfo(state, data) { state.securityInfo = data; },
      removeSecurityInfo(state) { state.securityInfo = ''; }
    }
  };
};
