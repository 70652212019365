import axios from 'axios';

const interpreters = {
  methods: {
    // ==================================================
    // ==================================================
    // INTERPRETERS CALLS
    // ==================================================
    // ==================================================
    fetchInterpreters(params) {
      const self = this;
      const paramsObj = {
        params: params,
        cancelToken: this.cancelationSource.token
      };

      return new Promise((resolve, reject) => {
        self.ajax.get('v1/interpreters/search', paramsObj).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    fetchLightInterpreters(params) {
      const self = this;
      const paramsObj = {
        params: params,
        cancelToken: this.cancelationSource.token
      };
      return new Promise((resolve, reject) => {
        self.ajax.get('v1/interpreters/light_search', paramsObj).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    cancelSearchInterpreters() {
      return new Promise((resolve) => {
        this.cancelationSource.cancel();
        this.cancelationSource = '';
        this.cancelationSource = axios.CancelToken.source();
        resolve();
      });
    },
    fetchAvailabilityEvents(interUid, params) {
      return new Promise((resolve, reject) => {
        this.ajax.get(`v1/interpreters/${interUid}/availability`, {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    fetchAvailableDate(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('v1/interpreters/suitable_date', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
        // setTimeout(() => {
        //   resolve(faker.availableDates);
        // }, 1500);
      });
    },
    fetchAvailableTime(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('v1/interpreters/suitable_time', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
        // setTimeout(() => {
        //   resolve(faker.availableTimes);
        // }, 1500);
      });
    },
    confirmTemporaryPoliceAttestation(id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/interpreters/${id}/temporary_police_attestation`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getDocs(uid) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/interpreters/${uid}/attachments`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    deleteDoc(uid, id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.delete(`/v1/interpreters/${uid}/attachments/${id}`).then(() => {
          resolve();
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    uploadDoc(uid, params) {
      const self = this;
      const form = new FormData();
      form.append('attachment[file]', params.file);
      form.append('attachment[title]', params.title);
      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/interpreters/${uid}/attachments`, form, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    getDoc(uid, id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/interpreters/${uid}/attachments/${id}/download_link`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    updateUserProfile(id, form, role) {
      const self = this;
      let path = role == 'server' ? `/v1/interpreters/${id}` : `/v1/buyers/${id}`;
      switch (role) {
        case 'server':
          path = `/v1/interpreters/${id}`;
          break;
        case 'buyer':
        case 'employee':
          path = `/v1/buyers/${id}`;
          break;
        case 'manager':
          path = `/v1/managers/${id}`;
          break;
      }
      return new Promise((resolve, reject) => {
        self.ajax.put(path, form, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    addSchool(id, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/interpreters/${id}/educations`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    editSchool(id, form, schoolId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/interpreters/${id}/educations/${schoolId}`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    removeSchool(userId, schoolId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.delete(`/v1/interpreters/${userId}/educations/${schoolId}`).then(() => {
          resolve();
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    addTool(id, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/interpreters/${id}/tools`, form).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error.data || {});
        });
      });
    },
    editTool(id, form, toolId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/interpreters/${id}/tools/${toolId}`, form).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error.data || {});
        });
      });
    },
    removeTool(userId, toolId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.delete(`/v1/interpreters/${userId}/tools/${toolId}`).then(() => {
          resolve();
        }).catch((error) => {
          reject(error.data || {});
        });
      });
    },
    addExperience(id, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/interpreters/${id}/experience`, form).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error.data || {});
        });
      });
    },
    editExperience(id, form, experienceId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/interpreters/${id}/experience/${experienceId}`, form).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error.data || {});
        });
      });
    },
    removeExperience(userId, experienceId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.delete(`/v1/interpreters/${userId}/experience/${experienceId}`).then(() => {
          resolve();
        }).catch((error) => {
          reject(error.data || {});
        });
      });
    },
    updateAreas(id, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/interpreters/${id}/expertise_areas`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    updateTypes(id, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/interpreters/${id}/session_types`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    updateMethods(id, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/interpreters/${id}/interpretation_methods`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    checkInterpreterSkill(id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/interpreters/${id}/skill_checks`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    removeCheckSkill(userId, id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.delete(`/v1/interpreters/${userId}/skill_checks/${id}`).then(() => {
          resolve();
        }).catch((error) => {
          this.resolveError404(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    createInterpreterSkill(id, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/interpreters/${id}/skills`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    updateInterpreterSkill(id, form, skillId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/interpreters/${id}/skills/${skillId}`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    removeSkill(userId, id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.delete(`/v1/interpreters/${userId}/skills/${id}`).then(() => {
          resolve();
        }).catch((error) => {
          this.resolveError404(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    sendCertificate(id, file) {
      const self = this;
      const form = new FormData();
      form.append('interpreter_uid', file);
      form.append('certificate_attachment[file]', file);
      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/interpreters/${id}/certificate_attachments`, form, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    getSettings(id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/interpreters/${id}/settings`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    updateSettings(id, params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/interpreters/${id}/settings`, params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    checkActiveJobs(id) {
      return new Promise((resolve, reject) => {
        this.ajax.get(`/v1/interpreters/${id}/settings/can_edit_phone`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          this.resolveError500(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    // ==================================================
    // ==================================================
    // DAILY CALENDAR CALLS
    // ==================================================
    // ==================================================
    fetchCalendarJobs(id, date) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/interpreters/${id}/day_events`, {params: {date}}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    // ==================================================
    // ==================================================
    // INTERPRETERS CLIENTS CALLS
    // ==================================================
    // ==================================================
    fetchInterClients(uid) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/interpreters/${uid}/clients`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    addClient(uid, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/interpreters/${uid}/clients`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    editClient(uid, form, clientId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/interpreters/${uid}/clients/${clientId}`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    deleteClient(uid, clientId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.delete(`/v1/interpreters/${uid}/clients/${clientId}`).then(() => {
          resolve();
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    getViewers(data = []) {
      const self = this;
      const params = new URLSearchParams();
      data.forEach((uid) => params.append('uids[]', uid));

      return new Promise((resolve, reject) => {
        self.ajax.get('/v1/people/list', {params}).then((response) => {
          resolve(response ? response?.data || {} : {});
        }).catch((error) => {
          reject(error?.response);
        });
      });
    },
    getInterpreterShortInfo(uid) {
      return new Promise((resolve, reject) => {
        this.ajax.get(`v1/support/interpreters/${uid}/short_info`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
        // setTimeout(() => {
        //   resolve(faker.interpreterShortInfo);
        // }, 1500);
      });
    },
    sendRegisterAnAssignment(jobForm) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/interpreters/jobs', jobForm).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
  }
};

export default interpreters;
