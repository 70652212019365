// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/plus_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.notes__main-wrapper[data-v-450a3f09] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  width: 100%;\n  padding: 10px 30px 30px;\n}\n.notes-view_section[data-v-450a3f09],\n.notes__basic-info[data-v-450a3f09] {\n  width: 100%;\n}\n.notes__add-button[data-v-450a3f09] {\n  height: 48px;\n  margin-bottom: 16px;\n  padding-right: 15px;\n  border: 1px dashed rgba(255, 91, 36, 0.2) !important;\n  border-radius: 8px;\n  background-color: #fff4f0 !important;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 40% 50%;\n  background-size: 22px auto;\n  background-repeat: no-repeat;\n}\n.notes__single-note[data-v-450a3f09]:not(:last-child) {\n  margin-bottom: 16px;\n}\n.note__textarea[data-v-450a3f09] {\n  height: 100px;\n}\n.notes__btns-wrapper[data-v-450a3f09] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  width: 100%;\n  margin-top: 10px;\n}\n.notes__btn[data-v-450a3f09] {\n  width: auto;\n  margin: 20px 5px 0;\n  padding: 0 20px;\n}\n\n", ""]);
// Exports
module.exports = exports;
