(<template>
  <div class="person">
    <interpreter-tile :is-fai="true"
                      :interpreter="interpreter" />
    <template v-if="userCanManageJob">
      <div class="person__btn-wrapper">

        <!-- Discussion is opened -->
        <template v-if="discussionStatus === 'opened'">
          <p v-if="isBlockedInterpreter"
             class="person__blocked-info">{{ blockedInterpreterText }}</p>
          <template v-else>
            <div class="person__info-wrapper">
              <p v-if="showAutoInvite"
                 class="person__invite-info">{{ $gettext('Next auto-invite') }}</p>
              <button v-if="interpreter.suitable"
                      class="sk-btn sk-btn--like-link sk-link person__link"
                      @click="inviteInt(interpreter.uid)">{{ $gettext('Invite now') }}
              </button>
            </div>
            <button class="sk-btn sk-btn--white person__btn"
                    @click="blockInterpreter">{{ $gettext('Block') }}
            </button>
          </template>
        </template>

        <!-- Discussion is invited -->
        <template v-if="discussionStatus === 'invited'">
          <p v-if="isBlockedInterpreter"
             class="person__blocked-info">{{ blockedInterpreterText }}</p>
          <template v-else>
            <button :class="{'person__btn--no-hover': !$isGodmode()}"
                    class="sk-btn sk-btn--white person__btn"
                    @click="inviteInt(interpreter.uid)">{{ invitedText }}
            </button>
            <button v-if="!$isGodmode()"
                    class="sk-btn sk-btn--white person__btn"
                    @click="blockInterpreter">{{ $gettext('Block') }}
            </button>
            <admin-only-note v-else
                             class="person__godmode-btn-wrapper">
              <button class="sk-btn sk-btn--default person__btn"
                      @click="applyByInt(interpreter.uid, interpreter.id, fullName)">{{ $gettext('Confirm/Apply') }}
              </button>
              <button class="sk-btn sk-btn--like-link person__decline-link"
                      @click="declineInvite(interpreter.uid, interpreter.id)">{{ $gettext('Decline') }}
              </button>
            </admin-only-note>
          </template>
        </template>

        <!-- Discussion is applied -->
        <button v-if="discussionStatus === 'applied'"
                class="sk-btn sk-btn--default person__btn">{{ $gettext('Applied') }}
        </button>

        <!-- Discussion is declined or abandoned or withdrawn -->
        <template v-if="['declined', 'abandoned', 'withdrawn'].includes(discussionStatus)">
          <p v-if="isBlockedInterpreter"
             class="person__blocked-info">{{ blockedInterpreterText }}</p>
          <button v-else
                  class="sk-btn sk-btn--white person__btn"
                  @click="blockInterpreter">{{ $gettext('Block') }}
          </button>
        </template>
      </div>
    </template>
  </div>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import Interpreter from '@/components/interpreters_components/InterForDashboardList';
  import AdminOnlyNote from '@/components/shared_components/AdminOnlyNote';
  import SuitableSupplierPrototype from '@/prototypes/SuitableSupplierPrototype';

  export default {
    components: {
      'interpreter-tile': Interpreter,
      'admin-only-note': AdminOnlyNote
    },
    extends: SuitableSupplierPrototype,
    props: {
      interpreter: {
        type: Object,
        default() {
          return {};
        }
      },
      showAutoInvite: Boolean
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userCanManageJob']),
      ...mapState('OneAssignmentStore', {
        jobId: ({storeJobObj}) => storeJobObj.id || '',
        discussionId: ({currentDiscussionId}) => currentDiscussionId || ''
      }),
      blockedText() { return this.$gettext('Block'); },
      blockedInterpreterText() {
        return this.$gettext('The interpreter was blocked for this assignment');
      }
    },
    methods: {
      blockInterpreter() {
        this.blockInterpreterFromJob(this.jobId, this.interpreter.uid);
      }
    }
  };
</script>

<style scoped>
  .person {
    margin-bottom: 15px;
    text-align: center;
  }

  .person__invite-info {
    margin: 0 5px;
    font-size: 12px;
    line-height: 12px;
  }

  .person__blocked-info {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 12px;
  }

  .person__info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .person__btn-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 10px 0;
  }

  .person__link {
    margin-top: 5px;
  }

  .person__btn {
    width: auto;
    min-width: 70px;
    margin: 0 5px;
    padding: 0 10px;
  }

  .person__btn--no-hover {
    pointer-events: none;
  }

  .person__godmode-btn-wrapper {
    position: relative;
    margin: 0 5px;
  }

  .person__godmode-btn-wrapper .person__btn {
    margin: 0;
  }

  .person__decline-link {
    position: absolute;
    right: 0;
    bottom: 0;
    padding-top: 3px;
    font-weight: bold;
    font-size: 11px;
    text-decoration: underline;
    transform: translateY(100%);
  }
</style>
