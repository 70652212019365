<script>
  import {mapState, mapActions} from 'vuex';
  import PostingTogglePrototype from '@/prototypes/PostingTogglePrototype';

  export default {
    extends: PostingTogglePrototype,
    computed: {
      ...mapState('OneAssignmentStore', {
        processRequirement: ({storeJobObj}) => storeJobObj.processRequirement || {}
      }),
      toggleValue() { return this.processRequirement.autoInviteEnabled === 'on'; },
      toggleDescription() { return this.$gettext('Auto invite'); },
      toggleStatus() { return this.processRequirement.autoInviteStatus; },
      toggleStatusText() {
        switch (this.toggleStatus) {
          case 'ongoing':
            return this.$gettext('The auto invite is in progress');
          case 'paused':
            return this.$gettext('The invitation queue is paused');
          case 'finished':
            return this.$gettext('All interpreters are invited');
          case 'no_suitable_interpreters':
            return this.$gettext('No suitable interpreters are found');
          case 'disabled':
            return this.$gettext('The invitation is stopped, no suitable interpreters');
          default:
            return '';
        }
      },
      statusError() {
        return this.toggleValue && this.toggleStatus === 'paused';
      },
      statusErrorText() {
        return this.$gettext('Something wrong with the invitation process, please try to disable and enable auto invite');
      }
    },
    methods: {
      ...mapActions('OneAssignmentStore', ['updateAutoInvite']),
      toggleHandler(value) {
        this.progressActive = true;
        this.updateAutoInvite(value).then(() => {
          this.progressActive = false;
        }).catch(() => {
          this.progressActive = false;
        });
      }
    }
  };
</script>
