(<template>
  <section class="same-job-modal__wrapper">
    <p class="same-job-modal__text">{{ $gettext('There is already an assignment with the exact same language and time where you are listed as booker.') }}</p>
    <p v-if="!userUid"
       class="same-job-modal__text">{{ $gettext('You must log in to the portal to check if the second assignment has been placed for another department. For privacy reasons, we can not display the name of the person who ordered the assignment when you are not logged in.') }}</p>
    <p class="same-job-modal__text"
       v-html="linkText"></p>
    <div class="same-job-modal__btn-wrapper">
      <button class="sk-btn sk-btn--default same-job-modal__btn"
              @click="submitData">{{ $gettext('Post an assignment') }}</button>
    </div>
  </section>
</template>)

<script>
  import {mapMutations, mapGetters} from 'vuex';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid']),
      linkText() {
        const template = this.userUid
          ? this.$gettext('If you are sure that you want to book another interpreter at the same time, then you can complete the booking')
          : this.$gettext('If you are sure that you want to book another interpreter at the same time, then you can complete the booking or <a class="sk-link js-link" href=%{link}>Post an assignment</a>');
        const link = this.$router.resolve(this.$makeRoute({name: 'SignIn'})).href;

        return this.$gettextInterpolate(template, {link});
      }
    },
    methods: {
      ...mapMutations('PostInterpretationStore', ['setForceCreate']),
      closeModal() {
        setTimeout(() => {
          this.$emit('closemodal');
        }, 500);
      },
      submitData() {
        this.$emit('startprogress');
        setTimeout(() => {
          this.setForceCreate(true);
          this.data.context.sendInfo();
          this.data.context.$on('postassignfailed', this.stopProgress);
          this.data.context.$on('postassignsuccess', this.closeModal);
        }, 2000);
      }
    },
    mounted() {
      const linkElement = document.querySelector('.js-link');
      if (linkElement) {
        linkElement.addEventListener('click', this.closeModal);
      }
    }
  };
</script>

<style scoped>
  .same-job-modal__wrapper {
    margin: 20px 15px;
  }

  .same-job-modal__text {
    margin-bottom: 20px;
  }

  .same-job-modal__btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 0 -10px;
  }

  .same-job-modal__btn {
    width: auto;
    margin: 0 10px;
    padding: 0 20px;
  }
</style>
