// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/auto_invite_statuses/ongoing.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/auto_invite_statuses/paused.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@assets/imgs/auto_invite_statuses/finished.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@assets/imgs/auto_invite_statuses/no_suitable_interpreters.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("@assets/imgs/auto_invite_statuses/disabled.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, ".toggle-section[data-v-5972f582] {\n  width: auto;\n}\n.toggle-section__wrapper[data-v-5972f582] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.toggle-section__wrapper--disabled[data-v-5972f582] {\n  opacity: 0.5;\n}\n.toggle-section__text[data-v-5972f582] {\n  margin-left: 10px;\n}\n.toggle-section__status[data-v-5972f582] {\n  width: 27px;\n  height: 23px;\n  margin-left: 10px;\n}\n.toggle-section__status-error[data-v-5972f582] {\n  width: 100%;\n  height: 30px;\n  margin-top: 10px;\n  color: #f56847;\n  font-weight: normal;\n  font-size: 10px;\n}\n.toggle-section__status--ongoing[data-v-5972f582] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.toggle-section__status--paused[data-v-5972f582] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.toggle-section__status--finished[data-v-5972f582] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.toggle-section__status--no_suitable_interpreters[data-v-5972f582] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n.toggle-section__status--disabled[data-v-5972f582] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
