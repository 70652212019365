(<template>
  <div class="full-address">
    <div v-if="onboardingPage">
      <p class="full-address__section-text sk-select__description">{{ $gettext('Choose country') }}</p>
      <sk-radio v-for="location in locationList"
                :key="location.id"
                :item-value="location.id"
                :item-name="'location'"
                :preselected-value="isGlobal"
                :item-text="location.name"
                class="full-address__location-radio"
                @radiochanged="setBoxChecked" />
    </div>
    <p v-if="showAttention && !fullAddress"
       class="full-address__attention">{{ attentionText }}</p>
    <autocomplete-input id="full-address"
                        :placeholder="addressText"
                        :preselected-value="fullAddress"
                        :description="description"
                        :disabled="disabled"
                        :autofocus="autofocus"
                        :is-global="isGlobal"
                        @placechanged="placeChangedHandler"
                        @valuechanged="valuechanged" />
    <template v-if="fullAddress && !disabled">
      <sk-input :placeholder="line1Text"
                :description="line1Text"
                :preselected-value="line1"
                :margin-bottom="true"
                :validation-name="line1ValidationName"
                @fieldvaluechanged="setLine1" />
      <sk-input :placeholder="line2Text"
                :description="line2Text"
                :preselected-value="line2"
                :margin-bottom="true"
                @fieldvaluechanged="setLine2" />
      <div class="full-address__inputs-cont">
        <sk-input class="full-address__post-code-select"
                  :placeholder="postcodeText"
                  :description="postcodeText"
                  :preselected-value="postcode"
                  :validation-name="postcodeValidationName"
                  @fieldvaluechanged="setPostcode" />

        <sk-input :placeholder="cityText"
                  :description="cityText"
                  :preselected-value="city"
                  :validation-name="cityValidationName"
                  @fieldvaluechanged="setCity" />
      </div>
      <div class="full-address__inputs-cont">
        <sk-input class="full-address__county-select"
                  :placeholder="countyText"
                  :description="countyText"
                  :preselected-value="county"
                  :validation-name="countyValidationName"
                  @fieldvaluechanged="setCounty" />

        <sk-input :placeholder="countryText"
                  :description="countryText"
                  :preselected-value="country"
                  :validation-name="countryValidationName"
                  @fieldvaluechanged="setCountry" />
      </div>
    </template>
  </div>
</template>)

<script>
  import {mapState, mapGetters, mapMutations} from 'vuex';
  import AutocompleteInput from '@/components/shared_components/AutocompleteInput';
  import FullAddressValidationPrototype from '@/prototypes/FullAddressValidationPrototype';

  export default {
    components: {
      'autocomplete-input': AutocompleteInput
    },
    extends: FullAddressValidationPrototype,
    props: {
      description: {
        type: String,
        default: ''
      },
      disabled: Boolean,
      autofocus: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        isGlobal: false,
        showAttention: false
      };
    },
    computed: {
      ...mapState('FullAddressStore', [
        'line1',
        'line2',
        'county',
        'postcode',
        'city',
        'country',
        'longitude',
        'latitude'
      ]),
      ...mapState('FullAddressStore', {
        addressValidationName: ({errors}) => errors.address.name,
        line1ValidationName: ({errors}) => errors.line1.name,
        countyValidationName: ({errors}) => errors.county.name,
        postcodeValidationName: ({errors}) => errors.postcode.name,
        cityValidationName: ({errors}) => errors.city.name,
        countryValidationName: ({errors}) => errors.country.name
      }),
      ...mapGetters('FullAddressStore', ['fullAddress']),
      attentionText() { return this.$gettext('Please select one of the suggested addresses from the list'); },
      addressText() { return this.$gettext('Address'); },
      line1Text() { return this.$gettext('Address line 1'); },
      line2Text() { return this.$gettext('Address line 2'); },
      postcodeText() { return this.$gettext('Postcode'); },
      cityText() { return this.$gettext('City'); },
      countyText() { return this.$gettext('County'); },
      countryText() { return this.$gettext('Country'); },
      notInPersonBooking() {
        return this.$route.name !== 'BuyerPostInterpretation';
      },
      onboardingPage() {
        return this.$route.name == 'ServerHome';
      },
      locationList() {
        return [
          {id: false, name: 'Norway'},
          {id: true, name: 'International'}
        ];
      },
    },
    methods: {
      ...mapMutations('FullAddressStore', [
        'setLine1',
        'setLine2',
        'setCounty',
        'setPostcode',
        'setCity',
        'setCountry'
      ]),
      ...mapMutations('PostingStore/SendMailStore', ['setAddress']),
      valuechanged() {
        this.showAttention = true;
        this.setAddress(this.fullAddress);
      },
      placeChangedHandler(place) {
        this.$store.commit('ErrorsStore/removeAllErrors');

        if (place
          && place.address_components
          && place.address_components.length) {
          const {
            street,
            buildingNumber,
            line2,
            city,
            county,
            country,
            postcode
          } = place.address_components.reduce((acc, {types, long_name: longName}) => {
            if (types.includes('street_number')) {
              return {...acc, buildingNumber: longName};
            }
            if (types.includes('route')) {
              return {...acc, street: longName};
            }
            if (types.includes('floor')) {
              return {...acc, line2: longName};
            }
            if (types.includes('locality') || types.includes('postal_town')) {
              return {...acc, city: longName};
            }
            if (types.includes('administrative_area_level_1')) {
              return {...acc, county: longName};
            }
            if (types.includes('country')) {
              return {...acc, country: longName};
            }
            if (types.includes('postal_code')) {
              return {...acc, postcode: longName};
            }
            return acc;
          }, {});
          this.setLine1(street ? `${street}${buildingNumber ? ' ' + buildingNumber : ''}` : '');
          this.setLine2(line2 || '');
          this.setCity(city || '');
          this.setCounty(county || '');
          this.setCountry(country || '');
          this.setPostcode(postcode || '');
          this.setAddress(this.fullAddress);
        }
      },
      validateForm() {
        this.setAddress(this.fullAddress);
        this.$store.commit('ErrorsStore/removeAllErrors', null);
        return this.validateAddressForm();
      },
      setBoxChecked(value) {
        this.isGlobal = value;
      },
    }
  };
</script>

<style scoped>
.full-address {
  width: 100%;
}

.full-address__location-radio {
  margin: 0 10px 12px 10px;
}

.full-address__attention {
  margin-bottom: 10px;
  padding-left: 20px;
  background-image: url(~@assets/imgs/salita/undefined_imgs/warning_icon.svg);
  background-size: 14px;
  background-repeat: no-repeat;
  line-height: 16px;
}

.full-address__section-text {
  display: block;
  width: 100%;
  margin: 0 0 10px;
}

.full-address__inputs-cont {
  display: flex;
}

.full-address .full-address__post-code-select {
  flex-shrink: 0;
  width: 100px;
  margin-right: 10px;
}

.full-address .full-address__county-select {
  flex-shrink: 0;
  width: 200px;
  margin-right: 10px;
}

.sk-select__description {
  margin-bottom: 2px;
}

@media (max-width: 767px) {
  .full-address__inputs-cont {
    flex-wrap: wrap;
  }
}
</style>
