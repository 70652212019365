(<template>
  <section class="add-private-event">
    <div class="add-private-event__radios-wrapper">
      <div v-for="event in eventTypes"
           :key="event.value"
           class="add-private-event__radio-wrapper">
        <p v-if="isSingleEventType"
           class="add-private-event__title">{{ event.text }}</p>
        <sk-radio v-else
                  :item-name="'privateEventType'"
                  :preselected-value="privateEvent.eventType"
                  :item-value="event.value"
                  :item-text="event.text"
                  class="add-private-event__radio"
                  @radiochanged="catchEventType(event.params)" />
        <p class="add-private-event__radio-description"
           v-html="event.description"></p>
      </div>
    </div>
    <template v-if="privateEvent.eventType && eventComponentName">
      <component :is="eventComponentName" />
      <button class="sk-btn sk-btn--default add-private-event__submit"
              @click="submitData()">{{ $gettext('Save') }}</button>
    </template>
  </section>
</template>)

<script>
  import {mapGetters, mapMutations} from 'vuex';
  import AddPrivateEventPrototype from '@/prototypes/AddPrivateEventPrototype';
  import AddStandByEvent from '@/components/dashboard_components/stand-by_events/AddStandByEvent';

  export default {
    components: {
      'add-standby-event': AddStandByEvent
    },
    extends: AddPrivateEventPrototype,
    props: {
      data: {
        type: Object,
        default() {
          return {
            data: {}
          };
        }
      }
    },
    data() {
      return {
        eventComponentName: ''
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userCanStandBy']),
      startTime() {
        return this.data.data.startTime ? this.$moment(this.data.data.startTime) : '';
      },
      finishTime() {
        return this.data.data.finishTime ? this.$moment(this.data.data.finishTime) : '';
      },
      eventTypesList() {
        return [{
          value: 1,
          text: this.$gettext('Time off'),
          description: this.$gettext('If you are taking vacation or can\'t work for a period of time you can add these dates here. We will not invite you for assignments that are on these days.'),
          condition: true,
          params: {
            title: '',
            component: 'add-event',
            eventType: 1
          }
        }, {
          value: 3,
          text: this.$gettext('StandBy interpreter'),
          description: this.$gettext('If you make yourself available, you will automatically be assigned a task. <a href="https://support.salita.no/article/758-standby-tolk/" target="_blank" class="sk-link sk-link--default">Read more about this service</a>'),
          condition: this.userCanStandBy,
          params: {
            title: this.$gettext('StandBy interpreter'),
            component: 'add-standby-event',
            eventType: 3
          }
        }];
      },
      eventTypes() {
        return this.eventTypesList.filter((type) => type.condition);
      },
      isSingleEventType() {
        return this.eventTypes.length === 1;
      }
    },
    methods: {
      ...mapMutations('TTPrivateEventsStore', [
        'setEventType',
        'setTitle',
        'setPrivateEvents',
        'setAllDay',
        'clearStore'
      ]),
      catchEventType({eventType, title, component}) {
        this.setEventType(eventType);
        this.setTitle(title);
        this.setAllDay(false);
        this.eventComponentName = component;
      },
      successHandling() {
        const date = this.$route.query.date ? this.$route.query.date : this.$moment().format('YYYY-MM-DD');
        const view = this.$route.query.view || 'week';

        this.setProgress(true);
        this.$store.dispatch('TTCalendarsStore/getCalendarJobs', {date, view}).then(() => {
          this.setProgress(false);
        });
      },
      setProgress(value) {
        this.closeModal();
        value ? this.$store.commit('GlobalStore/startProgress') : this.$store.commit('GlobalStore/stopProgress');
      },
      closeModal() {
        this.$emit('closemodal');
      },
    },
    mounted() {
      const eventType = this.isSingleEventType ? 1 : '';
      const initParams = this.startTime && this.finishTime
        ? {
          eventType,
          startTime: this.startTime.format('HH:mm'),
          finishTime: this.finishTime.format('HH:mm'),
          startDate: this.startTime.format('YYYY-MM-DD'),
          finishDate: this.finishTime.format('YYYY-MM-DD')
        }
        : {};
      const eventTypeParams = this.eventTypes.find((type) => {
        return type.value == eventType;
      })?.params || {};

      this.clearStore();
      this.setInitPrivateEvent(initParams);
      this.catchEventType(eventTypeParams);
      this.setPrivateEvents([]);
    }
  };
</script>

<style scoped>
  .add-private-event {
    padding: 20px 30px 30px;
  }

  .add-private-event__radios-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .add-private-event__radio-wrapper {
    width: calc(100% - 10px);
  }

  .add-private-event__radio-description {
    margin-top: 10px;
    font-size: 12px;
  }

  .add-private-event__submit {
    width: auto;
    margin-top: 15px;
    margin-left: auto;
    padding: 0 20px;
  }
</style>
