export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      currentUserUid: '',
      notes: [],
      userInfo: {},
    },
    actions: {
      fetchUserNotes({commit}, {uid}) {
        return new Promise((resolve, reject) => {
          fetchData.fetchUserNotes(uid).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setNotes', data.notes);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      addUserNote({commit}, {uid, params}) {
        return new Promise((resolve, reject) => {
          fetchData.addUserNote(uid, params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      editUserNote({commit}, {uid, noteId, params}) {
        return new Promise((resolve, reject) => {
          fetchData.editUserNote(uid, noteId, params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      }
    },
    mutations: {
      setNotes(state, data) {
        state.notes = data;
      },
      setCurrentUserId(state, data) {
        state.currentUserUid = data;
      },
      setUserInfo(state, interpreterSkill) {
        state.userInfo = interpreterSkill;
      },
      cleanStore(state) {
        state.currentUserId = '';
        state.notes = [];
        state.userInfo = {};
      }
    }
  };
};
