(<template>
  <div class="post-form__section booking-ref">
    <div v-if="showBookingReferenceField"
         class="post-form__field post-form__field--half-width booking-ref__field">
      <sk-input :description="bookingRefText"
                :preselected-value="bookingReference"
                :placeholder="bookingRefText"
                :validation-name="bookingRefErrorName"
                :reg-exp="/[^0-9a-zA-ZÆØÅæøå\/:\-\\ ]/g"
                class="booking-ref__input"
                @fieldvaluechanged="bookingInputHandler" />
      <p v-if="showBookingRegExpHint"
         class="sk-input-warning-hint">{{ bookingRegExpHint }}</p>
      <sk-checkbox v-if="showBookingReferenceSettingsCheckbox"
                   :preselected-value="bookingRefSettingsCheckbox"
                   :item-text="bookingRefSettingsCheckboxText"
                   class="sk-checkbox--top-alignment booking-ref__checkbox dynamic-content"
                   @checkboxvaluechanged="setBookingRefSettingsCheckbox" />
    </div>
    <div v-if="showPaymentBookingReferenceField"
         class="post-form__field post-form__field--half-width booking-ref__field">
      <sk-input :description="paymentBookingRefText"
                :preselected-value="paymentBookingReference"
                :placeholder="paymentBookingRefText"
                :validation-name="paymentBookingRefErrorName"
                :reg-exp="/[^0-9a-zA-ZÆØÅæøå\/:\-\\ ]/g"
                class="booking-ref__input"
                @fieldvaluechanged="paymentBookingInputHandler" />
      <p v-if="showPaymentBookingRegExpHint"
         class="sk-input-warning-hint">{{ paymentBookingRegExpHint }}</p>
      <sk-checkbox v-if="showPaymentBookingReferenceSettingsCheckbox"
                   :preselected-value="paymentBookingRefSettingsCheckbox"
                   :item-text="paymentBookingRefSettingsCheckboxText"
                   class="sk-checkbox--top-alignment booking-ref__checkbox dynamic-content"
                   @checkboxvaluechanged="setPaymentBookingRefSettingsCheckbox" />
    </div>
  </div>
</template>)

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';

  export default {
    props: {
      params: {
        type: Object,
        default: () => {
          return {
            bookerDefaultBookingReference: '',
            bookerDefaultPaymentBookingReference: ''
          };
        }
      }
    },
    data() {
      return {
        bookingRefSettingsCheckbox: false,
        paymentBookingRefSettingsCheckbox: false
      };
    },
    computed: {
      ...mapGetters('PostingStore/PaymentMethodStore', ['currentPaymentMethodData']),
      ...mapGetters('UserInfoStore', [
        'labelForBookingRef',
        'labelForPaymentBookingRef',
        'allowToEditBookingRefOnPosting',
        'allowToEditPaymentBookingRefOnPosting'
      ]),
      ...mapGetters('PostingNotifierStore', [
        'showBookingReferenceField',
        'showPaymentBookingReferenceField'
      ]),
      ...mapState('PostingStore/BookingRefStore', {
        bookingReference: ({bookingReference}) => bookingReference,
        initialBookingReference: ({initialBookingReference}) => initialBookingReference,
        paymentBookingReference: ({paymentBookingReference}) => paymentBookingReference,
        initialPaymentBookingReference: ({initialPaymentBookingReference}) => initialPaymentBookingReference,
        // error names
        bookingRefErrorName: (state) => state.errors.bookingRef.name,
        paymentBookingRefErrorName: (state) => state.errors.paymentBookingRef.name
      }),
      bookingRefText() { return this.labelForBookingRef || this.$gettext('Booking ref.'); },
      paymentBookingRefText() { return this.labelForPaymentBookingRef || this.$gettext('Attestant BID'); },
      bookerDefaultBookingReference() {
        return this.params.bookerDefaultBookingReference;
      },
      bookerDefaultPaymentBookingReference() {
        return this.params.bookerDefaultPaymentBookingReference;
      },
      showBookingReferenceSettingsCheckbox() {
        return this.bookerDefaultBookingReference
          && this.allowToEditBookingRefOnPosting;
      },
      showPaymentBookingReferenceSettingsCheckbox() {
        return this.bookerDefaultPaymentBookingReference
          && this.allowToEditPaymentBookingRefOnPosting;
      },
      bookingRefSettingsCheckboxText() {
        return this.getReferenceSettingsCheckboxText(
          this.bookerDefaultBookingReference,
          this.labelForBookingRef
        );
      },
      paymentBookingRefSettingsCheckboxText() {
        return this.getReferenceSettingsCheckboxText(
          this.bookerDefaultPaymentBookingReference,
          this.labelForPaymentBookingRef
        );
      },
      bookingRegExp() { return new RegExp(this.currentPaymentMethodData.bookingReferenceRegex || ''); },
      paymentBookingRegExp() { return new RegExp(this.currentPaymentMethodData.paymentBookingReferenceRegex || ''); },
      defaultRegExpHint() {
        const template = this.$gettext('%{orgname} requires a specific payment booking reference format. The text you have provided does not match.');
        return this.$gettextInterpolate(template, {orgname: this.currentPaymentMethodData.orgName});
      },
      bookingRegExpHint() { return this.currentPaymentMethodData.bookingReferenceRegexHint || this.defaultRegExpHint; },
      paymentBookingRegExpHint() { return this.currentPaymentMethodData.paymentBookingReferenceRegexHint || this.defaultRegExpHint; },
      showBookingRegExpHint() { return !this.bookingRegExp.test(this.bookingReference) && this.bookingReference != ''; },
      showPaymentBookingRegExpHint() { return !this.paymentBookingRegExp.test(this.paymentBookingReference) && this.paymentBookingReference != ''; }
    },
    methods: {
      ...mapMutations('PostingStore/BookingRefStore', [
        'setBookingReference',
        'setPaymentBookingReference',
        'setFromBookingReference',
        'setFromPaymentBookingReference'
      ]),
      getReferenceSettingsLink(membersCanOverride, departmentsCanOverride) {
        return membersCanOverride
          ? this.$router.resolve(this.$makeRoute({name: 'BuyerInvoiceSettings', query: {view: 'employees'}})).href
          : departmentsCanOverride
            ? this.$router.resolve(this.$makeRoute({name: 'BuyerInvoiceSettings', query: {view: 'departments'}})).href
            : this.$router.resolve(this.$makeRoute({name: 'BuyerInvoiceSettings', query: {view: 'settings'}})).href;
      },
      getReferenceSettingsCheckboxText(referenceValue = '', referenceLabel = '', referenceLink = '') {
        const template = this.$gettext('Use %{referenceLabel} %{referenceValue} from <a class="sk-link sk-link--default js-dynamic-content-link">the Invoice settings</a>');

        return this.$gettextInterpolate(template, {
          referenceValue,
          referenceLabel,
          referenceLink
        });
      },
      setBookingRefSettingsCheckbox(value) {
        this.bookingRefSettingsCheckbox = value;
        this.setBookingReference(
          value
            ? this.bookerDefaultBookingReference
            : this.initialBookingReference
        );
      },
      setPaymentBookingRefSettingsCheckbox(value) {
        this.paymentBookingRefSettingsCheckbox = value;
        this.setPaymentBookingReference(
          value
            ? this.bookerDefaultPaymentBookingReference
            : this.initialPaymentBookingReference
        );
      },
      handleClickOnDynamicContent(event) {
        this.$store.dispatch('ModalStore/closeModal');
        return helpers.clickOnDynamicContent(this, event);
      },
      bookingInputHandler(value) {
        if (value != this.initialBookingReference) {
          this.setFromBookingReference('user');
        }
        this.setBookingReference(value);
      },
      paymentBookingInputHandler(value) {
        if (value != this.initialPaymentBookingReference) {
          this.setFromPaymentBookingReference('user');
        }
        this.setPaymentBookingReference(value);
      }
    }
  };
</script>

<style>
  .booking-ref__field .sk-input__input:read-only {
    padding: 0;
    border: none;
    pointer-events: none;
  }

  .booking-ref__field .sk-input__input:read-only:hover,
  .booking-ref__field .sk-input__input:read-only:active {
    border: none;
    box-shadow: none;
    outline: none;
  }
</style>

<style scoped>
  .booking-ref__input {
    margin-bottom: 0;
  }

  .booking-ref__input-note {
    margin-top: 5px;
    font-weight: bold;
    font-size: 12px;
  }

  .booking-ref__checkbox {
    margin-top: 5px;
  }

  .sk-input-warning-hint {
    margin-top: 5px;
  }
</style>
