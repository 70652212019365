export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      featureFlags: {}
    },
    getters: {
      enableNewHeaderForSupplier(state) {
        return state.featureFlags?.new_header_supplier;
      },
      enableNewHeaderForEnterprise(state) {
        return state.featureFlags?.new_header_enterprise;
      },
      enableFaceliftedDesign(state) {
        const faceliftEnable = state.featureFlags?.facelifted_design;
        return faceliftEnable || false;
      },
    },
    actions: {
      fetchFeatureFlags({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getFeatureFlags().then((data) => {
            commit('setFeatureFlags', data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
        });
      },
    },
    mutations: {
      setFeatureFlags(state, value) {
        state.featureFlags = value;
      },
    }
  };
};
