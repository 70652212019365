const videoTests = {
  methods: {
    createSession() {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/video_tests').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    startTest(form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/video_tests/start', form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    endTest(id, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/video_tests/${id}/stop`, form).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getOneRecording(id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/video_tests/${id}`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    deleteRecording(id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.delete(`/v1/video_tests/${id}`).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    savePoll(id, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/video_tests/${id}`, form).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getRecordings() {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get('/v1/video_tests').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getVideoPlatformsList() {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get('/v1/video_platforms').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
  }
};

export default videoTests;
