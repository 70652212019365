(<template>
  <div class="post-form__section languages">
    <sk-select :items-list="languagesList"
               :description="langToText"
               :preselected-value="langTo"
               :default-value="langDefaultValue"
               :validation-name="langToErrorsName"
               class="post-form__field"
               @csvaluechanged="setLangTo" />
  </div>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';

  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('PostingStore/LanguagesStore', {
        langTo: (state) => state.languageTo,
        langToErrorsName: (state) => state.errors.langTo.name
      }),
      langToText() { return this.$gettext('Select foreign language'); },
      langDefaultValue() { return this.$gettext('Select a language'); },
      languages() { return this.$store.state.selectsData.language || []; },
      languagesList() {
        const languagesList = [...this.languages];
        languagesList.unshift({id: 'not_important', name: this.$gettext('Not important')});
        return languagesList.filter((lang) => lang.id != 3);
      }
    },
    methods: {
      ...mapMutations('PostingStore/LanguagesStore', ['setLangTo'])
    }
  };
</script>
