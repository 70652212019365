export default (addressObj) => {
  const {line1, line2, postcode, city, county, country} = {...addressObj};
  return ''.concat(
    line1 ? `${line1}, ` : '',
    line2 ? `${line2}, ` : '',
    postcode ? `${postcode}, ` : '',
    county ? `${county}, ` : '',
    city ? `${city}, ` : '',
    country
  );
};
