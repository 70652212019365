<template>
  <sk-select :description="description"
             :default-value="defaultValue"
             :items-list="itemsList"
             :reg-exp="regExp"
             :preselected-value="preselectedValue"
             :validation-name="validationName"
             :dynamic-search="true"
             :search-by="setSearchQuery"
             @csvaluechanged="changeFieldData" />
</template>

<script>
  import SearchPrototype from '@/components/shared_components/form_fields/SearchPrototype';

  export default {
    extends: SearchPrototype,
    props: {
      submitAfterCondition: {
        type: Function,
        default: () => {},
        required: false
      },
      preselectedValue: {
        type: [String, Array, Number],
        required: true
      },
      description: {
        type: String,
        default: ''
      },
      defaultValue: {
        type: String,
        default: ''
      },
      validationName: {
        type: String,
        default: ''
      },
      itemsList: {
        type: Array,
        default: () => []
      },
      regExp: {
        type: RegExp,
        default: ''
      }
    },
    methods: {
      changeFieldData(value) {
        this.$emit('csvaluechanged', value);
      },
      resetFilter() {
        this.$emit('onresetfilter');
        this.changeFieldData('');
        this.setSearchQueryMutation('');
      }
    }
  };
</script>
