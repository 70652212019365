export default ({$fetcher: fetchData, $moment: moment}) => {
  return {
    namespaced: true,
    state: {
      calendarJobs: ''
    },
    actions: {
      fetchCalendarJobs({commit}, {userId, date}) {
        function updateTimeToLocal(data) {
          if (data && data.jobs && data.jobs.length) {
            data.jobs.map((item) => {
              item.startTime = moment(item.startTime);
              item.finishTime = moment(item.finishTime);
            });
          }
        }
        return new Promise((resolve, reject) => {
          fetchData.fetchCalendarJobs(userId, date).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            updateTimeToLocal(data);
            commit('setCalendarJobs', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      }
    },
    mutations: {
      setCalendarJobs(state, data) {
        state.calendarJobs = data;
      },
      removeCalendarJobs(state) {
        state.calendarJobs = '';
      }
    }
  };
};
