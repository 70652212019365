<script>
  import {mapGetters} from 'vuex';
  import helpers from '@/helpers';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter']),
      isAwardedJob() { return this.jobStatus == 'accepted' || this.jobStatus == 'in_progress'; }
    },
    methods: {
      getJobPublishedAtObj({publishedAt, id, seriesOrderId, seriesSequenceNumber, category, subject}) {
        const template = this.$gettext('Published: %{ date }');
        const computedId = helpers.getJobInfo.jobId({id, seriesOrderId, seriesSequenceNumber});
        const title = this.$gettextInterpolate(template, {date: this.$moment(publishedAt).format('DD.MM.YYYY HH:mm')});
        const data = `#${computedId} - ${category || ''} ${subject}`;
        return {title, data};
      },
      getJobDate(dateTime) {
        const dateMoment = this.$moment(dateTime);
        const dayName = this.$helperData.getDayNames(this)[dateMoment.day()].slice(0, 3);
        const monthName = this.$helperData.getMonthNames(this)[dateMoment.month()].slice(0, 3);
        const yearName = dateMoment.year() != this.$moment().year() ? dateMoment.year() : '';

        return `${dayName} ${dateMoment.date()} ${monthName} ${yearName}`;
      },
      getJobTime(dateTime) {
        return this.$moment(dateTime).format('HH:mm');
      },
      getJobDateTimeArr(dateTime) {
        const jobDate = this.getJobDate(dateTime);
        const jobTime = this.getJobTime(dateTime);
        return [jobDate, jobTime];
      },
      getJobStartTimeObj(dateTime) {
        const data = this.getJobDateTimeArr(dateTime).join('<br>');
        return {title: this.$gettext('Start at'), data};
      },
      getJobFinishTimeObj(dateTime) {
        const data = this.getJobDateTimeArr(dateTime).join('<br>');
        return {title: this.$gettext('Finish at'), data};
      },
      getJobDeadlineObj(dateTime) {
        const data = this.getJobDateTimeArr(dateTime).join(' ');
        return {title: this.$pgettext('translation', 'Deadline'), data};
      },
      getJobType(sessionType) {
        return helpers.getJobInfo.jobType(sessionType, this) || this.$gettext('Default');
      },
      getJobStatus(jobStatus) {
        return helpers.getJobInfo.extendedJobStatusObj(this, jobStatus);
      },
      getLangObj({langFromId, langToId}) {
        const langFrom = this.$getLangName(langFromId);
        const langTo = this.$getLangName(langToId);
        const data = langFrom && langTo ? `${langFrom} - ${langTo}` : '';

        return {title: this.$gettext('Language'), data};
      }
    }
  };
</script>
