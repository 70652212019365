(<template>
  <article class="contact-admin">
    <div class="contact-admin__logo-cont">
      <div class="contact-admin__logo"></div>
    </div>
    <p class="contact-admin__main-text">{{ $gettext('Please contact us') }}</p>
    <p class="contact-admin__regular-text"
       v-html="warningText"></p>
    <button class="sk-btn sk-btn--default contact-admin__btn"
            @click="openHelpModal">{{ $gettext('Contact us') }}</button>
  </article>
</template>)

<script>
  export default {
    computed: {
      warningText() {
        const template = this.$gettext('Someone from your organisation is already using the platform and has created a enterprise account. Please contact platform suppost <a href="tel:%{phone}" class="sk-link js-copy-to-clipboard">%{phone}</a>.');
        return this.$gettextInterpolate(template, {fullPhone: this.supportPhone, phone: this.supportPhoneText});
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      openHelpModal() {
        this.closeModal();
        this.$store.commit('ModalStore/setModal', {
          component: 'help-modal',
          data: {
            title: this.$gettext('Contact us'),
            context: this
          }
        });
      }
    }
  };
</script>

<style scoped>
  .contact-admin {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: -15px;
    padding: 45px 40px 30px;
    background-color: #fff;
    text-align: center;
  }

  .contact-admin__logo-cont {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .contact-admin__logo {
    display: block;
    width: 75px;
    height: 75px;
    background-image: url(~@assets/imgs/logos/single_logo_red.svg);
    background-image: var(--image-single-logo);
    background-position: 50% 50%;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .contact-admin__main-text {
    font-size: 25px;
  }

  .contact-admin__regular-text {
    margin-bottom: 20px;
    font-size: 12px;
  }

  .contact-admin__btn {
    width: auto;
    margin-top: 10px;
    padding: 0 60px;
  }
</style>
