export default ({$fetcher: fetchData}) => {
  // We should to do clean up for this store
  return {
    namespaced: true,
    state: {
      settings: '',
      labelsList: '',
      departmentsList: '',
      filteredDepartmentsList: '',
      employeesList: '',
      filteredEmployeesList: '',
      filterValue: ''
    },
    actions: {
      getInvoicingDepartmentsList({rootGetters, commit}) {
        return new Promise((resolve, reject) => {
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'];

          fetchData.getInvoicingDepartmentsList(enterpriseId).then((data = {}) => {
            commit('setDepartmentsList', data.departments);
            commit('setFilteredDepartmentsList', data.departments);
            resolve(data.departments);
          }).catch((data) => {
            reject(data);
          });
        });
      },
      getFilteredInvoicingDepartmentsList({rootGetters, commit, dispatch}, departmentId) {
        return new Promise((resolve, reject) => {
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'];
          const promise = departmentId
            ? fetchData.getFilteredInvoicingDepartmentsList(enterpriseId, departmentId)
            : dispatch('getInvoicingDepartmentsList');
          promise.then((data = {}) => {
            commit('setFilteredDepartmentsList', data);
            resolve(data);
          }).catch((data) => {
            reject(data);
          });
        });
      },
      saveDepartmentInvoiceSettings({rootGetters}, {departmentId, params}) {
        return new Promise((resolve, reject) => {
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'];

          fetchData.saveDepartmentInvoiceSettings(enterpriseId, departmentId, params).then((data = {}) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      saveEmployeeInvoiceSettings({rootGetters}, {employeeUid, params}) {
        return new Promise((resolve, reject) => {
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'];

          fetchData.saveEmployeeInvoiceSettings(enterpriseId, employeeUid, params).then((data = {}) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getInvoicingEmployeesList({rootGetters, commit}) {
        return new Promise((resolve, reject) => {
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'];

          fetchData.getInvoicingEmployeesList(enterpriseId).then((data = {}) => {
            commit('setEmployeesList', data.employees);
            commit('setFilteredEmployeesList', data.employees);
            resolve(data.employees);
          }).catch((data) => {
            reject(data);
          });
        });
      },
      getFilteredInvoicingEmployeesList({rootGetters, commit, dispatch}, employeeUid) {
        return new Promise((resolve, reject) => {
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'];
          const promise = employeeUid
            ? fetchData.getFilteredInvoicingEmployeesList(enterpriseId, employeeUid)
            : dispatch('getInvoicingEmployeesList');
          promise.then((data = {}) => {
            commit('setFilteredEmployeesList', data);
            resolve(data);
          }).catch((data) => {
            reject(data);
          });
        });
      },
      createForm({state}) {
        const form = new FormData();
        const {bookingReference = {}, paymentBookingReference = {}} = state.settings;

        form.append('setting[allow_booking_reference]', bookingReference.allowed);
        form.append('setting[require_booking_reference]', bookingReference.allowed ? bookingReference.required : false);
        form.append('setting[label_for_booking_reference]', bookingReference.label);
        form.append('setting[default_booking_reference]', bookingReference.value);
        form.append('setting[departments_can_override_booking_reference]', bookingReference.departmentsCanOverride);
        form.append('setting[members_can_override_booking_reference]', bookingReference.membersCanOverride);
        form.append('setting[allow_payment_booking_reference]', paymentBookingReference.allowed);
        form.append('setting[require_payment_booking_reference]', paymentBookingReference.allowed ? paymentBookingReference.required : false);
        form.append('setting[label_for_payment_booking_reference]', paymentBookingReference.label);
        form.append('setting[default_payment_booking_reference]', paymentBookingReference.value);
        form.append('setting[departments_can_override_payment_booking_reference]', paymentBookingReference.departmentsCanOverride);
        form.append('setting[members_can_override_payment_booking_reference]', paymentBookingReference.membersCanOverride);

        return Promise.resolve(form);
      }
    },
    mutations: {
      setFilterValue(state, data) {
        state.filterValue = data;
      },
      setDepartmentsList(state, data) {
        state.departmentsList = data;
      },
      setFilteredDepartmentsList(state, data) {
        state.filteredDepartmentsList = Array.isArray(data) ? data : [data];
      },
      setSettingForFilteredDepartment(state, {departmentId, property, value}) {
        state.filteredDepartmentsList = state.filteredDepartmentsList.map((department = {}) => {
          if (department.id === departmentId) {
            department[property] = value;
          }
          return department;
        });
      },
      setSettingForFilteredEmployee(state, {employeeUid, property, value}) {
        state.filteredEmployeesList = state.filteredEmployeesList.map((employee = {}) => {
          if (employee.uid === employeeUid) {
            employee[property] = value;
          }
          return employee;
        });
      },
      setEmployeesList(state, data) {
        state.employeesList = data;
      },
      setFilteredEmployeesList(state, data) {
        state.filteredEmployeesList = Array.isArray(data) ? data : [data?.employee];
      },
      clearStore(state) {
        state.departmentsList = '';
        state.filteredDepartmentsList = '';
        state.employeesList = '';
        state.filteredEmployeesList = '';
        state.filterValue = '';
      }
    }
  };
};
