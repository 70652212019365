(<template>
  <article class="confirm-edit">
    <p class="confirm-edit__text"
       v-html="confirmationText"></p>
    <div class="confirm-edit__btn-cont">
      <button class="sk-btn sk-btn--white confirm-edit__btn confirm-edit__btn--cancel"
              @click="closeModal">{{ $gettext('Cancel') }}</button>
      <button class="sk-btn sk-btn--default confirm-edit__btn confirm-edit__btn--edit"
              @click="emitEdit">{{ $gettext('Edit assignment') }}</button>
    </div>
  </article>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      confirmationText() {
        return this.data.text || '';
      },
      context() {
        return this.data.context;
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      emitEdit() {
        this.context.goToEditForm();
        this.closeModal();
      }
    }
  };
</script>

<style scoped>
  .confirm-edit {
    display: block;
    width: 100%;
    padding: 20px 30px;
  }

  .confirm-edit__title {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }

  .confirm-edit__text {
    text-align: center;
  }

  .confirm-edit__btn-cont {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
  }

  .confirm-edit__btn {
    display: inline-block;
    width: auto;
    padding: 0 30px;
  }

  .confirm-edit__btn--cancel {
    flex-shrink: 0;
  }

  .confirm-edit__btn--edit {
    width: 100%;
    margin-left: 20px;
  }
</style>
