<template>
  <date-range-picker
    ref="picker"
    v-model="dateRangeLocal"
    :locale-data="localeData"
    class="date-range-picker"
    :append-to-body="config.appendToBody"
    :opens="config.opens"
    :ranges="ranges"
    @update="onUpdate" />
</template>

<script>
  import DateRangePicker from 'vue2-daterange-picker';
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

  export default {
    components: {
      DateRangePicker,
    },
    props: {
      dateRange: {
        type: Object,
        default: () => {}
      },
      config: {
        type: Object,
        default: () => {
          return {
            appendToBody: false,
            opens: 'right'
          };
        }
      }
    },
    data() {
      return {
        dateRangeLocal: {}
      };
    },
    computed: {
      localeData() {
        return {direction: 'ltr',
                format: 'dd/mm/yyyy',
                separator: ' - ',
                applyLabel: this.$gettext('Apply'),
                cancelLabel: this.$gettext('Cancel'),
                weekLabel: 'W',
                customRangeLabel: this.$gettext('Custom Range'),
                daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                firstDay: 0};
      },
      ranges() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);
        if (this.$language.current === 'nb') {
          return {
            'I dag': [today, today],
            'I går': [yesterday, yesterday],
            'Denne måneden': [new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0)],
            'I år': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
            'Forrige måned': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
          };
        } else {
          return {
            Today: [today, today],
            Yesterday: [yesterday, yesterday],
            'This month': [new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0)],
            'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
            'Last Month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
          };
        }
      }
    },
    methods: {
      onUpdate(data) {
        this.$emit('update', data);
      }
    },
    mounted() {
      this.dateRangeLocal = this.dateRange;
    }
  };
</script>
