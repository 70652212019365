(<template>
  <article class="undefined-error-modal">
    <div class="undefined-error-modal__logo-cont">
      <div class="undefined-error-modal__logo"></div>
    </div>
    <p class="undefined-error-modal__main-text">{{ $gettext('Unfortunately we were not able to complete your request.') }}</p>
    <p class="undefined-error-modal__secondary-text">{{ $gettext('Our customer support has been notified. We are taking care of it.') }}</p>
    <p class="undefined-error-modal__regular-text">{{ $gettext('Make sure you have access to the thing you tried to change.') }}</p>
    <p class="undefined-error-modal__regular-text"
       v-html="supportText"></p>
    <button class="sk-btn sk-btn--default undefined-error-modal__btn"
            @click="$emit('closemodal')">{{ $gettext('Got it!') }}</button>
  </article>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsEnterpriseMember']),
      supportEmail() {
        switch (this.$route.name) {
          case 'BuyerOneProject':
          case 'BuyerPostTranslationProject':
            return this.$getString('domainData', 'supportTranslationEmail');
          case 'BuyerOneAssignment':
          case 'BuyerPostInterpretation':
          case 'BuyerPostFastInterpretation':
          case 'BuyerPostSeriesInterpretation':
          case 'BuyerPostSecretaryInterpretation':
            return this.$getString('domainData', 'subSupportEmail');
          default:
            return this.$getString('domainData', 'supportEmail');
        }
      },
      supportText() {
        const template = this.userIsEnterpriseMember
          ? this.$gettext('Please contact your administrator or <a href="mailto:%{support}" target="_blank" class="sk-link"> us </a>')
          : this.$gettext('Please contact <a href="mailto:%{support}" target="_blank" class="sk-link"> us </a>');
        return this.$gettextInterpolate(template, {support: this.supportEmail});
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
    },
    mounted() {
      const payload = {
        context: 'Undefined Error Modal',
        error: {
          data: this.data?.error?.data?.errors,
          status: this.data?.error?.status,
          statusText: this.data?.error?.statusText,
          config: this.data?.error?.config,
        },
        page: this.$route.fullPath
      };
      this.$store.dispatch('sendLog', payload);
      this.$emit('stopprogress');
    }
  };
</script>

<style scoped>
  .undefined-error-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: -15px;
    padding: 45px 40px 30px;
    background-color: #fff;
    text-align: center;
  }

  .undefined-error-modal__logo-cont {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .undefined-error-modal__logo {
    display: block;
    width: 75px;
    height: 75px;
    background-image: url(~@assets/imgs/logos/single_logo_red.svg);
    background-image: var(--image-single-logo);
    background-position: 50% 50%;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .undefined-error-modal__main-text {
    margin-bottom: 10px;
    font-size: 25px;
  }

  .undefined-error-modal__secondary-text {
    margin-bottom: 25px;
    font-size: 16px;
  }

  .undefined-error-modal__regular-text {
    margin-bottom: 20px;
    font-size: 12px;
  }

  .undefined-error-modal__btn {
    width: auto;
    margin-top: 10px;
    padding: 0 60px;
  }
</style>
