(<template>
  <div class="add-event">
    <div v-if="!simpleEvent"
         class="add-event__title">
      <sk-input :description="titleText"
                :preselected-value="title"
                :placeholder="titlePlaceholder"
                :validation-name="titleValidationName"
                @fieldvaluechanged="setTitle" />
    </div>
    <div class="add-event__dates">
      <sk-datepicker :preselected-value="startDate"
                     :description="dateText"
                     :default-value="'--/--/----'"
                     :blocked-dates-array="blockedDatesArray"
                     class="add-event__datepicker"
                     @changeday="setEventStartDate" />
      <sk-timepicker v-if="!allDay"
                     :preselected-value="startTime"
                     :default-value="'--:--'"
                     :time-step="timeStep"
                     :mob-numeric-keyboard="true"
                     :description="startTimeText"
                     :validation-name="startTimeValidationName"
                     class="add-event__timepicker"
                     @timepickervaluechanged="setEventStartTime" />
      <span v-if="!simpleEvent"
            class="add-event__separator">{{ $gettext('to') }}</span>
      <sk-timepicker v-if="!allDay"
                     :preselected-value="finishTime"
                     :default-value="'--:--'"
                     :time-step="timeStep"
                     :description="finishTimeText"
                     :last-item="true"
                     :mob-numeric-keyboard="true"
                     :start-select-from="startFinishSelectFrom"
                     class="add-event__timepicker add-event__timepicker--finish"
                     @timepickervaluechanged="setEventFinishTime" />
      <sk-datepicker v-if="!simpleEvent"
                     :preselected-value="finishDate"
                     :description="dateText"
                     :default-value="'--/--/----'"
                     :blocked-dates-array="blockedDatesArray"
                     class="add-event__datepicker"
                     @changeday="setEventFinishDate" />
      <sk-checkbox v-if="showAllDayCheckbox && !simpleEvent"
                   :item-text="allDayText"
                   :preselected-value="allDay"
                   class="add-event__all-day"
                   @checkboxvaluechanged="onCheckboxChanged" />
    </div>
  </div>
</template>)

<script>
  import {mapMutations, mapState} from 'vuex';
  import constants from '@/modules/constants';

  export default {
    props: {
      simpleEvent: Boolean, // event without end date, all day option, title
      timeStep: {
        type: Number,
        default: constants.TIMEPICKER_STEP
      }
    },
    computed: {
      ...mapState('TTPrivateEventsStore', {
        privateEvent: ({privateEvent}) => privateEvent || {},
        // validation names
        titleValidationName: ({errors}) => errors.title.name,
        startTimeValidationName: ({errors}) => errors.startTime.name
      }),
      title() { return this.privateEvent.title || ''; },
      allDay() { return this.privateEvent.allDay; },
      startDate() {
        const {startDate} = this.privateEvent;
        return startDate ? this.$moment(startDate).format('YYYY-MM-DD') : '';
      },
      finishDate() {
        const {finishDate} = this.privateEvent;
        return finishDate ? this.$moment(finishDate).format('YYYY-MM-DD') : '';
      },
      startTime() { return this.privateEvent.startTime; },
      finishTime() { return this.privateEvent.finishTime; },
      titleText() { return this.$gettext('Event title'); },
      titlePlaceholder() { return this.$gettext('Add title'); },
      dateText() { return this.$gettext('Date'); },
      startTimeText() { return this.$gettext('Start time'); },
      finishTimeText() { return this.$gettext('Finish time'); },
      allDayText() { return this.$gettext('All day'); },
      startFinishSelectFrom(state) {
        const defaultSelectFrom = '00:00';
        if (state.startDate != state.finishDate) {
          return defaultSelectFrom;
        }
        const startFinishSelectFrom = this.$moment(state.startTime, 'HH:mm').add(this.timeStep, 'minutes').format('HH:mm');
        return startFinishSelectFrom === '00:00' ? '00:15' : startFinishSelectFrom;
      },
      showAllDayCheckbox() { return this.startDate != this.$moment().format('YYYY-MM-DD'); },
      blockedDatesArray() {
        return this.allDay ? [this.$moment().format('YYYY-MM-DD')] : [];
      }
    },
    methods: {
      ...mapMutations('TTPrivateEventsStore', [
        'setStartDate',
        'setFinishDate',
        'setStartTime',
        'setFinishTime',
        'setTitle',
        'setAllDay'
      ]),
      setEventStartTime(value) {
        this.setStartTime({value, step: this.timeStep});
      },
      setEventFinishTime(value) {
        this.setFinishTime({value, step: this.timeStep});
      },
      setEventFinishDate(value) {
        this.setFinishDate(value);
        this.setEventFinishTime(this.finishTime);
      },
      setEventStartDate(value) {
        this.setStartDate(value);
        this.setEventFinishTime(this.finishTime);
        if (this.simpleEvent) {
          this.setEventFinishDate(value);
        }
      },
      onCheckboxChanged(value) {
        this.setAllDay(value);
      }
    }
  };
</script>

<style>
  .add-event__datepicker .sk-month-calendar__wrapper.sk-date-picker__calendar,
  .add-event__timepicker .sk-time-picker__list {
    top: -2px;
    margin-top: 0;
    transform: translate(0, -100%);
  }
</style>

<style scoped>
  .add-event {
    margin: 20px 0 0;
  }

  .add-event__dates {
    display: flex;
    align-items: flex-start;
  }

  .add-event__datepicker {
    flex-shrink: 0;
    width: 100px;
    margin-right: 10px;
  }

  .add-event__timepicker {
    flex-shrink: 0;
    width: 75px;
    margin-right: 10px;
  }

  .add-event__separator {
    display: flex;
    align-items: center;
    margin: 0 15px 0 5px;
  }

  .add-event__all-day,
  .add-event__separator {
    width: auto;
    margin-top: 26px;
  }

  @media (max-width: 1023px) {
    .add-event__dates {
      flex-wrap: wrap;
    }

    .add-event__separator {
      width: 100%;
      margin: 15px 0;
    }

    .add-event__all-day {
      order: 2;
      width: 100%;
    }

    .add-event__timepicker--finish {
      order: 1;
    }
  }
</style>
