export default () => {
  return {
    namespaced: true,
    state: {
      toast: {
        component: '',
        progress: false,
        classes: [],
        data: {},
        errors: []
      }
    },
    getters: {
      toastComponent(state) {
        return state.toast.component;
      },
      toastData(state) {
        return state.toast.data;
      },
      toastProgress(state) {
        return state.toast.progress;
      },
      toastClasses(state) {
        return state.toast.classes;
      }
    },
    actions: {
      closeTooltip({commit}) {
        commit('removeTooltip');
        commit('stopTooltipProgress');
      }
    },
    mutations: {
      startToastProgress(state) {
        state.toast.progress = true;
      },
      stopToastProgress(state) {
        state.toast.progress = false;
      },
      setToast(state, {component, data, classes}) {
        state.toast.component = component;
        state.toast.data = data;
        state.toast.classes = classes || [];
      },
      removeTooltip(state) {
        state.toast.progress = false;
        state.toast.component = '';
        state.toast.data = {};
      }
    }
  };
};
