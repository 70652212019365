<template>
  <div>
    <span class="news-content"
          v-html="shortedText"></span>
    <button v-if="content.length >= 200"
            class="news__link"
            @click="showText">{{ $gettext('read more') }}</button>
  </div>
</template>
<script>
  export default {
    props: {
      content: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        showFullText: false
      };
    },
    computed: {
      shortedText() {
        let classText = this.content;
        const hrefPos = this.content.search('href="');
        if (hrefPos > 0) {
          classText = this.addString(classText, hrefPos, 'class="sk-link" target="_blank" ');
        }

        if (!this.showFullText && this.content.length >= 200) {
          return classText.slice(0, 200) + '...';
        }
        return classText;
      },
    },
    methods: {
      showText() {
        this.showFullText = !this.showFullText;
      },
      addString(str, index, stringToAdd) {
        return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
      }
    }
  };
</script>

<style scoped>
.news__link {
  font-weight: bold;
  text-decoration: underline;
}
</style>
