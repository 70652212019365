import { render, staticRenderFns } from "./AttachmentsInnerSection.vue?vue&type=template&id=797a0b78&scoped=true&"
import script from "./AttachmentsInnerSection.vue?vue&type=script&lang=js&"
export * from "./AttachmentsInnerSection.vue?vue&type=script&lang=js&"
import style0 from "./AttachmentsInnerSection.vue?vue&type=style&index=0&id=797a0b78&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "797a0b78",
  null
  
)

export default component.exports