(<template>
  <article class="cooperation-agreement">
    <h2 class="cooperation-agreement__title">{{ $gettext('Cooperation agreement') }}</h2>
    <p class="cooperation-agreement__text"
       v-html="confirmationText"></p>
    <button class="cooperation-agreement--downloading"
            @click="downloadDoc"> {{ $gettext('Download the cooperation agreement') }}</button>
    <sk-checkbox :item-text="downloadedFileText"
                 :preselected-value="docOpened"
                 class="cooperation-agreement__checkbox"
                 @checkboxvaluechanged="setDownloadedCoopFile" />
    <sk-checkbox :item-text="agreeCooperationText"
                 :preselected-value="agreeCooperation"
                 class="cooperation-agreement__checkbox"
                 @checkboxvaluechanged="setAgreeCooperation" />
    <div class="cooperation-agreement__btn-cont">
      <p class="cooperation-agreement__deadline-text">{{ deadlineText }}</p>
      <button class="sk-btn sk-btn--white cooperation-agreement__btn cooperation-agreement__btn--cancel"
              :class="{'is-disabled': disableCloseModal}"
              @click="closeModal">{{ $gettext('Later') }}</button>
      <button class="sk-btn sk-btn--default cooperation-agreement__btn cooperation-agreement__btn--sign"
              :class="{'is-disabled': !allowSignAgreement}"
              :disabled="!allowSignAgreement"
              @click="signAgreement">{{ $gettext('Sign the cooperation agreement') }}</button>
    </div>
  </article>
</template>)

<script>
  import {mapActions, mapState} from 'vuex';

  export default {

    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        agreeCooperation: false,
        docOpened: false
      };
    },
    computed: {
      ...mapState('TTSignaturesStore', {
        documentsForSigning: (state) => state.documentsForSigning
      }),
      confirmationText() {
        return this.$gettext('Updated collaboration agreement with all freelancers who take on assignments for Salita must be signed. This is done electronically. To avoid your account being suspended, we ask that you sign the agreement by February 4th 2023.</br> </br> The agreement consists of two parts. Part 1 is for you who undertake freelance assignments as an interpreter, and part 2 is for you who undertake freelance assignments as a translator.</br> </br> The agreement applies to the type of assignment you perform on behalf of Salita. If you have any questions, contact us at <a href=\"mailto:hr@salita.no\">hr@salita.no</a>');
      },
      agreeCooperationText() {
        return this.$gettext('I have read and understood the cooperation agreement');
      },
      downloadedFileText() {
        return this.$gettext('I confirm that I have downloaded the cooperation agreement');
      },
      cooperationDocument() {
        if (this.documentsForSigning && this.documentsForSigning.length) return this.documentsForSigning.find((file) => file.type === 'salita_agreement');
        return '';
      },
      allowSignAgreement() {
        return this.agreeCooperation && this.docOpened;
      },
      disableCloseModal() {
        const date = this.$moment(new Date());
        return date.isAfter(this.$moment(this.cooperationDocument?.requiredFromDate));
      },
      deadlineDate() {
        return this.cooperationDocument?.requiredFromDate ? this.$moment(this.cooperationDocument?.requiredFromDate).format('DD.MM.YYYY') : '31.12.2022';
      },
      deadlineText() {
        return this.$gettext('Signing deadline') + ': ' + this.deadlineDate;
      },
    },
    methods: {
      ...mapActions('TTSignaturesStore', ['uploadSignature', 'getDocForSigning']),
      setAgreeCooperation() {
        this.agreeCooperation = !this.agreeCooperation;
      },
      setDownloadedCoopFile() {
        this.docOpened = !this.docOpened;
      },
      downloadDoc() {
        this.getDocForSigning(this.cooperationDocument.id).then((link) => {
          window.location.href = link;
        });
      },
      signAgreement() {
        this.uploadSignature(this.cooperationDocument.id).then(() => {
          this.$store.dispatch('ModalStore/closeModal');
        });
      },
      closeModal() {
        if (!this.disableCloseModal) {
          this.$store.dispatch('ModalStore/closeModal');
        }
      },
    }
  };
</script>

<style scoped>
  .cooperation-agreement {
    z-index: 100;
    display: block;
    width: 100%;
    padding: 30px;
  }

  @media (max-width: 767px) {
    .cooperation-agreement__btn--cancel {
      height: 60px !important;
    }

    .cooperation-agreement--downloading {
      height: 50px !important;
    }

    .cooperation-agreement__btn--sign {
      height: 60px !important;
    }
  }

  .cooperation-agreement__deadline-text {
    width: 450px;
  }

  .cooperation-agreement__checkbox {
    margin-top: 12px;
  }

  .cooperation-agreement--downloading {
    display: inline-block;
    width: auto;
    height: 36px;
    margin-top: 15px;
    padding: 5px 10px 5px 30px;
    border: 1px solid var(--color-primary);
    border-radius: 3px;
    background-image: url(~@assets/imgs/salita/download_orange_icon.svg);
    background-position: 5px;
    background-repeat: no-repeat;
  }

  .cooperation-agreement__title {
    margin-top: -45px;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 18px;
  }

  .cooperation-agreement__btn-cont {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
  }

  .is-disabled {
    border: 1px solid #8b8d8f;
    background-color: #f2f4f7;
    color: #8b8d8f;
    box-shadow: none;
    cursor: not-allowed;
  }
</style>
