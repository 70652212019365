(<template>
  <div class="group-fields">
    <h3 v-if="title"
        class="group-fields__title">{{ title }}</h3>
    <div v-for="(line, lineIndex) in linesList"
         :key="genComponentKey('general', lineIndex)"
         class="post-form__field-group">
      <component :is="component.name"
                 v-for="component in getFilteredLines(line.components)"
                 :key="component.name"
                 :params="component.params"
                 :class="component.classes"
                 class="group-fields__field" />
    </div>
  </div>
</template>)

<script>
  import DepartmentAttendersSection from '@/components/booking_components/parts/DepartmentAttendersSection';
  import BookingReferenceSection from '@/components/booking_components/parts/BookingReferenceSection';
  import CaseNumberSection from '@/components/booking_components/parts/CaseNumberSection';
  import ClientSection from '@/components/booking_components/parts/ClientSection';
  import LanguagesSection from '@/components/booking_components/parts/LanguagesSection';
  import BookingOvertimeCheck from '@/components/booking_components/parts/BookingOvertimeCheck';
  import SubjectSection from '@/components/booking_components/parts/SubjectSection';
  import ExtraInfoSection from '@/components/booking_components/parts/extra_info_section/ExtraInfoSection';
  import ExtraInfoInnerSection from '@/components/booking_components/parts/extra_info_section/ExtraInfoInnerSection';
  import AttachmentsSection from '@/components/booking_components/parts/attachments_section/AttachmentsSection';
  import AttachmentsInnerSection from '@/components/booking_components/parts/attachments_section/AttachmentsInnerSection';
  import BookerSection from '@/components/booking_components/parts/BookerSection';
  import DeadlineSection from '@/components/booking_components/parts/DeadlineSection';
  import PaymentMethodSection from '@/components/booking_components/parts/PaymentMethodSection';
  import DateTimeSection from '@/components/booking_components/parts/DateTimeSection';
  import AssignmentTypeSection from '@/components/booking_components/parts/assignment_type_section/AssignmentTypeSection';
  import QualificationSection from '@/components/booking_components/parts/qualification_section/QualificationSection';
  import ParticipantsSection from '@/components/booking_components/parts/participants_section/ParticipantsSection';
  import GenderSection from '@/components/booking_components/parts/gender_section/GenderSection';
  import AccessibilitySection from '@/components/booking_components/parts/AccessibilitySection';
  import AutoInviteSection from '@/components/booking_components/parts/AutoInviteSection';
  import InfiniteAutoInviteSection from '@/components/booking_components/parts/InfiniteAutoInviteSection';
  import AutoApproveSection from '@/components/booking_components/parts/AutoApproveSection';
  import StandBySection from '@/components/booking_components/parts/StandBySection';
  import AutoInviteInfoSection from '@/components/assignment_components/one_assignment/assignment_info/AutoInviteSection';
  import InfiniteAutoInviteInfoSection
    from '@/components/assignment_components/one_assignment/assignment_info/InfiniteAutoInviteSection';
  import AutoApproveInfoSection from '@/components/assignment_components/one_assignment/assignment_info/AutoApproveSection';
  import StandByInfoSection from '@/components/assignment_components/one_assignment/assignment_info/StandBySection';
  import BasicJobInfoTableSection from '@/components/assignment_components/one_assignment/assignment_info/BasicJobInfoTableSection';
  import BlockedSuppliersSection from '@/components/booking_components/parts/blocked_supplier_section/BlockedSuppliersSection';
  import ReplacementSection from '@/components/booking_components/parts/ReplacementSection';
  import OwnerRealNameSection from '@/components/booking_components/parts/OwnerRealNameSection';
  import BlockedCitiesSection from '@/components/booking_components/parts/BlockedCitiesSection';
  import ManualFeesSection from '@/components/booking_components/parts/ManualFeesSection';
  import PricingTemplatesSection from '@/components/booking_components/parts/PricingTemplatesSection';
  import MultipleDateTimeSection from '@/components/booking_components/parts/multiple_datetime_section/MultipleDateTimeSection';
  import MultipleDateTimeInnerSection from '@/components/booking_components/parts/multiple_datetime_section/MultipleDateTimeInnerSection';
  import ForeignLanguageSection from '@/components/booking_components/parts/ForeignLanguageSection';
  import AddressSection from '@/components/booking_components/parts/AddressSection';
  import AdminConfirmationsSection from '@/components/booking_components/parts/AdminConfirmationsSection';
  import EditNoteSection from '@/components/booking_components/parts/EditNoteSection';
  import SecretarySection from '@/components/booking_components/parts/secretary_section/SecretarySection';
  import DialectSection from '@/components/booking_components/parts/DialectSection';
  import FindInterpreterBlock
    from '@/components/assignment_components/one_assignment/interactive_info/invite_suppliers/FindInterpreterBlock';
  import FAIAdminInviteSystem
    from '@/components/assignment_components/one_assignment/interactive_info/invite_suppliers/FAIAdminInviteSystem';
  // Direct booking components
  import AvailabilityDateTimeSection from '@/components/booking_components/parts/direct_booking/AvailabilityDateTimeSection';
  import LangPairsSection from '@/components/booking_components/parts/direct_booking/LangPairsSection';
  import InterpreterInfoSection from '@/components/booking_components/parts/direct_booking/InterpreterInfoSection';
  import DirectBookingSection from '@/components/booking_components/parts/direct_booking/DirectBookingSection';
  import DirectProcessingSection from '@/components/booking_components/parts/direct_booking/DirectProcessingSection';
  import SeveralSuppliersSection from '@/components/booking_components/parts/several_suppliers_section/SeveralSuppliersSection';
  import AlternativeLanguageSection from '@/components/booking_components/parts/AlternativeLanguageSection';
  import LatestSuppliersListSection from '@/components/booking_components/parts/suppliers_list_section/LatestSuppliersListSection';
  import DirectInterpreterSection from '@/components/booking_components/parts/direct_booking/DirectInterpreterSection';
  // open booking components
  import InvoiceInformation from '@/components/open_booking/InvoiceInformation';
  // translation-project components
  import MultipleLanguageSection from '@/components/booking_components/parts/MultipleLanguageSection';
  import ProjectAttachmentsSection from '@/components/booking_components/parts/attachments_section/ProjectAttachmentsSection';
  import ProjectExtraInfoSection from '@/components/booking_components/parts/extra_info_section/ProjectExtraInfoSection';
  import SendByMailInnerSection from '@/components/booking_components/parts/extra_info_section/SendByMailInnerSection';
  import WarningSection from '@/components/booking_components/parts/WarningSection';
  import AccessLevelSection from '@/components/booking_components/parts/AccessLevelSection';

  export default {
    components: {
      // my information components
      'booker-section': BookerSection,
      'department-attenders-section': DepartmentAttendersSection,
      'booking-reference-section': BookingReferenceSection,
      'case-number-section': CaseNumberSection,
      'client-section': ClientSection,
      'payment-method-section': PaymentMethodSection,
      'auto-invite-section': AutoInviteSection,
      'infinite-auto-invite-section': InfiniteAutoInviteSection,
      'auto-approve-section': AutoApproveSection,
      'standby-section': StandBySection,
      'auto-invite-info-section': AutoInviteInfoSection,
      'infinite-auto-invite-info-section': InfiniteAutoInviteInfoSection,
      'auto-approve-info-section': AutoApproveInfoSection,
      'standby-info-section': StandByInfoSection,
      'basic-job-info-table-section': BasicJobInfoTableSection,
      'find-interpreter-block': FindInterpreterBlock,
      'fai-admin-invite-system': FAIAdminInviteSystem,
      'owner-real-name-section': OwnerRealNameSection,
      // assignment information components
      'direct-interpreter-section': DirectInterpreterSection,
      'edit-note-section': EditNoteSection,
      'language-section': LanguagesSection,
      'booking-overtime-check': BookingOvertimeCheck,
      'foreign-language-section': ForeignLanguageSection,
      'subject-section': SubjectSection,
      'deadline-section': DeadlineSection,
      'extra-info-section': ExtraInfoSection,
      'extra-info-inner-section': ExtraInfoInnerSection,
      'attachments-section': AttachmentsSection,
      'attachments-inner-section': AttachmentsInnerSection,
      'date-time-section': DateTimeSection,
      'availability-date-time-section': AvailabilityDateTimeSection,
      'assignment-type-section': AssignmentTypeSection,
      'qualification-section': QualificationSection,
      'participants-section': ParticipantsSection,
      'confirmations-section': AdminConfirmationsSection,
      'gender-section': GenderSection,
      'several-suppliers-section': SeveralSuppliersSection,
      'accessibility-section': AccessibilitySection,
      'blocked-suppliers-section': BlockedSuppliersSection,
      'blocked-cities-section': BlockedCitiesSection,
      'direct-booking-section': DirectBookingSection,
      'replacement-section': ReplacementSection,
      'address-section': AddressSection,
      'multiple-date-time-section': MultipleDateTimeSection,
      'multiple-date-time-inner-section': MultipleDateTimeInnerSection,
      'manual-fees-section': ManualFeesSection,
      'pricing-templates-section': PricingTemplatesSection,
      'alternative-language-section': AlternativeLanguageSection,
      'latest-suppliers-list-section': LatestSuppliersListSection,
      'secretary-section': SecretarySection,
      'dialect-section': DialectSection,
      // direct booking components
      'direct-processing-section': DirectProcessingSection,
      'int-info-section': InterpreterInfoSection,
      'lang-pairs-section': LangPairsSection,
      // open booking components
      'invoice-info-section': InvoiceInformation,
      // translation projects components
      'multiple-language-selection': MultipleLanguageSection,
      'project-attachments-section': ProjectAttachmentsSection,
      'project-extra-info-section': ProjectExtraInfoSection,
      'send-by-mail-inner-section': SendByMailInnerSection,
      'warning-section': WarningSection,
      'access-level-section': AccessLevelSection,
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      linesList: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      genComponentKey(type, index) {
        return `${type}-${index}`;
      },
      getFilteredLines(components) {
        return components.filter(this.canShowComponent);
      },
      canShowComponent(component) {
        return component.condition !== undefined ? component.condition : true;
      }
    }
  };
</script>

<style scoped>
  .group-fields {
    margin-bottom: -15px;
  }

  #app.salita-facelifted .group-fields__field {
    gap: 0 24px;
  }

  .group-fields__title {
    margin-bottom: 20px;
    color: #000;
    font-weight: bold;
    font-size: 18px;
    line-height: 0.89;
  }
</style>
