export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      availabilityEvents: [],
      interpreters: '',
      queryDate: '',
      queryView: '',
      queryPage: ''
    },
    getters: {
      resourceInterpreters(state) {
        const interpreters = state.interpreters?.interpreters || [];

        return interpreters.map((interpreter) => {
          return {
            id: interpreter.uid,
            title: interpreter.name,
            skills: interpreter.skills || [],
            avatar: interpreter.avatar,
            relation: interpreter.relation,
            sex: interpreter.sex,
            city: interpreter.city,
            bookable: interpreter.bookable
          };
        });
      }
    },
    actions: {
      getInterpreters({rootState, commit}, params) {
        return new Promise((resolve, reject) => {
          params.amount = rootState.filterItemsAmount;
          fetchData.fetchLightInterpreters(params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setInterpreters', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      getAvailabilityEvents({commit}, {uid, params}) {
        return new Promise((resolve, reject) => {
          fetchData.fetchAvailabilityEvents(uid, params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            const availabilityEvents = {
              ...data.interpreter,
              ...{
                resourceId: uid
              }
            };
            commit('setAvailabilityEvents', availabilityEvents);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      }
    },
    mutations: {
      setInterpreters(state, data) {
        state.interpreters = data;
      },
      setAvailabilityEvents(state, data) {
        state.availabilityEvents.push(data);
      },
      clearAvailabilityEvents(state) {
        state.availabilityEvents = [];
      },
      setQueries(state, {date, view, page}) {
        state.queryDate = date;
        state.queryView = view;
        state.queryPage = page;
      },
      clearStore(state) {
        state.interpreters = '';
        state.availabilityEvents = [];
        state.queryDate = '';
        state.queryView = '';
        state.queryPage = '';
      }
    }
  };
};
