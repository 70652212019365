<script>
  import {mapState, mapMutations} from 'vuex';
  import PostingTogglePrototype from '@/prototypes/PostingTogglePrototype';

  export default {
    extends: PostingTogglePrototype,
    computed: {
      ...mapState('OneAssignmentStore', {
        storeJobObj: (state) => state.storeJobObj || {}
      }),
      toggleValue() {
        return this.storeJobObj.autoAwardAllowed === undefined ? true : this.storeJobObj.autoAwardAllowed;
      },
      toggleDescription() { return this.$gettext('Auto award'); }
    },
    methods: {
      ...mapMutations({
        toggleHandler: 'PostingStore/AutoApproveStore/setAutoApprove'
      })
    }
  };
</script>
