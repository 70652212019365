const tickets = {
  methods: {
    // ==================================================
    // ==================================================
    // TICKET SYSTEM CALLS
    // ==================================================
    // ==================================================
    getTickets(params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get('/v1/tickets', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError500(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    getTicketsStatistics(enterpriseId, params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/enterprises/${enterpriseId}/ticket_statistics`, {params: params, headers: {Accept: 'application/json'}}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError500(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    getTicketsStatisticsLines(enterpriseId, params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/enterprises/${enterpriseId}/ticket_statistics/lines`, {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError500(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    exportTicketsStatistics(enterpriseId, params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/enterprises/${enterpriseId}/ticket_statistics.csv`, {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError500(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    createTicket(form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/tickets', form)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error?.data || {});
          });
      });
    },
    getTicket(ticketId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/tickets/${ticketId}`)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error?.data || {});
          });
        // setTimeout(() => {
        //   resolve(faker.ticket);
        // }, 1500);
      });
    },
    addTicketMessage(ticketId, message) {
      const self = this;
      const params = {
        message: {
          text: message
        }
      };

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/tickets/${ticketId}/messages`, params)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error?.data || {});
          });
        // setTimeout(() => {
        //   resolve();
        // }, 1500);
      });
    },
    addParticipant(id, params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/tickets/${id}/add_participant`, params)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error?.data || {});
          });
      });
    }
  }
};

export default tickets;
