export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      phones: '',
      pinCodes: '',
      interpreterCount: ''
    },
    actions: {
      getPinCodes({commit, rootGetters}) {
        return new Promise((resolve, reject) => {
          fetchData.getHotlinePinCodes(rootGetters['UserInfoStore/enterpriseId']).then((response) => {
            if (response?.code == 401) {
              commit('logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setPinCodes', Array.isArray(response.pinCodes) ? response.pinCodes : [response.pinCodes]);
            resolve();
          }).catch((error) => {
            reject(error.data);
          });
        });
      },
      getPhones({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getHotlinePhones().then((response) => {
            if (response?.code == 401) {
              commit('logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setPhones', response.phones);
            resolve();
          }).catch((error) => {
            reject(error.data);
          });
        });
      },
      updateHotlineDepartment({rootGetters}, {form, departmentId}) {
        return new Promise((resolve, reject) => {
          fetchData.updateHotlineDepartment(form, departmentId, rootGetters['UserInfoStore/enterpriseId']).then(() => {
            resolve();
          }).catch((error) => {
            reject(error.data);
          });
        });
      },
      getAvailableInterpreters({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getAvailableInterpreters(params).then((response) => {
            if (response?.code == 401) {
              commit('logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setAvailableAmount', response.interpreterCount);
            resolve();
          }).catch((error) => {
            reject(error.data);
          });
        });
      }
    },
    mutations: {
      setPinCodes(state, data) { state.pinCodes = data; },
      setPhones(state, data) { state.phones = data; },
      setAvailableAmount(state, value) { state.interpreterCount = value; },
      clearStore(state) {
        state.phones = '';
        state.pinCodes = '';
        state.interpreterCount = '';
      }
    }
  };
};
