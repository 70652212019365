<script>
  import {mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import PostingProcessingPrototype from '@/prototypes/PostingProcessingPrototype';

  export default {
    extends: PostingProcessingPrototype,
    props: {
      description: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        radiosItemName: 'directProcessing'
      };
    },
    computed: {
      ...mapState('PostingStore/ProcessRequirementStore', {
        processing: ({directProcessing}) => directProcessing,
        processingErrors: ({errors}) => errors.directProcessing.errors
      }),
      choices() {
        return [{
          text: helpers.getJobInfo.jobProcessRequirement(this, 'direct_find_another'),
          value: 'direct_find_another'
        }, {
          text: helpers.getJobInfo.jobProcessRequirement(this, 'direct_cancel_said_no'),
          value: 'direct_cancel_said_no'
        }];
      }
    },
    methods: {
      ...mapMutations('PostingStore/ProcessRequirementStore', ['setDirectProcessing']),
      catchProcessing(value) {
        this.removeOneError({
          storeName: 'PostingStore',
          subStoreName: 'ProcessRequirementStore',
          fieldName: 'directProcessing'
        });
        this.setDirectProcessing(value);
      }
    }
  };
</script>
