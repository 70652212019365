<template>
  <div class="autocomplete"
       :class="{'open':openSuggestion, 'is-with-error': (errors && errors.length)}">
    <p class="sk-select__description">{{ description }}</p>
    <span class="search-icon"></span>
    <input v-model="inputValue"
           class="sk-input__input autocomplete-input"
           type="text"
           :placeholder="placeholder"
           @input="updateValue($event.target.value)"
           @keydown.enter="enter"
           @keydown.down="down"
           @keydown.up="up">
    <ul v-if="isSelectOpen"
        class="autocomplete-list">
      <li v-for="(suggestion, index) in renderedList"
          :key="index"
          class="sk-select__list-item"
          :class="{'is-highlighted': isActive(index), 'is-section-header': suggestion.itemType == 'item-section',}"
          @click="suggestionClick(index)">
        <template v-if="suggestion.itemType === 'item-section'">
          <span>{{ sectionName(suggestion) }}</span>
          <span class="enterprises-count">{{ sectionCount(suggestion) }}</span>
        </template>
        <template v-else>
          <span v-if="suggestion.orgNumber"
                class="org-number">{{ suggestion.orgNumber }}</span>
          <span v-if="suggestion.name"
                class="org-name">{{ suggestion.name }}</span>
          <span v-if="suggestion.address"
                class="org-address">{{ getAddress(suggestion.address) }}</span>
        </template>
      </li>
    </ul>
    <div v-if="errors && errors.length"
         class="sk-select__errors-cont">
      <span v-for="(error, index) in errors"
            v-show="error"
            :key="index"
            v-html="`${index + 1}. ${error}`"></span>
    </div>
  </div>
</template>

<script>
  import helpers from '@/helpers';

  export default {
    props: {
      value: {
        type: String,
        required: true
      },
      suggestions: {
        type: Object,
        required: true
      },
      placeholder: {type: String, default: ''},
      description: {type: String, default: ''},
      validationName: {type: String, default: ''},
      delayed: {type: Boolean, default: true},
    },
    data() {
      return {
        inputValue: '',
        isSelectOpen: false,
        currentIndex: 1,
        requestTime: ''
      };
    },
    computed: {
      // Filtering the suggestion based on the input
      matches() {
        return this.renderedList?.filter((obj) => {
          return obj?.name?.indexOf(this.value) >= 0;
        });
      },
      openSuggestion() {
        return this.selection !== ''
          && this.renderedList.length !== 0
          && this.isSelectOpen === true;
      },
      renderedList() {
        if (this.suggestions || this.suggestions.length) {
          return this.getFormattedList(this.suggestions);
        }
        return [];
      },
      errors() {
        return this.$store.getters['ErrorsStore/getNamedErrors'](this.validationName);
      },
    },
    methods: {
      getFormattedList(initList) {
        const res = [];
        for (const resKey in initList) {
          const currentItemObj = initList[resKey];
          if (currentItemObj) {
            currentItemObj.itemType = 'item-section';
            currentItemObj.section = resKey;
          }
          res.push(currentItemObj);
          if (currentItemObj && currentItemObj.length) {
            currentItemObj.forEach((subItem) => {
              subItem.itemType = 'sub-item';
              res.push(subItem);
            });
          }
        }
        return res;
      },
      updateValue(value) {
        clearInterval(this.requestTimer);
        this.requestTimer = setTimeout(() => {
          this.$store.commit('ErrorsStore/setError', {name: this.validationName, errors: []});
          if (this.isSelectOpen === false) {
            this.isSelectOpen = true;
            this.currentIndex = 1;
          }
          if (value === '') {
            this.isSelectOpen = false;
          }
          this.$emit('input', value);
          this.$emit('search', value);
        }, this.delayed ? 500 : 0);
      },
      // When enter pressed on the input
      enter() {
        this.isSelectOpen = false;
        this.inputValue = this.renderedList[this.currentIndex].name;
        this.$emit('input', this.renderedList[this.currentIndex].name);
        this.$emit('suggestionselected', this.renderedList[this.currentIndex]);
      },
      // When up pressed while suggestions are open
      up() {
        if (this.currentIndex > 0) {
          // check if next item is items section
          this.isItemsSection(this.currentIndex - 1)
            ? this.currentIndex -= 2
            : this.currentIndex -= 1;
        }
      },
      // When up pressed while suggestions are open
      down() {
        if (this.currentIndex < this.renderedList.length - 1) {
          // check if next item is items section
          this.isItemsSection(this.currentIndex + 1)
            ? this.currentIndex += 2
            : this.currentIndex += 1;
        }
      },
      // For highlighting element
      isActive(index) {
        return index === this.currentIndex;
      },
      // When one of the suggestion is clicked
      suggestionClick(index) {
        if (this.renderedList[index].itemType != 'item-section') {
          this.isSelectOpen = false;
          this.inputValue = this.renderedList[index].name;
          this.$emit('input', this.renderedList[index].name);
          this.$emit('suggestionselected', this.renderedList[index]);
        }
      },
      closeSelect(eve) {
        // close the select when we click on the document
        if (eve.target.className.indexOf('autocomplete-input') === -1) {
          this.isSelectOpen = false;
        }
      },
      sectionName(items) {
        switch (items.section) {
          case 'enterprises':
            return this.$gettext('Existing enterprises');
          case 'brregSearch':
            return this.$gettext('From Brreg');
        }
      },
      isItemsSection(index) {
        return this.renderedList[index].itemType === 'item-section';
      },
      sectionCount(items) {
        return (items && items?.length) || 0;
      },
      getAddress(address) {
        return address ? (helpers.getFullAddress(address) || '') : '';
      }
    },
    mounted() {
      document.addEventListener('click', this.closeSelect);
    }
  };
</script>
<style scoped>
.autocomplete {
  position: relative;
}

.autocomplete-list {
  position: absolute;
  top: calc(100% + 10px);
  z-index: 10;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgb(0 34 102 / 10%), 0 1px 2px 0 rgb(0 34 102 / 20%);
}

.is-with-error .autocomplete-input {
  border: #f04;
  border: solid 1px var(--color-error);
  background-color: #ffe5ec;
}

.is-highlighted {
  background-color: #f4f5f7;
}

.is-section-header {
  color: #555;
  font-size: 12px;
}

.is-section-header:hover {
  background-color: #fff;
}

.search-icon {
  position: absolute;
  left: 5px;
  width: 25px;
  height: 35px;
  background-image: url(~@assets/imgs/field_icons/search_icon.svg);
  background-position: 50% 50%;
  background-size: 16px auto;
  background-repeat: no-repeat;
}

.autocomplete-input {
  padding-left: 33px;
  background-color: #fff;
}

.enterprises-count {
  margin-left: 8px;
  padding: 1px 4px 3px;
  border-radius: 3px;
  background-color: #f4f5f7;
}

.org-name {
  color: #ff5b24;
}

.org-number,
.org-address {
  position: relative;
  margin-right: 8px;
}

.org-number::after,
.org-address::before {
  content: '';
  position: absolute;
  top: 9px;
  width: 3px;
  height: 3px;
  margin-left: 5px;
  border-radius: 50%;
  background: grey;
}

.org-address {
  margin-left: 8px;
}

.org-address::before {
  margin-left: -8px;
}
</style>
