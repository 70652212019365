(<template>
  <section class="interpreter-id-modal">
    <template v-if="!modalProgress">
      <span class="interpreter-id-modal__header-logo"></span>

      <div v-if="avatar"
           :style="compAvatarStyle"
           class="interpreter-id-modal__interpreter-image"></div>
      <div v-else-if="userIsInterpreter"
           class="interpreter-id-modal__upload-image">
        <p class="interpreter-id-modal__upload-image-instructions">
          {{ $gettext('Upload a profile image at') }}
          <router-link :to="$makeRoute(person.uid ? {name: 'ServerProfileEdit', params: {id: person.uid}} : '')"
                       class="interpreter-id-modal__upload-image-link"
                       @click.native="closeModal">
            {{ $gettext('My profile') }}
          </router-link>
        </p>
        <p>{{ $gettext('Remember that the picture should resemble your passport picture to ensure that the customer can identify you.') }}</p>
      </div>
      <div v-else
           class="interpreter-id-modal__upload-image interpreter-id-modal__upload-image--default-avatar"></div>

      <div class="interpreter-id-modal__content-container">
        <h2 class="interpreter-id-modal__name">{{ `${person.firstName} ${person.lastName}` }}</h2>

        <h3 v-if="tolkId"
            class="interpreter-id-modal__interpreter-id">{{ $gettextInterpolate('Tolkeportalen ID: %{ n }', {n: tolkId}) }}</h3>

        <p v-for="skill in skills"
           :key="skill.id"
           class="interpreter-id-modal__skill">
          <span class="interpreter-id-modal__skill-name">{{ $getLangName(skill.langFromId) }} - {{ $getLangName(skill.langToId) }}</span>
          <span class="interpreter-id-modal__skill-level">{{ getShortQualificationName(skill.qualificationId) }}</span>
        </p>

        <div class="interpreter-id-modal__row">
          <button v-if="qrCode"
                  class="interpreter-id-modal__badge"
                  @click="showDailyAnimation">
            <img :src="qrCode"
                 class="interpreter-id-modal__qr-code"
                 alt="qr code" />
            <p class="interpreter-id-modal__qr-code-text">{{ $gettext('Control') }}</p>
          </button>
          <div v-for="badge in badges"
               :key="badge.type"
               class="interpreter-id-modal__badge">
            <figure :class="`badge--${badge.type}_active`"
                    class="interpreter-id-modal__badge-illustration"></figure>
            <p class="interpreter-id-modal__badge-text">{{ badge.name }}</p>
          </div>
        </div>
      </div>

      <div v-if="isDailyAnimationShown"
           class="daily-animation-container">
        <span :class="'daily-animation-container__image--' + (new Date()).getDate()"
              class="daily-animation-container__image"></span>
      </div>
    </template>
  </section>
</template>)

<script>
  import {mapState, mapGetters} from 'vuex';
  import helpers from '@/helpers';
  import QRCode from 'qrcode';

  const getIsBrowser = new Function('try {return this===window;}catch(e){ return false;}');

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        qrCode: null,
        isDailyAnimationShown: false
      };
    },
    computed: {
      ...mapGetters('ModalStore', ['modalProgress']),
      ...mapGetters('UserInfoStore', ['userIsInterpreter']),
      ...mapState('EditProfileStore', {
        profileData: ({modalProfileData}) => modalProfileData || {},
        qrCodeUrl({settings: {setting}}) {
          if (setting && setting.privacy === 'internet' && getIsBrowser()) {
            return `${window.location.protocol}//${window.location.host}/${setting.slug_country}/${setting.slug}`;
          }

          return 'tel:' + this.$getString('domainData', 'supportPhone');
        }
      }),
      interUid() { return this.data.uid || this.$store.getters['UserInfoStore/userUid']; },
      person() { return this.profileData.person || {}; },
      tolkId() { return this.profileData.tolkId || ''; },
      skills() {
        const skills = this.profileData.skills || [];
        return skills.filter((skill) => skill.approved);
      },
      badges() { return this.profileData.badges ? this.profileData.badges.filter((badge) => badge.active) : []; },
      avatar() { return this.person.avatar || ''; },
      compAvatarStyle() { return this.avatar ? {backgroundImage: `url(${this.avatar})`} : {}; }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      showDailyAnimation() {
        this.isDailyAnimationShown = true;

        setTimeout(() => {
          this.isDailyAnimationShown = false;
        }, 2000);
      },
      getShortQualificationName(id) {
        return helpers.getJobInfo.getQualificationName(this, {id, showShortName: true});
      },
    },
    mounted() {
      if (this.qrCodeUrl) {
        QRCode.toDataURL(this.qrCodeUrl).then((code) => {
          this.qrCode = code;
        });
      }
    },
    beforeDestroy() {
      this.$store.commit('EditProfileStore/removeModalProfileData');
    },
    beforeMount() {
      const storeData = this.$store.state.EditProfileStore.modalProfileData;
      const id = this.interUid;
      if (!storeData) {
        this.$store.commit('ModalStore/startModalProgress');
        Promise.all([
          this.$store.dispatch('EditProfileStore/getModalProfile', {id})
        ]).then(() => {
          this.$store.commit('ModalStore/stopModalProgress');
        });
      }
    }
  };
</script>

<style>
  @media (max-width: 767px) {
    .sk-modal__wrapper {
      width: 100% !important;
    }
  }
</style>

<style scoped>
  .interpreter-id-modal {
    min-height: 500px;
  }

  .interpreter-id-modal__header-logo {
    display: block;
    height: 30px;
    margin: 0 31px 15px;
    background-image: url(~@assets/imgs/logos/black_logo.svg);
    background-image: var(--image-logo);
    background-position: 0% 50%;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .interpreter-id-modal__interpreter-image {
    width: 100%;
    height: 320px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .interpreter-id-modal__upload-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 320px;
    padding: 0 33px;
    background-color: #e9eaef;
    font-size: 12px;
  }

  .interpreter-id-modal__upload-image--default-avatar {
    background-image: var(--image-user-icon);
    background-position: 50% 40%;
    background-size: 60%;
    background-repeat: no-repeat;
  }

  .interpreter-id-modal__upload-image-instructions {
    margin-bottom: 12px;
    font-weight: bold;
  }

  .interpreter-id-modal__upload-image-link {
    color: #3f43ad;
    text-decoration: underline;
  }

  .interpreter-id-modal__content-container {
    display: flex;
    flex-direction: column;
    margin: -22px 33px 0;
    padding: 0 10px;
    background-color: #fff;
  }

  .interpreter-id-modal__name {
    font-weight: normal;
    font-size: 18px;
  }

  .interpreter-id-modal__interpreter-id {
    color: #ff5b24;
    color: var(--color-secondary);
    font-weight: normal;
    font-size: 10px;
  }

  .interpreter-id-modal__skill {
    margin-top: 12px;
    font-size: 12px;
  }

  .interpreter-id-modal__skill-name {
    font-weight: bold;
  }

  .interpreter-id-modal__skill-level {
    display: block;
  }

  .interpreter-id-modal__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 10px -10px 0;
  }

  .interpreter-id-modal__qr-code {
    width: 90px;
  }

  .interpreter-id-modal__qr-code-text {
    margin-top: -8px;
    color: #ff5b24;
    color: var(--color-secondary);
    font-size: 12px;
  }

  .interpreter-id-modal__badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: 90px;
    max-width: 50%;
    padding: 0 10px 10px;
  }

  .interpreter-id-modal__badge-illustration {
    width: 70px;
    height: 80px;
    background-position: 50% 50%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .interpreter-id-modal__badge-text {
    margin-top: 10px;
    font-size: 10px;
    text-align: center;
    word-break: break-word;
  }

  @media (max-width: 400px) {
    .interpreter-id-modal__badge {
      max-width: 100%;
    }
  }

  .daily-animation-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(24, 40, 73, 0.7);
    animation: fadeIn 0.2s, fadeIn 0.2s reverse 1.8s forwards;
  }

  .daily-animation-container__image {
    position: fixed;
    top: calc(50vh - 70px);
    left: calc(50vw - 70px);
    width: 140px;
    height: 140px;
    background-color: red;
    animation: fadeIn 0.35s alternate infinite;
  }
  .daily-animation-container__image--1 { background-image: url(~@assets/imgs/daily_animations/1.png); }
  .daily-animation-container__image--2 { background-image: url(~@assets/imgs/daily_animations/2.png); }
  .daily-animation-container__image--3 { background-image: url(~@assets/imgs/daily_animations/3.png); }
  .daily-animation-container__image--4 { background-image: url(~@assets/imgs/daily_animations/4.png); }
  .daily-animation-container__image--5 { background-image: url(~@assets/imgs/daily_animations/5.png); }
  .daily-animation-container__image--6 { background-image: url(~@assets/imgs/daily_animations/6.png); }
  .daily-animation-container__image--7 { background-image: url(~@assets/imgs/daily_animations/7.png); }
  .daily-animation-container__image--8 { background-image: url(~@assets/imgs/daily_animations/8.png); }
  .daily-animation-container__image--9 { background-image: url(~@assets/imgs/daily_animations/9.png); }
  .daily-animation-container__image--10 { background-image: url(~@assets/imgs/daily_animations/10.png); }
  .daily-animation-container__image--11 { background-image: url(~@assets/imgs/daily_animations/11.png); }
  .daily-animation-container__image--12 { background-image: url(~@assets/imgs/daily_animations/12.png); }
  .daily-animation-container__image--13 { background-image: url(~@assets/imgs/daily_animations/13.png); }
  .daily-animation-container__image--14 { background-image: url(~@assets/imgs/daily_animations/14.png); }
  .daily-animation-container__image--15 { background-image: url(~@assets/imgs/daily_animations/15.png); }
  .daily-animation-container__image--16 { background-image: url(~@assets/imgs/daily_animations/16.png); }
  .daily-animation-container__image--17 { background-image: url(~@assets/imgs/daily_animations/17.png); }
  .daily-animation-container__image--18 { background-image: url(~@assets/imgs/daily_animations/18.png); }
  .daily-animation-container__image--19 { background-image: url(~@assets/imgs/daily_animations/19.png); }
  .daily-animation-container__image--20 { background-image: url(~@assets/imgs/daily_animations/20.png); }
  .daily-animation-container__image--21 { background-image: url(~@assets/imgs/daily_animations/21.png); }
  .daily-animation-container__image--22 { background-image: url(~@assets/imgs/daily_animations/22.png); }
  .daily-animation-container__image--23 { background-image: url(~@assets/imgs/daily_animations/23.png); }
  .daily-animation-container__image--24 { background-image: url(~@assets/imgs/daily_animations/24.png); }
  .daily-animation-container__image--25 { background-image: url(~@assets/imgs/daily_animations/25.png); }
  .daily-animation-container__image--26 { background-image: url(~@assets/imgs/daily_animations/26.png); }
  .daily-animation-container__image--27 { background-image: url(~@assets/imgs/daily_animations/27.png); }
  .daily-animation-container__image--28 { background-image: url(~@assets/imgs/daily_animations/28.png); }
  .daily-animation-container__image--29 { background-image: url(~@assets/imgs/daily_animations/29.png); }
  .daily-animation-container__image--30 { background-image: url(~@assets/imgs/daily_animations/30.png); }
  .daily-animation-container__image--31 { background-image: url(~@assets/imgs/daily_animations/31.png); }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
</style>

<style scoped src="@/assets/tikktalk/css/badges.css"></style>
