// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/rounded_new_edit_pencil_icon_grey.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.note[data-v-391982a0] {\n  width: 100%;\n  padding: 12px 16px;\n  border-radius: 8px;\n  background-color: #f4f5f7;\n  line-height: 20px;\n}\n.note__bottom-wrapper[data-v-391982a0] {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  margin-top: 5px;\n  color: #555;\n}\n.note__edit-btn[data-v-391982a0]::before {\n  content: '';\n  position: absolute;\n  right: 0;\n  width: 15px;\n  height: 15px;\n  padding-right: 30px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n}\n", ""]);
// Exports
module.exports = exports;
