<script>
  import helpers from '@/helpers';
  import {mapGetters, mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('OneAssignmentStore', {
        assignmentRequirements: ({storeJobObj}) => storeJobObj.interpretationRequirement || {},
        processRequirements: ({storeJobObj}) => storeJobObj.processRequirement || {}
      }),
      ...mapGetters('UserInfoStore', [
        'userIsManager'
      ]),
      alternativeLanguageToId() {
        return this.assignmentRequirements.alternativeLanguageToId;
      },
      city() { return this.interpreter.city || ''; },
      invitationsCount() { return this.interpreter.invitationsCount || 0; },
      discussionStatus() { return this.interpreter.discussionStatus; },
      invitedText() { return this.$gettextInterpolate(this.$gettext('Invited %{count}'), {count: this.$isGodmode() ? `(${this.invitationsCount})` : ''}).trim(); },
      suitabilityText() { return this.interpreter.suitable ? this.$gettext('Available') : this.$gettext('Not available'); },
      computedName() {
        const idText = this.interpreter.id ? `<br />(ID ${this.interpreter.id})` : '';
        return `${this.fullName} ${idText}`.trim();
      },
      fullName() {
        return `${this.interpreter.firstName || ''} ${this.interpreter.lastName || ''}`;
      },
      qualifications() {
        const skills = this.interpreter.skills || [];

        return skills.map((skill) => {
          const qualificationShortName = helpers.getJobInfo.getQualificationName(this, {
            id: skill.qualificationId,
            showShortName: true
          }) || '';
          const languageName = this.$getLangName(skill.languageToId) || '';
          return {
            id: Math.random(),
            languageToId: skill.languageToId,
            name: `${languageName} - ${qualificationShortName}`,
            qualificationBg: skill.qualificationBg
          };
        });
      },
      distance() {
        const distanceInMeters = this.interpreter.distance;
        return helpers.getJobInfo.distanceText(distanceInMeters, this);
      },
      isBlockedInterpreter() {
        return this.interpreter.unsuitabilityReason?.includes('blocked_for_assignment');
      }
    },
    methods: {
      declineInvite(interpreterUid, jobId) {
        this.$emit('declineinvite', {interpreterUid, jobId});
      },
      inviteInt(interpreterUid) {
        if (this.invitationsCount === 0 || this.$isGodmode() || this.userIsManager) {
          this.$emit('invite', interpreterUid);
        }
      },
      blockInterpreterFromJob(jobId, interpreterUid) {
        this.$emit('blockinterpreter', {jobId, interpreterUid});
      },
      applyByInt(interpreterUid, interpreterId, interpreterName) {
        this.$emit('applyasinterpreter', interpreterUid, interpreterId, interpreterName);
      },
      useLightColorForQualification(qualification) {
        const alternativeLanguageActivated = this.processRequirements.alternativeLanguageActivated;

        return qualification.languageToId == this.alternativeLanguageToId && !alternativeLanguageActivated;
      },
      goToProfile() {
        const uid = this.interpreter.uid;
        if (uid) {
          const routeData = this.$router.resolve(this.$makeRoute({name: 'BuyerUserProfile', params: {id: uid}}));
          window.open(routeData.href, '_blank');
        }
      }
    }
  };
</script>
