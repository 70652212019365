(<template>
  <post-accordion-section ref="genderSection"
                          :state="genderSelect"
                          :title="title"
                          :description="description"
                          :disabled="disabledFields"
                          class="gender"
                          @focusin="openGenderSectionHelper"
                          @statechanged="updateGenderSelectState">
    <template v-if="genderSelect">
      <div class="gender__radio-wrapper">
        <sk-radio v-for="gender in genderList"
                  :key="gender.id"
                  :item-value="gender.id"
                  :item-name="'gender'"
                  :preselected-value="assignmentGender"
                  :item-text="gender.name"
                  :disabled="disabledFields"
                  class="gender__radio"
                  :class="{'gender__radio--disabled': disabledFields}"
                  @radiochanged="changeGender" />
      </div>
      <gender-processing v-if="showGenderProcessingSection"
                         :class="{'gender__processing--disabled': disabledFields}"
                         class="gender__processing" />
    </template>
  </post-accordion-section>
</template>)

<script>
  import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
  import PostAccordionSection from '@/components/booking_components/PostAccordionSection';
  import GenderProcessingSection from '@/components/booking_components/parts/gender_section/GenderProcessingSection';

  export default {
    components: {
      'post-accordion-section': PostAccordionSection,
      'gender-processing': GenderProcessingSection
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('PostingNotifierStore', ['showGenderProcessingSection']),
      ...mapState('PostingStore/GenderStore', {
        assignmentGender: ({sex}) => sex,
        genderSelect: ({genderSelect}) => genderSelect
      }),
      paramsList() { return this.params || {}; },
      genderList() { return this.$helperData.getSelectGenders(this); },
      disabledFields() { return this.paramsList.disabledFields; },
      title() {
        return this.$gettext('Specific gender');
      },
      description() {
        return this.$gettext('I need an interpreter with a specific gender');
      },
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    methods: {
      ...mapActions('PostingStore/GenderStore', [
        'changeGender',
        'changeGenderSelectState'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
        'setReferenceToBuyerInfoSection',
      ]),
      openGenderSectionHelper() {
        if (this.showAssignmentHelper) {
          this.setPopoverReference(this.$refs.genderSection);
          this.openHelper('gender');
        }
      },
      updateGenderSelectState(value) {
        if (!value) {
          this.setReferenceToBuyerInfoSection();
          this.openHelper('');
        }
        this.changeGenderSelectState(value);
      }
    }
  };
</script>

<style scoped>
  .gender__radio-wrapper {
    margin: 20px -15px 0;
  }

  .gender__radio {
    margin: 0 15px;
  }

  .gender__radio--disabled,
  .gender__processing--disabled {
    pointer-events: none;
  }

  .gender__processing {
    margin-top: 20px;
  }
</style>
