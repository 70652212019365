<script>
  import {mapState} from 'vuex';
  import BasicCustomerInfo from '@/components/customers/modals/BasicCustomerInfo';

  export default {
    extends: BasicCustomerInfo,
    computed: {
      ...mapState('UserNotesStore', {
        userInfo: (state) => state.userInfo || {}
      }),
      id() { return this.userInfo.personId || this.userInfo.person.id || ''; },
      name() { return this.userInfo.name || this.userInfo.person.name || '-'; },
      email() { return this.userInfo.email || this.userInfo.person.email || '-'; },
      phone() { return this.userInfo.phone || this.userInfo.person.phone || '-'; },
    }
  };
</script>
