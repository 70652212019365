const phoneVerification = {
  methods: {
    // ==================================================
    // ==================================================
    // PHONE VERIFICATION CALLS
    // ==================================================
    // ==================================================
    sendPhoneVerificationSMS(id, form) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/phone_verification/${id}`, form).then(() => {
          resolve();
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    sendSMSCode(id, code) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/phone_verification/${id}/verify/${code}`).then((data) => {
          resolve(data?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
  }
};

export default phoneVerification;
