export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      accessInfo: {
        id: '',
        embedUrl: '',
        accessToken: ''
      },
    },
    getters: {
      getAccessInfo(state) { return state.accessInfo; }
    },
    actions: {
      fetchPowerBIAccessInfo({rootGetters, commit}) {
        const enterpriseId = rootGetters['UserInfoStore/enterpriseId'] || rootGetters['EnterpriseStore/getManagerJobEnterpriseId'];
        return new Promise((resolve, reject) => {
          fetchData.getAccessPowerBIData(enterpriseId).then((data) => {
            commit('setAccessPowerBIData', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
    },
    mutations: {
      setAccessPowerBIData(state, data) {
        state.accessInfo.id = data.id;
        state.accessInfo.embedUrl = data.embedUrl;
        state.accessInfo.accessToken = data.accessToken;
      },
      clearStore(state) {
        state.accessInfo = {
          id: '',
          embedUrl: '',
          accessToken: ''
        };
      }
    }
  };
};
