(<template>
  <post-inner-section class="address-section">
    <div class="address-section__description">{{ description }}</div>
    <in-person-form :is-open-page="false"
                    class="address-section__in-person-form" />
  </post-inner-section>
</template>)

<script>
  import PostInnerSection from '@/components/booking_components/PostInnerSection';
  import InPersonForm from '@/components/booking_components/parts/assignment_type_section/InPersonForm';

  export default {
    components: {
      'post-inner-section': PostInnerSection,
      'in-person-form': InPersonForm
    },
    computed: {
      description() {
        return this.$gettext('Please provide the full address of the assignment, as well as other relevant details');
      }
    }
  };
</script>

<style scoped>
  .address-section__description {
    font-weight: bold;
  }
</style>
