export default () => {
  return {
    namespaced: true,
    state: {
      manualFees: {
        booking: false,
        editing: false,
        travelBooking: false,
      }
    },
    mutations: {
      setManualFee(state, {key, value}) {
        state.manualFees[key] = value;
      },
      clearStore(state) {
        state.manualFees = {
          booking: false,
          editing: false,
          travelBooking: false
        };
      }
    }
  };
};
