(<template>
  <article class="dangerous-edit">
    <h2 class="dangerous-edit__title">{{ $gettext('Are you sure you want to edit this assignment?') }}</h2>
    <p class="dangerous-edit__description">{{ descriptionText }}</p>
    <sk-checkbox class="dangerous-edit__text"
                 :preselected-value="confirmRebook"
                 :item-text="confirmationText"
                 @checkboxvaluechanged="setConfirmRebook" />

    <div class="dangerous-edit__btn-cont">
      <button class="sk-btn sk-btn--white dangerous-edit__btn dangerous-edit__btn--cancel"
              @click="closeModal">{{ $gettext('Cancel') }}</button>
      <button class="sk-btn sk-btn--default dangerous-edit__btn dangerous-edit__btn--confirm"
              :disabled="!confirmRebook"
              @click="emitEdit">{{ $gettext('Confirm Rebook') }}</button>
    </div>
  </article>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        confirmRebook: false
      };
    },
    computed: {
      confirmationText() {
        return this.data.data.text || '';
      },
      descriptionText() {
        return this.data.data.description || '';
      },
      context() {
        return this.data.context;
      }
    },
    methods: {
      setConfirmRebook(value) {
        this.confirmRebook = value;
      },
      closeModal() {
        this.$emit('closemodal');
      },
      emitEdit() {
        const param = {
          reason: 'dangerous_fields_edited',
          other: false
        };
        this.context.cancelAssignment(param);
      }
    }
  };
</script>

<style scoped>
.dangerous-edit {
  display: block;
  width: 100%;
  padding: 30px;
}

.dangerous-edit__title {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 18px;
}

.dangerous-edit__description {
  margin: 8px 0 24px 0;
}

.dangerous-edit__text {
  font-size: 13px;
}

.dangerous-edit__btn-cont {
  display: flex;
  justify-content: flex-end;
  margin-top: 45px;
}

.dangerous-edit__btn {
  display: inline-block;
  width: auto;
  padding: 0 30px;
}

.dangerous-edit__btn--cancel {
  flex-shrink: 0;
}

.dangerous-edit__btn--confirm {
  width: auto;
  margin-left: 20px;
}
</style>
