import LanguagesStore from '@/store/booking_stores/submodules/LanguagesStore';
import SubjectStore from '@/store/booking_stores/submodules/SubjectStore';
import ExtraInfoStore from '@/store/booking_stores/submodules/ExtraInfoStore';
import SendMailStore from '@/store/booking_stores/submodules/SendMailStore';
import AttachmentsStore from '@/store/booking_stores/submodules/AttachmentsStore';
import DeadlineStore from '@/store/booking_stores/submodules/DeadlineStore';
import DepartmentAttendersStore from '@/store/booking_stores/submodules/DepartmentAttendersStore';
import BookingRefStore from '@/store/booking_stores/submodules/BookingRefStore';
import CaseNumberStore from '@/store/booking_stores/submodules/CaseNumberStore';
import ClientStore from '@/store/booking_stores/submodules/ClientStore';
import BookerStore from '@/store/booking_stores/submodules/BookerStore';
import DirectBookingStore from '@/store/booking_stores/submodules/DirectBookingStore';
import PaymentMethodStore from '@/store/booking_stores/submodules/PaymentMethodStore';
import DateTimeStore from '@/store/booking_stores/submodules/DateTimeStore';
import AssignmentTypeStore from '@/store/booking_stores/submodules/AssignmentTypeStore';
import GenderStore from '@/store/booking_stores/submodules/GenderStore';
import AccessibilityStore from '@/store/booking_stores/submodules/AccessibilityStore';
import QualificationStore from '@/store/booking_stores/submodules/QualificationStore';
import ParticipantsStore from '@/store/booking_stores/submodules/ParticipantsStore';
import ProcessRequirementStore from '@/store/booking_stores/submodules/ProcessRequirementStore';
import AutoInviteStore from '@/store/booking_stores/submodules/AutoInviteStore';
import AutoApproveStore from '@/store/booking_stores/submodules/AutoApproveStore';
import StandByStore from '@/store/booking_stores/submodules/StandByStore';
import BlockedSuppliersStore from '@/store/booking_stores/submodules/BlockedSuppliersStore';
import ReplacementStore from '@/store/booking_stores/submodules/ReplacementStore';
import OwnerRealNameStore from '@/store/booking_stores/submodules/OwnerRealNameStore';
import ConfirmationByStore from '@/store/booking_stores/submodules/ConfirmationByStore';
import MultipleDateTimeStore from '@/store/booking_stores/submodules/MultipleDateTimeStore';
import BlockedCitiesStore from '@/store/booking_stores/submodules/BlockedCitiesStore';
import ManualFeesStore from '@/store/booking_stores/submodules/ManualFeesStore';
import SeveralSuppliersStore from '@/store/booking_stores/submodules/SeveralSuppliersStore';
import SuppliersListStore from '@/store/booking_stores/submodules/SuppliersListStore';
import SecretaryStore from '@/store/booking_stores/submodules/SecretaryStore';
import CoverLetterStore from '@/store/booking_stores/submodules/CoverLetterStore';
import DialectStore from '@/store/booking_stores/submodules/DialectStore';
import AccessLevelStore from '@/store/booking_stores/submodules/AccessLevelStore';

export default (context) => {
  return {
    namespaced: true,
    modules: {
      // MY INFORMATION BLOCK
      BookerStore: BookerStore(context),
      BookingRefStore: BookingRefStore(context),
      DepartmentAttendersStore: DepartmentAttendersStore(context),
      CaseNumberStore: CaseNumberStore(context),
      PaymentMethodStore: PaymentMethodStore(context),
      ClientStore: ClientStore(context),
      AutoInviteStore: AutoInviteStore(context),
      AutoApproveStore: AutoApproveStore(context),
      OwnerRealNameStore: OwnerRealNameStore(context),
      // ASSIGNMENT INFORMATION BLOCK
      LanguagesStore: LanguagesStore(context),
      SubjectStore: SubjectStore(context),
      DeadlineStore: DeadlineStore(context),
      DateTimeStore: DateTimeStore(context),
      ExtraInfoStore: ExtraInfoStore(context),
      SendMailStore: SendMailStore(context),
      AttachmentsStore: AttachmentsStore(context),
      AssignmentTypeStore: AssignmentTypeStore(context),
      GenderStore: GenderStore(context),
      AccessibilityStore: AccessibilityStore(context),
      QualificationStore: QualificationStore(context),
      ParticipantsStore: ParticipantsStore(context),
      StandByStore: StandByStore(context),
      ProcessRequirementStore: ProcessRequirementStore(context),
      BlockedSuppliersStore: BlockedSuppliersStore(context),
      ReplacementStore: ReplacementStore(context),
      ConfirmationByStore: ConfirmationByStore(context),
      DirectBookingStore: DirectBookingStore(context),
      MultipleDateTimeStore: MultipleDateTimeStore(context),
      BlockedCitiesStore: BlockedCitiesStore(context),
      ManualFeesStore: ManualFeesStore(context),
      SeveralSuppliersStore: SeveralSuppliersStore(context),
      SuppliersListStore: SuppliersListStore(context),
      SecretaryStore: SecretaryStore(context),
      CoverLetterStore: CoverLetterStore(context),
      DialectStore: DialectStore(context),
      AccessLevelStore: AccessLevelStore(context),
    },
    state: {
      validationResult: {
        missingData: false,
        wrongData: false
      },
      disableBooking: false,
    },
    getters: {
      disableBooking(state) {
        return state.disableBooking;
      }
    },
    actions: {
      setError({rootState, commit}, {storeName, subStoreName, fieldName, errorText, typeError}) {
        const state = subStoreName ? rootState[storeName][subStoreName] : rootState[storeName];
        const store = subStoreName ? `${storeName}/${subStoreName}` : storeName;

        commit('setValidationResult', typeError);
        commit(`${store}/setError`, {fieldName, errorText}, {root: true});
        commit('ErrorsStore/setError', {name: state.errors[fieldName].name, errors: state.errors[fieldName].errors}, {root: true});
      },
      removeErrors({commit}, {storeName, subStoreName}) {
        const store = subStoreName ? `${storeName}/${subStoreName}` : storeName;

        commit(`${store}/removeErrors`, null, {root: true});
      },
      removeOneError({rootState, commit}, {storeName, subStoreName, fieldName}) {
        const state = subStoreName ? rootState[storeName][subStoreName] : rootState[storeName];
        state.errors[fieldName].errors = [];
        commit('ErrorsStore/setError', {name: state.errors[fieldName].name, errors: []}, {root: true});
      }
    },
    mutations: {
      setValidationResult(state, typeError) {
        state.validationResult.missingData = typeError === 'missingData';
        state.validationResult.wrongData = typeError === 'wrongData';
      },
      resetValidationResult(state) {
        state.validationResult.missingData = false;
        state.validationResult.wrongData = false;
      },
      setDisableBooking(state, value) {
        state.disableBooking = value;
      }
    }
  };
};
