export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      rates: ''
    },
    actions: {
      getRates({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getRates().then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setRates', data);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getRatesDoc({commit}, id) {
        return new Promise((resolve, reject) => {
          fetchData.getRatesDoc(id).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      }
    },
    mutations: {
      setRates(state, data) {
        state.rates = data;
      },
      clearStore(state) {
        state.rates = '';
      }
    }
  };
};
