<script>
  import {mapActions} from 'vuex';

  export default {
    methods: {
      ...mapActions({
        setAddressError: 'FullAddressStore/setError',
        removeAddressErrors: 'FullAddressStore/removeErrors'
      }),
      validateAddressForm() {
        let isValidForm = true;

        this.removeAddressErrors();
        if (!this.line1 && !this.postcode && !this.city && !this.county && !this.country) {
          this.setAddressError({
            fieldName: 'address',
            errorText: this.$gettext('Please enter a full address')
          });
          isValidForm = false;
        } else {
          if (!this.line1) {
            this.setAddressError({
              fieldName: 'line1',
              errorText: this.$gettext('Address line 1 can\'t be blank')
            });
            isValidForm = false;
          }
          if (!this.postcode) {
            this.setAddressError({
              fieldName: 'postcode',
              errorText: this.$gettext('Postcode can\'t be blank')
            });
            isValidForm = false;
          }
          if (!this.city) {
            this.setAddressError({
              fieldName: 'city',
              errorText: this.$gettext('City can\'t be blank')
            });
            isValidForm = false;
          }
          if (!this.county) {
            this.setAddressError({
              fieldName: 'county',
              errorText: this.$gettext('County can\'t be blank')
            });
            isValidForm = false;
          }
          if (!this.country) {
            this.setAddressError({
              fieldName: 'country',
              errorText: this.$gettext('Country can\'t be blank')
            });
            isValidForm = false;
          }
          return isValidForm;
        }
      }
    }
  };
</script>
