export default {
  path: '/:lang/:country/setup',
  component: () => import('@/pages/shared_pages/wizard/sh_WizardRoot'),
  meta: {requiresAuth: true},
  children: [
    {
      path: '/',
      redirect: {name: 'WizardChooseRole'}
    },
    {
      path: 'choose_role',
      name: 'WizardChooseRole',
      component: () => import('@/pages/shared_pages/wizard/sh_ChooseRole'),
      meta: {emptyRoleOnly: true, wizardPage: true}
    },
    {
      path: 's',
      component: () => import('@/pages/ServerPages/wizard/s_WizardServerRoot'),
      meta: {serverOnly: true},
      children: [
        {
          path: 'name',
          name: 'ServerWizardEnterName',
          component: () => import('@/pages/ServerPages/wizard/s_EnterName'),
          meta: {wizardPage: true}
        },
        {
          path: 'phone_number',
          name: 'ServerWizardEnterPhone',
          component: () => import('@/pages/ServerPages/wizard/s_EnterPhone'),
          meta: {wizardPage: true}
        },
        {
          path: 'skills',
          name: 'ServerWizardAddSkills',
          component: () => import('@/pages/ServerPages/wizard/s_AddSkills'),
          meta: {wizardPage: true}
        },
        {
          path: 'gender',
          name: 'ServerWizardAddGender',
          component: () => import('@/pages/ServerPages/wizard/s_AddGender'),
          meta: {wizardPage: true}
        }
      ]
    },
    {
      path: 'b',
      component: () => import('@/pages/BuyerPages/wizard/b_WizardBuyerRoot'),
      meta: {buyerOnly: true},
      children: [
        {
          path: '/',
          redirect: {name: 'BuyerWizardEnterName'}
        },
        {
          path: 'name',
          name: 'BuyerWizardEnterName',
          component: () => import('@/pages/BuyerPages/wizard/b_EnterName'),
          meta: {wizardPage: true}
        },
        {
          path: 'phone_number',
          name: 'BuyerWizardEnterPhone',
          component: () => import('@/pages/BuyerPages/wizard/b_EnterPhone'),
          meta: {wizardPage: true}
        },
        {
          path: 'enterprise',
          name: 'BuyerRegisterEnterprise',
          component: () => import('@/pages/BuyerPages/wizard/b_EnterpriseSettings'),
          meta: {wizardPage: true}
        },
        {
          path: 'payment',
          name: 'BuyerEnterprisePayment',
          component: () => import('@/pages/BuyerPages/wizard/b_EnterprisePayment'),
          meta: {wizardPage: true}
        },
        {
          path: 'verify',
          name: 'BuyerWizardVerifyEmail',
          component: () => import('@/pages/BuyerPages/wizard/b_VerifyEmail'),
          meta: {wizardPage: true}
        }
      ]
    },
    {
      path: 'm',
      component: () => import('@/pages/BuyerPages/wizard/b_WizardBuyerRoot'),
      meta: {managerOnly: true},
      children: [
        {
          path: '/',
          redirect: {name: 'ManagerWizardEnterName'}
        },
        {
          path: 'name',
          name: 'ManagerWizardEnterName',
          component: () => import('@/pages/BuyerPages/wizard/b_EnterName'),
          meta: {wizardPage: true}
        },
        {
          path: 'phone_number',
          name: 'ManagerWizardEnterPhone',
          component: () => import('@/pages/BuyerPages/wizard/b_EnterPhone'),
          meta: {wizardPage: true}
        },
        {
          path: 'enterprise',
          name: 'ManagerRegisterEnterprise',
          component: () => import('@/pages/BuyerPages/wizard/b_EnterpriseSettings'),
          meta: {wizardPage: true}
        },
        {
          path: 'payment',
          name: 'ManagerEnterprisePayment',
          component: () => import('@/pages/BuyerPages/wizard/b_EnterprisePayment'),
          meta: {wizardPage: true}
        },
        {
          path: 'verify',
          name: 'ManagerWizardVerifyEmail',
          component: () => import('@/pages/BuyerPages/wizard/b_VerifyEmail'),
          meta: {wizardPage: true}
        }
      ]
    }
  ]
};
