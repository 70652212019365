(<template>
  <article class="no-rights">
    <div>
      <p class="no-rights__text">{{ $gettext('Unfortunately you don\'t have access rights for this page.') }}</p>
      <p v-if="userIsAdminOfEnterprise"
         class="no-rights__text">{{ $gettext('In order to get access, you will need to grant yourself rights on the Employee roles page.') }}</p>
      <p v-else
         class="no-rights__text">{{ $gettext('Please contact the admin of your enterprise.') }}</p>
    </div>
  </article>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters({
        userIsAdminOfEnterprise: 'UserInfoStore/userIsAdminOfEnterprise'
      })
    }
  };
</script>

<style scoped>
  .no-rights {
    display: flex;
    align-items: center;
    max-width: 550px;
    min-height: 65vh;
    margin: 0 auto;
    font-size: 18px;
    text-align: center;
  }

  .no-rights__text {
    display: block;
    width: 100%;
    margin: 0;
  }

  .no-rights__text ~ .no-rights__text {
    margin-top: 15px;
  }
</style>
