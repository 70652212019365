export default {
  path: 'preferences',
  component: () => import('@/pages/shared_pages/preferences/sh_PreferencesRoot'),
  children: [
    {
      path: '/',
      redirect: {name: 'BuyerPreferencesNotifications'}
    },
    {
      path: 'notifications',
      name: 'BuyerPreferencesNotifications',
      meta: {subPage: true},
      component: () => import('@/pages/shared_pages/preferences/sh_NotificationSettings')
    }
  ]
};
