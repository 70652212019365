(<template>
  <div>
    <div class="warning-banner__wrapper">
      <div v-if="!anyTravelExpenses"
           class="warning-banner__message"
           v-html="filteredMessage"></div>
      <sk-checkbox :preselected-value="receiptConfirm"
                   :item-text="filteredConfirmMessage"
                   class="warning-banner__checkbox"
                   @checkboxvaluechanged="catchReceiptConfirmation" />
    </div>
  </div>
</template>)

<script>
  import {mapMutations} from 'vuex';

  export default {
    props: {
      receiptConfirm: {
        type: Boolean,
        default: false
      },
      allowanceAmount: {
        type: Number,
        default: 0
      },
      message: {
        type: String,
        default: ''
      },
      confirmMessage: {
        type: String,
        default: ''
      },
      anyTravelExpenses: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      filteredMessage() {
        if (this.message) return this.message;
        else return this.warningMessage;
      },
      filteredConfirmMessage() {
        if (this.confirmMessage) return this.confirmMessage;
        else return this.checkboxMessage;
      },
      warningMessage() {
        if (this.anyTravelExpenses) {
          return this.$gettext('<b>NB!</b> Any travel costs must be reported, and receipts for all costs must be presented on request for up to 6 months after the assignment.');
        } else {
          return this.$gettext('<b>NB!</b> This customer doesn\'t reimburse any travel expenses.\n'
            + 'Instead, you will receive fixed amount for any travel expenses');
        }
      },
      checkboxMessage() {
        if (this.anyTravelExpenses && this.allowanceAmount > 0) {
          return this.$gettext('I confirm that I can present receipts for all reported travel expenses upon request.');
        } else {
          return this.$gettext('I accept that travel expenses will be reimbursed at a fixed amount.');
        }
      }
    },
    methods: {
      ...mapMutations('AssignmentFlowStore', [
        'setReceiptWarningConfirmation'
      ]),
      catchReceiptConfirmation(value) {
        this.$emit('confrimchanged', value);
      }
    }
  };
</script>

<style>
.warning-banner__checkbox .sk-checkbox__input:checked + .sk-checkbox__imitation {
  background-color: black !important;
}
</style>

<style scoped>
  .warning-banner__wrapper {
    position: relative;
    margin: 1rem 0;
    padding: 12px;
    border-radius: 3px;
    background-color: #ffb6c7;
    color: #f04;
    font-size: 13px;
  }

  .warning-banner__message {
    margin-bottom: 10px;
  }

  .warning-banner__checkbox {
    font-weight: bold;
  }
</style>
