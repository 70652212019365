export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      translationSkillsData: {
        translationSkills: [],
        count: 0,
        page: 1,
        pages: 1,
      },
      selectedTranslationSkill: {},
      updateTranslationSkillPageGrid: ''
    },
    actions: {
      getTranslationSkills({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getTranslationSkills(params).then((response) => {
            commit('setTranslationSkillsData', response);
            const resFormatted = response;
            resFormatted.data = response.skills;
            resolve(resFormatted);
          }).catch(() => {
            reject();
          });
        });
      },
      createSkill(context, params) {
        return new Promise((resolve, reject) => {
          fetchData.createSkill(params).then((response) => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        });
      },
    },
    mutations: {
      setTranslationSkillsData(state, data) {
        state.translationSkillsData = data;
      },
      setSelectedTranslationSkill(state, interpreterSkill) {
        state.selectedTranslationSkill = interpreterSkill;
      },
      refreshTranslationSkillGrid(state) {
        state.updateTranslationSkillPageGrid = Date.now();
      },
      clearSelectedTranslationSkill(state) {
        state.selectedTranslationSkill = {};
      },
      clearStore(state) {
        state.translationSkillsData = {
          translationSkills: [],
          count: 0,
          page: 1,
          pages: 1,
        };
        state.selectedTranslationSkill = {};
      }
    }
  };
};
