export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      project: '',
      progressActive: false,
    },
    getters: {
      project(state) {
        return state.project;
      },
      projectManager(state) {
        return state.project.projectManager || {};
      },
      isFinishedProject(state) {
        return state.project?.status === 'finished';
      }
    },
    actions: {
      getProjectById({commit}, id) {
        return new Promise((resolve, reject) => {
          fetchData.getProject(id).then((oneProject) => {
            if (oneProject.code === 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            if (oneProject.code && oneProject.code === 404) {
              commit('GlobalStore/set404Page', '', {root: true});
              resolve();
              return;
            }
            commit('setProject', oneProject.order);
            resolve(oneProject);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      preSelectInvoiceSection({state, dispatch, commit}) {
        const proj = state.project;
        // TODO: Book UID Missing For Now Add later

        dispatch('FeedbackStore/getProjectLastUpdatedData', {orderId: proj.id} || '', {root: true});
        // Clinet Name
        dispatch('PostingStore/ClientStore/setName', proj.clientName || '', {root: true});
        // Case Number
        dispatch('PostingStore/CaseNumberStore/changeCaseNumber', proj.caseNumber || '', {root: true});
        // Clinet Id
        dispatch('PostingStore/ClientStore/setId', proj.clientId || '', {root: true});
        // Payment Ref
        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/BookingRefStore/setPaymentBookingReference',
          payload: proj.paymentBookingReference || ''
        }, {root: true});
        // Booking Ref
        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/BookingRefStore/setBookingReference',
          payload: proj.bookingReference || ''
        }, {root: true});
        dispatch('PostingStore/CaseNumberStore/changeCaseNumber', proj.caseNumber, {root: true});
        // payment Method
        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/PaymentMethodStore/setPaymentMethod',
          payload: proj.paymentMethod?.id || '',
        }, {root: true});
        // department
        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/DepartmentAttendersStore/setDepartment',
          payload: proj.department?.id || ''
        }, {root: true});
        // Demader Commnet/Feedback
        commit('executeNotTrackedMutation', {
          mutationName: 'FeedbackStore/setPublicFeedbackComment',
          payload: proj.demanderFeedback || ''
        }, {root: true});
      },
      requestNewDeadline(_, params) {
        return new Promise((resolve, reject) => {
          fetchData.requestNewDeadline(params).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      changeStatus({commit}, {id, status}) {
        return new Promise((resolve, reject) => {
          fetchData.changeProjectStatus(id, status).then((data) => {
            commit('setProjectStatus', 'cancelled');
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      changeSubtaskStatus({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.changeSubtaskStatus(params).then((res) => {
            commit('setProject', res.order);
            resolve(res);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendAttachments({commit}, {id, attachments}) {
        return new Promise((resolve, reject) => {
          const form = new FormData();
          form.append('subtask_id', id);
          if (attachments.length) {
             attachments.forEach((attachment) => {
              form.append('attachments[][file]', attachment.file);
            });
          }
          fetchData.sendSubtaskAttachment(id, form).then((res) => {
            commit('setProject', res.order);
            resolve(res);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      deleteAttachment({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.deleteAttachment(params).then((res) => {
            commit('setProject', res.order);
            resolve(res);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      preselectForProjectInformationSectionEdit({state, dispatch, commit}) {
        dispatch('PostingStore/LanguagesStore/changeLangFrom', state.project.sourceLanguageId, {root: true});
        commit('PostingStore/LanguagesStore/setTargetLangs', state.project.targetLanguages, {root: true});
        commit('PostingStore/DeadlineStore/setDeadline', state.project.externalDeadline, {root: true});
        commit('PostingStore/ExtraInfoStore/setExtraInfo', state.project.instructionsFromDemander, {root: true});
        dispatch('preselectProjectAttachments');

        if (state.project.accessLevel) {
          commit('PostingStore/AccessLevelStore/setAccessLevel', state.project.accessLevel, {root: true});
          commit('PostingStore/AccessLevelStore/setAccessLevelSelectState', true, {root: true});
        } else {
          dispatch('PostingNotifierStore/preselectProjectDefaultAccessLevel', {root: true});
        }
      },
      preselectProjectAttachments({state, rootState}) {
        const attachments = state.project.attachments;
        if (attachments && attachments.length) {
          rootState.PostingStore.AttachmentsStore.attachmentsSelectState = true;
          rootState.PostingStore.AttachmentsStore.attachments = JSON.parse(JSON.stringify(attachments));
        }
      },
    },
    mutations: {
      setProject(state, data) {
        state.project = data;
      },
      setProjectStatus(state, status) {
        if (state.project) {
          state.project.status = status;
        }
      },
      startProjectProgress(state) {
        state.progressActive = true;
      },
      stopProjectProgress(state) {
        state.progressActive = false;
      },
      clearStore(state) {
        state.project = '';
        state.progressActive = false;
      }
    }
  };
};
