(<template>
  <article class="upload-modal">
    <sk-upload-file :id="'certiFile'"
                    :button-text="selectBtnText"
                    :is-single="true"
                    :accept="acceptTypes"
                    :reset="fileInputReset"
                    class="upload-modal__upload-doc"
                    @changefileinput="catchDoc" />
    <p v-if="fileName"
       class="upload-modal__selected-doc">{{ fileName }}</p>
    <div class="upload-modal__btns-wrapper">
      <button class="sk-btn sk-btn--white upload-modal__btn"
              @click="cancelUploading">{{ $gettext('Cancel') }}</button>
      <button class="sk-btn sk-btn--default upload-modal__btn"
              @click="makeUploading">{{ $gettext('Upload') }}</button>
    </div>
  </article>
</template>)

<script>
  import constants from '@/modules/constants';
  import {mapState, mapMutations, mapActions} from 'vuex';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        fileInputReset: false
      };
    },
    computed: {
      ...mapState('TTDocumentsStore', {
        title: (state) => state.newDoc.title,
        file: (state) => state.newDoc.file
      }),
      fileName() { return this.file ? this.file.name : ''; },
      selectBtnText() { return this.$gettext('Select file'); },
      acceptTypes() { return constants.DOCUMENT_CENTER_ALLOWED_FILE_TYPES.join(', '); }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      ...mapMutations('TTDocumentsStore', ['setNewDocFile', 'clearNewFile']),
      ...mapActions('DocumentCenterStore', ['addDocument']),
      closeModal() {
        this.$emit('closemodal');
      },
      cancelUploading() {
        this.$emit('closemodal');
        this.clearNewFile();
      },
      makeUploading() {
        let error = false;
        this.$store.commit('ModalStore/removeModalErrors');
        if (!this.file) {
          error = true;
          this.$store.commit('ModalStore/setModalErrors', this.$gettext('Please select a file'));
        }
        if (!error) {
          const form = new FormData();
          form.append('document[file]', this.file);
          this.$emit('startprogress');
          this.$store.dispatch('DocumentCenterStore/addDocument', form).then(() => {
            if (this.data.successHandlingCallbackName && this.data.context[this.data.successHandlingCallbackName]) {
              this.data.context[this.data.successHandlingCallbackName]();
            }
            this.cancelUploading();
          }).catch(() => {
            this.cancelUploading();
          });
        }
      },
      catchDoc(target) {
        this.$store.commit('ModalStore/removeModalErrors');
        if (target.files && target.files.length) {
          const fileType = target.files[0].type;
          const fileSize = target.files[0].size;
          const allowedFileTypes = constants.DOCUMENT_CENTER_ALLOWED_FILE_TYPES;
          if (!allowedFileTypes.includes(fileType)) {
            this.$store.commit('ModalStore/setModalErrors', this.$gettext('Invalid file type'));
            return;
          }
          if (fileSize > constants.DOCUMENT_CENTER_MAX_FILE_SIZE) {
            this.$store.commit('ModalStore/setModalErrors', this.$gettext('File size can\'t be more than 20 megabytes.'));
            return;
          }
          this.setNewDocFile(target.files[0]);
          this.fileInputReset = !this.fileInputReset;
        }
      }
    },
    beforeDestroy() {
      this.clearNewFile();
    }
  };
</script>

<style scoped>
  .upload-modal {
    display: block;
    width: 100%;
    padding: 20px 39px;
  }

  .upload-modal__upload-doc {
    width: 150px;
  }

  .upload-modal__selected-doc {
    margin-top: 5px;
  }

  .upload-modal__btns-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 25px;
  }

  .upload-modal__btn {
    display: inline-block;
    width: auto;
    padding: 0 25px;
  }

  .upload-modal__btn:last-of-type {
    margin-left: 20px;
  }
</style>
