<template>
  <div class="horizontal-progress">
    <span class="horizontal-progress__percentage"
          :style="{width: `${percents}%`}"></span>
    <p class="horizontal-progress__subtitle">{{ subtitle }}</p>
  </div>
</template>

<script>
  export default {
    props: {
      percents: {
        type: [String, Number],
        default: 0
      },
      title: {
        type: String,
        default: ''
      },
      subtitle: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style scoped>
.horizontal-progress {
  position: relative;
  box-sizing: content-box;
  width: 100px;
  height: 8px;
  border-radius: 25px;
  background: rgba(0, 132, 137, 0.2);
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}

.horizontal-progress > span {
  position: relative;
  display: block;
  height: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #008489;
  box-shadow:
    inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
}

.horizontal-progress > span::after,
.animate > span > span {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: none;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
}

.horizontal-progress__percentage::before {
  content: attr(percent);
  position: absolute;
  top: -25px;
  right: 0;
  width: 20px;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  transform: translateX(50%);
}

.animate > span::after {
  display: none;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}

.horizontal-progress__subtitle {
  padding: 5px 0;
  color: #89898a;
}

</style>
