<template>
  <div v-if="toastData"
       :class="[`${toastData.position}`]"
       class="notification-container">
    <span class="notification-image"
          :class="[toastType]"></span>
    <p class="notification-message">
      {{ toastData.message }}
    </p>
  </div>
</template>

<script>
  export default {
    computed: {
      toastData() {
        return this.$store.getters['ToastStore/toastData'] || {};
      },
      autoDeleteTime() {
        return this.toastData?.autoDeleteTime || 5000;
      },
      toastType() {
        return this.toastData?.type || 'error';
      }
    },
    methods: {
      deleteToast() {
        this.$store.commit('ToastStore/removeTooltip');
      }
    },
    mounted() {
      setTimeout(() => {
        this.deleteToast();
      }, this.autoDeleteTime);
    }

  };
</script>

<style scoped>
.notification-container {
  position: fixed;
  z-index: 999999;
  display: flex;
  box-sizing: border-box;
  max-width: 365px;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #333;
  color: #fff;
  font-size: 14px;
}

.top-right {
  top: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.bottom-right {
  right: 12px;
  bottom: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.top-left {
  top: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.bottom-left {
  bottom: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.bottom-center {
  bottom: 10%;
  left: 50%;
  transition: transform 0.6s ease-in;
  transform: translate(-50%, 0);
  animation: toast-in-bottom 0.7s;
}

.top-center {
  top: 10%;
  left: 50%;
  transition: transform 0.6s ease-in;
  transform: translate(-50%, 0);
  animation: toast-in-top 0.7s;
}

.notification-message {
  text-align: left;
}

.notification-image {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-right: 10px;
  background-image: url(~@assets/imgs/undefined_imgs/check_icon_filled.svg);
  background-position: center center;
  background-repeat: no-repeat;
}

.notification-image.success {
  background-image: url(~@assets/imgs/undefined_imgs/tick_icon.svg);
}

.notification-image.warning {
  background-image: url(~@assets/imgs/undefined_imgs/info_icon_brown.svg);
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-bottom {
  from {
    transform: translate(-50%, 0) translateY(100%);
  }

  to {
    transform: translate(-50%, 0) translateY(0);
  }
}

@keyframes toast-in-top {
  from {
    transform: translate(-50%, 0) translateY(-100%);
  }

  to {
    transform: translate(-50%, 0) translateY(0);
  }
}
</style>
