export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      currentTicket: '',
      replyText: '',
      errors: {
        replyText: {name: 'replyTextValidation', errors: []}
      }
    },
    actions: {
      setError({state, commit}, {fieldName, errorText}) {
        commit('setError', {fieldName, errorText});
        commit('ErrorsStore/setError', {name: state.errors[fieldName].name, errors: state.errors[fieldName].errors}, {root: true});
      },
      removeErrors({commit}) {
        commit('removeErrors');
        commit('ErrorsStore/removeAllErrors', null, {root: true});
      },
      getTicket({commit}, ticketId) {
        return new Promise((resolve, reject) => {
          fetchData.getTicket(ticketId).then((data = {}) => {
            commit('setCurrentTicket', data.ticket);
            resolve(data.ticket);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      addTicketMessage({dispatch}, {ticketId, message}) {
        return new Promise((resolve, reject) => {
          fetchData.addTicketMessage(ticketId, message).then(() => {
            dispatch('getTicket', ticketId)
              .then((data) => {
                resolve(data);
              })
              .catch((response) => {
                reject(response);
              });
          })
            .catch((response) => {
              reject(response);
            });
        });
      }
    },
    mutations: {
      setError(state, {fieldName, errorText}) {
        state.errors[fieldName].errors = [...state.errors[fieldName].errors, errorText];
      },
      removeErrors(state) {
        for (const key of Object.keys(state.errors)) {
          state.errors[key].errors = [];
        }
      },
      setReplyText(state, value) { state.replyText = value; },
      setCurrentTicket(state, data) { state.currentTicket = data; },
      clearModalStore(state) {
        state.replyText = '';
        state.errors = {
          replyText: {name: 'replyTextValidation', errors: []}
        };
      },
      clearStore(state) {
        state.currentTicket = '';
      }
    }
  };
};
