<script>
  import {mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import PostingProcessingPrototype from '@/prototypes/PostingProcessingPrototype';

  export default {
    extends: PostingProcessingPrototype,
    data() {
      return {
        radiosItemName: 'genderProcessing',
        description: ''
      };
    },
    computed: {
      ...mapState('PostingStore/ProcessRequirementStore', {
        processing: ({genderProcessing}) => genderProcessing,
        processingErrors: ({errors}) => errors.genderProcessing.errors
      }),
      choices() {
        return [{
          text: helpers.getJobInfo.jobProcessRequirement(this, 'gender_auto_update'),
          value: 'gender_auto_update'
        }, {
          text: helpers.getJobInfo.jobProcessRequirement(this, 'gender_cancel_not_found'),
          value: 'gender_cancel_not_found'
        }];
      }
    },
    methods: {
      ...mapMutations('PostingStore/ProcessRequirementStore', ['setGenderProcessing']),
      catchProcessing(value) {
        this.removeOneError({
          storeName: 'PostingStore',
          subStoreName: 'ProcessRequirementStore',
          fieldName: 'genderProcessing'
        });
        this.setGenderProcessing(value);
      }
    }
  };
</script>
