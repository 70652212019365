(<template>
  <div class="post-form__section languages">
    <sk-select v-if="showFromLang"
               :description="langFromText"
               :items-list="languages"
               :preselected-value="langFrom"
               :default-value="langDefaultValue"
               :validation-name="langFromErrorsName"
               :disabled="disabledFields"
               class="post-form__field post-form__field--half-width"
               @csvaluechanged="changeLangFrom" />
    <sk-select v-if="showOriginCountrySelectOnPosting"
               :items-list="countriesList"
               :description="countryText"
               :preselected-value="country"
               :default-value="countryDefaultText"
               :disabled="disabledFields"
               class="post-form__field post-form__field--half-width"
               @csvaluechanged="setCountry" />
    <section v-if="useSelectLanguagePopup"
             class="post-form__field"
             :class="{'post-form__field--half-width': showFromLang || showDialect}">
      <sk-input class="post-form__hidden-input"
                :preselected-value="''"
                :validation-name="langToErrorsName" />
      <p class="select-language-description"
         :class="{'lang-input-desc__error': inputFieldValidation}">{{ langToText }}</p>
      <button class="select-language-btn"
              :class="{'lang-input__error':inputFieldValidation}"
              @click="openSelectLanguageModal">
        {{ selectLanguageText }}
      </button>
      <p v-if="inputFieldValidation"
         class="lang-from__error"
         :class="{'fast-int__validation':isFastInterpretation}">{{ langToErrors[0] }}</p>
    </section>
    <sk-select v-else
               :items-list="filteredLangToList"
               :description="langToText"
               :preselected-value="langTo"
               :default-value="langDefaultValue"
               :validation-name="langToErrorsName"
               :disabled="disabledFields"
               :marked-items="countryBasedLangList"
               :class="{'post-form__field--half-width': showFromLang || showDialect}"
               class="post-form__field"
               @csvaluechanged="changeLangTo" />
    <dialect-section v-if="showDialect"
                     class="post-form__field post-form__field--half-width" />
  </div>
</template>)

<script>
  import {mapActions, mapState, mapGetters} from 'vuex';
  import helpers from '@/helpers';
  import DialectSection from '@/components/booking_components/parts/DialectSection';

  export default {
    components: {
      'dialect-section': DialectSection,
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        data: {
          type: Object,
          default() {
            return {};
          }
        },
        country: ''
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'showOriginCountrySelectOnPosting'
      ]),
      ...mapState('PostingStore/LanguagesStore', {
        langFrom: (state) => state.languageFrom,
        langTo: (state) => state.languageTo,
        langFromErrorsName: (state) => state.errors.langFrom.name,
        langToErrorsName: (state) => state.errors.langTo.name,
        langToErrors: (state) => state.errors.langTo.errors
      }),
      countriesList() {
        const countriesList = [...this.$store.state.selectsData.countries || []];
        countriesList.unshift({id: 'all', name: this.countryDefaultText});
        return countriesList;
      },
      isFastInterpretation() { return this.$route.meta.assignmentType == 'fast_interpretation'; },
      inputFieldValidation() { return this.langToErrors.length && !this.langTo; },
      countryText() { return this.$gettext('Origin country of the client/patient'); },
      countryDefaultText() { return this.$gettext('Choose country'); },
      langFromText() { return this.paramsList.langFromText || this.$gettext('From language:'); },
      paramsList() { return this.params || {}; },
      disabledFields() { return this.paramsList.disabledFields; },
      langToText() { return this.paramsList.langToText || this.$gettext('To language:'); },
      showFromLang() { return this.paramsList.showFromLang || false; },
      showDialect() { return this.paramsList.showDialect || false; },
      useSelectLanguagePopup() { return this.paramsList.useSelectLanguagePopup || false; },
      langDefaultValue() { return this.$gettext('Select a language'); },
      languages() { return this.$store.state.selectsData.language || []; },
      selectedCountryName() {
        return this.countriesList.find((country = {}) => country.id === this.country)?.name;
      },
      selectLanguageText() {
        return this.languages.find((lang) => lang.id == this.langTo)?.name || this.$gettext('Select a language');
      },
      countryBasedLangList() {
        const countryBasedLangList = [];
        this.languages.forEach((lang) => {
          if (lang.countries.includes(this.selectedCountryName)) {
            countryBasedLangList.push(this.getLangItemForSelect(lang));
          }
        });
        return countryBasedLangList;
      },
      languagesToList() {
        return this.languages.map(this.getLangItemForSelect);
      },
      filteredLangToList() {
        return [
          ...this.countryBasedLangList,
          ...helpers.excludeArrayFromBaseArray({
            baseArr: this.languagesToList,
            excludeArr: this.countryBasedLangList
          })
        ];
      }
    },
    methods: {
      ...mapActions('PostingStore/LanguagesStore', [
        'changeLangFrom',
        'changeLangTo'
      ]),
      getLangItemForSelect(lang) {
        const template = this.$gettext('The language is not listed in the National Interpreter Register');
        const isLangFormNorwegian = this.langFrom === 3;
        const isInTolkeregister = isLangFormNorwegian && lang.is_in_tolkeregister ? '' : `<span class="sk-select__sub-value">${template}</span>`;
        const title = isLangFormNorwegian && lang.is_in_tolkeregister ? '' : `${lang.name}. ${template}`;
        return {
          id: lang.id,
          name: `${lang.name}${isInTolkeregister}`,
          title: title
        };
      },
      setCountry(value) {
        this.country = value;
      },
      closeModal() {
        this.$emit('closemodal');
      },
      openSelectLanguageModal() {
        this.closeModal();
        this.$store.commit('ModalStore/setModal', {
          component: 'select-language-modal',
          width: 800,
          data: {
            context: this,
            method: 'changeLangTo',
            field: 'langTo'
          }
        });
      },
    }
  };

</script>

<style scoped>
.languages {
  position: relative;
}

.post-form__hidden-input {
  position: absolute;
  z-index: -1;
}

.lang-input-desc__error {
  color: #f04;
}

.lang-from__error {
  display: block;
  width: 100%;
  margin-top: 2px;
  color: #f04;
  font-size: 12px;
}

.select-language-description {
  width: 100%;
  padding-bottom: 2px;
  font-size: 12px;
}

.select-language-btn {
  display: block;
  width: 100%;
  height: 35px;
  padding: 0 10px;
  border: 1px solid #ddd;
  line-height: 33px;
  text-align: left;
}

#app.salita-facelifted .select-language-btn {
  border: 1px solid #dddfe7;
  border-radius: 4px;
}

.select-language-btn::after {
  content: var(--image-select-down-arrow-icon);
  display: flex;
  align-items: center;
  float: right;
  height: 100%;
  line-height: 1;
  cursor: pointer;
}

.lang-input__error {
  border: solid 1px #f04;
}

.fast-int__validation {
  top: 202px;
}

@media (max-width: 767px) {
  .select-language-description {
    font-size: 8px;
  }

  .lang-from__error {
    top: 154px;
    left: 12px;
  }

  .fast-int__validation {
    top: 214px;
  }
}

@media (max-width: 603px) {
  .fast-int__validation {
    top: 233px;
  }
}
</style>
