(<template>
  <div ref="multipleDatetimeSection"
       class="multiple-datetime"
       @focusin="openMultipleDateTimeSection">
    <button v-if="!showSeriesBooking"
            type="button"
            class="sk-btn sk-btn--white post-form__field assignment-date__btn assignment-date__btn--add"
            @click="changeMultipleDateTimesSelectState(true)">{{ multipleDateTimesBtnText }}</button>
    <template v-else>
      <multiple-date-time-section :show-always-delete="true">
        <div v-if="showErrors"
             slot="errors"
             :class="{'is-with-error': showErrors}"
             class="multiple-datetime__errors">
          <span v-for="(error, index) in dateTimesOverlappingErrors"
                :key="index"
                v-html="`${index + 1}. ${error}`"></span>
        </div>
      </multiple-date-time-section>
      <sk-note v-if="multipleDateTimesOneSupplierSelectState"
               :text="sameInterpreterNote"
               class="post-form__field" />
      <!--      <sk-checkbox :item-text="oneSupplierCheckboxText"-->
      <!--                   :preselected-value="multipleDateTimesOneSupplierSelectState"-->
      <!--                   class="post-form__field multiple-datetime__checkbox"-->
      <!--                   @checkboxvaluechanged="changeMultipleDateTimesOneSupplierSelectState" />-->
    </template>
  </div>
</template>)

<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import MultipleDateTimeSection
    from '@/components/booking_components/parts/multiple_datetime_section/MultipleDateTimeSection';

  export default {
    components: {
      'multiple-date-time-section': MultipleDateTimeSection
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('PostingStore/MultipleDateTimeStore', {
        multipleDateTimesOneSupplierSelectState: ({multipleDateTimesOneSupplierSelectState}) => multipleDateTimesOneSupplierSelectState,
        // errors
        dateTimesOverlappingErrors: ({errors}) => errors.dateTimesOverlapping.errors || []
      }),
      ...mapGetters('PostingNotifierStore', [
        'showSeriesBooking'
      ]),
      showErrors() { return this.dateTimesOverlappingErrors.length; },
      multipleDateTimesBtnText() {
        return this.$gettext('Serial booking');
      },
      sameInterpreterNote() {
        return this.$gettext('One interpreter will be applied for all series assignments');
      },
      oneSupplierCheckboxText() {
        return helpers.getJobInfo.jobProcessRequirement(this, 'same_int');
      },
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    methods: {
      ...mapActions('PostingStore/MultipleDateTimeStore', [
        'changeMultipleDateTimesSelectState',
        'changeMultipleDateTimesOneSupplierSelectState'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
      ]),
      openMultipleDateTimeSection() {
        if (this.showAssignmentHelper) {
          this.setPopoverReference(this.$refs.multipleDatetimeSection);
          this.openHelper('serialBookingDate');
        }
      },
    }
  };
</script>

<style scoped src="@assets/css/assignment_dates.css"></style>

<style scoped>
  .multiple-datetime {
    width: 100%;
  }

  .multiple-datetime__checkbox {
    margin-bottom: 30px;
  }

  .multiple-datetime__errors {
    display: block;
    width: 100%;
    margin: 0 15px;
    padding-bottom: 10px;
    color: #f04;
    font-size: 12px;
  }
</style>
