export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      message: '',
      messagesList: {
        discussion: {
          id: '',
          messages: ''
        }
      },
      adminInfo: ''
    },
    getters: {
      messages(state) {
        return state.messagesList?.discussion?.messages || [];
      }
    },
    actions: {
      getAdminInfo({commit}, uids = []) {
        return new Promise((resolve, reject) => {
          if (uids.length) {
            fetchData.getViewers(uids).then((data) => {
              commit('setAdminInfo', data.people?.length ? data.people[0] : '');
              resolve(data);
            }).catch((response) => {
              reject(response);
            });
          }
        });
      },
      fetchAdminMessages({commit}, {entityId, entityType}) {
        return new Promise((resolve, reject) => {
          fetchData.fetchAdminMessages({entityId, entityType}).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setMessagesList', response);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendAdminMessage({dispatch}, {entityId, entityType, message}) {
        return new Promise((resolve, reject) => {
          fetchData.sendAdminMessage({entityId, entityType, message}).then(() => {
            dispatch('fetchAdminMessages', {entityId, entityType}).then((data) => {
              resolve(data);
            }).catch((error) => {
              reject(error);
            });
          }).catch((error) => {
            reject(error);
          });
        });
      },
      editAdminMessage({commit}, {msgId, message}) {
        return new Promise((resolve, reject) => {
          fetchData.editAdminMessage({msgId, message}).then((data) => {
            commit('changeMessage', data.message);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      removeAdminMessage({commit}, {msgId}) {
        return new Promise((resolve, reject) => {
          fetchData.removeAdminMessage(msgId).then((data) => {
            commit('removeMessage', msgId);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      }
    },
    mutations: {
      setAdminInfo(state, data) { state.adminInfo = data; },
      setMessage(state, value) { state.message = value; },
      setMessagesList(state, data) { state.messagesList = data; },
      changeMessage(state, data) {
        state.messagesList.discussion.messages = state.messagesList.discussion.messages.map((message) => {
          return message.id === data.id ? data : message;
        });
      },
      removeMessage(state, msgId) {
        state.messagesList.discussion.messages = state.messagesList.discussion.messages.filter((message) => {
          return message.id !== msgId;
        });
      },
      clearStore(state) {
        state.message = '';
        state.messagesList = {
          discussion: {
            id: '',
            messages: ''
          }
        };
        state.adminInfo = '';
      }
    }
  };
};
