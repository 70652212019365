import BookingHelper from '@/store/booking_stores/BookingHelper';

export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      // New selected docs to be uploaded
      attachments: [],
      // all documents that are uploaded and present
      allDocuments: {
        documents: [],
        page: 1,
        pages: 1,
        count: 0
      },
      // selected docs from all docs available
      selectedFiles: [],
      errors: {attachments: {name: 'attachmentsError', errors: []}}
    },
    getters: {
      attachments(state) {
        return state.attachments.filter((attachment) => {
          return attachment.deleted !== undefined ? !attachment.deleted : true;
        });
      }
    },
    actions: {
      getDocuments({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getDocuments(params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setDocuments', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      addDocument({commit}, payload) {
        return new Promise((resolve, reject) => {
          fetchData.addDocument(payload).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setDocuments', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      deleteDocument({commit}, id) {
        return new Promise((resolve, reject) => {
          fetchData.deleteDocument(id).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
    },
    mutations: {
      ...BookingHelper().mutations,
      setDocuments(state, data) {
        state.allDocuments = data;
      },
      setAttachments(state, {files}) {
        if (files && files.length) {
          // Remove Not Allowed Files types [Folders, exe]
          const notAllowedList = ['', 'application/x-msdownload'];
          const validFiles = Array.from(files);
          for (let i = 0; i < files.length; i += 1) {
            const isInvalidFile = notAllowedList.includes(files[i]?.type);
            const isDuplicateFile = !!state.attachments?.find((file) => file.name == files[i]?.name);
            if (isDuplicateFile || isInvalidFile) {
              // if validFiles and splice index are equal splice will be skipped
              if (validFiles.length > i) validFiles.splice(i, 1);
              else validFiles.pop();
            }
          }

          for (let i = 0; i < validFiles.length; i += 1) {
            state.attachments.push({
              file: validFiles[i],
              name: validFiles[i].name,
              error: false
            });
          }
        }
      },
      setAttachmentsErrors(state, {data, context}) {
        Object.keys(data).forEach((index) => {
          if (data[+index].file.includes('This file is too big')) {
            state.attachments[+index].error = context.$gettext('This file is too big.');
          } else {
            state.attachments[+index].error = false;
          }
        });
      },
      deleteAttachment(state, {index, id}) {
        if (id) {
          state.attachments = state.attachments.map((attachment) => {
            return attachment.id == id
              ? {
                ...attachment,
                deleted: true
              }
              : attachment;
          });
        } else {
          state.attachments.splice(index, 1);
        }
      },
      catchSelection(state, file) {
        state.selectedFiles.push(file);
      },
      catchUnSelection(state, file) {
        const deleteIdx = state.selectedFiles.findIndex((selectedFile) => selectedFile.id == file.id);
        if (deleteIdx != -1) state.selectedFiles.splice(deleteIdx, 1);
      },
      deleteUploadedFile(state, {index, id}) {
        if (id) {
          state.selectedFiles = state.selectedFiles.map((file) => {
            return file.id == id
              ? {
                ...file,
                deleted: true
              }
              : file;
          });
        } else {
          state.selectedFiles.splice(index, 1);
        }
      },
      clearSelectedFiles(state) {
        state.selectedFiles = [];
      },
      clearStore(state) {
        state.attachments = [];
        state.allDocuments = {
          documents: [],
          page: 1,
          pages: 1,
          count: 0
        };
        state.errors.attachments.errors = [];
      }
    }
  };
};
