<script>
  import {mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import PostingProcessingPrototype from '@/prototypes/PostingProcessingPrototype';

  export default {
    extends: PostingProcessingPrototype,
    props: {
      isVideo: {
        type: Boolean,
        default() {
          return false;
        }
      }
    },
    data() {
      return {
        radiosItemName: 'sessionTypeProcessing',
        onSelectionProcessingType: '',
        description: ''
      };
    },
    computed: {
      ...mapState('PostingStore/AssignmentTypeStore', {
        assignmentType: ({assignmentType}) => assignmentType
      }),
      ...mapState('PostingStore/ProcessRequirementStore', {
        sessionTypeProcessing: ({sessionTypeProcessing}) => sessionTypeProcessing,
        processing: ({autoUpdateProcessingType}) => autoUpdateProcessingType,
        processingErrors: ({errors}) => errors.sessionTypeProcessing.errors,
        autoUpdateToSessionType: ({autoUpdateToSessionType}) => autoUpdateToSessionType
      }),
      choices() {
        if (this.isVideo) {
          return [{
            text: helpers.getJobInfo.jobProcessRequirement(this, 'session_type_auto_update_phone', this.assignmentType),
            value: 'phone'
          }, {
            text: helpers.getJobInfo.jobProcessRequirement(this, 'session_type_cancel_not_found', this.assignmentType),
            value: 'session_type_cancel_not_found'
          }];
        }
        return [{
          text: helpers.getJobInfo.jobProcessRequirement(this, 'session_type_auto_update_phone', this.assignmentType),
          value: 'phone'
        }, {
          text: helpers.getJobInfo.jobProcessRequirement(this, 'session_type_auto_update_video', this.assignmentType),
          value: 'video'
        }, {
          text: helpers.getJobInfo.jobProcessRequirement(this, 'session_type_cancel_not_found', this.assignmentType),
          value: 'session_type_cancel_not_found'
        }];
      }
    },
    methods: {
      ...mapMutations('PostingStore/ProcessRequirementStore',
                      ['setSessionTypeProcessing', 'setAutoUpdateToSessionType', 'setAutoUpdateProcessingType']),
      catchProcessing(value) {
        this.removeOneError({
          storeName: 'PostingStore',
          subStoreName: 'ProcessRequirementStore',
          fieldName: 'sessionTypeProcessing'
        });
        this.setAutoUpdateProcessingType(value);
        // if value is phone or video then it's auto_update but specifically to phone or video sessions
        const autoUpdate = ['phone', 'video'].includes(value);
        const sessionType = autoUpdate ? 'session_type_auto_update' : 'session_type_cancel_not_found';
        this.setAutoUpdateToSessionType(autoUpdate ? value : '');
        this.setSessionTypeProcessing(sessionType);
      }
    }
  };
</script>
