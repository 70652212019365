(<template>
  <form class="payment-modal"
        @submit.prevent.stop="validateInvoice">
    <p class="payment-modal__text">{{ faq }}</p>
    <p v-if="isEditMode"
       class="payment-modal__edit-text"
       v-html="selectedDepartmentInfo"></p>
    <sk-select v-else
               :items-list="departmentsList"
               :preselected-value="department"
               :description="departmentDescription"
               :default-value="departmentDefaultValue"
               :validation-name="errors.department.name"
               class="payment-modal__field payment-modal__field--select"
               @csvaluechanged="setDepartment" />
    <div class="payment-modal__field-wrapper">
      <sk-select :items-list="paymentsList"
                 :preselected-value="paymentMethod"
                 :description="paymentDescription"
                 :default-value="paymentDefaultValue"
                 :validation-name="errors.paymentMethod.name"
                 class="payment-modal__field payment-modal__field--select"
                 @csvaluechanged="setPayment" />
      <button class="sk-select-btn payment-modal__add-btn"
              @click.prevent.stop="openAddPaymentModal"></button>
    </div>
    <section class="payment-modal__action-btns-cont">
      <button class="sk-btn sk-btn--white is-progress-bar payment-modal__action-btn"
              type="button"
              @click="$emit('closemodal')">{{ cancelText }}</button>
      <button class="sk-btn sk-btn--default is-progress-bar payment-modal__action-btn"
              type="submit">{{ submitText }}</button>
    </section>
  </form>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        department: this.data.currentInvoice?.departmentId || '',
        paymentMethod: this.data.currentInvoice?.id || '',
        errors: {
          department: {name: 'departmentValidation', errors: []},
          paymentMethod: {name: 'paymentMethodValidation', errors: []}
        }
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'enterpriseName'
      ]),
      faq() {
        return this.$gettext('We currently only support this method in Norway');
      },
      defaultText() {
        const template = this.$gettext('Set as default for "%{name}"');
        return this.$gettextInterpolate(template, {name: this.enterpriseName});
      },
      norwegianOrgNumberText() {
        return this.$gettext('Norwegian organisation number');
      },
      departmentDescription() {
        return this.$gettext('Department');
      },
      departmentDefaultValue() {
        return this.$gettext('Select department');
      },
      departmentsList() {
        return this.data.departmentsList || [];
      },
      paymentDescription() {
        return this.$gettext('Payment method');
      },
      paymentDefaultValue() {
        return this.$gettext('Select payment method');
      },
      paymentsList() {
        return this.data.paymentsList || [];
      },
      cancelText() {
        return this.$gettext('Cancel');
      },
      isEditMode() {
        return !!this.data.currentInvoice;
      },
      submitText() {
        return this.isEditMode ? this.$gettext('Edit method') : this.$gettext('Add method');
      },
      selectedDepartmentInfo() {
        const template = this.$gettext('Current method is for <b>%{departmentName}</b> department');
        return this.$gettextInterpolate(template, {departmentName: this.data.currentInvoice?.departmentName});
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      setError({fieldName, errorText}) {
        this.errors[fieldName].errors = [...this.errors[fieldName].errors, errorText];
        this.$store.commit('ErrorsStore/setError', {name: this.errors[fieldName].name, errors: this.errors[fieldName].errors});
      },
      removeErrors() {
        for (const key of Object.keys(this.errors)) {
          this.errors[key].errors = [];
        }
        this.$store.commit('ErrorsStore/removeAllErrors');
      },
      changeOrgNumber(value) {
        this.orgNumber = value;
      },
      setDepartment(value) {
        this.department = value;
      },
      setPayment(value) {
        this.paymentMethod = value;
      },
      openAddPaymentModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'payment-method-option',
          width: 510,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Payment Methods'),
            context: this
          }
        });
      },
      validateInvoice() {
        let isValidForm = true;

        this.removeErrors();
        if (!this.department) {
          isValidForm = false;
          this.setError({
            fieldName: 'department',
            errorText: this.$gettext('Please select department.')
          });
        }
        if (!this.paymentMethod) {
          isValidForm = false;
          this.setError({
            fieldName: 'paymentMethod',
            errorText: this.$gettext('Please select payment method.')
          });
        }

        if (isValidForm) {
          this.saveDepartmentInvoice();
        }
      },
      getForm() {
        const form = new FormData();

        form.append('department[default_payment_method_id]', this.paymentMethod);

        return form;
      },
      saveDepartmentInvoice() {
        const form = this.getForm();

        this.$emit('startprogress');
        this.$store.dispatch('EnterpriseStore/updateDepartment', {departmentId: this.department, form}).then(() => {
          this.closeModal();
        }).catch((error) => {
          this.handleErrors(error);
        });
        this.data.context.$on('saveinvoicefailed', () => {
          this.stopProgress();
        });
        this.data.context.$on('saveinvoicesuccess', this.closeModal);
      },
      handleErrors(error) {
        if (error?.data?.errors['global!']) {
          this.setError({
            fieldName: 'paymentMethod',
            errorText: error?.data?.errors['global!'][0]
          });
          this.stopProgress();
        } else {
          this.$store.dispatch('ModalStore/closeModal');
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }, 0);
        }
      },
      stopProgress() {
        this.$emit('stopprogress');
      },
      closeModal() {
        this.$emit('closemodal');
      }
    },
    beforeDestroy() {
      this.removeErrors();
    }
  };
</script>

<style>
  .payment-modal__field--select .sk-select__list {
    max-height: 132px;
  }
</style>

<style scoped src="@assets/css/payment_modal.css"></style>
