const lastUpdatedText = ({context, lastUpdatedSectionData, lastUpdatedJobData}) => {
  const lastUpdatedData = lastUpdatedSectionData || lastUpdatedJobData || {};
  const {lastUpdatedAt, lastUpdatedBy} = lastUpdatedData;
  const template = lastUpdatedSectionData
    ? context.$gettext('Last saved by %{lastUpdatedBy} at %{lastUpdatedAt}')
    : context.$gettext('The job was last saved by %{lastUpdatedBy} at %{lastUpdatedAt}');
  const lastUpdatedAtMoment = context.$moment(lastUpdatedAt).format('DD.MM.YYYY @ HH:mm');

  return lastUpdatedAt && lastUpdatedBy && context.$gettextInterpolate(template, {
    lastUpdatedBy,
    lastUpdatedAt: lastUpdatedAtMoment
  });
};

const feedbackProblem = (context, userIsNotInterpreter, problemId) => {
  switch (problemId) {
    case 'punctuality':
      return userIsNotInterpreter
        ? context.$gettext('The interpreter was late or left prematurely')
        : context.$gettext('I was late or left prematurely');
    case 'showed_up':
      return userIsNotInterpreter
        ? context.$gettext('The interpreter did not show up')
        : context.$gettext('I did not show up');
    case 'norwegian_acceptable':
      return userIsNotInterpreter
        ? context.$gettext('The interpreter\'s language skills were not sufficient')
        : context.$gettext('My language skills were not good enough');
    //  TODO: Should be removed after Feedback v3 Interpreter side is completed
    case 'noisy_env':
      return userIsNotInterpreter
        ? context.$gettext('The interpreter was in a noisy place')
        : context.$gettext('I was in a noisy place');
      case 'quiet_env':
      return userIsNotInterpreter
        ? context.$gettext('The interpreter was in a noisy place')
        : context.$gettext('I was in a noisy place');
    //  TODO: Should be removed after Feedback v3 Interpreter side is completed
    case 'bad_phone_connection':
      return userIsNotInterpreter
        ? context.$gettext('The interpreter had a poor telephone connection')
        : context.$gettext('I had a bad phone connection');
    case 'good_phone_connection':
      return userIsNotInterpreter
        ? context.$gettext('The interpreter had a poor telephone connection')
        : context.$gettext('I had a bad phone connection');
    case 'demander_punctuality':
      return userIsNotInterpreter
        ? context.$gettext('I was late or had to leave prematurely')
        : context.$gettext('The customer was delayed or left prematurely');
    case 'demander_showed_up':
      return userIsNotInterpreter
        ? context.$gettext('I was not able to attend the meeting')
        : context.$gettext('The customer did not show up');
    case 'demander_bad_phone_connection':
      return userIsNotInterpreter
        ? context.$gettext('I had a bad phone connection')
        : context.$gettext('The customer had a bad phone connection');
    case 'conflict_of_interest':
      return userIsNotInterpreter
        ? context.$gettext('The interpreter was recused due to a conflict of interest')
        : context.$gettext('I had conflict of interest');
    default:
      return problemId;
  }
};

const feedbackDelayCausedBy = (context, delayCausedBy) => {
  switch (delayCausedBy) {
    case 'supplier':
      return context.$gettext('Interpreter');
    case 'demander':
      return context.$gettext('Customer');
    default:
      return delayCausedBy;
  }
};

const delayCausedByList = (context) => {
  return [{
    id: 'supplier',
    name: feedbackDelayCausedBy(context, 'supplier')
  }, {
    id: 'demander',
    name: feedbackDelayCausedBy(context, 'demander')
  }];
};

const travelTypeList = (context) => {
  return [{
    id: 'none',
    name: context.$gettext('None')
  }, {
    id: 'public_transport',
    name: context.$gettext('Public transport')
  }, {
    id: 'car',
    name: context.$gettext('Car')
  }];
};

export default {
  lastUpdatedText,
  feedbackProblem,
  feedbackDelayCausedBy,
  delayCausedByList,
  travelTypeList
};
