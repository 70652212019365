import helpers from '@/helpers';
import BookingHelper from '@/store/booking_stores/BookingHelper';

export default ({$moment: moment} = {}) => {
  return {
    namespaced: true,
    state: {
      startTime: '',
      finishTime: '',
      customDuration: '',
      errors: {
        startTime: {name: 'startTimeError', errors: []},
        finishTime: {name: 'finishTimeError', errors: []}
      }
    },
    getters: {
      minJobDuration(state, getters, rootState, rootGetters) {
        const assignmentType = rootState.PostingStore.AssignmentTypeStore.assignmentType;
        const defaultJobTypeParams = rootGetters['UserInfoStore/userAssignmentTypeDuration'].find((type) => type.name === assignmentType) || {};
        const defaultJobDuration = rootGetters['UserInfoStore/defaultJobDuration'];

        return defaultJobTypeParams.minDuration || defaultJobDuration;
      },
      selectedTimeDuration(state) {
        const duration = moment.duration(moment(state.finishTime).diff(moment(state.startTime)));
        return duration.asHours();
      }
    },
    actions: {
      catchAssignmentStartDate({commit, dispatch}, value) {
        commit('setAssignmentStartDate', value);
        commit('setAssignmentFinishDate', value);
        dispatch('changeFinishTime');
        dispatch('PostingNotifierStore/assignmentDateChanged', null, {root: true});
        commit('PostingStore/MultipleDateTimeStore/resetDateTimesOverlappingError', null, {root: true});
      },
      changeFinishTime({state, commit}) {
        const [startHours, startMinutes] = moment(state.startTime).format('HH:mm').split(':');
        const assignmentStartTime = moment()
          .hours(startHours)
          .minutes(startMinutes)
          .seconds(0)
          .milliseconds(0);
        const [finishHours, finishMinutes] = moment(state.finishTime).format('HH:mm').split(':');
        const assignmentFinishTime = moment()
          .hours(finishHours)
          .minutes(finishMinutes)
          .seconds(0)
          .milliseconds(0);
        const diff = assignmentStartTime.diff(assignmentFinishTime);
        const newFinishDate = diff >= 0
          ? moment(state.startTime).add(1, 'day')
          : moment(state.startTime);
        const expectedFinishTime = assignmentFinishTime.format('HH:mm');
        const expectedFinishDate = moment(newFinishDate).format('YYYY-MM-DD');

        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/DateTimeStore/setAssignmentFinishDate',
          payload: expectedFinishDate
        }, {root: true});
        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/DateTimeStore/setFinishTime',
          payload: expectedFinishTime
        }, {root: true});
        commit('setCustomDuration');
      },
      catchStartTime({state, commit, rootGetters}, value) {
        commit('setStartTime', value);

        const newVal = state.startTime;
        const duration = rootGetters['UserInfoStore/defaultJobDuration'];
        const expectedFinishTime = moment(newVal).add(duration, 'minutes').format('HH:mm');
        const expectedFinishDate = moment(newVal).add(duration, 'minutes').format('YYYY-MM-DD');

        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/DateTimeStore/setAssignmentFinishDate',
          payload: expectedFinishDate
        }, {root: true});
        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/DateTimeStore/setFinishTime',
          payload: expectedFinishTime
        }, {root: true});
        commit('PostingStore/MultipleDateTimeStore/resetDateTimesOverlappingError', null, {root: true});
      },
      catchFinishTime({commit, dispatch}, value) {
        commit('setFinishTime', value);
        dispatch('changeFinishTime');
        commit('PostingStore/MultipleDateTimeStore/resetDateTimesOverlappingError', null, {root: true});
      }
    },
    mutations: {
      ...BookingHelper().mutations,
      // only same date change
      setAssignmentStartDate(state, value) {
        state.startTime = helpers.dateTime.setDate(moment, {
          currentTime: state.startTime,
          value
        });
      },
      // only finish date change
      setAssignmentFinishDate(state, value) {
        state.finishTime = helpers.dateTime.setDate(moment, {
          currentTime: state.finishTime,
          value
        });
      },
      // only start time change
      setStartTime(state, value) {
        state.startTime = helpers.dateTime.setTime(moment, {
          currentTime: state.startTime,
          value
        });
      },
      // only finish time change
      setFinishTime(state, value) {
        state.finishTime = helpers.dateTime.setTime(moment, {
          currentTime: state.finishTime,
          value
        });
      },
      // Duration mutations
      setCustomDuration(state) {
        const momentStartTime = moment(state.startTime).milliseconds(0);
        const momentFinishTime = moment(state.finishTime).milliseconds(0);

        state.customDuration = momentFinishTime.diff(momentStartTime, 'minutes');
      },
      resetCustomDuration(state) {
        state.customDuration = '';
      },
      // only for initial time preselection
      setPreselectedTime(state, {duration, preselectedStartTime, preselectedFinishTime}) {
        if (preselectedStartTime && preselectedFinishTime) {
          const preselectedJobDuration = moment(preselectedFinishTime).diff(moment(preselectedStartTime), 'minutes');
          state.startTime = moment(preselectedStartTime);
          if (preselectedJobDuration > duration) {
            state.finishTime = moment(preselectedFinishTime);
            state.customDuration = preselectedJobDuration;
          } else {
            state.finishTime = moment(preselectedStartTime).add(duration, 'minutes');
            state.customDuration = duration;
          }
        } else {
          const [hours, minutes] = helpers.dateTime.convertCurrentTime(moment).split(':');
          state.startTime = moment()
            .hours(+hours)
            .minutes(+minutes)
            .seconds(0)
            .milliseconds(0);
          state.finishTime = moment()
            .hours(+hours)
            .minutes(+minutes)
            .seconds(0)
            .milliseconds(0)
            .add(duration, 'minutes');
        }
      },
      resetTime(state) {
        state.startTime = null;
        state.finishTime = null;
      },
      clearStore(state) {
        state.startTime = '';
        state.finishTime = '';
        state.customDuration = '';
      }
    }
  };
};
