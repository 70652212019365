const signatures = {
  methods: {
    getDocumentsForSigning() {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get('/v1/signable_documents').then((response) => {
          const resp = response ? response?.data || {} : {};
          resolve(resp);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getSignatures() {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get('/v1/signatures').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          if (error?.status == 422) {
            resolve(error);
            return;
          }
          reject(error);
        });
      });
    },
    uploadSignature(form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/signatures', form, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    getSignature(id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/signatures/${id}/download_link`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    getDocForSigning(id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/signable_documents/${id}/download_link`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
  }
};

export default signatures;
