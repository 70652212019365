import projectsRoutes from './server_projects_routes';
import assignmentsRoutes from './server_assignments_routes';
import preferencesRoutes from './server_preferences_routes';
import documentsRoutes from './server_documents_routes';
import payoutsRoutes from './server_payouts_routes';
import testVideoRoutes from '../testVideoRoutes';

export default {
  path: '/:lang/:country/s',
  meta: {
    requiresAuth: true,
    serverOnly: true
  },
  component: () => import('@/pages/shared_pages/sh_Root'),
  children: [
    {
      path: '/',
      redirect: {name: 'ServerHome'}
    },
    {
      path: 'home',
      name: 'ServerHome',
      component: () => import('@/pages/ServerPages/s_Home')
    },
    {
      path: 'calendar',
      name: 'ServerCalendar',
      component: () => import('@/pages/shared_pages/sh_Calendar')
    },
    {
      path: 'change_password',
      name: 'ServerChangePassword',
      meta: {subPage: true},
      component: () => import('@/pages/ServerPages/s_ChangePassword')
    },
    {
      path: 'profile/:id',
      name: 'ServerProfile',
      meta: {subPage: true},
      component: () => import('@/pages/shared_pages/profile/sh_ProfileShow')
    },
    {
      path: 'profile/:id/edit',
      name: 'ServerProfileEdit',
      meta: {
        currentUserOnly: true,
        subPage: true
      },
      component: () => import('@/pages/shared_pages/profile/sh_ProfileEdit')
    },
    {
      path: 'clients',
      name: 'ServerClients',
      component: () => import('@/pages/ServerPages/s_ManageClients')
    },
    {
      path: 'invitations',
      name: 'ServerAllInvitations',
      component: () => import('@/pages/ServerPages/s_AllInvitations')
    },
    {
      path: 'app_android',
      name: 'DownloadApkSeller',
      component: () => import('@/pages/shared_pages/sh_DownloadApk')
    },
    projectsRoutes,
    assignmentsRoutes,
    preferencesRoutes,
    documentsRoutes,
    payoutsRoutes,
    testVideoRoutes('Server')
  ]
};
