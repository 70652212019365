import constants from '@/modules/constants';

const peoples = {
  methods: {
    sendNamesAndAvatar(form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`${constants.API_PATH}/v1/people/${this.uid}`, form, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    getUserInfo(uid) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/people/${uid}`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    deleteCurrentUserAccount(uid) {
      return new Promise((resolve, reject) => {
        this.ajax.delete(`v1/people/${uid}`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    addInterpreterShortInfo(uid, params) {
      return new Promise((resolve, reject) => {
        this.ajax.post(`v1/support/people/${uid}/notes`, params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    mergeDuplicateAccounts(params) {
      return new Promise((resolve, reject) => {
        this.ajax.post('v1/people/merge', params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    fetchUserNotes(uid) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/people/${uid}/notes`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    addUserNote(uid, params) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/people/${uid}/notes`, params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    editUserNote(uid, noteId, params) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/people/${uid}/notes/${noteId}`, params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    }
  }
};

export default peoples;
