export default ({$fetcher: fetchData, $moment: moment}) => {
  return {
    namespaced: true,
    state: {
      unsupportedDevice: false,
      activeRecording: '',
      pageProgress: false,
      wantToStart: false,
      oneRecording: '',
      recordingsList: '',
      sessionData: '',
      pollAnswers: {
        voice_works: '',
        video_works: ''
      }
    },
    actions: {
      getRecordings({commit, dispatch}) {
        return new Promise((resolve, reject) => {
          fetchData.getRecordings().then((data) => {
            commit('setList', data.videoTests);
            if (data.videoTests && data.videoTests.length) {
              const tests = data.videoTests;
              tests.sort((a, b) => moment(a.date) - moment(b.date)).reverse();
              dispatch('getOneRecording', tests[0].id).then(() => {
                resolve();
              });
            } else {
              resolve();
            }
          }).catch((error) => {
            reject(error);
          });
        });
      },
      createSession({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.createSession().then((data) => {
            commit('setSessionData', data);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      startTest({state}) {
        const form = new FormData();
        form.append('video_test[session_id]', state.sessionData.sessionId);
        return new Promise((resolve, reject) => {
          fetchData.startTest(form).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      endTest({state, dispatch}, {id, obj}) {
        const form = new FormData();
        form.append('video_test[browser]', obj.browser);
        form.append('video_test[device]', obj.device);
        form.append('video_test[session_id]', state.sessionData.sessionId);
        return new Promise((resolve, reject) => {
          fetchData.endTest(id, form).then(() => {
            dispatch('getRecordings').then(() => {
              resolve();
            });
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getOneRecording({commit}, id) {
        return new Promise((resolve, reject) => {
          fetchData.getOneRecording(id).then((data) => {
            commit('setOneRecording', data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      deleteRecording({dispatch}, id) {
        return new Promise((resolve, reject) => {
          fetchData.deleteRecording(id).then(() => {
            dispatch('getRecordings').then(() => {
              resolve();
            });
          }).catch((error) => {
            reject(error);
          });
        });
      },
      savePoll({state, dispatch}, id) {
        const form = new FormData();
        form.append('video_test[session_id]', state.oneRecording.sessionId);
        for (const key of Object.keys(state.pollAnswers)) {
          form.append(`video_test[${key}]`, state.pollAnswers[key]);
        }
        return new Promise((resolve, reject) => {
          fetchData.savePoll(id, form).then(() => {
            dispatch('getOneRecording', id).then((data) => {
              for (let i = 0, lim = state.recordingsList.length; i < lim; i += 1) {
                if (state.recordingsList[i].id == id) {
                  state.recordingsList.splice(i, 1, data.videoTest);
                  break;
                }
              }
              resolve();
            });
          }).catch((error) => {
            reject(error);
          });
        });
      }
    },
    mutations: {
      setUnsupportedDevice(state) { state.unsupportedDevice = true; },
      startPageProgress(state) { state.pageProgress = true; },
      stopPageProgress(state) { state.pageProgress = false; },
      setList(state, list) { state.recordingsList = list || []; },
      setSessionData(state, value) { state.sessionData = value; },
      setWantToStart(state, value) { state.wantToStart = value; },
      setPollAnswer(state, obj) {
        switch (obj.id) {
          case 'voice_works':
            state.pollAnswers.voice_works = obj.value;
            break;
          case 'video_works':
            state.pollAnswers.video_works = obj.value;
            break;
        }
      },
      setOneRecording(state, data) {
        if (data) {
          state.oneRecording = data.videoTest || {};
          state.activeRecording = state.oneRecording.id || '';
          state.pollAnswers.voice_works = state.oneRecording.voiceWorks === null || state.oneRecording.voiceWorks === undefined ? '' : state.oneRecording.voiceWorks;
          state.pollAnswers.video_works = state.oneRecording.videoWorks === null || state.oneRecording.videoWorks === undefined ? '' : state.oneRecording.videoWorks;
        }
      },
      clearPoll(state) {
        state.pollAnswers = {
          voice_works: '',
          video_works: ''
        };
      },
      clearSessionData(state) {
        state.sessionData = '';
      },
      clearStore(state) {
        state.unsupportedDevice = false;
        state.activeRecording = '';
        state.wantToStart = false;
        state.pageProgress = false;
        state.oneRecording = '';
        state.recordingsList = '';
        state.sessionData = '';
        state.unsupportedDevice = '';
        state.pollAnswers = {
          voice_works: '',
          video_works: ''
        };
      }
    }
  };
};
