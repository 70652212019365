const documents = {
  methods: {
    // ==================================================
    // ==================================================
    // DOCUMENTS CALLS
    // ==================================================
    // ==================================================
    getDocuments(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/documents', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    addDocument(payload) {
      return new Promise((resolve, reject) => {
        this.ajax.post('/v1/documents', payload).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    deleteDocument(id) {
      return new Promise((resolve, reject) => {
        this.ajax.delete(`/v1/documents/${id}`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
  }
};

export default documents;
