(<template>
  <sk-input :id="id"
            :description="description"
            :placeholder="placeholder"
            :preselected-value="preselectedValue"
            :input-icon="'search'"
            :class="[className]"
            :validation-name="validationName"
            :disabled="disabled"
            :autofocus="autofocus"
            @fieldvaluechanged="valueChanged" />
</template>)
<script>
  export default {
    props: {
      description: {
        type: String,
        default: ''
      },
      validationName: {
        type: String, // connects with ErrorsStorage
        default: ''
      },
      preselectedValue: {
        type: [String, Number],
        required: true
      },
      placeholder: {
        type: String,
        default: ''
      },
      id: {
        type: [Number, String],
        default: ''
      },
      className: {
        type: String,
        default: ''
      },
      disabled: Boolean,
      autofocus: {
        type: Boolean,
        default: false
      },
      isGlobal: { // defines if the search is in Norway or Global
        type: Boolean,
        default: false
      }
    },
    watch: {
      isGlobal() {
        if (window && window.google && window.google.maps) {
          // toggle country restriction
          this.autocomplete.setComponentRestrictions({
            country: this.isGlobal ? [] : ['no']
          });
        }
      }
    },
    methods: {
      addAutocomplete() {
        const autocompleteInput = document.getElementById(this.id);
        if (autocompleteInput) {
          this.autocomplete = new window.google.maps.places.Autocomplete(
            (autocompleteInput),
            {types: ['geocode'],
             componentRestrictions: {country: ['no']}}
          );
          this.autocomplete.addListener('place_changed', () => {
            const place = this.autocomplete.getPlace();
            this.$emit('placechanged', place);
          });
        }
      },
      valueChanged(value) {
        this.$emit('valuechanged', value);
      }
    },
    mounted() {
      setTimeout(() => {
        if (window && window.google && window.google.maps) this.addAutocomplete();
      }, 500);
    }
  };
</script>
