(<template>
  <div class="toggle-section">
    <div :class="{
           'in-progress': progressActive,
           'toggle-section__wrapper--disabled': disabledToggle
         }"
         class="toggle-section__wrapper is-progress-bar">
      <sk-toggler :disabled="disabledToggle"
                  :preselected-value="toggleValue"
                  @togglerchanged="toggleHandler" />
      <p class="toggle-section__text">{{ toggleDescription }}</p>
      <span v-if="toggleStatus"
            :class="[`toggle-section__status--${toggleStatus}`]"
            :title="toggleStatusText"
            :alt="toggleStatusText"
            class="toggle-section__status"></span>
    </div>
    <p v-if="statusError"
       class="toggle-section__status-error"
       v-html="statusErrorText"></p>
  </div>
</template>)

<script>
  export default {
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      toggleValue() {},
      toggleDescription() {},
      toggleStatus() {},
      toggleStatusText() {},
      statusError() {},
      statusErrorText() {},
      disabledToggle() { return false; }
    },
    methods: {
      toggleHandler() {}
    }
  };
</script>

<style scoped src="@assets/css/toggle_section.css"></style>
