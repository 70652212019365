(<template>
  <section class="prof-modal-submit-btns__cont">
    <button class="sk-btn sk-btn--white prof-modal-submit-btns__btn"
            type="button"
            @click="cancelHandler">{{ $gettext("Cancel") }}</button>
    <button class="sk-btn sk-btn--default prof-modal-submit-btns__btn"
            type="submit"
            @click="submitHandler">{{ $gettext("Save") }}</button>
  </section>
</template>)

<script>
  export default {
    props: {
      submitHandler: {
        type: Function,
        default: () => {}
      },
      cancelHandler: {
        type: Function,
        default: () => {}
      }
    }
  };
</script>

<style scoped>
  .prof-modal-submit-btns__cont {
    display: flex;
    justify-content: flex-end;
    width: auto;
    margin-top: 30px;
    margin-left: auto;
  }

  .prof-modal-submit-btns__btn {
    display: inline-block;
    width: auto;
    padding: 0 25px;
  }

  .prof-modal-submit-btns__btn:first-of-type {
    margin-right: 20px;
  }
</style>
