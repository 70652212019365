// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/assignment_accessibility_icons/private_assignment.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/assignment_accessibility_icons/public_assignment.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@assets/imgs/assignment_accessibility_icons/private_assignment_selected.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@assets/imgs/assignment_accessibility_icons/public_assignment_selected.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".accessibility-btn {\n  display: block;\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  background-color: #fff;\n  background-position: 50% 50%;\n  background-repeat: no-repeat;\n}\n.accessibility-btn--private {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.accessibility-btn--public {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.accessibility-btn--private:hover,\n.accessibility-btn--public:hover {\n  background-color: #fbefea;\n  background-color: var(--color-primary-hover);\n}\n.accessibility-btn__input:focus + .accessibility-btn {\n  -webkit-box-shadow: 0 0 0 2px #9dd;\n          box-shadow: 0 0 0 2px #9dd;\n}\n.accessibility-btn__input:checked + .accessibility-btn {\n  background-color: #ff5b24;\n  background-color: var(--color-secondary);\n}\n.accessibility-btn__input:checked + .accessibility-btn--private {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.accessibility-btn__input:checked + .accessibility-btn--public {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
