(<template>
  <post-accordion-section ref="attachmentSection"
                          :state="attachmentsSelectState"
                          :title="title"
                          :description="description"
                          class="attachments-section"
                          @focusin="openAttachmentSectionHelper"
                          @statechanged="changeAttachmentsSelectState">
    <div class="post-form__field-group attachments-section__wrapper">
      <attachments-section :params="params" />
    </div>
  </post-accordion-section>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';
  import PostAccordionSection from '@/components/booking_components/PostAccordionSection';
  import AttachmentsSection from '@/components/booking_components/parts/attachments_section/AttachmentsSection';

  export default {
    components: {
      'post-accordion-section': PostAccordionSection,
      'attachments-section': AttachmentsSection
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('PostingStore/AttachmentsStore', {
        attachmentsSelectState: ({attachmentsSelectState}) => attachmentsSelectState
      }),
      title() {
        return this.$gettext('Upload file');
      },
      description() {
        return this.$gettext('Upload files for the interpreter to read through.');
      },
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    methods: {
      ...mapMutations('PostingStore/AttachmentsStore', [
        'setAttachmentsSelectState',
        'clearStore'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
        'setReferenceToBuyerInfoSection'
      ]),
      openAttachmentSectionHelper() {
        if (this.showAssignmentHelper) {
          this.setPopoverReference(this.$refs.attachmentSection);
          this.openHelper('attachment');
        }
      },
      changeAttachmentsSelectState(value) {
        if (!value) {
          this.setReferenceToBuyerInfoSection();
          this.openHelper('');
        }
        this.clearStore();
        this.setAttachmentsSelectState(value);
      }
    }
  };
</script>

<style scoped>
  .attachments-section__wrapper {
    margin-top: 20px;
    margin-bottom: -10px;
  }
</style>
