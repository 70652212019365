(<template>
  <div class="post-form__field extra-info">
    <sk-textarea :description="extraInfoText"
                 :preselected-value="extraInfo"
                 :placeholder="extraInfoPlaceholder"
                 class="extra-info__textarea"
                 :maxlength="charLimit"
                 @fieldvaluechanged="setExtraInfo" />
    <p class="extra-info__character-limit"
       v-html="charMessage"></p>
    <p v-if="showExtraInfoNote"
       class="extra-info__note">{{ noteText }}</p>
  </div>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';

  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('PostingStore/ExtraInfoStore', {
        extraInfo: (state) => state.extraInfo
      }),
      paramsList() { return this.params || {}; },
      extraInfoText() { return this.paramsList.extraInfoText || this.$gettext('Message for the interpreter'); },
      showExtraInfoNote() { return this.paramsList.showExtraInfoNote || false; },
      extraInfoPlaceholder() { return this.$gettext('Write here...'); },
      noteText() {
        return this.$gettext('Details for the interpreter so they can prepare. No personal or sensitive details.');
      },
      remainingChars() {
        return this.charLimit - this.extraInfo.length;
      },
      charLimit() {
        return 1000;
      },
      charMessage() {
        const remChars = this.remainingChars;
        const charLimit = this.charLimit;
        const template = this.$gettext('Maximum <strong>%{charLimit}</strong> characters ( <strong> %{remChars} </strong> remaining )');
        return this.$gettextInterpolate(template, {remChars, charLimit});
      }
    },
    methods: {
      ...mapMutations('PostingStore/ExtraInfoStore', [
        'setExtraInfo'
      ])
    }
  };
</script>

<style>
  .extra-info__textarea .sk-input__input-wrapper--textarea {
    height: 80px;
  }
</style>

<style scoped>
.extra-info__character-limit {
  margin: 2px 0 9px 5px;
  padding-bottom: 8px;
}

.extra-info__textarea {
  margin-bottom: 0;
}
</style>
