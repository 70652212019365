<template>
  <div class="news-container">
    <div class="news-list">
      <h2>{{ $gettext('News Archive') }}</h2>
      <div v-for="article in newsList.articles"
           :key="article.id"
           class="news-item">
        <h3 class="news-title">{{ article.title }}</h3>
        <news-content :content="article.content" />
      </div>
    </div>
    <button v-if="currentPage < newsList.pages"
            class="load-news__button"
            @click="loadMoreNewsClick">{{ $gettext('Load more') }}</button>
  </div>
</template>

<script>
  import NewsContent from '@/components/dashboard_components/NewsContent';
  import {mapActions, mapState} from 'vuex';

  export default {
    components: {
      'news-content': NewsContent
    },
    data() {
      return {
        showFullText: false
      };
    },
    computed: {
      ...mapState('b_DashboardStore', {
        newsList: (state) => state.newsList || {}
      }),
      currentPage() {
        return this.newsList.page;
      }
    },
    methods: {
      ...mapActions({
        getDashboardNewsList: 'b_DashboardStore/getDashboardNewsList'
      }),
      shortedText(content) {
        if (!this.showFullText && content.length >= 200) {
          return content.slice(0, 200) + '...';
        }
        return content;
      },
      showText() {
        this.showFullText = !this.showFullText;
      },
      loadMoreNewsClick() {
        this.$emit('load');
        const params = {
          page: this.currentPage + 1
        };
        this.getDashboardNewsList(params);
      }
    },
    mounted() {
      this.getDashboardNewsList(this.params);
    }
  };
</script>

<style scoped>
.news-container {
  display: grid;
}

.news-list {
  display: block;
  align-items: center;
  justify-content: space-around;
  margin-top: 5px;
  padding: 20px;
  list-style-type: none;
}

.news-item {
  display: block;
  padding: 10px 0 10px 0;
  border-bottom: 1px solid #e9eaef;
}

.load-news__button {
  margin: 0 30% 20px;
  padding: 8px 45px;
  border: solid 1px #ff5b24;
  border-radius: 5px;
  color: #ff5b24;
}
</style>
