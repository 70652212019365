(<template>
  <section class="checkboxes-list">
    <ul class="profile__modal-checkboxes-list">
      <li v-for="(item, index) in editedData"
          :key="index"
          class="profile__modal-checkboxes-list-item">
        <sk-checkbox :item-text="computeCheckboxText(item.name)"
                     :item-value="item.id"
                     :autofocus="item.id == editedData[0].id"
                     :preselected-value="item.checked"
                     @checkboxvaluechanged="catchCheckbox" />
      </li>
    </ul>
    <submit-btns :submit-handler="submitHandler"
                 :cancel-handler="closeModal" />
  </section>
</template>)

<script>
  import SubmitBtns from '@/components/profile_components/edit/ModalSubmitBtns';

  export default {
    components: {
      'submit-btns': SubmitBtns
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        editedData: JSON.parse(JSON.stringify(this.data.data.data))
      };
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      computeCheckboxText(name) {
        if (this.data.data.type == 'sessionTypes') {
          switch (name) {
            case 'video':
              return this.$gettext('Video assignment');
            case 'phone':
              return this.$gettext('Phone assignment');
            case 'in_person':
              return this.$gettext('In-person assignment');
            case 'videoroom':
              return this.$gettext('Video room assignment');
          }
        } else {
          return name;
        }
      },
      submitHandler() {
        const form = new FormData();
        const jsonForm = [];
        // form.append(this.data.data.type, JSON.stringify(this.editedData));
        for (const item of this.editedData) {
          form.append(`${this.data.data.type}[${item.id}]`, item.checked);
          jsonForm.push({[item.id]: item.checked});
        }
        this.$emit('startprogress');
        switch (this.data.data.type) {
          case 'expertiseAreas':
            this.data.context.updateAreas(form);
            break;
          case 'interpretationMethods':
            this.data.context.updateMethods(form);
            break;
          case 'translationMethods':
            this.data.context.updateMethods(jsonForm);
            break;
          case 'sessionTypes':
            this.data.context.updateTypes(form);
            break;
        }
        // this.data.context.updateAreasTypesAndMethods(form);
        this.data.context.$on('updatefailed', this.stopProgress);
        this.data.context.$on('updatesuccess', this.closeModal);
      },
      closeModal() {
        this.$emit('closemodal');
      },
      stopProgress() {
        this.$emit('stopprogress');
      },
      catchCheckbox(checked, value) {
        for (const item of this.editedData) {
          if (item.id == value) {
            item.checked = checked;
            return;
          }
        }
      }
    }
  };
</script>

<style scoped>
  .checkboxes-list {
    display: block;
    width: 100%;
    padding: 20px 35px 35px;
  }
</style>
