export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      viewers: {
        all: [],
        background: [],
        foreground: [],
      },
      viewersList: []
    },
    actions: {
      setViewers({state, dispatch, commit}, data = []) {
        // ---- filter viewers -- //
        const backgroundViewers = data?.activePeople?.filter((uid) => {
          return !data?.foregroundActivePeople.includes(uid);
        }) || [];
       // ---- foreground viewers -- //
        const foregroundViewers = data?.foregroundActivePeople || [];
        // ---- all viewers -- //
        const all = backgroundViewers.concat(foregroundViewers) || [];
        if (state) {
          const viewersInfoIsComplete = state.viewers?.all?.every((uid) => all.includes(uid)) && state.viewers.all.length === all.length;
          if (!viewersInfoIsComplete) dispatch('getViewersList', all);
          commit('setViewers', {all, background: backgroundViewers, foreground: foregroundViewers});
        }
      },
      getViewersList({commit}, uids = []) {
        return new Promise((resolve, reject) => {
          if (uids.length) {
            fetchData.getViewers(uids).then((data) => {
              commit('setViewersList', data.people);
              resolve(data);
            }).catch((response) => {
              reject(response);
            });
          } else {
            commit('setViewersList', []);
          }
        });
      }
    },
    mutations: {
      setViewersList(state, data) {
        state.viewersList = data;
      },
      setViewers(state, data) {
        state.viewers = data;
      }
    }
  };
};
