// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/salita/download_orange_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.cooperation-agreement[data-v-30604914] {\n  z-index: 100;\n  display: block;\n  width: 100%;\n  padding: 30px;\n}\n@media (max-width: 767px) {\n.cooperation-agreement__btn--cancel[data-v-30604914] {\n    height: 60px !important;\n}\n.cooperation-agreement--downloading[data-v-30604914] {\n    height: 50px !important;\n}\n.cooperation-agreement__btn--sign[data-v-30604914] {\n    height: 60px !important;\n}\n}\n.cooperation-agreement__deadline-text[data-v-30604914] {\n  width: 450px;\n}\n.cooperation-agreement__checkbox[data-v-30604914] {\n  margin-top: 12px;\n}\n.cooperation-agreement--downloading[data-v-30604914] {\n  display: inline-block;\n  width: auto;\n  height: 36px;\n  margin-top: 15px;\n  padding: 5px 10px 5px 30px;\n  border: 1px solid var(--color-primary);\n  border-radius: 3px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 5px;\n  background-repeat: no-repeat;\n}\n.cooperation-agreement__title[data-v-30604914] {\n  margin-top: -45px;\n  margin-bottom: 15px;\n  font-weight: bold;\n  font-size: 18px;\n}\n.cooperation-agreement__btn-cont[data-v-30604914] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  margin-top: 25px;\n}\n.is-disabled[data-v-30604914] {\n  border: 1px solid #8b8d8f;\n  background-color: #f2f4f7;\n  color: #8b8d8f;\n  -webkit-box-shadow: none;\n          box-shadow: none;\n  cursor: not-allowed;\n}\n", ""]);
// Exports
module.exports = exports;
