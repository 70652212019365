(<template>
  <div class="inter-info">
    <section class="inter-info__data-cont">
      <div :class="{'is-favourite favourite-icon': showFavouriteLogo,
                    'is-attested-by-police attested-by-police-icon': showPoliceLogo}"
           class="inter-info__avatar-cont">
        <router-link :to="interpreterLink"
                     target="_blank">
          <tt-avatar class="inter-info__avatar"
                     :avatar="avatarUrl" />
        </router-link>
      </div>

      <div class="inter-info__info-cont">
        <div class="inter-info__row">
          <p class="inter-info__title">
            {{ interName }}
            <button v-if="$isGodmode()"
                    class="sk-link sk-link--default inter-info__support-link"
                    @click="loginAsInterpreter">{{ $gettext('[T]') }}</button>
          </p>
          <p class="inter-info__subtitle">{{ interInfo }}</p>
        </div>

        <div class="inter-info__row">
          <p class="inter-info__title">
            {{ languageFrom }} - {{ languageTo }}
          </p>
          <p class="inter-info__subtitle">{{ interQualification }}</p>
        </div>

        <div v-if="interPhone"
             class="inter-info__row">
          <p class="inter-info__title">{{ $gettext('Tel:') }}</p>
          <a :href="`tel:${interPhone}`"
             class="inter-info__link js-copy-to-clipboard">{{ interPhone }}</a>
        </div>

        <div v-if="interEmail"
             class="inter-info__row">
          <p class="inter-info__title">{{ $gettext('Email:') }}</p>
          <a :href="`mailto:${interEmail}`"
             class="inter-info__link">{{ interEmail }}</a>
        </div>
      </div>
    </section>
  </div>
</template>)

<script>
  import helpers from '@/helpers';

  export default {
    props: {
      supplierInfo: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      interpreterLink() { return this.supplierInfo.uid ? this.$makeRoute({name: 'BuyerUserProfile', params: {id: this.supplierInfo.uid}}) : ''; },
      showFavouriteLogo() { return this.supplierInfo.relation === 'favourite'; },
      showPoliceLogo() { return this.supplierInfo.showPoliceLogo || false; },
      avatarUrl() { return this.supplierInfo.avatar || ''; },
      interName() { return this.supplierInfo.name || ''; },
      interPhone() { return this.supplierInfo.phone || ''; },
      interEmail() { return this.supplierInfo.email || ''; },
      languageFrom() { return this.$getLangName(this.supplierInfo.languageFromId); },
      languageTo() { return this.$getLangName(this.supplierInfo.languageToId); },
      interQualification() {
        return helpers.getJobInfo.getQualificationName(this, {
          id: this.supplierInfo.qualification,
          showShortName: true
        }) || '';
      },
      interInfo() {
        const {city, country, gender} = this.supplierInfo;
        const addressText = (city && country) ? `${city}, ${country}` : (city || country || '');
        const genderText = gender ? `${gender} | ` : '';

        return `${genderText}${addressText}`;
      }
    },
    methods: {
      loginAsInterpreter() {
        const {id: userId, uid: userUid} = this.supplierInfo;
        const params = {
          uid: userUid,
          admin_uid: this.$store.state.UserInfoStore.adminInfo.uid,
          notifs: false
        };

        this.$store.dispatch('TTAuthStore/getGodModeToken', params).then((data) => {
          const link = helpers.getGodmodeLoginLink({
            userId,
            userUid,
            token: data.token,
            jobId: this.jobId
          });
          window.open(link, '_blank');
        });
      }
    }
  };
</script>

<style scoped>
  .inter-info__data-cont {
    display: flex;
    flex-grow: 0;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .inter-info__avatar-cont {
    position: relative;
    flex-shrink: 0;
  }

  .inter-info__avatar-cont.is-attested-by-police::after {
    right: 0;
    width: 30px;
    height: 30px;
  }

  .inter-info__avatar-cont.is-favourite::before {
    left: 4px;
    width: 15px;
    height: 15px;
  }

  .inter-info__avatar {
    width: 75px;
    height: 75px;
  }

  .inter-info__info-cont {
    flex-grow: 1;
    padding: 0 20px;
  }

  .inter-info__row:nth-child(1n + 2) {
    margin-top: 10px;
  }

  .inter-info__title {
    display: inline-block;
    font-weight: bold;
  }

  .inter-info__subtitle {
    display: block;
    color: #7a829d;
    font-size: 10px;
  }

  .inter-info__support-link {
    margin-left: 3px;
  }

  .inter-info__link {
    color: #ff5b24;
    color: var(--color-secondary);
    font-weight: bold;
  }

  @media (max-width: 768px) {
    .inter-info__data-cont {
      flex-direction: column;
    }

    .inter-info__info-cont {
      margin-top: 10px;
      padding: 0;
    }
  }
</style>
