// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/close_x_icon_blue.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".sk-info-modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 11;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n}\n.sk-info-modal__wrapper {\n  position: relative;\n  display: block;\n  overflow: auto;\n  width: 600px;\n  max-height: 100%;\n  padding: 40px 30px 20px 30px;\n  border-radius: 8px;\n  background-color: #fff;\n}\n.sk-info-modal__title {\n  font-weight: 600;\n  font-size: 20px;\n  line-height: 24px;\n}\n.sk-info-modal__text {\n  padding: 20px 0;\n  font-size: 14px;\n  line-height: 20px;\n}\n.sk-info-modal__close-btn {\n  position: absolute;\n  top: 0;\n  right: 0;\n  display: block;\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 17px auto;\n  background-repeat: no-repeat;\n}\n.sk-info-modal__close-btn:hover {\n  background-color: rgba(0, 56, 85, 0.1);\n}\n.sk-info-modal__close-btn:active {\n  background-color: #e1d7eb;\n}\n.sk-info-modal__ok-btn {\n  width: auto;\n  margin-left: auto;\n  padding: 0 12px;\n}\n@media (max-width: 767px) {\n.sk-info-modal {\n    -webkit-box-align: stretch;\n        -ms-flex-align: stretch;\n            align-items: stretch;\n}\n.sk-info-modal__wrapper {\n    width: 100%;\n}\n}\n", ""]);
// Exports
module.exports = exports;
