import Vue from 'vue';

export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      interpreters: {
        interpreters: '',
        pageTotal: 0
      }
    },
    getters: {
      totalPages(state) {
        return state.interpreters.pageTotal;
      },
      allInterpreters(state) {
        return state.interpreters.interpreters || [];
      }
    },
    actions: {
      fetchInterpreters({rootState, commit}, params) {
        return new Promise((resolve, reject) => {
          params.amount = rootState.filterItemsAmount;
          fetchData.fetchInterpreters(params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setInterpreters', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      }
    },
    mutations: {
      setInterpreters(state, data) {
        state.interpreters = data;
      },
      removeInterpreters(state) {
        state.interpreters = {
          interpreters: '',
          pageTotal: 0
        };
      },
      setNewRelation(state, {uid, value}) {
        const interpreter = state.interpreters.interpreters.find((inter) => inter.uid == uid);
        if (interpreter) {
          Vue.set(interpreter, 'relation', value ? 'favourite' : '');
        }
      }
    }
  };
};
