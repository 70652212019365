export default () => {
  return {
    mutations: {
      setError(state, {fieldName, errorText}) {
        state.errors[fieldName].errors = [...state.errors[fieldName].errors, errorText];
      },
      removeOneError(state, fieldName) {
        state.errors[fieldName].errors = [];
      },
      removeErrors(state) {
        for (const key of Object.keys(state.errors)) {
          state.errors[key].errors = [];
        }
      }
    }
  };
};
