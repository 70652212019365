export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      searchQuery: '',
      progress: false,
      searchAssignments: ''
    },
    actions: {
      getSearchAssignments({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getSearchAssignments(params).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setSearchAssignments', response);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      cancelSearchAssignments() {
        return new Promise((resolve, reject) => {
          fetchData.cancelSearchAssignments().then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      }
    },
    mutations: {
      setSearchAssignmentProgress(state, value) {
        state.progress = value;
      },
      setSearchAssignments(state, data) {
        state.progress = false;
        state.searchAssignments = data;
      },
      setSearchQuery(state, value) {
        state.searchQuery = value;
      },
      clearStore(state) {
        state.searchQuery = '';
        state.searchAssignments = '';
        state.progress = false;
      }
    }
  };
};
