(<template>
  <article class="assign-apply">
    <p class="assign-apply__title">{{ $gettext('Thanks for applying to this assignment.') }}</p>
    <invoice-draft-info v-for="(invoice, index) in invoices"
                        :key="index"
                        :title="invoice.title"
                        :invoice="invoice"
                        class="assign-apply__prices-cont" />
    <apply-conditions-check :data="data"
                            @validationchanged="catchValidationChange">
      <contract-section v-if="showContractLink"
                        :link-name="contractLink"
                        class="assign-apply__link" />
    </apply-conditions-check>
    <button :class="{'is-disabled': !isValidForm}"
            class="sk-btn sk-btn--default assign-apply__btn"
            @click="sendApply">{{ $gettext('Send offer') }}</button>
  </article>
</template>)

<script>
  import {mapState} from 'vuex';
  import helpers from '@/helpers';
  import ApplyConditionsCheck from '@/components/assignment_components/one_assignment/interactive_info/pt_applying/ApplyConditionsCheck';
  import InvoiceDraftInfo from '@/components/assignment_components/one_assignment/interactive_info/shared_components/InvoiceDraftInfo';
  import ContractSection from '@/components/assignment_components/one_assignment/assignment_info/ContractSection';

  export default {
    components: {
      'contract-section': ContractSection,
      'invoice-draft-info': InvoiceDraftInfo,
      'apply-conditions-check': ApplyConditionsCheck
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        isValidForm: false
      };
    },
    computed: {
      ...mapState('AssignmentFlowStore', {
        modalPreviewData: ({modalPreviewData}) => modalPreviewData || []
      }),
      ...mapState('OneAssignmentStore', {
        assignmentObj: ({storeJobObj}) => storeJobObj || {}
      }),
      invoices() {
        return this.modalPreviewData.map((data = {}) => {
          const titleTemplate = this.$gettext('Assignment #%{jobId}');
          const jobId = helpers.getJobInfo.jobId(data);

          return {
            ...data.invoice,
            title: this.$gettextInterpolate(titleTemplate, {jobId})
          };
        });
      },
      showContractLink() {
        return this.assignmentObj.signedContract?.pricingTemplates?.length;
      },
      contractLink() { return this.$gettext('This customer has set their own fixed rates. Click to see their payment rules here.'); }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() { this.$emit('closemodal'); },
      catchValidationChange(value) { this.isValidForm = value; },
      sendApply() {
        if (this.isValidForm) {
          this.data.context.makeApply();
          this.closeModal();
        }
      }
    },
    beforeDestroy() {
      this.$store.commit('AssignmentFlowStore/clearModalData');
    }
  };
</script>

<style scoped>
  .assign-apply {
    display: block;
    width: 100%;
    padding: 10px 30px 20px;
  }

  .assign-apply__title {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 14px;
  }

  .assign-apply__prices-cont {
    margin-bottom: 30px;
  }

  .assign-apply__price-title {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 16px;
  }

  .assign-apply__btn {
    width: auto;
    margin-left: auto;
    padding: 0 30px;
  }

  @media (max-width: 768px) {
    .assign-apply {
      padding: 15px;
    }
  }
</style>
