(<template>
  <div class="apply-conditions-check">
    <sk-note v-if="assignmentObj.isPolice && !isWithoutTravel"
             :text="policeNoteText"
             class="apply-conditions__police-note" />
    <sk-note v-if="noteText && !isWithoutTravel"
             :text="noteText"
             class="apply-conditions-check__note" />
    <sk-note v-if="cancellationNoteText"
             :text="cancellationNoteText"
             class="apply-conditions-check__note" />
    <expense-warning-banner v-if="isInPersonJob && !isWithoutTravel"
                            :receipt-confirm="receiptConfirm"
                            :allowance-amount="Number(maxAllowanceAmount)"
                            :any-travel-expenses="anyTravelExpensesCovered"
                            class="apply-conditions-check__expense-warning"
                            @confrimchanged="setReceiptConfirm" />
    <slot></slot>
    <div class="apply-conditions-check__checkboxs">
      <div v-if="showVideoSystemCondition"
           class="apply-video__section">
        <div class="apply-video__container">
          <p v-html="videoPlatformText"></p>
          <sk-checkbox v-if="showVideoSystemCondition"
                       :preselected-value="acceptedVideoSystem"
                       :validation-name="errors.acceptedVideoSystem.name"
                       :item-text="acceptedVideoSystemText"
                       class="apply-conditions-check__checkbox"
                       @checkboxvaluechanged="catchAcceptedVideoSystem" />
          <p class="apply-video__specs">{{ videoSpecificationsText }}</p>
        </div>
      </div>
      <terms-check :preselected-value="acceptedTerms"
                   :validation-name="errors.acceptedTerms.name"
                   :item-text="termsText"
                   class="apply-conditions-check__checkbox"
                   @checkboxvaluechanged="termsValueChanged" />
      <sk-checkbox v-if="allowUndertime"
                   :preselected-value="acceptedUndertime"
                   :validation-name="errors.acceptedUndertime.name"
                   :item-text="acceptedUndertimeText"
                   class="apply-conditions-check__checkbox"
                   @checkboxvaluechanged="catchAcceptedUndertime" />
      <sk-checkbox v-if="allowCanceledOnShortNotice"
                   :preselected-value="acceptedShortNotice"
                   :validation-name="errors.acceptedShortNotice.name"
                   :item-text="acceptedShortNoticeText"
                   class="apply-conditions-check__checkbox"
                   @checkboxvaluechanged="catchAcceptedShortNotice" />
      <sk-checkbox v-if="hasNoTravelCost"
                   :preselected-value="acceptedNoTravelCost"
                   :validation-name="errors.acceptedNoTravelCost.name"
                   :item-text="acceptedNoTravelCostText"
                   class="apply-conditions-check__checkbox"
                   @checkboxvaluechanged="catchAcceptedNoTravelCost" />
    </div>
  </div>
</template>)

<script>
  import {mapState, mapGetters} from 'vuex';
  import helpers from '@/helpers';
  import WarningBanner from '@/components/shared_components/banners/RecepitWarningBanner';
  import CancellationPolicyPrototype from '@/prototypes/CancellationPolicyPrototype';

  export default {
    components: {
      'expense-warning-banner': WarningBanner
    },
    extends: CancellationPolicyPrototype,
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        domain: this.$getString('domainData', 'platformName'),
        acceptedTerms: false,
        acceptedUndertime: false,
        acceptedShortNotice: false,
        acceptedNoTravelCost: false,
        acceptedVideoSystem: false,
        receiptConfirm: false,
        errors: {
          acceptedTerms: {name: 'acceptedTermsValidation', errors: []},
          acceptedUndertime: {name: 'acceptedUndertimeValidation', errors: []},
          acceptedShortNotice: {name: 'acceptedShortNoticeValidation', errors: []},
          acceptedNoTravelCost: {name: 'acceptedNoTravelCostValidation', errors: []},
          acceptedVideoSystem: {name: 'acceptedVideoSystemValidation', errors: []},
        }
      };
    },
    computed: {
      ...mapGetters('AssignmentFlowStore', [
        'modalPricing',
        'travelCostsPricing'
      ]),
      ...mapGetters('OneAssignmentStore', [
        'customerUseOwnVideoSystem'
      ]),
      ...mapState('AssignmentFlowStore', {
        travelType: ({travelType}) => travelType,
        previewData: ({previewData}) => previewData || ''
      }),
      ...mapState('OneAssignmentStore', {
        assignmentObj: ({storeJobObj}) => storeJobObj || {}
      }),
      videoPlatformText() {
        const videoPlatformName = this.videoPlatform.name || this.$gettext('own video solution');
        const template = this.$gettext('<b>Videoplatform:</b> %{videoPlatformName}.');
        return this.$gettextInterpolate(template, {videoPlatformName});
      },
      isWithoutTravel() { return this.travelType === 'no_travel'; },
      maxAllowanceAmount() { return this.travelCostsPricing.maxAllowanceAmount || 0; },
      anyTravelExpensesCovered() { return this.travelCostsPricing.anyTravelExpensesCovered; },
      interpretationRequirement() { return this.assignmentObj.interpretationRequirement || {}; },
      assignmentType() { return this.interpretationRequirement.sessionType || {}; },
      videoPlatform() { return this.interpretationRequirement.videoPlatform || {}; },
      isInPersonJob() { return this.previewData[0]?.sessionType === 'in_person'; },
      priceRequirement() { return this.assignmentObj.priceRequirement || {}; },
      acceptedUndertimeText() { return this.$gettext('I understand that I will get paid less if the booking finishes earlier than planned.'); },
      acceptedShortNoticeText() { return this.$gettext('I understand that I will get paid less if booking gets cancelled on short notice.'); },
      acceptedVideoSystemText() {
        const videoPlatformName = this.videoPlatform.name || this.$gettext('own video solution');
        const template = this.$gettext('I understand that the customer will be using %{videoPlatformName} for this assignment.');
        return this.$gettextInterpolate(template, {videoPlatformName});
      },
      videoSpecificationsText() {
        return this.$gettext('The video meeting specifications will either come in the confirmation email or will be sent by the customer in a separate email.');
      },
      acceptedNoTravelCostText() {
        const template = this.$gettext('I accept that I am applying for this assignment without any travel costs. I will not charge %{domain} for my travel to or from this assignment.');
        return this.$gettextInterpolate(template, {domain: this.domain});
      },
      termsText() {
        const template = this.$gettext('I accept %{domain}\'s <a class="sk-link" href="%{termsLink}" target="_blank">terms and conditions</a>');
        return this.$gettextInterpolate(template, {domain: this.domain, termsLink: this.$getString('domainData', 'termLink')});
      },
      ptTravelCosts() { return this.modalPricing.travelRatePerHour || 0; },
      noteText() {
        let template = '';
        const domain = this.$getString('domainData', 'platformName');

        if (this.allowCanceledOnShortNotice && this.allowUndertime) {
          template = this.$gettext('The customer will pay less if the booking finishes early or gets cancelled on short notice.<br/>Please note that this is the customer choice, and is out of %{domain}\'s control.');
        } else if (this.allowUndertime) {
          template = this.$gettext('The customer will pay less if the booking finishes on short notice.');
        } else if (this.allowCanceledOnShortNotice) {
          template = this.$gettext('The customer will pay less if the booking gets cancelled on short notice.<br/>Please note that this is the customer choice, and is out of %{domain}\'s control.');
        }
        return template ? this.$gettextInterpolate(template, {domain}) : '';
      },
      policeNoteText() {
        return this.$gettext('This assignment is remunerated in accordance with the salærforskrift.');
      },
      showVideoSystemCondition() {
        return this.assignmentType === 'video' && this.customerUseOwnVideoSystem;
      },
      allowUndertime() {
        return this.modalPricing.paidLessOnEarlyFinish;
      },
      allowCanceledOnShortNotice() {
        return this.modalPricing.paidLessOnShortNoticeCancel;
      },
      isNotFlatAmount() {
        return helpers.priceTemplates.isNotFlatAmount(this.modalPricing);
      },
      hasNoTravelCost() {
        return !this.travelType && this.ptTravelCosts && this.isNotFlatAmount;
      }
    },
    methods: {
      getCancellationPolicyType(type) {
        switch (type) {
          case 'calendar_hours_12':
            return this.$gettext('12 hours before assignment');
          case 'calendar_hours_24':
            return this.$gettext('24 hours before assignment');
          case 'day_before_1400':
            return this.$gettext('14:00 on the previous calendar day');
          case 'day_before_1200':
            return this.$gettext('12:00 on the previous calendar day');
          case 'day_before_1500':
            return this.$gettext('15:00 on the previous calendar day');
          case 'working_days_3':
            return this.$gettext('3 working days before assignment');
          default:
            return this.$gettext('24 hours before assignment');
        }
      },
      validateForm() {
        let isValidForm = true;

        this.removeErrors();
        if (!this.acceptedTerms) {
          isValidForm = false;
          this.errors.acceptedTerms.errors.push(this.$gettext('required'));
        }
        if (this.showVideoSystemCondition && !this.acceptedVideoSystem) {
          isValidForm = false;
          this.errors.acceptedVideoSystem.errors.push(this.$gettext('required'));
        }
        if (this.allowUndertime && !this.acceptedUndertime) {
          isValidForm = false;
          this.errors.acceptedUndertime.errors.push(this.$gettext('required'));
        }
        if (this.allowCanceledOnShortNotice && !this.acceptedShortNotice) {
          isValidForm = false;
          this.errors.acceptedShortNotice.errors.push(this.$gettext('required'));
        }
        if (this.hasNoTravelCost && !this.acceptedNoTravelCost) {
          isValidForm = false;
          this.errors.acceptedNoTravelCost.errors.push(this.$gettext('required'));
        }
        if (this.isInPersonJob && !this.receiptConfirm && !this.isWithoutTravel) {
          isValidForm = false;
        }

        this.$store.commit('ErrorsStore/setError', {name: this.errors.acceptedTerms.name, errors: this.errors.acceptedTerms.errors});
        this.$store.commit('ErrorsStore/setError', {name: this.errors.acceptedUndertime.name, errors: this.errors.acceptedUndertime.errors});
        this.$store.commit('ErrorsStore/setError', {name: this.errors.acceptedShortNotice.name, errors: this.errors.acceptedShortNotice.errors});
        this.$store.commit('ErrorsStore/setError', {name: this.errors.acceptedNoTravelCost.name, errors: this.errors.acceptedNoTravelCost.errors});
        this.$store.commit('ErrorsStore/setError', {name: this.errors.acceptedVideoSystem.name, errors: this.errors.acceptedVideoSystem.errors});

        return isValidForm;
      },
      termsValueChanged(val) {
        this.catchConditionChange(val, 'acceptedTerms');
      },
      catchAcceptedUndertime(val) {
        this.catchConditionChange(val, 'acceptedUndertime');
      },
      catchAcceptedVideoSystem(val) {
        this.catchConditionChange(val, 'acceptedVideoSystem');
      },
      catchAcceptedShortNotice(val) {
        this.catchConditionChange(val, 'acceptedShortNotice');
      },
      catchAcceptedNoTravelCost(val) {
        this.catchConditionChange(val, 'acceptedNoTravelCost');
      },
      catchConditionChange(val, name) {
        this.removeOneError(name);
        this[name] = val;
        this.$emit('validationchanged', this.validateForm());
      },
      setReceiptConfirm(value) {
        this.receiptConfirm = value;
        this.$emit('validationchanged', this.validateForm());
      },
      removeErrors() {
        for (const key of Object.keys(this.errors)) {
          this.errors[key].errors = [];
        }
      },
      removeOneError(fieldName) {
        this.errors[fieldName].errors = [];
        this.$store.commit('ErrorsStore/setError', {name: this.errors[fieldName].name, errors: this.errors[fieldName].errors});
      }
    },
    beforeDestroy() {
      this.removeErrors();
      this.$store.commit('ErrorsStore/removeAllErrors');
    }
  };
</script>

<style scoped>
  .apply-conditions-check__checkbox {
    margin: 10px 0 10px;
  }

  .apply-conditions__police-note {
    margin-top: 10px;
  }

  .apply-conditions-check__checkboxs {
    margin: 20px 0 30px;
  }

  .apply-conditions-check__note {
    margin-top: 20px;
  }

  .apply-video__section {
    border-radius: 3px;
    background-color: #e2d2bb;
  }

  .apply-video__specs {
    margin-left: 25px;
  }

  .apply-video__container {
    padding: 7px;
  }
</style>
