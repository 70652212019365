export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      // demander side
      pricingData: '',
      pricingModalData: '',
      // supplier side
      previewData: [],
      modalPreviewData: [],
      // both side
      seriesJobConfirmation: false,
      travelDuration: '',
      travelAllowance: '',
      travelType: '',
      pricingFinalData: '',
      invoiceGenerationInProgress: false, // should be deleted after BE fix
      errors: {
        seriesJobConfirmation: {
          name: 'seriesJobConfirmationErrorName',
          errors: []
        },
        travelDuration: '',
        travelAllowance: '',
        maxTravelDuration: '',
        maxTravelInvoice: ''
      }
    },
    getters: {
      // demander: invoice preview pricing
      getPricingModalData(state) { return state.pricingModalData || {}; },
      invoiceDraftModalData(state, getters) { return getters.getPricingModalData.invoice || {}; },
      getPricingData(state) { return state.pricingData || {}; },
      invoiceDraftData(state, getters) { return getters.getPricingData.invoice || {}; },
      // supplier: invoice preview pricing
      travelCostsModalPricing(state) {
        if (state.modalPreviewData.length) {
          // select first in-person job from series
          const inPersonJobPreviewData = state.modalPreviewData.find((data) => {
            return data.sessionType === 'in_person';
          }) || {};

          return inPersonJobPreviewData.pricing || {};
        }
        return {};
      },
      modalPricing(state, getters) {
        if (Object.keys(getters.travelCostsModalPricing).length === 0) {
          return state.modalPreviewData.length ? state.modalPreviewData[0].pricing : {};
        }
        return getters.travelCostsModalPricing;
      },
      travelCostsPricing(state) {
        if (state.previewData.length) {
          // select first in-person job from series
          const inPersonJobPreviewData = state.previewData.find((data) => {
            return data.sessionType === 'in_person';
          }) || {};

          return inPersonJobPreviewData.pricing || {};
        }
        return {};
      },
      // invoice final pricing
      getPricingFinalData(state) { return state.pricingFinalData || {}; },
      invoiceFinalData(state, getters) { return getters.getPricingFinalData.invoice || {}; },
      // other
      travelFromAddress(state, getters) {
        const address = getters.travelCostsPricing.travelFromAddress || {};
        const {line_1: line1, line_2: line2, city, postcode, country} = address;
        return line1 && postcode && city && country
          ? ''.concat(`${line1}, `, (line2 ? `${line2}, ` : ''), `${postcode} `, `${city}, `, country)
          : '';
      }
    },
    actions: {
      getInterInvoicePreview({rootGetters}, {jobId, seriesJobs = [], params = {}}) {
        return new Promise((resolve, reject) => {
          fetchData.getInterInvoicePreview({jobId, params})
            .then((data = {}) => {
              const jobInfo = seriesJobs.find((job = {}) => {
                return job.id == jobId;
              }) || {
                id: jobId,
                sessionType: rootGetters['OneAssignmentStore/sessionType']
              };
              // extend data if job is seriesJob
              resolve({...data, ...jobInfo});
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      getInterInvoicePreviewDataPromises({dispatch, rootGetters}, {jobId, params}) {
        let promisesArr = [];
        const seriesJobs = rootGetters['OneAssignmentStore/seriesJobsForSupplier'] || [];
        const jobs = rootGetters['OneAssignmentStore/isSameSupplierSeriesJob'] ? seriesJobs : [{id: jobId}];
        jobs.forEach((job) => {
          promisesArr = [...promisesArr, dispatch('getInterInvoicePreview', {
            jobId: job.id,
            seriesJobs,
            params
          })];
        });
        return Promise.all(promisesArr);
      },
      getInterInvoicePreviewData({commit, dispatch}, {jobId, params}) {
        return new Promise((resolve, reject) => {
          dispatch('getInterInvoicePreviewDataPromises', {jobId, params})
            .then((data) => {
              commit('setPreviewData', data);
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      getInterModalInvoicePreviewData({commit, dispatch}, {params, jobId}) {
        return new Promise((resolve, reject) => {
          dispatch('getInterInvoicePreviewDataPromises', {jobId, params})
            .then((data = []) => {
              commit('setModalPreviewData', data);
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      getBuyerInvoicePreview({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getBuyerInvoicePreview(params).then((data) => {
            commit('setPricingData', data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getFinalInvoice({commit}, {jobId, jobStatus}) {
        return new Promise((resolve, reject) => {
          fetchData.getFinalInvoice(jobId).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            // ToDo: BE should generate confirmed feedback and final invoice and then change job status to locked. Delete 404 after
            if (data.code == 404) {
              if (jobStatus === 'finished') {
                commit('setInvoiceGenerationInProgress');
                resolve();
                return;
              }
              if (jobStatus === 'cancelled') {
                commit('setFinalPricingData', '');
                resolve();
                return;
              }
            }
            commit('setFinalPricingData', data);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      }
    },
    mutations: {
      setError(state, {fieldName, errorText}) {
        state.errors[fieldName] = errorText;
      },
      removeOneError(state, fieldName) {
        state.errors[fieldName] = '';
      },
      removeErrors(state) {
        for (const key of Object.keys(state.errors)) {
          state.errors[key] = '';
        }
      },
      setSeriesJobConfirmation(state, value) {
        state.seriesJobConfirmation = value;
      },
      setInvoiceGenerationInProgress(state) {
        state.invoiceGenerationInProgress = true;
      },
      setTravelType(state, value) {
        state.travelType = value || '';
      },
      setTravelDuration(state, data) {
        state.travelDuration = data;
      },
      setTravelAllowance(state, data) {
        state.travelAllowance = data;
      },
      setPricingData(state, data) {
        state.pricingData = data;
      },
      setPreviewData(state, data) {
        state.previewData = data;
      },
      setModalPreviewData(state, data) {
        state.modalPreviewData = data;
      },
      setFinalPricingData(state, data) {
        state.pricingFinalData = data;
      },
      setPricingModalData(state, data) {
        state.pricingModalData = data;
      },
      clearModalData(state) {
        state.pricingModalData = '';
      },
      clearPublicTransportData(state) {
        state.travelDuration = '';
        state.travelAllowance = '';
      },
      clearFlowData(state) {
        state.seriesJobConfirmation = false;
        state.travelType = '';
        state.previewData = [];
        state.modalPreviewData = [];
        state.pricingData = '';
        state.pricingModalData = '';
        state.pricingFinalData = '';
        state.travelDuration = '';
        state.travelAllowance = '';
        state.invoiceGenerationInProgress = '';
      }
    }
  };
};
