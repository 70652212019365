<template>
  <div class="inter-blocked-by">
    <p class="inter-blocked-by__subtitle">{{ subtitleText }}</p>
    <table>
      <tr>
        <th v-for="(column, idx) in columns"
            :key="idx">{{ column }}</th>
      </tr>
      <tr v-for="customer in customers"
          :key="customer.id">
        <td class="inter-blocked-by__name">{{ customer.name }}</td>
        <td class="inter-blocked-by__date">{{ $moment(customer.blockedAt).format('DD.MM.YYYY @ HH:mm') }}</td>
        <td class="inter-blocked-by__reason">{{ customer.reason }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'interpreter-blocked-by-list',
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      columns() {
        return [
          this.$gettext('Blocked by'),
          this.$gettext('Date/time'),
          this.$gettext('Reason given')
        ];
      },
      customers() {
        return this.data.customers;
      },
      fullName() { return this.data.fullName; },
      subtitleText() {
        const template = this.$gettext('%{fullName} was blocked by these customers:');
        return this.$gettextInterpolate(template, {fullName: this.fullName});
      },
      isMobileView() { return this.$store.state.GlobalStore.clientWidth < 768; },
      setMobileTable() {
        // if (window.innerWidth > 600) return false;
        const tableEl = document.querySelector('table');
        const thEls = tableEl.querySelectorAll('th');
        const tdLabels = Array.from(thEls).map((el) => el.innerText);
        tableEl.querySelectorAll('tr').forEach((tr) => {
          Array.from(tr.children).forEach(
            (td, ndx) => td.setAttribute('label', tdLabels[ndx])
          );
        });
      }
    },
    watch: {
      // set mobile Table on Mobile Devices
      isMobileView(newValue) {
        if (newValue) this.setMobileTable();
      }
    }
  };
</script>

<style scoped>
.inter-blocked-by {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 30px;
}

.inter-blocked-by__subtitle {
  padding: 10px 0;
  opacity: 0.7;
}

.inter-blocked-by__name,
.inter-blocked-by__date {
  width: 25%;
}

table {
  width: 100%;
}

table,
th,
td {
  border-collapse: collapse;
  border-bottom: 1px solid #e1dede;
  text-align: left;
}

td {
  padding: 10px 5px;
  color: #636161;
}

th {
  vertical-align: bottom;
  padding: 3px 5px;
}

@media (max-width: 767px) {
  .inter-blocked-by {
    padding: 10px;
  }

  .inter-blocked-by__name,
  .inter-blocked-by__date {
    width: 100%;
  }

  table th {
    display: none;
  }

  table tr {
    margin-bottom: 8px;
    border-bottom: 1px solid #ddd;
  }

  table td {
    display: flex;
    border-bottom: none;
  }

  table td::before {
    content: attr(label);
    width: 120px;
    min-width: 120px;
    color: #000;
    font-weight: bold;
  }
}
</style>
