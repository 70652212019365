import helpers from '@/helpers';

export default () => {
  return {
    namespaced: true,
    state: {
      line1: '',
      line2: '',
      county: '',
      postcode: '',
      city: '',
      country: '',
      longitude: '',
      latitude: '',
      errors: {
        address: {name: 'addressError', errors: []},
        line1: {name: 'line1Error', errors: []},
        line2: {name: 'line2Error', errors: []},
        county: {name: 'countyError', errors: []},
        postcode: {name: 'postcodeError', errors: []},
        city: {name: 'cityError', errors: []},
        country: {name: 'countryError', errors: []}
      }
    },
    getters: {
      fullAddress(state) {
        return helpers.getFullAddress(state);
      }
    },
    actions: {
      getGeometryLocation({commit}, address) {
        return new Promise((resolve, reject) => {
          if (!window || !window.google || !window.google.maps) {
            reject();
          }
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({address}, (results, status) => {
            if (status !== 'OK' || !results.length) {
              reject();
              return;
            }
            const {lat, lng} = results[0].geometry.location;
            commit('setLongitude', lng());
            commit('setLatitude', lat());
            resolve();
          });
        });
      },
      setError({state, commit}, {fieldName, errorText}) {
        commit('setError', {fieldName, errorText});
        commit('ErrorsStore/setError', {name: state.errors[fieldName].name, errors: state.errors[fieldName].errors}, {root: true});
      },
      removeErrors({commit}) {
        commit('removeErrors');
      }
    },
    mutations: {
      setError(state, {fieldName, errorText}) {
        state.errors[fieldName].errors = [...state.errors[fieldName].errors, errorText];
      },
      removeErrors(state) {
        for (const key of Object.keys(state.errors)) {
          state.errors[key].errors = [];
        }
      },
      setAddressInfo(state, data) {
        state.line1 = data.line1 || data.line_1 || data.addressLine1 || '';
        state.line2 = data.line2 || data.line_2 || data.addressLine2 || '';
        state.county = data.county || '';
        state.city = data.city || '';
        state.postcode = data.postcode || '';
        state.country = data.country || '';
        state.longitude = data.longitude || '';
        state.latitude = data.latitude || '';
      },
      setAddress(state, value) {
        state.address = value;
      },
      setLine1(state, value) {
        state.line1 = value;
      },
      setLine2(state, value) {
        state.line2 = value;
      },
      setCounty(state, value) {
        state.county = value;
      },
      setPostcode(state, value) {
        state.postcode = value;
      },
      setCity(state, value) {
        state.city = value;
      },
      setCountry(state, value) {
        state.country = value;
      },
      setLongitude(state, value) {
        state.longitude = value;
      },
      setLatitude(state, value) {
        state.latitude = value;
      },
      clearStore(state) {
        state.line1 = '';
        state.line2 = '';
        state.county = '';
        state.postcode = '';
        state.city = '';
        state.country = '';
        state.longitude = '';
        state.latitude = '';
      }
    }
  };
};
