<script>
  import {mapActions} from 'vuex';

  export default {
    methods: {
      ...mapActions('TTPayoutStore', [
        'setError',
        'removeErrors'
      ]),
      validatePersonalInfo() {
        let isValidForm = true;

        if (!this.firstName.trim()) {
          this.setError({
            fieldName: 'firstName',
            errorText: this.$gettext('First name is required.')
          });
          isValidForm = false;
        }
        if (!this.lastName.trim()) {
          this.setError({
            fieldName: 'lastName',
            errorText: this.$gettext('Last name is required.')
          });
          isValidForm = false;
        }
        return isValidForm;
      },
      validateBankAccountNumber() {
        let isValidForm = true;

        if (!this.bankAccountNumber.trim()) {
          this.setError({
            fieldName: 'bankAccountNumber',
            errorText: this.$gettext('Bank account number is required.')
          });
          isValidForm = false;
        } else if (this.bankAccountNumber.length !== 11) {
          this.setError({
            fieldName: 'bankAccountNumber',
            errorText: this.$gettext('Bank account number should contain 11 digits.')
          });
          isValidForm = false;
        }
        return isValidForm;
      },
      validateFreelanceNumber() {
        let isValidForm = true;

        if (this.freelanceType == 'INDIVIDUAL') {
          if (!this.personalNumber.trim()) {
            this.setError({
              fieldName: 'personalNumber',
              errorText: this.$gettext('Personal number is required.')
            });
            isValidForm = false;
          } else if (this.personalNumber.length !== 11) {
            this.setError({
              fieldName: 'personalNumber',
              errorText: this.$gettext('Personal number should contain 11 digits.')
            });
            isValidForm = false;
          }
        } else if (!this.validateOrganizationNumber()) {
          isValidForm = false;
        }
        return isValidForm;
      },
      validateOrganizationNumber() {
        let isValidForm = true;

        if (!this.organizationNumber.trim()) {
          this.setError({
            fieldName: 'organizationNumber',
            errorText: this.$gettext('Organization number is required.')
          });
          isValidForm = false;
        } else if (this.organizationNumber.length !== 9) {
          this.setError({
            fieldName: 'organizationNumber',
            errorText: this.$gettext('Organization number should contain 9 digits.')
          });
          isValidForm = false;
        }

        return isValidForm;
      },
      validateAddress() {
        let isValidForm = true;

        if (!this.line1.trim()) {
          this.setError({
            fieldName: 'line1',
            errorText: this.$gettext('Address line 1 can\'t be blank.')
          });
          isValidForm = false;
        }
        if (!this.postcode.trim()) {
          this.setError({
            fieldName: 'postcode',
            errorText: this.$gettext('Postcode can\'t be blank.')
          });
          isValidForm = false;
        }
        if (!this.city.trim()) {
          this.setError({
            fieldName: 'city',
            errorText: this.$gettext('City can\'t be blank.')
          });
          isValidForm = false;
        }
        return isValidForm;
      }
    }
  };
</script>
