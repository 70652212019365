<template>
  <div class="language-pair is-progress-bar"
       :class="{'in-progress': progressActive, 'pointer': !noActionOnClick}"
       @click="onCellClick">
    <div class="language-pair__first-row">
      <p ref="langFromNameCell"
         class="language-pair__from-lang">{{ fromLang }}</p>
      <p v-if="showProcessingIcons"
         class="language-pair__processing-icon"
         :class="{'language-pair__processing--activated': alternativeLanguageActivated}"
         @mouseleave="closeTooltip"
         @mouseover="openTooltip"
         @click="openTooltip"></p>
    </div>
    <p class="language-pair__to-lang">{{ toLangProcessed }}</p>
  </div>
</template>

<script>
  import helpers from '@/helpers';
  import ManagerJobEditInfoMixin from '@/mixins/ManagerJobEditInfoMixin';

  export default {
    mixins: [ManagerJobEditInfoMixin],
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      languageToId() { return this.propData?.langToId; },
      languageFromId() { return this.propData?.langFromId; },
      alternativeLangToId() { return this.propData?.alternativeLangToId; },
      fromLang() { return this.$getLangName(this.languageFromId); },
      toLang() { return this.$getLangName(this.languageToId); },
      toLangProcessed() { return this.alternativeLanguageActivated ? this.alternativeLang : this.toLang; },
      alternativeLang() { return this.$getLangName(this.alternativeLangToId); },
      alternativeLanguageProcessing() { return this.propData?.alternativeLanguageProcessing; },
      alternativeLanguageActivated() { return this.propData?.alternativeLanguageActivated; },
      showProcessingIcons() {
        return this.alternativeLanguageProcessing && this.alternativeLangToId;
      },
      toolTipText() {
        return `
            <div>
             <h4>${this.alternativeLanguageActivated ? this.toLang : this.alternativeLang}</h4>
             <p>${this.alternativeLanguageActivated ? this.$gettext('Original') : this.$gettext('Alternative')} ${this.$gettext('language')}</p>
           </div>
        `;
      },
      computedStyle() {
        const {top, left} = helpers.getElementPosition(this.$refs.langFromNameCell);
        return {
          position: 'absolute',
          top: (top) + 'px',
          left: (left + 20) + 'px'
        };
      },
      noActionOnClick() { return !!this.propData?.noActionOnClick; },
    },
    methods: {
      openTooltip() {
        this.$store.commit('TooltipRootStore/setTooltip', {
          data: {
            itemText: this.toolTipText,
            relativePosition: 'top-center'
          },
          absolutePosition: this.computedStyle
        });
      },
      closeTooltip() {
        this.$store.dispatch('TooltipRootStore/closeTooltip');
      },
      onCellClick() {
        if (!this.noActionOnClick) this.openEditAssignmentInformation();
      }
    }
  };
</script>
<style scoped>
.language-pair {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.language-pair__first-row {
  display: inline-flex;
}

.language-pair__to-lang {
  overflow: hidden;
  max-width: 80%;
  text-overflow: ellipsis;
}

.language-pair__processing-icon {
  width: 15px;
  height: 15px;
  margin-top: 5px;
  margin-left: 5px;
  background-image: url(~@assets/imgs/salita/job_grid/time_icon_orange.svg);
  background-repeat: no-repeat;
  line-height: 15px;
  cursor: pointer;
}

.language-pair__processing--activated {
  background-image: url(~@assets/imgs/salita/job_grid/reverted_back_icon_green.svg);
}

.language-pair__from-lang {
  color: #555;
}
</style>
