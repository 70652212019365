import BookingHelper from '@/store/booking_stores/BookingHelper';
import helpers from '../../../helpers';

export default ({$moment: moment} = {}) => {
  return {
    namespaced: true,
    state: {
      multipleDateTimesSelect: false,
      multipleDateTimesOneSupplierSelectState: false,
      dateTimes: [],
      errors: {
        dateTimesOverlapping: {name: 'dateTimesOverlappingTimeValidation', errors: []}
      }
    },
    actions: {
      changeFinishTime({state, commit}, index) {
        const [startHours, startMinutes] = moment(state.dateTimes[index].startTime).format('HH:mm').split(':');
        const assignmentStartTime = moment()
          .hours(startHours)
          .minutes(startMinutes)
          .seconds(0)
          .milliseconds(0);
        const [finishHours, finishMinutes] = moment(state.dateTimes[index].finishTime).format('HH:mm').split(':');
        const assignmentFinishTime = moment()
          .hours(finishHours)
          .minutes(finishMinutes)
          .seconds(0)
          .milliseconds(0);
        const diff = assignmentStartTime.diff(assignmentFinishTime);
        const newFinishDate = diff >= 0
          ? moment(state.dateTimes[index].startTime).add(1, 'day')
          : moment(state.dateTimes[index].startTime);
        const expectedFinishTime = assignmentFinishTime.format('HH:mm');
        const expectedFinishDate = moment(newFinishDate).format('YYYY-MM-DD');

        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/MultipleDateTimeStore/setAssignmentFinishDate',
          payload: {
            index,
            value: expectedFinishDate
          }
        }, {root: true});
        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/MultipleDateTimeStore/setFinishTime',
          payload: {
            index,
            value: expectedFinishTime
          }
        }, {root: true});
      },
      setStartTime({state, rootGetters, commit}, {index, value}) {
        commit('setStartTime', {index, value});

        const newVal = state.dateTimes[index].startTime;
        const duration = rootGetters['UserInfoStore/defaultJobDuration'];
        const expectedFinishTime = moment(newVal).add(duration, 'minutes').format('HH:mm');
        const expectedFinishDate = moment(newVal).add(duration, 'minutes').format('YYYY-MM-DD');

        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/MultipleDateTimeStore/setAssignmentFinishDate',
          payload: {
            index,
            value: expectedFinishDate
          }
        }, {root: true});
        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/MultipleDateTimeStore/setFinishTime',
          payload: {
            index,
            value: expectedFinishTime
          }
        }, {root: true});
        commit('resetDateTimesOverlappingError');
      },
      setFinishTime({dispatch, commit}, {index, value}) {
        commit('setFinishTime', {index, value});
        dispatch('changeFinishTime', index);
        commit('resetDateTimesOverlappingError');
      },
      preselectDateTimes({state, dispatch}) {
        state.dateTimes = [];
        dispatch('addDateTime');
      },
      addDateTime({state, commit, rootState, rootGetters}) {
        let length = state.dateTimes.length;
        const duration = rootGetters['UserInfoStore/defaultJobDuration'];
        if (length >= 1) {
          let startDate;
          length >= 2
            ? startDate = state.dateTimes[length - 2].startTime
            : startDate = rootState.PostingStore.DateTimeStore.startTime;
          const seriesStartDate = moment(startDate);
          const lastSeriesJobStartDate = moment(state.dateTimes[length - 1].startTime);
          const seriesInterval = lastSeriesJobStartDate.diff(seriesStartDate, 'days');
          const newSeriesDate = lastSeriesJobStartDate.add(seriesInterval, 'days');
          length = state.dateTimes.push({startTime: newSeriesDate, finishTime: ''});
        } else {
          length = state.dateTimes.push({startTime: '', finishTime: ''});
        }
        const index = length - 1;

        state.errors[`startTime${index}`] = {name: `startTimeValidation${index}`, errors: []};
        state.errors[`finishTime${index}`] = {name: `finishTimeValidation${index}`, errors: []};
        commit('setPreselectedTime', {index, duration});
      },
      deleteDateTime({commit}, index) {
        commit('deleteDateTime', index);
        commit('resetDateTimesOverlappingError');
      },
      setDate({commit, dispatch}, {index, value}) {
        commit('setDate', {index, value});
        dispatch('changeFinishTime', index);
        commit('resetDateTimesOverlappingError');
      },
      changeMultipleDateTimesSelectState({state, commit, dispatch}, value) {
        commit('resetDateTimesOverlappingError');
        commit('setMultipleDateTimesSelectState', value);
        value
         ? dispatch('preselectDateTimes', value)
         : state.dateTimes = [];
      },
      changeMultipleDateTimesOneSupplierSelectState({commit}, value) {
        const processingValue = value ? 'same_int' : 'different_int';

        commit('resetDateTimesOverlappingError');
        commit('setMultipleDateTimesOneSupplierSelectState', value);
        commit('PostingStore/ProcessRequirementStore/setSeriesJobProcessing', processingValue, {root: true});
      }
    },
    mutations: {
      ...BookingHelper().mutations,
      setMultipleDateTimesSelectState(state, value) {
        state.multipleDateTimesSelect = value;
      },
      setMultipleDateTimesOneSupplierSelectState(state, value) {
        state.multipleDateTimesOneSupplierSelectState = value;
      },
      resetDateTimesOverlappingError(state) {
        state.errors.dateTimesOverlapping.errors = [];
      },
      // only date change
      setDate(state, {index, value}) {
        state.dateTimes[index].startTime = helpers.dateTime.setDate(moment, {
          currentTime: state.dateTimes[index].startTime,
          value
        });
        state.dateTimes[index].finishTime = helpers.dateTime.setDate(moment, {
          currentTime: state.dateTimes[index].finishTime,
          value
        });
      },
      setAssignmentFinishDate(state, {index, value}) {
        const finishTime = state.dateTimes[index].finishTime;
        state.dateTimes[index].finishTime = helpers.dateTime.setDate(moment, {
          currentTime: finishTime,
          value
        });
      },
      // only start time change
      setStartTime(state, {index, value}) {
        state.dateTimes[index].startTime = helpers.dateTime.setTime(moment, {
          currentTime: state.dateTimes[index].startTime,
          value
        });
      },
      // only finish time change
      setFinishTime(state, {index, value}) {
        state.dateTimes[index].finishTime = helpers.dateTime.setTime(moment, {
          currentTime: state.dateTimes[index].finishTime,
          value
        });
      },
      // only for initial time preselection
      setPreselectedTime(state, {index, duration}) {
        const [hours, minutes] = helpers.dateTime.convertCurrentTime(moment).split(':');
        let startTime = state.dateTimes[index].startTime;

        if (startTime == null || startTime == '') {
          startTime = moment()
            .hours(+hours)
            .minutes(+minutes)
            .seconds(0)
            .milliseconds(0);
        } else {
          startTime = moment(startTime)
            .hours(+hours)
            .minutes(+minutes)
            .seconds(0)
            .milliseconds(0);
        }
        state.dateTimes[index].startTime = startTime;

        // take startTime as the starting point of the time calculation finish time
        state.dateTimes[index].finishTime = moment(startTime)
            .hours(+hours)
            .minutes(+minutes)
            .seconds(0)
            .milliseconds(0)
            .add(duration, 'minutes');
      },
      deleteDateTime(state, index) {
        state.dateTimes.splice(index, 1);
        state.errors[`startTime${index}`] = {name: `startTimeValidation${index}`, errors: []};
      },
      clearStore(state) {
        state.multipleDateTimesSelect = false;
        state.multipleDateTimesOneSupplierSelectState = false;
        state.dateTimes = [];
        state.errors = {
          dateTimesOverlapping: {name: 'dateTimesOverlappingTimeValidation', errors: []}
        };
      }
    }
  };
};
