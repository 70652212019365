(<template>
  <div :class="{'is-fai-block': isFai, 'is-chat-list': isChatList, 'is-chat': isChat}"
       class="inter-tile">
    <div class="inter-tile__image-info-cont">
      <div :class="{'is-favourit favourite-icon': interpreter.favourite,
                    'is-blocked': interpreter.blocked,
                    'is-attested-by-police attested-by-police-icon': interpreter.showPoliceLogo,
                    'is-attested-by-police attested-by-police-temporary-icon': interpreter.showTemporaryPoliceLogo}"
           class="inter-tile__avatar-cont">
        <button class="inter-tile__avatar"
                @click="goToProfile">
          <tt-avatar :avatar="interpreter.avatar || interpreter.avatarUrl"
                     class="inter-tile__avatar"
                     @click="goToProfile" />
        </button>
      </div>
      <span v-if="interpreter.suitable !== undefined"
            :class="{'inter-tile__status--suitable': interpreter.suitable}"
            class="inter-tile__status">{{ suitabilityText }}</span>
    </div>
    <div class="inter-tile__text-info-cont">
      <button v-if="interpreter.firstName"
              class="inter-tile__name"
              @click="goToProfile"
              v-html="computedName"></button>
      <template v-if="$isGodmode()">
        <a v-if="interpreter.email"
           :href="`mailto:${interpreter.email}`"
           class="inter-tile__text">{{ interpreter.email }}</a>
        <a v-if="interpreter.phoneNumber"
           :href="`tel:${interpreter.phoneNumber}`"
           class="inter-tile__text js-copy-to-clipboard">{{ interpreter.phoneNumber }}</a>
      </template>
      <p v-for="qualification in qualifications"
         :key="qualification.id"
         :class="{'inter-tile__text--light': useLightColorForQualification(qualification)}"
         class="inter-tile__text">{{ qualification.name }}</p>
      <p v-if="interpreter.city"
         class="inter-tile__text">{{ interpreter.city }}</p>
      <p v-if="interpreter.distance && inPersonJob"
         class="inter-tile__text">{{ computedDistance }}</p>
    </div>
  </div>
</template>)

<script>
  import SuitableSupplierPrototype from '@/prototypes/SuitableSupplierPrototype';

  export default {
    extends: SuitableSupplierPrototype,
    props: {
      interpreter: {
        type: Object,
        default() {
          return {};
        }
      },
      isFai: Boolean,
      isChatList: Boolean,
      isChat: Boolean
    },
    computed: {
      inPersonJob() {
        const assignmentType = this.assignmentRequirements.sessionType;
        return assignmentType && (assignmentType === 'in_person' || assignmentType === 'videoroom');
      },
      computedDistance() {
        return this.$gettextInterpolate(this.$gettext('Distance: %{distance}'), {distance: this.distance});
      }
    }
  };
</script>

<style scoped>
  /* common styles */
  .inter-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .inter-tile__avatar-cont {
    position: relative;
    width: 35px;
    height: 35px;
    margin-bottom: 5px;
  }

  .inter-tile__image-info-cont {
    text-align: center;
  }

  .inter-tile__text-info-cont {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .inter-tile__avatar {
    width: 100%;
    height: 100%;
  }

  .inter-tile__name {
    display: block;
    width: 100%;
    max-width: 90px;
    margin: 5px 0;
    padding: 2px;
    font-size: 12px;
    text-align: left;
    word-break: break-word;
  }

  .inter-tile__name:focus {
    outline: var(--default-focus-outline);
  }

  .inter-tile__avatar-cont.is-attested-by-police::after {
    right: -9px;
    width: 20px;
    height: 20px;
  }

  .inter-tile__avatar-cont.is-favourit::before {
    left: -4px;
    width: 15px;
    height: 15px;
  }

  .inter-tile__avatar-cont.is-blocked::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-image: url(~@assets/imgs/undefined_imgs/block_inter_icon.svg);
    background-position: 50% 50%;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .inter-tile__text {
    display: block;
    font-size: 10px;
  }

  .inter-tile__text--light {
    color: #a7abbd;
  }

  .inter-tile__status {
    padding: 5px;
    border-radius: 10px;
    background-color: #f8e5e7;
    color: #9a2f51;
    font-size: 9px;
    text-align: center;
    white-space: nowrap;
    word-break: keep-all;
  }

  .inter-tile__status--suitable {
    background-color: #edf2e7;
    color: #81a369;
  }

  /* specification for chat and chat list block */
  .inter-tile.is-chat-list {
    display: block;
    width: 45px;
    height: 45px;
    padding: 0;
  }

  .inter-tile.is-chat {
    display: block;
    width: 30px;
    height: 30px;
    padding: 0;
  }

  .is-chat-list .inter-tile__avatar-cont {
    width: 45px;
    height: 45px;
  }

  .is-chat .inter-tile__avatar-cont {
    width: 30px;
    height: 30px;
  }

  .is-chat .inter-tile__avatar-cont.is-favourit::before {
    display: none;
  }

  /* specification for FAI block */
  .inter-tile.is-fai-block {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    padding: 10px 10px 0;
    word-break: break-all;
  }

  .is-fai-block .inter-tile__avatar-cont {
    width: 65px;
    height: 65px;
    margin-right: 5px;
  }

  .is-fai-block .inter-tile__image-info-cont {
    margin-right: 10px;
  }

  .is-fai-block .inter-tile__avatar-cont.is-attested-by-police::after {
    right: 0;
    width: 30px;
    height: 30px;
  }

  .is-fai-block .inter-tile__name {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    min-height: 33px;
    margin-bottom: auto;
    font-weight: bold;
    text-decoration: none;
  }

  .inter-tile__name span {
    display: block;
  }

  .is-fai-block .inter-tile__avatar-cont.is-favourit::before {
    top: 5px;
    left: 5px;
    width: 14px;
    height: 14px;
  }
</style>
