export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      stepsData: {
        name: {
          firstName: '',
          lastName: '',
          avatarFile: '',
          avatarLink: '',
          avatarRotation: 0
        },
        phone: {
          phoneCode: '+47',
          phoneNumber: '',
          code: ''
        },
        skill: {
          langFrom: '',
          langTo: '',
          qualification: '',
          price: '350'
        },
        address: {country: ''},
        gender: {sex: ''},
        enterprise: {
          enterpriseId: '',
          orgNumber: '',
          orgName: '',
          logoFile: '',
          logoLink: '',
          logoRotation: 0,
          terms: false
        },
        payment: {
          company: '',
          orgNumber: ''
        }
      },
      errors: {
        firstName: {errors: [], name: 'firstNameError'},
        lastName: {errors: [], name: 'lastNameError'},
        avatar: {errors: [], name: 'avatarError'},
        enterprise: {errors: [], name: 'enterpriseError'},
        orgNumber: {errors: [], name: 'orgNumberError'},
        orgName: {errors: [], name: 'orgNameError'},
        country: {errors: [], name: 'countryError'},
        langFrom: {errors: [], name: 'langFromError'},
        langTo: {errors: [], name: 'langToError'},
        qualification: {errors: [], name: 'qualificationError'},
        price: {errors: [], name: 'priceError'},
        phoneNumber: {errors: [], name: 'phoneNumberError'},
        code: {errors: [], name: 'codeError'},
        sex: {errors: [], name: 'sexError'},
        terms: {errors: [], name: 'termsError'},
        paymentOrgNumber: {errors: [], name: 'paymentOrgNumberError'}
      }
    },
    getters: {},
    actions: {
      setError({state, commit}, {fieldName, errorText}) {
        commit('setError', {fieldName, errorText});
        commit('ErrorsStore/setError', {name: state.errors[fieldName].name, errors: state.errors[fieldName].errors}, {root: true});
      },
      removeErrors({commit}) {
        commit('removeErrors');
        commit('ErrorsStore/removeAllErrors', null, {root: true});
      },
      setRole({commit, rootGetters}, role) {
        return new Promise((resolve, reject) => {
          fetchData.sendRole(role, rootGetters['UserInfoStore/userUid']).then(() => {
            commit('UserInfoStore/setUserInfo', {userInfoObj: {role: role}}, {root: true});
            resolve();
          }).catch((error) => {
            if (error?.status == 409) {
              resolve({code: 409});
              return;
            }
            reject(error);
          });
        });
      },
      setPaymentInvoice(context, data) {
        return new Promise((resolve, reject) => {
          fetchData.sendNewEnterprisePaymentInvoice(data).then((response) => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        });
      },
    },
    mutations: {
      setError(state, {fieldName, errorText}) {
        state.errors[fieldName].errors = [...state.errors[fieldName].errors, errorText];
      },
      removeOneError(state, fieldName) {
        state.errors[fieldName].errors = [];
      },
      removeErrors(state) {
        for (const key of Object.keys(state.errors)) {
          state.errors[key].errors = [];
        }
      },
      setFirstName(state, value) { state.stepsData.name.firstName = value; },
      setLastName(state, value) { state.stepsData.name.lastName = value; },
      setAvatarFile(state, value) { state.stepsData.name.avatarFile = value; },
      setAvatarLink(state, value) { state.stepsData.name.avatarLink = value; },
      setAvatarRotation(state, value) { state.stepsData.name.avatarRotation = value; },
      setLangFrom(state, value) { state.stepsData.skill.langFrom = value; },
      setLangTo(state, value) { state.stepsData.skill.langTo = value; },
      setQualification(state, value) { state.stepsData.skill.qualification = value; },
      setPrice(state, value) { state.stepsData.skill.price = value; },
      setPhoneCode(state, value) { state.stepsData.phone.phoneCode = value; },
      setPhoneNumber(state, value) { state.stepsData.phone.phoneNumber = value; },
      setCode(state, value) { state.stepsData.phone.code = value; },
      setSex(state, value) { state.stepsData.gender.sex = value; },
      setTerms(state, value) { state.stepsData.enterprise.terms = value; },
      setEnterprise(state, value) { state.stepsData.enterprise.enterpriseId = value; },
      setOrgNumber(state, value) { state.stepsData.enterprise.orgNumber = value; },
      setOrgName(state, value) { state.stepsData.enterprise.orgName = value; },
      setLogoFile(state, value) { state.stepsData.enterprise.logoFile = value; },
      setLogoLink(state, value) { state.stepsData.enterprise.logoLink = value; },
      setLogoRotation(state, value) { state.stepsData.enterprise.logoRotation = value; },
      // address step
      setAddressCountry(state, value) { state.stepsData.address.country = value; },
      // payment step
      setPaymentCompany(state, value) { state.stepsData.payment.company = value; },
      setPaymentOrgNumber(state, value) { state.stepsData.payment.orgNumber = value; }
    }
  };
};
