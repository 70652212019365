(<template>
  <div class="post-form__section post-form__section--underline manual-fees">
    <p class="post-form__section-title manual-fees__title">{{ $gettext('Which manual fees should be added to this assignment?') }}</p>
    <div class="manual-fees__checkboxes-wrapper">
      <sk-checkbox v-for="fee in feesList"
                   :key="fee.key"
                   :preselected-value="manualFees[fee.key]"
                   :item-text="fee.text"
                   class="post-form__field manual-fees__checkbox"
                   @checkboxvaluechanged="fee.method" />
    </div>
  </div>
</template>)

<script>
  import {mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';

  export default {
    computed: {
      ...mapState('PostingStore/ManualFeesStore', {
        manualFees: ({manualFees}) => manualFees
      }),
      feesList() {
        return [{
          key: 'booking',
          text: helpers.getJobInfo.jobManualFee(this, 'booking'),
          method: this.catchManualFee('booking')
        }, {
          key: 'editing',
          text: helpers.getJobInfo.jobManualFee(this, 'editing'),
          method: this.catchManualFee('editing')
        }, {
          key: 'travelBooking',
          text: helpers.getJobInfo.jobManualFee(this, 'travelBooking'),
          method: this.catchManualFee('travelBooking')
        }];
      }
    },
    methods: {
      ...mapMutations('PostingStore/ManualFeesStore', ['setManualFee']),
      catchManualFee(key) {
        return (value) => {
          return this.setManualFee({key, value});
        };
      }
    }
  };
</script>

<style scoped>
  .manual-fees__checkboxes-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .manual-fees__checkbox {
    width: auto;
  }
</style>
