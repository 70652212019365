// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/check_icon_filled.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/file_types/document.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@assets/imgs/file_types/audio.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@assets/imgs/file_types/image.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "\n.attached-file[data-v-5d9cd630] {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 22%;\n  border-radius: 8px;\n  line-height: 20px;\n  text-align: center;\n  cursor: pointer;\n}\n.attached-file[data-v-5d9cd630]:hover {\n  border: 1px solid var(--color-primary);\n}\n.attached-file.is-selected[data-v-5d9cd630] {\n  background-color: var(--salita-25);\n}\n.attached-file.is-selected .attached-file__icon[data-v-5d9cd630] {\n  background-color: var(--col-white);\n}\n.attached-file__tick-icon[data-v-5d9cd630] {\n  position: absolute;\n  top: 9px;\n  right: 9px;\n  width: 16px;\n  height: 16px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center center;\n  background-repeat: no-repeat;\n}\n.attached-file__icon[data-v-5d9cd630] {\n  width: 40px;\n  height: 40px;\n  margin: 16px 54px;\n  border-radius: 8px;\n  background: var(--cool-gray-50);\n  background-position: center;\n  background-size: 20px;\n  background-repeat: no-repeat;\n}\n.attached-file__info[data-v-5d9cd630] {\n  display: inline-grid;\n  margin: 0 16px 16px 16px;\n}\n.attached-file__name[data-v-5d9cd630] {\n  font-weight: 600;\n}\n.attached-file__created-at[data-v-5d9cd630] {\n  color: var(--black-500);\n  font-size: 12px;\n  line-height: 16px;\n}\n.attached-file__icon--document[data-v-5d9cd630] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.attached-file__icon--audio[data-v-5d9cd630] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.attached-file__icon--image[data-v-5d9cd630] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
