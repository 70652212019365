import { render, staticRenderFns } from "./SecretarySection.vue?vue&type=template&id=52464a23&scoped=true&"
import script from "./SecretarySection.vue?vue&type=script&lang=js&"
export * from "./SecretarySection.vue?vue&type=script&lang=js&"
import style0 from "./SecretarySection.vue?vue&type=style&index=0&id=52464a23&prod&lang=css&"
import style1 from "./SecretarySection.vue?vue&type=style&index=1&id=52464a23&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52464a23",
  null
  
)

export default component.exports