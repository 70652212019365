(<template>
  <section class="prof-experience-modal__content-wrapper">
    <section class="prof-experience-form__wrapper">
      <sk-input :description="employerText"
                :preselected-value="currentExperience.employer"
                :margin-bottom="true"
                :placeholder="employerText"
                :validation-name="employerValidationName"
                :autofocus="true"
                @fieldvaluechanged="catchCompany" />
      <div class="prof-experience-form__years-selects-cont">
        <sk-select :default-value="monthDefaultValue"
                   :description="monthFromText"
                   :type="'month'"
                   :preselected-value="currentExperience.monthFrom"
                   :validation-name="monthFromValidationName"
                   class="sk-select__wrapper--margin-right"
                   @csvaluechanged="catchMonthFrom" />
        <sk-select :default-value="yearDefaultValue"
                   :description="yearFromText"
                   :type="'pastYear'"
                   :preselected-value="currentExperience.yearFrom"
                   :validation-name="yearFromValidationName"
                   class="sk-select__wrapper--margin-right prof-experience-form__select--from-year"
                   @csvaluechanged="catchYearFrom" />
        <sk-select :default-value="monthDefaultValue"
                   :description="monthToText"
                   :type="'month'"
                   :preselected-value="currentExperience.monthTo"
                   :validation-name="monthToValidationName"
                   class="sk-select__wrapper--margin-right"
                   @csvaluechanged="catchMonthTo" />
        <sk-select :default-value="yearDefaultValue"
                   :description="yearToText"
                   :type="'pastYear'"
                   :preselected-value="currentExperience.yearTo"
                   :validation-name="yearToValidationName"
                   class="sk-select__wrapper--margin-right"
                   @csvaluechanged="catchYearTo" />
      </div>
      <sk-textarea :description="text3"
                   :preselected-value="currentExperience.assignmentInformation"
                   :margin-bottom="true"
                   :placeholder="text3"
                   :validation-name="assignmentInformationValidationName"
                   class="prof-experience-form__assignment-information"
                   @fieldvaluechanged="catchDescription" />
      <button v-if="currentExperience.id"
              class="sk-btn sk-btn--like-link prof-experience-form__delete-btn"
              @click="deleteExperienceHandler">{{ $gettext('Delete experience') }}</button>
    </section>
    <button class="sk-btn sk-btn--white prof-experience-modal__add-btn"
            @click="addNewExperienceHandler">{{ $gettext("Add a new experience") }}</button>
    <submit-btns :submit-handler="submitHandler"
                 :cancel-handler="closeModal" />
  </section>
</template>)

<script>
  import SubmitBtns from '@/components/profile_components/edit/ModalSubmitBtns';

  export default {
    components: {
      'submit-btns': SubmitBtns
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        currentExperience: {
          id: this.data.data.id || '',
          employer: this.data.data.employer || '',
          assignmentInformation: this.data.data.assignmentInformation || '',
          monthFrom: this.data.data.monthFrom || '',
          monthTo: this.data.data.monthTo || '',
          yearFrom: this.data.data.yearFrom || '',
          yearTo: this.data.data.yearTo || ''
        },
        openNewModal: false,
        employerValidationName: 'experienceCompany',
        assignmentInformationValidationName: 'experienceDescription',
        yearFromValidationName: 'experienceStartYear',
        yearToValidationName: 'experienceEndYear',
        monthFromValidationName: 'experienceStartMonth',
        monthToValidationName: 'experienceEndMonth',
        experienceErrors: [],
        assignmentInformationErrors: [],
        yearFromErrors: [],
        yearToErrors: []
      };
    },
    computed: {
      employerText() { return this.$gettext('Company*'); },
      text3() { return this.$gettext('Description'); },
      yearFromText() { return this.$gettext('From year*'); },
      text5() { return this.$gettext('YYYY'); },
      yearToText() { return this.$gettext('To year*'); },
      monthDefaultValue() { return this.$gettext('MM'); },
      monthFromText() { return this.$gettext('From month*'); },
      monthToText() { return this.$gettext('To month*'); },
      yearDefaultValue() { return this.$gettext('YYYY'); },
      yearText() { return this.$gettext('Year'); },
      text7() { return this.$gettext('YYYY'); },
      isAnyErrors() {
        return this.experienceErrors.length || this.assignmentInformationErrors.length || this.yearFromErrors.length || this.yearToErrors.length;
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      catchCompany(value) {
        this.currentExperience.employer = value;
      },
      catchDescription(value) {
        this.currentExperience.assignmentInformation = value;
      },
      catchYearFrom(value) {
        this.currentExperience.yearFrom = value;
      },
      catchYearTo(value) {
        this.currentExperience.yearTo = value;
      },
      catchMonthFrom(value) {
        this.currentExperience.monthFrom = value;
      },
      catchMonthTo(value) {
        this.currentExperience.monthTo = value;
      },
      closeModal() {
        this.$emit('closemodal');
      },
      stopProgress() {
        this.$emit('stopprogress');
      },
      submitHandler(openNewModal) {
        this.experienceErrors = [];
        this.yearFromErrors = [];
        this.yearToErrors = [];
        this.monthFromErrors = [];
        this.monthToErrors = [];

        if (!this.currentExperience.employer || !this.currentExperience.employer.trim()) {
          this.experienceErrors.push(this.$gettext('Your employer is required.'));
          this.$store.commit('ErrorsStore/setError', {name: this.employerValidationName, errors: this.experienceErrors});
        }
        if (!this.currentExperience.yearTo) {
          this.yearToErrors.push(this.$gettext('End year is required.'));
          this.$store.commit('ErrorsStore/setError', {name: this.yearToValidationName, errors: this.yearToErrors});
        }
        if (!this.currentExperience.yearFrom) {
          this.yearFromErrors.push(this.$gettext('Start year is required.'));
          this.$store.commit('ErrorsStore/setError', {name: this.yearFromValidationName, errors: this.yearFromErrors});
        }
        if (!this.currentExperience.monthTo) {
          this.monthToErrors.push(this.$gettext('End month is required.'));
          this.$store.commit('ErrorsStore/setError', {name: this.monthToValidationName, errors: this.monthToErrors});
        }
        if (!this.currentExperience.monthFrom) {
          this.monthFromErrors.push(this.$gettext('Start month is required.'));
          this.$store.commit('ErrorsStore/setError', {name: this.monthFromValidationName, errors: this.monthFromErrors});
        }
        const fromDate = this.$moment({year: this.currentExperience.yearFrom, month: this.currentExperience.monthFrom});
        const toDate = this.$moment({year: this.currentExperience.yearTo, month: this.currentExperience.monthTo});
        if (toDate.isBefore(fromDate)) {
          this.yearToErrors.push(this.$gettext('End date should be later than start date.'));
          this.$store.commit('ErrorsStore/setError', {name: this.yearToValidationName, errors: this.yearToErrors});
        }

        if (!this.isAnyErrors) {
          const payload = {
            assignment_information: this.currentExperience.assignmentInformation.trim(),
            employer: this.currentExperience.employer.trim(),
            month_from: this.currentExperience.monthFrom,
            month_to: this.currentExperience.monthTo,
            year_from: this.currentExperience.yearFrom,
            year_to: this.currentExperience.yearTo,
          };

          this.$emit('startprogress');
          if (this.currentExperience.id) {
            this.data.context.editExperience(payload, this.currentExperience.id);
          } else {
            this.data.context.addExperience(payload);
          }
          this.data.context.$on('updatefailed', this.stopProgress);
          this.data.context.$on('updatesuccess', () => {
            if (openNewModal === true) {
              this.stopProgress();
              this.currentExperience = {
                id: '',
                employer: '',
                assignmentInformation: '',
                yearFrom: '',
                yearTo: '',
                monthFrom: '',
                monthTo: ''
              };
            } else {
              this.closeModal();
            }
          });
        }
      },
      addNewExperienceHandler() {
        this.submitHandler(true);
      },
      deleteExperienceHandler() {
        this.$emit('startprogress');
        this.$store.dispatch('EditProfileStore/removeExperience', {uid: this.$route.params.id, experienceId: this.currentExperience.id}).then(() => {
          this.closeModal();
        });
      }
    }
  };
</script>

<style scoped>
  .prof-experience-modal__content-wrapper {
    display: block;
    width: 100%;
    padding: 35px;
    padding-top: 10px;
  }

  .prof-experience-modal__add-btn {
    display: inline-block;
    width: auto;
    padding: 0 10px;
  }

  .prof-experience-form__wrapper {
    display: block;
    width: 100%;
    padding-bottom: 20px;
  }

  .prof-experience-form__years-selects-cont {
    display: flex;
    margin: 15px 10px 10px 0;
  }

  .prof-experience-form__select--from-year {
    margin-right: 40px;
  }

  .prof-experience-form__delete-btn {
    margin-top: 20px;
    color: #f04;
    color: var(--color-error);
    font-size: 16px;
    text-decoration: underline;
  }

  .prof-experience-form__assignment-information {
    height: 100px;
    margin-top: 20px;
  }
</style>
