export default {
  openBookingTypes: [
    'police',
    'municipality',
    'health',
    'child-services',
    'court',
    'business',
    'nav',
    'nav-translations',
    'drivers-license-exam',
    'all'
  ],
  get openBookingOrgTypesNb() {
    return this.openBookingTypes.map((type) => this.getTranslatedOpenBookingType(type));
  },
  getOriginalOpenBookingType(type) {
    switch (type) {
      case 'politi':
        return 'police';
      case 'kommune':
        return 'municipality';
      case 'helse':
        return 'health';
      case 'barnevern':
        return 'child-services';
      case 'rettsvesen':
        return 'court';
      case 'næringslivet':
        return 'business';
      case 'teoriprøve':
        return 'drivers-license-exam';
      case 'nav-oversettelse':
        return 'nav-translations';
      case 'alle':
        return 'all';
      default:
        return type;
    }
  },
  getTranslatedOpenBookingType(type) {
    switch (type) {
      case 'police':
        return 'politi';
      case 'municipality':
        return 'kommune';
      case 'health':
        return 'helse';
      case 'all':
        return 'alle';
      case 'child-services':
        return 'barnevern';
      case 'court':
        return 'rettsvesen';
      case 'business':
        return 'næringslivet';
      case 'drivers-license-exam':
        return 'teoriprøve';
      case 'nav-oversettelse':
        return 'nav-translations';
      default:
        return type;
    }
  },
  getFullOpenBookingName(context, type) {
    switch (type) {
      case 'police':
        return context.$gettext('the Police');
      case 'health':
        return context.$gettext('Health Services');
      case 'municipality':
        return context.$gettext('Municipalities');
      case 'child-services':
        return context.$gettext('Child Services');
      case 'court':
        return context.$gettext('Court');
      case 'business':
        return context.$gettext('Business');
      case 'drivers-license-exam':
        return context.$gettext('Drivers license exam');
      case 'all':
        return context.$gettext('everyone');
      case 'nav':
        return context.$gettext('Nav');
      case 'nav-oversettelse':
        return context.$gettext('Nav');
      default:
        return '';
    }
  },
  // TODO: New Norwegian to be added
  prioritizedFromLanguages() {
    return [{
      id: 3,
      name: 'Norwegian (Bokmål)'
    }, {
      id: 212,
      name: 'Norwegian (Nynorsk)'
    }, {
      id: 1,
      name: 'Arabic'
    }, {
      id: 21,
      name: 'Turkish'
    }, {
      id: 12,
      name: 'Polish'
    }, {
      id: 6,
      name: 'Russian'
    }, {
      id: 9,
      name: 'Spanish'
    }, {
      id: 14,
      name: 'French'
    }, {
      id: 13,
      name: 'Persian (Farsi)'
    }, {
      id: 15,
      name: 'Lithuanian'
    }, {
      id: 2,
      name: 'English'
    }, {
      id: 4,
      name: 'German'
    }, {
      id: 43,
      name: 'Ukrainian'
    }, {
      id: 16,
      name: 'Persian (Dari)'
    }, {
      id: 8,
      name: 'Tigrinya'
    }, {
      id: 27,
      name: 'Dutch'
    }, {
      id: 58,
      name: 'Latvian'
    }, {
      id: 41,
      name: 'Finnish'
    }, {
      id: 19,
      name: 'Mandarin (Chinese)'
    }, {
      id: 33,
      name: 'Portuguese'
    }, {
      id: 20,
      name: 'Thai'
    }, {
      id: 48,
      name: 'Pashto'
    }, {
      id: 29,
      name: 'Kurdish (Kurmanji)'
    }, {
      id: 40,
      name: 'Italian'
    }, {
      id: 26,
      name: 'Romanian'
    }, {
      id: 17,
      name: 'Albanian'
    }, {
      id: 24,
      name: 'Urdu'
    },
    ];
  },
  prioritizedToLanguages() {
    return [{
      id: 2,
      name: 'English'
    }, {
      id: 3,
      name: 'Norwegian (Bokmål)'
    }, {
      id: 212,
      name: 'Norwegian (Nynorsk)'
    }, {
      id: 12,
      name: 'Polish'
    }, {
      id: 1,
      name: 'Arabic'
    }, {
      id: 11,
      name: 'Somali'
    }, {
      id: 8,
      name: 'Tigrinya'
    }, {
      id: 15,
      name: 'Lithuanian'
    }, {
      id: 4,
      name: 'German'
    }, {
      id: 6,
      name: 'Russian'
    }, {
      id: 9,
      name: 'Spanish'
    }, {
      id: 13,
      name: 'Persian (Farsi)'
    }, {
      id: 29,
      name: 'Kurdish (Kurmanji)'
    }, {
      id: 16,
      name: 'Persian (Dari)'
    }, {
      id: 21,
      name: 'Turkish'
    }, {
      id: 14,
      name: 'French'
    }, {
      id: 24,
      name: 'Urdu'
    }, {
      id: 20,
      name: 'Thai'
    }, {
      id: 43,
      name: 'Ukrainian'
    }, {
      id: 17,
      name: 'Albanian'
    }, {
      id: 41,
      name: 'Finnish'
    }, {
      id: 27,
      name: 'Dutch'
    }, {
      id: 58,
      name: 'Latvian'
    }, {
      id: 22,
      name: 'Swahili'
    }, {
      id: 48,
      name: 'Pashto'
    }, {
      id: 19,
      name: 'Mandarin (Chinese)'
    }, {
      id: 40,
      name: 'Italian'
    }, {
      id: 26,
      name: 'Romanian'
    },
    ];
  },
};
