<template>
  <section class="social-links">
    <div class="social-links__cont">
      <button class="sk-btn sk-btn--linkedin social-links__link"
              :title="linkedInHover"
              type="button"
              @click="sendAjax('linkedin')"></button>
      <button class="sk-btn sk-btn--facebook social-links__link"
              :title="facebookHover"
              type="button"
              @click="sendAjax('facebook')"></button>
      <button class="sk-btn sk-btn--google social-links__link"
              :title="googleHover"
              type="button"
              @click="sendAjax('google')"></button>
    </div>
  </section>
</template>

<script>
  export default {
    props: {
      createJob: Boolean
    },
    computed: {
      linkedInHover() { return this.$gettext('Login or create an account with your LinkedIn account'); },
      facebookHover() { return this.$gettext('Login or create an account with your Facebook account'); },
      googleHover() { return this.$gettext('Login or create an account with your Google account'); }
    },
    methods: {
      sendAjax(provider) {
        this.$store.dispatch('sendSocialAuth', provider).then((data) => {
          if (this.createJob) {
            // for open direct booking && open assignment
            this.$cookieManager.setCookie('addJobAfterSignIn', true, {path: '/', expires: 2592000});
          }
          window.location = data.redirect_url;
        });
      }
    }
  };
</script>

<style scoped>
  .social-links {
    display: block;
    width: 100%;
  }

  .social-links__cont {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px 0;
  }

  .social-links__link:hover {
    background-color: #f4f5f7;
  }

  .social-links__link:not(:last-of-type) {
    margin-right: 30px;
  }
</style>
