function transformSignaturesArray(signaturesArray) {
  return signaturesArray.map((signature) => {
    signature.name = signature.signableDocument.name;
    return signature;
  }) || [];
}

export default ({$fetcher: fetchData, $moment: moment}) => {
  return {
    namespaced: true,
    state: {
      documentsForSigning: '',
      activeSignatures: '',
      inactiveSignatures: '',
      newSignature: {
        personalNumber: '',
        file: ''
      }
    },
    actions: {
      getDocumentsForSigning({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getDocumentsForSigning().then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setDocumentsForSigning', data.documents);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getSignatures({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getSignatures().then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            if (data.status == 422) {
              resolve(data.data);
              return;
            }
            commit('setSignatures', data.signatures);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      uploadSignature({commit, state}, documentId) {
        const form = new FormData();
        if (state.newSignature.file) {
          form.append('file', state.newSignature.file);
        }
        if (state.newSignature.personalNumber) {
          form.append('personal_number', state.newSignature.personalNumber);
        }
        form.append('signable_document_id', documentId);
        return new Promise((resolve, reject) => {
          fetchData.uploadSignature(form).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            if (state.newSignature.file) {
              commit('pushOneSignature', data.signature);
            }
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getSignature({commit}, id) {
        return new Promise((resolve, reject) => {
          fetchData.getSignature(id).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getDocForSigning({commit}, id) {
        return new Promise((resolve) => {
          fetchData.getDocForSigning(id).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            resolve(data);
          });
        });
      },
      confirmTemporaryPoliceAttestation({rootGetters}) {
        return new Promise((resolve, reject) => {
          fetchData.confirmTemporaryPoliceAttestation(rootGetters['UserInfoStore/userUid']).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      }
    },
    mutations: {
      setDocumentsForSigning(state, data) {
        state.documentsForSigning = data || [];
      },
      setSignatures(state, data) {
        const signatures = transformSignaturesArray(data);
        state.activeSignatures = [];
        state.inactiveSignatures = [];
        signatures.forEach((signature) => {
          moment().isAfter(moment(signature.expires)) || signature.status === 'rejected' ? state.inactiveSignatures.push(signature) : state.activeSignatures.push(signature);
        });
      },
      pushOneSignature(state, signature) {
        state.activeSignatures.push(signature);
        state.activeSignatures = transformSignaturesArray(state.activeSignatures);
      },
      setPersonalNumber(state, value) { state.newSignature.personalNumber = value; },
      setNewDocFile(state, file) { state.newSignature.file = file; },
      clearNewSignature(state) {
        state.newSignature = {
          personalNumber: '',
          file: ''
        };
      },
      clearStore(state) {
        state.activeSignatures = '';
        state.inactiveSignatures = '';
        state.newSignature = {
          personalNumber: '',
          file: ''
        };
      }
    }
  };
};
