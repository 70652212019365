<template>
  <div class="check-video">
    <h3 class="check-video__title">{{ title }}</h3>
    <p v-for="(text, index) in descriptions"
       :key="index"
       class="check-video__description"
       v-html="text"></p>
  </div>
</template>

<script>
  export default {
    computed: {
      isAssignmentPage() { return this.$route.name == 'ServerOneAssignment' || this.$route.name == 'BuyerOneAssignment'; },
      title() {
        if (this.isAssignmentPage) {
          return this.$gettext('OPS! Only send an offer if your software and hardware is up to date.');
        }
        return this.$gettext('OPS! Make sure that your software and hardware is up to date.');
      },
      descriptions() {
        return [
          this.$gettext('1. Our built in video solution will work on the latest versions of: '
            + '<a class="sk-link sk-link--gray-bg" target="_blank" href="https://www.google.com/chrome">Chrome</a>, '
            + '<a class="sk-link sk-link--gray-bg" target="_blank" href="https://www.apple.com/safari/">Safari</a>, '
            + '<a class="sk-link sk-link--gray-bg" target="_blank" href="https://www.mozilla.org">Firefox</a>, '
            + '<a class="sk-link sk-link--gray-bg" target="_blank" href="https://vivaldi.com">Vivaldi</a>, '
            + '<a class="sk-link sk-link--gray-bg" target="_blank" href="https://www.microsoft.com/nb-no/windows/microsoft-edge">Microsoft Edge</a> '
            + 'make sure that you have installed one of these browsers.'),
          this.$gettext('2. You have a working web camera and microphone'),
          this.$gettext('3. You are connected to a good Wifi system or 4G network.'),
          this.$gettext('4. Your IT/security department allows you to use video from your computer.')
        ];
      }
    }
  };
</script>

<style scoped>
  .check-video {
    width: 100%;
    font-size: 12px;
    text-align: left;
  }

  .check-video__title {
    margin-bottom: 10px;
    color: #000;
    font-size: 12px;
  }

  .check-video__test {
    margin-top: 20px;
  }
</style>
