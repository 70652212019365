const travelExpenses = {
  methods: {
    // ==================================================
    // ==================================================
    // INTERPRETERS TRAVEL EXPENSES CALLS
    // ==================================================
    // ==================================================
    fetchTravelExpensesList(params) {
      const {jobId, discussionId} = params;
      return new Promise((resolve, reject) => {
        this.ajax.get(`/v1/jobs/${jobId}/discussions/${discussionId}/expenses`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    addTravelExpense(params) {
      const {jobId, discussionId, form} = params;
      return new Promise((resolve, reject) => {
        this.ajax.post(`/v1/jobs/${jobId}/discussions/${discussionId}/expenses`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    updateTravelExpense(params) {
      const {jobId, discussionId, expenseId, form} = params;
      return new Promise((resolve, reject) => {
        this.ajax.put(`/v1/jobs/${jobId}/discussions/${discussionId}/expenses/${expenseId}`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    removeTravelExpense(params) {
      const {jobId, discussionId, id} = params;
      return new Promise((resolve, reject) => {
        this.ajax.delete(`/v1/jobs/${jobId}/discussions/${discussionId}/expenses/${id}`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
  }
};

export default travelExpenses;
