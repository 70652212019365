import Vue from 'vue';

export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      suppliersData: {
        suppliers: [],
        count: 0,
        pages: 1,
        page: 1
      },
      searchSuppliersList: [],
      supplierSearchQuery: '',
      updateSupplierPageGrid: ''
    },
    actions: {
      getSuppliers({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getSuppliers(params).then((response) => {
            commit('setSuppliersData', response);
            const resFormatted = response;
            resFormatted.data = response.suppliers;
            resolve(resFormatted);
          }).catch(() => {
            reject();
          });
        });
      },
      searchSupplierByName({commit}, searchName) {
        return new Promise((resolve, reject) => {
          const params = {
            's[name_cont]': searchName
          };
          fetchData.getSuppliers(params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setSearchSuppliersList', data.suppliers);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      cancelSearchSupplier() {
        return new Promise((resolve, reject) => {
          fetchData.cancelSearchCustomers().then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      createSupplier(context, {payload}) {
        return new Promise((resolve, reject) => {
          fetchData.createSupplier(payload).then((response) => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      editSupplier(context, {id, payload}) {
        return new Promise((resolve, reject) => {
          fetchData.editSupplier(id, payload).then((response) => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        });
      },
    },
    mutations: {
      setSuppliersData(state, data) {
        state.suppliersData = data;
      },
      setSearchSuppliersList(state, data) {
        Vue.set(state, 'searchSuppliersList', data);
      },
      setSupplierSearchQuery(state, value) {
        state.supplierSearchQuery = value;
      },
      refreshSupplierGrid(state) {
        state.updateSupplierPageGrid = Date.now();
      },
      clearStore(state) {
        state.suppliersData = {
          suppliers: [],
          count: 0,
          pages: 1,
          page: 1
        };
        state.searchSuppliersList = [];
        state.supplierSearchQuery = '';
        state.updateSupplierPageGrid = '';
      }
    }
  };
};
