import Vue from 'vue';
import GetTextPlugin from 'vue-gettext';
import translations from '@/translations.json';

const availableLanguages = require('@skiwo/skiwo-available-langs');

const gettextOptions = {
  translations: translations,
  defaultLanguage: 'nb',
  availableLanguages,
  silent: true
};
Vue.use(GetTextPlugin, gettextOptions);

// set all pairs of route and title
const titles = {
  // buyer routes mobile titles
  ServerHome: 'Dashboard',
  ServerCalendar: 'Calendar',
  ServerChangePassword: 'Change password',
  ServerProfile: 'Profile',
  ServerProfileEdit: 'My profile',
  ServerAllAssignments: 'Assignments',
  ServerOneAssignment: 'Assignment',
  ServerPayoutsAll: 'Payouts',
  ServerPayoutsCreateNorwayCompany: 'Payouts',
  ServerPayoutsCreateNorway: 'Payouts',
  ServerPayoutsCreateWorld: 'Payouts',
  ServerPayoutsCreate: 'Payouts',
  ServerPayoutsCreateWorldPaypal: 'Payouts',
  ServerPayoutsEdit: 'Payouts',
  ServerPayoutsShow: 'Payouts',
  ServerInterpretationNotifications: 'Interpretation Preferences',
  ServerTranslationNotifications: 'Translation Preferences',
  ServerPreferencesAvailability: 'Preferences',
  ServerPostAssignment: 'Register an assignment',
  ServerAllProjects: 'Translations',
  ServerAllInvitations: 'Invitations',
  // buyer routes mobile titles
  BuyerHome: 'Dashboard',
  BuyerChangePassword: 'Change password',
  BuyerInterpreters: 'Interpreters',
  BuyerCalendar: 'Calendar',
  BuyerUserProfile: 'Profile',
  BuyerUserProfileEdit: 'My profile',
  BuyerAllAssignments: 'Assignments',
  BuyerBookDirectly: 'Direct assignment',
  BuyerFaiBooking: 'Direct assignment',
  BuyerEditInterpretation: 'Edit assignment',
  BuyerOneAssignment: 'Assignment',
  BuyerSelectAssignmentType: 'New order',
  BuyerCompanyInfo: 'Company info',
  BuyerEmployees: 'Employees',
  BuyerUpgradeProfile: 'Upgrade',
  BuyerPaymentMethods: 'Payments',
  BuyerPreferencesNotifications: 'Preferences'
};

export function createMobileTitle(pathName, locale) {
  let title;
  // set locale
  if (locale) {
    Vue.config.language = locale;
  }
  // check if we have title for current route
  for (const url in titles) {
    if (pathName === url) {
      title = titles[url];
    }
  }
  // return translated title
  return Vue.prototype.$gettext(title);
}
