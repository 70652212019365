export default () => {
  return (store) => {
    store.subscribe((mutation) => {
      if (mutation.type === 'executeNotTrackedMutation') {
        store.commit(mutation.payload.mutationName, mutation.payload.payload);
        store.commit('clearNotTrackedMutation');
        return;
      }
    });
  };
};
