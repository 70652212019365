const adminDiscussion = {
  methods: {
    // ==================================================
    // ==================================================
    // ADMIN CHAT CALLS
    // ==================================================
    // ==================================================
    fetchAdminMessages({entityId, entityType}) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get('/v1/support/admin_discussions', {params: {entity_id: entityId, entity_type: entityType}}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    sendAdminMessage({entityId, entityType, message}) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/support/admin_discussions/messages', {entity_id: entityId, entity_type: entityType, message}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    editAdminMessage({msgId, message}) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/support/admin_discussions/messages/${msgId}`, {message}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    removeAdminMessage(msgId) {
      return new Promise((resolve, reject) => {
        this.ajax.delete(`/v1/support/admin_discussions/messages/${msgId}`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
  }
};

export default adminDiscussion;
