import { render, staticRenderFns } from "./Step3UsingOwnProvider.vue?vue&type=template&id=9094e660&scoped=true&"
import script from "./Step3UsingOwnProvider.vue?vue&type=script&lang=js&"
export * from "./Step3UsingOwnProvider.vue?vue&type=script&lang=js&"
import style0 from "./Step3UsingOwnProvider.vue?vue&type=style&index=0&id=9094e660&prod&lang=css&"
import style1 from "./Step3UsingOwnProvider.vue?vue&type=style&index=1&id=9094e660&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9094e660",
  null
  
)

export default component.exports