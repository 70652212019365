const conversations = {
  methods: {
    // ==================================================
    // ==================================================
    // CONVERSATION CALLS
    // ==================================================
    // ==================================================
    getConversations(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/conversations', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    createSmsConversation(params) {
      return new Promise((resolve, reject) => {
        this.ajax.post('/v1/conversations/sms', {conversation: params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    createNoteConversation(params) {
      return new Promise((resolve, reject) => {
        this.ajax.post('/v1/conversation_notes', {conversationNote: params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    }
  }
};

export default conversations;
