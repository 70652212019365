const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');

const preference = {
  methods: {
    // ==================================================
    // ==================================================
    // PREFERENCES CALLS
    // ==================================================
    // ==================================================
    fetchWorkingHours(uid) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/interpreters/${uid}/offtimes`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    // eslint-disable-next-line
    sendWorkingHours(form, uid) {
      // eslint-disable-next-line
      const self = this;
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/interpreters/${uid}/offtimes`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    updateProfileData(form, uid) {
      return new Promise((resolve, reject) => {
        this.ajax.put(`/v1/interpreters/${uid}`, form).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    fetchPreferences() {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/notification_preferences').then((response) => {
          resolve(response?.data?.notificationPreferences);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    savePreferences(params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put('/v1/notification_preferences', params).then((response) => {
          resolve(response?.data?.notificationPreferences);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    fetchPrivateEvents() {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/calendars/events').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    removePrivateEvent(eventId) {
      return new Promise((resolve, reject) => {
        this.ajax.delete(`v1/calendars/events/${eventId}`).then((data) => {
          resolve(data?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    savePrivateEvent(form) {
      return new Promise((resolve, reject) => {
        this.ajax.post('/v1/calendars/events', form).then((response) => {
          resolve(response?.data?.event);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    sendTimezone(moment) {
      return new Promise((resolve, reject) => {
        this.ajax.put('/v1/preferences', {current_time: moment.toString(), dst: moment.isDST()}).then(() => {
          resolve();
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    setLocale(locale, loggedIn) {
      return new Promise((resolve) => {
        this.locale = locale;
        // eslint-disable-next-line
        if (isBrowser() && loggedIn) {
          this.ajax.put('/v1/preferences', {locale: this.locale}).then(() => {
            resolve();
          }).catch((error) => {
            this.resolveError403(error, resolve);
          });
        } else {
          resolve();
        }
      });
    },
  }
};

export default preference;
