export default ({$fetcher: fetchData, $moment: moment}) => {
  const filterDates = {
    startDate: moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  };
  return {
    namespaced: true,
    state: {
      newTicket: {
        category: '',
        jobIds: [],
        subject: '',
        description: '',
        invoiceNumber: '',
      },
      errors: {
        category: {name: 'categoryErrors', errors: []},
        description: {name: 'descriptionErrors', errors: []},
        jobIds: {name: 'jobIdsErrors', errors: []}
      },
      ticketsStatistics: '',
      ticketsStatisticsLine: {
        tickets: '',
        pages: 1,
        page: 1,
        count: '',
      },
      searchQuery: '',
      jobIdsList: [],
      jobIdsFullList: [],
      tickets: {
        tickets: '',
        pages: 0
      },
      filterStartDate: filterDates.startDate,
      filterEndDate: filterDates.endDate
    },
    getters: {
      pageTotal(state) {
        return state.tickets.pages || 0;
      },
      ticketsList(state) {
        return state.tickets.tickets || [];
      }
    },
    actions: {
      setError({state, commit}, {fieldName, errorText}) {
        commit('setError', {fieldName, errorText});
        commit('ErrorsStore/setError', {name: state.errors[fieldName].name, errors: state.errors[fieldName].errors}, {root: true});
      },
      removeErrors({commit}) {
        commit('removeErrors');
        commit('ErrorsStore/removeAllErrors', null, {root: true});
      },
      searchJobsById({commit}, searchQuery) {
        return new Promise((resolve, reject) => {
          const params = {
            searchQuery,
            items: 10,
            page: 1
          };
          fetchData.getSearchAssignments(params).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setJobIdsList', response?.jobs);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      cancelSearchJobs() {
        return new Promise((resolve, reject) => {
          fetchData.cancelSearchAssignments().then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getTickets({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getTickets(params).then((data) => {
            commit('setTickets', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      fetchTicketsStatistics({commit, rootGetters, state}) {
        return new Promise((resolve, reject) => {
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'] || rootGetters['EnterpriseStore/getManagerJobEnterpriseId'];
          fetchData.getTicketsStatistics(enterpriseId, {dateFrom: state.filterStartDate, dateTo: state.filterEndDate}).then((data) => {
            commit('setTicketsStatistics', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      fetchTicketsStatisticsLines({commit, rootGetters, state}, params) {
        return new Promise((resolve, reject) => {
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'] || rootGetters['EnterpriseStore/getManagerJobEnterpriseId'];
          const {page, items} = params;
          const queryParams = {
            page,
            items,
            dateFrom: state.filterStartDate,
            dateTo: state.filterEndDate
          };
          fetchData.getTicketsStatisticsLines(enterpriseId, queryParams).then((data) => {
            commit('setTicketsStatisticsLines', {
              tickets: data.collection,
              pagination: data.pagination
            });
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      exportTicketsStatistics({rootGetters, state}) {
        return new Promise((resolve, reject) => {
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'] || rootGetters['EnterpriseStore/getManagerJobEnterpriseId'];
          fetchData.exportTicketsStatistics(enterpriseId, {dateFrom: state.filterStartDate, dateTo: state.filterEndDate}).then((data) => {
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      createForm({state}) {
        const form = new FormData();

        form.append('ticket[category]', state.newTicket.category);
        form.append('ticket[description]', state.newTicket.description.trim());
        // Hardcode to question to avoid doint a lot of job on BE side
        // We will eventually redo the ticket converstation system, right no need for customer to pick the subject
        form.append('ticket[subject]', 'question');
        for (const jobId of state.newTicket.jobIds) {
          form.append('ticket[job_ids][]', jobId);
        }
        if (state.newTicket.invoiceNumber) form.append('ticket[invoice_ids][]', state.newTicket.invoiceNumber);
        return Promise.resolve(form);
      },
      createOrderForm({rootState}) {
        const form = new FormData();
        form.append('ticket[order_ids][]', rootState.OneProjectStore.project.id);
        form.append('ticket[description]', rootState.OneTicketStore.replyText);

        return Promise.resolve(form);
      },
      createTicket({dispatch}) {
        return new Promise((resolve, reject) => {
          dispatch('createForm').then((form) => {
            fetchData.createTicket(form).then((data) => {
              resolve(data);
            }).catch((response) => {
              reject(response);
            });
          });
        });
      },
      createOrderTicket({dispatch}) {
        return new Promise((resolve, reject) => {
          dispatch('createOrderForm').then((form) => {
            fetchData.createTicket(form).then((data) => {
              resolve(data);
            }).catch((response) => {
              reject(response);
            });
          });
        });
      },
      addParticipant(context, {id, email}) {
        const params = {
          participant_email: email
        };

        return new Promise((resolve, reject) => {
          fetchData.addParticipant(id, params).then((data) => {
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
    },
    mutations: {
      setError(state, {fieldName, errorText}) {
        state.errors[fieldName].errors = [...state.errors[fieldName].errors, errorText];
      },
      removeErrors(state) {
        for (const key of Object.keys(state.errors)) {
          state.errors[key].errors = [];
        }
      },
      setNewTicketCategory(state, value) { state.newTicket.category = value; },
      setNewTicketJobIds(state, data) { state.newTicket.jobIds = data; },
      setNewTicketDescription(state, value) { state.newTicket.description = value; },
      setNewTicketInvoiceNum(state, value) { state.newTicket.invoiceNumber = value; },
      setSearchQuery(state, value) { state.searchQuery = value; },
      setJobIdsList(state, data) { state.jobIdsList = data || []; },
      setJobIdsFullList(state, data) { state.jobIdsFullList = data || []; },
      addJobIdsFullListItem(state, data) { state.jobIdsFullList.push(data); },
      setTickets(state, data) { state.tickets = data; },
      setTicketsStatistics(state, data) { state.ticketsStatistics = data; },
      setTicketsStatisticsLines(state, {tickets, pagination}) {
        state.ticketsStatisticsLine = {
          ...state.ticketsStatisticsLine,
          tickets,
          ...pagination
        };
      },
      clearTicketsStatistics(state) {
        state.ticketsStatistics = '';
        state.ticketsStatisticsLine.tickets = '';
      },
      setDateRange(state, {startDate, endDate}) {
        state.filterStartDate = startDate;
        state.filterEndDate = endDate;
      },
      clearModalStore(state) {
        state.newTicket = {
          category: '',
          jobIds: [],
          subject: '',
          description: '',
          invoiceNumber: '',
        };
        state.searchQuery = '';
        state.jobIdsList = [];
        state.jobIdsFullList = [];
      },
      clearStore(state) {
        state.tickets = {
          tickets: '',
          pages: 0
        };
      },
      clearTicketsStatisticData(state) {
        state.ticketsStatisticsLine = {
          tickets: '',
          pages: 1,
          page: 1,
          count: '',
        };
        state.ticketsStatistics = '';
        state.filterStartDate = filterDates.startDate;
        state.filterEndDate = filterDates.endDate;
      }
    }
  };
};
