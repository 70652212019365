(<template>
  <section class="lang-pairs post-form__field">
    <sk-radio v-for="skill in filteredSkillsList"
              :key="compLangPairValue(skill)"
              :item-name="'lang-pair'"
              :preselected-value="computedLangsValue"
              :item-value="compLangPairValue(skill)"
              :item-text="compLangPairText(skill)"
              class="lang-pairs__radio-label"
              @radiochanged="setLanguagePair" />
  </section>
</template>)

<script>
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('EditProfileStore', {
        skills: ({profileData}) => { return profileData && profileData.skills ? profileData.skills : ''; }
      }),
      ...mapState('PostingStore', {
        langFrom: ({LanguagesStore}) => LanguagesStore.languageFrom,
        langTo: ({LanguagesStore}) => LanguagesStore.languageTo,
        assignmentQualification: ({QualificationStore}) => QualificationStore.qualification
      }),
      skillsList() {
        return this.skills || [];
      },
      filteredSkillsList() {
        return this.skillsList.filter((skill) => skill.approved);
      },
      computedLangsValue() {
        return `${this.langFrom}-${this.langTo}-${this.assignmentQualification}`;
      }
    },
    watch: {
      skills() {
        if (this.skills && (!this.langFrom || !this.langTo)) {
          this.updateLangs(this.compLangPairValue(this.skills[0] || {}));
        }
      }
    },
    methods: {
      setLanguagePair(value) {
        this.updateLangs(value);
      },
      updateLangs(value) {
        if (value && value.split) {
          const langsArray = value.split('-');
          this.$store.commit('executeNotTrackedMutation', {
            mutationName: 'PostingStore/LanguagesStore/setLangFrom',
            payload: langsArray[0]
          });
          this.$store.commit('executeNotTrackedMutation', {
            mutationName: 'PostingStore/LanguagesStore/setLangTo',
            payload: langsArray[1]
          });
          this.$store.commit('executeNotTrackedMutation', {
            mutationName: 'PostingStore/QualificationStore/setQualification',
            payload: langsArray[2]
          });
        }
      },
      compLangPairValue(obj) {
        return `${obj.langFromId}-${obj.langToId}-${obj.qualificationId}`;
      },
      compLangPairText(obj) {
        return `${this.computeLangName(obj.langFromId)} - ${this.computeLangName(obj.langToId)}`;
      },
      computeLangName(langId) {
        if (this.$store.state.selectsData.language && this.$store.state.selectsData.language.length) {
          for (const lang of this.$store.state.selectsData.language) {
            if (lang.id == langId) {
              return lang.name;
            }
          }
        }
      }
    },
    mounted() {
      if (this.skills && (!this.langFrom || !this.langTo)) {
        this.updateLangs(this.compLangPairValue(this.skills[0] || {}));
      }
    }
  };
</script>

<style scoped>
  .lang-pairs {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }

  .lang-pairs__radio-label {
    display: block;
    width: 100%;
  }

  .lang-pairs__radio-label:not(:last-child) {
    display: block;
    margin-bottom: 12px;
  }

  @media (max-width: 768px) {
    .lang-pairs__radio-label:not(:last-child) {
      margin-bottom: 15px;
    }
  }
</style>
