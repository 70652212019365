(<template>
  <article class="delete-enterprise-admin-account">
    <h2 class="delete-enterprise-admin-account-modal__title">{{ $gettext('Delete account') }}</h2>
    <section class="delete-enterprise-admin-account-modal__section">
      <p v-html="deleteEnterpriseAdminAccountText"></p>
    </section>
    <section class="delete-enterprise-admin-account-modal__section">
      <p v-html="contactSupportTeamText"></p>
    </section>
  </article>
</template>)

<script>
  import getProductConfig from '@/modules/productConfig';
  import {mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', [
        'additionalUserInfo'
      ]),
      supportPhone() {
        return getProductConfig(this.$gettext, 'salita', 'domainData', 'supportPhone');
      },
      supportEmail() {
        return getProductConfig(this.$gettext, 'salita', 'domainData', 'supportEmail');
      },
      enterpriseName() {
        return this.additionalUserInfo?.enterprise?.name || '';
      },
      deleteEnterpriseAdminAccountText() {
        const template = this.$gettext('Sorry since you are the owner of enterprise <b>%{enterprise}</b> we cannot remove your account automatically.');
        return this.$gettextInterpolate(template, {enterprise: this.enterpriseName});
      },
      contactSupportTeamText() {
        const template = this.$gettext('Please contact support team at <a class="sk-link js-copy-to-clipboard" href="mailto:%{email}">%{email}</a> or call <a class="sk-link js-copy-to-clipboard" href="tel:%{phone}">%{phone}</a>');
        return this.$gettextInterpolate(template, {email: this.supportEmail, phone: this.supportPhone});
      }
    }
  };
</script>

<style scoped>
  .delete-enterprise-admin-account {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-top: -15px;
    padding: 45px 30px 20px;
  }

  .delete-enterprise-admin-account-modal__title {
    margin-bottom: 15px;
    color: #000;
    font-size: 18px;
  }

  .delete-enterprise-admin-account-modal__section {
    padding-bottom: 15px;
    font-size: 14px;
  }

  @media (max-width: 767px) {
    .delete-enterprise-admin-account {
      padding: 45px 16px;
    }
  }
</style>
