<template>
  <section class="availability-events__wrapper">
    <div class="availability-events__title-cont">
      <h3 class="availability-events__title">
        <p class="availability-events__name">{{ interpreterFullName }}</p>
        <p class="availability-events__phone">{{ interpreterPhone }}</p>
      </h3>
      <admin-actions :interpreter="interpreter"
                     class="availability-events__actions"
                     @invited="makeInterInvite"
                     @applied="makeInterApply"
                     @declined="makeInterDecline" />
    </div>
    <div v-for="event in jobsList"
         :key="event.id"
         :class="{'availability-events__event-row--current-job': event.isCurrentJob}"
         class="availability-events__event-row">
      <p class="availability-events__event-cell availability-events__id">{{ `#${getEventId(event)}` }}</p>
      <p :class="[`availability-events__session-type--${event.sessionType}`]"
         class="availability-events__event-cell availability-events__session-type"></p>
      <p class="availability-events__event-cell availability-events__customer">{{ event.customer }}</p>
      <p class="availability-events__event-cell availability-events__time"
         v-html="getTime(event)"></p>
    </div>
  </section>
</template>

<script>
  import {mapState} from 'vuex';
  import helpers from '@/helpers';
  import FAIAdminActions from '@/components/assignment_components/one_assignment/interactive_info/invite_suppliers/FAIAdminActions';

  export default {
    components: {
      'admin-actions': FAIAdminActions
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        assignmentObj: ({storeJobObj}) => storeJobObj || {},
        assignmentRequirements: ({storeJobObj}) => storeJobObj.interpretationRequirement || {}
      }),
      interpreterPhone() {
        return this.interpreter.phoneNumber || '';
      },
      interpreterFullName() {
        if (this.interpreter.fullName) return this.interpreter.fullName;
        return `${this.interpreter.firstName || ''} ${this.interpreter.lastName || ''}`;
      },
      interpreter() {
        return this.data.data.interpreter || {};
      },
      interpreterJobs() {
        return this.data.data.interpreterJobs || [];
      },
      currentJobData() {
        const res = [];
        if (this.assignmentObj.id) {
          res.push({
            ...this.assignmentRequirements,
            id: this.getEventId(this.assignmentObj),
            customer: this.assignmentObj.info?.owner,
            isCurrentJob: true
          });
        }
        return res;
      },
      jobsList() {
        // remove duplicate assignment with current job
        const interpreterJobs = this.interpreterJobs.filter((job) => job.id != this.assignmentObj.id);
        return [...interpreterJobs, ...this.currentJobData].sort((a, b) => {
          return Number(this.$moment(a.startTime).diff(b.startTime));
        });
      }
    },
    methods: {
      closeModal() {
        this.$store.dispatch('ModalStore/closeModal');
      },
      getEventId(event) {
        return helpers.getJobInfo.jobId(event);
      },
      getTime(event) {
        const startTime = this.$moment(event.startTime).format('DD/MM/YYYY HH:mm');
        const finishTime = this.$moment(event.finishTime).format('DD/MM/YYYY HH:mm');
        return `${startTime}<br>${finishTime}`;
      },
      makeInterInvite() {
        this.closeModal();
        this.data.context.makeInterInvite();
      },
      makeInterApply() {
        this.closeModal();
        this.data.context.makeInterApply();
      },
      makeInterDecline() {
        this.closeModal();
        this.data.context.makeInterDecline();
      }
    }
  };
</script>

<style>
  .availability-modal .sk-modal__wrapper .sk-modal__title {
    margin-top: 15px;
    padding-top: 0;
  }
</style>

<style scoped>
  .availability-events__wrapper {
    margin: 0;
  }

  .availability-events__title-cont {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 45px;
    margin-bottom: 10px;
    margin-left: 20px;
  }

  .availability-events__title {
    display: flex;
    margin-right: 16px;
    font-weight: normal;
    font-size: 14px;
  }

  .availability-events__name {
    padding-right: 20px;
  }

  .availability-events__actions {
    margin-left: auto;
  }

  .availability-events__event-row {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 50px;
    border-top: 1px solid #ccc;
    color: #000;
  }

  .availability-events__event-row--current-job {
    background: #ff5b24;
  }

  .availability-events__event-cell {
    width: 100%;
    padding-right: 15px;
  }

  .availability-events__event-cell:last-child {
    padding: 0;
  }

  .availability-events__id {
    flex-shrink: 0;
    width: 70px;
    padding-right: 5px;
    font-weight: bold;
    text-decoration: underline;
  }

  .availability-events__time {
    flex-shrink: 0;
    width: 120px;
    margin-left: auto;
  }

  .availability-events__customer {
    text-align: center;
  }

  .availability-events__session-type {
    display: inline-block;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 25px;
    padding: 0;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .availability-events__session-type--video {
    background-image: url(~@assets/imgs/assignment_types/calendar/video_icon_calendar.svg);
    background-size: auto 10px;
  }

  .availability-events__session-type--videoroom {
    background-image: url(~@assets/imgs/assignment_types/calendar/video_room_icon_calendar.svg);
    background-size: 21px auto;
  }

  .availability-events__session-type--phone {
    background-image: url(~@assets/imgs/assignment_types/calendar/phone_icon_calendar.svg);
    background-size: auto 18px;
  }

  .availability-events__session-type--in_person {
    background-image: url(~@assets/imgs/assignment_types/calendar/in_person_icon_calendar.svg);
    background-size: auto 20px;
  }

  @media (max-width: 767px) {
    .availability-events__title-cont {
      flex-wrap: wrap;
    }

    .availability-events__title {
      display: block;
      margin-right: 0;
    }
  }

  @media (max-width: 420px) {
    .availability-events__event-row {
      padding: 15px;
    }

    .availability-events__title {
      display: flex;
      justify-content: center;
      order: 1;
      width: 100%;
      margin-right: 0;
      padding-top: 15px;
    }
  }
</style>
