(<template>
  <section v-if="badges.length"
           class="badges__main-wrapper">
    <h2 v-if="!hideTitle"
        class="profile__section-title">
      <span>{{ $gettext("Badges") }}</span>
    </h2>
    <p class="badges__one-area-subtitle">{{ subtitleText }}</p>
    <section v-if="showOnlyActive"
             class="badges__badges-wrapper">
      <article v-for="badge in activeBadges"
               :key="badge.type"
               :class="{active: badge.active}"
               class="badges__one-badge-cont">
        <figure :class="[badgeClass(badge)]"
                class="badges__one-badge"></figure>
        <p class="badges__one-badge-text">{{ badge.name }}</p>
      </article>
    </section>
    <section v-else
             class="badges__badges-wrapper">
      <article v-for="badge in badges"
               :key="badge.type"
               :class="{active: badge.active}"
               class="badges__one-badge-cont">
        <figure :class="[badgeClass(badge)]"
                class="badges__one-badge"></figure>
        <p class="badges__one-badge-text">{{ badge.name }}</p>
      </article>
    </section>
  </section>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsNotInterpreter'
      ]),
      paramsList() { return this.params || {}; },
      badges() { return this.params.badges.filter((badge) => badge.context === 'default') || []; },
      activeBadges() { return this.badges.filter((badge) => badge.active); },
      hideTitle() { return this.params.hideTitle; },
      subtitleText() {
        if (!this.showBadgesSection) return this.$gettext('The interpreter has not yet unlocked any badges.');
        return this.$gettext(this.showOnlyActive ? this.$gettext('These achievement badges are automatically activated as the achievements are completed.') : this.$gettext('You will find a number of digital badges that are automatically activated.'));
      },
      showOnlyActive() {
        if (this.userIsNotInterpreter || this.$route.name === 'ServerProfile') return true;
        return false;
      },
      showBadgesSection() {
        if (this.showOnlyActive) {
          if (this.activeBadges && this.activeBadges.length) return true;
          return false;
        } else if (this.badges.length) return true;
        return false;
      }
    },
    methods: {
      badgeClass(badge) {
        return badge.type ? badge.active ? `badge--${badge.type}_active` : `badge--${badge.type}` : '';
      }
    }
  };
</script>

<style scoped>
  .badges__main-wrapper {
    display: block;
    width: 100%;
  }

  .badges__badges-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -20px;
  }

  .badges__one-area-subtitle {
    padding-bottom: 20px;
    color: rgba(0, 0, 0, 0.4);
  }

  .badges__one-badge-cont {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    width: 100%;
    height: 60px;
    margin-bottom: 20px;
  }

  .badges__one-badge {
    display: block;
    width: 100%;
    max-width: 100px;
    height: 100%;
    margin: 0;
    margin-bottom: 5px;
    background-position: 50% 50%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .badges__one-badge-text {
    display: block;
    width: 100%;
    margin: 0;
    padding-bottom: 10px;
    color: #000;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
  }

  .active .badges__one-badge-text {
    color: #000;
  }

  @media (max-width: 768px) {
    .badges__badges-wrapper {
      justify-content: space-evenly;
      margin: 0 -10px;
    }
  }
</style>

<style scoped src="@/assets/tikktalk/css/badges.css"></style>
