import BookingHelper from '@/store/booking_stores/BookingHelper';

export default () => {
  return {
    namespaced: true,
    state: {
      secretaryContactName: '',
      secretaryContactPhone: '',
      secretaryContactPhoneCode: '+47',
      secretaryInfo: '',
      secretaryCallbackPhoneSelect: false,
      secretaryCallbackPhone: '',
      secretaryCallbackPhoneCode: '+47',
      // errors
      errors: {
        secretaryContactName: {name: 'secretaryContactNameError', errors: []},
        secretaryContactPhone: {name: 'secretaryContactPhoneError', errors: []},
        secretaryInfo: {name: 'secretaryInfoError', errors: []},
        secretaryCallbackPhone: {name: 'secretaryCallbackPhonePhoneError', errors: []}
      }
    },
    mutations: {
      ...BookingHelper().mutations,
      setSecretaryContactName(state, value) {
        state.secretaryContactName = value;
      },
      setSecretaryContactPhone(state, value) {
        state.secretaryContactPhone = value;
      },
      setSecretaryContactPhoneCode(state, value) {
        state.secretaryContactPhoneCode = value;
      },
      setSecretaryInfo(state, value) {
        state.secretaryInfo = value;
      },
      setSecretaryCallbackPhoneSelect(state, value) {
        state.secretaryCallbackPhoneSelect = value;
      },
      setSecretaryCallbackPhone(state, value) {
        state.secretaryCallbackPhone = value;
      },
      setSecretaryCallbackPhoneCode(state, value) {
        state.secretaryCallbackPhoneCode = value;
      },
      clearStore(state) {
        state.secretaryContactName = '';
        state.secretaryContactPhone = '';
        state.secretaryContactPhoneCode = '+47';
        state.secretaryInfo = '';
        state.secretaryCallbackPhoneSelect = false;
        state.secretaryCallbackPhone = '';
        state.secretaryCallbackPhoneCode = '+47';
      }
    }
  };
};
