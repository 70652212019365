<template>
  <div :style="styles"
       :class="[eventTypeClass, sessionTypeClass, travelTypeClass]"
       class="timeline-event">
    <sk-tooltip v-if="tooltipText && enableTooltips"
                :item-text="tooltipText"
                :position="'top-center'"
                class="timeline-event__tooltip" />
    <p class="timeline-event__content">{{ eventDescription }}</p>
  </div>
</template>

<script>
  export default {
    props: {
      // event: {
      // id: String, // `${id}-${eventType}`
      // startTime: Timestamp
      // finishTime: Timestamp
      // eventType: String
      // }
      event: {
        type: Object,
        required: true
      },
      enableTooltips: Boolean,
      width: {
        type: String,
        default: ''
      },
      left: {
        type: String,
        default: ''
      }
    },
    computed: {
      eventId() { return this.event.id.split('-')[0]; },
      eventStartTime() { return this.$moment(this.event.startTime).format('HH:mm'); },
      eventFinishTime() { return this.$moment(this.event.finishTime).format('HH:mm'); },
      eventTimeText() { return `${this.eventStartTime} - ${this.eventFinishTime}`; },
      styles() {
        return {
          left: this.left,
          width: this.width
        };
      },
      eventTypeClass() {
        const res = [];
        if (this.event.eventType === 'event') res.push('timeline-event--event');
        if (this.event.isSelected) res.push('timeline-event--selected');
        if (this.event.allDay) res.push('timeline-event--all-day');
        if (this.event.eventType === 'offtime') res.push('timeline-event--offtime');
        if (this.event.eventType === 'job') res.push('timeline-event--job');
        if (this.event.eventType === 'travelToTime' || this.event.eventType === 'travelFromTime') res.push('timeline-event--travel-time');
        return res;
      },
      travelTypeClass() {
        const res = [];
        if (this.event.eventType === 'travelToTime') res.push('timeline-event--travel-to');
        if (this.event.eventType === 'travelFromTime') res.push('timeline-event--travel-from');
        return res;
      },
      sessionTypeClass() {
        return this.event.sessionType ? [`timeline-event--${this.event.sessionType}`] : [];
      },
      eventDescription() {
        switch (this.event.eventType) {
          case 'offtime':
            return this.$gettext('N/A');
          case 'event':
            return this.event.title;
          default:
            return '';
        }
      },
      tooltipText() {
        let title = '';
        switch (this.event.eventType) {
          case 'travelToTime':
            title = this.$gettext('Travel to');
            break;
          case 'travelFromTime':
            title = this.$gettext('Travel from');
            break;
          case 'job':
            title = `#${this.eventId}`;
            break;
          case 'event':
            title = this.event.title;
            break;
          case 'offtime':
            title = this.$gettext('N/A');
            break;
          default:
            title = '';
        }
        return title ? `${title}: ${this.eventTimeText}` : '';
      }
    }
  };
</script>

<style>
  .timeline-event__tooltip .sk-tooltip__text {
    width: auto;
  }
</style>

<style scoped>
  .timeline-event {
    position: absolute;
    z-index: 3;
    display: flex;
    align-items: center;
    height: 24px;
    border: solid 1px #3f43ad;
    border-radius: 4px;
    background-color: #d9d2ee;
    white-space: nowrap;
  }

  .timeline-event--offtime {
    border: solid 1px #cecce7;
    background-color: #fff;
    background-image: url(~@assets/imgs/undefined_imgs/striped-bg.svg);
    background-position: 50% 50%;
    background-size: auto 60px;
    color: #3f43ad;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
  }

  .timeline-event--travel-time {
    border: solid 1px #ccc;
    background-color: #e5e5e5;
  }

  .timeline-event--event {
    border: solid 1px #fd6;
    background-color: #ffecb0;
    color: #333;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
  }

  .timeline-event--all-day {
    z-index: 9;
  }

  #app.salita-facelifted .timeline-event--selected {
    z-index: 10;
    border: solid 1px var(--color-success-700);
    background-color: var(--color-success-100);
  }

  .timeline-event--selected {
    z-index: 10;
    border: solid 1px #ff5b23;
    background-color: #ff5b2333;
  }

  .timeline-event--selected:hover {
    background-color: #fdded3;
  }

  .timeline-event__content {
    overflow: hidden;
    width: 100%;
    margin: auto;
    text-align: center;
    cursor: default;
  }

  .timeline-event--job .timeline-event__content {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .timeline-event--video .timeline-event__content {
    background-image: var(--image-video-icon-not-available);
    background-size: 14px auto;
  }

  .timeline-event--videoroom .timeline-event__content {
    background-image: var(--image-videoroom-icon);
    background-size: 12px auto;
  }

  .timeline-event--phone .timeline-event__content {
    background-image: var(--image-phone-icon-not-available);
    background-size: auto 12px;
  }

  .timeline-event--in_person .timeline-event__content {
    background-image: var(--image-inperson-icon-not-available);
    background-size: auto 14px;
  }

  .timeline-event--video.timeline-event--selected .timeline-event__content {
    background-image: var(--image-video-icon);
  }

  .timeline-event--videoroom.timeline-event--selected .timeline-event__content {
    background-image: var(--image-videoroom-icon);
  }

  .timeline-event--phone.timeline-event--selected .timeline-event__content {
    background-image: var(--image-phone-icon);
  }

  .timeline-event--in_person.timeline-event--selected .timeline-event__content {
    background-image: var(--image-inperson-icon);
  }

  .timeline-event__tooltip {
    position: absolute;
    width: 100%;
    height: 100%;
  }
</style>
