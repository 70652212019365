import DeadlineStore from '@/store/booking_stores/submodules/DeadlineStore';

export default (context) => {
  return {
    namespaced: true,
    state: {
      ...DeadlineStore(context).state,
      confirmationBySelect: false,
      confirmationByPhoneCheckbox: false
    },
    actions: {
      catchConfirmationBySelectState({commit}, value) {
        commit('setConfirmationBySelectState', value);
        if (value) commit('setInitialDeadlineTime');
      }
    },
    mutations: {
      ...DeadlineStore(context).mutations,
      setConfirmationBySelectState(state, value) {
        state.confirmationBySelect = value;
        state.deadline = '';
      },
      setConfirmationByPhoneCheckboxState(state, value) {
        state.confirmationByPhoneCheckbox = value;
      },
      clearStore(state) {
        state.confirmationBySelect = false;
        state.confirmationByPhoneCheckbox = false;
        state.deadline = '';
        state.errors = {deadlineTime: {name: 'deadlineTimeError', errors: []}};
      }
    }
  };
};
