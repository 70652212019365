import {cookieManager} from '@skiwo/sk-basic-components';
import jwtDecode from 'jwt-decode';

const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');

export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      godmode: false,
      support: false,
      loggedIn: false,
      resetPasswordEmail: '',
      punchOutRedirectUrl: ''
    },
    getters: {
      isGodmode(state) {
        return state.godmode;
      },
      punchOutRedirectUrl(state) {
        return state.punchOutRedirectUrl;
      },
    },
    actions: {
      validateToken({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.checkJwtToken().then(() => {
            resolve();
          }).catch((error) => {
            if (error?.status == 422) {
              commit('logOut');
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
            }
            reject(error.data);
          });
        });
      },
      getGodModeToken(context, params) {
        return new Promise((resolve, reject) => {
          fetchData.getGodModeToken(params).then((data) => {
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      checkSupportMode({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.checkSupportMode().then((response) => {
            if (response?.code == 401) {
              commit('logOut');
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setSupportMode');
            resolve();
          }).catch((error) => {
            reject(error.data);
          });
        });
      },
      logOut({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.signOut().then(() => {
            commit('logOut');
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      authorize({rootState, dispatch}, {form, type, rememberMe}) {
        return new Promise((resolve, reject) => {
          fetchData.sendAuthData(form, type).then((data) => {
            dispatch('logIn', {token: data.token, uid: data.identity.uid, rememberMe: rememberMe});
            if (isBrowser()) {
              if (cookieManager.getCookie('userInfo')) {
                const userObj = JSON.parse(cookieManager.getCookie('userInfo'));
                rootState.UserInfoStore.additionalUserInfo.favouriteInterLang = userObj.favouriteInterLang;
                rootState.UserInfoStore.additionalUserInfo.onlineInterLang = userObj.onlineInterLang;
              }
            }
            resolve(data);
          }).catch((data) => {
            reject(data);
          });
        });
      },
      logIn({commit}, {token, uid, rememberMe, punchOutRedirectUrl}) {
        const decoded = jwtDecode(token);
        commit('UserInfoStore/setAdminInfo', decoded.godmode ? decoded : {}, {root: true});
        commit('logIn', {token, uid, rememberMe, punchOutRedirectUrl});
      }
    },
    mutations: {
      logOut(state) {
        state.loggedIn = false;
        if (isBrowser()) {
          cookieManager.setCookie('authToken', '', {path: '/'});
          cookieManager.setCookie('uID', '', {path: '/'});
          cookieManager.setCookie('userInfo', '', {path: '/'});
          cookieManager.setCookie('godmode', '', {path: '/'});
          cookieManager.setCookie('punch_out_redirect_url', '', {path: '/'});
          cookieManager.setCookie('shortLivedToken', '', {path: '/'});
          cookieManager.setCookie('shortLivedTokenExpiresAt', '', {path: '/'});
          cookieManager.setCookie('lastJobCreatedAt', '', {path: '/'});
          cookieManager.setCookie('assignmentHelperEnabled', true, {path: '/'});
          if (cookieManager.getCookie('user_id_hash')) {
            cookieManager.setCookie('user_id_hash', '', {path: '/', expires: 2592000});
          }
          if (cookieManager.getCookie('device_id_hash')) {
            cookieManager.setCookie('device_id_hash', '', {path: '/', expires: 2592000});
          }
          if (localStorage) {
            localStorage.removeItem('uID');
            localStorage.removeItem('authToken');
            localStorage.removeItem('dangerousFieldsEditedValues');
            localStorage.removeItem('skillsNeedDialect');
          }
        }
      },
      logIn(state, {token, uid, rememberMe, punchOutRedirectUrl}) {
        fetchData.setToken(token, uid);
        state.loggedIn = true;
        if (isBrowser()) {
          if (!cookieManager.getCookie('uID')) {
            cookieManager.setCookie('uID', uid, {path: '/', expires: rememberMe ? 2592000 : ''});
          }
          if (!cookieManager.getCookie('user_id_hash')) {
            cookieManager.setCookie('user_id_hash', uid, {path: '/', expires: 2592000});
          }
          if (!cookieManager.getCookie('device_id_hash')) {
            cookieManager.setCookie('device_id_hash', uid, {path: '/', expires: 2592000});
          }
          if (!cookieManager.getCookie('authToken')) {
            cookieManager.setCookie('authToken', token, {path: '/', expires: 604800});
          }
          if (punchOutRedirectUrl && !cookieManager.getCookie('punch_out_redirect_url')) {
            cookieManager.setCookie('punch_out_redirect_url', punchOutRedirectUrl, {path: '/', expires: rememberMe ? 2592000 : ''});
          }
        }
      },
      setAuthInfoToStorage(state, {token, uid}) {
        if (isBrowser()) {
          localStorage.setItem('uID', uid);
          localStorage.setItem('authToken', token);
        }
      },
      setResetPasswordEmail(state, value) {
        state.resetPasswordEmail = value;
      },
      setSupportMode(state) {
        state.support = true;
      },
      setGodMode(state, value) {
        state.godmode = value;
        if (isBrowser()) {
          cookieManager.setCookie('godmode', (value || ''), {path: '/', expires: 2592000});
        }
      },
      setPunchout(state, value) {
        state.punchOutRedirectUrl = value;
        if (isBrowser()) {
          cookieManager.setCookie('punch_out_redirect_url', (value || ''), {path: '/', expires: 2592000});
        }
      }
    }
  };
};
