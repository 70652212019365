(<template>
  <div class="sign-modal">
    <div class="sign-modal__steps">
      <h3 class="sign-modal__title">{{ $gettext('Process:') }}</h3>
      <p v-for="(step, index) in signatureSteps"
         :key="index">
        {{ `${index + 1}. ${step.text}` }}
        <button v-if="step.document"
                class="sk-btn sk-btn--like-link sign-modal__link"
                @click="catchDownload(step.document.id)">{{ step.document.name }}</button>
      </p>
      <div v-if="!userCanUploadDocument"
           class="sign-modal__btns-wrapper">
        <button class="sk-btn sk-btn--default sign-modal__btn"
                @click="cancelSigning">{{ $gettext('OK') }}</button>
      </div>
    </div>
    <div v-if="userCanUploadDocument"
         class="sign-modal__form">
      <p>{{ $gettext('Your name, email, phone and address will be saved along with your personal number and signed document. Please ensure that your profile is up-to-date.') }}</p>
      <div class="sign-modal__inputs">
        <sk-input :description="personalNumberText"
                  :placeholder="personalNumberText"
                  :preselected-value="personalNumber"
                  class="sign-modal__input"
                  autofocus="true"
                  @fieldvaluechanged="setPersonalNumber" />
        <sk-upload-file :id="'docFile'"
                        :button-text="uploadBtnText"
                        :is-single="true"
                        :reset="fileInputReset"
                        class="sign-modal__upload-doc"
                        @changefileinput="catchDoc" />
        <p v-if="fileName">{{ fileName }}</p>
        <sk-checkbox :preselected-value="confirmedData"
                     :validation-name="confirmedDataValidationName"
                     :item-text="confirmText"
                     class="sign-modal__checkbox"
                     @checkboxvaluechanged="catchConfirmedData" />
        <div class="sign-modal__btns-wrapper">
          <button class="sk-btn sk-btn--white sign-modal__btn"
                  @click="cancelSigning">{{ $gettext('Cancel') }}</button>
          <button class="sk-btn sk-btn--default sign-modal__btn"
                  @click="makeSigning">{{ $gettext('Submit') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>)

<script>
  import constants from '@/modules/constants';
  import {mapState, mapMutations, mapActions} from 'vuex';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        fileInputReset: false,
        confirmedData: false,
        confirmedDataValidationName: 'confirmedDataValidation'
      };
    },
    computed: {
      ...mapState('TTSignaturesStore', {
        personalNumber: (state) => state.newSignature.personalNumber,
        file: (state) => state.newSignature.file
      }),
      currentDocument() { return this.data.data.document || {}; },
      isSelfUploadDocument() { return this.currentDocument.selfUpload; },
      userCanUploadDocument() { return this.$isGodmode() || this.isSelfUploadDocument; },
      fileName() { return this.file ? this.file.name : ''; },
      uploadBtnText() { return this.$gettext('Upload file'); },
      signatureSteps() {
        return [{
          text: this.$gettext('Download this file:'),
          document: this.currentDocument
        }, {text: this.$gettext('Read the file and follow the instructions.')}];
      },
      personalNumberText() { return this.$gettext('Personal number'); },
      confirmText() { return this.$gettext('I confirm that the information provided is correct.'); }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      ...mapMutations('TTSignaturesStore', ['setPersonalNumber', 'setNewDocFile', 'clearNewSignature']),
      ...mapActions('TTSignaturesStore', ['uploadSignature', 'getDocForSigning']),
      closeModal() {
        this.$emit('closemodal');
      },
      cancelSigning() {
        this.$emit('closemodal');
        this.clearNewSignature();
      },
      makeSigning() {
        let error = false;
        this.$store.commit('ModalStore/removeModalErrors');
        if (!this.personalNumber.trim()) {
          error = true;
          this.$store.commit('ModalStore/setModalErrors', this.$gettext('Personal number is required'));
        }
        if (!this.file) {
          error = true;
          this.$store.commit('ModalStore/setModalErrors', this.$gettext('Please upload a file'));
        }
        if (!this.confirmedData) {
          error = true;
          this.$store.commit('ErrorsStore/setError', {name: this.confirmedDataValidationName, errors: ['Please confirm']});
        }
        if (!error) {
          this.$emit('startprogress');
          this.uploadSignature(this.currentDocument.id).then(() => {
            this.cancelSigning();
          });
        }
      },
      catchDoc(target) {
        const supportedTypes = [
          'application/pdf',
          'application/msword',
          'image/gif',
          'image/png',
          'image/jpeg'
        ];
        this.$store.commit('ModalStore/removeModalErrors');
        if (target.files && target.files.length) {
          if (!supportedTypes.includes(target.files[0].type)) {
            this.$store.commit('ModalStore/setModalErrors', this.$gettext('Invalid file type.'));
            return;
          }
          if (target.files[0].size > constants.MAX_FILE_SIZE) {
            this.$store.commit('ModalStore/setModalErrors', this.$gettext('File size can\'t be more than 5 megabytes.'));
            return;
          }
          this.setNewDocFile(target.files[0]);
          this.fileInputReset = !this.fileInputReset;
        }
      },
      catchConfirmedData(value) {
        this.confirmedData = value;
      },
      catchDownload(id) {
        this.progressActive = true;
        this.getDocForSigning(id).then((link) => {
          this.progressActive = false;
          window.location.href = link;
        });
      }
    },
    beforeMount() {
      this.$store.commit('ErrorsStore/removeAllErrors');
    }
  };
</script>

<style scoped>
  .sign-modal {
    display: block;
    width: 100%;
  }

  .sign-modal__steps {
    padding: 20px;
    border-bottom: 1px solid #e9ebef;
  }

  .sign-modal__form {
    padding: 20px;
  }

  .sign-modal__title {
    margin-bottom: 20px;
  }

  .sign-modal__btns-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .sign-modal__btn {
    width: auto;
    min-width: 85px;
    padding: 0 20px;
  }

  .sign-modal__btn:last-of-type {
    margin-left: 20px;
  }

  .sign-modal__inputs {
    margin-top: 20px;
  }

  .sign-modal__input {
    width: 50%;
  }

  .sign-modal__checkbox {
    margin-top: 20px;
  }

  .sign-modal__link {
    text-decoration: underline;
  }

  .sign-modal__link:focus {
    box-shadow: var(--default-focus-shadow);
    outline: none;
  }
</style>
