(<template>
  <section :class="{'in-progress': modalProgress}"
           class="create-supplier__main-wrapper is-progress-bar">
    <h4 class="create-supplier__subtitle">{{ $gettext('Personal info') }}</h4>
    <sk-input :description="firstNameText"
              :preselected-value="firstName"
              :margin-bottom="true"
              :placeholder="firstNameText"
              :autofocus="true"
              :validation-name="errors.firstName.name"
              class="create-supplier__half-width"
              @fieldvaluechanged="catchFirstName" />
    <sk-input :description="lastNameText"
              :preselected-value="lastName"
              :margin-bottom="true"
              :placeholder="lastNameText"
              :validation-name="errors.lastName.name"
              class="create-supplier__half-width"
              @fieldvaluechanged="catchLastName" />
    <div class="create-supplier__tel-row">
      <sk-select :type="'countryCodes'"
                 :description="countryCodeText"
                 :default-value="countryCodeText"
                 :preselected-value="telCode"
                 :mob-numeric-keyboard="true"
                 class="create-supplier__code-select"
                 @csvaluechanged="catchTelCode" />
      <sk-input :description="phoneText"
                :preselected-value="telNumber"
                :margin-bottom="true"
                :placeholder="phoneText"
                :type="'tel'"
                :reg-exp="/[^0-9]/g"
                :mob-numeric-keyboard="true"
                class="create-supplier__number-input"
                @fieldvaluechanged="catchTelNumber" />
    </div>
    <component :is="emailComponent"
               :description="emailText"
               :placeholder="emailText"
               :preselected-value="email"
               :type="'email'"
               :validation-name="errors.email.name"
               :submit-after-condition="isValidEmail"
               @startprogress="startProgress"
               @stopprogress="stopProgress"
               @fieldvaluechanged="catchEmail"
               @onsuccess="catchValidEmail"
               @onerror="catchInvalidEmail"
               @onreset="resetEmailErrors" />
    <div class="create-supplier__btns-wrapper">
      <button class="sk-btn sk-btn--white create-supplier__cancel-btn"
              @click="closeModal">{{ $gettext('Cancel') }}</button>
      <div class="create-supplier__right-btns-wrapper">
        <button v-if="!id"
                class="sk-btn sk-btn--white create-supplier__btn"
                @click="validateForm">{{ $gettext('Create') }}</button>
        <button class="sk-btn sk-btn--default create-supplier__btn"
                @click="validateForm({sendPassword: true})">{{ submitBtnText }}</button>
      </div>
    </div>
  </section>
</template>)

<script>
  import InteractiveEmail from '@/components/enterprise/form_parts/InteractiveEmail';
  import Autocomplete from '@/components/shared_components/Autocomplete';

  export default {
    components: {
      'interactive-email': InteractiveEmail,
      autocomplete: Autocomplete,
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        id: this.data?.supplier?.id || '',
        firstName: this.data?.supplier?.firstName || '',
        lastName: this.data?.supplier?.lastName || '',
        email: this.data?.supplier?.email || '',
        telCode: this.data?.supplier?.telCode || '+47',
        telNumber: this.data?.supplier?.telNumber || '',
        errors: {
          firstName: {name: 'firstNameError', errors: []},
          lastName: {name: 'lastNameError', errors: []},
          email: {name: 'emailError', errors: []}
        },
        emailPassServerValidation: false,
        emailIsAvailable: true,
        modalProgress: false
      };
    },
    computed: {
      emailComponent() {
        return this.data.currentSupplier ? 'sk-input' : 'interactive-email';
      },
      canSendEmail() {
        if (this.data.currentSupplier) return true;
        return this.$isGodmode() ? this.emailIsAvailable : this.emailPassServerValidation;
      },
      firstNameText() { return this.$gettext('First name*'); },
      lastNameText() { return this.$gettext('Last name*'); },
      emailText() { return this.$gettext('Email*'); },
      countryCodeText() { return this.$gettext('Country code'); },
      phoneText() { return this.$gettext('Phone number'); },
      submitBtnText() { return this.id ? this.$gettext('Edit') : this.$gettext('Create and send password'); }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      setError({fieldName, errorText}) {
        this.errors[fieldName].errors.push(errorText);
        this.$store.commit('ErrorsStore/setError', {
          name: this.errors[fieldName].name,
          errors: this.errors[fieldName].errors
        });
      },
      removeErrors() {
        for (const key of Object.keys(this.errors)) {
          this.errors[key].errors = [];
        }
        this.$store.commit('ModalStore/removeModalErrors');
        this.$store.commit('ErrorsStore/removeAllErrors');
      },
      startProgress() {
        this.$store.commit('ModalStore/startModalProgress');
      },
      stopProgress() {
        this.$store.commit('ModalStore/stopModalProgress');
      },
      isValidEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },
      catchFirstName(value) {
        this.firstName = value;
      },
      catchLastName(value) {
        this.lastName = value;
      },
      catchEmail(value) {
        this.email = value;
      },
      catchValidEmail() {
        this.$store.commit('ModalStore/removeModalErrors');
        this.emailPassServerValidation = true;
        this.emailIsAvailable = true;
      },
      catchInvalidEmail(error) {
        this.$store.commit('ModalStore/removeModalErrors');
        this.emailPassServerValidation = false;
        this.emailIsAvailable = false;
        if (error.errors?.email?.includes('Invalid email')) {
          this.$store.commit('ModalStore/setModalErrors', this.$gettext('Please check the email address you have provided for spelling errors, and try again.'));
        } else if (error.errors?.email.includes('This email is already taken by buyer')) {
          this.emailIsAvailable = true;
          // TODO: Change to a single error with BE
          this.$store.commit('ModalStore/setModalErrors', this.$gettext('This email address has already been registered'));
        } else if (error.errors?.email.includes('This email is already taken by interpreter')) {
          this.$store.commit('ModalStore/setModalErrors', this.$gettext('This email address has already been registered'));
        } else if (error.errors?.email.includes('This email is already taken by the another enterprise member')) {
          this.$store.commit('ModalStore/setModalErrors', this.$gettext('This email address has already been registered'));
        }
      },
      resetEmailErrors() {
        this.$store.commit('ModalStore/removeModalErrors');
      },
      catchTelCode(value) {
        this.telCode = value;
      },
      catchTelNumber(value) {
        this.telNumber = value;
      },
      closeModal() {
        this.$emit('closemodal');
      },
      validateForm({sendPassword = false}) {
        let isValidForm = true;

        this.removeErrors();
        this.$store.commit('ModalStore/removeModalErrors');
        if (!this.firstName.trim()) {
          isValidForm = false;
          this.setError({
            fieldName: 'firstName',
            errorText: this.$gettext('First name is required')
          });
        }
        if (!this.lastName.trim()) {
          isValidForm = false;
          this.setError({
            fieldName: 'lastName',
            errorText: this.$gettext('Last name is required')
          });
        }
        if (!this.email.trim()) {
          isValidForm = false;
          this.setError({
            fieldName: 'email',
            errorText: this.$gettext('Email is required')
          });
        } else if (!this.isValidEmail(this.email.trim())) {
          isValidForm = false;
          this.setError({
            fieldName: 'email',
            errorText: this.$gettext('Please enter a valid email')
          });
        }

        if (isValidForm) {
          this.submitForm({sendPassword});
        }
      },
      submitForm({sendPassword = false}) {
        const params = {
          id: this.id,
          payload: {
            supplier: {
              firstName: this.firstName.trim(),
              lastName: this.lastName.trim(),
              phoneCode: this.telCode,
              phoneNumber: this.telNumber.trim(),
              email: this.email.trim()
            }
          }
        };
        if (!this.id) params.payload.generateAndSendPassword = sendPassword;
        const action = this.id ? 'AllSuppliersStore/editSupplier' : 'AllSuppliersStore/createSupplier';
        this.modalProgress = true;
        this.$store.dispatch(action, params)
          .then(() => {
            this.modalProgress = false;
            this.closeModal();
            if (this.data.context && this.data.context[this.data.successHandlingCallbackName]) {
              this.data.context[this.data.successHandlingCallbackName]();
            }
          }).catch((error) => {
            this.modalProgress = false;
            this.handleError(error);
          });
      },
      handleError(error) {
        if (error
          && error.data
          && error.data.errors) {
          if (error.data.errors.length
            && (error.data.errors.includes('Email is already registered') || error.data.errors.find((er) => er.includes('is already registered as a Seller.')))) {
            this.setError({
              fieldName: 'email',
              errorText: this.$gettext('This email address has already been registered')
            });
          } else if (error.data.errors.email
            && error.data.errors.email.length
            && error.data.errors.email.includes('Invalid email')) {
            this.setError({
              fieldName: 'email',
              errorText: this.$gettext('Please check the email address you have provided for spelling errors, and try again.')
            });
          }
        } else {
          this.$store.dispatch('ModalStore/closeModal');
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }, 0);
        }
      }
    },
    beforeMount() {
      this.removeErrors();
    },
    beforeDestroy() {
      this.$store.commit('EnterpriseStore/removeEmailQuery');
    }
  };
</script>

<style scoped>
.create-supplier__main-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 35px 30px;
}

.create-supplier__subtitle {
  width: 100%;
  margin-bottom: 12px;
}

.create-supplier__half-width {
  width: calc(50% - 10px);
}

.create-supplier__tel-row {
  display: flex;
  width: 100%;
}

.create-supplier__code-select {
  flex-shrink: 0;
  width: 85px;
  margin-right: 10px;
}

.create-supplier__number-input {
  width: calc(100% - 95px);
}

.create-supplier__btns-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.create-supplier__right-btns-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}

.create-supplier__btn {
  width: auto;
  margin: 20px 5px 0;
  padding: 0 20px;
}

.create-supplier__cancel-btn {
  max-width: max-content;
  margin: 20px 5px 0;
  padding: 0 20px;
  border: none !important;
}

@media (max-width: 767px) {
  .create-supplier__main-wrapper {
    padding: 15px;
  }
}
</style>
