// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/salita/job_grid/time_icon_orange.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/salita/job_grid/reverted_back_icon_green.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.language-pair[data-v-f62e8a70] {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  height: 100%;\n}\n.language-pair__first-row[data-v-f62e8a70] {\n  display: -webkit-inline-box;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n}\n.language-pair__to-lang[data-v-f62e8a70] {\n  overflow: hidden;\n  max-width: 80%;\n  text-overflow: ellipsis;\n}\n.language-pair__processing-icon[data-v-f62e8a70] {\n  width: 15px;\n  height: 15px;\n  margin-top: 5px;\n  margin-left: 5px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  line-height: 15px;\n  cursor: pointer;\n}\n.language-pair__processing--activated[data-v-f62e8a70] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.language-pair__from-lang[data-v-f62e8a70] {\n  color: #555;\n}\n", ""]);
// Exports
module.exports = exports;
