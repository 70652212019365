(<template>
  <sk-checkbox :preselected-value="preselectedValue"
               :validation-name="validationName"
               :item-text="termsText"
               class="terms-check"
               @checkboxvaluechanged="catchValueChange" />
</template>)

<script>
  export default {
    props: {
      preselectedValue: {
        type: [String, Number, Boolean],
        default: ''
      },
      validationName: {
        type: String,
        default: ''
      },
      itemText: {
        type: String,
        default: ''
      }
    },
    computed: {
      termsText() {
        if (this.itemText) return this.itemText;
        const template = this.$gettext('I accept %{domain}\'s <a class="sk-link" href="%{termsLink}" target="_blank">terms and conditions</a>');
        return this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName'), termsLink: this.$getString('domainData', 'termLink')});
      }
    },
    methods: {
      catchValueChange(value) {
        this.$emit('checkboxvaluechanged', value);
      }
    }
  };
</script>
