export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {preferences: ''},
    actions: {
      fetchPreferences({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.fetchPreferences().then((data) => {
            commit('setPreferences', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      savePreferences({commit}, prefList) {
        const params = {notification_preference: prefList};
        return new Promise((resolve, reject) => {
          fetchData.savePreferences(params).then((data) => {
            commit('setPreferences', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      }
    },
    mutations: {
      setPreferences(state, data) {
        state.preferences = data;
      },
      setChangedPreference(state, {key, value, name}) {
        state.preferences.find((element) => {
          if (element.key == key) {
            return value ? element.turnedOnPlatforms.push(name) : element.turnedOnPlatforms.splice(element.turnedOnPlatforms.indexOf(name), 1);
          }
        });
      },
      clearStore(state) {
        state.preferences = '';
      }
    }
  };
};
