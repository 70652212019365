(<template>
  <article class="outdated_client_modal">
    <p class="outdated_client_modal__main-text">{{ $gettext('A new version is available.') }}</p>
    <button class="sk-btn sk-btn--default"
            @click="refreshPage">{{ $gettext('Get the new version') }}</button>
  </article>
</template>)

<script>
  export default {
    methods: {
      refreshPage() {
        window.location.reload();
      }
    },
    mounted() {
      const skModalCloseBtn = document.querySelector('.sk-modal__close-btn');
      if (skModalCloseBtn) skModalCloseBtn.addEventListener('click', this.refreshPage);
    }
  };
</script>

<style scoped>
  .outdated_client_modal {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: -15px;
    padding: 45px 40px 30px;
    background-color: #fff;
    text-align: center;
  }

  .outdated_client_modal__main-text {
    margin-bottom: 20px;
    font-size: 25px;
  }

  .outdated_client_modal__secondary-text {
    margin-bottom: 25px;
    font-size: 16px;
  }
</style>
