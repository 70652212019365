export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      suitableInterpreters: [],
      suitableInterpretersIds: [],
      interpreterShortInfo: ''
    },
    actions: {
      getSuitableInterpretersIds({commit, rootState}, {jobId}) {
        return new Promise((resolve, reject) => {
          const filterParams = {};
          if (rootState.TTAuthStore.godmode) {
            const {qualification, gender, distance, showAll} = rootState.FAIFiltersStore;
            if (qualification !== 'all') filterParams.suitable_qualification = true;
            if (gender !== 'all') filterParams.suitable_gender = true;
            if (distance !== 'all') filterParams.max_distance_meters = +distance * 1000;
            filterParams.show_all = showAll;
          }
          fetchData.getSuitableInterpreters(jobId, filterParams)
            .then((data) => {
              if (data.code == 401) {
                commit('TTAuthStore/logOut', '', {root: true});
                commit('GlobalStore/remove404Page', '', {root: true});
                resolve('LOGOUT');
                return;
              }
              if (data.code == 404) {
                commit('GlobalStore/set404Page', '', {root: true});
                resolve();
                return;
              }
              commit('setSuitableInterpretersIds', data.suitableInterpreters);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      getSuitableInterpretersIdsByName({commit, rootState, rootGetters}, {jobId}) {
        return new Promise((resolve, reject) => {
          const filterParams = {};
          if (rootState.TTAuthStore.godmode || rootGetters['UserInfoStore/userIsManager']) {
            const {name} = rootState.FAIFiltersStore;
            filterParams.name = name;
          }
          fetchData.getSuitableInterpreters(jobId, filterParams)
            .then((data) => {
              if (data.code == 401) {
                commit('TTAuthStore/logOut', '', {root: true});
                commit('GlobalStore/remove404Page', '', {root: true});
                resolve('LOGOUT');
                return;
              }
              if (data.code == 404) {
                commit('GlobalStore/set404Page', '', {root: true});
                resolve();
                return;
              }
              commit('setSuitableInterpretersIds', data.suitableInterpreters);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      getAutoInvitableInterpreters({commit}, {jobId}) {
        return new Promise((resolve, reject) => {
          fetchData.getAutoInvitableInterpreters(jobId)
            .then((data) => {
              if (data.code == 401) {
                commit('TTAuthStore/logOut', '', {root: true});
                commit('GlobalStore/remove404Page', '', {root: true});
                resolve('LOGOUT');
                return;
              }
              if (data.code == 404) {
                commit('GlobalStore/set404Page', '', {root: true});
                resolve();
                return;
              }
              commit('setSuitableInterpretersIds', data.autoInvitableInterpreters);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      getSuitableNotInvitedInterpreters({commit}, {jobId}) {
        return new Promise((resolve, reject) => {
          fetchData.getSuitableNotInvitedInterpreters(jobId)
            .then((data) => {
              if (data.code == 401) {
                commit('TTAuthStore/logOut', '', {root: true});
                commit('GlobalStore/remove404Page', '', {root: true});
                resolve('LOGOUT');
                return;
              }
              if (data.code == 404) {
                commit('GlobalStore/set404Page', '', {root: true});
                resolve();
                return;
              }
              commit('setSuitableInterpretersIds', data.suitableInterpreters);
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      },
      getSuitableInterpretersList({dispatch, commit}, {jobId, interpretersList = []}) {
        return new Promise((resolve, reject) => {
          const promisesArr = [];

          interpretersList.forEach((interId) => {
            promisesArr.push(dispatch('getSuitableInterpreterById', {jobId, interId}));
          });
          Promise.all(promisesArr)
            .then((data) => {
              commit('clearSuitableInterpreters');
              commit('setSuitableInterpreters', data);
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      getSuitableInterpreterById({commit}, {jobId, interId}) {
        return new Promise((resolve, reject) => {
          fetchData.getSuitableInterpreterById(jobId, interId)
            .then((data) => {
              if (data.code == 401) {
                commit('TTAuthStore/logOut', '', {root: true});
                commit('GlobalStore/remove404Page', '', {root: true});
                resolve('LOGOUT');
                return;
              }
              resolve(data.interpreter);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      refetchSuitableInterpreter({dispatch, commit}, {jobId, interId}) {
        return new Promise((resolve, reject) => {
          dispatch('getSuitableInterpreterById', {jobId, interId})
            .then((interpreterData) => {
              commit('setSuitableInterpreter', interpreterData);
              resolve(interpreterData);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      sendInvite({dispatch}, {jobId, id: interId, headers}) {
        return new Promise((resolve, reject) => {
          fetchData.sendInvite(jobId, interId, headers)
            .then(() => {
              dispatch('refetchSuitableInterpreter', {jobId, interId})
                .then((interpreterData) => {
                  resolve(interpreterData);
                })
                .catch((error) => {
                  reject(error);
                });
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      declineInvite({dispatch}, {interpreterUid, jobId, headers}) {
        return new Promise((resolve, reject) => {
          fetchData.makeDeclineInvite(jobId, interpreterUid, headers)
            .then(() => {
              dispatch('refetchSuitableInterpreter', {jobId, interId: interpreterUid})
                .then((interpreterData) => {
                  resolve(interpreterData);
                })
                .catch((error) => {
                  reject(error);
                });
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      applyAsInterpreter({commit}, {jobId, interpreterUid, force, headers}) {
        return new Promise((resolve, reject) => {
          fetchData.makeApplyAsInterpreter(jobId, interpreterUid, force, headers)
            .then((data) => {
              commit('OneAssignmentStore/setJobStatus', 'applied', {root: true});
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      sendChangeAutoInviteDelay({commit}, {jobId, delay, headers}) {
        return new Promise((resolve, reject) => {
          fetchData.sendChangeAutoInviteDelay(jobId, {delay}, headers)
            .then((data) => {
              commit('OneAssignmentStore/setAutoInviteDelay', delay, {root: true});
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      changeInterpreterRating(context, {jobId, params}) {
        return new Promise((resolve, reject) => {
          fetchData.changeInterpreterRating(jobId, params)
            .then((data) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      blockInterpreter({dispatch, commit}, {jobId, interpreterUid, headers}) {
        return new Promise((resolve, reject) => {
          fetchData.makeJobBlockInterpreter(jobId, interpreterUid, headers)
            .then((data) => {
              dispatch('refetchSuitableInterpreter', {jobId, interId: interpreterUid})
                .then((interpreterData) => {
                  commit('setSuitableInterpreter', data);
                  resolve(interpreterData);
                })
                .catch((error) => {
                  reject(error);
                });
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      getInterpreterShortInfo({commit}, uid) {
        return new Promise((resolve, reject) => {
          fetchData.getInterpreterShortInfo(uid)
            .then((data) => {
              commit('setInterpreterShortInfo', data.interpreter);
              resolve(data.interpreter);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      addInterpreterShortInfo({commit}, {uid, params}) {
        return new Promise((resolve, reject) => {
          fetchData.addInterpreterShortInfo(uid, params)
            .then((data) => {
              commit('addInterpreterNote', data.note);
              resolve(data.note);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
    },
    mutations: {
      setSuitableInterpreters(state, data) {
        state.suitableInterpreters = data;
      },
      setSuitableInterpretersIds(state, data) {
        state.suitableInterpretersIds = data;
      },
      setSuitableInterpreter(state, interpreterData) {
        state.suitableInterpreters = state.suitableInterpreters.map((interpreter) => {
          return interpreter.id == interpreterData.id ? interpreterData : interpreter;
        });
      },
      setInterpreterShortInfo(state, data) {
        state.interpreterShortInfo = data;
      },
      addInterpreterNote(state, data) {
        const notes = state.interpreterShortInfo?.notes || [];

        notes.push(data);
        state.interpreterShortInfo = {
          ...state.interpreterShortInfo,
          ...{
            notes
          }
        };
      },
      clearSuitableInterpreters(state) {
        state.suitableInterpreters = [];
      },
      clearStore(state) {
        state.suitableInterpreters = [];
        state.suitableInterpretersIds = [];
      }
    }
  };
};
