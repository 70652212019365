<template>
  <div class="active-job-confirm">
    <p class="active-job-confirm__text">{{ $gettext('If you change your phone number we will send this to all your confirmed assignments. Since your old number has already been shared with your customers, some of them may still try to ring your old number.') }}</p>
    <p class="active-job-confirm__subtext">{{ subText }}</p>
    <div class="active-job-confirm__btn-cont">
      <button class="sk-btn sk-btn--white active-job-confirm__btn active-job-confirm__btn--cancel-btn"
              @click="closeModal">{{ $gettext('Cancel') }}</button>
      <button class="sk-btn sk-btn--white active-job-confirm__btn active-job-confirm__btn--ok-btn"
              @click="acceptWarning">{{ $gettext('Change phone number') }}</button>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      modalData() {
        return this.data;
      },
      subText() {
        return this.$gettext('Please contact platform support if a customer does not call you for your assignment. They may be trying to reach you on your old number.');
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      acceptWarning() {
        this.closeModal();
        this.modalData.context.openPhoneModal();
      },
      closeModal() {
        this.$emit('closemodal');
      }
    }
  };
</script>

<style scoped>
  .active-job-confirm {
    padding: 20px;
  }

  .active-job-confirm__subtext {
    margin-top: 10px;
    font-weight: bold;
  }

  .active-job-confirm__btn-cont {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
  }

  .active-job-confirm__btn {
    width: auto;
    margin-left: 15px;
    padding: 0 25px;
  }
</style>
