import { render, staticRenderFns } from "./SeveralSuppliersSection.vue?vue&type=template&id=489ba157&scoped=true&"
import script from "./SeveralSuppliersSection.vue?vue&type=script&lang=js&"
export * from "./SeveralSuppliersSection.vue?vue&type=script&lang=js&"
import style0 from "./SeveralSuppliersSection.vue?vue&type=style&index=0&id=489ba157&prod&lang=css&"
import style1 from "./SeveralSuppliersSection.vue?vue&type=style&index=1&id=489ba157&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "489ba157",
  null
  
)

export default component.exports