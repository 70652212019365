<template>
  <div ref="basePopoverContent"
       class="base-popover">
    <slot></slot>
  </div>
</template>

<script>
  import Popper from 'popper.js';

  const QUICK_ANIMATION_DURATION = 100;

  export default {
    name: 'base-popover',

    props: {
      popoverOptions: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        popperInstance: null
      };
    },
    computed: {
      placement() { return this.popoverOptions?.placement || 'top'; },
      offset() { return this.popoverOptions?.offset || '0,0'; },
    },

    methods: {
      getXValueFromTranslate3d(translateString) {
        const n = translateString.indexOf('(');
        const n1 = translateString.indexOf(',');

        return parseInt(translateString.slice(n + 1, n1 - 2));
      },

      getYValueFromTranslate3d(translateString) {
        const n = translateString.indexOf(',');
        const n1 = translateString.indexOf(')');

        return parseInt(translateString.slice(n + 1, n1 - 1));
      },

      clickOutside() {
        this.destroyPopover();
      },

      initPopper() {
        const modifiers = {};
        const {popoverReference} = this.popoverOptions;

        if (this.offset) {
          modifiers.offset = {
            offset: this.offset
          };
        }

        if (this.placement) {
          modifiers.placement = this.placement;
        }

        this.popperInstance = new Popper(
          popoverReference,
          this.$refs.basePopoverContent,
          {
            placement: this.placement,
            modifiers: {
              ...modifiers,
              preventOverflow: {
                boundariesElement: 'viewport'
              }
            },
            onCreate() {}
          }
        );
      },

      destroyPopover() {
        setTimeout(() => {
          if (this.popperInstance) {
            this.popperInstance.destroy();
            this.popperInstance = null;
            this.$emit('closePopover');
          }
        }, QUICK_ANIMATION_DURATION);
      }
    },

    mounted() {
      this.initPopper();
    }
  };
</script>

<style scoped>
.base-popover {
  position: relative;
  z-index: 50;
}

.base-popover__content {
  display: flex;
  flex-direction: column;
}
</style>
