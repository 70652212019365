<script>
  import {mapActions, mapState} from 'vuex';
  import helpers from '@/helpers';
  import PostingTogglePrototype from '@/prototypes/PostingTogglePrototype';

  export default {
    extends: PostingTogglePrototype,
    computed: {
      ...mapState('OneAssignmentStore', {
        processRequirement: ({storeJobObj}) => storeJobObj.processRequirement || {},
        subject: ({storeJobObj}) => storeJobObj.info?.subject || '',
        extraInfo: ({storeJobObj}) => storeJobObj.info?.description || ''
      }),
      toggleValue() { return this.processRequirement.standbyProcessing === 'turned_on'; },
      toggleDescription() { return this.$gettext('StandBy'); },
      disabledToggle() {
        return helpers.getJobInfo.disabledStandBy([this.subject, this.extraInfo]);
      },
      statusError() {
        return this.disabledToggle;
      },
      statusErrorText() {
        return helpers.getJobInfo.disabledStandByText(this);
      }
    },
    methods: {
      ...mapActions('OneAssignmentStore', ['updateStandBy']),
      toggleHandler(value) {
        this.progressActive = true;
        this.updateStandBy(value).then(() => {
          this.progressActive = false;
        }).catch(() => {
          this.progressActive = false;
        });
      }
    }
  };
</script>
