<template>
  <div :class="{'in-progress': progressActive}"
       class="fai-invite-system is-progress-bar">
    <p class="fai-invite-system__description">{{ invitationDelayText }}</p>
    <div class="fai-invite-system__select"
         @click.prevent.stop="clickInviteSystem">
      <sk-select :default-value="invitationDelayText"
                 :no-description="true"
                 :items-list="invitationTimeList"
                 :preselected-value="invitationDelay"
                 @csvaluechanged="changeInvitationDelay" />
    </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex';

  export default {
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        invitationDelay: ({storeJobObj}) => storeJobObj?.processRequirement?.invitationDelay || 'magic'
      }),
      ...mapState('OneAssignmentStore', {
        storeJobObj: (state) => state.storeJobObj || {},
      }),
      ...mapGetters('EnterpriseStore', ['getOwnerUid']),
      jobId() { return this.$route.params.id || this.storeJobObj.id || ''; },
      invitationTimeList() {
        return [{
          id: 'magic',
          name: this.$gettext('Magic')
        }, {
          id: 2,
          name: this.$gettext('2 min')
        }, {
          id: 5,
          name: this.$gettext('5 min')
        }, {
          id: 10,
          name: this.$gettext('10 min')
        }, {
          id: 20,
          name: this.$gettext('20 min')
        }, {
          id: 60,
          name: this.$gettext('60 min')
        }, {
          id: 120,
          name: this.$gettext('120 min')
        }];
      },
      invitationDelayText() { return this.$gettext('Invite system:'); }
    },
    methods: {
      ...mapActions('FAIListStore', [
        'sendChangeAutoInviteDelay'
      ]),
      changeInvitationDelay(value) {
        const delay = value === 'magic' ? '' : value;
        this.progressActive = true;
        this.sendChangeAutoInviteDelay({jobId: this.jobId, delay, headers: {AuthImpersonationUID: this.getOwnerUid}}).then(() => {
          this.progressActive = false;
        }).catch(() => {
          this.progressActive = false;
        });
      },
      clickInviteSystem() {
        this.$emit('clickinvitesystem');
      }
    }
  };
</script>

<style>
  .fai-invite-system__select .sk-select {
    background-color: #fff;
  }
</style>

<style scoped>
  .fai-invite-system {
    display: flex;
    align-items: baseline;
  }

  .fai-invite-system__select {
    width: 100px;
    margin-left: 5px;
  }
</style>
