(<template>
  <article class="hotline-info-modal">
    <h2 class="hotline-info-modal__title">{{ $gettext('Hotline availability') }}</h2>
    <div class="hotline-info-modal__content">
      <p class="hotline-info-modal__text">{{ descriptionText }}</p>
      <a href="tel:+4721999789"
         class="sk-link hotline-info-modal__phone">+47 21 999 789</a>

      <p v-if="userIsInterpreter"
         class="hotline-info-modal__text">{{ infoModalText }}</p>

      <div class="hotline-info-modal__price-list-cont">
        <table class="hotline-info-modal__price-list">
          <tr v-for="(row, index) in genPriceList(priceListData)"
              :key="index"
              :class="[row.classList]"
              class="hotline-info-modal__price-row">
            <component :is="row.element || 'td'"
                       v-for="(cell, cellIndex) in row.rowData"
                       :key="cellIndex"
                       class="hotline-info-modal__price-cell"
                       v-html="cell.text" />
          </tr>
        </table>
      </div>

      <div v-if="userIsInterpreter"
           class="hotline-info-modal__additional-info">
        <p>{{ noteText1 }}</p>
        <p>{{ noteText2 }}</p>
        <p>{{ noteText3 }}</p>
      </div>
    </div>
  </article>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter'
      ]),
      currency() { return this.$gettext('NOK'); },
      descriptionText() {
        return this.$gettext('The Hotline service is a single phone number for Norwegian-Arabic interpreters.');
      },
      infoModalText() {
        return this.$gettext('Our fixed price customers will call this number and if you are available we will call you. Save our hotline number on your phone, so you know it\'s a hotline call.');
      },
      noteText1() { return this.$gettext('* The minimum fixed price subscription on the platform is 450kr per hour'); },
      noteText2() { return this.$gettext('** You will be paid based on the contract we have with our customers.'); },
      noteText3() { return this.$gettext('All prices are shown on our website.'); },
      priceListData() {
        return [
          {
            element: 'th',
            classList: 'hotline-info-modal__price-row--header',
            rowData: [
              this.$gettext('Call duration'),
              this.$gettext('Fee'),
              this.$gettext('Customer pays'),
              this.userIsInterpreter ? this.$gettext('You will receive') : this.$gettext('Interpreter fee')
            ]
          },
          {rowData: [this.$gettext('0 to 2 min'), '', 0, 0]},
          {rowData: [this.$gettext('2 to 35 min'), this.$gettext('Minimum fee'), 225, 175]},
          {rowData: [this.$gettext('After 35 min'), this.$gettext('Every 1/2 h'), 225, 175]}
        ];
      }
    },
    methods: {
      genPriceText(price) { return `${price.toFixed(2)} ${this.currency}`; },
      genCellData(data) {
        return data.map((item) => {
          return {text: !Number.isNaN(+item) && String(item) ? this.genPriceText(item) : item};
        });
      },
      genPriceList(data) {
        return data.map((item) => {
          item.rowData = this.genCellData(item.rowData);
          return item;
        });
      }
    }
  };
</script>

<style scoped>
  .hotline-info-modal {
    position: relative;
    margin-top: -10px;
    padding: 30px 25px;
    text-align: center;
  }

  .hotline-info-modal__content {
    font-size: 12px;
  }

  .hotline-info-modal__title {
    margin-bottom: 10px;
    font-size: 18px;
  }

  .hotline-info-modal__text {
    margin-bottom: 20px;
  }

  .hotline-info-modal__phone {
    margin-top: -20px;
    margin-bottom: 20px;
    font-weight: bold;
    white-space: nowrap;
  }

  .hotline-info-modal__price-list-cont {
    margin-bottom: 20px;
  }

  .hotline-info-modal__price-list-cont:last-child {
    margin-bottom: 0;
  }

  .hotline-info-modal__price-list {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    border-collapse: collapse;
    font-size: 10px;
  }

  .hotline-info-modal__price-list.is-hidden-price {
    max-width: 260px;
  }

  .hotline-info-modal__price-row {
    background-color: #f4f5f7;
    text-align: left;
  }

  .hotline-info-modal__price-row--header {
    background-color: #fff;
  }

  .hotline-info-modal__price-row--header th {
    padding: 2px 10px;
  }

  .hotline-info-modal__price-cell {
    padding: 5px 10px;
  }

  .hotline-info-modal__price-cell:nth-child(3),
  .hotline-info-modal__price-cell:nth-child(4) {
    text-align: right;
  }

  .is-hidden-price .hotline-info-modal__price-cell:nth-child(3),
  .is-hidden-price .hotline-info-modal__price-cell:nth-child(4) {
    display: none;
  }

  .hotline-info-modal__additional-info {
    color: #a7abbd;
    font-size: 10px;
  }

  @media (max-width: 767px) {
    .hotline-info-modal {
      padding: 30px 0;
    }

    .hotline-info-modal__text,
    .hotline-info-modal__phone,
    .hotline-info-modal__additional-info {
      padding: 0 15px;
    }

    .hotline-info-modal__price-cell {
      padding: 5px;
    }

    .hotline-info-modal__price-cell:first-child {
      padding-left: 10px;
    }

    .hotline-info-modal__price-cell:last-child {
      padding-right: 10px;
    }

    .hotline-info-modal__price-cell:nth-child(3),
    .hotline-info-modal__price-cell:nth-child(4) {
      width: 75px;
    }
  }
</style>
