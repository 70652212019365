import axios from 'axios';
import constants from '@/modules/constants';

const paymentMethods = {
  methods: {
    // ==================================================
    // ==================================================
    // PAYMENTS
    // ==================================================
    // ==================================================
    getEnterprisePayments(enterpriseId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/enterprises/${enterpriseId}/payment_methods`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getBuyerPayments(uid) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/buyers/${uid}/payment_methods`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    createEnterprisePayment(enterpriseId, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/enterprises/${enterpriseId}/payment_methods`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    createBuyerPayment(uid, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/buyers/${uid}/payment_methods`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    updateEnterprisePayment(enterpriseId, paymentId, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/enterprises/${enterpriseId}/payment_methods/${paymentId}`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    updateBuyerPayment(uid, paymentId, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/buyers/${uid}/payment_methods/${paymentId}`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    deleteEnterprisePayment(enterpriseId, paymentId, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.delete(`/v1/enterprises/${enterpriseId}/payment_methods/${paymentId}`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    deleteBuyerPayment(uid, paymentId, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.delete(`/v1/buyers/${uid}/payment_methods/${paymentId}`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getInvoices(id, params) {
      return new Promise((resolve, reject) => {
        this.financeAjax.get('invoices', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
        // setTimeout(() => {
        //   resolve(faker.invoices);
        // }, 1500);
      });
    },
    getCustomerInvoices(enterpriseId, params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/enterprises/${enterpriseId}/invoices?`, {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    downloadInvoices(enterpriseId, params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/enterprises/${enterpriseId}/invoices.csv?`, {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    // ==================================================
    // ==================================================
    // INTERPRETERS PAYOUT PREFERENCES CALLS
    // ==================================================
    // ==================================================
    getCountryCode(country) {
      return new Promise((resolve) => {
        axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${country}&key=${constants.GOOGLE_KEY}`).then((response) => {
          if (response?.data?.status == 'OK') {
            resolve(response?.data?.results[0]?.address_components[0]?.short_name);
          } else {
            resolve({code: 'ZERO_RESULTS'});
          }
        }).catch(() => {
          resolve({code: 'ZERO_RESULTS'});
        });
      });
    },
    postPayoutMethod(type, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.financeAjax.post(`payout_methods/${type}`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    postAccountDetails(data) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.financeAjax.post('dipps/freelance_profiles', data).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    // getAccountDetails() {
    //   const self = this;
    //
    //   return new Promise((resolve, reject) => {
    //     self.financeAjax.get('dipps/freelance_profiles').then((response) => {
    //       resolve(response?.data);
    //     }).catch((error) => {
    //       if (error?.status == 404) {
    //         resolve({code: 404});
    //         return;
    //       }
    //       reject(error);
    //     });
    //   });
    // },
    // updateAccountDetails(id, data) {
    //   const self = this;
    //
    //   return new Promise((resolve, reject) => {
    //     self.financeAjax.put(`dipps/freelance_profiles/${id}`, data).then((response) => {
    //       resolve(response);
    //     }).catch((error) => {
    //       reject(error?.data || {});
    //     });
    //   });
    // },
    // signUpDipps() {
    //   const self = this;
    //
    //   return new Promise((resolve, reject) => {
    //     self.ajax.post('/v1/finances/dipps/workers').then((response) => {
    //       resolve(response);
    //     }).catch((error) => {
    //       reject(error);
    //     });
    //   });
    // },
  }
};

export default paymentMethods;
