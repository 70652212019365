(<template>
  <div :class="{'person-table--direct': !showAutoInvite}"
       class="person-table">
    <one-person v-for="inter in interpretersList"
                :key="inter.uid"
                :interpreter="inter"
                :show-auto-invite="showAutoInvite"
                class="person-table__person"
                @invite="inviteInterpreter"
                @applyasinterpreter="applyAsInterpreter"
                @declineinvite="declineInvite"
                @blockinterpreter="blockInterpreter" />
    <div v-if="!interpretersList.length"
         class="person-table__no-inters-cont">
      <p class="person-table__no-inters-text">{{ $gettext('We are now looking for the best interpreter for your assignment, and will contact you by email.') }}</p>
    </div>
  </div>
</template>)

<script>
  import OnePerson
    from '@/components/assignment_components/one_assignment/interactive_info/invite_suppliers/InterpreterForFAIBlock';

  export default {
    components: {
      'one-person': OnePerson
    },
    props: {
      interpretersList: {
        type: Array,
        required: true
      },
      showAutoInvite: Boolean
    },
    methods: {
      inviteInterpreter(interpreterUid, invitesCount) {
        this.$emit('invite', interpreterUid, invitesCount);
      },
      applyAsInterpreter(interpreterUid, interpreterId, interpreterName) {
        this.$emit('applyasinterpreter', interpreterUid, interpreterId, interpreterName);
      },
      declineInvite(declineObj) {
        this.$emit('declineinvite', declineObj);
      },
      blockInterpreter(blockObj) {
        this.$emit('blockinterpreter', blockObj);
      },
    }
  };
</script>

<style scoped>
  .person-table {
    display: flex;
    overflow: auto;
  }

  .person-table--direct {
    overflow: unset;
  }

  .person-table__person {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    min-width: 230px;
    margin-right: 10px;
    color: inherit;
    text-decoration: none;
  }

  .person-table__no-inters-cont {
    display: block;
    width: 100%;
    padding: 30px 30px 15px;
    font-weight: lighter;
    font-size: 16px;
    text-align: center;
  }

  .person-table__no-inters-text {
    padding-bottom: 15px;
  }
</style>
