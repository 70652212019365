(<template>
  <div class="step-1">
    <div class="step-1__block-wrapper">
      <div class="step-1__block">
        <p>{{ haveCompanyText }}</p>
        <ul class="payout-block__list">
          <li v-for="(item, index) in payoutPartnerDescItems"
              :key="index"
              class="desc-list__item">{{ item }}</li>
        </ul>
        <button class="sk-btn sk-btn--default step-1__btn"
                autofocus="true"
                @click="setSelection(true)">{{ $gettext('Choose') }}</button>
      </div>
      <div class="step-1__block">
        <p>{{ withoutCompanyText }}</p>
        <ul class="payout-block__list">
          <li v-for="(item, index) in payoutPartnerDescItems"
              :key="index"
              class="desc-list__item">{{ item }}</li>
        </ul>
        <button class="sk-btn sk-btn--default step-1__btn"
                @click="setSelection(false)">{{ $gettext('Choose') }}</button>
      </div>
    </div>
  </div>
</template>)

<script>
  import {mapMutations} from 'vuex';

  export default {
    computed: {
      payoutPartnerDescItems() {
        return [
          this.$gettext('Salita pays PayoutPartner on your behalf'),
          this.$gettext('You get this for free'),
        ];
      },
      haveCompanyText() {
        return this.$gettext('I have my own proprietorship, and want PayoutPartner to generate my invoices for me.');
      },
      withoutCompanyText() {
        return this.$gettext('I don\'t have an proprietorship and need PayoutPartner to provide a salary service to me.');
      }
    },
    methods: {
      ...mapMutations('TTPayoutStore', ['setUserHasCompany']),
      setSelection(value) {
        this.setUserHasCompany(value);
        this.$emit('gotonext');
      }
    }
  };
</script>

<style scoped>
  .step-1__block {
    margin-top: 20px;
  }

  .step-1__btn {
    max-width: 170px;
    margin-left: auto;
    padding: 0 10px;
    line-height: 1.43;
    white-space: normal;
  }

  .payout-block__list {
    padding-left: 30px;
  }

  @media (max-width: 500px) {
    .step-1__btn {
      max-width: 100%;
    }
  }
</style>
