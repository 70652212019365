export default () => {
  return {
    namespaced: true,
    state: {
      clientName: '',
      clientId: ''
    },
    actions: {
      setName({commit}, payload) {
        commit('setClientName', payload);
      },
      setId({commit}, payload) {
        commit('setClientId', payload);
      }
    },
    mutations: {
      setClientName(state, value) {
        state.clientName = value;
      },
      setClientId(state, value) {
        state.clientId = value;
      },
      clearStore(state) {
        state.clientName = '';
        state.clientId = '';
      }
    }
  };
};
