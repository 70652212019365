import { render, staticRenderFns } from "./InviteEmail.vue?vue&type=template&id=87f5e940&scoped=true&"
import script from "./InviteEmail.vue?vue&type=script&lang=js&"
export * from "./InviteEmail.vue?vue&type=script&lang=js&"
import style0 from "./InviteEmail.vue?vue&type=style&index=0&id=87f5e940&prod&lang=css&"
import style1 from "@assets/css/participants_section.css?vue&type=style&index=1&id=87f5e940&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87f5e940",
  null
  
)

export default component.exports