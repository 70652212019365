const isNotFlatDistanceAmount = (pricingData) => {
  const travelDistance = pricingData.travelDistance || 0;
  const minTravelDistance = pricingData.travelFlatAmountThresholdKm || 0;
  return minTravelDistance ? travelDistance > minTravelDistance : true;
};

const isNotFlatDurationAmount = (pricingData) => {
  const travelDuration = pricingData.travelDuration || 0;
  const minTravelDuration = pricingData.travelFlatAmountThresholdHours || 0;
  return minTravelDuration ? travelDuration > minTravelDuration : true;
};

const isNotFlatAmount = (pricingData) => {
  const distanceCheck = isNotFlatDistanceAmount(pricingData);
  const durationCheck = isNotFlatDurationAmount(pricingData);
  return distanceCheck && durationCheck;
};
export default {
  isNotFlatAmount,
  isNotFlatDistanceAmount,
  isNotFlatDurationAmount
};
