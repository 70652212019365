(<template>
  <div class="payment-methods_types">
    <add-invoice-tile v-for="method in paymentMethodTypes"
                      :key="method.key"
                      :autofocus="method.autofocus"
                      class="payment-method_type"
                      :title="method.title"
                      :button-text="method.buttonText"
                      :type="method.type"
                      @add="openAddInvoiceDialog(method)" />
  </div>
</template>)

<script>
  import AddInvoiceTile from '@/components/payments_components/payment_tiles/AddInvoiceTile';

  export default {
    name: 'payment-method-option-modal',
    components: {
      'add-invoice-tile': AddInvoiceTile
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      organizationPayment() { return this.$gettext('Norwegian Organization'); },
      emailPayment() { return this.$gettext('Private Email'); },
      addEHF() { return this.$gettext('Add EHF'); },
      addEmail() { return this.$gettext('Add Email'); },
      paymentMethodTypes() {
        return [
          {
            title: this.organizationPayment,
            type: 'ehf',
            key: Math.random(),
            buttonText: this.addEHF,
            autofocus: true
          },
          {
            title: this.organizationPayment,
            type: 'organization',
            key: Math.random(),
            buttonText: this.addEmail
          },
          {
            title: this.emailPayment,
            type: 'email',
            key: Math.random(),
            buttonText: this.addEmail
          }
        ];
      },
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      openAddInvoiceDialog(method) {
        const isEmailInvoice = (method.type === 'email');
        this.$store.commit('ModalStore/setModal', {
          component: 'add-invoice-modal',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: isEmailInvoice ? this.$gettext('Add private email') : this.$gettext('Add an organization number'),
            type: method.type,
            context: this.data?.context || this,
            successHandlingCallback: this.data?.successHandlingCallback
          }
        });
      },
      closeModal() {
        this.$emit('closemodal');
      }
    }
  };
</script>

<style scoped>
.payment-methods_types {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
}

.payment-method_type {
  margin-top: 20px;
  margin-right: 20px;
}
</style>
