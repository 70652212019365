(<template>
  <article class="dialects-modal">
    <h2 class="dialects-modal__title">{{ $gettext('Dialects') }}</h2>
    <p class="dialects-modal__description">{{ descriptionTextFirst }}</p>
    <p class="dialects-modal__description">{{ descriptionTextSecond }}</p>
    <sk-select :items-list="countriesList"
               :description="dialectToText"
               :default-value="dialectToSelectPlace"
               :preselected-value="dialectTo"
               :validation-name="dialectToErrorsName"
               @csvaluechanged="setDialectTo" />
    <p class="dialects-modal__email-text"
       v-html="supportText"></p>
    <div class="dialects-modal__btn-cont">
      <button class="sk-btn sk-btn--white dialects-modal__btn dialects-modal__btn--cancel"
              @click="hideModal">{{ $gettext('Remind me later') }}</button>
      <button class="sk-btn sk-btn--default dialects-modal__btn dialects-modal__btn--confirm"
              @click="saveDialect">{{ $gettext('Submit') }}</button>
    </div>
  </article>
</template>)

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import getProductConfig from '@/modules/productConfig';

  export default {
    computed: {
      ...mapState('EditProfileStore', {
        profileData: (state) => state.profileData || {}
      }),
      ...mapState('TTManageSupplierSkill', {
        dialectTo: (state) => state.skillData.dialectTo,
        skillsNeedDialect: (state) => state.skillsNeedDialect || [],
        dialectToErrors: ({errors}) => errors.dialectTo.errors,
        dialectToErrorsName: ({errors}) => errors.dialectTo.name
      }),
      dialectToText() {
        return this.$gettext('Country');
      },
      dialectToSelectPlace() {
        return this.$gettext('Select');
      },
      descriptionTextFirst() {
        return this.$gettext('In order to provide a better service for both our interpreters and our customers, we are gathering data on the regional dialects provided by our Arabic interpreters.');
      },
      descriptionTextSecond() {
        return this.$gettext('Which country does your dialect originate from?');
      },
      supportEmail() { return getProductConfig(this.$gettext, 'salita', 'domainData', 'supportEmail'); },
      supportText() {
        const template = this.$gettext('If you have any questions about dialects or why we are doing this now, please contact <a href="mailto:%{email}" class="sk-link js-copy-to-clipboard">%{email}</a>.');
        return this.$gettextInterpolate(template, {email: this.supportEmail});
      },
      langsList() { return this.$store.state.selectsData.language || []; },
      skillWithoutDialectId() { return this.skillsNeedDialect[0]?.skill.id || JSON.parse(localStorage.getItem('skillsNeedDialect'))[0]?.skill.id; },
      skillDataInfo() { return this.profileData?.skills?.find((skill) => skill.id === this.skillWithoutDialectId); },
      targetlangToData() { return this.langsList?.find((lang) => lang.id === this.skillDataInfo?.langToId); },
      countriesList() { return this.targetlangToData?.dialects.map((countryName) => ({id: countryName, name: countryName})) || []; }
    },
    methods: {
      ...mapActions('TTManageSupplierSkill', ['updateSkill']),
      ...mapMutations('TTManageSupplierSkill', [
        'setLangFrom',
        'setLangTo',
        'setDialectTo',
        'setQualification',
        'setError',
        'removeErrors'
      ]),
      setErrorModal(error) {
        this.$store.commit('ModalStore/stopModalProgress');
        this.closeModal();
        setTimeout(() => {
          this.$store.commit('ModalStore/setModal', {
            component: 'error-modal',
            data: {
              error
            }
          });
        });
      },
      closeModal() {
        this.$emit('closemodal');
      },
      clearLocalStorage() {
        localStorage.removeItem('skillsNeedDialect');
      },
      hideModal() {
        this.$store.commit('ModalStore/startModalProgress');
        this.$store.dispatch('TTManageSupplierSkill/removeCheckSkill', this.skillWithoutDialectId).then(() => {
          this.$store.commit('ModalStore/stopModalProgress');
          this.closeModal();
          this.clearLocalStorage();
        }).catch((error) => {
          this.setErrorModal(error);
        });
      },
      saveDialect() {
        let invalid = false;
        this.tolkIdNameErrors = [];
        this.removeErrors();

        if (!this.dialectTo) {
          this.setError({
            fieldName: 'dialectTo',
            errorText: this.$gettext('Dialect required')
          });
          invalid = true;
        }

        this.$store.commit('ErrorsStore/setError', {name: this.dialectToErrorsName, errors: this.dialectToErrors});

        if (!invalid) {
          this.$store.commit('ModalStore/startModalProgress');
          this.setQualification(this.skillDataInfo.qualificationId);
          this.setLangFrom(this.skillDataInfo.langFromId);
          this.setLangTo(this.skillDataInfo.langToId);

          this.updateSkill({id: this.skillWithoutDialectId}).then(() => {
            this.$store.commit('ModalStore/stopModalProgress');
            this.closeModal();
            this.clearLocalStorage();
          }).catch((error) => {
            this.setErrorModal(error);
          });
        }
      },
    }
  };
</script>

<style scoped>
.dialects-modal {
  display: block;
  width: 100%;
  padding: 30px;
}

.dialects-modal__title {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 18px;
}

.dialects-modal__description {
  margin: 8px 0 24px 0;
}

.dialects-modal__text {
  font-size: 13px;
}

.dialects-modal__email-text {
  margin-top: 24px;
}

.dialects-modal__btn-cont {
  display: flex;
  justify-content: flex-end;
  margin-top: 45px;
}

.dialects-modal__btn {
  display: inline-block;
  width: auto;
  padding: 0 30px;
}

.dialects-modal__btn--confirm {
  width: auto;
  margin-left: 20px;
}
</style>
