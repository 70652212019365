export default {
  path: 'documents',
  name: 'ServerDocs',
  component: () => import('@/pages/ServerPages/documents/s_DocumentsRoot'),
  children: [
    {
      path: 'documents',
      name: 'ServerDocuments',
      meta: {subPage: true},
      component: () => import('@/pages/ServerPages/documents/s_Documents')
    },
    {
      path: 'signatures',
      name: 'ServerSignatures',
      meta: {subPage: true},
      component: () => import('@/pages/ServerPages/documents/s_Signatures')
    }
  ]
};
