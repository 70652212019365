import axios from 'axios';

const customers = {
  methods: {
    // ==================================================
    // ==================================================
    // CUSTOMER CALLS
    // ==================================================
    // ==================================================
    getCustomers(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/customers', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    cancelSearchCustomers() {
      return new Promise((resolve) => {
        this.cancelationSource.cancel();
        this.cancelationSource = '';
        this.cancelationSource = axios.CancelToken.source();
        resolve();
      });
    },
  }
};

export default customers;
