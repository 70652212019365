/*
* Default translations for sk npm components. $npmTranslations
* */
export default {
  getMultiselectTemplate(context) { return context.$gettext('%{ n } selected', [].length); },
  getTmepickerText(context) { return context.$gettext('Time'); },
  getDatepickerText(context) { return context.$gettext('Date'); },
  getWeekCalendarText(context) { return context.$gettext('week'); },
  getFileManagerError1(context) { return context.$gettext('The picture you uploaded was really large (over 5MB). Could you please try uploading a smaller picture?'); },
  getFileManagerError2(context) { return context.$gettext('You can only attach .jpg, .png, .gif types of files.'); },
  getMobileMultiselectText1(context) { return context.$gettext('Clear'); },
  getMobileSelectCloseText(context) { return context.$gettext('Done'); },
  getMobileSelectPlaceholderText(context) { return context.$gettext('Search'); },
  getMobileTimepickerText(context) { return context.$gettext('Choose time'); },
  getGenderMale(context) { return context.$gettext('Male'); },
  getGenderFemale(context) { return context.$gettext('Female'); },
  getPaginationPrevText(context) { return context.$gettext('Prev'); },
  getPaginationNextText(context) { return context.$gettext('Next'); },
  getPaginationPageTemplate(context) { return context.$gettext('Page %{ n }'); },
  getInfoModalCloseText(context) { return context.$gettext('OK'); },
  getStartStopTimepickerText(context) { return context.$gettext('Start Time'); },
  getDayNames(context) {
    return [
      context.$gettext('Sunday'),
      context.$gettext('Monday'),
      context.$gettext('Tuesday'),
      context.$gettext('Wednesday'),
      context.$gettext('Thursday'),
      context.$gettext('Friday'),
      context.$gettext('Saturday')
    ];
  },
  getMonthNames(context) {
    return [
      context.$gettext('January'),
      context.$gettext('February'),
      context.$gettext('March'),
      context.$gettext('April'),
      context.$gettext('May'),
      context.$gettext('June'),
      context.$gettext('July'),
      context.$gettext('August'),
      context.$gettext('September'),
      context.$gettext('October'),
      context.$gettext('November'),
      context.$gettext('December')
    ];
  },
  getSelectCountries(context) {
    return [
      {id: 'Norway', name: context.$gettext('Norway')},
      {id: 'Albania', name: context.$gettext('Albania')},
      {id: 'Algeria', name: context.$gettext('Algeria')},
      {id: 'Angola', name: context.$gettext('Angola')},
      {id: 'Anguilla', name: context.$gettext('Anguilla')},
      {id: 'Antigua And Barbuda', name: context.$gettext('Antigua And Barbuda')},
      {id: 'Argentina', name: context.$gettext('Argentina')},
      {id: 'Armenia', name: context.$gettext('Armenia')},
      {id: 'Australia', name: context.$gettext('Australia')},
      {id: 'Austria', name: context.$gettext('Austria')},
      {id: 'Bahamas', name: context.$gettext('Bahamas')},
      {id: 'Bahrain', name: context.$gettext('Bahrain')},
      {id: 'Barbados', name: context.$gettext('Barbados')},
      {id: 'Belgium', name: context.$gettext('Belgium')},
      {id: 'Bolivia', name: context.$gettext('Bolivia')},
      {id: 'Botswana', name: context.$gettext('Botswana')},
      {id: 'Brazil', name: context.$gettext('Brazil')},
      {id: 'British Virgin Islands', name: context.$gettext('British Virgin Islands')},
      {id: 'Bulgaria', name: context.$gettext('Bulgaria')},
      {id: 'Cameroon', name: context.$gettext('Cameroon')},
      {id: 'Canada', name: context.$gettext('Canada')},
      {id: 'Cayman Islands', name: context.$gettext('Cayman Islands')},
      {id: 'Central African Republic', name: context.$gettext('Central African Republic')},
      {id: 'Chad', name: context.$gettext('Chad')},
      {id: 'Chile', name: context.$gettext('Chile')},
      {id: 'China', name: context.$gettext('China')},
      {id: 'Colombia', name: context.$gettext('Colombia')},
      {id: 'Costa Rica', name: context.$gettext('Costa Rica')},
      {id: 'Côte d\'Ivoire', name: context.$gettext('Côte d\'Ivoire')},
      {id: 'Croatia', name: context.$gettext('Croatia')},
      {id: 'Cyprus', name: context.$gettext('Cyprus')},
      {id: 'Czech Republic', name: context.$gettext('Czech Republic')},
      {id: 'Democratic Republic of the Congo', name: context.$gettext('Democratic Republic of the Congo')},
      {id: 'Denmark', name: context.$gettext('Denmark')},
      {id: 'Dominica', name: context.$gettext('Dominica')},
      {id: 'Dominican Republic', name: context.$gettext('Dominican Republic')},
      {id: 'Ecuador', name: context.$gettext('Ecuador')},
      {id: 'Egypt', name: context.$gettext('Egypt')},
      {id: 'El Salvador', name: context.$gettext('El Salvador')},
      {id: 'Estonia', name: context.$gettext('Estonia')},
      {id: 'Finland', name: context.$gettext('Finland')},
      {id: 'France', name: context.$gettext('France')},
      {id: 'Gambia', name: context.$gettext('Gambia')},
      {id: 'Germany', name: context.$gettext('Germany')},
      {id: 'Ghana', name: context.$gettext('Ghana')},
      {id: 'Greece', name: context.$gettext('Greece')},
      {id: 'Grenada', name: context.$gettext('Grenada')},
      {id: 'Guadeloupe', name: context.$gettext('Guadeloupe')},
      {id: 'Guam', name: context.$gettext('Guam')},
      {id: 'Guatemala', name: context.$gettext('Guatemala')},
      {id: 'Guinea', name: context.$gettext('Guinea')},
      {id: 'Guinea-Bissau', name: context.$gettext('Guinea-Bissau')},
      {id: 'Guyana', name: context.$gettext('Guyana')},
      {id: 'Honduras', name: context.$gettext('Honduras')},
      {id: 'Hong Kong', name: context.$gettext('Hong Kong')},
      {id: 'Iceland', name: context.$gettext('Iceland')},
      {id: 'India', name: context.$gettext('India')},
      {id: 'Indonesia', name: context.$gettext('Indonesia')},
      {id: 'Ireland', name: context.$gettext('Ireland')},
      {id: 'Israel', name: context.$gettext('Israel')},
      {id: 'Italy', name: context.$gettext('Italy')},
      {id: 'Jamaica', name: context.$gettext('Jamaica')},
      {id: 'Japan', name: context.$gettext('Japan')},
      {id: 'Jordan', name: context.$gettext('Jordan')},
      {id: 'Kazakhstan', name: context.$gettext('Kazakhstan')},
      {id: 'Kenya', name: context.$gettext('Kenya')},
      {id: 'Kuwait', name: context.$gettext('Kuwait')},
      {id: 'Laos', name: context.$gettext('Laos')},
      {id: 'Latvia', name: context.$gettext('Latvia')},
      {id: 'Lebanon', name: context.$gettext('Lebanon')},
      {id: 'Liechtenstein', name: context.$gettext('Liechtenstein')},
      {id: 'Lithuania', name: context.$gettext('Lithuania')},
      {id: 'Luxembourg', name: context.$gettext('Luxembourg')},
      {id: 'Macau', name: context.$gettext('Macau')},
      {id: 'Macedonia', name: context.$gettext('Macedonia')},
      {id: 'Madagascar', name: context.$gettext('Madagascar')},
      {id: 'Malaysia', name: context.$gettext('Malaysia')},
      {id: 'Mali', name: context.$gettext('Mali')},
      {id: 'Malta', name: context.$gettext('Malta')},
      {id: 'Martinique', name: context.$gettext('Martinique')},
      {id: 'Mauritius', name: context.$gettext('Mauritius')},
      {id: 'Mexico', name: context.$gettext('Mexico')},
      {id: 'Moldova', name: context.$gettext('Moldova')},
      {id: 'Montserrat', name: context.$gettext('Montserrat')},
      {id: 'Morocco', name: context.$gettext('Morocco')},
      {id: 'Namibia', name: context.$gettext('Namibia')},
      {id: 'Netherlands', name: context.$gettext('Netherlands')},
      {id: 'New Zealand', name: context.$gettext('New Zealand')},
      {id: 'Nicaragua', name: context.$gettext('Nicaragua')},
      {id: 'Niger', name: context.$gettext('Niger')},
      {id: 'Nigeria', name: context.$gettext('Nigeria')},
      {id: 'Oman', name: context.$gettext('Oman')},
      {id: 'Pakistan', name: context.$gettext('Pakistan')},
      {id: 'Palau', name: context.$gettext('Palau')},
      {id: 'Panama', name: context.$gettext('Panama')},
      {id: 'Paraguay', name: context.$gettext('Paraguay')},
      {id: 'Peru', name: context.$gettext('Peru')},
      {id: 'Philippines', name: context.$gettext('Philippines')},
      {id: 'Poland', name: context.$gettext('Poland')},
      {id: 'Puerto Rico', name: context.$gettext('Puerto Rico')},
      {id: 'Qatar', name: context.$gettext('Qatar')},
      {id: 'Réunion', name: context.$gettext('Réunion')},
      {id: 'Romania', name: context.$gettext('Romania')},
      {id: 'Russia', name: context.$gettext('Russia')},
      {id: 'Saint Kitts And Nevis', name: context.$gettext('Saint Kitts And Nevis')},
      {id: 'Saint Lucia', name: context.$gettext('Saint Lucia')},
      {id: 'Saint Vincent and the Grenadines', name: context.$gettext('Saint Vincent and the Grenadines')},
      {id: 'Sao Tome And Principe', name: context.$gettext('Sao Tome And Principe')},
      {id: 'Saudi Arabia', name: context.$gettext('Saudi Arabia')},
      {id: 'Senegal', name: context.$gettext('Senegal')},
      {id: 'Seychelles', name: context.$gettext('Seychelles')},
      {id: 'Sierra Leone', name: context.$gettext('Sierra Leone')},
      {id: 'Singapore', name: context.$gettext('Singapore')},
      {id: 'Slovakia', name: context.$gettext('Slovakia')},
      {id: 'Slovenia', name: context.$gettext('Slovenia')},
      {id: 'South Africa', name: context.$gettext('South Africa')},
      {id: 'South Korea', name: context.$gettext('South Korea')},
      {id: 'Spain', name: context.$gettext('Spain')},
      {id: 'Sri Lanka', name: context.$gettext('Sri Lanka')},
      {id: 'Suriname', name: context.$gettext('Suriname')},
      {id: 'Swaziland', name: context.$gettext('Swaziland')},
      {id: 'Sweden', name: context.$gettext('Sweden')},
      {id: 'Switzerland', name: context.$gettext('Switzerland')},
      {id: 'Taiwan', name: context.$gettext('Taiwan')},
      {id: 'Tanzania', name: context.$gettext('Tanzania')},
      {id: 'Thailand', name: context.$gettext('Thailand')},
      {id: 'Trinidad and Tobago', name: context.$gettext('Trinidad and Tobago')},
      {id: 'Tunisia', name: context.$gettext('Tunisia')},
      {id: 'Turkey', name: context.$gettext('Turkey')},
      {id: 'Turks and Caicos Islands', name: context.$gettext('Turks and Caicos Islands')},
      {id: 'Uganda', name: context.$gettext('Uganda')},
      {id: 'Ukraine', name: context.$gettext('Ukraine')},
      {id: 'United Arab Emirates', name: context.$gettext('United Arab Emirates')},
      {id: 'United Kingdom', name: context.$gettext('United Kingdom')},
      {id: 'United States', name: context.$gettext('United States')},
      {id: 'Venezuela', name: context.$gettext('Venezuela')},
      {id: 'Vietnam', name: context.$gettext('Vietnam')},
      {id: 'Yemen', name: context.$gettext('Yemen')},
      {id: 'Zimbabwe', name: context.$gettext('Zimbabwe')}
    ];
  },
  getSelectCountryCodesLong(context) {
    return [
      {id: '+47', name: context.$gettext('<span dir="ltr">+47</span> (Norway)')},
      {id: '+355', name: context.$gettext('<span dir="ltr">+355</span> (Albania)')},
      {id: '+213', name: context.$gettext('<span dir="ltr">+213</span> (Algeria)')},
      {id: '+244', name: context.$gettext('<span dir="ltr">+244</span> (Angola)')},
      {id: '+1264', name: context.$gettext('<span dir="ltr">+1264</span> (Anguilla)')},
      {id: '+1268', name: context.$gettext('<span dir="ltr">+1268</span> (Antigua And Barbuda)')},
      {id: '+54', name: context.$gettext('<span dir="ltr">+54</span> (Argentina)')},
      {id: '+374', name: context.$gettext('<span dir="ltr">+374</span> (Armenia)')},
      {id: '+61', name: context.$gettext('<span dir="ltr">+61</span> (Australia)')},
      {id: '+43', name: context.$gettext('<span dir="ltr">+43</span> (Austria)')},
      {id: '+1242', name: context.$gettext('<span dir="ltr">+1242</span> (Bahamas)')},
      {id: '+973', name: context.$gettext('<span dir="ltr">+973</span> (Bahrain)')},
      {id: '+1246', name: context.$gettext('<span dir="ltr">+1246</span> (Barbados)')},
      {id: '+32', name: context.$gettext('<span dir="ltr">+32</span> (Belgium)')},
      {id: '+591', name: context.$gettext('<span dir="ltr">+591</span> (Bolivia)')},
      {id: '+267', name: context.$gettext('<span dir="ltr">+267</span> (Botswana)')},
      {id: '+55', name: context.$gettext('<span dir="ltr">+55</span> (Brazil)')},
      {id: '+1284', name: context.$gettext('<span dir="ltr">+1284</span> (British Virgin Islands)')},
      {id: '+359', name: context.$gettext('<span dir="ltr">+359</span> (Bulgaria)')},
      {id: '+237', name: context.$gettext('<span dir="ltr">+237</span> (Cameroon)')},
      {id: '+1', name: context.$gettext('<span dir="ltr">+1</span> (Canada)')},
      {id: '+1345', name: context.$gettext('<span dir="ltr">+1345</span> (Cayman Islands)')},
      {id: '+236', name: context.$gettext('<span dir="ltr">+236</span> (Central African Republic)')},
      {id: '+235', name: context.$gettext('<span dir="ltr">+235</span> (Chad)')},
      {id: '+56', name: context.$gettext('<span dir="ltr">+56</span> (Chile)')},
      {id: '+86', name: context.$gettext('<span dir="ltr">+86</span> (China)')},
      {id: '+57', name: context.$gettext('<span dir="ltr">+57</span> (Colombia)')},
      {id: '+506', name: context.$gettext('<span dir="ltr">+506</span> (Costa Rica)')},
      {id: '+225', name: context.$gettext('<span dir="ltr">+225</span> (Côte d\'Ivoire)')},
      {id: '+385', name: context.$gettext('<span dir="ltr">+385</span> (Croatia)')},
      {id: '+357', name: context.$gettext('<span dir="ltr">+357</span> (Cyprus)')},
      {id: '+420', name: context.$gettext('<span dir="ltr">+420</span> (Czech Republic)')},
      {id: '+243', name: context.$gettext('<span dir="ltr">+243</span> (Democratic Republic of the Congo)')},
      {id: '+45', name: context.$gettext('<span dir="ltr">+45</span> (Denmark)')},
      {id: '+1767', name: context.$gettext('<span dir="ltr">+1767</span> (Dominica)')},
      {id: '+1', name: context.$gettext('<span dir="ltr">+1</span> (Dominican Republic)')},
      {id: '+593', name: context.$gettext('<span dir="ltr">+593</span> (Ecuador)')},
      {id: '+20', name: context.$gettext('<span dir="ltr">+20</span> (Egypt)')},
      {id: '+503', name: context.$gettext('<span dir="ltr">+503</span> (El Salvador)')},
      {id: '+372', name: context.$gettext('<span dir="ltr">+372</span> (Estonia)')},
      {id: '+679', name: context.$gettext('<span dir="ltr">+679</span> (Fiji)')},
      {id: '+358', name: context.$gettext('<span dir="ltr">+358</span> (Finland)')},
      {id: '+33', name: context.$gettext('<span dir="ltr">+33</span> (France)')},
      {id: '+220', name: context.$gettext('<span dir="ltr">+220</span> (Gambia)')},
      {id: '+49', name: context.$gettext('<span dir="ltr">+49</span> (Germany)')},
      {id: '+233', name: context.$gettext('<span dir="ltr">+233</span> (Ghana)')},
      {id: '+30', name: context.$gettext('<span dir="ltr">+30</span> (Greece)')},
      {id: '+1473', name: context.$gettext('<span dir="ltr">+1473</span> (Grenada)')},
      {id: '+590', name: context.$gettext('<span dir="ltr">+590</span> (Guadeloupe)')},
      {id: '+1', name: context.$gettext('<span dir="ltr">+1</span> (Guam)')},
      {id: '+502', name: context.$gettext('<span dir="ltr">+502</span> (Guatemala)')},
      {id: '+224', name: context.$gettext('<span dir="ltr">+224</span> (Guinea)')},
      {id: '+245', name: context.$gettext('<span dir="ltr">+245</span> (Guinea-Bissau)')},
      {id: '+592', name: context.$gettext('<span dir="ltr">+592</span> (Guyana)')},
      {id: '+504', name: context.$gettext('<span dir="ltr">+504</span> (Honduras)')},
      {id: '+852', name: context.$gettext('<span dir="ltr">+852</span> (Hong Kong)')},
      {id: '+36', name: context.$gettext('<span dir="ltr">+36</span> (Hungary)')},
      {id: '+354', name: context.$gettext('<span dir="ltr">+354</span> (Iceland)')},
      {id: '+91', name: context.$gettext('<span dir="ltr">+91</span> (India)')},
      {id: '+62', name: context.$gettext('<span dir="ltr">+62</span> (Indonesia)')},
      {id: '+353', name: context.$gettext('<span dir="ltr">+353</span> (Ireland)')},
      {id: '+972', name: context.$gettext('<span dir="ltr">+972</span> (Israel)')},
      {id: '+39', name: context.$gettext('<span dir="ltr">+39</span> (Italy)')},
      {id: '+1876', name: context.$gettext('<span dir="ltr">+1876</span> (Jamaica)')},
      {id: '+81', name: context.$gettext('<span dir="ltr">+81</span> (Japan)')},
      {id: '+962', name: context.$gettext('<span dir="ltr">+962</span> (Jordan)')},
      {id: '+7', name: context.$gettext('<span dir="ltr">+7</span> (Kazakhstan)')},
      {id: '+254', name: context.$gettext('<span dir="ltr">+254</span> (Kenya)')},
      {id: '+965', name: context.$gettext('<span dir="ltr">+965</span> (Kuwait)')},
      {id: '+856', name: context.$gettext('<span dir="ltr">+856</span> (Laos)')},
      {id: '+371', name: context.$gettext('<span dir="ltr">+371</span> (Latvia)')},
      {id: '+961', name: context.$gettext('<span dir="ltr">+961</span> (Lebanon)')},
      {id: '+423', name: context.$gettext('<span dir="ltr">+423</span> (Liechtenstein)')},
      {id: '+370', name: context.$gettext('<span dir="ltr">+370</span> (Lithuania)')},
      {id: '+352', name: context.$gettext('<span dir="ltr">+352</span> (Luxembourg)')},
      {id: '+853', name: context.$gettext('<span dir="ltr">+853</span> (Macau)')},
      {id: '+389', name: context.$gettext('<span dir="ltr">+389</span> (Macedonia)')},
      {id: '+261', name: context.$gettext('<span dir="ltr">+261</span> (Madagascar)')},
      {id: '+60', name: context.$gettext('<span dir="ltr">+60</span> (Malaysia)')},
      {id: '+223', name: context.$gettext('<span dir="ltr">+223</span> (Mali)')},
      {id: '+356', name: context.$gettext('<span dir="ltr">+356</span> (Malta)')},
      {id: '+596', name: context.$gettext('<span dir="ltr">+596</span> (Martinique)')},
      {id: '+230', name: context.$gettext('<span dir="ltr">+230</span> (Mauritius)')},
      {id: '+52', name: context.$gettext('<span dir="ltr">+52</span> (Mexico)')},
      {id: '+373', name: context.$gettext('<span dir="ltr">+373</span> (Moldova)')},
      {id: '+1', name: context.$gettext('<span dir="ltr">+1</span> (Montserrat)')},
      {id: '+212', name: context.$gettext('<span dir="ltr">+212</span> (Morocco)')},
      {id: '+264', name: context.$gettext('<span dir="ltr">+264</span> (Namibia)')},
      {id: '+31', name: context.$gettext('<span dir="ltr">+31</span> (Netherlands)')},
      {id: '+64', name: context.$gettext('<span dir="ltr">+64</span> (New Zealand)')},
      {id: '+505', name: context.$gettext('<span dir="ltr">+505</span> (Nicaragua)')},
      {id: '+227', name: context.$gettext('<span dir="ltr">+227</span> (Niger)')},
      {id: '+234', name: context.$gettext('<span dir="ltr">+234</span> (Nigeria)')},
      {id: '+968', name: context.$gettext('<span dir="ltr">+968</span> (Oman)')},
      {id: '+92', name: context.$gettext('<span dir="ltr">+92</span> (Pakistan)')},
      {id: '+680', name: context.$gettext('<span dir="ltr">+680</span> (Palau)')},
      {id: '+507', name: context.$gettext('<span dir="ltr">+507</span> (Panama)')},
      {id: '+595', name: context.$gettext('<span dir="ltr">+595</span> (Paraguay)')},
      {id: '+51', name: context.$gettext('<span dir="ltr">+51</span> (Peru)')},
      {id: '+63', name: context.$gettext('<span dir="ltr">+63</span> (Philippines)')},
      {id: '+48', name: context.$gettext('<span dir="ltr">+48</span> (Poland)')},
      {id: '+351', name: context.$gettext('<span dir="ltr">+351</span> (Portugal)')},
      {id: '+1', name: context.$gettext('<span dir="ltr">+1</span> (Puerto Rico)')},
      {id: '+974', name: context.$gettext('<span dir="ltr">+974</span> (Qatar)')},
      {id: '+262', name: context.$gettext('<span dir="ltr">+262</span> (Réunion)')},
      {id: '+40', name: context.$gettext('<span dir="ltr">+40</span> (Romania)')},
      {id: '+7', name: context.$gettext('<span dir="ltr">+7</span> (Russia)')},
      {id: '+1869', name: context.$gettext('<span dir="ltr">+1869</span> (Saint Kitts And Nevis)')},
      {id: '+1758', name: context.$gettext('<span dir="ltr">+1758</span> (Saint Lucia)')},
      {id: '+1784', name: context.$gettext('<span dir="ltr">+1784</span> (Saint Vincent and the Grenadines)')},
      {id: '+239', name: context.$gettext('<span dir="ltr">+239</span> (Sao Tome And Principe)')},
      {id: '+966', name: context.$gettext('<span dir="ltr">+966</span> (Saudi Arabia)')},
      {id: '+221', name: context.$gettext('<span dir="ltr">+221</span> (Senegal)')},
      {id: '+248', name: context.$gettext('<span dir="ltr">+248</span> (Seychelles)')},
      {id: '+232', name: context.$gettext('<span dir="ltr">+232</span> (Sierra Leone)')},
      {id: '+65', name: context.$gettext('<span dir="ltr">+65</span> (Singapore)')},
      {id: '+421', name: context.$gettext('<span dir="ltr">+421</span> (Slovakia)')},
      {id: '+386', name: context.$gettext('<span dir="ltr">+386</span> (Slovenia)')},
      {id: '+27', name: context.$gettext('<span dir="ltr">+27</span> (South Africa)')},
      {id: '+82', name: context.$gettext('<span dir="ltr">+82</span> (South Korea)')},
      {id: '+34', name: context.$gettext('<span dir="ltr">+34</span> (Spain)')},
      {id: '+94', name: context.$gettext('<span dir="ltr">+94</span> (Sri Lanka)')},
      {id: '+597', name: context.$gettext('<span dir="ltr">+597</span> (Suriname)')},
      {id: '+268', name: context.$gettext('<span dir="ltr">+268</span> (Swaziland)')},
      {id: '+46', name: context.$gettext('<span dir="ltr">+46</span> (Sweden)')},
      {id: '+41', name: context.$gettext('<span dir="ltr">+41</span> (Switzerland)')},
      {id: '+886', name: context.$gettext('<span dir="ltr">+886</span> (Taiwan)')},
      {id: '+255', name: context.$gettext('<span dir="ltr">+255</span> (Tanzania)')},
      {id: '+66', name: context.$gettext('<span dir="ltr">+66</span> (Thailand)')},
      {id: '+1868', name: context.$gettext('<span dir="ltr">+1868</span> (Trinidad and Tobago)')},
      {id: '+216', name: context.$gettext('<span dir="ltr">+216</span> (Tunisia)')},
      {id: '+90', name: context.$gettext('<span dir="ltr">+90</span> (Turkey)')},
      {id: '+1649', name: context.$gettext('<span dir="ltr">+1649</span> (Turks and Caicos Islands)')},
      {id: '+256', name: context.$gettext('<span dir="ltr">+256</span> (Uganda)')},
      {id: '+380', name: context.$gettext('<span dir="ltr">+380</span> (Ukraine)')},
      {id: '+971', name: context.$gettext('<span dir="ltr">+971</span> (United Arab Emirates)')},
      {id: '+44', name: context.$gettext('<span dir="ltr">+44</span> (United Kingdom)')},
      {id: '+1', name: context.$gettext('<span dir="ltr">+1</span> (United States)')},
      {id: '+598', name: context.$gettext('<span dir="ltr">+598</span> (Uruguay)')},
      {id: '+58', name: context.$gettext('<span dir="ltr">+58</span> (Venezuela)')},
      {id: '+84', name: context.$gettext('<span dir="ltr">+84</span> (Vietnam)')},
      {id: '+967', name: context.$gettext('<span dir="ltr">+967</span> (Yemen)')},
      {id: '+263', name: context.$gettext('<span dir="ltr">+263</span> (Zimbabwe)')}
    ];
  }
};
