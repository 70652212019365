(<template>
  <article class="summary-info">
    <div v-for="(summarySection, index) in filteredSummaryList"
         :key="index"
         :class="{'summary-info__data-section--admin': summarySection.isAdminSection}"
         class="summary-info__data-section">
      <h3 v-if="summarySection.title"
          class="summary-info__data-header">{{ summarySection.title }}</h3>
      <div v-for="(line, lineIndex) in summarySection.lines"
           :key="lineIndex"
           class="summary-info__data-row"
           v-html="line">
      </div>
      <sk-note v-if="showAttachmentWarning(summarySection)"
               :text="attachmentNote"
               class="summary-info__attachment-note" />
    </div>
  </article>
</template>)

<script>
  export default {
    props: {
      summaryList: {
        type: Array,
        default: () => []
      },
      hasAttachments: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      filteredSummaryList() {
        return this.summaryList.map((summaryItem) => {
          summaryItem.lines = summaryItem.lines?.filter((item) => item.value) || [];
          summaryItem.lines = summaryItem.lines.map((line) => {
            return this.genSummaryRow(line.text, line.value);
          }) || [];
          return summaryItem;
        })?.filter((summaryItem) => summaryItem.lines.length);
      },
      attachmentNote() {
        return this.$gettext('NB: You have not uploaded a document. It is not required!');
      }
    },
    methods: {
      showAttachmentWarning(section) {
        if (section.title === 'Assignment information') return !this.hasAttachments;
      },
      genSummaryRow(parameterName, parameterValue) {
        const title = parameterName ? `${parameterName}: ` : '';

        return `<p class="summary-info__data-title">${title}</p><div>${this.genSummaryValue(parameterValue)}</div>`;
      },
      genSummaryValue(parameterValue) {
        return Array.isArray(parameterValue) ? parameterValue.map((value) => `<p>${value}</p>`).join('') : parameterValue;
      }
    }
  };
</script>

<style>
  .summary-info__data-row div,
  .summary-info__data-row p {
    font-weight: bold;
    line-height: inherit;
  }

  .summary-info__data-title {
    width: 150px;
    min-width: 150px;
    font-weight: normal;
    word-break: normal;
  }

  .summary-info__data-title:empty ~ div {
    color: #b68e56;
  }
</style>

<style scoped>
  .summary-info {
    font-size: 12px;
    line-height: 18px;
  }

  .summary-info__data-section {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: solid 1px rgba(0, 0, 0, 0.2);
    line-height: inherit;
  }

  .summary-info__data-section:last-child:not(.summary-info__data-section--admin) {
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  }

  .summary-info__data-section--admin {
    width: calc(100% + 60px);
    margin-right: -30px;
    margin-left: -30px;
    padding-right: 30px;
    padding-left: 30px;
    border-top: 0;
    background-color: #aaa8be;
  }

  .summary-info__data-header {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: inherit;
  }

  .summary-info__data-row {
    display: flex;
    line-height: inherit;
  }

  .summary-info__attachment-note {
    margin: 10px 5px;
  }
</style>
