(<template>
  <section class="confirmed-jobs-modal__wrapper">
    <p class="confirmed-jobs-modal__main-text">{{ $gettext('You have confirmed jobs') }}</p>
    <p class="confirmed-jobs-modal__text">{{ $gettext('You have confirmed jobs during the date/time period that you have selected.') }}</p>
    <p class="confirmed-jobs-modal__text">{{ $gettext('Please check your calendar and withdraw from these jobs before adding time off.') }}</p>
    <div class="confirmed-jobs-modal__btn-wrapper">
      <button class="sk-btn sk-btn--white confirmed-jobs-modal__btn confirmed-jobs-modal__btn--cancel"
              @click="closeModal">{{ $gettext('Cancel') }}</button>
      <button class="sk-btn sk-btn--default confirmed-jobs-modal__btn"
              @click="goToCalendar">{{ $gettext('Calendar') }}</button>
    </div>
  </section>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      goToCalendar() {
        this.closeModal();
        this.$router.push(this.$makeRoute({name: 'ServerCalendar', query: {view: 'week', date: this.data.context.privateEvent.startDate}}));
      }
    }
  };
</script>
<style scoped>
  .confirmed-jobs-modal__wrapper {
    margin: 20px 15px;
  }

  .confirmed-jobs-modal__main-text {
    font-weight: bold;
  }

  .confirmed-jobs-modal__text {
    margin-bottom: 20px;
  }

  .confirmed-jobs-modal__btn {
    width: auto;
    padding: 0 20px;
  }

  .confirmed-jobs-modal__btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .confirmed-jobs-modal__btn--cancel {
    margin-right: 20px;
  }
</style>
