(<template>
  <div class="feedback-jobs-modal">
    <div v-html="modalText"></div>
    <div class="feedback-jobs-modal__btn-wrapper">
      <button v-for="feedbackJob in feedbackJobs"
              :key="feedbackJob.id"
              class="sk-btn sk-btn--default feedback-jobs-modal__btn"
              @click="goToAssignment(feedbackJob.id)"> {{ getButtonText(feedbackJob.id) }}</button>
    </div>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('s_DashboardStore', {
        feedbackJobs: ({feedbackJobs}) => feedbackJobs || []
      }),
      modalText() {
        return this.$gettext('Before you can apply for any new assignments, we need your feedback regarding an earlier assignment. <br/>We cannot charge your customers without your feedback.');
      }
    },
    methods: {
      getButtonText(assignmentId) {
        const template = this.$gettext('Click here to view assignment #%{num}');
        return this.$gettextInterpolate(template, {num: assignmentId});
      },
      goToAssignment(assignmentId) {
        this.$store.commit('OneAssignmentStore/clearAssignmentStore');
        this.$store.commit('FeedbackStore/clearStore');
        this.$emit('closemodal');
        this.$router.push(this.$makeRoute({name: 'ServerOneAssignment', params: {id: assignmentId}}));
      }
    }
  };
</script>

<style scoped>
  .feedback-jobs-modal {
    padding: 20px 30px;
  }

  .feedback-jobs-modal__btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 20px -5px 0;
  }

  .feedback-jobs-modal__btn {
    margin: 5px 0;
  }
</style>
