export default ({$fetcher: fetchData, $moment: moment}) => {
  return {
    namespaced: true,
    state: {
      availableDates: '',
      availableTimes: '',
      relatedAvailableTimes: '',
      availabilityWarning: ''
    },
    actions: {
      fetchAvailableDate({commit}, {params}) {
        return new Promise((resolve, reject) => {
          fetchData.fetchAvailableDate(params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setAvailableDate', data.suitableDate);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      fetchAvailableTime({commit}, {params}) {
        return new Promise((resolve, reject) => {
          fetchData.fetchAvailableTime(params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setAvailableTime', data.suitableTime);
            commit('setRelatedAvailableTime', data.suitableTime);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      }
    },
    mutations: {
      setAvailableDate(state, data) { state.availableDates = data; },
      setAvailableTime(state, data) { state.availableTimes = data; },
      setAvailabilityWarning(state, value) { state.availabilityWarning = value; },
      clearAvailabilityWarning(state) { state.availabilityWarning = ''; },
      setRelatedAvailableTime(state, data) {
        const res = [];
        if (data && data.length) {
          data.forEach((item) => {
            if (item > moment().format('HH:mm')) {
              res.push(item);
            }
          });
        }
        state.relatedAvailableTimes = res;
      },
      removeInterBookingData(state) {
        state.availableDates = '';
        state.availableTimes = '';
        state.relatedAvailableTimes = '';
        state.availabilityWarning = '';
      }
    }
  };
};
