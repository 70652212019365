export default () => {
  return {
    namespaced: true,
    state: {
      tooltip: {
        component: '',
        position: '',
        progress: false,
        classes: [],
        data: {},
        errors: []
      }
    },
    getters: {
      tooltipComponent(state) {
        return state.tooltip.component;
      },
      tooltipData(state) {
        return state.tooltip.data;
      },
      tooltipPosition(state) {
        return state.tooltip.position;
      },
      tooltipProgress(state) {
        return state.tooltip.progress;
      },
      tooltipClasses(state) {
        return state.tooltip.classes;
      }
    },
    actions: {
      closeTooltip({commit}) {
        commit('removeTooltip');
        commit('stopTooltipProgress');
      }
    },
    mutations: {
      startTooltipProgress(state) {
        state.tooltip.progress = true;
      },
      stopTooltipProgress(state) {
        state.tooltip.progress = false;
      },
      setTooltip(state, {component, data, classes, absolutePosition}) {
        state.tooltip.component = component;
        state.tooltip.data = data;
        state.tooltip.position = absolutePosition;
        state.tooltip.classes = classes || [];
      },
      removeTooltip(state) {
        state.tooltip.progress = false;
        state.tooltip.component = '';
        state.tooltip.data = {};
      }
    }
  };
};
