<script>
  import {mapGetters} from 'vuex';
  import SendDetailsPrototype from '@/components/shared_components/modals/SendDetailsPrototype';

  export default {
    extends: SendDetailsPrototype,
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter']),
      codeText() { return this.$gettext('Country code'); },
      numberText() { return this.$gettext('Phone number'); },
      placeholderText() { return this.$gettext('Number'); },
      sendText() { return this.$gettext('Send SMS'); },
      titleText() {
        return this.userIsInterpreter
          ? this.$gettext('Send a copy of the assignment details to yourself')
          : this.$gettext('Send your colleague these assignment details');
      },
      infoText() {
        return this.userIsInterpreter
          ? this.$gettext('Do not send this information to anyone other than yourself.')
          : this.$gettext('Only send these assignment details to people who you work with and trust! Don\'t add any sensitive information.');
      },
      numberRequiredText() { return this.$gettext('Phone number is required.'); },
      numberIsNotValidText() { return this.$gettext('Phone number is not valid'); },
      jobId() { return this.data.jobId || ''; }
    },
    methods: {
      sendDetails() {
        if (!this.isAnyErrors()) {
          const form = new FormData();
          const id = this.jobId || this.$route.params.id;
          this.progressActive = true;
          form.append('phone_code', this.phoneCode);
          form.append('phone_number', this.phoneNumber);
          form.append('platform', 'sms');
          this.sendJobDetails({form, id}).then(() => {
            this.progressActive = false;
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('Success! These assignment details have been sent to your colleague via SMS.')
              });
            });
          }).catch((error) => {
            this.progressActive = false;
            if (error?.data?.errors?.length
              && error.data.errors.includes('Invalid phone')) {
              this.phoneNumberErrors.push(this.$gettext('Please enter a valid phone number.'));
              this.$store.commit('ErrorsStore/setError', {name: this.phoneNumberValidationName, errors: this.phoneNumberErrors});
            }
          });
        }
      }
    },
    beforeMount() {
      this.$store.commit('ErrorsStore/removeAllErrors');
    }
  };
</script>
