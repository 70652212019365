import constants from '@/modules/constants';

export default function getGodmodeLoginLink({userId, userUid, token, jobId}) {
  if (constants.GODMODE_SERVER_PATH) {
    const [protocol, domain] = constants.GODMODE_SERVER_PATH.split('://');
    const jobIdQuery = jobId ? `&id=${jobId}` : '';
    const userUidQuery = userUid ? `&uid=${userUid}` : '';
    const tokenQuery = token ? `&token=${token}` : '';
    const authPath = `auth/sign_in?godmode=true${userUidQuery}${tokenQuery}${jobIdQuery}`;
    const wildCardDomain = domain.includes('localhost') ? '' : `${userId}.`;

    return `${protocol}://${wildCardDomain}${domain}/${authPath}`;
  }
  return '/';
}
