(<template>
  <section class="applied-jobs-modal__wrapper">
    <p class="applied-jobs-modal__text">{{ $gettext('You have applied to job(s) during this date/time period.') }}</p>
    <p class="applied-jobs-modal__text"
       v-html="secondParagraphText"></p>
    <div class="applied-jobs-modal__btn-wrapper">
      <button class="sk-btn sk-btn--white applied-jobs-modal__btn applied-jobs-modal__btn--cancel"
              @click="closeModal">{{ $gettext('Cancel') }}</button>
      <button class="sk-btn sk-btn--white applied-jobs-modal__btn"
              @click="submitData">{{ $gettext('Apply time off') }}</button>
    </div>
  </section>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      secondParagraphText() {
        return this.$gettext('If you click on "<span class="applied-jobs-modal__red-text">Apply time off</span>", we will withdraw all your job applications in this period.');
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      submitData() {
        this.closeModal();
        this.data.context.submitData(true);
      }
    }
  };
</script>

<style>
  .applied-jobs-modal__red-text {
    color: #646d8c;
    color: var(--color-primary);
  }
</style>

<style scoped>
  .applied-jobs-modal__wrapper {
    margin: 20px 15px;
  }

  .applied-jobs-modal__text {
    margin-bottom: 20px;
  }

  .applied-jobs-modal__btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .applied-jobs-modal__btn {
    width: auto;
    padding: 0 20px;
  }

  .applied-jobs-modal__btn--cancel {
    margin-right: 20px;
  }
</style>
