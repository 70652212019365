(<template>
  <section class="prof-education-modal__content-wrapper">
    <section class="prof-school-form__wrapper">
      <sk-input :description="text1"
                :preselected-value="currentSchool.school"
                :margin-bottom="true"
                :placeholder="text1"
                :validation-name="schoolValidationName"
                :autofocus="true"
                @fieldvaluechanged="catchSchool" />
      <sk-input :description="text2"
                :preselected-value="currentSchool.location"
                :margin-bottom="true"
                :placeholder="text2"
                :validation-name="schoolLocationValidationName"
                @fieldvaluechanged="catchLocation" />
      <sk-input :description="text3"
                :preselected-value="currentSchool.degree"
                :margin-bottom="true"
                :placeholder="text3"
                :validation-name="schoolDegreeValidationName"
                @fieldvaluechanged="catchDegree" />
      <div class="prof-school-form__years-selects-cont">
        <sk-select :type="'pastYear'"
                   :description="text4"
                   :default-value="text5"
                   :preselected-value="currentSchool.year_from"
                   :validation-name="schoolYearFromValidationName"
                   class="sk-select__wrapper--margin-right"
                   @csvaluechanged="catchYearFrom" />
        <sk-select :type="'pastYear'"
                   :description="text6"
                   :default-value="text7"
                   :preselected-value="currentSchool.year_to"
                   :validation-name="schoolYearToValidationName"
                   @csvaluechanged="catchYearTo" />
      </div>
      <button v-if="currentSchool.id"
              class="sk-btn sk-btn--like-link prof-school-form__delete-btn"
              @click="deleteSchoolHandler">{{ $gettext('Delete school') }}</button>
    </section>
    <button class="sk-btn sk-btn--white prof-education-modal__add-school-btn"
            @click="addNewShoolHandler">{{ $gettext("Add a new school") }}</button>
    <submit-btns :submit-handler="submitHandler"
                 :cancel-handler="closeModal" />
  </section>
</template>)

<script>
  import SubmitBtns from '@/components/profile_components/edit/ModalSubmitBtns';

  export default {
    components: {
      'submit-btns': SubmitBtns
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        currentSchool: {
          id: this.data.data.id || '',
          school: this.data.data.school || '',
          location: this.data.data.location || '',
          degree: this.data.data.degree || '',
          year_to: this.data.data.year_to || '',
          year_from: this.data.data.year_from || ''
        },
        openNewModal: false,
        schoolValidationName: 'profileSchool',
        schoolLocationValidationName: 'profileSchoolLocation',
        schoolDegreeValidationName: 'profileSchoolDegree',
        schoolYearFromValidationName: 'profileSchoolYearFrom',
        schoolYearToValidationName: 'profileSchoolYearTo',
        schoolErrors: [],
        schoolLocationErrors: [],
        schoolDegreeErrors: [],
        schoolYearFromErrors: [],
        schoolYearToErrors: []
      };
    },
    computed: {
      text1() { return this.$gettext('School*'); },
      text2() { return this.$gettext('Location'); },
      text3() { return this.$gettext('Degree'); },
      text4() { return this.$gettext('Year from*'); },
      text5() { return this.$gettext('YYYY'); },
      text6() { return this.$gettext('Year To*'); },
      text7() { return this.$gettext('YYYY'); },
      isAnyErrors() {
        return this.schoolErrors.length || this.schoolLocationErrors.length || this.schoolDegreeErrors.length || this.schoolYearFromErrors.length || this.schoolYearToErrors.length;
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      catchSchool(value) {
        this.currentSchool.school = value;
      },
      catchLocation(value) {
        this.currentSchool.location = value;
      },
      catchDegree(value) {
        this.currentSchool.degree = value;
      },
      catchYearFrom(value) {
        this.currentSchool.year_from = value;
      },
      catchYearTo(value) {
        this.currentSchool.year_to = value;
      },
      closeModal() {
        this.$emit('closemodal');
      },
      stopProgress() {
        this.$emit('stopprogress');
      },
      submitHandler(openNewModal) {
        const form = new FormData();
        this.schoolErrors = [];
        this.schoolYearFromErrors = [];
        this.schoolYearToErrors = [];

        if (!this.currentSchool.school || !this.currentSchool.school.trim()) {
          this.schoolErrors.push(this.$gettext('Your school is required.'));
        }
        if (!this.currentSchool.year_from) {
          this.schoolYearFromErrors.push(this.$gettext('Year from is required.'));
        }
        if (!this.currentSchool.year_to) {
          this.schoolYearToErrors.push(this.$gettext('Year to is required.'));
        }
        if (this.currentSchool.year_to && this.currentSchool.year_from > this.currentSchool.year_to) {
          this.schoolYearToErrors.push(this.$gettext('Year from can\'t be less than year to'));
        }
        this.$store.commit('ErrorsStore/setError', {name: this.schoolValidationName, errors: this.schoolErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.schoolYearFromValidationName, errors: this.schoolYearFromErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.schoolYearToValidationName, errors: this.schoolYearToErrors});

        if (!this.isAnyErrors) {
          form.append('education[school]', this.currentSchool.school.trim());
          form.append('education[location]', this.currentSchool.location.trim());
          form.append('education[degree]', this.currentSchool.degree.trim());
          form.append('education[year_from]', this.currentSchool.year_from);
          form.append('education[year_to]', this.currentSchool.year_to);
          this.$emit('startprogress');
          if (this.currentSchool.id) {
            this.data.context.editSchool(form, this.currentSchool.id);
          } else {
            this.data.context.addSchool(form);
          }
          this.data.context.$on('updatefailed', this.stopProgress);
          this.data.context.$on('updatesuccess', () => {
            if (openNewModal === true) {
              this.stopProgress();
              this.currentSchool = {
                id: '',
                school: '',
                location: '',
                degree: '',
                year_to: '',
                year_from: ''
              };
            } else {
              this.closeModal();
            }
          });
        }
      },
      addNewShoolHandler() {
        this.submitHandler(true);
      },
      deleteSchoolHandler() {
        this.$emit('startprogress');
        this.$store.dispatch('EditProfileStore/removeSchool', this.currentSchool.id).then(() => {
          this.closeModal();
        });
      }
    }
  };
</script>

<style>
  .prof-school-form__years-selects-cont .sk-select__list {
    top: auto;
    bottom: calc(100% + 2px);
    max-height: 200px;
  }
</style>

<style scoped>
  .prof-education-modal__content-wrapper {
    display: block;
    width: 100%;
    padding: 35px;
    padding-top: 10px;
  }

  .prof-education-modal__add-school-btn {
    display: inline-block;
    width: auto;
    padding: 0 10px;
  }

  .prof-school-form__wrapper {
    display: block;
    width: 100%;
    padding-bottom: 20px;
  }

  .prof-school-form__years-selects-cont {
    display: flex;
    margin-top: 15px;
  }

  .prof-school-form__delete-btn {
    margin-top: 20px;
    color: #f04;
    color: var(--color-error);
    font-size: 16px;
    text-decoration: underline;
  }
</style>
