<template>
  <div class="chip-wrapper"
       @click="emitClick"
       @touchstart="emitTouchStart"
       @touchend="emitTouchEnd">
    <p class="chip"
       :class="[color, {'chip--with-icon': icon}]">{{ label }}
      <span v-if="icon"
            :class="{'sk-select__arrow': icon === 'selectArrow'}"></span></p>
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: ''
      },
      color: {
        type: String,
        required: true,
        default: 'grey',
        validator: (value) => {
          const res = [
            'white',
            'green',
            'yellow',
            'orange',
            'blue',
            'dark-blue',
            'red',
            'grey',
            'purple'
          ].indexOf(value) !== -1;
          return res;
        }
      },
      icon: {
        type: String,
        default: ''
      }
    },
    methods: {
      emitClick(eventArg) { this.$emit('chipclick', eventArg); },
      emitTouchStart(eventArg) { this.$emit('touchstart', eventArg); },
      emitTouchEnd(eventArg) { this.$emit('touchend', eventArg); },
    }
  };
</script>
<style>
.chip-wrapper .sk-select__arrow::before {
  background-color: currentColor;
}

.chip-wrapper .sk-select__arrow::after {
  background-color: currentColor;
}

.chip-wrapper .sk-select__arrow {
  top: 13px;
  height: auto;
}
</style>
<style scoped>
.chip-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.chip {
  position: relative;
  padding: 3px 8px;
  border-radius: 20px;
  background-color: #ffffff29;
}

.chip--with-icon {
  padding-right: 25px;
}

.chip.yellow {
  background-color: #ffed24;
  color: #000;
}

.chip.green {
  background-color: #54bf54;
  color: #fff;
}

.chip.blue {
  background-color: #246dff;
  color: #fff;
}

.chip.dark-blue {
  background-color: #104a81;
  color: #fff;
}

.chip.purple {
  background-color: #8071ef;
  color: #fff;
}

.chip.red {
  background-color: #ff3624;
  color: #fff;
}

.chip.orange {
  background-color: #f5771d;
  color: #fff;
}

.chip.grey {
  background-color: #f4f5f7;
  color: #555;
}

.chip.white {
  border: 1px solid #e6e7eb;
  background-color: #fff;
  color: #555;
}
</style>
