import BookingHelper from '@/store/booking_stores/BookingHelper';

export default ({$fetcher: fetchData} = {}) => {
  return {
    namespaced: true,
    state: {
      attendersList: '',
      departmentsList: '',
      department: '',
      attenders: '',
      departmentsProgress: false,
      attendersProgress: false,
      showAttenders: true,
      errors: {
        department: {name: 'departmentError', errors: []},
        attenders: {name: 'attendersError', errors: []}
      }
    },
    getters: {
      currentDepartment(state) {
        return Array.isArray(state.departmentsList)
          ? state.departmentsList.find((dep) => dep.id == state.department) || ''
          : '';
      },
      showAttenders(state) {
        return state.showAttenders;
      }
    },
    actions: {
      fetchDepartments({commit, rootGetters}, params) {
        commit('setDepartmentsProgress', true);
        return new Promise((resolve, reject) => {
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'] || rootGetters['EnterpriseStore/getManagerJobEnterpriseId'];
          fetchData.fetchSelectsData('departments', enterpriseId, params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setDepartments', data);
            commit('setDepartmentsProgress', false);
            resolve(data);
          }).catch((error) => {
            commit('setDepartmentsProgress', false);
            reject(error);
          });
        });
      },
      fetchAttenders({commit}, {department, headers}) {
        return new Promise((resolve, reject) => {
          const promise = department
            ? fetchData.fetchAttendersForDepartment(department, headers)
            : fetchData.fetchSelectsData('attenders');
          if (promise) {
            commit('setAttendersProgress', true);
            promise.then((data) => {
              if (data.code == 401) {
                commit('TTAuthStore/logOut', '', {root: true});
                commit('GlobalStore/remove404Page', '', {root: true});
                resolve('LOGOUT');
                return;
              }
              commit('setAttendersList', data.attenders || data);
              commit('setAttendersProgress', false);
              resolve(data.attenders || data);
            }).catch((error) => {
              commit('setAttendersProgress', false);
              reject(error);
            });
          } else {
            commit('setAttendersProgress', false);
            resolve();
          }
        });
      },
      changeDepartment({commit, dispatch}, {department, headers}) {
        commit('setDepartment', department);
        dispatch('PostingNotifierStore/departmentChanged', {headers}, {root: true});
      },
      emptyAttenders({commit}) {
        commit('setAttendersList', []);
      },
      changeShowAttenders({commit}, showAttenders) {
        commit('setShowAttenders', showAttenders);
      }
    },
    mutations: {
      ...BookingHelper().mutations,
      setDepartments(state, data) {
        state.departmentsList = data;
      },
      setDepartment(state, value) {
        state.department = value;
      },
      setAttendersList(state, data) {
        state.attendersList = data;
      },
      setAttenders(state, value) {
        state.attenders = value;
      },
      setShowAttenders(state, value) {
        state.showAttenders = value;
      },
      setDepartmentsProgress(state, value) {
        state.departmentsProgress = value;
      },
      setAttendersProgress(state, value) {
        state.attendersProgress = value;
      },
      clearStore(state) {
        state.attendersProgress = false;
        state.departmentsProgress = false;
        state.attendersList = '';
        state.departmentsList = '';
        state.department = '';
        state.attenders = '';
        state.showAttenders = true;
        state.errors = {
          department: {name: 'departmentError', errors: []},
          attenders: {name: 'attendersError', errors: []}
        };
      }
    }
  };
};
