(<template>
  <section class="address-error">
    <p class="address-error__description">{{ $gettext('Please fill in the following information first') }}</p>
    <form class="address-error__form"
          @submit.prevent.stop="submitHandler">
      <p class="address-error__section-text">{{ $gettext('Add your address') }}</p>
      <full-address ref="addressComponent" />
      <div class="address-error__btns-wrapper">
        <button class="sk-link address-error__btn"
                type="button"
                @click="goToDashboard">{{ $gettext("View dashboard") }}</button>
        <button class="sk-btn sk-btn--default address-error__submit-btn">{{ $gettext('Save') }}</button>
      </div>
    </form>
  </section>
</template>)

<script>
  import {mapGetters, mapActions, mapState} from 'vuex';
  import FullAddress from '@/components/shared_components/FullAddress';

  export default {
    components: {
      'full-address': FullAddress
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('FullAddressStore', [
        'line1',
        'line2',
        'county',
        'postcode',
        'city',
        'country',
        'longitude',
        'latitude'
      ]),
      ...mapGetters('FullAddressStore', ['fullAddress']),
      ...mapGetters('UserInfoStore', ['userUid'])
    },
    methods: {
      ...mapActions('EditProfileStore', ['updateUserProfile']),
      ...mapActions('FullAddressStore', ['getGeometryLocation', 'setError']),
      goToDashboard() {
        this.closeModal();
        this.$router.push({name: 'ServerHome'});
      },
      submitHandler() {
        if (this.$refs.addressComponent.validateForm()) {
          const form = new FormData();
          const address = `${this.line1}, ${this.line2}, ${this.postcode} ${this.city}, ${this.country}`;

          form.append('interpreter[person][address][line_1]', this.line1);
          form.append('interpreter[person][address][line_2]', this.line2);
          form.append('interpreter[person][address][city]', this.city);
          form.append('interpreter[person][address][county]', this.county);
          form.append('interpreter[person][address][country]', this.country);
          form.append('interpreter[person][address][postcode]', this.postcode);

          this.getGeometryLocation(address).then(() => {
            form.append('interpreter[person][address][longitude]', this.longitude);
            form.append('interpreter[person][address][latitude]', this.latitude);

            this.$emit('startprogress');
            this.updateUserProfile({id: this.userUid, form}).then(() => {
              this.stopProgress();
              this.data.context.setDisablingButtons(false);
            }).catch(() => {
              this.stopProgress();
            });
          }).catch(() => {
            this.setError({
              fieldName: 'address',
              errorText: this.$gettext('We were unable to locate the address')
            });
          });
        }
      },
      closeModal() {
        this.$emit('closemodal');
      },
      stopProgress() {
        this.$emit('stopprogress');
        this.closeModal();
      }
    },
    destroyed() {
      this.$store.commit('FullAddressStore/clearStore');
    }
  };
</script>

<style scoped>
  .address-error {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 10px 30px 30px;
  }

  .address-error__description {
    margin-bottom: 20px;
    text-align: center;
  }

  .address-error__form {
    display: block;
    width: 100%;
  }

  .address-error__btns-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .address-error__submit-btn {
    width: auto;
    padding: 0 30px;
  }

  .address-error__section-text {
    margin-bottom: 20px;
  }
</style>
