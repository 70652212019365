(<template>
  <div class="access-options">
    <h3 class="access-options__title">{{ $gettext('Accessibility options') }}</h3>
    <div class="access-options__buttons access-buttons">
      <button v-for="button in buttons"
              :key="button.id"
              class="access-buttons__item access-btn"
              @click="activateAccessibility(button.name, button.id)">
        <div class="access-btn__icon"
             :class="[`icon-${button.name}`]"></div>
        <p class="access-btn__description">{{ $gettext(button.text) }}</p>
        <div class="access-btn__indication-line indication-line">
          <span class="indication-line__item"
                :class="{'active-line': accessOptions[button.name] >= 1}"></span>
          <span class="indication-line__item"
                :class="{'active-line': accessOptions[button.name] >= 2}"></span>
        </div>
      </button>
      <button class="access-buttons__item access-btn access-btn-toggle"
              @click="hightLightLink">
        <div class="access-btn__checked"
             :class="{'access-btn__checked--active': isHighlighted}"></div>
        <div class="access-btn__icon icon-hightlight"></div>
        <p class="access-btn__description">{{ $gettext('Hightlighted links') }}</p>
        <div class="access-btn__indication-line indication-line">
          <span class="indication-line__item"></span>
          <span class="indication-line__item"></span>
        </div>
      </button>
    </div>
    <button class="access-options__reset-btn sk-btn--default"
            @click="resetAccessOptions">{{ $gettext('Reset options') }}</button>
  </div>
</template>)

<script>
  export default {
    data() {
      return {
        choosenClasses: Array(6).fill(null),
        isHighlighted: false,
        buttons: [
          {
            id: 0,
            name: 'font',
            text: this.$gettext('Font size'),
          },
          {
            id: 1,
            name: 'spacing',
            text: this.$gettext('Text spacing'),
          },
          {
            id: 2,
            name: 'line',
            text: this.$gettext('Line height'),
          },
          {
            id: 3,
            name: 'dyslexia',
            text: this.$gettext('Dyslexia'),
          },
          {
            id: 4,
            name: 'saturation',
            text: this.$gettext('Saturation'),
          },
          {
            id: 5,
            name: 'contrast',
            text: this.$gettext('Contrast'),
          },
        ],
        accessClasses: {
          font: ['font-size-bigger', 'font-size-large'],
          spacing: ['text-space-enlarged', 'text-space-large'],
          line: ['line-height-enlarged', 'line-height-large'],
          dyslexia: ['dyslexia-font', 'readible-font'],
          saturation: ['hight-saturation', 'desaturation'],
          contrast: ['invert-colors', 'dark-colors'],
        },
        accessOptions: {
          font: 0,
          spacing: 0,
          line: 0,
          dyslexia: 0,
          saturation: 0,
          contrast: 0,
        }
      };
    },
    methods: {
      saveAccessOptions() {
        localStorage.setItem('accessibilityOptions', JSON.stringify(this.choosenClasses));
        localStorage.setItem('accessibilityMode', JSON.stringify(this.accessOptions));
      },
      removeAccesOptions() {
        localStorage.removeItem('accessibilityOptions');
        localStorage.removeItem('accessibilityMode');
      },
      activateAccessibility(mode, id) {
        const currentClass = this.accessClasses[mode][this.accessOptions[mode]];
        const prevClass = this.accessClasses[mode][this.accessOptions[mode] - 1];
        this.accessOptions[mode] += 1;
        if (currentClass != undefined) {
          this.choosenClasses[id] = currentClass;
          document.documentElement.classList.add(currentClass);
        } else {
          this.choosenClasses[id] = null;
          this.accessOptions[mode] = 0;
        }
        document.documentElement.classList.remove(prevClass);
        this.choosenClasses.every((elem) => elem === null) === true ? this.removeAccesOptions() : this.saveAccessOptions();
      },
      selectAllClickableUI() {
        const links = document.querySelectorAll('a');
        const buttons = document.querySelectorAll('button');
        return [...links, ...buttons];
      },
      addHighlighted() {
        localStorage.setItem('accessibilityHighlightLinks', true);
        this.selectAllClickableUI().forEach((el) => {
          el.classList.add('highlighted-links');
          el.children[0] != undefined ? el.children[0].classList.add('highlighted-links--children') : '';
        });
      },
      removeHighlighted() {
        localStorage.removeItem('accessibilityHighlightLinks');
        this.selectAllClickableUI().forEach((el) => {
          el.classList.remove('highlighted-links');
          el.children[0] != undefined ? el.children[0].classList.remove('highlighted-links--children') : '';
        });
      },
      hightLightLink() {
        if (this.isHighlighted) {
          this.removeHighlighted();
        } else {
          this.addHighlighted();
        }
        this.isHighlighted = !this.isHighlighted;
      },
      resetAccessOptions() {
        document.documentElement.classList = '';
        this.choosenClasses = Array(6).fill(null);
        this.removeAccesOptions();
        this.removeHighlighted();
        this.isHighlighted = false;
        Object.keys(this.accessOptions).forEach((key) => {
          this.accessOptions[key] = 0;
        });
      },
    },
    mounted() {
      if (localStorage.getItem('accessibilityMode')) {
        this.accessOptions = JSON.parse(localStorage.getItem('accessibilityMode'));
        this.choosenClasses = JSON.parse(localStorage.getItem('accessibilityOptions'));
      }
      this.isHighlighted = localStorage.getItem('accessibilityHighlightLinks') !== null;
    },
  };
</script>

<style>
.access-options__title {
  margin-left: 10px;
  font-size: 20px;
}

.access-options {
  padding: 10px 15px;
}

.access-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  gap: 20px;
  justify-items: center;
  margin: 25px 0;
}

.access-btn-toggle {
  position: relative;
}

.access-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 110px;
  border: 1.5px solid #000;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
}

.access-btn:focus {
  outline: 1px solid #3af;
}

.access-btn:hover {
  background-color: #0038551a;
}

.access-btn__icon {
  width: 50px;
  height: 50px;
  background: no-repeat center/contain;
}

.access-btn__description {
  padding-top: 7px;
  text-align: center;
}

.access-btn__checked {
  position: absolute;
  top: 4px;
  right: 4px;
  display: none;
  width: 15px;
  height: 15px;
  background: no-repeat center/contain;
  background-image: var(--image-chechked-circle);
}

.access-btn__checked--active {
  display: block;
}

.icon-font {
  background-image: var(--image-font-size);
}

.icon-spacing {
  background-image: var(--image-text-spaicng);
}

.icon-line {
  background-image: var(--image-line-height);
}

.icon-dyslexia {
  background-image: var(--image-dyslexia);
}

.icon-saturation {
  background-image: var(--image-saturation);
}

.icon-contrast {
  background-image: var(--image-contrast);
}

.icon-hightlight {
  background-image: var(--image-hightlight-link);
}

.indication-line {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-top: 5px;
}

.indication-line__item {
  width: 45%;
  height: 4px;
  border-radius: 2px;
  background-color: var(--color-primary);
  opacity: 0;
}

.access-options__reset-btn {
  display: block;
  margin: 10px auto;
  padding: 12px 20px;
  border-radius: 3px;
}

.active-line {
  opacity: 1;
}

@media (max-width: 767px) {
  .access-btn {
    width: 115px;
    height: 115px;
  }
}
</style>
