(<template>
  <div class="direct-availability">
    <div class="direct-availability__title-wrapper">
      <p v-if="directBookingSelected"
         class="direct-availability__title">{{ availabilityTime }}</p>
      <router-link v-if="selectedSupplier"
                   :to="capacityLink"
                   target="_blank"
                   class="sk-btn sk-btn--white sk-btn--rounded direct-availability__calendar-link">{{ $gettext('Show full calendar') }}</router-link>
    </div>
    <availability-timeline :events-list="timelineEvents"
                           :selected-event="selectedEventData"
                           :date-range-from="assignmentStartTime"
                           :date-range-to="assignmentFinishTime"
                           :validation-name="availabilityErrorName"
                           :col-width="40"
                           :timeline-step="60"
                           :enable-event-description="true"
                           class="direct-availability__timeline"
                           @onclick="openEventsModal"
                           @overlapstatechanged="setOverlappedEventsState" />
  </div>
</template>)

<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';
  import AvailabilityTimeline from '@/components/shared_components/availability/AvailabilityTimeline';

  export default {
    components: {
      'availability-timeline': AvailabilityTimeline
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        contractInfo: (state) => {
          return state.jobCallDetails?.contractInfo?.length ? state.jobCallDetails.contractInfo[0] : [];
        }
      }),
      ...mapGetters('PostingNotifierStore', ['showDirectProcessingSection']),
      ...mapState('PostingStore/DirectBookingStore', {
        directBookingSelected: ({directBookingSelected}) => directBookingSelected,
        selectedSupplier: ({selectedSupplier}) => selectedSupplier,
        directBookingWarning: ({directBookingWarning}) => directBookingWarning,
        availabilityEvents: ({availabilityEvents}) => availabilityEvents || {},
        availabilityErrorName: ({errors}) => errors.availabilityError.name
      }),
      ...mapState('PostingStore/AssignmentTypeStore', {
        assignmentType: ({assignmentType}) => assignmentType
      }),
      ...mapState('PostingStore/DateTimeStore', {
        startTime: ({startTime}) => startTime,
        finishTime: ({finishTime}) => finishTime
      }),
      assignmentStartTime() { return this.startTime ? this.$moment(this.startTime).format('YYYY-MM-DD HH:mm') : ''; },
      assignmentFinishTime() { return this.finishTime ? this.$moment(this.finishTime).format('YYYY-MM-DD HH:mm') : ''; },
      selectedEvent() {
        const startTime = this.assignmentStartTime;
        const finishTime = this.assignmentFinishTime;
        const sessionType = this.assignmentType;
        if (startTime && finishTime && sessionType) {
          return {
            startTime,
            finishTime,
            sessionType
          };
        }
      },
      selectedEventData() {
        return this.selectedEvent ? [this.selectedEvent, 'job'] : null;
      },
      travelTimes() {
        return this.availabilityEvents.travelTimes || [];
      },
      jobEvents() {
        return this.availabilityEvents.jobs || [];
      },
      events() {
        return this.availabilityEvents.events?.map((event = {}) => {
          event.allowPosting = event.eventType === 3;
          return event;
        });
      },
      timelineEvents() {
        return [
          [this.events || [], 'event'],
          [this.jobEvents, 'job'],
          [this.availabilityEvents.offTimes || [], 'offtime'],
          [this.travelTimes[0] || [], 'travelFromTime'],
          [this.travelTimes[1] || [], 'travelToTime']
        ];
      },
      availabilityTime() {
        const date = this.$moment(this.startTime);
        const formattedDate = date.format('DD/MM/YYYY');
        const template = this.$gettext('Interpreter availability on %{time}');
        return this.$gettextInterpolate(template, {time: formattedDate});
      },
      capacityLink() {
        return this.$makeRoute({
          name: 'BuyerCapacity',
          query: {
            view: 'day',
            date: this.startDate,
            page: 1,
            interpreterUid: this.selectedSupplier
          }
        });
      },
    },
    methods: {
      ...mapMutations('PostingStore/DirectBookingStore', [
        'setOverlappedEventsState'
      ]),
      openEventsModal() {
        if (this.jobEvents.length) {
          this.$store.commit('ModalStore/setModal', {
            component: 'availability-events-modal',
            classes: ['availability-modal'],
            width: 600,
            data: {
              data: {
                interpreter: {
                  fullName: this.contractInfo.name,
                  phoneNumber: this.contractInfo.phone
                },
                interpreterJobs: this.jobEvents
              },
              context: this
            }
          });
        }
      },
    }
  };
</script>

<style>
  #app.salita a.direct-availability__calendar-link {
    max-width: 140px;
    background-color: transparent;
  }

  #app.salita-facelifted a.direct-availability__calendar-link {
    max-width: 140px;
    border-color: #d0d3dc;
    color: #000;
  }

  #app.salita-facelifted a.direct-availability__calendar-link:hover {
    border-color: #d0d3dc;
    background-color: #f4f5f7;
    color: #000;
  }
</style>

<style scoped>
  .direct-availability__title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .direct-availability__title {
    font-weight: bold;
  }
</style>
