<template>
  <ul class="avatars-list"
      :style="compWidth">
    <li v-for="(avatar, i) in compAvatars"
        :key="i"
        class="avatar-wrapper"
        :style="{ left: `${i * 20}px` }">
      <div v-if="avatar.id === 'remainingAvatars'"
           class="avatar__remaining-items">
        {{ '+'+ avatar.count }}
      </div>
      <tt-avatar v-else
                 class="avatar"
                 :class="{'inactive': avatar.status === 'inactive'}"
                 :avatar="avatar.avatarPath"
                 @avatarclick="emitClick"
                 @touchstart="emitTouchStart"
                 @touchend="emitTouchEnd" />

    </li>
  </ul>
</template>

<script>
  export default {
    name: 'avatars-list',
    props: {
      avatars: {
        type: Array,
        default: () => {}
      },
      maxAvatars: {
        type: Number,
        default: 0
      }
    },
    computed: {
      avatarsLength() { return this.avatars.length; },
      compAvatars() {
        if (this.maxAvatars && (this.avatarsLength > this.maxAvatars)) {
          const visibleAvatars = this.avatars.slice(0, this.maxAvatars);
          visibleAvatars.push({
            id: 'remainingAvatars',
            name: '',
            count: this.avatarsLength - this.maxAvatars
          });
          return visibleAvatars;
        } else {
          return this.avatars;
        }
      },
      compWidth() {
        const leng = this.avatarsLength;
        const style = {};
        style.width = leng * 20 + 'px';
        return style;
      }
    },
    methods: {
      emitClick(eventArg) { this.$emit('avatarclick', eventArg); },
      emitTouchStart(eventArg) { this.$emit('touchstart', eventArg); },
      emitTouchEnd(eventArg) { this.$emit('touchend', eventArg); },
    }
  };
</script>
<style scoped>
.avatar,
.avatar__remaining-items {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  box-shadow: none;
}

.avatar.inactive {
  opacity: 0.3;
}

.avatar__remaining-items {
  margin-bottom: 5px;
  padding-top: 3px;
  border-radius: 50%;
  background-color: #fff4f0;
  color: #ff5b24;
  font-weight: 600;
  text-align: center;
}

.avatars-list {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 70px;
  padding-bottom: 3px;
}

.avatar-wrapper {
  position: absolute;
  padding: 5px;
}

ul {
  list-style-type: none;
}
</style>
