export default ({$fetcher: fetchData} = {}) => {
  return {
    namespaced: true,
    state: {
      blockedSuppliers: [],
      blockedSuppliersFullList: [],
      blockedSuppliersSelect: false,
      suppliersList: '',
      supplierSearchQuery: ''
    },
    actions: {
      searchSupplierByName({commit}, searchName) {
        return new Promise((resolve, reject) => {
          const params = {nameSearch: searchName};
          fetchData.fetchInterpreters(params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setSuppliersList', data.interpreters);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      cancelSearchInterpreters() {
        return new Promise((resolve, reject) => {
          fetchData.cancelSearchInterpreters().then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      }
    },
    mutations: {
      setBlockedSuppliers(state, data) {
        state.blockedSuppliers = data;
      },
      setBlockedSuppliersFullList(state, data) {
        state.blockedSuppliersFullList = data;
      },
      addBlockedSuppliersFullListItem(state, data) {
        state.blockedSuppliersFullList.push(data);
      },
      setBlockedSuppliersSelect(state, value) {
        state.blockedSuppliersSelect = value;
        state.blockedSuppliers = [];
        state.blockedSuppliersFullList = [];
        state.suppliersList = '';
        state.supplierSearchQuery = '';
      },
      setSuppliersList(state, data) {
        state.suppliersList = data;
      },
      setSupplierSearchQuery(state, value) {
        state.supplierSearchQuery = value;
      },
      clearStore(state) {
        state.blockedSuppliersSelect = false;
        state.blockedSuppliers = [];
        state.blockedSuppliersFullList = [];
        state.suppliersList = '';
        state.supplierSearchQuery = '';
      }
    }
  };
};
