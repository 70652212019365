(<template>
  <div class="step-3">
    <p class="step-3__text">{{ descriptionText }}</p>
    <form class="step-3__form"
          @submit.prevent.stop="submitForm">
      <div class="step-3__input-wrapper">
        <sk-input :description="bankAccountNumberText"
                  :placeholder="bankAccountNumberText"
                  :validation-name="bankAccountNumberErrorName"
                  :preselected-value="bankAccountNumber"
                  :reg-exp="/[^0-9]/g"
                  :mob-numeric-keyboard="true"
                  :max-length="11"
                  class="step-3__input"
                  @fieldvaluechanged="setBankAccountNumber" />
        <org-number-field v-if="userHasCompany"
                          :preselected-value="organizationNumber"
                          :description="organizationNumberText"
                          :placeholder="organizationNumberText"
                          :validation-name="organizationNumberErrorName"
                          :mob-numeric-keyboard="true"
                          :reg-exp="/[^0-9]/g"
                          :country="'Norway'"
                          :max-length="9"
                          class="step-3__input"
                          @fieldvaluechanged="setOrganizationNumber" />
        <sk-input v-else
                  :description="personalNumberText"
                  :placeholder="personalNumberText"
                  :validation-name="personalNumberErrorName"
                  :preselected-value="personalNumber"
                  :reg-exp="/[^0-9]/g"
                  :mob-numeric-keyboard="true"
                  :max-length="11"
                  class="step-3__input"
                  @fieldvaluechanged="setPersonalNumber" />
      </div>
      <sk-checkbox :preselected-value="confirmationValue"
                   :validation-name="confirmationValidationName"
                   :item-text="confirmationText"
                   class="step-3__checkbox"
                   @checkboxvaluechanged="setConfirmationValue" />
      <div class="step-3__btn-wrapper">
        <button type="button"
                class="sk-btn sk-btn--white step-3__btn"
                @click="$emit('gotoback')">{{ $gettext('Go back') }}</button>
        <button class="sk-btn sk-btn--default step-3__btn">{{ $gettext('Save') }}</button>
      </div>
    </form>
  </div>
</template>)

<script>
  import {mapMutations, mapState} from 'vuex';
  import OrgNumberField from '@/components/enterprise/form_parts/OrgNumberField';
  import PayoutValidation from '@/prototypes/PayoutValidation';

  export default {
    components: {
      'org-number-field': OrgNumberField
    },
    extends: PayoutValidation,
    data() {
      return {
        confirmationValue: false,
        confirmationValidationName: 'confirmationValidation',
        confirmationErrors: []
      };
    },
    computed: {
      ...mapState('TTPayoutStore', {
        userHasCompany: ({payoutInfoWizard}) => payoutInfoWizard.userHasCompany,
        userHasOwnProvider: ({payoutInfoWizard}) => payoutInfoWizard.userHasOwnProvider,
        firstName: ({newAccount}) => newAccount.firstName,
        lastName: ({newAccount}) => newAccount.lastName,
        bankAccountNumber: ({newAccount}) => newAccount.bankAccountNumber,
        personalNumber: ({newAccount}) => newAccount.personalNumber,
        organizationNumber: ({newAccount}) => newAccount.organizationNumber,
        freelanceType: ({newAccount}) => newAccount.freelanceType,
        // validation names
        bankAccountNumberErrorName: ({errors}) => errors.bankAccountNumber.name,
        personalNumberErrorName: ({errors}) => errors.personalNumber.name,
        organizationNumberErrorName: ({errors}) => errors.organizationNumber.name
      }),
      descriptionText() {
        return this.userHasCompany
          ? this.$gettext('Please provide your Bank account number and your Organisation number')
          : this.$gettext('Please provide your Bank account number and your Personal number');
      },
      bankAccountNumberText() { return this.$gettext('Bank account number'); },
      personalNumberText() { return this.$gettext('Personal number'); },
      organizationNumberText() { return this.$gettext('Organisation number'); },
      confirmationText() {
        const template = this.$gettext('I accept %{domain}\'s <a class="sk-link" href="%{termsLink}" target="_blank">terms and conditions</a>');
        return this.userHasCompany
          ? this.$gettext('I accept PayoutPartner AS <a class="sk-link" href="https://payoutpartner.com/privacy_and_terms.html" target="_blank">terms and conditions</a>.')
          : this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName'), termsLink: this.$getString('domainData', 'termLink')});
      }
    },
    methods: {
      ...mapMutations('TTPayoutStore', [
        'setFirstName',
        'setLastName',
        'setFreelanceType',
        'setBankAccountNumber',
        'setPersonalNumber',
        'setOrganizationNumber'
      ]),
      setConfirmationValue(value) {
        this.confirmationValue = value;
      },
      validateForm() {
        this.removeErrors();
        return this.validatePersonalInfo()
          & this.validateBankAccountNumber()
          & this.validateFreelanceNumber()
          & this.validateConfirmationValue();
      },
      validateConfirmationValue() {
        let isValidForm = true;

        if (!this.userHasOwnProvider) {
          this.confirmationErrors = [];
          if (!this.confirmationValue) {
            this.confirmationErrors.push(this.$gettext('Confirmation is required'));
            this.$store.commit('ErrorsStore/setError', {
              name: this.confirmationValidationName,
              errors: this.confirmationErrors
            });
            isValidForm = false;
          }
        }
        return isValidForm;
      },
      setPersonalInfo() {
        this.setFreelanceType(this.userHasCompany ? 'ORGANIZATION' : 'INDIVIDUAL');
        this.setFirstName(this.$store.getters['UserInfoStore/userFirstName']);
        this.setLastName(this.$store.getters['UserInfoStore/userLastName']);
      },
      submitForm() {
        if (this.validateForm()) {
          this.$emit('gotonext');
        }
      }
    },
    beforeMount() {
      this.setPersonalInfo();
    },
    beforeDestroy() {
      this.$store.commit('ErrorsStore/removeAllErrors');
    }
  };
</script>

<style scoped>
  .step-3__input-wrapper {
    display: flex;
    margin: 10px -10px;
  }

  .step-3__input {
    margin: 10px;
  }

  .step-3__btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 20px -10px 0;
  }

  .step-3__btn {
    max-width: 170px;
    margin: 10px 10px 0;
    padding: 0 20px;
  }

  @media (max-width: 500px) {
    .step-3__btn-wrapper {
      flex-wrap: wrap;
    }

    .step-3__btn {
      max-width: 100%;
    }
  }
</style>
