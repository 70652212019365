import Vue from 'vue';
import axios from 'axios';
import constants from '@/modules/constants';
// import faker from '@/modules/fake_data';
import {cookieManager} from '@skiwo/sk-basic-components';
import preferences from './endpoints/preferences';
import orders from './endpoints/orders';
import subtasks from './endpoints/subtasks';
import enterprises from './endpoints/enterprises';
import dashboards from './endpoints/dashboards';
import interpreters from './endpoints/interpreters';
import invoicing from './endpoints/invoicing';
import paymentMethods from './endpoints/paymentMethods';
import videoTests from './endpoints/videoTests';
import travelExpenses from './endpoints/travelExpenses';
import tickets from './endpoints/tickets';
import payoutMethods from './endpoints/payoutMethods';
import adminDiscussion from './endpoints/adminDiscussion';
import signatures from './endpoints/signatures';
import phoneVerification from './endpoints/phoneVerification';
import auth from './endpoints/auth';
import peoples from './endpoints/peoples';
import jobs from './endpoints/jobs';
import discussions from './endpoints/discussions';
import profiles from './endpoints/profiles';
import oldTranslations from './endpoints/oldTranslations';
import other from './endpoints/other';
import sellers from './endpoints/sellers';
import customers from './endpoints/customers';
import suppliers from './endpoints/suppliers';
import statistics from './endpoints/statistics';
import interpretationSkills from './endpoints/interpretationSkills';
import translationSkills from './endpoints/translationSkills';
import conversations from './endpoints/conversations';
import frontendServer from './endpoints/frontendServer';
import documents from './endpoints/documents';

export function createFetcher(locale, store) {
  return new Vue({
    mixins: [
      orders,
      subtasks,
      preferences,
      enterprises,
      dashboards,
      interpreters,
      invoicing,
      paymentMethods,
      videoTests,
      travelExpenses,
      tickets,
      payoutMethods,
      adminDiscussion,
      signatures,
      phoneVerification,
      auth,
      peoples,
      jobs,
      discussions,
      profiles,
      oldTranslations,
      other,
      sellers,
      customers,
      suppliers,
      statistics,
      interpretationSkills,
      translationSkills,
      conversations,
      frontendServer,
      documents
    ],
    data() {
      return {
        userToken: '',
        uid: '',
        locale: locale || 'nb',
        ajax: '',
        authAjax: '',
        airbrakenAjax: '',
        financeAjax: '',
        pushAjax: '',
        cancelationSource: ''
      };
    },
    watch: {
      userToken() {
        this.initAxiosInstances();
        this.initAxiosInterceptors();
        this.cancelationSource = axios.CancelToken.source();
      },
      locale() {
        this.initAxiosInstances();
        this.initAxiosInterceptors();
      },
    },
    methods: {
      initAxiosInstances() {
        const commonHeaders = {
          'X-Client-App': 'VueApp/1.0',
          'Accept-Language': this.locale.trim(),
          Authorization: `Bearer ${this.userToken}`.trim()
        };

        this.authAjax = axios.create({
          baseURL: constants.AUTH_API_PATH,
          headers: commonHeaders
        });
        this.ajax = axios.create({
          baseURL: constants.API_PATH,
          headers: commonHeaders
        });
        this.financeAjax = axios.create({
          baseURL: constants.FINANCE_API_PATH,
          headers: commonHeaders
        });
        this.pushAjax = axios.create({
          baseURL: constants.PUSH_API_PATH,
          headers: {'X-Client-App': 'VueApp/1.0', Authorization: `Bearer ${this.userToken}`.trim()}
        });
        this.airbrakenAjax = axios.create({
          baseURL: constants.API_PATH,
          headers: commonHeaders
        });
      },
      respSuccessInterceptor(response) {
        return response;
      },
      respErrorsInterceptor(err) {
        const resp = err ? err.response || {} : {};
        const {status} = err.response;
        if (status === 429) {
          store.commit('TTAuthStore/logOut', '', {root: true});
        }
        if (status === 401) {
          store.commit('TTAuthStore/logOut', '', {root: true});
          window.location.reload();
        }
        return Promise.reject(resp);
      },
      initAxiosInterceptors() {
        this.authAjax.interceptors.response.use(this.respSuccessInterceptor, this.respErrorsInterceptor);
        this.ajax.interceptors.response.use(this.respSuccessInterceptor, this.respErrorsInterceptor);
        this.financeAjax.interceptors.response.use(this.respSuccessInterceptor, this.respErrorsInterceptor);
        this.pushAjax.interceptors.response.use(this.respSuccessInterceptor, this.respErrorsInterceptor);
        this.airbrakenAjax.interceptors.response.use(this.respSuccessInterceptor, (err) => {
          return Promise.reject(err || {});
        });
      },
      resolveError403(error, resolve, message) {
        if (error?.status == 403) {
          if (message) {
            // eslint-disable-next-line
            console.log(message);
            resolve({code: 403});
          } else {
            // eslint-disable-next-line
            console.log(`Request ${error?.config?.url} responded with error code ${error?.status}`);
            resolve({code: 403});
          }
          return;
        }
      },
      resolveError404(error, resolve, message) {
        if (error?.status == 404) {
          if (message) {
            // eslint-disable-next-line
            console.log(message);
            resolve({code: 404});
          } else {
            // eslint-disable-next-line
            console.log(`Request ${error?.config?.url} responded with error code ${error?.status}`);
            resolve({code: 404});
          }
          return;
        }
      },
      resolveError500(error, resolve, message) {
        if (error?.status == 500) {
          if (message) {
            // eslint-disable-next-line
            console.log(message);
            resolve({code: 500});
          } else {
            // eslint-disable-next-line
            console.log(`Request ${error?.config?.url} responded with error code ${error?.status}`);
            resolve({code: 500});
          }
          return;
        }
      }
    },
    created() {
      const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');
      if (isBrowser()) this.locale = cookieManager.getCookie('locale') || 'nb';
      this.initAxiosInstances();
      this.initAxiosInterceptors();
    }
  });
}
