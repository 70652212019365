export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      // CREATION
      form: {
        orgNumber: '',
        orgName: '',
        webSite: '',
        phoneCode: '+47',
        phoneNumber: '',
        line1: '',
        line2: '',
        county: '',
        postcode: '',
        city: '',
        country: 'Norway',
        logoUrl: '',
        logoFile: '',
        adminEmail: '',
        terms: false,
        logoRotation: 0,
        paymentOrgNumber: '',
        paymentCompany: ''
      },
      errors: {
        logo: {errors: [], name: 'logoError'},
        orgNumber: {errors: [], name: 'orgNumberError'},
        orgName: {errors: [], name: 'orgNameError'},
        phoneCode: {errors: [], name: 'phoneCodeError'},
        phoneNumber: {errors: [], name: 'phoneNumberError'},
        line1: {errors: [], name: 'line1Error'},
        county: {errors: [], name: 'countyError'},
        country: {errors: [], name: 'countryError'},
        postcode: {errors: [], name: 'postcodeError'},
        city: {errors: [], name: 'cityError'},
        adminEmail: {errors: [], name: 'adminEmailError'},
        terms: {errors: [], name: 'termsError'},
        paymentOrgNumber: {errors: [], name: 'paymentOrgNumberError'},
        paymentCompany: {errors: [], name: 'paymentCompanyError'}
      },
      // SHOW
      companyInfo: ''
    },
    actions: {
      // CREATION
      createForm({state, rootGetters}, update) {
        const form = new FormData();

        form.append('enterprise[org_number]', state.form.orgNumber.trim());
        form.append('enterprise[name]', state.form.orgName.trim());
        if (update) {
          if (state.form.webSite.trim()) {
            form.append('enterprise[website]', state.form.webSite.trim());
          }
          if (state.form.phoneNumber.trim()) {
            form.append('enterprise[phone_code]', state.form.phoneNumber.trim() ? state.form.phoneCode : '');
            form.append('enterprise[phone_number]', state.form.phoneNumber.trim());
          }
          if (state.form.adminEmail.trim()) {
            form.append('enterprise[admin_email]', state.form.adminEmail.toLowerCase().trim());
          }
          if (state.form.line1.trim()) {
            form.append('enterprise[address][line_1]', state.form.line1.trim());
          }
          if (state.form.line2.trim()) {
            form.append('enterprise[address][line_2]', state.form.line2.trim());
          }
          if (state.form.city.trim()) {
            form.append('enterprise[address][city]', state.form.city.trim());
          }
          if (state.form.county.trim()) {
            form.append('enterprise[address][county]', state.form.county.trim());
          }
          if (state.form.postcode.trim()) {
            form.append('enterprise[address][postcode]', state.form.postcode.trim());
          }
          if (state.form.country.trim()) {
            form.append('enterprise[address][country]', state.form.country.trim());
          }
          if (state.form.logoRotation) {
            form.append('enterprise[image_rotation]', state.form.logoRotation);
          }
        } else {
          form.append('enterprise[price_setting][currency_id]', rootGetters['UserInfoStore/userCurrencyId']);
          // payment
          form.append('enterprise[payment_method][org_number]', state.form.paymentOrgNumber.trim());
          form.append('enterprise[payment_method][default]', true);
        }
        if ((update && state.form.logoFile) || !update) {
          form.append('enterprise[logo]', state.form.logoFile);
        }

        return Promise.resolve(form);
      },
      createEnterprise({commit}, form) {
        return new Promise((resolve, reject) => {
          fetchData.createEnterprise(form).then((data) => {
            commit('setCompanyInfo', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      // SHOW
      getEnterprise({commit}, id) {
        return new Promise((resolve, reject) => {
          fetchData.getEnterprise(id).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setCompanyInfo', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      updateEnterprise({commit, rootGetters}, form) {
        return new Promise((resolve, reject) => {
          fetchData.updateEnterprise(rootGetters['UserInfoStore/enterpriseId'], form).then((data) => {
            if (data.enterprise && data.enterprise.logo) {
              commit('UserInfoStore/setEnterpriseLogo', data.enterprise.logo, {root: true});
            }
            commit('setCompanyInfo', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      }
    },
    mutations: {
      // CREATION
      setError(state, {fieldName, errorText}) {
        state.errors[fieldName].errors = [...state.errors[fieldName].errors, errorText];
      },
      removeOneError(state, fieldName) {
        state.errors[fieldName].errors = [];
      },
      removeErrors(state) {
        for (const key of Object.keys(state.errors)) {
          state.errors[key].errors = [];
        }
      },
      setOrgNumber(state, value) { state.form.orgNumber = value; },
      setOrgName(state, value) { state.form.orgName = value; },
      setWebSite(state, value) { state.form.webSite = value; },
      setPhoneCode(state, value) { state.form.phoneCode = value; },
      setPhoneNumber(state, value) { state.form.phoneNumber = value; },
      setLine1(state, value) { state.form.line1 = value; },
      setLine2(state, value) { state.form.line2 = value; },
      setCounty(state, value) { state.form.county = value; },
      setPostcode(state, value) { state.form.postcode = value; },
      setCity(state, value) { state.form.city = value; },
      setCountry(state, value) { state.form.country = value; },
      setLogoUrl(state, value) { state.form.logoUrl = value; },
      setLogoFile(state, value) { state.form.logoFile = value; },
      setAdminEmail(state, value) { state.form.adminEmail = value; },
      setTerms(state, value) { state.form.terms = value; },
      setLogoRotation(state) { state.form.logoRotation = (state.form.logoRotation + 90) % 360; },
      resetLogoRotation(state) { state.form.logoRotation = 0; },
      setPaymentOrgNumber(state, value) { state.form.paymentOrgNumber = value; },
      setPaymentCompany(state, value) { state.form.paymentCompany = value; },
      prefillEditForm(state) {
        state.form.orgNumber = state.companyInfo.org_number || '';
        state.form.orgName = state.companyInfo.name || '';
        state.form.webSite = state.companyInfo.website || '';
        state.form.phoneCode = state.companyInfo.phoneCode || '+47';
        state.form.phoneNumber = state.companyInfo.phoneNumber || '';
        state.form.line1 = state.companyInfo.address ? state.companyInfo.address.line_1 || '' : '';
        state.form.line2 = state.companyInfo.address ? state.companyInfo.address.line_2 || '' : '';
        state.form.county = state.companyInfo.address ? state.companyInfo.address.county || '' : '';
        state.form.postcode = state.companyInfo.address ? state.companyInfo.address.postcode || '' : '';
        state.form.city = state.companyInfo.address ? state.companyInfo.address.city || '' : '';
        state.form.country = state.companyInfo.address ? state.companyInfo.address.country || '' : '';
        state.form.logoUrl = state.companyInfo.logo || '';
        state.form.adminEmail = state.companyInfo.admin_email || '';
      },
      resetForm(state) {
        state.form = {
          orgNumber: '',
          orgName: '',
          webSite: '',
          phoneCode: '+47',
          phoneNumber: '',
          line1: '',
          line2: '',
          county: '',
          postcode: '',
          city: '',
          country: 'Norway',
          logoUrl: '',
          logoFile: '',
          adminEmail: '',
          terms: false,
          logoRotation: 0,
          paymentOrgNumber: '',
          paymentCompany: ''
        };
      },
      // SHOW
      setCompanyInfo(state, data) { state.companyInfo = data.enterprise; },
      removeCompanyInfo(state) { state.companyInfo = ''; }
    }
  };
};
