<template>
  <sk-input :type="type"
            :description="description"
            :preselected-value="preselectedValue"
            :placeholder="placeholder"
            :autocomplete="false"
            :input-icon="inputIcon"
            :validation-name="validationName"
            :mob-numeric-keyboard="mobNumericKeyboard"
            :reg-exp="regExp"
            @fieldvaluechanged="catchSearchFieldChange" />
</template>

<script>
  import SearchPrototype from '@/components/shared_components/form_fields/SearchPrototype';

  export default {
    extends: SearchPrototype,
    props: {
      submitAfterCondition: {
        type: Function,
        default: undefined,
        required: false
      },
      type: {
        type: String, // possible types: [text, email, tel, password]
        default: ''
      },
      inputIcon: {
        type: String,
        default: ''
      },
      validationName: {
        type: String, // connects with ErrorsStorage
        default: ''
      },
      preselectedValue: {
        type: [String, Number],
        required: true
      },
      description: {
        type: String,
        default: ''
      },
      mobNumericKeyboard: {
        type: Boolean
      },
      placeholder: {
        type: String,
        default: ''
      },
      regExp: {
        type: RegExp, // replace entered characters with allowed in regular expression
        default: () => {
          return /[*]/g;
        }
      }
    },
    methods: {
      changeFieldData(value) {
        this.$emit('fieldvaluechanged', value);
      },
      catchSearchFieldChange(value) {
        if (this.submitAfterCondition) {
          if (this.submitAfterCondition(value)) {
            this.setSearchQuery(value);
          } else {
            this.resetErrors();
            this.changeFieldData(value);
          }
        } else {
          this.setSearchQuery(value);
        }
      },
      search(value) {
        this.startSearchRequest();
        this.changeFieldData(value);
        this.submitAction(value).then((data) => {
          this.handleSearchResponse();
          this.$emit('onsuccess', data);
        }).catch((error) => {
          this.handleSearchResponse();
          this.$emit('onerror', error);
        });
      },
      resetFilter() {
        this.resetErrors();
        this.changeFieldData('');
        this.setSearchQueryMutation('');
      }
    }
  };
</script>
