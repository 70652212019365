export default () => {
  return {
    namespaced: true,
    state: {
      autoInvite: true,
      infiniteAutoInvite: false
    },
    mutations: {
      setAutoInvite(state, value) {
        state.autoInvite = value;
      },
      setInfiniteAutoInvite(state, value) {
        state.infiniteAutoInvite = value;
      },
      clearStore(state) {
        state.autoInvite = true;
        state.infiniteAutoInvite = false;
      }
    }
  };
};
