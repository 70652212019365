(<template>
  <article :class="{'in-progress': progressActive}"
           class="send-details-modal is-progress-bar">
    <h2 class="send-details-modal__title">{{ titleText }}</h2>
    <sk-note :text="infoText"
             class="send-details-modal__info" />
    <div class="send-details-modal__content">
      <sk-select :type="'countryCodes'"
                 :preselected-value="phoneCode"
                 :default-value="codeText"
                 :description="codeText"
                 :mob-numeric-keyboard="true"
                 :validation-name="phoneCodeValidationName"
                 class="send-details-modal__code"
                 @csvaluechanged="catchCodeSelectValue" />
      <sk-input :preselected-value="phoneNumber"
                :description="numberText"
                :placeholder="placeholderText"
                :validation-name="phoneNumberValidationName"
                :type="'tel'"
                :reg-exp="/[^0-9]/g"
                :mob-numeric-keyboard="true"
                class="send-details-modal__number"
                @fieldvaluechanged="catchPhoneNumber" />
    </div>
    <div class="send-details-modal__btn-cont">
      <button class="sk-btn sk-btn--default send-details-modal__btn"
              @click="sendDetails">{{ sendText }}</button>
    </div>
  </article>
</template>)

<script>
  import {mapActions} from 'vuex';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        progressActive: false,
        phoneCode: '+47',
        phoneCodeErrors: [],
        phoneCodeValidationName: 'phoneCode',
        phoneNumber: '',
        phoneNumberErrors: [],
        phoneNumberValidationName: 'phoneNumber'
      };
    },
    methods: {
      ...mapActions('OneAssignmentStore', ['sendJobDetails']),
      catchCodeSelectValue(value) { this.phoneCode = value; },
      catchPhoneNumber(value) { this.phoneNumber = value; },
      isAnyErrors() {
        this.phoneCodeErrors = [];
        this.phoneNumberErrors = [];
        if (!this.phoneCode) this.phoneCodeErrors.push(this.$gettext('Please choose a country code.'));
        if (!this.phoneNumber.trim()) {
          this.phoneNumberErrors.push(this.numberRequiredText);
        } else if (Number.isNaN(+this.phoneNumber)) {
          this.phoneNumberErrors.push(this.numberIsNotValidText);
        }
        this.$store.commit('ErrorsStore/setError', {name: this.phoneCodeValidationName, errors: this.phoneCodeErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.phoneNumberValidationName, errors: this.phoneNumberErrors});
        return this.phoneNumberErrors.length || this.phoneCodeErrors.length;
      },
      preselectData() {
        const phone = this.data.phone;

        this.phoneCode = phone ? phone.slice(0, 4) : this.data.phoneCode || '';
        this.phoneNumber = phone ? phone.slice(4) : this.data.phoneNumber || '';
      }
    },
    beforeMount() {
      this.$store.commit('ErrorsStore/removeAllErrors');
    },
    mounted() {
      this.preselectData();
    }
  };
</script>

<style>
  .send-sms-modal .sk-modal__wrapper {
    overflow: visible;
  }

  .send-details-modal__code .sk-select__list {
    height: 99px;
  }

  .send-details-modal__code .sk-select {
    width: 80px;
  }

  .send-details-modal__code .sk-select :first-child {
    font-weight: bold;
  }
</style>

<style scoped>
  .send-details-modal {
    padding: 15px;
  }

  .send-details-modal__title {
    margin: 20px auto;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    text-align: center;
  }

  .send-details-modal__info {
    margin-bottom: 20px;
  }

  .send-details-modal__content {
    display: flex;
  }

  .send-details-modal__code {
    margin-right: 20px;
  }

  .send-details-modal__btn-cont {
    display: flex;
    justify-content: flex-end;
  }

  .send-details-modal__btn {
    width: auto;
    padding: 0 10px;
  }
</style>
