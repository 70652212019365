export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      skillData: {
        langFrom: 3,
        langTo: '',
        dialectTo: '',
        qualification: '',
        industry: '',
        certificates: [],
        id: '',
        approved: false,
        declined: false
      },
      skillsNeedDialect: [],
      newCertifTitle: '',
      skillType: '',
      tolkId: '',
      errors: {
        langFrom: {name: 'langFromError', errors: []},
        langTo: {name: 'langToError', errors: []},
        dialectTo: {name: 'dialectToError', errors: []},
        qualification: {name: 'qualificationError', errors: []},
        upload: {name: 'uploadError', errors: []},
      }
    },
    getters: {
      isTranslation(state) { return state.skillType == 'translation'; },
      isInterpretation(state) { return state.skillType == 'interpretation'; },
    },
    actions: {
      sendCertificate({rootGetters}, file) {
        return new Promise((resolve, reject) => {
          fetchData.sendCertificate(rootGetters['UserInfoStore/userUid'], file).then((data) => {
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      createInterpretationForm({state, rootState}, {status, silent}) {
        return new Promise((resolve) => {
          const form = new FormData();
          form.append('skill[lang_from_id]', state.skillData.langFrom);
          form.append('skill[lang_to_id]', state.skillData.langTo);
          form.append('skill[qualification_id]', state.skillData.qualification);

          // interpretation only property
          form.append('skill[dialect_to]', state.skillData.dialectTo);

          form.append('skill[silent]', !!silent);
          if (status) form.append('skill[status]', status);

          const {attachments} = rootState.PostingStore.AttachmentsStore;
          if (attachments && attachments.length) {
            for (const file of attachments) {
              // deleted certificate
              if (file.deleted && file.certificateFileId) {
                form.append('skill[certificates_attributes][][id]', file.id);
                form.append('skill[certificates_attributes][][_destroy]', true);
                // existing certificate
              } else if (file.certificateFileId) {
                form.append('skill[certificates_attributes][][id]', file.id);
                form.append('skill[certificates_attributes][][desc]', state.newCertifTitle);
                form.append('skill[certificates_attributes][][certificate_file_id]', file.certificateFileId);
                // new certificate
              } else if (!file.deleted && file.filenameSearchValue) {
                form.append('skill[certificates_attributes][][certificate_file_id]', file.id);
                form.append('skill[certificates_attributes][][desc]', state.newCertifTitle);
              }
            }
          }
          resolve(form);
        });
      },
      checkSkill({commit}, id) {
        return new Promise((resolve, reject) => {
          fetchData.checkInterpreterSkill(id).then((data) => {
            commit('setSkillWithoutDialects', data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      removeCheckSkill({rootGetters}, id) {
        return new Promise((resolve, reject) => {
          fetchData.removeCheckSkill(rootGetters['UserInfoStore/userUid'], id).then(() => {
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      createSkill({rootGetters, dispatch}, {silent} = {}) {
        return new Promise((resolve, reject) => {
          dispatch('createInterpretationForm', {silent}).then((form) => {
             fetchData.createInterpreterSkill(rootGetters['UserInfoStore/userUid'], form).then((data) => {
               resolve(data);
             }).catch((response) => {
               reject(response);
             });
          });
        });
      },
      updateSkill({rootGetters, dispatch}, {id, status, silent}) {
        return new Promise((resolve, reject) => {
          dispatch('createInterpretationForm', {status, silent}).then((form) => {
             fetchData.updateInterpreterSkill(rootGetters['UserInfoStore/userUid'], form, id).then((data) => {
               resolve(data);
             }).catch((response) => {
               reject(response);
             });
          });
        });
      },
      removeSkill({rootGetters}, id) {
        return new Promise((resolve, reject) => {
          fetchData.removeSkill(rootGetters['UserInfoStore/userUid'], id).then(() => {
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      createTranslationForm({rootState, state}) {
        return new Promise((resolve) => {
          const form = new FormData();
          form.append('translation_skill[language_from_id]', state.skillData.langFrom);
          form.append('translation_skill[language_to_id]', state.skillData.langTo);
          form.append('translation_skill[qualification_id]', state.skillData.qualification);

          const {attachments} = rootState.PostingStore.AttachmentsStore;
          if (attachments && attachments.length) {
            for (const file of attachments) {
              // deleted certificate
              if (file.deleted && file.certificateFileId) {
                form.append('translation_skill[certificates_attributes][][id]', file.id);
                form.append('translation_skill[certificates_attributes][][_destroy]', true);
                // existing certificate
              } else if (file.certificateFileId) {
                form.append('translation_skill[certificates_attributes][][id]', file.id);
                form.append('translation_skill[certificates_attributes][][desc]', state.newCertifTitle);
                form.append('translation_skill[certificates_attributes][][certificate_file_id]', file.certificateFileId);
                // new certificate
              } else if (!file.deleted && file.filenameSearchValue) {
                form.append('translation_skill[certificates_attributes][][certificate_file_id]', file.id);
                form.append('translation_skill[certificates_attributes][][desc]', state.newCertifTitle);
              }
            }
          }
          resolve(form);
        });
      },
      createTranslationSkill({dispatch}) {
        return new Promise((resolve, reject) => {
          dispatch('createTranslationForm').then((form) => {
            fetchData.createTranslatorSkill(form).then((data) => {
              resolve(data);
            }).catch((response) => {
              reject(response);
            });
          });
        });
      },
      updateTranslationSkill({dispatch}, {id}) {
        return new Promise((resolve, reject) => {
          dispatch('createTranslationForm').then((form) => {
              fetchData.updateTranslatorSkill(form, id).then((data) => {
                resolve(data);
              }).catch((response) => {
                reject(response);
              });
          });
        });
      },
      removeTranslationSkill(context, id) {
        return new Promise((resolve, reject) => {
          fetchData.removeTranslationSkill(id).then(() => {
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      }
    },
    mutations: {
      setExistingSkill(state, data = {}) {
        state.skillData.langFrom = data.langFromId || 3;
        state.skillData.langTo = data.langToId || '';
        state.skillData.dialectTo = data.dialectTo || '';
        state.skillData.industry = data.industry || 'interpretation';
        state.skillType = data.type || '';
        state.skillData.qualification = data.qualificationId || '';
        state.skillData.certificates = data.certificates ? JSON.parse(JSON.stringify(data.certificates)) : [];
        state.skillData.id = data.id || '';
        state.skillData.approved = data.approved || false;
      },
      setError(state, {fieldName, errorText}) { state.errors[fieldName].errors = [...state.errors[fieldName].errors, errorText]; },
      removeOneError(state, fieldName) { state.errors[fieldName].errors = []; },
      removeErrors(state) {
        for (const key of Object.keys(state.errors)) {
          state.errors[key].errors = [];
        }
      },
      setLangFrom(state, value) { state.skillData.langFrom = value; },
      setLangTo(state, value) { state.skillData.langTo = value; },
      setDialectTo(state, value) { state.skillData.dialectTo = value; },
      setSkillType(state, value) { state.skillType = value; },
      setQualification(state, value) { state.skillData.qualification = value; },
      setTolkId(state, value) { state.tolkId = value; },
      setSkillWithoutDialects(state, value) { state.skillsNeedDialect = value; },
      setNewCertifTitle(state, value) { state.newCertifTitle = value; },
      addCertif(state, value) { state.skillData.certificates.push(value); },
      removeCertif(state, id) {
        for (let i = 0, lim = state.skillData.certificates.length; i < lim; i += 1) {
          if (state.skillData.certificates[i].id == id) {
            const newObj = JSON.parse(JSON.stringify(state.skillData.certificates[i]));
            delete newObj.title;
            delete newObj.link;
            newObj.deleted = true;
            state.skillData.certificates.splice(i, 1, newObj);
            return;
          }
        }
      },
      clearStore(state) {
        state.newCertifTitle = '';
        state.tolkId = '';
        state.skillData = {
          langFrom: '3',
          langTo: '',
          dialectTo: '',
          qualification: '',
          industry: '',
          certificates: [],
          id: '',
          approved: false,
          declined: false
        };
        state.errors = {
          langFrom: {name: 'langFromError', errors: []},
          langTo: {name: 'langToError', errors: []},
          dialectTo: {name: 'dialectToError', errors: []},
          qualification: {name: 'qualificationError', errors: []},
          upload: {name: 'uploadError', errors: []},
        };
      },
      clearSkillType(state) {
        state.skillType = '';
      }
    }
  };
};
