<template>
  <div class="assignment-date__warning">
    <sk-checkbox v-if="userConfirm || adminConfirm"
                 :preselected-value="false"
                 :validation-name="'error'"
                 :item-text="checkboxText"
                 class="assignment-date__checkbox"
                 @checkboxvaluechanged="check" />
    <div v-if="selectedTimeDuration > 12 && !adminConfirm"
         class="sk-note assignment-date__warnings"> {{ warningText }}</div>
  </div>
</template>

<script>
  import {mapGetters, mapState, mapMutations} from 'vuex';

  export default {
    computed: {
      ...mapGetters('PostingStore/DateTimeStore', ['selectedTimeDuration']),
      ...mapGetters('PostingStore', ['disableBooking']),
      ...mapGetters('UserInfoStore', ['userIsAdminOfEnterprise']),
      ...mapState('TTInterBookingStore', [
        'availableDates',
        'availableTimes',
        'relatedAvailableTimes'
      ]),
      userConfirm() {
        return this.selectedTimeDuration > 6 && this.selectedTimeDuration <= 12;
      },
      adminConfirm() {
        return this.selectedTimeDuration > 12 && this.godmode;
      },
      checkboxText() {
        if (this.adminConfirm) {
          return this.$gettext('I have confirmation from the customer that they want an interpreter for more than 12 hours');
        } else {
          return this.$gettext('I confirm that I need interpreter for more than 6 hours');
        }
      },
      warningText() {
        const template = this.$gettext('Please contact %{domain} to place orders longer than 12 hours');
        return this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName')});
      },
      role() {
        return this.$store.getters['UserInfoStore/currentRole'];
      },
      godmode() {
        return this.$isGodmode();
      }
    },
    watch: {
      selectedTimeDuration: {
        handler(val) {
          if (val > 6) {
            this.setDisableBooking(true);
          } else {
            this.setDisableBooking(false);
          }
        },
        immediate: true
      }
    },
    methods: {
      ...mapMutations({
        setDisableBooking: 'PostingStore/setDisableBooking'
      }),
      check() {
        this.setDisableBooking(!this.disableBooking);
      }
    },
  };
</script>

<style scoped>
  .assignment-date__warning {
    width: calc(100% - 30px);
    margin: 0 auto;
    padding-bottom: 20px;
  }

  #app.salita-facelifted .assignment-date__warning {
    width: 100%;
    padding-bottom: 0;
  }

  #app.salita-facelifted .assignment-date__warnings {
    padding-bottom: 20px;
  }
</style>
