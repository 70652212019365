import BookingHelper from '@/store/booking_stores/BookingHelper';

export default () => {
    return {
        namespaced: true,
        state: {
            name: '',
            isAddressInLetter: false,
            company: '',
            address: '',
            sendByPost: false,
            errors: {
              name: {name: 'nameError', errors: []},
              company: {name: 'companyError', errors: []},
              address: {name: 'addressError', errors: []}
            }
        },
        mutations: {
          ...BookingHelper().mutations,
            setName(state, value) {
              state.name = value;
            },
            setIsAddressInLetter(state, value) {
              state.isAddressInLetter = value;
            },
            setCompany(state, value) {
              state.company = value;
            },
            setAddress(state, value) {
              state.address = value;
            },
            setSendByPost(state, value) {
              state.sendByPost = value;
            },
            clearStore(state) {
                state.name = '';
                state.company = '';
                state.address = '';
                state.sendByPost = false;
                state.isAddressInLetter = false;
            }
          }
    };
};
