import sseRequest from '../../api/sse-request';

export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      myAssignments: {
        progress: true,
        futureAssignments: '',
        pastAssignments: ''
      },
      myAssignmentsStatistic: '',
      assignStatisticByLang: '',
      favouritInterpreters: {
        progress: true,
        data: ''
      },
      todayJobs: '',
      tomorrowJobs: '',
      newsList: '',
      lastPublishedNewsList: '',
      didYouKnowList: ''
    },
    actions: {
      getDashboardFutureAssignments({commit, dispatch}, params) {
        const fallBackRequest = (resolve, reject) => {
          // ------ Fall back Ajax Request  ------ //
          fetchData.getMyAssignments(params).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setBDashboardFutureAssignments', response);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        };

        return new Promise((resolve, reject) => {
          dispatch('getShortLivedToken', '', {root: true})
            .then((res) => {
              params.token = res.token;
              // ------ SSE Request  ------ //
              sseRequest(
                {
                  request: 'dashboardJobs',
                  onmessage: (event) => {
                    if (event) {
                      commit('setBDashboardFutureAssignments', JSON.parse(event.data));
                      resolve(event.data);
                    }
                  },
                  onerror: () => {
                    fallBackRequest(resolve, reject);
                  },
                  params
                }
              );
            })
            .catch(() => {
              fallBackRequest(resolve, reject);
            });
        });
      },
      getDashboardNewsList({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getNewsList(params).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setNewsList', response);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getLastPublishedNewsList({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getLastPublishedNewsList().then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setLastPublishedNewsList', response);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getDidYouKnowList({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getDidYouKnowList().then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setDidYouKnowList', response);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getDashboardPastAssignments({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getAllPastAssignments(params).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setBDashboardPastAssignments', response);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getFavouritInterpreters({commit, rootGetters}, id) {
        const lang = id || rootGetters['UserInfoStore/langFavouriteInters'];
        return new Promise((resolve, reject) => {
          fetchData.getFavouritInterpreters(lang).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setBDashboardFavouritInters', response.interpreters);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      b_getTodayCalendarJobs({commit}, {date, view}) {
        return new Promise((resolve, reject) => {
          fetchData.getCalendarJobs(date, view).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('b_setTodayJobs', response);
            resolve(response);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      b_getTomorrowCalendarJobs({commit}, {date, view}) {
        return new Promise((resolve, reject) => {
          fetchData.getCalendarJobs(date, view).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('b_setTomorrowJobs', response);
            resolve(response);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      getAssignStatisticByLang({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getAssignStatisticByLang().then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setAssignStatisticByLang', response);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getMyAssignmentsStatistic({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getMyAssignmentsStatistic().then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setAssignmentsStatistic', response);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      }
    },
    mutations: {
      setBDashboardFavouritInters(state, data) {
        state.favouritInterpreters.progress = false;
        state.favouritInterpreters.data = data;
      },
      setBDashboardFutureAssignments(state, data) {
        state.myAssignments.progress = false;
        state.myAssignments.futureAssignments = data;
      },
      setBDashboardPastAssignments(state, data) {
        state.myAssignments.progress = false;
        state.myAssignments.pastAssignments = data;
      },
      setAssignmentsProgress(state, value) {
        state.myAssignments.progress = value;
      },
      setNewsList(state, value) {
        state.newsList = value;
      },
      setLastPublishedNewsList(state, value) {
        state.lastPublishedNewsList = value;
      },
      setDidYouKnowList(state, value) {
        state.didYouKnowList = value;
      },
      updateBuyerDashboardJobInfo(state, data) {
        const assignmentsData = state.myAssignments.futureAssignments || {};
        const availableJobs = assignmentsData.jobs || [];

        if (availableJobs.length) {
          for (let i = 0; i < availableJobs.length; i += 1) {
            if (+availableJobs[i].id === +data.id) {
              availableJobs[i].status = data.status;
              return;
            }
          }
        }
      },
      setAssignmentsStatistic(state, data) {
        state.myAssignmentsStatistic = data;
      },
      setAssignStatisticByLang(state, data) {
        state.assignStatisticByLang = data;
      },
      b_setTodayJobs(state, response) {
        state.todayJobs = response;
      },
      b_setTomorrowJobs(state, response) {
        state.tomorrowJobs = response;
      },
      removeBDashboardData(state) {
        state.todayJobs = '';
        state.tomorrowJobs = '';
        state.favouritInterpreters.data = '';
        state.myAssignments.futureAssignments = '';
        state.myAssignments.pastAssignments = '';
        state.myAssignmentsStatistic = '';
        state.assignStatisticByLang = '';
        state.favouritInterpreters.progress = true;
        state.myAssignments.progress = true;
        state.newsList = '';
        state.didYouKnowList = '';
        state.lastPublishedNewsList = '';
      }
    }
  };
};
