export default () => {
  return {
    namespaced: true,
    state: {
      autoApprove: true
    },
    mutations: {
      setAutoApprove(state, value) {
        state.autoApprove = value;
      },
      clearStore(state) {
        state.autoApprove = true;
      }
    }
  };
};
