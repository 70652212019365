(<template>
  <div class="assignment-date">
    <div v-for="(dateTime, index) in dateTimesList"
         :key="index"
         class="assignment-date__cont">
      <sk-datepicker :preselected-value="dateTime.date"
                     :default-value="'--/--/----'"
                     :full-calendar="allowPastBooking"
                     :prevent-call="true"
                     class="post-form__field assignment-date__datepicker"
                     @changeday="dateTime.catchDate" />
      <sk-timepicker :preselected-value="dateTime.startTime"
                     :start-select-from="allowPastBooking ? '' : startTimeBeginning(dateTime.date)"
                     :auto-scroll="true"
                     :default-value="'--:--'"
                     :last-item="false"
                     :mob-numeric-keyboard="true"
                     :description="startTimeText"
                     :validation-name="dateTime.startTimeValidation"
                     :time-step="$timepickerStep()"
                     class="post-form__field assignment-date__timepicker"
                     @timepickervaluechanged="dateTime.catchStartTime" />
      <sk-timepicker :preselected-value="dateTime.finishTime"
                     :auto-scroll="true"
                     :default-value="'--:--'"
                     :last-item="true"
                     :mob-numeric-keyboard="true"
                     :description="finishTimeText"
                     :validation-name="dateTime.finishTimeValidation"
                     :time-step="$timepickerStep()"
                     class="post-form__field assignment-date__timepicker"
                     @timepickervaluechanged="dateTime.catchFinishTime" />
      <button v-if="showDeleteButton"
              class="sk-btn post-form__field assignment-date__btn assignment-date__btn--delete"
              type="button"
              @click="deleteDateTime(index)"></button>
    </div>
    <slot name="errors"></slot>
    <button class="sk-btn sk-btn--white post-form__field assignment-date__btn assignment-date__btn--add"
            type="button"
            @click="addDateTime">{{ $gettext('Add date') }}</button>
  </div>
</template>)

<script>
  import {mapActions, mapGetters, mapState} from 'vuex';
  import helpers from '@/helpers';

  export default {
    props: {
      showAlwaysDelete: Boolean
    },
    computed: {
      ...mapGetters('PostingStore/DateTimeStore', ['minJobDuration']),
      ...mapState('PostingStore/MultipleDateTimeStore', {
        dateTimes: ({dateTimes}) => dateTimes || [],
        errors: ({errors}) => errors
      }),
      dateTimesList() {
        return this.dateTimes.map((dateTime, index) => {
          return {
            date: dateTime.startTime ? this.$moment(dateTime.startTime).format('YYYY-MM-DD') : '',
            startTime: dateTime.startTime ? this.$moment(dateTime.startTime).format('HH:mm') : '',
            finishTime: dateTime.finishTime ? this.$moment(dateTime.finishTime).format('HH:mm') : '',
            startTimeValidation: this.errors[`startTime${index}`]?.name,
            finishTimeValidation: this.errors[`finishTime${index}`]?.name,
            catchDate: (value) => { this.catchDate(index, value); },
            catchStartTime: (value) => { this.catchStartTime(index, value); },
            catchFinishTime: (value) => { this.catchFinishTime(index, value); }
          };
        });
      },
      allowPastBooking() { return this.$isGodmode(); },
      startTimeText() { return this.$gettext('Start time'); },
      finishTimeText() { return this.$gettext('Finish time'); },
      showDeleteButton() { return this.dateTimes.length > 1 || this.showAlwaysDelete; }
    },
    methods: {
      ...mapActions('PostingStore/MultipleDateTimeStore', [
        'setStartTime',
        'setFinishTime',
        'addDateTime',
        'deleteDateTime',
        'setDate'
      ]),
      catchDate(index, value) { this.setDate({index, value}); },
      catchStartTime(index, value) { this.setStartTime({index, value}); },
      catchFinishTime(index, value) { this.setFinishTime({index, value}); },
      startTimeBeginning(date) {
        return date === this.$moment().format('YYYY-MM-DD') ? helpers.dateTime.convertCurrentTime(this.$moment) : '';
      },
      finishTimeBeginning(startTime) {
        if (startTime) {
          const time = this.$moment(startTime, 'HH:mm').add(this.minJobDuration, 'minutes');
          if (time.hours() == 0 && time.minutes() == 0) { return '23:59'; }
          return time.format('HH:mm');
        }
        return '';
      }
    }
  };
</script>

<style>
  .assignment-date .sk-time-picker__description {
    height: 22px;
  }

  @media (max-width: 767px) {
    .assignment-date .sk-time-picker__description {
      height: 20px;
    }
  }
</style>

<style scoped src="@assets/css/assignment_dates.css"></style>
