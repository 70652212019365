import constants from '@/modules/constants';

const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');

const frontendServer = {
  methods: {
    checkServerVersion() {
      const self = this;

      return new Promise((resolve, reject) => {
        // eslint-disable-next-line
        if (isBrowser()) {
          self.ajax.get(`${window.location.origin}/server-version`).then((response) => {
            resolve(response?.data);
          }).catch((error) => {
            reject(error);
          });
        } else {
          resolve();
        }
      });
    },
    sendLog(payload) {
      const self = this;
      const headers = {
        key: constants.FE_SERVER_API_ACCESS_KEY
      };

      return new Promise((resolve, reject) => {
        if (isBrowser()) {
          self.ajax.post(`${window.location.origin}/log`, payload, {headers}).then((response) => {
            resolve(response?.data);
          }).catch((error) => {
            reject(error);
          });
        } else {
          resolve();
        }
      });
    },
  }
};

export default frontendServer;
