(<template>
  <div class="post-form__section post-form__section--underline replacement">
    <p v-if="!noDescription"
       class="post-form__section-title replacement__title">{{ $gettext('When and how did you receive the booking information?') }}</p>
    <div class="replacement__pickers-wrapper">
      <sk-datepicker :preselected-value="actualCreatedDate"
                     :full-calendar="true"
                     :default-value="'--/--/----'"
                     :prevent-call="true"
                     :no-description="noDescription"
                     :description="dateText"
                     class="post-form__field replacement__datepicker"
                     @changeday="setActualCreatedDate" />
      <sk-timepicker :preselected-value="actualCreatedTime"
                     :auto-scroll="true"
                     :no-description="noDescription"
                     :default-value="'--:--'"
                     :description="timeText"
                     :time-step="1"
                     :mob-numeric-keyboard="true"
                     :validation-name="actualCreatedAtErrorName"
                     class="post-form__field replacement__timepicker"
                     @timepickervaluechanged="setActualCreatedTime" />
    </div>
    <div class="post-form__field replacement__radio-wrapper">
      <sk-radio v-for="mechanism in bookingMechanismList"
                :key="mechanism.key"
                :preselected-value="bookingMechanism"
                :item-text="mechanism.text"
                :item-value="mechanism.key"
                :item-name="'booking mechanism'"
                class="replacement__radio"
                @radiochanged="setBookingMechanism" />
    </div>
  </div>
</template>)

<script>
  import {mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';

  export default {
    props: {
      noDescription: Boolean
    },
    computed: {
      ...mapState('PostingStore/ReplacementStore', {
        actualCreatedDate: (state) => state.actualCreatedDate,
        actualCreatedTime: (state) => state.actualCreatedTime,
        bookingMechanism: (state) => state.bookingMechanism,
        // error names
        actualCreatedAtErrorName: (state) => state.errors.actualCreatedAt.name
      }),
      dateText() { return this.$gettext('Order date'); },
      timeText() { return this.$gettext('Order time'); },
      bookingMechanismList() {
        return helpers.getJobInfo.jobBookingMechanismList(this);
      }
    },
    methods: {
      ...mapMutations('PostingStore/ReplacementStore', [
        'setActualCreatedDate',
        'setActualCreatedTime',
        'setBookingMechanism'
      ])
    }
  };
</script>

<style>
  .replacement .sk-time-picker,
  .replacement .sk-date-picker {
    background-color: #fff;
  }
</style>

<style scoped>
  .replacement__pickers-wrapper {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  .replacement__radio-wrapper {
    margin-bottom: 0;
    padding-bottom: 20px;
  }

  .replacement__radio {
    margin-right: 20px;
  }

  .replacement__datepicker {
    width: 100%;
    max-width: 100px;
  }

  .replacement__timepicker {
    width: 100%;
    max-width: 70px;
  }
</style>
