(<template>
  <div class="edit-error-modal">
    <p class="edit-error-modal__text">{{ $gettext('There was an error during job information update') }}</p>
    <div v-if="assignmentInfoEditModalErrors.length"
         class="sk-input__errors-cont edit-error-modal__errors">
      <span v-for="(error, index) in assignmentInfoEditModalErrors"
            :key="index"
            v-html="(assignmentInfoEditModalErrors.length === 1) ? error : `${index + 1}. ${error}`"></span>
    </div>
    <p class="edit-error-modal__text">{{ $gettext('If you want to update job information, please go to full job edit page') }}</p>
    <router-link :to="link"
                 class="sk-btn sk-btn--default edit-error-modal__btn"
                 @click.native="closeModal">{{ $gettext('Go to full job edit page') }}</router-link>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import PostInterpretationPrototype from '@/prototypes/PostInterpretationPrototype';

  export default {
    extends: PostInterpretationPrototype,
    props: {
      data: {
        type: Object,
        default() {
          return {
            errors: {}
          };
        }
      }
    },
    data() {
      return {
        assignmentInfoEditModalErrors: []
      };
    },
    computed: {
      ...mapGetters('OneAssignmentStore', [
        'isSecretaryAssignment'
      ]),
      link() {
        const routeName = this.isSecretaryAssignment
          ? 'BuyerEditSecretaryInterpretation'
          : 'BuyerEditInterpretation';

        return this.$makeRoute({name: routeName, params: {id: this.$route.params.id}});
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
    },
    mounted() {
      this.handleError(this.data.errors, this);
    }
  };
</script>

<style scoped>
  .edit-error-modal {
    display: flex;
    flex-direction: column;
    padding: 20px 30px 30px;
  }

  .edit-error-modal__text,
  .edit-error-modal__errors {
    padding-bottom: 20px;
  }

  .edit-error-modal__btn {
    width: auto;
    margin: 10px auto 0;
    padding: 0 60px;
  }
</style>
