const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');

export default (_t) => {
  let browser = '';
  let device = '';
  if (isBrowser()) {
    const templateBrowser = _t.$gettext('%{browser}, %{device}');
    const templateApp = _t.$gettext('Mobile app, %{device}');
    const hardware = _t.$store.state.hardware || {};
    const mobApp = _t.$store.state.mobileApp;
    browser = hardware.browser ? hardware.browser.name || '' : '';
    if (hardware.device) {
      switch (hardware.device.type) {
        case 'desktop':
          device = _t.$gettext('Desktop');
          break;
        case 'mobile':
          device = _t.$gettext('Mobile');
          break;
        case 'tablet':
          device = _t.$gettext('Tablet');
          break;
        default:
          if (mobApp == 'ios') {
            device = _t.$gettext('iOS');
          } else if (mobApp == 'android') {
            device = _t.$gettext('Android');
          } else {
            device = _t.$gettext('Other device');
          }
          break;
      }
    }

    if ((browser || mobApp) && device) return _t.$gettextInterpolate(mobApp ? templateApp : templateBrowser, {browser, device});
  }
  return _t.$gettext('Undetected device');
};
