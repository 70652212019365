<template>
  <div class="image-preview__modal">
    <img :src="imageBase64"
         :alt="data.file.name" />
    <div v-if="userIsInterpreter && data.disableDownload"
         class="confirmation__text">
      {{ $gettext('Translator accepts that he/she will have access to the files before he/she accepts the order. If the translator rejects, he/she agrees to delete all the files he/she previously downloaded that are associated with this order.') }}
      <sk-checkbox :item-text="confirmationText"
                   :preselected-value="checkboxFlag"
                   class="apply-actions__checkbox"
                   @checkboxvaluechanged="setCheckboxFlag" /></div>
    <button v-if="data.disableDownload"
            :disabled="userIsInterpreter && !checkboxFlag"
            class="sk-btn sk-btn--default confirmation__btn"
            @click="openLink">
      {{ $pgettext('translation', 'Confirm') }}</button>
  </div>
</template>

<script>
  import constants from '@/modules/constants';
  import {mapGetters, mapState} from 'vuex';
  import axios from 'axios';

  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        apiPath: constants.API_PATH,
        checkboxFlag: false

      };
    },
    computed: {
      ...mapState('OneProjectStore', {
        project: ({project}) => project
      }),
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter'
      ]),
      confirmationText() {
        return this.$gettext('I agree to the terms of this pop-up');
      },
      imageBase64() {
        return 'data:image/png;base64,' + this.data.file.base64;
      },
    },
    methods: {
      downloadImage() {
        axios.get(this.imageLinkWithAuth(this.data.file.link), {responseType: 'blob'}).then((response) => {
          const reader = new window.FileReader();
          reader.readAsDataURL(response.data);
          reader.onload = () => {
            const imageDataUrl = reader.result;
            const a = document.createElement('a');
            a.href = imageDataUrl;
            a.download = this.data.file.name;
            a.click();
          };
        });
      },
      imageLinkWithAuth(link) {
        return this.apiPath + link + `?authorization_token=${this.$fetcher.userToken}&random=${Math.random()}`;
      },
      setCheckboxFlag() {
        this.checkboxFlag = !this.checkboxFlag;
      },
      openLink() {
        const a = document.createElement('a');
        a.href = this.imageLinkWithAuth(this.data.file.link);
        a.target = '_blank';
        a.click();
      },
    }
  };
</script>

<style scoped>
.image-preview__modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-top: -15px;
  padding: 45px 30px 20px;
}

img {
  pointer-events: none;
}

.confirmation__text {
  margin-top: 20px;
}

.confirmation__btn {
  align-self: end;
  width: auto;
  margin: 0 5px;
  padding: 0 10px;
}

.apply-actions__checkbox {
  margin-top: 20px;
}
</style>
