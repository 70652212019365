import { render, staticRenderFns } from "./CPersonInfo.vue?vue&type=template&id=b57ef040&scoped=true&"
import script from "./CPersonInfo.vue?vue&type=script&lang=js&"
export * from "./CPersonInfo.vue?vue&type=script&lang=js&"
import style0 from "./CPersonInfo.vue?vue&type=style&index=0&id=b57ef040&prod&scoped=true&lang=css&"
import style1 from "@assets/css/phone_email_icon.css?vue&type=style&index=1&id=b57ef040&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b57ef040",
  null
  
)

export default component.exports