export default ({$moment: moment} = {}) => {
  const sameLocationSuppliersList = [{
      selected: false,
      uid: '1',
      name: 'May Altai',
      timelineEvents: {
        events: [],
        jobs: [{
          id: 1,
          sessionType: 'video',
          finish: moment()
            .hour(11)
            .minute(0),
          start_time: moment()
            .hour(9)
            .minute(0)
        }, {
          id: 1,
          sessionType: 'phone',
          finish: moment()
            .hour(8)
            .minute(30),
          start_time: moment()
            .hour(6)
            .minute(0)
        }],
        offTimes: [{
          id: 1,
          finish: moment()
            .hour(23)
            .minute(0),
          start_time: moment()
            .hour(19)
            .minute(0)
        }],
        travelTimes: [[], []]
      }
    }, {
      selected: false,
      uid: '2',
      name: 'Sulafa Ahmad Esmail',
      timelineEvents: {
        events: [],
        jobs: [{
          id: 1,
          sessionType: 'in_person',
          finish: moment()
            .hour(20)
            .minute(0),
          start_time: moment()
            .hour(13)
            .minute(0)
        }],
        offTimes: [{
          id: 1,
          finish: moment()
            .hour(8)
            .minute(0),
          start_time: moment()
            .hour(0)
            .minute(0)
        }],
        travelTimes: [[{
          id: 1,
          finish: moment()
            .hour(13)
            .minute(0),
          start_time: moment()
            .hour(12)
            .minute(30)
        }], [{
          id: 1,
          finish: moment()
            .hour(20)
            .minute(30),
          start_time: moment()
            .hour(20)
            .minute(0)
        }]]
      }
    }];
  const latestSuppliersList = [{
    selected: false,
    uid: '3',
    name: 'Mohamed Mahgoub',
    timelineEvents: {
      events: [{
        id: 1,
        title: 'Private event',
        finish: moment()
          .hour(23)
          .minute(0),
        start_time: moment()
          .hour(19)
          .minute(0)
      }],
      jobs: [{
        id: 1,
        sessionType: 'phone',
        finish: moment()
          .hour(8)
          .minute(0),
        start_time: moment()
          .hour(6)
          .minute(0)
      }, {
        id: 1,
        sessionType: 'video',
        finish: moment()
          .hour(16)
          .minute(0),
        start_time: moment()
          .hour(12)
          .minute(30)
      }],
      travelTimes: [[], []]
    }
  }, {
    selected: false,
    uid: '4',
    name: 'Amjed Hasan Naji',
    timelineEvents: {
      events: [],
      jobs: [{
        id: 1,
        sessionType: 'phone',
        finish: moment()
          .hour(13)
          .minute(0),
        start_time: moment()
          .hour(8)
          .minute(0)
      }],
      offTimes: [{
        id: 1,
        finish: moment()
          .hour(23)
          .minute(30),
        start_time: moment()
          .hour(20)
          .minute(0)
      }],
      travelTimes: [[], []]
    }
  }, {
    selected: false,
    uid: '5',
    name: 'Maher Abdel Sabour Abdel Wahab',
    timelineEvents: {
      events: [],
      jobs: [{
        id: 1,
        sessionType: 'in_person',
        finish: moment()
          .hour(20)
          .minute(0),
        start_time: moment()
          .hour(13)
          .minute(0)
      }],
      offTimes: [{
        id: 1,
        finish: moment()
          .hour(8)
          .minute(0),
        start_time: moment()
          .hour(0)
          .minute(0)
      }],
      travelTimes: [[], []]
    }
  }, {
    selected: false,
    uid: '6',
    name: 'Ania Filatova Øgaard',
    timelineEvents: {
      events: [],
      jobs: [{
        id: 1,
        sessionType: 'phone',
        finish: moment()
          .hour(8)
          .minute(0),
        start_time: moment()
          .hour(7)
          .minute(0)
      }, {
        id: 1,
        sessionType: 'video',
        finish: moment()
          .hour(12)
          .minute(0),
        start_time: moment()
          .hour(9)
          .minute(0)
      }],
      travelTimes: [[], []]
    }
  }, {
    selected: false,
    uid: '7',
    name: 'Waleed Khalid Dawood Al-Sultani',
    timelineEvents: {
      events: [],
      jobs: [{
        id: 1,
        sessionType: 'phone',
        finish: moment()
          .hour(8)
          .minute(0),
        start_time: moment()
          .hour(6)
          .minute(0)
      }],
      offTimes: [{
        id: 1,
        finish: moment()
          .hour(6)
          .minute(0),
        start_time: moment()
          .hour(0)
          .minute(0)
      }],
      travelTimes: [[], []]
    }
  }];

  return {
    namespaced: true,
    state: {
      sameLocationSuppliersListSelect: false,
      latestSuppliersListSelect: false,
      sameLocationSuppliersList: [...sameLocationSuppliersList.map((supplier) => ({...supplier}))],
      latestSuppliersList: [...latestSuppliersList.map((supplier) => ({...supplier}))]
    },
    mutations: {
      setSameLocationSuppliersListSelectState(state, value) {
        state.sameLocationSuppliersListSelect = value;
      },
      checkSameLocationSupplier(state, uid) {
        for (const supplier of state.sameLocationSuppliersList) {
          if (supplier.uid === uid) {
            supplier.selected = !supplier.selected;
            break;
          }
        }
      },
      setLatestSuppliersListSelectState(state, value) {
        state.latestSuppliersListSelect = value;
      },
      checkLatestSupplier(state, uid) {
        for (const supplier of state.latestSuppliersList) {
          if (supplier.uid === uid) {
            supplier.selected = !supplier.selected;
            break;
          }
        }
      },
      clearStore(state) {
        state.sameLocationSuppliersListSelect = false;
        state.latestSuppliersListSelect = false;
        state.sameLocationSuppliersList = [...sameLocationSuppliersList.map((supplier) => ({...supplier}))];
        state.latestSuppliersList = [...latestSuppliersList.map((supplier) => ({...supplier}))];
      }
    }
  };
};
