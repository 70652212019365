export default () => {
  return {
    namespaced: true,
    state: {
      standBy: true
    },
    getters: {
      standByValue(state, getters, rootState, rootGetters) {
        return rootGetters['PostingNotifierStore/disableStandBySection']
          ? 'turned_off'
          : state.standBy
            ? 'turned_on'
            : 'turned_off';
      }
    },
    mutations: {
      setStandBy(state, value) {
        state.standBy = value;
      },
      clearStore(state) {
        state.standBy = true;
      }
    }
  };
};
