export default {
  path: '/:lang/:country',
  meta: {guestOnly: true},
  component: () => import('@/pages/shared_pages/open_booking/sh_OpenBookingRoot'),
  children: [
    {
      path: 'book-interpreter/:organization',
      name: 'OpenBookingInterpretation',
      meta: {guestOnly: true, type: 'interpretation'},
      component: () => import('@/pages/shared_pages/open_booking/sh_OpenBookingInterpretation')
    },
    {
      path: 'bestill-tolk/:organization',
      name: 'OpenBookingInterpretationNb',
      meta: {guestOnly: true, type: 'interpretation'},
      component: () => import('@/pages/shared_pages/open_booking/sh_OpenBookingInterpretation')
    },
    {
      path: 'order-translation/:organization',
      name: 'OpenBookingTranslation',
      meta: {guestOnly: true, type: 'translation'},
      component: () => import('@/pages/shared_pages/open_booking/sh_OpenBookingTranslationProject')
    },
    {
      path: 'bestill-oversettelse/:organization',
      name: 'OpenBookingTranslationNb',
      meta: {guestOnly: true, type: 'translation'},
      component: () => import('@/pages/shared_pages/open_booking/sh_OpenBookingTranslationProject')
    }
  ]
};
