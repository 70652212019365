<template>
  <div class="file-progress">
    <p class="file-progress__text">{{ $gettext('We are uploading your file(s)') }}</p>
    <svg class="file-progress__bar">
      <path ref="ring"
            class="file-progress__range"
            transform="translate(100, 100)" />
      <circle cx="100"
              cy="100"
              r="82"
              fill="#ffffff" />
      <circle class="file-progress__circle"
              cx="100"
              cy="100"
              r="73" />
      <text class="file-progress__percentage"
            x="100"
            y="100">{{ `${uploadPercentage}%` }}</text>
    </svg>
  </div>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState({
        uploadPercentage: ({uploadProgress}) => uploadProgress.percentage
      })
    },
    watch: {
      uploadPercentage() {
        this.draw();
      }
    },
    methods: {
      draw() {
        const r = 100;
        const toRadians = Math.PI / 180;
        const ring = this.$refs.ring;
        // Update the wheel giving to it a value in degrees,
        // getted from the percentage of the input value
        // a.k.a. (value * 360) / 100
        const degrees = this.uploadPercentage * 3.5999;
        // Convert the degrees value to radians
        const rad = degrees * toRadians;
        // Determine X and cut to 2 decimals
        const x = (Math.sin(rad) * r).toFixed(2);
        // Determine Y and cut to 2 decimals
        const y = -(Math.cos(rad) * r).toFixed(2);
        // The another half ring. Same as (deg > 180) ? 1 : 0
        const lenghty = Number(degrees > 180);
        // Moveto + Arcto
        const descriptions = ['M', 0, 0, 'v', -r, 'A', r, r, 1, lenghty, 1, x, y, 'z'];
        // Apply changes to the path
        ring.setAttribute('d', descriptions.join(' '));
      }
    }
  };
</script>

<style scoped>
  .file-progress {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 50;
    transform: translate(-50%, -50%);
  }

  .file-progress__text {
    padding: 5px;
    background-color: #fff;
    font-weight: bold;
  }

  .file-progress__bar {
    width: 200px;
    height: 200px;
  }

  .file-progress__range {
    display: block;
    fill: #ff5b24;
    fill: var(--color-secondary);
  }

  .file-progress__percentage {
    font-weight: bold;
    font-size: 30px;
    fill: #333;
    text-anchor: middle;
    dominant-baseline: central;
  }

  .file-progress__circle {
    fill: none;
    stroke: #ffccb3;
    stroke: var(--color-secondary-lighter);
    stroke-width: 7;
  }
</style>
