(<template>
  <div class="start-time is-progress-bar"
       :class="[classes, {'in-progress': progressActive, 'pointer': !noActionOnClick}]"
       @click="onCellClick">
    <div class="start-time__cont"
         :class="{'start-date__danger': hourLeftToStart,
                  'start-date__warning': threeHoursLeftToStart}">
      <div class="start-date__wrapper">
        <p class="start-date">{{ startedDate }}</p>
      </div>
      <p class="assignment__duration">{{ duration }}</p>
    </div>
  </div>
</template>)

<script>
  import ManagerJobEditInfoMixin from '@/mixins/ManagerJobEditInfoMixin';

  export default {
    mixins: [ManagerJobEditInfoMixin],
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      classes() { return this.propData?.classes; },
      startTime() { return this.$moment(this.propData?.startTime); },
      finishTime() { return this.$moment(this.propData?.finishTime); },
      startedDate() { return this.startTime.format('DD.MM.YY'); },
      duration() { return `${this.startTime.format('HH:mm')} - ${this.finishTime.format('HH:mm')}`; },
      daysLeftToStart() { return this.finishTime.diff(Date.now(), 'days'); },
      hoursLeftToStart() { return this.startTime.diff(Date.now(), 'hours'); },
      hourLeftToStart() { return this.hoursLeftToStart >= 0 && this.hoursLeftToStart <= 1; },
      threeHoursLeftToStart() { return this.hoursLeftToStart > 1 && this.hoursLeftToStart <= 3; },
      jobWillStartToday() { return this.daysLeftToStart === 0; },
      jobWillStartTomorrow() { return !this.isStartDateWeekend && this.daysLeftToStart === 1; },
      jobWillStartNextWorkingDay() { return !this.isStartDateWeekend && this.daysLeftToStart === 2; },
      isStartDateWeekend() {
        const weekday = this.startTime.format('dddd');
        return weekday === 'Sunday' || weekday === 'Saturday';
      },
      noActionOnClick() { return !!this.propData?.noActionOnClick; },
    },
    methods: {
      onCellClick() {
        if (!this.noActionOnClick) this.openEditAssignmentInformation();
      },
    }
  };
</script>
<style scoped>
.start-time {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 5px;
}

.start-time__cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  margin-left: 12px;
  padding: 10px;
  border-radius: 8px;
}

.start-date__wrapper {
  display: flex;
  align-items: center;
}

.dot {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.start-date__danger,
.start-date__warning,
.start-date__next-working-day {
  color: #fff;
}

.start-date__danger {
  background-color: #ff3624;
}

.start-date__warning {
  background-color: #ff7f24;
}

.start-date__next-working-day {
  background-color: #ffc824;
}

.assignment__duration {
  color: #555;
}

.start-date__danger .assignment__duration,
.start-date__warning .assignment__duration,
.start-date__next-working-day .assignment__duration {
  color: #fff;
}
</style>
