(<template>
  <section class="address">
    <p v-if="currentDepartment"
       class="address__extra-text"
       v-html="selectedDepartmentText"></p>
    <p class="address__description"></p>
    <form class="address__form"
          @submit.prevent.stop="submitHandler">
      <full-address ref="addressComponent"
                    :autofocus="true" />
      <button class="sk-btn sk-btn--default address__submit-btn">{{ $pgettext('custom', 'Save') }}</button>
    </form>
  </section>
</template>)

<script>
  import {mapGetters, mapActions, mapState, mapMutations} from 'vuex';
  import FullAddress from '@/components/shared_components/FullAddress';

  export default {
    components: {
      'full-address': FullAddress
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        currentDepartment: this.data.currentDepartment
      };
    },
    computed: {
      ...mapState('FullAddressStore', [
        'line1',
        'line2',
        'county',
        'postcode',
        'city',
        'country',
        'longitude',
        'latitude'
      ]),
      ...mapGetters('FullAddressStore', ['fullAddress']),
      currentAddress() { return this.data.currentAddress || {}; },
      currentAddressId() { return this.currentAddress.id || ''; },
      selectedDepartmentText() {
        if (this.currentDepartment) {
          const template = this.$gettext('New address will be added to <b>"%{departmentName}"</b> department');
          return this.$gettextInterpolate(template, {departmentName: this.currentDepartment.name});
        }
      },
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      ...mapMutations('FullAddressStore', ['setAddressInfo', 'clearStore']),
      ...mapActions('EnterpriseStore', ['createAddressForm', 'createAddress', 'updateAddress']),
      ...mapActions('FullAddressStore', ['getGeometryLocation', 'setError']),
      submitHandler() {
        if (this.$refs.addressComponent.validateForm()) {
          this.$emit('startprogress');
          this.getGeometryLocation(this.fullAddress).then(() => {
            this.createAddressForm().then((form) => {
              if (this.currentDepartment) {
                form.append('address[departments][][id]', this.currentDepartment.id);
              }
              const submitAction = this.currentAddressId ? 'updateAddress' : 'createAddress';
              this[submitAction]({form, addressId: this.currentAddressId}).then((addressId) => {
                if (this.data.successHandlingCallbackName && this.data.context[this.data.successHandlingCallbackName]) {
                  this.data.context[this.data.successHandlingCallbackName](addressId);
                }
                this.stopProgress();
              }).catch(() => {
                this.stopProgress();
              });
            });
          }).catch(() => {
            this.setError({
              fieldName: 'address',
              errorText: this.$gettext('We were unable to locate the address')
            });
          });
        }
      },
      closeModal() {
        this.$emit('closemodal');
      },
      stopProgress() {
        this.$emit('stopprogress');
        this.closeModal();
      }
    },
    destroyed() {
      this.clearStore();
    },
    mounted() {
      this.setAddressInfo(this.currentAddress);
    }
  };
</script>

<style scoped>
  .address {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 10px 30px 30px;
  }

  .address__extra-text,
  .address__description {
    width: 100%;
    font-size: 12px;
    text-align: center;
  }

  .address__description {
    margin-bottom: 20px;
    text-align: center;
  }

  .address__form {
    display: block;
    width: 100%;
  }

  .address__submit-btn {
    width: auto;
    margin-left: auto;
    padding: 0 30px;
  }
</style>
