<script>
  import {mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters('OneAssignmentStore', ['storeJob']),
      cancellationNoteText() {
        const cancellationType = this.storeJob?.freeCancellationPeriod;
        const customerCancelMinutes = this.storeJob?.freeCancellationSinceCreationInterval;
        if (cancellationType) {
          const typeText = this.getCancellationPolicyType(cancellationType) || cancellationType;
          const template = customerCancelMinutes
            ? this.$gettext('<b>Customer cancellation policy</b> '
              + '<br> - Customers can cancel assignment for free up to <b>%{typeText}</b>. '
              + '<br> - If the assignment has been published <b>less than 24 hours</b> of it\'s start time, the customer has'
              + ' <b>%{customerCancelMinutes} minutes </b>after the assignment was published to cancel it for <b>free</b>.')
            : this.$gettext('<b>Customer cancellation policy</b> '
              + '<br> - Customers can cancel assignment for free up to <b>%{typeText}</b>.');
          return this.$gettextInterpolate(template, {typeText, customerCancelMinutes});
        }
      }
    },
    methods: {
      getCancellationPolicyType(type) {
        switch (type) {
          case 'calendar_hours_12':
            return this.$gettext('12 hours before assignment');
          case 'calendar_hours_24':
            return this.$gettext('24 hours before assignment');
          case 'day_before_1400':
            return this.$gettext('14:00 on the previous calendar day');
          case 'day_before_1200':
            return this.$gettext('12:00 on the previous calendar day');
          case 'day_before_1500':
            return this.$gettext('15:00 on the previous calendar day');
          case 'working_days_3':
            return this.$gettext('3 working days before assignment');
          default:
            return this.$gettext('24 hours before assignment');
        }
      }
    }
  };
</script>
