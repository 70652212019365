(<template>
  <section class="help-modal__content-cont">
    <div class="help-modal__content-wrapper">
      <p>{{ mainTitle }}</p>
      <p>{{ $gettext('Whether you have a suggestion, complaint or idea - please do send us a message.') }}</p>
      <br>
      <p v-if="supportPortalLink"
         v-html="siteHelpText"></p>
      <br>
      <table class="help-modal__table help-modal__table_desktop">
        <tr><th>{{ $gettext('Online') }}</th><th>{{ $gettext('Email') }}</th><th>{{ $gettext('Phone') }}</th></tr>
        <tr>
          <td>
            <button class="sk-btn sk-btn--default"
                    @click="openHelpscout">{{ $gettext('Click the chat icon') }}</button>
          </td>
          <td>
            <a class="sk-btn sk-btn--default"
               :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
          </td>
          <td>
            <a class="sk-btn sk-btn--default"
               :href="`tel:${supportPhone}`">{{ supportPhoneText }}</a>
          </td>
        </tr>
      </table>
      <table class="help-modal__table help-modal__table_mobile">
        <tr><th>{{ $gettext('Email') }}</th><th>{{ $gettext('Phone') }}</th></tr>
        <tr>
          <td>
            <a class="sk-btn sk-btn--default"
               :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
          </td>
          <td>
            <a class="sk-btn sk-btn--default"
               :href="`tel:${supportPhone}`">{{ supportPhoneText }}</a>
          </td>
        </tr>
      </table>
      <br>
      <p><strong v-html="phoneHelpText"></strong></p>
      <br>
      <button class="sk-btn sk-btn--default help-modal-close-btn"
              type="button"
              @click="closeModal">{{ $gettext("Close") }}</button>
    </div>
  </section>
</template>)

<script>
  export default {
    data() {
      return {
        message: '',
        messageValidationName: 'message',
        messageErrors: [],
        supportEmail: this.$getString('domainData', 'supportEmail'),
        supportPortalText: this.$getString('domainData', 'supportPortalText'),
        supportPortalLink: this.$getString('domainData', 'supportPortalLink'),
        supportPhoneText: this.$getString('domainData', 'supportPhoneText'),
        supportPhone: this.$getString('domainData', 'supportPhone')
      };
    },
    computed: {
      mainTitle() {
        const template = this.$gettext('At %{domain}, we are always interested in hearing from you.');
        return this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName')});
      },
      siteHelpText() {
        const template = this.$gettext('Take a look at our support website at <a class="sk-link" href="%{link}" target="_blank">%{text}</a> for commonly asked questions.');
        return this.$gettextInterpolate(template, {link: this.supportPortalLink, text: this.supportPortalText});
      },
      phoneHelpText() {
        const template = this.$gettext('For urgent matters, please call us at <a class="sk-link" href="tel:%{fullPhone}">%{phone}</a>.');
        return this.$gettextInterpolate(template, {fullPhone: this.supportPhone, phone: this.supportPhoneText});
      }
    },
    methods: {
      openHelpscout() {
        if (window && window.Beacon) {
          window.Beacon('open');
        }
      },
      submitHandler() {
        this.messageErrors = [];

        if (!this.message || !this.message.trim()) {
          this.messageErrors.push(this.$gettext('Please enter a message'));
        }

        this.$store.commit('ErrorsStore/setError', {name: this.messageValidationName, errors: this.messageErrors});

        if (!this.messageErrors.length) {
          this.$emit('startprogress');
          this.$store.dispatch('sendHelpMessage', {message: this.message.trim()}).then(() => {
            this.stopProgress();
            this.closeModal();
            this.message = '';
          }).catch(() => {
            this.stopProgress();
          });
        }
      },
      closeModal() {
        this.$emit('closemodal');
      },
      stopProgress() {
        this.$emit('stopprogress');
      }
    }
  };
</script>

<style scoped>
  .help-modal__content-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 35px 35px;
  }

  .help-modal__content-wrapper {
    display: block;
    max-width: 100%;
    text-align: center;
  }

  .help-modal__table {
    width: 100%;
  }

  .help-modal__table td {
    width: 25%;
    padding: 10px;
  }

  .help-modal-close-btn {
    display: inline-block;
    width: 140px;
    margin-top: 15px;
  }

  @media (min-width: 768px) {
    .help-modal__table_mobile {
      display: none;
    }

    .help-modal__table_desktop {
      display: block;
    }
  }

  @media (max-width: 767px) {
    .help-modal__table_mobile {
      display: block;
    }

    .help-modal__table_desktop {
      display: none;
    }
  }
</style>
