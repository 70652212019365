(<template>
  <section class="pers-info-modal__content-cont js-for-phone-verification">
    <p v-if="!hideForDashboard"
       class="pers-info-modal__title">{{ $gettext('Welcome!') }}</p>
    <p v-if="!hideForDashboard"
       class="pers-info-modal__description">{{ $gettext('Please fill out the following information so that we can offer you relevant assignments.') }}</p>
    <div class="pers-info-modal__content-wrapper">
      <p class="pers-info-modal__language-text">{{ $gettext('Website Language') }}</p>
      <locale-select class="pers-info-modal__language-select" />
      <sk-select v-if="showField(!initData.gender)"
                 :default-value="genderText"
                 :description="genderText"
                 :type="'gender'"
                 :preselected-value="personalData.sex"
                 :validation-name="genderValidationName"
                 class="pers-info-modal__gender-select"
                 :autofocus="!isMobileView"
                 @csvaluechanged="catchChosenGender" />
      <template v-if="userIsInterpreter && hideForDashboard">
        <p class="pers-info-modal__section-text">{{ $gettext('When is your birthday?') }}</p>
        <div class="pers-info-modal__dob-selects-cont">
          <sk-select :default-value="dateDefaultValue"
                     :description="dateText"
                     :type="'day'"
                     :preselected-value="personalData.birthDay"
                     class="pers-info-modal__dob-select"
                     @csvaluechanged="catchChosenBirthDay" />
          <sk-select :default-value="monthDefaultValue"
                     :description="monthText"
                     :type="'month'"
                     :preselected-value="personalData.birthMonth"
                     class="pers-info-modal__dob-select"
                     @csvaluechanged="catchChosenBirthMonth" />
          <sk-select :default-value="yearDefaultValue"
                     :description="yearText"
                     :type="'birthYear'"
                     :preselected-value="personalData.birthYear"
                     class="pers-info-modal__dob-select"
                     @csvaluechanged="catchChosenBirthYear" />
        </div>
        <div v-if="dobErrors && dobErrors.length"
             class="sk-input__errors-cont">
          <span v-for="(error, index) in dobErrors"
                :key="index"
                v-html="(dobErrors.length === 1) ? error : `${index + 1}. ${error}`"></span>
        </div>
      </template>
      <sk-input v-if="hideForDashboard"
                :description="emailText"
                :preselected-value="personalData.email"
                :margin-bottom="true"
                :placeholder="emailText"
                :disabled="true"
                :readonly="true" />
      <div v-if="showPhone"
           class="pers-info-modal__tel-number-inputs-cont">
        <sk-select :type="'countryCodes'"
                   :description="phoneCodeText"
                   :default-value="phoneCodeText"
                   :preselected-value="personalData.phoneCode"
                   :mob-numeric-keyboard="true"
                   class="pers-info-modal__tel-code-label"
                   @csvaluechanged="catchPhoneCode" />
        <sk-input :description="phoneText"
                  :preselected-value="personalData.phoneNumber"
                  :margin-bottom="true"
                  :placeholder="phoneText"
                  :validation-name="phoneNumberValidationName"
                  :autofocus="true"
                  :type="'tel'"
                  :reg-exp="/[^0-9]/g"
                  :mob-numeric-keyboard="true"
                  class="pers-info-modal__tel-number-label"
                  @fieldvaluechanged="catchPhoneNumber" />
      </div>
      <div v-if="showAdditionalPhone"
           class="pers-info-modal__tel-number-inputs-cont">
        <sk-select :type="'countryCodes'"
                   :description="additionalPhoneCodeText"
                   :default-value="additionalPhoneCodeText"
                   :preselected-value="personalData.additionalPhoneCode"
                   :mob-numeric-keyboard="true"
                   class="pers-info-modal__tel-code-label"
                   @csvaluechanged="catchAdditionalPhoneCode" />
        <sk-input :description="additionalPhoneText"
                  :preselected-value="personalData.additionalPhoneNumber"
                  :margin-bottom="true"
                  :placeholder="additionalPhoneText"
                  :validation-name="additionalPhoneNumberValidationName"
                  :autofocus="true"
                  :type="'tel'"
                  :reg-exp="/[^0-9]/g"
                  :mob-numeric-keyboard="true"
                  class="pers-info-modal__tel-number-label"
                  @fieldvaluechanged="catchAdditionalPhoneNumber" />
      </div>
      <full-address v-if="userIsInterpreter"
                    ref="addressComponent"
                    :description="$gettext('Add your address')" />
      <button v-if="!hideForDashboard"
              :disabled="isDisabledSubmit"
              class="sk-btn sk-btn--default pers-info-modal__submit-btn"
              @click="submitHandler">{{ $gettext('Register information') }}</button>
      <submit-btns v-if="hideForDashboard"
                   :submit-handler="submitHandler"
                   :cancel-handler="closeModal" />
    </div>
  </section>
</template>)

<script>
  import {mapGetters, mapActions, mapState, mapMutations} from 'vuex';
  import SubmitBtns from '@/components/profile_components/edit/ModalSubmitBtns';
  import FullAddress from '@/components/shared_components/FullAddress';

  export default {
    components: {
      'full-address': FullAddress,
      'submit-btns': SubmitBtns
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        personalData: {
          sex: this.data.data.gender || '',
          email: this.data.data.email || '',
          phoneCode: this.data.data.phoneCode || '+47',
          phoneNumber: this.data.data.phoneNumber || '',
          additionalPhoneCode: this.data.data.alternativePhoneCode || '+47',
          additionalPhoneNumber: this.data.data.alternativePhoneNumber || '',
          birthDay: this.data.data.dob ? this.data.data.dob.split('.')[0] : '',
          birthMonth: this.data.data.dob ? this.data.data.dob.split('.')[1] : '',
          birthYear: this.data.data.dob ? this.data.data.dob.split('.')[2] : ''
        },
        changedLocale: false,
        phoneNumberValidationName: 'phoneNumber',
        additionalPhoneNumberValidationName: 'additionalPhoneNumber',
        genderValidationName: 'gender',
        phoneNumberErrors: [],
        genderErrors: [],
        dobErrors: []
      };
    },
    computed: {
      ...mapState('FullAddressStore', [
        'line1',
        'line2',
        'county',
        'postcode',
        'city',
        'country',
        'longitude',
        'latitude'
      ]),
      ...mapGetters('FullAddressStore', ['fullAddress']),
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter',
        'userIsNotInterpreter',
        'userIsManager',
        'userUid',
        'userLocale']),
      genderText() { return this.$gettext('Gender'); },
      dateDefaultValue() { return this.$gettext('DD'); },
      dateText() { return this.$gettext('Day'); },
      monthDefaultValue() { return this.$gettext('MM'); },
      monthText() { return this.$gettext('Month'); },
      yearDefaultValue() { return this.$gettext('YYYY'); },
      yearText() { return this.$gettext('Year'); },
      emailText() { return this.$gettext('Email'); },
      phoneCodeText() { return this.$gettext('Code'); },
      additionalPhoneCodeText() { return this.$gettext('Code'); },
      phoneText() { return this.$gettext('Mobile number'); },
      additionalPhoneText() { return this.$gettext('Additional mobile number'); },
      hideForDashboard() { return !this.data.dashboard; },
      isRequiredOnly() { return this.data.requiredOnly; },
      showPhone() { return (this.hideForDashboard && this.userIsNotInterpreter) || this.userIsManager; },
      initData() { return this.data.data || {}; },
      isDisabledSubmit() { return !this.fullAddress || !this.personalData.sex; },
      dateOfBirth() { return [this.personalData.birthDay, this.personalData.birthMonth, this.personalData.birthYear].join('.'); },
      isMobileView() { return this.$store.state.GlobalStore.clientWidth < 768; },
      showAdditionalPhone() { return this.hideForDashboard && this.$isGodmode(); },
      errorAddressText() {
        const template = this.$gettext('When you change your home address, you have to wait at least 6 months to change it again. If this is an emergency, and you have to change your address now, please contact <a href="mailto:%{email}" class="sk-link js-copy-to-clipboard">%{email}</a>.');
        return this.$gettextInterpolate(template, {email: this.$getString('domainData', 'subSupportEmail')});
      }
    },
    watch: {
      userLocale() {
        this.changedLocale = true;
      },
      $route() {
        if (!this.changedLocale) this.closeModal();
      }
    },
    methods: {
      ...mapActions('EditProfileStore', ['updateUserProfile']),
      ...mapActions('FullAddressStore', ['getGeometryLocation', 'setError']),
      ...mapMutations('FullAddressStore', ['setAddressInfo']),
      showField(fieldCond) { return this.isRequiredOnly ? fieldCond && this.userIsInterpreter : this.userIsInterpreter; },
      validateForm() {
        let isValidForm = true;

        if (this.userIsInterpreter) {
          isValidForm = this.$refs.addressComponent.validateForm();
        }
        this.phoneNumberErrors = [];
        this.genderErrors = [];
        this.dobErrors = [];

        if (this.userIsInterpreter && !this.personalData.sex) {
          this.genderErrors.push(this.$gettext('Please choose a gender'));
          this.$store.commit('ErrorsStore/setError', {name: this.genderValidationName, errors: this.genderErrors});
          isValidForm = false;
        }
        if (this.personalData.birthDay && this.personalData.birthMonth && this.personalData.birthYear) {
          const dobMoment = this.$moment(this.dateOfBirth, 'DD.MM.YYYY');
          if (!dobMoment.isValid()) {
            this.dobErrors.push(this.$gettext('Please check the date.'));
            isValidForm = false;
          }
        }
        return isValidForm;
      },
      submitHandler() {
        if (this.validateForm()) {
          const form = new FormData();
          const string = this.userIsInterpreter ? 'interpreter[person]' : 'person';
          const address = `${this.line1}, ${this.line2}, ${this.postcode} ${this.city}, ${this.country}`;

          if ((this.userIsNotInterpreter || this.userIsManager) && this.personalData.phoneCode && this.personalData.phoneNumber) {
            form.append(`${string}[phone_code]`, this.personalData.phoneCode);
            form.append(`${string}[phone_number]`, this.personalData.phoneNumber);
          }
          if (this.$isGodmode() && this.personalData.additionalPhoneCode && this.personalData.additionalPhoneNumber) {
            form.append(`${string}[alternative_phone_code]`, this.personalData.additionalPhoneCode);
            form.append(`${string}[alternative_phone_number]`, this.personalData.additionalPhoneNumber);
          }
          if (this.personalData.sex) { form.append(`${string}[sex]`, this.personalData.sex); }
          if (this.personalData.birthDay && this.personalData.birthMonth && this.personalData.birthYear) {
            form.append(`${string}[birth]`, this.dateOfBirth);
          }
          if (this.userIsInterpreter) {
            form.append(`${string}[address][line_1]`, this.line1);
            form.append(`${string}[address][line_2]`, this.line2);
            form.append(`${string}[address][city]`, this.city);
            form.append(`${string}[address][county]`, this.county);
            form.append(`${string}[address][country]`, this.country);
            form.append(`${string}[address][postcode]`, this.postcode);
            form.append('interpreter[tolk_id]', this.data.data.tolkId);

            this.getGeometryLocation(address).then(() => {
              form.append(`${string}[address][latitude]`, this.latitude);
              form.append(`${string}[address][longitude]`, this.longitude);

              this.sendForm(form);
            }).catch(() => {
              this.setError({
                fieldName: 'address',
                errorText: this.$gettext('We were unable to locate the address')
              });
            });
          } else {
            this.sendForm(form);
          }
        }
      },
      closeModal() {
        this.$emit('closemodal');
      },
      reloadPage() {
        window.location.reload();
      },
      stopProgress() {
        this.$emit('stopprogress');
      },
      catchChosenGender(value) {
        this.personalData.sex = value;
      },
      catchChosenBirthDay(value) {
        this.personalData.birthDay = value;
      },
      catchChosenBirthMonth(value) {
        this.personalData.birthMonth = value;
      },
      catchChosenBirthYear(value) {
        this.personalData.birthYear = value;
      },
      catchPhoneCode(value) {
        this.personalData.phoneCode = value;
      },
      catchPhoneNumber(value) {
        this.personalData.phoneNumber = value;
      },
      catchAdditionalPhoneCode(value) {
        this.personalData.additionalPhoneCode = value;
      },
      catchAdditionalPhoneNumber(value) {
        this.personalData.additionalPhoneNumber = value;
      },
      sendForm(form) {
        this.$emit('startprogress');
        this.updateUserProfile({id: this.userUid, form}).then(() => {
          this.stopProgress();
          this.closeModal();
        }).catch((error) => {
          this.stopProgress();
          if (error
            && error.errors
            && error.errors.person) {
            error.errors = error.errors.person;
          }
          if (error
            && error.errors
            && error.errors.phone_number
            && error.errors.phone_number.length
            && error.errors.phone_number.indexOf('Invalid phone') !== -1
            && !this.phoneNumberErrors.length) {
            this.phoneNumberErrors.push(this.$gettext('Please enter a valid phone number.'));
            this.$store.commit('ErrorsStore/setError', {name: this.phoneNumberValidationName, errors: this.phoneNumberErrors});
          }
          if (error
            && error.errors
            && error.errors.global
            && error.errors.global.indexOf('interpreter_address_change_cooldown') !== -1) {
            this.closeModal();
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.errorAddressText
            });
          }
        });
      }
    },
    beforeMount() {
      this.$store.commit('ErrorsStore/removeAllErrors');
    },
    mounted() {
      this.setAddressInfo(this.data.data);
    },
    destroyed() {
      this.$store.commit('FullAddressStore/clearStore');
    }
  };
</script>

<style>
  .pers-info-modal__content-wrapper .sk-select__list {
    max-height: 100px;
  }

  .dashboard-pers-info .sk-modal__wrapper {
    overflow: auto;
  }
</style>

<style scoped>
.pers-info-modal__content-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 35px 35px 40px;
  padding-top: 10px;
}

.dashboard-pers-info .pers-info-modal__content-cont {
  position: relative;
  margin-top: -5px;
  padding: 27px 26px 20px;
  background-color: #fff;
}

.pers-info-modal__title {
  margin-bottom: 15px;
  font-size: 18px;
  text-align: center;
}

.pers-info-modal__description {
  margin-bottom: 25px;
  text-align: center;
}

.pers-info-modal__content-wrapper {
  display: block;
  width: 350px;
  max-width: 100%;
}

.pers-info-modal__dob-selects-cont {
  display: flex;
  margin-bottom: 12px;
}

.pers-info-modal__dob-select {
  margin-right: 10px;
}

.pers-info-modal__section-text {
  display: block;
  width: 100%;
  margin: 0 0 10px;
}

.pers-info-modal__language-text {
  overflow: hidden;
  width: 100%;
  padding-bottom: 2px;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pers-info-modal__language-select {
  width: 50%;
  margin-bottom: 12px;
}

.pers-info-modal__gender-select {
  margin-bottom: 12px;
}

.dashboard-pers-info .pers-info-modal__gender-select {
  width: 50%;
}

.pers-info-modal__tel-number-inputs-cont {
  display: flex;
  margin-bottom: 12px;
}

.pers-info-modal__submit-btn {
  width: auto;
  margin-top: 5px;
  margin-left: auto;
  padding: 0 10px;
}

.pers-info-modal__tel-code-label {
  flex-shrink: 0;
  width: 100px;
  margin-right: 10px;
}

.pers-info-modal__tel-number-label {
  display: inline-block;
  flex-grow: 1;
  width: auto;
}
</style>
