import { render, staticRenderFns } from "./RecepitWarningBanner.vue?vue&type=template&id=29aa0f60&scoped=true&"
import script from "./RecepitWarningBanner.vue?vue&type=script&lang=js&"
export * from "./RecepitWarningBanner.vue?vue&type=script&lang=js&"
import style0 from "./RecepitWarningBanner.vue?vue&type=style&index=0&id=29aa0f60&prod&lang=css&"
import style1 from "./RecepitWarningBanner.vue?vue&type=style&index=1&id=29aa0f60&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29aa0f60",
  null
  
)

export default component.exports