import ActionCable from 'actioncable';
import constants from '@/modules/constants';
import {cookieManager} from '@skiwo/sk-basic-components';

const defaultChannelHandler = {
  connected() {
    // eslint-disable-next-line
    console.log('connected', this.identifier);
  },
  disconnected() {
    // eslint-disable-next-line
    console.log('disconnected', this.identifier);
  },
  rejected() {
    // eslint-disable-next-line
    console.log('rejected', this.identifier);
  },
  received(data) {
    // eslint-disable-next-line
    console.log(data, this.identifier);
    this.callback(data, this.identifier);
  },
  callback(data) {
    // eslint-disable-next-line
    console.log('default callback', data);
  },
  appear() {
    // eslint-disable-next-line
    console.log('Attempting to re-appear', this.identifier);
    this.perform('appear', {web_page: window.location.href});
  }
};

export default {
  cable() {
    const token = cookieManager.getCookie('authToken') || localStorage.authToken;
    return ActionCable.createConsumer(constants.WEB_SOCKET_PATH + '?jwt=' + token);
  },
  subscribe(callback, channelName = 'DefaultChannel', idParam = '') {
    defaultChannelHandler.callback = callback;
    return this.cable().subscriptions.create({channel: channelName, resource_id: idParam}, defaultChannelHandler);
  },
  remove(cable, subscriber) {
    cable.subscriptions.remove(subscriber);
  }
};
