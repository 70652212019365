(<template>
  <section :class="{'in-progress': modalProgress}"
           class="move-customer__main-wrapper is-progress-bar">
    <basic-customer-info class="move-customer__basic-info" />
    <autocomplete v-model="searchTerm"
                  class="move-customer__enterprises"
                  :description="enterpriseText"
                  :placeholder="organizationPlaceholder"
                  :suggestions="suggestions"
                  :validation-name="enterpriseErrorsName"
                  @search="searchEnterprise"
                  @suggestionselected="catchSuggestion" />
    <div :class="{'in-progress': departmentProgress}"
         class="move-customer__departments-row is-progress-bar">
      <sk-multiselect :items-list="departmentsList"
                      :preselected-value="departments"
                      :description="departmentText"
                      :default-value="departmentPlaceholder"
                      :validation-name="departmentErrorsName"
                      class="move-customer__departments"
                      @checkboxeschanged="catchDepartment" />
    </div>
    <sk-checkbox :preselected-value="moveAssignments"
                 :item-text="moveAssignmentsText"
                 class="move-customer__move-assignments"
                 @checkboxvaluechanged="catchMoveAssignments" />
    <sk-note v-show="moveAssignments"
             class="move-customer__move-warning"
             type="danger"
             :text="moveWarningText" />
    <section class="move-customer__btns-wrapper">
      <button class="sk-btn sk-btn--white move-customer__btn"
              @click="closeModal">{{ $gettext('Cancel') }}</button>
      <button class="sk-btn sk-btn--default move-customer__btn"
              @click="validateForm">{{ $gettext('Move') }}</button>
    </section>
  </section>
</template>)

<script>
  import Autocomplete from '@/components/shared_components/Autocomplete';
  import BasicCustomerInfo from '@/components/customers/modals/BasicCustomerInfo';
  import {mapState} from 'vuex';

  export default {
    components: {
      autocomplete: Autocomplete,
      'basic-customer-info': BasicCustomerInfo,
    },
    data() {
      return {
        departments: '',
        searchTerm: '',
        suggestions: {},
        selectedSuggestion: '',
        moveAssignments: true,
        departmentErrorsName: 'departmentError',
        departmentErrors: [],
        enterpriseErrorsName: 'enterpriseError',
        enterpriseErrors: [],
        departmentProgress: false,
        modalProgress: false
      };
    },
    computed: {
      ...mapState('AllCustomersStore', {
        selectedCustomer: (state) => state.selectedCustomer || {}
      }),
      ...mapState('EnterpriseStore', {
        departmentsList: ({departments}) => departments || []
      }),
      enterpriseText() { return this.$gettext('Enterprise*'); },
      departmentText() { return this.$gettext('Department*'); },
      departmentPlaceholder() { return this.$gettext('Select department'); },
      organizationPlaceholder() { return this.$gettext('Search by name or org number'); },
      moveAssignmentsText() { return this.$gettext('Move assignments'); },
      moveWarningText() { return this.$gettext('This will move all selected jobs to selected enterprise. You can\'t undo this action.'); },
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      validateForm() {
        let isValidForm = true;

        this.enterpriseErrors = [];
        this.departmentErrors = [];
        this.$store.commit('ModalStore/removeModalErrors');

        if (!this.selectedSuggestion) {
          isValidForm = false;
          this.enterpriseErrors.push(this.$gettext('Enterprise is required'));
        }

        if (!this.departments.length > 0) {
          isValidForm = false;
          this.departmentErrors.push(this.$gettext('Department is required'));
        }

        this.$store.commit('ErrorsStore/setError', {name: this.enterpriseErrorsName, errors: this.enterpriseErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.departmentErrorsName, errors: this.departmentErrors});

        if (isValidForm) {
          this.submitForm();
        }
      },
      submitForm() {
        const params = {
          member_id: this.selectedCustomer.enterpriseMembershipId,
          enterprise_id: this.selectedSuggestion.id,
          department_ids: this.departments,
          move_jobs: this.moveAssignments,
        };
        this.modalProgress = true;
        this.$store.dispatch('AllCustomersStore/moveCustomerEnterprise', params)
          .then(() => {
            this.modalProgress = false;
            this.closeModal();
            this.$store.commit('AllCustomersStore/refreshCustomerGrid');
            this.showToast(this.$gettext('Customer moving is in progress'));
          }).catch((error) => {
            this.modalProgress = false;
            let message = this.$gettext('Something went wrong please try again');
            if (error?.data?.errors.includes('same enterprises')) message = this.$gettext('Can\'t move to the same enterprise');
            this.showToast(message);
          });
      },
      catchSuggestion(value) {
        this.selectedSuggestion = value;
        // get enterprise departments
        this.fetchEnterpriseDepartments();
      },
      searchEnterprise(term) {
        return this.$store.dispatch('EnterpriseStore/searchEnterprises', {query: term, search_brreg: false}).then((response) => {
          this.suggestions = response ? {
            enterprises: response.enterprises?.slice(0, 5) || []
          } : {};
        });
      },
      fetchEnterpriseDepartments() {
        const params = {
          enterpriseId: this.selectedSuggestion.id
        };
        this.departmentProgress = true;
        this.$store.dispatch('EnterpriseStore/getDepartments', params)
          .then(() => {
            this.departmentProgress = false;
          })
          .catch(() => {
            this.departmentProgress = false;
          });
      },
      showToast(message) {
        this.$store.commit('ToastStore/setToast', {
          data: {
            position: 'bottom-center',
            message: message,
            autoDeleteTime: 6000
          }
        });
      },
      catchDepartment(value) {
        this.departments = value;
      },
      catchMoveAssignments(value) {
        this.moveAssignments = value;
      },
      closeModal() {
        this.$emit('closemodal');
      },
      clearErrors() {
        this.$store.commit('ModalStore/removeModalErrors');
        this.$store.commit('ErrorsStore/setError', {name: this.enterpriseErrorsName, errors: []});
        this.$store.commit('ErrorsStore/setError', {name: this.departmentErrorsName, errors: []});
      }
    },
    beforeDestroy() {
      this.clearErrors();
      this.$store.commit('AllCustomersStore/clearSelectedCustomer');
    }
  };
</script>
<style scoped>
.move-customer__main-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 35px 30px;
}

.move-customer__basic-info {
  width: 100%;
}

.move-customer__departments-row {
  display: flex;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
}

.move-customer__departments,
.move-customer__enterprises,
.move-customer__move-warning {
  width: 100%;
}

.move-customer__move-warning {
  margin-top: 20px;
}

.move-customer__btns-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}

.move-customer__btn {
  width: auto;
  margin: 20px 5px 0;
  padding: 0 20px;
}

</style>
