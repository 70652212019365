import BookingHelper from '@/store/booking_stores/BookingHelper';

export default ({$fetcher: fetchData, $moment: moment} = {}) => {
  return {
    namespaced: true,
    state: {
      directInterpreter: false,
      directBookingSelected: false,
      directBookingWarning: '',
      suppliersList: '',
      selectedSupplier: '',
      supplierSearchQuery: '',
      hasOverlappedEvents: false,
      availabilityEvents: '',
      errors: {
        availabilityError: {name: 'availabilityError', errors: []}
      }
    },
    getters: {
      getSelectedSupplierData(state) {
        const suppliersList = state.suppliersList || [];
        return suppliersList.find(({uid}) => uid === state.selectedSupplier) || {};
      },
      getSuitableSupplierSkill(state, getters, rootState) {
        const {languageFrom: rootLangFrom, languageTo: rootLangTo} = rootState.PostingStore.LanguagesStore;
        const rootQualification = rootState.PostingStore.QualificationStore.qualification;
        const supplierData = getters.getSelectedSupplierData || {};
        const supplierSkills = supplierData.skills || [];
        return supplierSkills.find(({langFromId, langToId, qualificationId}) => {
          const skillParamsCheck = [];
          if (rootLangFrom) {
            skillParamsCheck.push(langFromId == rootLangFrom);
          }
          if (rootLangTo) {
            skillParamsCheck.push(langToId == rootLangTo);
          }
          if (rootQualification) {
            skillParamsCheck.push(qualificationId == rootQualification);
          }
          return skillParamsCheck.every((condition) => condition);
        });
      }
    },
    actions: {
      fetchAvailabilityEvents({state, commit, rootState}) {
        return new Promise((resolve, reject) => {
          const params = {};
          const startTime = rootState.PostingStore.DateTimeStore.startTime;
          const finishTime = rootState.PostingStore.DateTimeStore.finishTime;
          if (startTime) {
            params.date_from = moment(startTime).format('YYYY-MM-DD');
          }
          if (finishTime) {
            params.date_to = moment(finishTime).format('YYYY-MM-DD');
          }
          fetchData.fetchAvailabilityEvents(state.selectedSupplier, params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setAvailabilityEvents', data.interpreter);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      searchSupplierByName({commit, rootState, rootGetters}, searchName) {
        return new Promise((resolve, reject) => {
          const {languageFrom, languageTo} = rootState.PostingStore.LanguagesStore;
          const qualification = rootState.PostingStore.QualificationStore.qualification;
          const userNeedSpecificQualificationLevel = rootGetters['PostingStore/ProcessRequirementStore/userNeedSpecificQualificationLevel'];
          const gender = rootState.PostingStore.GenderStore.sex;
          const genderParam = !Number.isNaN(+gender) ? String(gender) : '';
          const params = {nameSearch: searchName};
          if (languageFrom) {
            params.langFrom = languageFrom;
          }
          if (languageTo) {
            params.langTo = languageTo;
          }
          if (genderParam && genderParam != 2) {
            params.gender = gender;
          }
          if (userNeedSpecificQualificationLevel && qualification) {
            params.qualification = qualification;
          }
          fetchData.fetchInterpreters(params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setSuppliersList', data.interpreters);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      cancelSearchInterpreters() {
        return new Promise((resolve, reject) => {
          fetchData.cancelSearchInterpreters().then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      changeDirectBooking({commit, dispatch}, bookingState) {
        commit('setDirectBooking', bookingState);
        dispatch('PostingNotifierStore/directBookingStateChanged', null, {root: true});
      },
      changeDirectBookingWarning({state, commit}, type) {
        if (state.selectedSupplier) {
          commit('PostingStore/ProcessRequirementStore/setDirectProcessing', '', {root: true});
          commit('setDirectBookingWarning', type);
        }
      },
      changeSupplier({commit, dispatch, rootState}, supplierUid) {
        commit('setSupplier', supplierUid);
        if (supplierUid !== rootState.PostInterpretationStore.job.interpretation_requirement.interpreter_uid) {
          dispatch('PostingNotifierStore/directSupplierChanged', supplierUid, {root: true});
        }
      }
    },
    mutations: {
      ...BookingHelper().mutations,
      setSupplier(state, value) {
        state.selectedSupplier = value;
      },
      setSuppliersList(state, data) {
        state.suppliersList = data;
      },
      setDirectBooking(state, value) {
        state.directBookingSelected = value;
        state.directBookingWarning = '';
        state.suppliersList = '';
        state.selectedSupplier = '';
        state.supplierSearchQuery = '';
      },
      setSupplierSearchQuery(state, value) {
        state.supplierSearchQuery = value;
      },
      setDirectBookingWarning(state, value) {
        state.directBookingWarning = value;
      },
      setDirectInterpreter(state, value) {
        state.directInterpreter = value;
      },
      setOverlappedEventsState(state, value) {
        state.hasOverlappedEvents = value;
      },
      setAvailabilityEvents(state, data) {
        state.availabilityEvents = data;
      },
      clearSupplierSearchResults(state) {
        state.suppliersList = '';
        state.supplierSearchQuery = '';
        state.hasOverlappedEvents = false;
        state.availabilityEvents = '';
      },
      clearStore(state) {
        state.directInterpreter = false;
        state.directBookingSelected = false;
        state.directBookingWarning = '';
        state.suppliersList = '';
        state.selectedSupplier = '';
        state.supplierSearchQuery = '';
        state.hasOverlappedEvents = false;
        state.availabilityEvents = '';
      }
    }
  };
};
