import BookingHelper from '@/store/booking_stores/BookingHelper';

export default () => {
  return {
    namespaced: true,
    state: {
      attachments: [],
      previousFileArchive: false,
      attachmentsSelectState: false,
      coverLetterAtached: true,
      fileInputReset: false,
      errors: {attachments: {name: 'attachmentsError', errors: []}}
    },
    getters: {
      attachments(state) {
        return state.attachments.filter((attachment) => {
          return attachment.deleted !== undefined ? !attachment.deleted : true;
        });
      },
      attachmentsCount(state, getters) {
        return getters.attachments.length;
      },
      fileType(state) {
        if (state.attachments.length) {
          const maxSize = Math.max(...state.attachments.map((att) => att.file.size));
          const maxSizedAttachment = state.attachments.find((att) => att.file.size === maxSize) || {};
          return maxSizedAttachment.file?.type || 'unknown';
        }
        return '';
      }
    },
    mutations: {
      ...BookingHelper().mutations,
      setAttachments(state, {files}) {
        if (files && files.length) {
          for (let i = 0; i < files.length; i += 1) {
            state.attachments.push({
              file: files[i],
              name: files[i].name,
              error: false
            });
          }
          state.fileInputReset = !state.fileInputReset;
        }
      },
      setCoverLetterAtached(state, value) {
        state.coverLetterAtached = value;
      },
      setAttachmentsErrors(state, {data, context}) {
        Object.keys(data).forEach((index) => {
          if (data[+index].file.includes('This file is too big')) {
            state.attachments[+index].error = context.$gettext('This file is too big.');
          } else {
            state.attachments[+index].error = false;
          }
        });
      },
      setAttachmentsSelectState(state, value) {
        state.attachmentsSelectState = value;
      },
      deleteAttachment(state, {index, id}) {
        if (id) {
          state.attachments = state.attachments.map((attachment) => {
            return attachment.id == id
              ? {
                ...attachment,
                deleted: true
              }
              : attachment;
          });
        } else {
          state.attachments.splice(index, 1);
        }
      },
      archiveFile(state) {
        state.previousFileArchive = true;
      },
      clearStore(state) {
        state.previousFileArchive = false;
        state.attachments = [];
        state.attachmentsSelectState = false;
        state.fileInputReset = false;
        state.errors.attachments.errors = [];
      }
    }
  };
};
