export default {
    path: 'projects',
    component: () => import('@/pages/ManagerPages/project_pages/m_ProjectsRoot'),
    children: [
      {
        path: '/',
        redirect: {name: 'ManagerAllProjects'}
      },
      {
        path: 'all',
        name: 'ManagerAllProjects',
        meta: {subPage: true},
        component: () => import('@/pages/ManagerPages/project_pages/m_AllProjects')
      }
    ]
  };
