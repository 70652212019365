<template>
  <sk-multiselect :items-list="itemsList"
                  :preselected-value="preselectedValue"
                  :description="description"
                  :dynamic-search="true"
                  :validation-name="validationName"
                  :default-value="defaultValue"
                  :search-by="setSearchQuery"
                  :tags="tags"
                  :loading="requestWasMade"
                  @checkboxeschanged="changeFieldData" />
</template>

<script>
  import SearchPrototype from '@/components/shared_components/form_fields/SearchPrototype';

  export default {
    extends: SearchPrototype,
    props: {
      preselectedValue: {
        type: [String, Array, Number],
        required: true
      },
      description: {
        type: String,
        default: ''
      },
      defaultValue: {
        type: String,
        default: ''
      },
      itemsList: {
        type: Array,
        default: () => []
      },
      tags: {
        type: Array,
        default: () => []
      },
      validationName: {
        type: String,
        default: ''
      }
    },
    methods: {
      changeFieldData(value) {
        this.$emit('checkboxeschanged', value);
      }
    }
  };
</script>
