import BookingHelper from '@/store/booking_stores/BookingHelper';

export default ({$fetcher: fetchData} = {}) => {
  return {
    namespaced: true,
    state: {
      contactAddresses: '',
      allowedAssignmentTypes: [],
      assignmentType: '',
      actualAssignmentType: '',
      contactAttributes: {
        person: {
          name: '',
          phone: ''
        },
        address: {
          id: '',
          extra: '',
          save_address: false
        }
      },
      videoPlatform: {
        type: 'default',
        id: '',
        instructions: '',
        url: '',
        confirmationValue: false
      },
      videoPlatformsList: '',
      errors: {
        assignmentType: {name: 'assignmentTypeError', errors: []},
        personName: {name: 'assignContactPersonName', errors: []},
        personPhone: {name: 'assignContactPersonPhone', errors: []},
        videoPlatformId: {name: 'videoPlatformIdError', errors: []},
        videoPlatformConfirmationValue: {name: 'videoPlatformConfirmationValueError', errors: []},
      }
    },
    getters: {
      defaultVideoPlatformId(state) {
        if (Array.isArray(state.videoPlatformsList)) {
          return state.videoPlatformsList?.find((platform) => platform.default)?.id || '';
        } else if (state.videoPlatform.type === 'default') {
          return state.videoPlatform.id || '';
        }
      },
      chosenAddressData(state) {
        const chosenAddressId = state.contactAttributes.address.id;

        return chosenAddressId
          ? state.contactAddresses.find((address) => address.id == chosenAddressId) || {}
          : {};
      },
      isAddressSelectedFromList(state) {
        const chosenAddressId = state.contactAttributes.address.id;

        return !!(chosenAddressId && state.contactAddresses.find((address) => address.id == chosenAddressId));
      }
    },
    actions: {
      setAssignmentType({commit, dispatch}, value) {
        commit('setAssignmentType', value);
        commit('setVideoPlatformConfirmationValue', false);
        dispatch('PostingNotifierStore/preselectAutoApprove', null, {root: true});
        dispatch('PostingNotifierStore/preselectContactPersonInfo', null, {root: true});
        commit('PostingStore/ProcessRequirementStore/setSessionTypeProcessing', '', {root: true});
      },
      getPostingSavedContactInfo({dispatch, commit}, {departmentId, headers}) {
        return new Promise((resolve, reject) => {
          fetchData.getSavedContactInfo(departmentId, headers)
            .then((contacts) => {
              if (contacts.code == 401) {
                commit('TTAuthStore/logOut', '', {root: true});
                commit('GlobalStore/remove404Page', '', {root: true});
                resolve('LOGOUT');
                return;
              }
              commit('setContactAddresses', contacts.contactAttributes.address);
              dispatch('PostingNotifierStore/setFirstSavedInPersonInfo', null, {root: true});
              resolve(contacts.contactAttributes.address);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      setAllowedAssignmentTypes({commit, rootGetters}, supplierAllowedSessionTypes) {
        let userAllowedSessionTypes = rootGetters['UserInfoStore/userHasAllowedSessionTypes']
          ? rootGetters['UserInfoStore/userAllowedSessionTypes']
          : ['phone', 'video', 'in-person', 'videoroom'];
        if (Array.isArray(supplierAllowedSessionTypes)) {
          userAllowedSessionTypes = userAllowedSessionTypes.filter((type) => supplierAllowedSessionTypes.includes(type));
        }
        commit('setAllowedAssignmentTypes', userAllowedSessionTypes || []);
      },
      getVideoPlatformsList({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getVideoPlatformsList().then((data) => {
            commit('setVideoPlatformsList', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      }
    },
    mutations: {
      ...BookingHelper().mutations,
      setAssignmentType(state, value) {
        state.assignmentType = value;
      },
      setActualAssignmentType(state, value) {
        state.actualAssignmentType = value;
      },
      setAllowedAssignmentTypes(state, value = []) {
        state.allowedAssignmentTypes = value;
      },
      // IN PERSON CONTACTS
      setContactAddresses(state, data) {
        state.contactAddresses = data;
      },
      setContactPerson(state, value) {
        state.contactAttributes.person.name = value;
      },
      setContactPhone(state, value) {
        state.contactAttributes.person.phone = value;
      },
      // IN PERSON ADDRESS
      setAddressID(state, value) {
        state.contactAttributes.address.id = value;
      },
      resetAddressID(state) {
        state.contactAttributes.address.id = '';
      },
      setAddressExtra(state, value) {
        state.contactAttributes.address.extra = value;
      },
      setAddressSaving(state, value) {
        state.contactAttributes.address.save_address = value;
      },
      // VIDEO PLATFORM
      setVideoPlatformsList(state, data) {
        state.videoPlatformsList = data;
      },
      setVideoPlatformType(state, value) {
        state.videoPlatform.type = value;
      },
      setVideoPlatformId(state, value) {
        state.videoPlatform.id = value;
      },
      setVideoPlatformInstructions(state, value) {
        state.videoPlatform.instructions = value;
      },
      setVideoPlatformUrl(state, value) {
        state.videoPlatform.url = value;
      },
      setVideoPlatformConfirmationValue(state, value) {
        state.videoPlatform.confirmationValue = value;
      },
      // OTHER
      clearContactAddresses(state) {
        state.contactAddresses = '';
      },
      clearVideoPlatformsList(state) {
        state.videoPlatformsList = '';
      },
      clearStore(state) {
        state.assignmentType = '';
        state.actualAssignmentType = '';
        state.allowedAssignmentTypes = [];
        state.contactAttributes = {
          person: {
            name: '',
            phone: ''
          },
          address: {
            id: '',
            extra: '',
            save_address: false
          }
        };
        state.videoPlatform = {
          type: 'default',
          id: '',
          instructions: '',
          url: '',
          confirmationValue: false
        };
        state.errors.assignmentType.errors = [];
        state.errors.personName.errors = [];
        state.errors.personPhone.errors = [];
        state.errors.videoPlatformId.errors = [];
        state.errors.videoPlatformConfirmationValue.errors = [];
      }
    }
  };
};
