(<template>
  <div class="post-form__section confirmation-by-phone">
    <sk-checkbox :item-text="checkboxDescription"
                 :preselected-value="confirmationByPhone"
                 class="post-form__field confirmation-by-phone__checkbox"
                 @checkboxvaluechanged="setConfirmationByPhoneCheckboxState" />
  </div>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';

  export default {
    computed: {
      ...mapState('PostingStore/ConfirmationByStore', {
        confirmationByPhone: (state) => state.confirmationByPhoneCheckbox
      }),
      checkboxDescription() { return this.$gettext('This customer wants to receive interpreter details by phone'); }
    },
    methods: {
      ...mapMutations('PostingStore/ConfirmationByStore', ['setConfirmationByPhoneCheckboxState'])
    }
  };
</script>
