(<template>
  <article :class="{'in-progress': progressActive}"
           class="dialect-request is-progress-bar">
    <div class="dialect-request_wrapper">
      <p class="dialect-request__important-note"
         v-html="importantNote"></p>
      <div class="dialect-request__row">
        <p class="dialect-request__title">{{ languageText }}:</p>
        <b class="dialect-request__value">{{ toLang }}</b>
      </div>
      <div class="dialect-request__row">
        <p class="dialect-request__title">{{ dialectText }}:</p>
        <b class="dialect-request__value">{{ dialect }}</b>
      </div>
      <h4 class="dialect-request__dont-apply">{{ dontApplyText }}</h4>
    </div>
    <div class="dialect-request_btn-wrapper">
      <button class="sk-btn sk-btn--white dialect-request__btn"
              @click="closeModal">{{ $gettext('Cancel') }}</button>
      <button class="sk-btn sk-btn--white dialect-request__btn"
              @click="continueApply">{{ $gettext('Next') }}</button>
    </div>
  </article>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      dialect() { return this.data.dialect; },
      toLang() {
        const langToId = this.data.languageToId;
        const languagesList = this.$store.state.selectsData.language || [];
        return languagesList.find((lang) => lang.id == langToId).name;
      },
      params() { return this.data.params; },
      dialectText() { return this.$gettext('Dialect'); },
      languageText() { return this.$gettext('Language'); },
      importantNote() { return this.$gettext('<b>IMPORTANT:</b> This customer has a special dialect request'); },
      dontApplyText() { return this.$gettext('Do not apply for the assignment if you can not speak and understand this dialect.'); }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      continueApply() {
        this.progressActive = true;
        this.data.context.openJobApplyModal(this.params);
      }
    }
  };
</script>

<style scoped>
  .dialect-request {
    display: block;
    width: 100%;
    padding: 20px 30px;
  }

  .dialect-request__btn {
    display: inline-block;
    width: auto;
    padding: 0 30px;
  }

  .dialect-request__btn:not(:last-child) {
    margin-right: 30px;
  }

  .dialect-request_btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
  }

  .dialect-request_wrapper {
    padding: 10px;
    border-radius: 4px;
    background-color: #e2d2bb;
  }

  .dialect-request__row {
    display: flex;
    align-items: center;
  }

  .dialect-request__title {
    margin-right: 15px;
  }

  .dialect-request__important-note {
    margin-bottom: 15px;
  }

  .dialect-request__dont-apply {
    margin-top: 15px;
  }
</style>
