import { render, staticRenderFns } from "./SendByMailInnerSection.vue?vue&type=template&id=5b2f080d&scoped=true&"
import script from "./SendByMailInnerSection.vue?vue&type=script&lang=js&"
export * from "./SendByMailInnerSection.vue?vue&type=script&lang=js&"
import style0 from "./SendByMailInnerSection.vue?vue&type=style&index=0&id=5b2f080d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b2f080d",
  null
  
)

export default component.exports