(<template>
  <div class="delete-account">
    <div v-for="(item, index) in deletedList"
         :key="item.text"
         class="delete-account__deleted-item">
      <span class="delete-account__deleted-item-number">{{ genIndexText(index) }}</span>
      <span class="delete-account__deleted-item-text"
            v-html="item.text"></span>
    </div>
    <button class="sk-btn sk-btn--white delete-account__btn"
            @click="deleteAccount">{{ $gettext('Delete my account') }}</button>
  </div>
</template>)

<script>
  import {mapActions, mapGetters} from 'vuex';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsNotInterpreter',
        'userIsInterpreter'
      ]),
      deletedList() {
        const list = [{
          condition: true,
          text: this.$gettext('We will retain financial information as per the laws of Norway: <a class="sk-link sk-link--gray-bg" href="https://www.altinn.no/en/start-and-run-business/accounts-and-auditing/accounting/retention-of-accounting-documents/" target="_blank">https://www.altinn.no/en/start-and-run-business/accounts-and-auditing/accounting/retention-of-accounting-documents/</a>')
        }, {
          condition: true,
          text: this.$gettext('We will delete your account when you click the button below.')
        }, {
          condition: true,
          text: this.$gettext('You will be logged out.')
        }, {
          condition: true,
          text: this.$gettext('If you have any active bookings, we will cancel them.')
        }, {
          condition: this.userIsNotInterpreter,
          text: this.$gettextInterpolate(this.$gettext('If you have any active bookings, that start in less than %{h} hours, we will charge you for them.'), {h: this.maxShortNoticeCancellation})
        }, {
          condition: true,
          text: this.$gettext('This will take immediate effect. It cannot be reversed.')
        }];
        return list.filter((item) => item.condition);
      },
      maxShortNoticeCancellation() { return this.data.maxShortNoticeCancellation; }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      ...mapActions('EditProfileStore', ['deleteCurrentUserAccount']),
      closeModal() {
        this.$emit('closemodal');
      },
      genIndexText(index) { return `${index + 1}.`; },
      deleteAccount() {
        this.$emit('startprogress');
        this.$store.commit('ModalStore/removeModalErrors');
        this.deleteCurrentUserAccount().then(() => {
          this.logOut();
        }).catch((error) => {
          this.$emit('stopprogress');
          if (error
            && error.data
            && error.data.errors
            && error.data.errors.length
            && error.data.errors[0] == 'In-progress jobs') {
            this.$store.commit('ModalStore/setModalErrors', this.$gettext('You can\'t delete the account until your in-progress jobs will be finished'));
          } else if (error
            && error.data
            && error.data.errors
            && error.data.errors.admins_exists == 'Last admin') {
            this.$store.commit('ModalStore/setModalErrors', this.$gettext('You\'re the last admin in the enterprise. Please, create one more admin before deleting your account'));
          } else {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }
        });
      },
      logOut() {
        this.$store.dispatch('TTAuthStore/logOut').then(() => {
          this.$goToExternalLoginPage();
        });
      }
    }
  };
</script>

<style scoped>
  .delete-account {
    padding: 30px 20px;
  }

  .delete-account__deleted-item {
    display: flex;
  }

  .delete-account__deleted-item-number {
    padding-right: 10px;
    font-weight: bold;
  }

  .delete-account__btn {
    width: auto;
    margin: 30px auto 0;
    padding: 0 15px;
    background-color: #f04;
    background-color: var(--color-error);
    color: #fff;
  }

  .delete-account__btn:hover {
    opacity: 0.65;
  }

  .delete-account__btn:active {
    border-radius: 3px;
    color: #fff;
    outline: none;
    opacity: 1;
  }
</style>
