(<template>
  <section :class="{'in-progress': modalProgress}"
           class="edit-enterprise__main-wrapper is-progress-bar">
    <h4 class="edit-enterprise__subtitle">{{ $gettext('Main info') }}</h4>
    <sk-input :description="nameText"
              :preselected-value="enterpriseName"
              :margin-bottom="true"
              :placeholder="nameText"
              :autofocus="true"
              :validation-name="errors.enterpriseName.name"
              class="edit-enterprise__three-quarters-width"
              @fieldvaluechanged="catchEnterpriseName" />
    <sk-input :description="orgNumberText"
              :preselected-value="orgNumber"
              :margin-bottom="true"
              :placeholder="orgNumberText"
              :validation-name="errors.enterpriseName.name"
              class="edit-enterprise__quarter-width"
              @fieldvaluechanged="catchOrgNumber" />
    <sk-input :description="websiteText"
              :preselected-value="website"
              :margin-bottom="true"
              :placeholder="websiteText"
              :validation-name="errors.website.name"
              class="edit-enterprise__half-width"
              @fieldvaluechanged="catchWebsite" />
    <sk-input :description="websiteDomainText"
              :preselected-value="websiteDomain"
              :margin-bottom="true"
              :placeholder="websiteDomainText"
              :validation-name="errors.websiteDomain.name"
              class="edit-enterprise__half-width"
              @fieldvaluechanged="catchWebsiteDomain" />
    <h4 class="edit-enterprise__subtitle">{{ $gettext('Address') }}</h4>
    <full-address ref="addressComponent"
                  :description="addressText" />
    <h4 class="edit-enterprise__subtitle">{{ $gettext('Owner') }}</h4>
    <sk-input :description="ownerNameText"
              :preselected-value="ownerName"
              :margin-bottom="true"
              :placeholder="ownerNameText"
              :autofocus="true"
              :validation-name="errors.ownerName.name"
              class="edit-enterprise__full-width"
              @fieldvaluechanged="catchOwnerName" />
    <div class="edit-enterprise__tel-row">
      <sk-select :type="'countryCodes'"
                 :description="countryCodeText"
                 :default-value="countryCodeText"
                 :preselected-value="telCode"
                 :mob-numeric-keyboard="true"
                 class="edit-enterprise__code-select"
                 @csvaluechanged="catchTelCode" />
      <sk-input :description="phoneText"
                :preselected-value="telNumber"
                :margin-bottom="true"
                :placeholder="phoneText"
                :type="'tel'"
                :reg-exp="/[^0-9]/g"
                :mob-numeric-keyboard="true"
                class="edit-enterprise__number-input"
                @fieldvaluechanged="catchTelNumber" />
    </div>
    <sk-input :description="emailText"
              :placeholder="emailText"
              :preselected-value="email"
              :validation-name="errors.email.name"
              :type="'email'"
              class="participants-section__input"
              @fieldvaluechanged="catchEmail" />
    <section class="merge-customer__btns-wrapper">
      <button class="sk-btn sk-btn--white merge-customer__btn">{{ $gettext('Cancel') }}</button>
      <button class="sk-btn sk-btn--default merge-customer__btn">{{ $gettext('Save') }}</button>
    </section>
  </section>
</template>)

<script>
  import {mapActions} from 'vuex';
  import FullAddress from '@/components/shared_components/FullAddress';

  export default {
    components: {
      'full-address': FullAddress
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        enterpriseName: this.data.currentEnterprise?.website || '',
        orgNumber: this.data.currentEnterprise?.orgNumber || '',
        website: this.data.currentEnterprise?.website || '',
        websiteDomain: this.data.currentEnterprise?.websiteDomain || '',
        email: this.data.currentEnterprise?.email || '',
        ownerName: this.data.currentEnterprise?.ownerName || '',
        errors: {
          enterpriseName: {name: 'enterpriseNameError', errors: []},
          website: {name: 'websiteError', errors: []},
          websiteDomain: {name: 'websiteDomainError', errors: []},
          email: {name: 'emailError', errors: []},
          ownerName: {name: 'ownerNameError', errors: []},
          bookingRef: {name: 'bookingRefError', errors: []},
        },
        emailPassServerValidation: false,
        emailIsAvailable: true,
        telCode: this.data.currentEnterprise?.phoneCode || '+47',
        telNumber: this.data.currentEnterprise?.phoneNumber || '',
        modalProgress: false,
      };
    },
    computed: {
      websiteText() { return this.$gettext('Website'); },
      websiteDomainText() { return this.$gettext('Website domain'); },
      nameText() { return this.$gettext('Name*'); },
      ownerNameText() { return this.$gettext('Name'); },
      orgNumberText() { return this.$gettext('Org number*'); },
      addressText() { return this.$gettext('Search address'); },
      emailText() { return this.$gettext('Email'); },
      countryCodeText() { return this.$gettext('Country code'); },
      phoneText() { return this.$gettext('Phone number'); }
    },
    methods: {
      ...mapActions('EnterpriseStore', [
        'inviteEmployee',
        'updateEmployee'
      ]),
      setError({fieldName, errorText}) {
        this.errors[fieldName].errors.push(errorText);
        this.$store.commit('ErrorsStore/setError', {
          name: this.errors[fieldName].name,
          errors: this.errors[fieldName].errors
        });
      },
      removeErrors() {
        for (const key of Object.keys(this.errors)) {
          this.errors[key].errors = [];
        }
        this.$store.commit('ModalStore/removeModalErrors');
        this.$store.commit('ErrorsStore/removeAllErrors');
      },
      catchOrgNumber(value) {
        this.orgNumber = value;
      },
      catchEnterpriseName(value) {
        this.enterpriseName = value;
      },
      catchWebsite(value) {
        this.website = value;
      },
      catchWebsiteDomain(value) {
        this.websiteDomain = value;
      },
      catchOwnerName(value) {
        this.ownerName = value;
      },
      catchEmail(value) {
        this.email = value;
      },
      catchTelCode(value) {
        this.telCode = value;
      },
      catchTelNumber(value) {
        this.telNumber = value;
      },
      closeModal() {
        this.$emit('closemodal');
      },
      checkForm() {
        let isValidForm = true;

        this.removeErrors();
        this.$store.commit('ModalStore/removeModalErrors');
        if (!this.website.trim()) {
          isValidForm = false;
          this.setError({
            fieldName: 'website',
            errorText: this.$gettext('First name is required')
          });
        }
        if (!this.websiteDomain.trim()) {
          isValidForm = false;
          this.setError({
            fieldName: 'websiteDomain',
            errorText: this.$gettext('Last name is required')
          });
        }
        if (!this.email.trim()) {
          isValidForm = false;
          this.setError({
            fieldName: 'email',
            errorText: this.$gettext('Email is required')
          });
        } else if (!this.isValidEmail(this.email.trim())) {
          isValidForm = false;
          this.setError({
            fieldName: 'email',
            errorText: this.$gettext('Please enter a valid email')
          });
        }

        if (isValidForm) {
          this.submitForm();
        }
      },
      submitForm() {
        const form = new FormData();
        const submitAction = this.employeeUid ? 'updateEmployee' : 'inviteEmployee';
        let params = form;

        if (this.employeeUid) {
          params = {
            form,
            id: this.employeeUid,
          };
        }

        form.append('employee[website]', this.website.trim());
        form.append('employee[websiteDomain]', this.websiteDomain.trim());
        if (this.telCode && this.telNumber) {
          form.append('employee[phoneCode]', this.telCode);
          form.append('employee[phoneNumber]', this.telNumber.trim());
        }
        if (this.currentDepartment && this.currentDepartment.id) {
          form.append('employee[departments][][id]', this.currentDepartment.id);
        } else if (this.departments && this.departments.length) {
          for (const dep of this.departments) {
            form.append('employee[departments][][id]', dep);
          }
        }
        if (this.bookingRef.trim()) {
          form.append('employee[bookingReference]', this.bookingRef.trim());
        }
        form.append('employee[email]', this.email.toLowerCase().trim());

        this.$emit('startprogress');
        this[submitAction](params).then((data) => {
          if (this.data.context && this.data.context[this.data.successHandlingCallbackName]) {
            this.data.context[this.data.successHandlingCallbackName](data.employee.uid);
          }
          this.$emit('stopprogress');
          this.closeModal();
        }).catch((error) => {
          this.$emit('stopprogress');
          this.handleError(error);
        });
      },
      handleError(error) {
        if (error && error?.status == 401) {
          Promise.all([
            this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid),
            this.$store.dispatch('EnterpriseStore/getEmployees')
          ]).then(() => {
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('Sorry, you don\'t have rights to manage employees. Please contact an administrator.')
              });
            }, 0);
          });
        } else if (error
          && error.data
          && error.data.errors) {
          if (error.data.errors.length
            && error.data.errors.includes('Email is already registered')) {
            this.setError({
              fieldName: 'email',
              errorText: this.$gettext('This email address has already been registered')
            });
          } else if (error.data.errors.email
            && error.data.errors.email.length
            && error.data.errors.email.includes('Invalid email')) {
            this.setError({
              fieldName: 'email',
              errorText: this.$gettext('Please check the email address you have provided for spelling errors, and try again.')
            });
          } else if (error.data.errors.department_jobs
            && error.data.errors.department_jobs.length
            && error.data.errors.department_jobs.includes('There are attended jobs for')) {
            this.$store.dispatch('ModalStore/closeModal');
            const template = this.$gettext('This user is part of several assignments for this department. To remove them, please contact us at <a class="sk-link" href="mailto:%{support}">%{support}</a>');
            const infoMessage = this.$gettextInterpolate(template, {support: this.$getString('domainData', 'supportEmail')});
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {text: infoMessage});
            }, 0);
          }
        } else {
          this.$store.dispatch('ModalStore/closeModal');
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }, 0);
        }
      }
    },
    beforeMount() {
      this.removeErrors();
    },
    beforeDestroy() {
      this.$store.commit('EnterpriseStore/removeEmailQuery');
    }
  };
</script>

<style scoped>
.edit-enterprise__main-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 35px 30px;
}

.edit-enterprise__subtitle {
  width: 100%;
  margin-bottom: 12px;
}

.edit-enterprise__half-width {
  width: calc(50% - 10px);
}

.edit-enterprise__three-quarters-width {
  width: calc(75% - 10px);
}

.edit-enterprise__quarter-width {
  width: calc(25%);
}

.edit-enterprise__tel-row {
  display: flex;
  width: 100%;
}

.edit-enterprise__code-select {
  flex-shrink: 0;
  width: 85px;
  margin-right: 10px;
}

.edit-enterprise__number-input {
  width: calc(100% - 95px);
}

.merge-customer__btns-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}

.merge-customer__btn {
  width: auto;
  margin: 20px 5px 0;
  padding: 0 20px;
}

@media (max-width: 767px) {
  .edit-enterprise__main-wrapper {
    padding: 15px;
  }
}
</style>
