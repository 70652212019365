const interpretationSkills = {
  methods: {
    // ==================================================
    // ==================================================
    // Interpretation Skill CALLS
    // ==================================================
    // ==================================================
    getInterpretationSkills(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/managers/interpretation_skills', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    createSkill(params) {
      return new Promise((resolve, reject) => {
        this.ajax.post('v1/managers/interpretation_skills', params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
  }
};

export default interpretationSkills;
