<template>
  <article class="attached-file"
           :class="{'is-selected': isSelected}"
           @click.prevent.stop="emitItemSelect">
    <figure v-if="isSelected"
            class="attached-file__tick-icon"></figure>
    <figure class="attached-file__icon"
            :class="[`attached-file__icon--${fileType}`]"></figure>
    <div class="attached-file__info">
      <p class="attached-file__name overflow-ellipsis">{{ fileName }}</p>
      <p class="attached-file__created-at">{{ createdAtF }}</p>
    </div>
  </article>
</template>

<script>
  import helpers from '@/helpers';

  export default {
    props: {
      file: {
        type: Object,
        default: () => {}
      },
      isSelected: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      name() { return this.file?.filenameSearchValue; },
      type() { return this.file?.contentType; },
      fileName() { return this.name; },
      createdAt() { return this.file?.createdAt; },
      createdAtF() { return this.$moment(this.createdAt).format('HH:mm, DD:MM:YYYY'); },
      fileType() { return helpers.file.fileType(this.type); },
    },
    methods: {
      emitItemSelect() {
        if (!this.isSelected) this.$emit('selected', this.file);
        else this.$emit('unselected', this.file);
      }
    }
  };
</script>

<style scoped>
.attached-file {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 22%;
  border-radius: 8px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}

.attached-file:hover {
  border: 1px solid var(--color-primary);
}

.attached-file.is-selected {
  background-color: var(--salita-25);
}

.attached-file.is-selected .attached-file__icon {
  background-color: var(--col-white);
}

.attached-file__tick-icon {
  position: absolute;
  top: 9px;
  right: 9px;
  width: 16px;
  height: 16px;
  background-image: url(~@assets/imgs/undefined_imgs/check_icon_filled.svg);
  background-position: center center;
  background-repeat: no-repeat;
}

.attached-file__icon {
  width: 40px;
  height: 40px;
  margin: 16px 54px;
  border-radius: 8px;
  background: var(--cool-gray-50);
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
}

.attached-file__info {
  display: inline-grid;
  margin: 0 16px 16px 16px;
}

.attached-file__name {
  font-weight: 600;
}

.attached-file__created-at {
  color: var(--black-500);
  font-size: 12px;
  line-height: 16px;
}

.attached-file__icon--document {
  background-image: url(~@assets/imgs/file_types/document.svg);
}

.attached-file__icon--audio {
  background-image: url(~@assets/imgs/file_types/audio.svg);
}

.attached-file__icon--image {
  background-image: url(~@assets/imgs/file_types/image.svg);
}
</style>
