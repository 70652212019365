// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/field_icons/search_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.autocomplete[data-v-6c3924a1] {\n  position: relative;\n}\n.autocomplete-list[data-v-6c3924a1] {\n  position: absolute;\n  top: calc(100% + 10px);\n  z-index: 10;\n  width: 100%;\n  background-color: #fff;\n  -webkit-box-shadow: 0 1px 3px 0 rgb(0 34 102 / 10%), 0 1px 2px 0 rgb(0 34 102 / 20%);\n          box-shadow: 0 1px 3px 0 rgb(0 34 102 / 10%), 0 1px 2px 0 rgb(0 34 102 / 20%);\n}\n.is-with-error .autocomplete-input[data-v-6c3924a1] {\n  border: #f04;\n  border: solid 1px var(--color-error);\n  background-color: #ffe5ec;\n}\n.is-highlighted[data-v-6c3924a1] {\n  background-color: #f4f5f7;\n}\n.is-section-header[data-v-6c3924a1] {\n  color: #555;\n  font-size: 12px;\n}\n.is-section-header[data-v-6c3924a1]:hover {\n  background-color: #fff;\n}\n.search-icon[data-v-6c3924a1] {\n  position: absolute;\n  left: 5px;\n  width: 25px;\n  height: 35px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 16px auto;\n  background-repeat: no-repeat;\n}\n.autocomplete-input[data-v-6c3924a1] {\n  padding-left: 33px;\n  background-color: #fff;\n}\n.enterprises-count[data-v-6c3924a1] {\n  margin-left: 8px;\n  padding: 1px 4px 3px;\n  border-radius: 3px;\n  background-color: #f4f5f7;\n}\n.org-name[data-v-6c3924a1] {\n  color: #ff5b24;\n}\n.org-number[data-v-6c3924a1],\n.org-address[data-v-6c3924a1] {\n  position: relative;\n  margin-right: 8px;\n}\n.org-number[data-v-6c3924a1]::after,\n.org-address[data-v-6c3924a1]::before {\n  content: '';\n  position: absolute;\n  top: 9px;\n  width: 3px;\n  height: 3px;\n  margin-left: 5px;\n  border-radius: 50%;\n  background: grey;\n}\n.org-address[data-v-6c3924a1] {\n  margin-left: 8px;\n}\n.org-address[data-v-6c3924a1]::before {\n  margin-left: -8px;\n}\n", ""]);
// Exports
module.exports = exports;
