<template>
  <div class="availability-timeline">
    <div :class="{'availability-timeline__cont--show-times': !hideTime,
                  'availability-timeline__cont--error': hasErrors}"
         class="availability-timeline__cont">
      <div v-if="daysRangeAmount > 1"
           class="availability-timeline__row availability-timeline__row--date">
        <p v-for="period in periodsArray"
           :key="period"
           :style="{'width': `${colWidth}px`}"
           class="availability-timeline__time-cell">{{ getPeriodDateText(period) }}</p>
      </div>
      <div v-if="!hideTime"
           class="availability-timeline__row availability-timeline__row--time">
        <p v-for="period in periodsArray"
           :key="period"
           :style="{'width': `${colWidth}px`}"
           class="availability-timeline__time-cell">{{ getPeriodTimeText(period) }}</p>
      </div>
      <div class="availability-timeline__row availability-timeline__row--event"
           @click="catchTimelineClick">
        <div v-for="period in periodsArray"
             :key="period"
             :style="{'width': `${colWidth}px`}"
             class="availability-timeline__time-cell">
          <timeline-event v-for="(event, index) in getCellEvents(period)"
                          :key="`${index}${event.id}`"
                          :event="event"
                          :width="getStyles(event).width"
                          :left="getStyles(event).left"
                          :enable-event-tooltips="enableEventTooltips"
                          class="availability-timeline__event" />
        </div>
      </div>
    </div>
    <events-description v-if="enableEventDescription"
                        :events-list="eventsList"
                        :is-selected-event="!!selectedEvent"
                        class="availability-timeline__events-description" />
    <div v-if="hasErrors"
         class="availability-timeline__errors-cont">
      <span v-for="(error, index) in errors"
            :key="index"
            v-html="`${index + 1}. ${error}`"></span>
    </div>
  </div>
</template>

<script>
  import TimelineEvent from '@/components/shared_components/availability/TimelineEvent';
  import EventsDescription from '@/components/shared_components/availability/EventsDescription';

  export default {
    components: {
      'timeline-event': TimelineEvent,
      'events-description': EventsDescription
    },
    props: {
      // eventsList: [[data, eventType], ...]
      //
      // handled eventTypes:
      // - 'event'
      // - 'job'
      // - 'offtime'
      // - 'travelFromTime'
      // - 'travelToTime'
      eventsList: {
        type: Array,
        required: true
      },
      // selectedEvent: [data, eventType]
      selectedEvent: {
        type: Array,
        default: () => []
      },
      dateRangeFrom: {
        type: String,
        default: ''
      },
      dateRangeTo: {
        type: String,
        default: ''
      },
      colWidth: {
        type: Number,
        required: true
      },
      timelineStep: {
        type: Number,
        required: true
      },
      validationName: {
        type: String,
        default: ''
      },
      enableEventDescription: Boolean,
      enableEventTooltips: Boolean,
      hideTime: Boolean
    },
    computed: {
      periodsArray() {
        const arr = [];
        const cellsAmount = ((60 / this.timelineStep) * 24) * this.daysRangeAmount;
        const rangeStartDate = this.dateRangeFrom || this.$moment(this.eventDate);
        let dateMoment = this.$moment(rangeStartDate).hours(0).minutes(0).seconds(0);
        for (let i = 0; i < cellsAmount; i += 1) {
          arr.push(dateMoment.format('YYYY-MM-DD HH:mm'));
          dateMoment = dateMoment.add(this.timelineStep, 'minutes');
        }
        return arr;
      },
      daysRangeAmount() {
        const rangeNormalizedDateFrom = this.getNormalizedDateTime(this.dateRangeFrom);
        const rangeNormalizedDateTo = this.getNormalizedDateTime(this.dateRangeTo);
        return this.dateRangeFrom && this.dateRangeTo
          ? rangeNormalizedDateTo.diff(rangeNormalizedDateFrom, 'days') + 1
          : 1;
      },
      eventDate() {
        return this.availabilityEventsList.length
          ? this.$moment(this.availabilityEventsList[0].startTime).format('YYYY-MM-DD')
          : this.$moment();
      },
      selectedEventData() {
        const res = [];
        if (this.selectedEvent) {
          const eventData = this.selectedEvent[0];
          const eventTypeName = this.selectedEvent[1];
          const data = {
            ...eventData,
            id: 0,
            isSelected: true
          };
          res.push([[data], eventTypeName]);
        }
        return res;
      },
      availabilityEventsList() {
        const res = [];
        const events = [...this.eventsList, ...this.selectedEventData];
        events.forEach((item) => {
          const eventData = item[0];
          const eventTypeName = item[1];
          res.push(this.getTransformedEventData(eventData, eventTypeName));
        });
        return res.flat();
      },
      hasErrors() {
        return this.errors && this.errors.length;
      },
      errors() {
        return this.$store.getters['ErrorsStore/getNamedErrors'](this.validationName);
      }
    },
    watch: {
      availabilityEventsList() {
        this.catchEventsOverlap();
      }
    },
    methods: {
      getPeriodTimeText(period) {
        return this.$moment(period).format('HH:mm');
      },
      getPeriodDateText(period) {
        if (this.getPeriodTimeText(period) === '00:00') {
          return this.$moment(period).format('DD/MM/YYYY');
        }
      },
      getNormalizedDateTime(period) {
        return period ? this.$moment(period).hours(0).minutes(0).seconds(0) : '';
      },
      getTransformedEventData(data, type) {
        return data.map((event) => {
          return {
            ...event,
            id: `${event.id}-${type}`,
            startTime: event.startTime || event.start_time,
            finishTime: event.finishTime || event.finish,
            eventType: type
          };
        });
      },
      getStyles(event) {
        return {
          left: `${this.calculateTimeStepDiff(event) || 0}px`,
          width: `${this.calculateWidth(event)}px`,
        };
      },
      calculateWidth(event) {
        const eventDuration = this.$moment(event.finishTime) - this.$moment(event.startTime);
        const oneMinuteWidth = this.colWidth / this.timelineStep;
        return (eventDuration / 60000) * oneMinuteWidth;
      },
      calculateTimeStepDiff(event) {
        const timeStep = this.timelineStep;
        const startTimeMinutes = this.$moment(event.startTime).minutes();
        const oneMinuteWidth = this.colWidth / timeStep;
        if (startTimeMinutes != 0 && startTimeMinutes % timeStep != 0) {
          return startTimeMinutes > timeStep ? (startTimeMinutes - timeStep) * oneMinuteWidth : startTimeMinutes * oneMinuteWidth;
        }
      },
      getCellEvents(period) {
        const res = [];
        const cellMoment = this.$moment(period);
        this.availabilityEventsList.forEach((event) => {
          if (cellMoment.isSame(event.startTime)
            || (cellMoment.isBefore(event.startTime) && this.$moment(cellMoment).add(this.timelineStep - 1, 'minutes').isAfter(event.startTime))) {
            res.push(event);
          }
        });
        return res;
      },
      catchTimelineClick() {
        this.$emit('onclick');
      },
      catchEventsOverlap() {
        this.$emit('overlapstatechanged', this.checkEventsOverlap());
      },
      removeErrors() {
        this.$store.commit('ErrorsStore/setError', {name: this.validationName, errors: []});
      },
      checkEventsOverlap() {
        let isOverlapped = false;
        this.removeErrors();
        if (this.availabilityEventsList.length && this.selectedEventData.length) {
          const [[[selectedEvent]]] = this.selectedEventData;
          const selectedEventStartTime = selectedEvent.startTime;
          const selectedEventFinishTime = selectedEvent.finishTime;

          this.availabilityEventsList.forEach((event) => {
            const eventStartTime = this.$moment(event.startTime);
            const eventFinishTime = this.$moment(event.finishTime);
            const isOverlappedStartTime = this.$moment(selectedEventStartTime).isBetween(eventStartTime, eventFinishTime);
            const isOverlappedFinishTime = this.$moment(selectedEventFinishTime).isBetween(eventStartTime, eventFinishTime);

            if (!event.allowPosting && (isOverlappedStartTime || isOverlappedFinishTime)) {
              isOverlapped = true;
            }
          });
        }
        return isOverlapped;
      }
    },
    mounted() {
      this.catchEventsOverlap();
    }
  };
</script>

<style scoped>
  .availability-timeline__cont {
    position: relative;
    z-index: 0;
    display: block;
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
    padding: 12px 0 3px;
    background: transparent;
  }

  .availability-timeline__row {
    display: flex;
    width: 100%;
  }

  .availability-timeline__row--time {
    margin-bottom: 5px;
    font-size: 10px;
  }

  .availability-timeline__row--date {
    margin-bottom: 2px;
    font-weight: bold;
    font-size: 10px;
    white-space: nowrap;
  }

  .availability-timeline__time-cell {
    flex-shrink: 0;
  }

  .availability-timeline__row--event {
    min-height: 30px;
  }

  .availability-timeline__row--event .availability-timeline__time-cell {
    position: relative;
    padding: 2px 0;
    border-top: solid 1px #d3d5de;
    border-bottom: solid 1px #d3d5de;
    background: #fff;
  }

  .availability-timeline__cont--show-times .availability-timeline__row--event .availability-timeline__time-cell {
    border-right: 1px solid #d3d5de;
  }

  .availability-timeline__cont--show-times.availability-timeline__cont--error .availability-timeline__row--event .availability-timeline__time-cell {
    border-right: 1px solid #f04;
  }

  .availability-timeline__cont--error .availability-timeline__row--event .availability-timeline__time-cell {
    border-top: solid 1px #f04;
    border-bottom: solid 1px #f04;
    background: rgba(255, 0, 68, 0.2);
  }

  .availability-timeline__row--event .availability-timeline__time-cell:first-child {
    border-left: solid 1px #d3d5de;
    border-radius: 5px 0 0 5px;
  }

  .availability-timeline__cont--error .availability-timeline__row--event .availability-timeline__time-cell:first-child {
    border-left: solid 1px #f04;
  }

  .availability-timeline__row--event .availability-timeline__time-cell:last-child {
    border-right: solid 1px #d3d5de;
    border-radius: 0 5px 5px 0;
  }

  .availability-timeline__cont--error .availability-timeline__row--event .availability-timeline__time-cell:last-child {
    border-right: 1px solid #f04;
  }

  .availability-timeline__errors-cont {
    width: 100%;
    margin-top: 2px;
    padding-bottom: 10px;
    color: #f04;
    color: var(--color-error);
    font-size: 12px;
  }

  .availability-timeline__events-description {
    padding: 5px 0;
  }
</style>
