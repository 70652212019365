<script>
  import {mapState, mapMutations, mapActions} from 'vuex';
  import InteractiveSearchSelect from '@/components/shared_components/form_fields/InteractiveSearchSelect';

  export default {
    extends: InteractiveSearchSelect,
    computed: {
      ...mapState('AllSuppliersStore', {
        searchQuery: ({supplierSearchQuery}) => supplierSearchQuery
      })
    },
    methods: {
      ...mapActions({
        submitAction: 'AllSuppliersStore/searchSupplierByName',
        cancelSearch: 'AllSuppliersStore/cancelSearchSupplier'
      }),
      ...mapMutations({
        setSearchQueryMutation: 'AllSuppliersStore/setSupplierSearchQuery'
      })
    }
  };
</script>
