const jobs = {
  methods: {
    getCallDetails(id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/jobs/${id}/details`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getAnAssignment(jobId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/jobs/${jobId}`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          const mess = `Get job with id: ${jobId} responded with ${error?.status} code`;
          if (error?.status == 403) {
            this.resolveError403(error, resolve, mess);
          }
          reject(error);
        });
      });
    },
    getJobDetailsById(jobId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/jobs/${jobId}/compact_data`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });

        // setTimeout(() => {
        //   resolve(faker.jobDetails);
        // }, 1500);
      });
    },
    getSuitableInterpreters(id, params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/jobs/${id}/suitable_interpreters`, {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          this.resolveError404(error, resolve);
          reject(error);
        });
      });
    },
    getAutoInvitableInterpreters(id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/jobs/${id}/auto_invitable_interpreters `).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          this.resolveError404(error, resolve);
          reject(error);
        });
      });
    },
    getSuitableNotInvitedInterpreters(id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/jobs/${id}//suitable_interpreters?suitable_not_yet_invited=true`).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          this.resolveError404(error, resolve);
          reject(error);
        });
      });
    },
    getSuitableInterpreterById(jobId, interId) {
      return new Promise((resolve, reject) => {
        this.ajax.get(`v1/jobs/${jobId}/suitable_interpreters/${interId}`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
        // setTimeout(() => {
        //   resolve(faker.suitableInterpreter);
        // }, 1500);
      });
    },
    getStandardOffer(jobId, entityId, travelObj) {
      const self = this;
      const params = {supplier_id: entityId};
      if (travelObj) {
        params.travel_cost = {
          travel_from_longitude: travelObj.travelFromLongitude || '',
          travel_from_latitude: travelObj.travelFromLatitude || '',
          travel_type: travelObj.travelType || 'default'
        };
      }
      return new Promise((resolve) => {
        self.ajax.post(`v1/jobs/${jobId}/standard_offer`, params).then((response) => {
          resolve(response?.data?.offer);
        }).catch((error) => {
          this.resolveError403(error, resolve, `Get apply price for job with id: ${jobId} responded with ${error?.status} code`);
        });
      });
    },
    getInterInvoicePreview(data) {
      const params = data.params || {};
      return new Promise((resolve, reject) => {
        this.ajax.get(`v1/jobs/${data.jobId}/preview_supplier_invoice`, {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getBuyerInvoicePreview(data) {
      const params = data.params || {};
      return new Promise((resolve, reject) => {
        this.ajax.get(`v1/jobs/${data.jobId}/preview_demander_invoice`, {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getFinalInvoice(jobId) {
      return new Promise((resolve, reject) => {
        this.ajax.get(`v1/jobs/${jobId}/confirmed_invoice`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          this.resolveError404(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    getInterpreterTotalInvoicePreview({jobId, params}) {
      return new Promise((resolve, reject) => {
        this.ajax.get(`v1/jobs/${jobId}/preview_invoice_total`, {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          this.resolveError404(error, resolve);
          reject(error);
        });
      });
    },
    makeJobBlockInterpreter(jobId, interpreterUid, headers) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/jobs/${jobId}/block_interpreter`, {id: jobId, interpreter_uid: interpreterUid}, {headers}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    makeAwardAsCustomer(jobId, supplierId) {
      const self = this;
      const params = {
        job_id: jobId,
        supplier_id: supplierId
      };
      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/support/job_awards', params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    makeJobAbandon(jobId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/jobs/${jobId}/decline_invite`).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    makeDeclineInvite(jobId, interpreterUid, headers) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/jobs/${jobId}/decline_invite`, {interpreter_uid: interpreterUid}, {headers}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    updateFeedback(feedbackForm) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put('v1/jobs/update_feedback', feedbackForm).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    sendFeedbackForCancelledAssignment(discussionId, feedback) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/discussions/${discussionId}/feedback_for_cancelled_job`, {
        interpreterTravelled: feedback.interpreterTravelled,
        travelType: feedback.travelType,
        travelTime: feedback.travelTime,
        travelDistance: feedback.travelDistance,
        interpreterArrivedAtCancelledJob: feedback.interpreterArrivedAtCancelledJob
      }).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    sendFeedbackV3(discussionId, feedback) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.post(`v2/jobs/discussions/${discussionId}/feedbacks`, feedback).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    updateFeedbackV3(discussionId, feedbackId, {feedback}) {
      const self = this;
      const params = {
        feedback: {
          id: feedbackId,
          ...feedback
        }
      };
      return new Promise((resolve, reject) => {
        self.ajax.put(`v2/jobs/discussions/${discussionId}/feedbacks/${feedbackId}`, params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    sendInvite(jobId, interpreterUid, headers) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/jobs/${jobId}/send_invite`, {interpreter_uid: interpreterUid}, {headers: headers}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    sendCancelNotShown(jobId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/support/jobs/${jobId}/cancel_not_shown`).then((response) => {
          resolve(response?.data);
        }).catch((response) => {
          reject(response?.data);
        });
      });
    },
    sendChangeAutoInviteDelay(jobId, params, headers) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/support/jobs/${jobId}/change_auto_invite_delay`, params, {headers: headers}).then((response) => {
          resolve(response?.data);
        }).catch((response) => {
          reject(response?.data);
        });
      });
    },
    lockJob(jobId) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/support/jobs/${jobId}/locks`).then((response) => {
          resolve(response?.data);
        }).catch((response) => {
          reject(response?.data);
        });
      });
    },
    finishJob(id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/jobs/${id}/finish`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getSupplierLink(interpreterUid, jobId) {
      const self = this;
      const paramsObj = {
        params: {interpreter_uid: interpreterUid},
        cancelToken: this.cancelationSource.token
      };

      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/support/jobs/${jobId}/login_for_job`, paramsObj).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    sendCancel(jobId, reason, withoutCharge, withNotification) {
      let path = 'v1/jobs/';
      let params = reason;

      if (withoutCharge) {
        path = 'v1/interpreters/jobs/';
        params = {...params, without_charge: true};
      } else if (withNotification !== undefined) {
        params = {...params, send_email: withNotification};
      }

      return new Promise((resolve, reject) => {
        this.ajax.post(`${path}${jobId}/cancel`, params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    fetchAttendersForDepartment(department, headers) {
      const params = department ? {department_id: department} : {};
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/jobs/attenders', {params, headers})
          .then((response) => {
            resolve(response?.data);
          }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    fetchSystemLogs(jobId, params = {}) {
      return new Promise((resolve, reject) => {
        this.ajax.get(`/v1/support/jobs/${jobId}/logs`, {params})
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            this.resolveError403(error, resolve);
            reject(error);
          });
      });
    },
    sendPostNavAssignment(jobForm) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/navs/jobs', jobForm).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    sendEditAssignment(jobForm, id, supportMode) {
      const self = this;

      if (supportMode) {
        return new Promise((resolve, reject) => {
          self.ajax.put(`/v1/support/jobs/${id}`, jobForm).then((response) => {
            resolve(response?.data);
          }).catch((error) => {
            reject(error);
          });
        });
      } else {
        return new Promise((resolve, reject) => {
          self.ajax.patch(`/v1/jobs/${id}`, jobForm).then((response) => {
            resolve(response?.data);
          }).catch((error) => {
            reject(error);
          });
        });
      }
    },
    updateAssignmentProcessRequirement(jobForm, id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.patch(`/v1/jobs/${id}/update_process_requirement`, jobForm).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    updateAssignmentPriceRequirement(jobForm, id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/support/jobs/${id}/update_auto_award_requirement`, jobForm).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getLastUpdatedData(jobId) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/jobs/${jobId}/last_updated_data`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    changeInterpreterRating(id, params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/jobs/${id}/change_interpreter_rating`, params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    updateAssignmentNote(jobId, noteForm) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/support/jobs/${jobId}/update_note`, noteForm).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    downgradeProcessRequirements(id, parameter) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/support/jobs/${id}/downgrade_requirements`, {requirement_parameter: parameter}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    fetchJobStatement(jobId, statementId) {
      return new Promise((resolve, reject) => {
        this.ajax.get(`/v1/support/jobs/${jobId}/statements/${statementId}`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    updateJobStatement(jobId, statementId, form) {
      return new Promise((resolve, reject) => {
        this.ajax.put(`/v1/support/jobs/${jobId}/statements/${statementId}`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    sendJobDetails(form, id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/jobs/${id}/send_session_details`, form).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getSavedContactInfo(departmentId, headers) {
      const self = this;
      const params = departmentId ? {department_id: departmentId} : {};
      return new Promise((resolve, reject) => {
        self.ajax.get('v1/jobs/contact_attributes', {params, headers}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    sendPostAnAssignment(jobForm, support) {
      const self = this;
      const path = support ? '/v1/support/jobs' : '/v1/jobs';
      return new Promise((resolve, reject) => {
        self.ajax.post(path, jobForm).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    postLogoutAssignment(jobForm) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.post('v1/jobs/logout_create', jobForm).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    saveNewAttendersList(id, array) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put('v1/jobs/attenders', {job_id: id, participants: array}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    getTemplates(type) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/support/pricing_templates/${type}_templates`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    updateCurrentTemplate(jobId, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/support/jobs/${jobId}/update_template`, form, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    }
  }
};

export default jobs;
