export default {
  path: 'payouts',
  name: 'ServerPayouts',
  component: () => import('@/pages/ServerPages/payouts/s_PayoutsRoot'),
  children: [
    {
      path: 'all',
      name: 'ServerPayoutsAll',
      meta: {subPage: true},
      component: () => import('@/pages/ServerPages/payouts/s_AllPayouts')
    },
    {
      path: 'rates',
      name: 'ServerRates',
      meta: {subPage: true},
      component: () => import('@/pages/shared_pages/sh_Rates')
    }
    // {
    //   path: 'preferences',
    //   name: 'ServerPayoutPreferences',
    //   meta: {subPage: true},
    //   component: () => import('@/pages/ServerPages/payouts/s_PayoutPreferences')
    // },
  ]
};
