const auth = {
  methods: {
    sendSocialAuth(provider) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.authAjax.get(`/oauth/${provider}`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    sendAuthData(formObj, type) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.authAjax.post((type == 'signUp' ? '/identities' : '/sign_in'), formObj).then((response) => {
          self.setToken(response?.data?.token);
          resolve(response?.data);
        }).catch((error) => {
          if (error.status === 429) reject(error);
          reject(error?.data || {});
        });
      });
    },
    signOut() {
      const self = this;

      return new Promise((resolve, reject) => {
        self.authAjax.post('/sign_out').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    sendOneTimeLogin(form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.authAjax.post('/magic_links', form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    sendResetEmail(form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.authAjax.post('/reset_passwords', form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    sendResetPass(form, token) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.authAjax.put(`/reset_passwords/${token}`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    sendNewPass(form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.authAjax.post('/change', form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    acceptInvite(form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.authAjax.post('/accept_invitation', form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    verifyMagicLink(token) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/accounts/authentication_links/${token}`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    verifyEmail(uid, token) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/email_verifications/${uid}/verify/${token}`).then((data) => {
          resolve(data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    sendVerifyEmail() {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/email_verifications').then(() => {
          resolve();
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    setToken(token, uid) {
      this.userToken = token;
      this.uid = uid;
      this.initAxiosInstances();
      this.initAxiosInterceptors();
    },
  }
};

export default auth;
