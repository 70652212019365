(<template>
  <section class="stand-by-event">
    <add-event :simple-event="true" />
    <div class="stand-by-event__fields-wrapper">
      <sk-select :items-list="sessionTypes"
                 :default-value="sessionTypeText"
                 :description="sessionTypeText"
                 :preselected-value="sessionType"
                 :validation-name="sessionTypeValidationName"
                 class="stand-by-event__field"
                 @csvaluechanged="setSessionType" />
    </div>
    <div v-if="showTravelFields"
         class="stand-by-event__fields-wrapper">
      <sk-select :items-list="travelMethods"
                 :default-value="travelMethodText"
                 :description="travelMethodText"
                 :preselected-value="travelMethod"
                 :validation-name="travelMethodValidationName"
                 class="stand-by-event__field"
                 @csvaluechanged="setTravelMethod" />
      <sk-select :items-list="distances"
                 :default-value="distanceText"
                 :description="distanceText"
                 :preselected-value="distance"
                 :validation-name="distanceValidationName"
                 class="stand-by-event__field"
                 @csvaluechanged="setDistance" />
    </div>
  </section>
</template>)

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import AddEvent from '@/components/preferences/private_events/AddEvent';

  export default {
    components: {
      'add-event': AddEvent
    },
    computed: {
      ...mapGetters('TTPrivateEventsStore', [
        'showTravelFields'
      ]),
      ...mapState('TTPrivateEventsStore', {
        privateEvent: ({privateEvent}) => privateEvent,
        sessionType: ({standByPrivateEvent}) => standByPrivateEvent.sessionType || '',
        travelMethod: ({standByPrivateEvent}) => standByPrivateEvent.travelMethod || '',
        distance: ({standByPrivateEvent}) => standByPrivateEvent.distance || '',
        // validation names
        startTimeValidationName: ({errors}) => errors.startTime.name,
        sessionTypeValidationName: ({errors}) => errors.sessionType.name,
        travelMethodValidationName: ({errors}) => errors.travelMethod.name,
        distanceValidationName: ({errors}) => errors.distance.name,
        // errors array
        startTimeErrors: ({errors}) => errors.startTime.errors,
        sessionTypeErrors: ({errors}) => errors.sessionType.errors,
        travelMethodErrors: ({errors}) => errors.travelMethod.errors,
        distanceErrors: ({errors}) => errors.distance.errors
      }),
      fullStartDate() { return this.$moment(`${this.privateEvent.startDate} ${this.privateEvent.startTime}`); },
      sessionTypeText() { return this.$gettext('Assignment type(s)'); },
      travelMethodText() { return this.$gettext('Travel method'); },
      distanceText() { return this.$gettext('Distance'); },
      sessionTypes() {
        return [{
          id: 'on_site',
          name: this.$gettext('On site (in-person and video room jobs)')
        }, {
          id: 'remote',
          name: this.$gettext('Remote (phone and video jobs)')
        }];
      },
      travelMethods() {
        return [
          {id: 'default', name: helpers.getJobInfo.getTravelMethodType(this, 'default')},
          {id: 'public_transport', name: helpers.getJobInfo.getTravelMethodType(this, 'public_transport')},
          {id: 'car', name: helpers.getJobInfo.getTravelMethodType(this, 'car')},
          {id: 'flight', name: helpers.getJobInfo.getTravelMethodType(this, 'flight')}
        ];
      },
      distances() {
        return [
          {id: 30, name: this.$gettext('30 km')},
          {id: 70, name: this.$gettext('70 km')},
          {id: 100, name: this.$gettext('100 km')},
          {id: 150, name: this.$gettext('150 km')},
          {id: 200, name: this.$gettext('200 km')},
          {id: 300, name: this.$gettext('300 km')}
        ];
      }
    },
    methods: {
      ...mapMutations('TTPrivateEventsStore', [
        'setSessionType',
        'setTravelMethod',
        'setDistance'
      ])
    }
  };
</script>

<style>
  .stand-by-event .add-event__datepicker,
  .stand-by-event .add-event__timepicker {
    width: 33.3%;
    margin: 0;
    padding: 0 7.5px;
  }

  .stand-by-event .add-event__dates {
    width: calc(100% + 15px);
    margin: 0 -7.5px;
  }
</style>

<style scoped>
  .stand-by-event__fields-wrapper {
    display: flex;
    width: calc(100% + 15px);
    margin: 15px -7.5px 0;
  }

  .stand-by-event__field {
    flex-grow: 1;
    flex-basis: 50%;
    margin: 0 7.5px;
  }
</style>
