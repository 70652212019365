(<template>
  <div class="note">
    <div class="note__wrapper"
         :class="[`note__wrapper--${type}`]">
      <div class="note__text"
           :class="[`note__text--${type}`]"
           v-html="text"></div>
      <slot></slot>
    </div>
  </div>
</template>)

<script>
  export default {
    props: {
      text: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style scoped>
  .note__wrapper {
    position: relative;
    padding: 10px 10px 10px 35px;
    border-radius: 3px;
    background-color: var(--color-warning-200, #f0e8dd);
    font-size: 12px;
  }

  #app.salita-facelifted .note__wrapper {
    padding: 8px 12px 8px 32px;
    border-radius: 4px;
    line-height: 16px;
  }

  .note__wrapper--danger {
    background-color: rgba(255, 54, 36, 0.1);
  }

  .note__text::before {
    content: '';
    position: absolute;
    top: 12px;
    left: 10px;
    display: block;
    width: 15px;
    height: 15px;
    background-image: var(--image-note-info-icon);
    background-position: 50% 50%;
    background-size: 15px auto;
    background-repeat: no-repeat;
  }

  #app.salita-facelifted .note__text::before {
    top: 8px;
  }

  .note__text--danger::before {
    background-image: url(~@assets/imgs/salita/hints/application/interpreter_withdrew.svg);
    background-size: 20px;
  }

  .note__text--danger {
    color: #ff3624;
  }

  .note__wrapper--white {
    background-color: #fff;
  }
</style>
