<template>
  <article class="multiselect-tab"
           :class="{'is-active': tab.isActive}"
           @click.self="onTabClick">
    <div :class="{'sk-select--with-icon': icon,
                  'sk-select--with-tags': tags,
                  'is-with-error': (errors && errors.length),
                  'in-progress': loading}"
         class="sk-select__wrapper is-progress-bar">
      <!--  Showing avatars Multi-select instead of default behaviour-->
      <avatars-list v-if="managerAvatarList.length"
                    class="multiselect-tab__avatar-list"
                    :avatars="managerAvatarList"
                    @avatarclick="toggleSelectState"
                    @touchstart="touchStartHandler"
                    @touchend="touchHandler" />
      <div v-if="!preselectedValue.length && withAvatar"
           class="add-manager__icon avatar"
           @click="toggleSelectState"
           @touchstart="touchStartHandler"
           @touchend="touchHandler">
        <tt-avatar @avatarclick="toggleSelectState"
                   @touchstart="touchStartHandler"
                   @touchend="touchHandler" />
      </div>
      <div v-if="leftSideIcon"
           :class="[`left-side-icon--${leftSideIcon}`]"
           class="left-side-icon">
        <tt-avatar @avatarclick="toggleSelectState"
                   @touchstart="touchStartHandler"
                   @touchend="touchHandler" />
      </div>
      <button class="tab"
              @click="onTabClick">
        {{ tab.title }}
        <span v-if="count"
              class="multiselect-tab__jobs-count"
              :class="{'multiselect-tab__jobs-count--new-job': statNeedsUpdate}">{{ kFormatter(count) }}</span>
      </button>
      <div ref="customSelect"
           :class="[typeClass,
                    {'is-opened': isSelectOpened},
                    {'is-disabled': disabled}]"
           class="sk-select"
           @click="toggleSelectState"
           @touchstart="touchStartHandler"
           @touchend="touchHandler">
        <input ref="typingInput"
               v-model="typingInputValue"
               :class="{'is-not-focusable': disabled}"
               :maxlength="typeMaxlength"
               placeholder=""
               type="hidden"
               class="sk-select__typing-input"
               @keyup.40="downArrowHandler"
               @keyup.38="upArrowHandler"
               @keydown.13.stop.prevent="pressEnterOrSpaceLogic"
               @keydown.32.stop.prevent="pressEnterOrSpaceLogic"
               @keydown.9="pressTabLogic"
               @focus="focusLogic" />
        <span v-if="icon"
              :class="iconClass"
              class="sk-select__icon"></span>
        <div v-if="tags && tags.length"
             class="sk-select__preview-button-wrapper">
          <button v-for="item in tags"
                  :key="item.id"
                  type="button"
                  class="sk-select__preview-button"
                  @click.stop="catchCheckbox(false, item.id)">{{ item.name }}</button>
        </div>
        <template v-else>
          <span v-if="!isSelectOpened"
                :class="computedValueClass"
                :title="currentValue"
                class="sk-select__value"
                v-html="currentValue"></span>
        </template>
        <ul v-if="isSelectOpened"
            ref="csList"
            class="sk-select__list">
          <li v-for="item in renderedList"
              :key="makeUniqueKey(item.id)"
              :class="{'is-highlighted': item.id == currentHighlightedItemObj.id,
                       'is-chosen': preselectedValue.indexOf(item.id) != -1}"
              class="sk-select__list-item js-list-item"
              @click.stop="">
            <avatar-checkbox v-if="item.id"
                             :item-text="item.name"
                             :item-value="item.id"
                             :preselected-value="getPreselected(item.id)"
                             :avatar-path="item.avatarPath"
                             :class="{'is-marked': isMarked(item.id)}"
                             class="sk-select__checkbox"
                             @checkboxvaluechanged="catchCheckbox" />
            <span v-else>{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </article>
</template>

<script>
  import AvatarsList from '@/components/assignment_components/dashboard/manager/AvatarsList';
  import {skMultiselect} from '@skiwo/sk-basic-components';
  import AvatarCheckbox from '@/components/shared_components/AvatarCheckbox';
  import helpers from '@/helpers';

  export default {
    components: {
      'avatars-list': AvatarsList,
      'avatar-checkbox': AvatarCheckbox
    },
    extends: skMultiselect,
    props: {
      tab: {
        type: Object,
        default: () => {}
      },
      preselectedValue: {
        type: Array,
        default: () => []
      },
      count: {
        type: [Number, String],
        default: 0
      },
      withAvatar: {
        type: Boolean,
        default: false
      },
      statNeedsUpdate: {
        type: Boolean,
        default: false
      },
      leftSideIcon: {
        type: String,
        default: ''
      }
    },
    computed: {
      managerAvatarList() {
        return this.renderedList.filter((manager) => {
          return this.preselectedValue.find((selectedManager) => selectedManager === manager.id);
        });
      }
    },
    methods: {
      closeSelect(eve) {
        if (eve.target.className.indexOf('avatar') === -1) {
          this.isSelectOpened = false;
        }
      },
      onTabClick() {
        this.tab.onClick ? this.tab.onClick(this.tab.tab, this.tab.params) : '';
      },
      kFormatter(num) { return helpers.numbers.kFormatter(num); }
    }
  };
</script>

<style>
.multiselect-tab .tab {
  margin-left: 0 !important;
  padding: 20px 8px;
  font-size: 14px;
}

.multiselect-tab .sk-select__wrapper:hover {
  background: #fff4f0;
  background: var(--multiselect-tab-hover-color);
}

.multiselect-tab .sk-select__wrapper {
  min-width: max-content;
  border-radius: 8px;
}

.multiselect-tab .sk-select {
  max-width: 120px;
  height: 0;
  border: none !important;
}

.multiselect-tab .sk-select__list {
  width: auto;
  min-width: 200px;
}

@media (max-width: 767px) {
  .multiselect-tab .sk-select__wrapper {
    margin-bottom: 0;
  }
}
</style>

<style scoped>
.multiselect-tab {
  position: relative;
  margin: 0 2px;
  cursor: pointer;
}

.multiselect-tab__avatar-list {
  margin-right: 10px;
}

.add-manager__icon,
.left-side-icon {
  display: inline-block;
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  padding: 2px;
  border: 1px dashed #ff5b24;
  border: 1px dashed var(--color-primary);
  border-radius: 50%;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNSA0LjI1VjZNOS41IDZWNy43NU05LjUgNkgxMS4yNU05LjUgNkg3Ljc1TTYuNTgzMzMgMy4wODMzM0M2LjU4MzMzIDQuMzcyIDUuNTM4NjcgNS40MTY2NyA0LjI1IDUuNDE2NjdDMi45NjEzNCA1LjQxNjY3IDEuOTE2NjcgNC4zNzIgMS45MTY2NyAzLjA4MzMzQzEuOTE2NjcgMS43OTQ2NyAyLjk2MTM0IDAuNzUgNC4yNSAwLjc1QzUuNTM4NjcgMC43NSA2LjU4MzMzIDEuNzk0NjcgNi41ODMzMyAzLjA4MzMzWk0wLjc1IDEwLjY2NjdDMC43NSA4LjczMzY3IDIuMzE3IDcuMTY2NjcgNC4yNSA3LjE2NjY3QzYuMTgzIDcuMTY2NjcgNy43NSA4LjczMzY3IDcuNzUgMTAuNjY2N1YxMS4yNUgwLjc1VjEwLjY2NjdaIiBzdHJva2U9IiNGRjVCMjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
  background-image: var(--image-add-manager);
  background-position: center;
  background-size: 15px auto;
  background-repeat: no-repeat;
}

.multiselect-tab.is-active {
  border-bottom: 3px solid var(--active-tab-border-primary);
  color: var(--color-secondary);
}

.multiselect-tab__jobs-count {
  margin-left: 8px;
  padding: 5px;
  border-radius: 3px;
  background-color: #f4f5f7;
}

.multiselect-tab__jobs-count--new-job {
  background-color: #fff4f0;
  color: #ff5b24;
}

.left-side-icon--anniversary {
  border: none;
  background-image: url(~@assets/imgs/undefined_imgs/cake_icon.svg);
  background-size: 20px auto;
}
</style>
