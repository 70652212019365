export default () => {
  return {
    namespaced: true,
    state: {
      severalSuppliersSelect: false
    },
    actions: {
      changeSeveralSuppliersSelectState({commit}, value) {
        commit('setSeveralSuppliersSelectState', value);
        commit('PostingStore/ProcessRequirementStore/setSeveralSuppliersProcessing', '', {root: true});
      }
    },
    mutations: {
      setSeveralSuppliersSelectState(state, value) {
        state.severalSuppliersSelect = value;
      },
      clearStore(state) {
        state.severalSuppliersSelect = false;
      }
    }
  };
};
