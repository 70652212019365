(<template>
  <article class="call-problems">
    <p class="call-problems__text">{{ data.text }}</p>
    <button class="sk-btn sk-btn--default call-problems__btn"
            @click="$emit('closemodal')">{{ $gettext('Ok') }}</button>
  </article>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    }
  };
</script>

<style scoped>
  .call-problems {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px;
    font-weight: bold;
    font-size: 18px;
  }

  .call-problems__btn {
    display: inline-block;
    width: auto;
    margin-top: 25px;
    padding: 0 25px;
  }
</style>
