(<template>
  <post-inner-section ref="secretaryCallback"
                      class="post-form__field secretary-phone-section"
                      @focusin="openCallBackHelper">
    <sk-checkbox :item-text="secretaryCallbackPhoneCheckboxDescription"
                 :preselected-value="secretaryCallbackPhoneSelect"
                 class="secretary-phone-section__checkbox"
                 @checkboxvaluechanged="changeSecretaryCallbackPhoneSelect" />
    <div v-if="secretaryCallbackPhoneSelect"
         class="secretary-phone-section__field">
      <sk-select :type="'countryCodes'"
                 :description="secretaryCallbackPhoneCodeText"
                 :default-value="secretaryCallbackPhoneCodeText"
                 :preselected-value="secretaryCallbackPhoneCode"
                 :mob-numeric-keyboard="true"
                 class="secretary-phone-section__code-field"
                 @csvaluechanged="setSecretaryCallbackPhoneCode" />
      <sk-input :description="secretaryCallbackPhoneDescription"
                :placeholder="secretaryCallbackPhonePlaceholder"
                :preselected-value="secretaryCallbackPhone"
                :reg-exp="/[^0-9]/g"
                :mob-numeric-keyboard="true"
                :validation-name="secretaryCallbackPhoneErrorName"
                class="secretary-phone-section__input"
                @fieldvaluechanged="setSecretaryCallbackPhone" />
    </div>
  </post-inner-section>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';
  import PostInnerSection from '@/components/booking_components/PostInnerSection';

  export default {
    components: {
      'post-inner-section': PostInnerSection
    },
    props: {
      showAssignmentHelper: {
        type: Boolean,
      }
    },
    computed: {
      ...mapState('PostingStore/SecretaryStore', {
        secretaryCallbackPhoneSelect: ({secretaryCallbackPhoneSelect}) => secretaryCallbackPhoneSelect,
        secretaryCallbackPhone: ({secretaryCallbackPhone}) => secretaryCallbackPhone,
        secretaryCallbackPhoneCode: ({secretaryCallbackPhoneCode}) => secretaryCallbackPhoneCode,
        // errors
        secretaryCallbackPhoneErrorName: ({errors}) => errors.secretaryCallbackPhone.name,
      }),
      secretaryCallbackPhoneCheckboxDescription() {
        return this.$gettext('I want the interpreter to call me when finished');
      },
      secretaryCallbackPhoneDescription() {
        return this.$gettext('My phone number');
      },
      secretaryCallbackPhoneCodeText() {
        return this.$gettext('Phone code');
      },
      secretaryCallbackPhonePlaceholder() {
        return this.$gettext('Phone number');
      }
    },
    methods: {
      ...mapMutations('PostingStore/SecretaryStore', [
        'setSecretaryCallbackPhone',
        'setSecretaryCallbackPhoneSelect',
        'setSecretaryCallbackPhoneCode'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
      ]),
      openCallBackHelper() {
        if (this.showAssignmentHelper) {
          this.setPopoverReference(this.$refs.secretaryCallback);
          this.openHelper('secretaryCallbackInfo');
        }
      },
      changeSecretaryCallbackPhoneSelect(value) {
        this.setSecretaryCallbackPhoneSelect(value);
        this.setSecretaryCallbackPhone('');
        this.setSecretaryCallbackPhoneCode('+47');
      }
    }
  };
</script>

<style scoped>
  #app.salita-facelifted .secretary-phone-section {
    padding: 0;
    background-color: transparent;
  }

  .secretary-phone-section__field {
    display: flex;
    margin-top: 20px;
    margin-bottom: 0;
  }

  #app.salita-facelifted .secretary-phone-section__input {
    max-width: 300px;
  }

  .secretary-phone-section__input {
    margin-bottom: 0;
  }

  .secretary-phone-section__code-field {
    width: 90px;
    min-width: 90px;
    margin-right: 15px;
  }

</style>
