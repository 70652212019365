const discussions = {
  methods: {
    getDiscussions(jobId, entity, entityId) {
      const self = this;

      return new Promise((resolve) => {
        self.ajax.get(`v1/jobs/${jobId}/discussions`, {params: {job_entity_id: entityId, job_entity_type: entity}}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          if (error?.status == 401 || error?.status == 500 || error?.status == 404 || error?.status == 403) {
            // eslint-disable-next-line
            console.log(`Get discussions for job with id: ${jobId} responsed with ${error?.status} code`);
            resolve({code: error?.status});
          }
        });
      });
    },
    getOneDiscussion(discussionId, entity, entityId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/discussions/${discussionId}`, {params: {job_entity_id: entityId, job_entity_type: entity}}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    makeApply(id, params) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/discussions/${id}/apply`, {application: params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    makeProactiveApply(params) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/discussions/apply', {application: params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    makeSeriesApply(id, params) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/discussions/${id}/series_apply`, {applications: params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    makeApplyAsInterpreter(jobId, id, force, headers) {
      const self = this;
      const params = {
        uid: id,
        job_id: jobId,
        force_award: force
      };

      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/support/discussions/apply', params, {headers}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    makeAward(discussionId, params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/discussions/${discussionId}/award`, params)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    makeReject(params, entity, entityId) {
      const self = this;
      const data = {job_entity_id: entityId, job_entity_type: entity};
      params.reason ? data.reason = params.reason : '';

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/discussions/${params.id}/reject`, data).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    makeDecline(id, entity, entityId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/discussions/${id}/decline`, {job_entity_id: entityId, job_entity_type: entity}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    makeWithdraw(params) {
      const self = this;
      const data = {};
      params.reason ? data.reason = params.reason : '';

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/discussions/${params.id}/withdraw`, data).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    makeWithdrawAsSupplier(jobId, discussionId, params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/support/jobs/${jobId}/discussions/${discussionId}/withdraw`, params).then((response) => {
          resolve(response?.data);
        }).catch((response) => {
          reject(response?.data);
        });
      });
    },
    sendMessage(id, msg) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/discussions/${id}/message`, {message: {text: msg}}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    sendFile(id, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/discussions/${id}/message`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    sendFeedback(id, feedback) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/discussions/${id}/leave_feedback`, feedback).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    sendHide(id) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/discussions/${id}/hide`).then(() => {
          resolve();
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    changePublicFeedbackStatus(id, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`v1/jobs/discussions/public_feedbacks/${id}`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    getInterpreterFeedback(uid, params) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/jobs/discussions/public_feedbacks/${uid}`, {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
  }
};

export default discussions;
