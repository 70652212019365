// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/striped-bg.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.events-description[data-v-34cd437f] {\n  font-size: 10px;\n}\n.events-description__type[data-v-34cd437f] {\n  position: relative;\n  display: inline-block;\n  margin-right: 15px;\n}\n.events-description__text[data-v-34cd437f] {\n  margin-top: 5px;\n  font-weight: bold;\n}\n.events-description__type[data-v-34cd437f]::before {\n  content: '';\n  display: inline-block;\n  width: 20px;\n  height: 5px;\n  margin-right: 5px;\n  border: 1px solid #ccc;\n  border-radius: 3px;\n  background-color: #fff;\n}\n.events-description__type--event[data-v-34cd437f]::before {\n  border: solid 1px #fd6;\n  background-color: #ffecb0;\n}\n#app.salita-facelifted .events-description__type--event[data-v-34cd437f]::before {\n  background-color: #fffaed;\n}\n#app.salita-facelifted .events-description__type--selected[data-v-34cd437f]::before {\n  border-color: var(--color-success-700);\n  background-color: var(--color-success-100);\n}\n#app.salita-facelifted .events-description__type--job[data-v-34cd437f]::before {\n  border: 1px solid #949ab0;\n  background-color: #f4f5f7;\n}\n.events-description__type--selected[data-v-34cd437f]::before,\n.events-description__type--standby[data-v-34cd437f]::before {\n  border: solid 1px #ff5b23;\n  background-color: rgba(255, 91, 35, 0.2);\n}\n.events-description__type--job[data-v-34cd437f]::before {\n  border: 1px solid #3f43ad;\n  background-color: #3f43ad;\n}\n.events-description__type--offtime[data-v-34cd437f]::before {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: auto 40px;\n}\n.events-description__type--travelTime[data-v-34cd437f]::before {\n  background-color: #e5e5e5;\n}\n", ""]);
// Exports
module.exports = exports;
