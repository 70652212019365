(<template>
  <article
    class="notes__main-wrapper">
    <basic-person-info class="notes__basic-info" />
    <section :class="{'in-progress': notesProgress}"
             class="notes-view_section is-progress-bar">
      <!----- View Notes ------->
      <template v-if="view === 'list'">
        <button class="sk-btn sk-btn--white notes__add-button"
                @click="setView('add')">{{ $gettext('Add note') }}</button>
        <note v-for="singleNote in notesList"
              :key="singleNote.id"
              class="notes__single-note"
              :note="singleNote"
              @editnote="editNote" />
      </template>
      <!----- Add and View Notes ------->
      <template v-else-if="view === 'add' || view === 'edit'">
        <sk-textarea :description="noteText"
                     :preselected-value="note"
                     :placeholder="notePlaceholder"
                     :validation-name="noteErrorsName"
                     class="note__textarea"
                     @fieldvaluechanged="catchNote" />
        <sk-input v-if="view === 'edit'"
                  :description="authorText"
                  :placeholder="authorText"
                  :disabled="true"
                  :preselected-value="author"
                  class="feedback__field assignment-section__input"
                  @fieldvaluechanged="catchAuthor" />
      </template>
      <!----- Notes Actions ------->
      <section class="notes__btns-wrapper">
        <button v-if="view === 'list'"
                class="sk-btn sk-btn--default notes__btn"
                @click="closeModal">{{ $gettext('Ok') }}</button>
        <template v-else-if="view === 'add' || view === 'edit'">
          <button class="sk-btn sk-btn--white notes__btn"
                  @click="closeModal">{{ $gettext('Cancel') }}</button>
          <button class="sk-btn sk-btn--default notes__btn"
                  @click="validateForm">{{ $gettext('Save') }}</button>
        </template>
      </section>
    </section>
  </article>
</template>)

<script>
  import BasicPersonInfo from '@/components/interpretation_skills/BasicPersonInfo';
  import {mapState} from 'vuex';
  import Note from '@/components/interpretation_skills/notes/Note';

  export default {
    components: {
      note: Note,
      'basic-person-info': BasicPersonInfo,
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        id: '',
        note: '',
        author: '',
        view: 'list',
        noteErrorsName: '',
        noteErrors: [],
        notesProgress: false
      };
    },
    computed: {
      ...mapState('UserNotesStore', {
        currentUserUid: (state) => state.currentUserUid,
        notesList: (state) => state.notes || [],
      }),
      noteText() { return this.$gettext('Note'); },
      notePlaceholder() { return this.$gettext('Your note...'); },
      authorText() { return this.$gettext('Author'); }
    },
    methods: {
      setView(view) {
        this.view = view;
      },
      editNote(note) {
        this.note = note.note;
        this.author = note.author?.name;
        this.id = note.id;
        this.view = 'edit';
      },
      validateForm() {
        let isValidForm = true;

        this.noteErrors = [];
        this.$store.commit('ModalStore/removeModalErrors');

        if ((this.view === 'add' || this.view === 'view') && !this.note.trim()) {
          isValidForm = false;
          this.noteErrors.push(this.$gettext('Note is required'));
        }

        this.$store.commit('ErrorsStore/setError', {name: this.noteErrorsName, errors: this.noteErrors});

        if (isValidForm) {
          this.submitForm();
        }
      },
      submitForm() {
        const params = {
          note: this.note
        };
        const action = this.view === 'edit' ? 'UserNotesStore/editUserNote' : 'UserNotesStore/addUserNote';
        const payload = {uid: this.currentUserUid, params};
        if (this.view === 'edit') payload.noteId = this.id;
        this.notesProgress = true;
        this.$store.dispatch(action, payload)
          .then(() => {
            this.notesProgress = false;
            this.closeModal();
            if (this.data.context && this.data.context[this.data.successHandlingCallbackName]) {
              this.data.context[this.data.successHandlingCallbackName]();
            }
          }).catch(() => {
            this.notesProgress = false;
          });
      },
      getUserNotes() {
        this.notesProgress = true;
        this.$store.dispatch('UserNotesStore/fetchUserNotes', {uid: this.currentUserUid}).then(() => {
          this.notesProgress = false;
        }).catch(() => { this.notesProgress = false; });
      },
      closeModal() {
        this.$emit('closemodal');
      },
      clearErrors() {
        this.$store.commit('ModalStore/removeModalErrors');
        this.$store.commit('ErrorsStore/setError', {name: this.noteErrorsName, errors: []});
      },
      catchAuthor(value) {
        this.author = value;
      },
      catchNote(value) {
        this.note = value;
      }
    },
    mounted() {
      this.getUserNotes();
    },
    beforeDestroy() {
      this.clearErrors();
      this.$store.commit('UserNotesStore/cleanStore');
    }
  };
</script>
<style scoped>
.notes__main-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 10px 30px 30px;
}

.notes-view_section,
.notes__basic-info {
  width: 100%;
}

.notes__add-button {
  height: 48px;
  margin-bottom: 16px;
  padding-right: 15px;
  border: 1px dashed rgba(255, 91, 36, 0.2) !important;
  border-radius: 8px;
  background-color: #fff4f0 !important;
  background-image: url(~@assets/imgs/undefined_imgs/plus_icon.svg);
  background-position: 40% 50%;
  background-size: 22px auto;
  background-repeat: no-repeat;
}

.notes__single-note:not(:last-child) {
  margin-bottom: 16px;
}

.note__textarea {
  height: 100px;
}

.notes__btns-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}

.notes__btn {
  width: auto;
  margin: 20px 5px 0;
  padding: 0 20px;
}

</style>
