export default (gettext, env, obj, string) => {
  const marketplaceStrings = {
    salita: {
      domainData: {
        platformName: 'Salita',
        platformFullName: gettext('Salita AS'),
        platformOrgNumber: '918 586 296',
        supportEmail: 'post@salita.no',
        subSupportEmail: 'post@salita.no',
        supportFinanceEmail: 'regnskap@salita.no',
        supportFinancePersonEmail: 'regnskap@salita.no',
        supportInterpreterEmail: 'regnskap@salita.no',
        interpreterSupportPhone: '22120119',
        supportPhone: '04523',
        supportPhoneText: '04523',
        supportStaffingPhone: '23961033',
        supportStaffingEmail: 'bemanning@salita.no',
        supportTranslationPhone: '+4723961032',
        supportTranslationEmail: 'oversettelse@salita.no',
        infoPortalLink: 'https://www.salita.no/',
        infoPortalText: 'salita.no',
        supportPortalLink: 'https://support.salita.no/',
        supportPortalText: 'support.salita.no',
        platformLink: 'https://my.salita.no',
        platformText: 'my.salita.no',
        termLink: 'https://salita.no/terms',
        privacyLink: 'https://salita.no/privacy',
        financeGuideLink: 'https://support.salita.no/article/368-retningslinjer-for-fakturering/',
        ssoRedirectLink: 'http://localhost:3001/v1/accounts/oauth/boxyhqsaml?tenant=827&product=tt-core',
      },
      metaData: {
        faviconPath: '/static/favicons_salita',
        adminFaviconPath: '/static/favicons_salita/admin',
        shortTitle: gettext('Salita'),
        longTitle: gettext('Salita - The new standard in interpretation'),
        adminTitle: gettext('Salita Admin'),
        description: gettext('Salita is an open and transparent marketplace for interpretation services. We connect interpreters with customers worldwide. Register for free today!')
      },
      rootPath: {
        development: 'localhost:4000',
        testing: 'qa.my.salita.no',
        backend: 'rc.my.salita.no',
        staging: 'rc.my.salita.no',
        production: 'my.salita.no'
      },
      social: {
        facebookLink: 'https://www.facebook.com/salitatt',
        twitterLink: 'https://twitter.com/SalitaTT',
        linkedinLink: 'https://www.linkedin.com/company/salita-interpreting-and-translation-services/about/',
        hashtag: gettext('Salita'),
        shareMessage: gettext('Take a look at Salita, an open marketplace for interpretation services. Anyone can join and help!')
      },
      app: {
        androidAppLink: process.env.ANDROID_APP_LINK,
        iosAppLink: process.env.IOS_APP_LINK,
      },
      footer: {
        copyright: gettext('© Salita AS'),
        address: gettext('Pilestredet 17, Oslo - 0164, Norway'),
        certificate: '',
      }
    }
  };
  if (!string || !obj || !marketplaceStrings[env][obj]) return '';
  return marketplaceStrings[env][obj][string] || '';
};
