(<template>
  <section :class="{'in-progress': modalProgress}"
           class="merge-customer__main-wrapper is-progress-bar">
    <basic-customer-info class="merge-customer__basic-info" />
    <div class="merge-customer__hint-wrapper">
      <h3>{{ $gettext('Duplicate accounts') }}</h3>
      <p>{{ $gettext('These customers will be deleted and all the jobs will be moved to correct one.') }}</p>
    </div>
    <customer-multiselect-search :items-list="computedCustomersList"
                                 :preselected-value="selectedCustomersIds"
                                 :description="customersText"
                                 :dynamic-search="true"
                                 :default-value="customersText"
                                 :tags="selectedCustomerFullList"
                                 :validation-name="duplicateCustomersErrorsName"
                                 class="merge-customer__customers"
                                 @onsuccess="handleSuccess"
                                 @onresetfilter="resetFilter"
                                 @checkboxeschanged="catchCustomerSelect" />
    <sk-note class="merge-customer__warning"
             type="danger"
             :text="mergeWarningText" />
    <section class="merge-customer__btns-wrapper">
      <button class="sk-btn sk-btn--white merge-customer__btn"
              @click="closeModal">{{ $gettext('Cancel') }}</button>
      <button class="sk-btn sk-btn--default merge-customer__btn"
              @click="validateForm">{{ $gettext('Merge') }}</button>
    </section>
  </section>
</template>)

<script>
  import BasicCustomerInfo from '@/components/customers/modals/BasicCustomerInfo';
  import {mapMutations, mapState} from 'vuex';
  import CustomerMultiSelectSearch from '@/components/customers/modals/merge/CustomerMultiSelectSearch';

  export default {
    components: {
      'basic-customer-info': BasicCustomerInfo,
      'customer-multiselect-search': CustomerMultiSelectSearch
    },
    data() {
      return {
        customer: '',
        selectedCustomersIds: [],
        selectedCustomerFullList: [],
        modalProgress: false,
        duplicateCustomersErrorsName: '',
        duplicateCustomersErrors: [],
      };
    },
    computed: {
      ...mapState('AllCustomersStore', {
        selectedCustomer: (state) => state.selectedCustomer || {},
        searchCustomerList: ({searchCustomerList}) => searchCustomerList || [],
      }),
      customersText() { return this.$gettext('Customers'); },
      mergeWarningText() {
        const temp = this.$gettext('This will move all jobs to <b>%{email}</b> and delete other accounts. You can\'t undo this action.');
        return this.$gettextInterpolate(temp, {email: this.selectedCustomer.email});
      },
      computedCustomersList() {
        const filteredCustomersList = this.searchCustomerList.filter((customer) => customer.uid !== this.selectedCustomer.uid) || [];
        return filteredCustomersList.map((customer) => {
          return {
            id: customer.uid,
            name: this.customerNameEmail(customer)
          };
        });
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      ...mapMutations('AllCustomersStore', [
        'setSearchCustomersList'
      ]),
      validateForm() {
        let isValidForm = true;

        this.duplicateCustomersErrors = [];
        this.$store.commit('ModalStore/removeModalErrors');

        if (this.selectedCustomersIds && !this.selectedCustomersIds.length > 0) {
          isValidForm = false;
          this.duplicateCustomersErrors.push(this.$gettext('At least one duplicate customer account is required.'));
        }

        this.$store.commit('ErrorsStore/setError', {name: this.duplicateCustomersErrorsName, errors: this.duplicateCustomersErrors});

        if (isValidForm) {
          this.submitForm();
        }
      },
      submitForm() {
        const params = {
          correct_person_uid: this.selectedCustomer.uid,
          duplicate_person_uids: this.selectedCustomersIds
        };
        this.modalProgress = true;
        this.$store.dispatch('AllCustomersStore/mergeDuplicateCustomers', params)
          .then(() => {
            this.modalProgress = false;
            this.closeModal();
            this.$store.commit('AllCustomersStore/refreshCustomerGrid');
            this.showToast(this.$gettext('Customer merging is in progress'));
          }).catch((error) => {
            this.modalProgress = false;
            let message = this.$gettext('Something went wrong please try again');
            if (error?.data?.errors[1].includes('Exclude correct person from list of perople to merge')) message = this.$gettext('Can\'t merge to the same customers');
            if (error?.data?.errors[1].includes('Only people with same roles could be merged')) message = this.$gettext('Can\'t merge customers with different roles');
            this.showToast(message);
          });
      },
      showToast(message) {
        this.$store.commit('ToastStore/setToast', {
          data: {
            position: 'bottom-center',
            message: message,
            autoDeleteTime: 6000
          }
        });
      },
      resetFilter() {
        this.setSearchCustomersList([]);
      },
      handleSuccess() {
        if (!this.searchCustomerList.length) {
          this.setSearchCustomersList([{name: 'Not found', uid: ''}]);
        }
      },
      catchCustomerSelect(data = []) {
        if (data.length < this.selectedCustomersIds.length) {
          this.selectedCustomerFullList = this.selectedCustomerFullList.filter((item) => data.indexOf(item.id) !== -1);
        } else {
          this.computedCustomersList.forEach((item) => {
            if (data.indexOf(item.id) !== -1 && !this.selectedCustomerFullList.find((customer) => customer.id === item.id)) {
              this.selectedCustomerFullList.push(item);
            }
          });
        }

        this.selectedCustomersIds = data;
      },
      customerNameEmail(customer) {
        const res = customer.name;
        if (customer.email) return res + ` (${customer.email})`;
        return res;
      },
      closeModal() {
        this.$emit('closemodal');
      },
      clearErrors() {
        this.$store.commit('ModalStore/removeModalErrors');
        this.$store.commit('ErrorsStore/setError', {name: this.duplicateCustomersErrorsName, errors: []});
      }
    },
    beforeDestroy() {
      this.clearErrors();
      this.$store.commit('AllCustomersStore/clearSelectedCustomer');
    }
  };
</script>
<style scoped>
.merge-customer__main-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 35px 30px;
}

.merge-customer__basic-info {
  width: 100%;
}

.merge-customer__hint-wrapper {
  margin-bottom: 15px;
}

.merge-customer__customers,
.merge-customer__warning {
  width: 100%;
}

.merge-customer__warning {
  margin-top: 20px;
}

.merge-customer__btns-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}

.merge-customer__btn {
  width: auto;
  margin: 20px 5px 0;
  padding: 0 20px;
}

</style>
