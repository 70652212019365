export default {
  path: 'assignments',
  component: () => import('@/pages/shared_pages/assignment_pages/sh_AssignmentsRoot'),
  children: [
    {
      path: '/',
      redirect: {name: 'ServerAllAssignments'}
    },
    {
      path: 'all',
      name: 'ServerAllAssignments',
      component: () => import('@/pages/shared_pages/assignment_pages/sh_AllAssignments')
    },
    {
      path: 'all/:id',
      name: 'ServerOneAssignment',
      meta: {subPage: true, viewers: true},
      component: () => import('@/pages/shared_pages/assignment_pages/sh_OneInterpretation')
    }
  ]
};
