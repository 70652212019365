(<template>
  <div class="extra-info__wrapper">
    <h3 class="extra-info_title">{{ descriptionTitleText }}</h3>
    <div class="post-form__field extra-info">
      <p class="extra-info__text">{{ extraInfoText }}</p>
      <div v-if="notLatinLang"
           class="extra-info__non-latin">
        <accordion ref="assignmentAccordion"
                   class="assignment-accordion">
          <div slot="header"
               class="assignment-accordion__header translation-info">
            <p class="translation-info__title">{{ translationTitleText }}</p>
            <p class="translation-info__subtitle">{{ translationSubtitleText }}</p>
          </div>
          <template slot="content">
            <p v-for="(item, index) in translationMainText"
               :key="index"
               class="translation-info__main-text">{{ item }}</p>
          </template>
        </accordion>
        <sk-checkbox :preselected-value="acceptedNonLatinLang"
                     :item-text="acceptedNonLatinLangText"
                     :validation-name="errorNonLatinName"
                     class="apply-conditions-check__checkbox"
                     @checkboxvaluechanged="catchAcceptedNonLatinLang" />
        <p v-if="showNonLatinError"
           class="validate-message-hint">{{ errorNonLatinText }}</p>
      </div>
      <sk-textarea
        ref="translationDescription"
        :preselected-value="extraInfo"
        :placeholder="extraInfoPlaceholder"
        class="extra-info__textarea"
        :maxlength="charLimit"
        @focus="openDescriptionHelper"
        @fieldvaluechanged="setExtraInfo" />
      <p class="extra-info__character-limit"
         v-html="charMessage"></p>
      <p v-if="showExtraInfoNote"
         class="extra-info__note">{{ noteText }}</p>
    </div>
    <div v-if="showPriceQuote"
         class="price-check__devider"></div>
    <sk-checkbox v-if="showPriceQuote"
                 :item-text="priceQouteText"
                 :preselected-value="priceQoute"
                 class="price-quote__checkbox"
                 @checkboxvaluechanged="setPriceQoute" />
    <sk-note :text="infoWarning"
             class="extra-info-warning" />
  </div>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';
  import Accordion from '@/components/shared_components/Accordion';

  export default {
    components: {
      accordion: Accordion,
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        acceptedNonLatinLang: false
      };
    },
    computed: {
      ...mapState('PostingStore/LanguagesStore', {
        langFrom: (state) => state.languageFrom || '',
        langsTo: (state) => state.targetLanguages || '',
        noSourceLang: (state) => state.noSourceLang || '',
        nonLatinLanguage: (state) => state.nonLatinLanguage || '',
        errorNonLatinName: (state) => state.errors.nonLatinLang.name || '',
        showNonLatinError: (state) => state.showNotLatinError || ''
      }),
      ...mapState('PostingStore/ExtraInfoStore', {
        extraInfo: (state) => state.extraInfo,
        priceQoute: (state) => state.priceQoute,
      }),
      ...mapState('OneProjectStore', {
        project: (state) => state.project
      }),
      ...mapState('OpenBookingStore', {
        emailExists: (state) => state.emailExisting,
        emailWhitelisted: (state) => state.emailWhitelisted,
        isExistingCustomer: ({openBooking}) => openBooking.isExistingCustomer,
      }),
      paramsList() { return this.params || {}; },
      extraInfoText() { return this.paramsList.extraInfoText || this.$gettext('Message for the interpreter'); },
      showExtraInfoNote() { return this.paramsList.showExtraInfoNote || false; },
      isLoggedIn() { return this.$store.state.TTAuthStore.loggedIn; },
      extraInfoPlaceholder() { return this.$gettext('Write here...'); },
      priceQouteText() { return this.$gettext('I would like a price quote before the project starts'); },
      showPriceQuote() {
        if (!this.project && !this.emailExists && !this.emailWhitelisted && !this.isLoggedIn && !this.isExistingCustomer) {
          return true;
        }
        this.setPriceQoute(false);
        return false;
      },
      noteText() {
        return this.$gettext('Details for the interpreter so they can prepare. No personal or sensitive details.');
      },
      descriptionTitleText() { return this.$gettext('Assignment Description'); },
      remainingChars() { return this.charLimit - this.extraInfo.length; },
      charLimit() { return 200; },
      charMessage() {
        const remChars = this.remainingChars;
        const charLimit = this.charLimit;
        const template = this.$gettext('Maximum <strong>%{charLimit}</strong> characters ( <strong> %{remChars} </strong> remaining )');
        return this.$gettextInterpolate(template, {remChars, charLimit});
      },
      notLatinLang() {
        const languages = this.$store.state.selectsData.language;
        let usesNonLatinAlphabet = false;

        if (this.langFrom && !this.noSourceLang) {
          const langFromData = languages.find((language) => language.id === parseInt(this.langFrom));
          this.setUsesLatinAlphabet(langFromData.uses_non_latin_alphabet);
          usesNonLatinAlphabet = langFromData.uses_non_latin_alphabet;
        }

        if (this.langsTo.length > 0 && this.noSourceLang) {
          usesNonLatinAlphabet = this.langsTo.some((lang) => {
            const langData = languages.find((language) => language.id === parseInt(lang));
            this.setUsesLatinAlphabet(langData.uses_non_latin_alphabet);
            return langData.uses_non_latin_alphabet;
          });
        }

        return usesNonLatinAlphabet;
      },
      infoWarning() {
        const template = this.$gettext('Please get in touch at <a class="sk-link sk-link--gray-bg" href="mailto:%{email}">%{email}</a> if you want a specific translator or have any other questions.');
        return this.$gettextInterpolate(template, {email: 'oversettelse@salita.no'});
      },
      translationTitleText() {
        return this.$gettext('Translation of names from languages not written in the Latin alphabet');
      },
      translationSubtitleText() {
        return this.$gettext('This information is very important. Please read it carefully prior to ordering your translation.');
      },
      translationMainText() {
        return [
          this.$gettext('Certain languages have different letters / fonts than languages written with Latin letters.'),
          this.$gettext('This applies to e.g. Ukrainian, Russian, Arabic, Farsi, and Urdu. To ensure an accurate translation, it is crucial that you as a customer provide us with the correct spelling of names in Latin letters before we start the translation process.'),
          this.$gettext('Without this information, the translator may have to guess the correct spelling, which can lead to delays and frustration. Please provide us with the full name of the document owner in Latin letters below.'),
        ];
      },
      acceptedNonLatinLangText() {
        return this.$gettext('Please confirm that you have read and understood these guidelines');
      },
      errorNonLatinText() {
        return this.$gettext('Please confirm that you have read and understood these guidelines');
      },
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    methods: {
      ...mapMutations('PostingStore/ExtraInfoStore', [
        'setExtraInfo',
        'setPriceQoute'
      ]),
      ...mapMutations('PostingStore/LanguagesStore', [
        'setNotLatinError',
        'setUsesLatinAlphabet'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setContext',
        'setPopoverReference',
      ]),
      openDescriptionHelper() {
        if (this.showAssignmentHelper) {
          this.setContext('translation');
          this.setPopoverReference(this.$refs.translationDescription);
          this.openHelper('description');
        }
      },
      catchAcceptedNonLatinLang(value) {
        if (value) {
          this.setUsesLatinAlphabet(false);
          this.setNotLatinError(false);
        } else {
          this.setUsesLatinAlphabet(true);
        }
      }
    }
  };
</script>

<style>
  .accordion__toggle::before,
  .accordion__toggle::after {
    background-color: black !important;
  }
</style>

<style scoped>
.extra-info__textarea {
  height: 200px;
}

.price-quote__checkbox {
  margin-top: 20px;
}

.price-check__devider {
  height: 1px;
  margin: 20px 0;
  background-color: black;
  opacity: 0.1;
}

.extra-info__wrapper {
  width: 100%;
  margin: 20px 15px;
  border-top: 1px solid #d3d5de;
}

#app.salita-facelifted .extra-info__wrapper {
  margin: 20px 0;
  border: 0;
}

.extra-info__text {
  margin-bottom: 15px;
  font-size: 13px;
}

.post-form__field {
  margin: 0;
}

.extra-info__character-limit {
  margin-top: 3px;
  margin-left: 5px;
}

.extra-info_title {
  margin: 13px 0;
}

.extra-info__non-latin {
  margin: 20px 0;
}

.assignment-accordion {
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-radius: 10px;
  background-color: var(--color-warning-200, #f0e8dd);
  color: #000;
  font-size: 13px;
}

.assignment-accordion__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 55px;
  padding: 8px 15px 0;
}

.validate-message-hint {
  margin: 10px 0;
  color: #f04;
}

.translation-info__title {
  padding-right: 15px;
  font-weight: bold;
}

.translation-info__main-text {
  margin-top: 4px;
  padding: 0 15px;
}

.extra-info-warning {
  margin-top: 20px;
}
</style>
