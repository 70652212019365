export default {
  path: '/:lang/:country/auth',
  meta: {guestOnly: true},
  component: () => import('@/pages/shared_pages/auth_pages/AuthRoot'),
  children: [
    {
      path: '/',
      redirect: {name: 'SignIn'}
    },
    {
      path: 'sign_in',
      name: 'SignIn',
      component: () => import('@/pages/shared_pages/auth_pages/SignIn')
    },
    {
      path: 'sign_up',
      name: 'SignUp',
      component: () => import('@/pages/shared_pages/auth_pages/SignUp')
    },
    {
      path: 'restore_password',
      name: 'RestorePassword',
      component: () => import('@/pages/shared_pages/auth_pages/ForgotPassword')
    },
    {
      path: 'new_password',
      name: 'NewPassword',
      component: () => import('@/pages/shared_pages/auth_pages/NewPassword')
    },
    {
      path: 'app_android',
      name: 'DownloadApk',
      component: () => import('@/pages/shared_pages/sh_DownloadApk')
    }
  ]
};
