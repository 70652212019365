(<template>
  <section class="unsuitable-modal__content">
    <p class="unsuitable-modal__text">{{ message }}</p>
    <button class="sk-btn sk-btn--default unsuitable-modal__btn"
            type="button"
            @click="goToDashboard">{{ $gettext("View dashboard") }}</button>
  </section>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      message() {
        if (this.data.location == 'project') return this.$pgettext('translation', 'If you are seeing this message, the assignment is unfortunately no long available. Please view your dashboard to see if there are any available assignments.');
        else return this.$gettext('If you are seeing this message, the assignment is unfortunately no long available. Please view your dashboard to see if there are any available assignments.');
      }
    },
    methods: {
      goToDashboard() {
        this.closeModal();
        if (this.data.location == 'project') this.$router.push({name: 'ServerAllProjects'});
        else this.$router.push({name: 'ServerHome'});
      },
      closeModal() {
        this.$emit('closemodal');
      }
    }
  };
</script>

<style>
  .unsuitable-modal .sk-modal__wrapper .sk-modal__title {
    padding-top: 15px;
  }
</style>

<style scoped>
  .unsuitable-modal__content {
    padding: 20px 30px 25px;
  }

  .unsuitable-modal__text {
    padding-bottom: 30px;
    font-size: 12px;
    line-height: 1.67;
    text-align: center;
  }

  .unsuitable-modal__btn {
    max-width: 183px;
    margin: 0 auto;
  }
</style>
