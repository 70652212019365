export default () => {
  return {
    namespaced: true,
    state: {translationJob: ''},
    mutations: {
      setTranslationJobInfo(state, data) {
        state.translationJob = data;
      },
      clearStore(state) {
        state.translationJob = '';
      }
    }
  };
};
