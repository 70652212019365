export default {
  path: 'customers',
  component: () => import('@/pages/ManagerPages/customer_pages/m_CustomersRoot'),
  children: [
    {
      path: '/',
      redirect: {name: 'ManagerAllCustomers'}
    },
    {
      path: 'all',
      name: 'ManagerAllCustomers',
      meta: {subPage: true},
      component: () => import('@/pages/ManagerPages/customer_pages/m_AllCustomers')
    }
  ]
};
