(<template>
  <article class="rejection-reason">
    <p class="rejection-reason__description">{{ modalDescriptionText }}</p>
    <div>
      <sk-datepicker :preselected-value="newDeadline"
                     :full-calendar="allowPastBooking"
                     :default-value="datePickerDefaultValue"
                     :prevent-call="true"
                     :icon="true"
                     :description="deadlineText"
                     class="rejection-reason__date-picker"
                     @changeday="catchDeadline" />
    </div>
    <div class="rejection-reason__actions">
      <button class="sk-btn--white rejection-reason__cancel"
              @click="closeModal">{{ $gettext('Cancel') }}</button>
      <button class="sk-btn--default rejection-reason__reject"
              :class="{'is-disabled': !newDeadline}"
              @click="saveDeadline">{{ $gettext('Save proposal') }}</button>
    </div>
  </article>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        newDeadline: ''
      };
    },
    computed: {
      allowPastBooking() { return this.$isGodmode(); },
      deadlineText() { return this.$pgettext('translation', 'Deadline'); },
      modalDescriptionText() { return this.$gettext('Change your deadline proposal while it hasn\'t been reviewed.'); },
      datePickerDefaultValue() { return this.$gettext('Select deadline'); },
    },
    methods: {
      catchDeadline(value) {
        this.newDeadline = value;
      },
      closeModal() {
        this.$store.dispatch('ModalStore/closeModal');
      },
      saveDeadline() {
        if (this.newDeadline) {
          this.data.context.requestNewDeadline(this.newDeadline);
          this.closeModal();
        }
      }
    }
  };
</script>

<style scoped>
.rejection-reason__date-picker {
  margin: 16px 0 32px 0;
  margin-top: 16px;
}
</style>
