export default () => {
  return {
    namespaced: true,
    state: {booker: ''},
    getters: {
      bookerData(state, getters, rootState) {
        const bookerList = rootState.EnterpriseStore.employees || [];
        return bookerList.find((employee) => employee.uid === state.booker);
      }
    },
    actions: {
      getEmployees({dispatch}) {
        return new Promise((resolve, reject) => {
          dispatch('EnterpriseStore/getEmployees', null, {root: true}).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      changeBooker({commit, dispatch}, {employeeId, headers = {}} = {}) {
        commit('setBooker', employeeId);
        dispatch('PostingNotifierStore/bookerChanged', {headers}, {root: true});
      }
    },
    mutations: {
      setBooker(state, value) {
        state.booker = value;
      },
      clearStore(state) {
        state.booker = '';
      }
    }
  };
};
