(<template>
  <div class="accordion">
    <div :class="[{'accordion__header--opened': opened}]"
         class="accordion__header"
         @click="toggleState">
      <button :class="[toggleClasses]"
              class="accordion__toggle"
              type="button"></button>
      <slot name="header"></slot>
    </div>
    <slot v-if="opened"
          name="content"></slot>
  </div>
</template>)

<script>
  export default {
    props: {
      initialOpenedState: Boolean,
      toggleClasses: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        opened: this.initialOpenedState || false
      };
    },
    watch: {
      initialOpenedState(newVal) {
        this.toggleState(newVal);
      }
    },
    methods: {
      changeState(state) { this.opened = state; },
      toggleState() {
        const opened = !this.opened;

        this.changeState(opened);
        this.$emit('statechanged', opened);
      }
    }
  };
</script>

<style scoped>
  .accordion__header {
    position: relative;
  }

  .accordion__toggle {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 30px;
    height: 30px;
    transform: translateY(-50%);
  }

  .accordion__toggle::before,
  .accordion__toggle::after {
    content: '';
    position: absolute;
    display: block;
    width: 1.8px;
    height: 10px;
    margin-left: 50%;
    background-color: #fff;
  }

  .accordion__toggle::before {
    left: -1.5px;
    transition: all 0.25s ease-in-out;
    transform: translate(-100%, -50%) rotate(45deg);
  }

  .accordion__toggle::after {
    left: 1.5px;
    transition: all 0.25s ease-in-out;
    transform: translate(100%, -50%) rotate(-45deg);
  }

  .accordion__toggle:focus {
    box-shadow: var(--default-focus-shadow);
    outline: none;
  }

  .accordion__header--opened .accordion__toggle::before {
    left: 1.5px;
    transform: translate(100%, -50%) rotate(45deg);
  }

  .accordion__header--opened .accordion__toggle::after {
    left: -1.5px;
    transform: translate(-100%, -50%) rotate(-45deg);
  }

  .accordion__header:hover {
    cursor: pointer;
  }
</style>
