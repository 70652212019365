<script>
  import {mapGetters, mapState} from 'vuex';
  import PostingValidation from './PostingValidation';

  export default {
    extends: PostingValidation,
    computed: {
      ...mapState('FullAddressStore', [
        'line1',
        'line2',
        'county',
        'postcode',
        'city',
        'country'
      ]),
      ...mapState('EditProfileStore', {
        directInterpreterName: ({profileData}) => {
          return profileData?.person ? `${profileData.person.firstName || ''} ${profileData.person.lastName || ''}` : '';
        }
      }),
      ...mapGetters({
        paymentMethods: 'PostingStore/PaymentMethodStore/currentPaymentMethods',
        defaultPaymentMethodData: 'PostingStore/PaymentMethodStore/defaultPaymentMethodData',
        actualCreatedAt: 'PostingStore/ReplacementStore/actualCreatedAt',
        standBy: 'PostingStore/StandByStore/standByValue',
        attachments: 'PostingStore/AttachmentsStore/attachments',
        isAddressSelectedFromList: 'PostingStore/AssignmentTypeStore/isAddressSelectedFromList'
      }),
      ...mapState('PostInterpretationStore', {
        shouldBeDisabledImportantFields: ({disabledImportantFields}) => disabledImportantFields
      }),
      ...mapState('PostingStore', {
        // personal info
        booker: ({BookerStore}) => BookerStore.booker,
        ownerRealName: ({OwnerRealNameStore}) => OwnerRealNameStore.ownerRealName,
        department: ({DepartmentAttendersStore}) => DepartmentAttendersStore.department,
        departmentsList: ({DepartmentAttendersStore}) => DepartmentAttendersStore.departmentsList,
        attendersList: ({DepartmentAttendersStore}) => DepartmentAttendersStore.attendersList,
        attenders: ({DepartmentAttendersStore}) => DepartmentAttendersStore.attenders,
        // assignment info
        languageFrom: ({LanguagesStore}) => LanguagesStore.languageFrom,
        languageTo: ({LanguagesStore}) => LanguagesStore.languageTo,
        alternativeLanguageTo: ({LanguagesStore}) => LanguagesStore.alternativeLanguageTo,
        dialect: ({DialectStore}) => DialectStore.dialect,
        subject: ({SubjectStore}) => SubjectStore.subject,
        category: ({SubjectStore}) => SubjectStore.category,
        categoriesList: ({SubjectStore}) => SubjectStore.categoriesList,
        deadline: ({DeadlineStore}) => DeadlineStore.deadline,
        fullStartTime: ({DateTimeStore}) => DateTimeStore.startTime,
        fullFinishTime: ({DateTimeStore}) => DateTimeStore.finishTime,
        assignmentType: ({AssignmentTypeStore}) => AssignmentTypeStore.assignmentType,
        allowedAssignmentTypes: ({AssignmentTypeStore}) => AssignmentTypeStore.allowedAssignmentTypes,
        blockedSuppliers: ({BlockedSuppliersStore}) => BlockedSuppliersStore.blockedSuppliersFullList,
        blockedCities: ({BlockedCitiesStore}) => BlockedCitiesStore.blockedCitiesFullList,
        dateTimes: ({MultipleDateTimeStore}) => MultipleDateTimeStore.dateTimes,
        directBookingSelected: ({DirectBookingStore}) => DirectBookingStore.directBookingSelected,
        selectedSupplier: ({DirectBookingStore}) => DirectBookingStore.selectedSupplier,
        hasOverlappedEvents: ({DirectBookingStore}) => DirectBookingStore.hasOverlappedEvents,
        contactPersonName: ({AssignmentTypeStore}) => AssignmentTypeStore.contactAttributes.person.name,
        contactPersonPhone: ({AssignmentTypeStore}) => AssignmentTypeStore.contactAttributes.person.phone,
        contactAddressChosenID: ({AssignmentTypeStore}) => AssignmentTypeStore.contactAttributes.address.id,
        contactWayDescription: ({AssignmentTypeStore}) => AssignmentTypeStore.contactAttributes.address.extra,
        savedContactAddresses: ({AssignmentTypeStore}) => AssignmentTypeStore.contactAddresses,
        videoPlatformsList: ({AssignmentTypeStore}) => AssignmentTypeStore.videoPlatformsList,
        videoPlatformType: ({AssignmentTypeStore}) => AssignmentTypeStore.videoPlatform.type,
        videoPlatformId: ({AssignmentTypeStore}) => AssignmentTypeStore.videoPlatform.id,
        videoPlatformInstructions: ({AssignmentTypeStore}) => AssignmentTypeStore.videoPlatform.instructions,
        videoPlatformUrl: ({AssignmentTypeStore}) => AssignmentTypeStore.videoPlatform.url,
        videoPlatformConfirmationValue: ({AssignmentTypeStore}) => AssignmentTypeStore.videoPlatform.confirmationValue,
        sex: ({GenderStore}) => GenderStore.sex,
        severalSuppliers: ({SeveralSuppliersStore}) => SeveralSuppliersStore.severalSuppliersSelect,
        sameLocationSuppliersList: ({SuppliersListStore}) => SuppliersListStore.sameLocationSuppliersList,
        latestSuppliersList: ({SuppliersListStore}) => SuppliersListStore.latestSuppliersList,
        qualification: ({QualificationStore}) => QualificationStore.qualification,
        accessibility: ({AccessibilityStore}) => AccessibilityStore.accessibility,
        participantEmail: ({ParticipantsStore}) => ParticipantsStore.participantEmail,
        participantPhone: ({ParticipantsStore}) => ParticipantsStore.participantPhone,
        participantEmails: ({ParticipantsStore}) => ParticipantsStore.participantEmails,
        participantPhones: ({ParticipantsStore}) => ParticipantsStore.participantPhones,
        extraInfo: ({ExtraInfoStore}) => ExtraInfoStore.extraInfo,
        // secretary assignment
        secretaryContactName: ({SecretaryStore}) => SecretaryStore.secretaryContactName,
        secretaryContactPhone: ({SecretaryStore}) => SecretaryStore.secretaryContactPhone,
        secretaryContactPhoneCode: ({SecretaryStore}) => SecretaryStore.secretaryContactPhoneCode,
        secretaryInfo: ({SecretaryStore}) => SecretaryStore.secretaryInfo,
        secretaryCallbackPhoneSelect: ({SecretaryStore}) => SecretaryStore.secretaryCallbackPhoneSelect,
        secretaryCallbackPhone: ({SecretaryStore}) => SecretaryStore.secretaryCallbackPhone,
        secretaryCallbackPhoneCode: ({SecretaryStore}) => SecretaryStore.secretaryCallbackPhoneCode,
        // process requirements
        processRequirements: ({ProcessRequirementStore}) => ProcessRequirementStore,
        sessionTypeProcessing: ({ProcessRequirementStore}) => ProcessRequirementStore.sessionTypeProcessing,
        directProcessing: ({ProcessRequirementStore}) => ProcessRequirementStore.directProcessing,
        genderProcessing: ({ProcessRequirementStore}) => ProcessRequirementStore.genderProcessing,
        qualificationProcessing: ({ProcessRequirementStore}) => ProcessRequirementStore.qualificationProcessing,
        severalSuppliersProcessing: ({ProcessRequirementStore}) => ProcessRequirementStore.severalSuppliersProcessing,
        seriesJobProcessing: ({ProcessRequirementStore}) => ProcessRequirementStore.seriesJobProcessing,
        // invoice info
        bookingRef: ({BookingRefStore}) => BookingRefStore.bookingReference,
        paymentBookingRef: ({BookingRefStore}) => BookingRefStore.paymentBookingReference,
        caseNumber: ({CaseNumberStore}) => CaseNumberStore.caseNumber,
        clientName: ({ClientStore}) => ClientStore.clientName,
        clientId: ({ClientStore}) => ClientStore.clientId,
        paymentMethod: ({PaymentMethodStore}) => PaymentMethodStore.paymentMethod || '',
        // admin info
        actualCreatedDate: ({ReplacementStore}) => ReplacementStore.actualCreatedDate,
        actualCreatedTime: ({ReplacementStore}) => ReplacementStore.actualCreatedTime,
        bookingMechanism: ({ReplacementStore}) => ReplacementStore.bookingMechanism,
        confirmationByPhone: ({ConfirmationByStore}) => ConfirmationByStore.confirmationByPhoneCheckbox,
        confirmationBy: ({ConfirmationByStore}) => ConfirmationByStore.deadline,
        manualFees: ({ManualFeesStore}) => ManualFeesStore.manualFees,
        autoInvite: ({AutoInviteStore}) => AutoInviteStore.autoInvite,
        infiniteAutoInvite: ({AutoInviteStore}) => AutoInviteStore.infiniteAutoInvite,
        autoApprove: ({AutoApproveStore}) => AutoApproveStore.autoApprove,
        // translation project info
        targetLanguages: ({LanguagesStore}) => LanguagesStore.targetLanguages,
      }),
      ...mapState('PricingTemplatesStore', {
        demanderTemplates: ({demanderTemplates}) => demanderTemplates,
        supplierTemplates: ({supplierTemplates}) => supplierTemplates,
        demanderInternalTemplate: ({demanderInternalTemplate}) => demanderInternalTemplate,
        demanderExternalTemplate: ({demanderExternalTemplate}) => demanderExternalTemplate,
        supplierHonorarTemplate: ({supplierHonorarTemplate}) => supplierHonorarTemplate,
        supplierCompanyTemplate: ({supplierCompanyTemplate}) => supplierCompanyTemplate,
        supplierEmployeeTemplate: ({supplierEmployeeTemplate}) => supplierEmployeeTemplate
      }),
      ...mapGetters('FullAddressStore', ['fullAddress']),
      ...mapGetters('PostingNotifierStore', [
        'showDepartmentAttendersSection',
        'showBookingReferenceSection',
        'showDirectInterpreterSection',
        'showCaseNumberSection',
        'showClientSection',
        'showBookerSection',
        'showPaymentMethod',
        'showQualificationSection',
        'showParticipantsSection',
        'showAutoInviteSection',
        'showInfiniteAutoInviteSection',
        'showAutoApproveSection',
        'showStandBySection',
        'showSessionTypeProcessingSection',
        'showDirectProcessingSection',
        'showGenderProcessingSection',
        'showQualificationProcessingSection',
        'showOwnerRealNameSection',
        'showConfirmationsSection',
        'showManualFeeSection',
        'showReplacementSection',
        'showPricingTemplatesSection',
        'showSeveralSuppliersSection',
        'showSeveralSuppliersProcessingSection',
        'showAlternativeLanguageSection',
        'showAlternativeLanguageSelect',
        'showMultipleDatetimeInnerSection',
        'showSeriesBooking',
        'showSameLocationSuppliersListSection',
        'showSameLocationSuppliersList',
        'showLatestSuppliersListSection',
        'showLatestSuppliersList'
      ]),
      ...mapGetters('UserInfoStore', [
        'userIsEnterpriseMember'
      ]),
    }
  };
</script>
