export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      interpreterSkillsData: {
        interpreterSkills: [],
        count: 0,
        page: 1,
        pages: 1,
      },
      selectedInterpreterSkill: {},
      updateInterpreterSkillPageGrid: ''
    },
    actions: {
      getInterpreterSkills({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getInterpretationSkills(params).then((response) => {
            commit('setInterpreterSkillsData', response);
            const resFormatted = response;
            resFormatted.data = response.skills;
            resolve(resFormatted);
          }).catch(() => {
            reject();
          });
        });
      },
      createSkill(context, params) {
        return new Promise((resolve, reject) => {
          fetchData.createSkill(params).then((response) => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        });
      },
    },
    mutations: {
      setInterpreterSkillsData(state, data) {
        state.interpreterSkillsData = data;
      },
      setSelectedInterpreterSkill(state, interpreterSkill) {
        state.selectedInterpreterSkill = interpreterSkill;
      },
      refreshInterpreterSkillGrid(state) {
        state.updateInterpreterSkillPageGrid = Date.now();
      },
      clearSelectedInterpreterSkill(state) {
        state.selectedInterpreterSkill = {};
      },
      clearStore(state) {
        state.interpreterSkillsData = {
          interpreterSkills: [],
          count: 0,
          page: 1,
          pages: 1,
        };
        state.selectedInterpreterSkill = {};
      }
    }
  };
};
