(<template>
  <div class="participants-section">
    <div class="participants-section__container">
      <div class="participants-section__input-cont">
        <sk-select :type="'countryCodes'"
                   :no-description="true"
                   :default-value="'+47'"
                   :preselected-value="phoneCode"
                   :mob-numeric-keyboard="true"
                   class="participants-section__input"
                   @csvaluechanged="setPhoneCode" />
        <sk-input :no-description="true"
                  :preselected-value="participantPhone"
                  :margin-bottom="true"
                  :placeholder="phoneText"
                  :type="'tel'"
                  :reg-exp="/[^0-9]/g"
                  :mob-numeric-keyboard="true"
                  :validation-name="participantPhoneValidation"
                  class="participants-section__input"
                  @fieldvaluechanged="setParticipantPhone" />
        <button type="button"
                class="sk-btn sk-btn--white participants-section__add-btn"
                @click="addParticipantPhone">{{ $gettext('Add') }}</button>
      </div>
      <div v-for="(phone, index) in participantPhones"
           :key="index"
           class="participants-section__list-item">
        <p class="participants-section__item-text">{{ phone }}</p>
        <button type="button"
                class="participants-section__remove-btn"
                @click="onRemoveClick(index)"></button>
      </div>
      <div v-for="error in participantPhonesErrors"
           :key="error"
           :class="{'is-with-error': showErrors}"
           class="participants-section__error">{{ error }}</div>
    </div>
  </div>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';

  export default {
    data() {
      return {phoneCode: '+47'};
    },
    computed: {
      ...mapState('PostingStore/ParticipantsStore', {
        participantPhone: (state) => state.participantPhone,
        participantPhones: (state) => state.participantPhones,
        participantPhonesErrors: ({errors}) => errors.participantPhones.errors,
        participantPhoneValidation: ({errors}) => errors.participantPhone.name
      }),
      phoneText() {
        return this.$gettext('Phone number');
      },
      duplicatePhoneErrorMessage() {
        return this.$gettext('Confirmation phones should not be duplicated');
      },
      showErrors() {
        return this.participantPhonesErrors && this.participantPhonesErrors.length;
      }
    },
    methods: {
      ...mapMutations('PostingStore/ParticipantsStore', [
        'setParticipantPhone',
        'addParticipantPhones',
        'removeParticipantPhone',
        'removeOneError'
      ]),
      addParticipantPhone() {
        const participantPhone = `${this.phoneCode}${this.participantPhone}`;
        this.removeParticipantPhoneErrors();
        if (this.participantPhones.includes(participantPhone)) {
          this.$store.commit('ErrorsStore/setError', {name: this.participantPhoneValidation, errors: [this.duplicatePhoneErrorMessage]});
          return;
        }
        if (!this.participantPhone) return;
        this.addParticipantPhones(participantPhone);
        this.setParticipantPhone('');
      },
      setPhoneCode(val) {
        this.phoneCode = val;
      },
      onRemoveClick(index) {
        this.removeOneError('participantPhones');
        this.removeParticipantPhone(index);
      },
      removeParticipantPhoneErrors() {
        this.removeOneError('participantPhones');
        this.$store.commit('ErrorsStore/setError', {name: this.participantPhoneValidation, errors: []});
      }
    }
  };
</script>

<style>
  .participants-section__input .sk-input__input-wrapper {
    background-color: #fff;
  }

  .participants-section__input .sk-select {
    background-color: #fff;
  }

  .participants-section__input .sk-select :first-child {
    font-weight: bold;
  }
</style>

<style scoped src="@assets/css/participants_section.css"></style>
