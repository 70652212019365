const timelineEvents = (timelineEvents = {}) => {
  return [
    [timelineEvents.events || [], 'event'],
    [timelineEvents.jobs || [], 'job'],
    [timelineEvents.offTimes || [], 'offtime'],
    [timelineEvents.travelTimes[0] || [], 'travelFromTime'],
    [timelineEvents.travelTimes[1] || [], 'travelToTime']
  ];
};

const getAccountStatus = (context, accountStatus) => {
  switch (accountStatus) {
    case 'paused':
      return context.$gettext('Paused');
    case 'retired':
      return context.$gettext('Retired');
    case 'blocked':
      return context.$gettext('Blocked');
    case 'banned':
      return context.$gettext('Banned');
    case 'deceased':
      return context.$gettext('Deceased');
    default:
      return '';
  }
};

const isBlockedSessionType = (sessionType) => {
  return ['paused', 'blocked'].includes(sessionType);
};

const isBlockedAccountStatus = (accountStatus) => {
  return ['paused', 'blocked', 'banned', 'deceased'].includes(accountStatus);
};

const іsBlockedInterpreter = (interpreterData = {}) => {
  const sessionTypes = interpreterData.sessionTypeStatus || {};
  const sessionTypeIsBlocked = Object.entries(sessionTypes)
    // eslint-disable-next-line no-unused-vars
    .find(([key, value]) => isBlockedSessionType(value));
  const accountStatusIsBlocked = isBlockedAccountStatus(interpreterData.accountStatus || '');
  const interpreterIsBlockedForEnterprises = interpreterData.blockedForEnterprises?.length;

  return sessionTypeIsBlocked || accountStatusIsBlocked || interpreterIsBlockedForEnterprises;
};

export default {
  timelineEvents,
  getAccountStatus,
  іsBlockedInterpreter,
  isBlockedSessionType,
  isBlockedAccountStatus
};
