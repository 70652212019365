(<template>
  <post-inner-section ref="severalSuppliers"
                      class="several-suppliers"
                      @focusin="openSeveralSuppliersHelper">
    <sk-checkbox :item-text="severalSuppliersCheckboxDescription"
                 :preselected-value="severalSuppliersSelect"
                 :disabled="disableSelection"
                 :class="{'is-disabled': disableSelection}"
                 class="several-suppliers__checkbox"
                 @checkboxvaluechanged="changeSeveralSuppliersSelectState" />
    <several-suppliers-processing v-if="severalSuppliersSelect"
                                  class="several-suppliers__processing" />
  </post-inner-section>
</template>)

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import PostInnerSection from '@/components/booking_components/PostInnerSection';
  import SeveralSuppliersProcessingSection from '@/components/booking_components/parts/several_suppliers_section/SeveralSuppliersProcessingSection';

  export default {
    components: {
      'post-inner-section': PostInnerSection,
      'several-suppliers-processing': SeveralSuppliersProcessingSection
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('PostingStore/SeveralSuppliersStore', {
        severalSuppliersSelect: ({severalSuppliersSelect}) => severalSuppliersSelect
      }),
      paramsList() { return this.params || {}; },
      disableSelection() { return this.paramsList.disabledFields; },
      severalSuppliersCheckboxDescription() { return this.$gettext('I need 2 interpreters for this assignment'); },
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    methods: {
      ...mapActions('PostingStore/SeveralSuppliersStore', [
        'changeSeveralSuppliersSelectState'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
      ]),
      openSeveralSuppliersHelper() {
        if (this.showAssignmentHelper) {
          this.setPopoverReference(this.$refs.severalSuppliers);
          this.openHelper('severalSuppliers');
        }
      },
    }
  };
</script>

<style>
  .several-suppliers .processing__radios-wrapper {
    flex-wrap: wrap;
    margin-top: 0;
  }

  .several-suppliers .processing__radio {
    width: 100%;
    margin-top: 15px;
  }
</style>

<style scoped>
  .is-disabled {
    color: #d3d5de;
    pointer-events: none;
  }
</style>
