import axios from 'axios';

const dashboard = {
  // ==================================================
  // ==================================================
  // BUSINESS DASHBOARD CALLS
  // ==================================================
  // ==================================================
  methods: {
    getFavouritInterpreters(langFromId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get('/v1/dashboards/favourite_interpreters', {params: {lang_from_id: langFromId}}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getNewsList(params) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.get('/v1/articles', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getLastPublishedNewsList() {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.get('/v1/articles/last_published').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          this.resolveError404(error, resolve);
          reject(error);
        });
      });
    },
    getDidYouKnowList() {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.get('/v1/helpscout/articles').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          this.resolveError404(error, resolve);
          if (error?.status == 422) {
            resolve(error);
            return;
          }
          reject(error);
        });
      });
    },
    getMyAssignments(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/dashboards/jobs', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getSearchAssignments(params) {
      const paramsObj = {
        params: params,
        cancelToken: this.cancelationSource.token
      };
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/dashboards/all_booked_jobs', paramsObj).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    cancelSearchAssignments() {
      return new Promise((resolve) => {
        this.cancelationSource.cancel();
        this.cancelationSource = '';
        this.cancelationSource = axios.CancelToken.source();
        resolve();
      });
    },
    getMyAssignmentsStatistic() {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.get('/v1/dashboards/job_statistics').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getAssignStatisticByLang() {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.get('/v1/dashboards/booked_languages').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    // ==================================================
    // ==================================================
    // INTERPRETER DASHBOARD CALLS
    // ==================================================
    // ==================================================
    getAvailableAssignments(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/dashboards/suitable_jobs', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getInvitedAssignmentDashboard(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/dashboards/suitable_jobs_with_availability', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getAllPastAssignments(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/dashboards/past_jobs', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getAllHiddenAssignments(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/dashboards/hidden_jobs', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    //* ************ Manager Endpoints **************//
    getManagerAssignments(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/dashboards/manager_jobs', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getManagerJobStats() {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/dashboards/manager_job_stats').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getManagerJobActivity() {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/dashboards/manager_job_activity').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getManagerJobAssignments() {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/dashboards/manager_job_assignments').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    updateManagerJobAssignment(data) {
      return new Promise((resolve, reject) => {
        this.ajax.patch('/v1/dashboards/manager_job_assignments', data).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    assignManagerToJobAssignment(id, data) {
      return new Promise((resolve, reject) => {
        this.ajax.patch(`/v1/jobs/${id}/assign_manager`, data).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getManagerSubtasks(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/dashboards/manager_subtasks', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getManagerSubtaskStats() {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/dashboards/manager_subtasks_stats').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getManagerSubtaskTabsAssignments() {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/dashboards/manager_subtasks_assignments').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    updateManagerSubtaskTabsAssignment(data) {
      return new Promise((resolve, reject) => {
        this.ajax.patch('/v1/dashboards/manager_subtasks_assignments', data).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getFeedbackJobs() {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get('/v1/dashboards/without_feedback').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getAvailableInterpreters(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('v1/dashboards/hotline_interpreters', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
  }
};

export default dashboard;
