(<template>
  <post-accordion-section ref="blockedSupplierSection"
                          :state="blockedSuppliersSelect"
                          :title="title"
                          :description="description"
                          class="blocked-suppliers"
                          @focusin="openBlockedSuppliersHelper"
                          @statechanged="updateBlockedSuppliersSelect">
    <template v-if="blockedSuppliersSelect">
      <p class="blocked-suppliers__text">{{ blockedText }}</p>
      <interactive-supplier-search :items-list="computedSuppliersList"
                                   :preselected-value="blockedSuppliers"
                                   :description="blockedSuppliersText"
                                   :dynamic-search="true"
                                   :default-value="blockedSuppliersDefaultText"
                                   :tags="blockedSuppliersFullList"
                                   class="blocked-suppliers__select"
                                   @onsuccess="handleSuccess"
                                   @onresetfilter="resetFilter"
                                   @checkboxeschanged="catchBlockedSuppliers" />
    </template>
  </post-accordion-section>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';
  import PostAccordionSection from '@/components/booking_components/PostAccordionSection';
  import InteractiveSearchForBlocking from '@/components/booking_components/parts/blocked_supplier_section/InteractiveSearchForBlocking';

  export default {
    components: {
      'post-accordion-section': PostAccordionSection,
      'interactive-supplier-search': InteractiveSearchForBlocking,
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('PostingStore/BlockedSuppliersStore', {
        blockedSuppliersSelect: ({blockedSuppliersSelect}) => blockedSuppliersSelect,
        blockedSuppliers: ({blockedSuppliers}) => blockedSuppliers,
        blockedSuppliersFullList: ({blockedSuppliersFullList}) => blockedSuppliersFullList,
        suppliersList: ({suppliersList}) => suppliersList || [],
      }),
      title() { return this.$gettext('Block interpreter'); },
      description() { return this.$gettext('I need to block one or more interpreters from this assignment'); },
      blockedText() {
        return this.$gettext('Search and tick all interpreters that you do not want for the assignment. These interpreters will not be invited and thus will be unable to apply.');
      },
      blockedSuppliersText() { return this.$gettext('Search by: Interpreter name'); },
      blockedSuppliersDefaultText() { return this.$gettext('Interpreter name'); },
      computedSuppliersList() {
        return this.suppliersList.map((supplier) => {
          return {
            id: supplier.uid,
            name: supplier.name
          };
        });
      },
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    methods: {
      ...mapMutations('PostingStore/BlockedSuppliersStore', [
        'setBlockedSuppliers',
        'setBlockedSuppliersFullList',
        'addBlockedSuppliersFullListItem',
        'setBlockedSuppliersSelect',
        'setSuppliersList'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
        'setReferenceToBuyerInfoSection',
      ]),
      openBlockedSuppliersHelper() {
        if (this.showAssignmentHelper) {
          this.setPopoverReference(this.$refs.blockedSupplierSection);
          this.openHelper('blockedSuppliers');
        }
      },
      updateBlockedSuppliersSelect(value) {
        if (!value) {
          this.setReferenceToBuyerInfoSection();
          this.openHelper('');
        }
        this.setBlockedSuppliersSelect(value);
      },
      catchBlockedSuppliers(data = []) {
        if (data.length < this.blockedSuppliers.length) {
          this.setBlockedSuppliersFullList(this.blockedSuppliersFullList.filter((item) => data.indexOf(item.id) !== -1));
        } else {
          this.computedSuppliersList.forEach((item) => {
            if (data.indexOf(item.id) !== -1) {
              if (!this.blockedSuppliersFullList.find((supplier) => supplier.id === item.id)) {
                this.addBlockedSuppliersFullListItem(item);
              }
            }
          });
        }
        this.setBlockedSuppliers(data);
      },
      resetFilter() {
        this.setSuppliersList([]);
      },
      handleSuccess() {
        if (!this.suppliersList.length) {
          this.setSuppliersList([{name: 'Not found', uid: ''}]);
        }
      }
    }
  };
</script>

<style scoped>
  .blocked-suppliers__text {
    margin-top: 15px;
  }

  .blocked-suppliers__select {
    margin-top: 20px;
  }
</style>
