export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    getters: {
      bookingFormProgress(state, getters, rootState) {
        const {departmentsProgress, attendersProgress} = rootState.PostingStore.DepartmentAttendersStore;
        return departmentsProgress || attendersProgress;
      }
    },
    actions: {
      createForm({rootState, rootGetters, dispatch}) {
        return new Promise((resolve) => {
          const form = new FormData();
          // MY INFORMATION SECTION
          if (rootState.PostingStore.BookerStore.booker) form.append('staffing_project[requirement][booker_uid]', rootState.PostingStore.BookerStore.booker);
          if (rootState.PostingStore.DepartmentAttendersStore.department) form.append('staffing_project[requirement][department_id]', rootState.PostingStore.DepartmentAttendersStore.department);
          const {attenders} = rootState.PostingStore.DepartmentAttendersStore;
          if (attenders && attenders.length) {
            attenders.forEach((attender) => {
              form.append('staffing_project[participants][][uid]', attender);
            });
          }
          if (rootState.PostingStore.CaseNumberStore.caseNumber) form.append('staffing_project[info][case_number]', rootState.PostingStore.CaseNumberStore.caseNumber);
          if (rootState.PostingStore.BookingRefStore.bookingReference) form.append('staffing_project[info][booking_reference]', rootState.PostingStore.BookingRefStore.bookingReference);
          if (rootState.PostingStore.BookingRefStore.paymentBookingReference) form.append('staffing_project[info][payment_booking_reference]', rootState.PostingStore.BookingRefStore.paymentBookingReference);
          if (rootState.PostingStore.PaymentMethodStore.paymentMethod) form.append('staffing_project[payment_method_id]', rootState.PostingStore.PaymentMethodStore.paymentMethod);

          // ASSIGNMENT INFORMATION SECTION
          form.append('staffing_project[requirement][language_from_id]', '3');
          if (rootState.PostingStore.LanguagesStore.languageTo !== 'not_important') form.append('staffing_project[requirement][language_to_id]', rootState.PostingStore.LanguagesStore.languageTo);
          form.append('staffing_project[info][subject]', rootState.PostingStore.SubjectStore.subject);
          form.append('staffing_project[requirement][sex]', rootState.PostingStore.GenderStore.sex); // send 2 for select male and female or for edit
          // TODO
          if (rootState.PostingStore.GenderStore.genderSelect) form.append('staffing_project[process_requirement][auto_update_gender]', !!rootState.PostingStore.GenderStore.autoUpdateGender);
          if (rootState.PostingStore.SubjectStore.category) { form.append('staffing_project[info][job_category_item_id]', rootState.PostingStore.SubjectStore.category); }
          if (rootState.PostingStore.ExtraInfoStore.extraInfo) form.append('staffing_project[requirement][translator_information]', rootState.PostingStore.ExtraInfoStore.extraInfo);
          if (rootState.PostingStore.MultipleDateTimeStore.dateTimes.length) {
            rootState.PostingStore.MultipleDateTimeStore.dateTimes.forEach((dateTime) => {
              form.append('staffing_project[assignment_dates][][start_time]', dateTime.startTime);
              form.append('staffing_project[assignment_dates][][finish_time]', dateTime.finishTime);
            });
          }
          const {contactAttributes} = rootState.PostingStore.AssignmentTypeStore;
          if (contactAttributes.person.id) {
            form.append('staffing_project[requirement][contact_attributes][person][id]', contactAttributes.person.id);
          } else {
            if (contactAttributes.person.name) {
              form.append('staffing_project[requirement][contact_attributes][person][name]', contactAttributes.person.name);
            }
            if (contactAttributes.person.phone) {
              form.append('staffing_project[requirement][contact_attributes][person][phone]', contactAttributes.person.phone);
            }
            form.append('staffing_project[requirement][contact_attributes][person][save_contact]', contactAttributes.person.save_contact);
          }
          if (contactAttributes.address.id) {
            form.append('staffing_project[requirement][contact_attributes][address][id]', contactAttributes.address.id);
          } else {
            const {line1, line2, postcode, city, county, country} = rootState.FullAddressStore;
            const address = rootGetters['FullAddressStore/fullAddress'];
            dispatch('FullAddressStore/getGeometryLocation', address, {root: true})
              .then(() => {
                const {latitude, longitude} = rootState.FullAddressStore;
                form.append('staffing_project[requirement][contact_attributes][address][latitude]', latitude);
                form.append('staffing_project[requirement][contact_attributes][address][longitude]', longitude);
                form.append('staffing_project[requirement][contact_attributes][address][line_1]', line1);
                form.append('staffing_project[requirement][contact_attributes][address][line_2]', line2);
                form.append('staffing_project[requirement][contact_attributes][address][postcode]', postcode);
                form.append('staffing_project[requirement][contact_attributes][address][city]', city);
                form.append('staffing_project[requirement][contact_attributes][address][county]', county);
                form.append('staffing_project[requirement][contact_attributes][address][country]', country);
                form.append('staffing_project[requirement][contact_attributes][address][save_address]', contactAttributes.address.save_address);
                form.append('staffing_project[requirement][contact_attributes][address][extra]', contactAttributes.address.extra);

                resolve(form);
              });
            return;
          }
          resolve(form);
        });
      },
      createJob({dispatch, commit}) {
        return new Promise((resolve, reject) => {
          dispatch('createForm')
            .then((form) => {
              fetchData.createStaffingJob(form).then((newJobObj = {}) => {
                commit('OneStaffingStore/setStaffingJobInfo', newJobObj.staffingProject, {root: true});
                resolve(newJobObj);
              }).catch((error) => {
                reject(error);
              });
            });
        });
      },
      clearStore({commit}) {
        commit('PostingStore/BookerStore/clearStore', null, {root: true});
        commit('PostingStore/BookingRefStore/clearStore', null, {root: true});
        commit('PostingStore/CaseNumberStore/clearStore', null, {root: true});
        commit('PostingStore/DepartmentAttendersStore/clearStore', null, {root: true});
        commit('PostingStore/ExtraInfoStore/clearStore', null, {root: true});
        commit('PostingStore/SubjectStore/clearStore', null, {root: true});
        commit('PostingStore/PaymentMethodStore/clearStore', null, {root: true});
        commit('PostingStore/GenderStore/clearStore', null, {root: true});
        commit('EnterpriseStore/clearStore', null, {root: true});
      }
    }
  };
};
