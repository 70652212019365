(<template>
  <post-accordion-section ref="alternativeLanguage"
                          :state="alternativeLanguageToSelect"
                          :title="title"
                          :description="description"
                          :disabled="disabledFields"
                          class="alternative-language-section"
                          @focusin="openAlternativeLanguageHelper"
                          @statechanged="updateAlternativeLangToSelectState">
    <div v-if="alternativeLanguageToSelect"
         class="post-form__field-group alternative-language__select-wrapper">
      <sk-select :items-list="languages"
                 :description="langToText"
                 :preselected-value="alternativeLangTo"
                 :default-value="langDefaultValue"
                 :validation-name="alternativeLangFromErrorsName"
                 :disabled="disabledFields"
                 class="post-form__field post-form__field--half-width alternative-language__select"
                 @csvaluechanged="setAlternativeLangTo" />
    </div>
  </post-accordion-section>
</template>)

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import PostAccordionSection from '@/components/booking_components/PostAccordionSection';

  export default {
    components: {
      'post-accordion-section': PostAccordionSection
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('PostingStore/LanguagesStore', {
        alternativeLangTo: ({alternativeLanguageTo}) => alternativeLanguageTo,
        alternativeLanguageToSelect: ({alternativeLanguageToSelect}) => alternativeLanguageToSelect,
        // errors
        alternativeLangFromErrorsName: ({errors}) => errors.alternativeLangTo.name
      }),
      paramsList() { return this.params || {}; },
      disabledFields() { return this.paramsList.disabledFields; },
      langToText() { return this.$gettext('Language'); },
      langDefaultValue() { return this.$gettext('Select a language'); },
      languages() { return this.$store.state.selectsData.language || []; },
      title() {
        return this.$gettext('Alternative language');
      },
      description() {
        return this.$gettext('Add an alternate language that could be used');
      },
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    methods: {
      ...mapActions('PostingStore/LanguagesStore', [
        'changeAlternativeLangToSelectState'
      ]),
      ...mapMutations('PostingStore/LanguagesStore', [
        'setAlternativeLangTo'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
        'setReferenceToBuyerInfoSection',
      ]),
      openAlternativeLanguageHelper() {
        if (this.showAssignmentHelper) {
          this.setPopoverReference(this.$refs.alternativeLanguage);
          this.openHelper('alternativeLanguage');
        }
      },
      updateAlternativeLangToSelectState(value) {
        if (!value) {
          this.setReferenceToBuyerInfoSection();
          this.openHelper('');
        }
        this.changeAlternativeLangToSelectState(value);
      }
    }
  };
</script>

<style scoped>
  .alternative-language__select {
    margin-top: 15px;
    margin-bottom: 0;
  }
</style>
