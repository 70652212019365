export default (context, event) => {
  const {target} = event;
  // handle only links that occur inside the 'dynamic-content'
  if (target && target.matches('.dynamic-content a') && target.href) {
    // don't handle same page links/anchors
    const url = new URL(target.href);
    const to = `${url.pathname}${url.search}`.trim();
    if (event.preventDefault) {
      event.preventDefault();
      context.$router.push(to);
    }
  }
};
