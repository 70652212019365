export default {
  path: 'projects',
  component: () => import('@/pages/shared_pages/project_pages/sh_ProjectsRoot'),
  children: [
    {
      path: '/',
      redirect: {name: 'ServerAllProjects'}
    },
    {
      path: 'all',
      name: 'ServerAllProjects',
      component: () => import('@/pages/shared_pages/project_pages/sh_AllProjects')
    },
    {
      path: 'all/:id',
      name: 'ServerOneProject',
      meta: {subPage: true, viewers: true},
      component: () => import('@/pages/shared_pages/project_pages/sh_OneProject')
    }
  ]
};
