(<template>
  <div class="pricing-templates">
    <div class="pricing-templates__selectors-wrapper">
      <sk-select :items-list="demanderInternalTemplatesList"
                 :default-value="defaultTemplateValue"
                 :description="demanderInternalText"
                 :preselected-value="demanderInternalTemplate"
                 :disabled="disableSelectors"
                 class="pricing-templates__selector"
                 @csvaluechanged="catchDemanderInternalTemplate" />
      <slot name="demanderInternal"></slot>
    </div>
    <div class="pricing-templates__selectors-wrapper">
      <sk-select :items-list="demanderExternalTemplatesList"
                 :default-value="defaultTemplateValue"
                 :description="demanderExternalText"
                 :preselected-value="demanderExternalTemplate"
                 :disabled="disableSelectors"
                 class="pricing-templates__selector"
                 @csvaluechanged="catchDemanderExternalTemplate" />
      <slot name="demanderExternal"></slot>
    </div>
    <div class="pricing-templates__selectors-wrapper">
      <sk-select :items-list="supplierHonorarTemplatesList"
                 :default-value="defaultTemplateValue"
                 :description="supplierHonararText"
                 :preselected-value="supplierHonorarTemplate"
                 :disabled="disableSelectors"
                 class="pricing-templates__selector"
                 @csvaluechanged="catchSupplierHonorarTemplate" />
      <slot name="supplierHonorar"></slot>
    </div>
    <div class="pricing-templates__selectors-wrapper">
      <sk-select :items-list="supplierCompanyTemplatesList"
                 :default-value="defaultTemplateValue"
                 :description="supplierCompanyText"
                 :preselected-value="supplierCompanyTemplate"
                 :disabled="disableSelectors"
                 class="pricing-templates__selector"
                 @csvaluechanged="catchSupplierCompanyTemplate" />
      <slot name="supplierCompany"></slot>
    </div>
    <div class="pricing-templates__selectors-wrapper">
      <sk-select :items-list="supplierEmployeeTemplatesList"
                 :default-value="defaultTemplateValue"
                 :description="supplierEmployeeText"
                 :preselected-value="supplierEmployeeTemplate"
                 :disabled="disableSelectors"
                 class="pricing-templates__selector"
                 @csvaluechanged="catchSupplierEmployeeTemplate" />
      <slot name="supplierEmployee"></slot>
    </div>
  </div>
</template>)

<script>
  import {mapMutations, mapState} from 'vuex';

  export default {
    props: {
      disableSelectors: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapState('PricingTemplatesStore', {
        demanderTemplates: ({demanderTemplates}) => demanderTemplates,
        supplierTemplates: ({supplierTemplates}) => supplierTemplates,
        demanderInternalTemplate: ({demanderInternalTemplate}) => demanderInternalTemplate,
        demanderExternalTemplate: ({demanderExternalTemplate}) => demanderExternalTemplate,
        supplierHonorarTemplate: ({supplierHonorarTemplate}) => supplierHonorarTemplate,
        supplierCompanyTemplate: ({supplierCompanyTemplate}) => supplierCompanyTemplate,
        supplierEmployeeTemplate: ({supplierEmployeeTemplate}) => supplierEmployeeTemplate
      }),
      defaultTemplateValue() { return this.$gettext('Select'); },
      demanderInternalText() { return this.$gettext('Customer Internal:'); },
      demanderExternalText() { return this.$gettext('Customer External:'); },
      supplierHonararText() { return this.$gettext('Supplier Honorar:'); },
      supplierCompanyText() { return this.$gettext('Supplier Company:'); },
      supplierEmployeeText() { return this.$gettext('Supplier Employee:'); },
      submitText() { return this.$gettext('Update'); },
      demanderInternalTemplatesList() { return this.formList({roleType: 'demander', financeType: 'internal'}); },
      demanderExternalTemplatesList() { return this.formList({roleType: 'demander', financeType: 'external'}); },
      supplierHonorarTemplatesList() { return this.formList({roleType: 'supplier', financeType: 'honorar'}); },
      supplierCompanyTemplatesList() { return this.formList({roleType: 'supplier', financeType: 'company'}); },
      supplierEmployeeTemplatesList() { return this.formList({roleType: 'supplier', financeType: 'employee'}); }
    },
    methods: {
      ...mapMutations('PricingTemplatesStore', ['setTemplate']),
      catchDemanderInternalTemplate(value) { this.setTemplate({roleType: 'demander', id: value, financeType: 'internal'}); },
      catchDemanderExternalTemplate(value) { this.setTemplate({roleType: 'demander', id: value, financeType: 'external'}); },
      catchSupplierHonorarTemplate(value) { this.setTemplate({roleType: 'supplier', id: value, financeType: 'honorar'}); },
      catchSupplierCompanyTemplate(value) { this.setTemplate({roleType: 'supplier', id: value, financeType: 'company'}); },
      catchSupplierEmployeeTemplate(value) { this.setTemplate({roleType: 'supplier', id: value, financeType: 'employee'}); },
      formList({roleType, financeType}) {
        const roleBasedList = roleType === 'demander' ? this.demanderTemplates : this.supplierTemplates;
        const childList = roleBasedList[financeType];
        const cloneList = (childList && childList.length) ? [...childList] : [];
        if (cloneList.filter((e) => e.name === this.defaultTemplateValue).length === 0) {
          cloneList.unshift({id: '', name: this.defaultTemplateValue});
        }
        return cloneList;
      }
    }
  };
</script>

<style>
.pricing-templates__selector .sk-select {
  background-color: #fff;
}
</style>

<style scoped>
.pricing-templates__selectors-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px;
}

.pricing-templates__selector {
  width: 100%;
  margin-top: 15px;
  padding: 0 10px;
}
</style>
