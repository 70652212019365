export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      newSubtasks: '',
      proactiveSubtasks: '',
      rejectedSubtasks: '',
      cancelledSubtasks: '',
      activeSubtasks: '',
      finishedSubtasks: '',
      requestedReviewSubtasks: '',
      changesRequiredSubtasks: '',
      availableSubtaskProgress: false,
      acceptedSubtaskProgress: false
    },
    getters: {
      activeSubtasks(state) {
        return state.activeSubtasks.subtasks || [];
      },
      rejectedSubtasks(state) {
        return state.rejectedSubtasks.subtasks || [];
      },
      cancelledSubtasks(state) {
        return state.cancelledSubtasks.subtasks || [];
      },
      newSubtasks(state) {
        return state.newSubtasks.subtasks || [];
      },
      proactiveSubtasks(state) {
        return state.proactiveSubtasks.subtasks || [];
      },
      finishedSubtasks(state) {
        return state.finishedSubtasks.subtasks || [];
      },
      requestedReviewSubtasks(state) {
        return state.requestedReviewSubtasks.subtasks || [];
      },
      changesRequiredSubtasks(state) {
        return state.changesRequiredSubtasks.subtasks || [];
      },
      activeSubtasksPages(state) {
        return state.activeSubtasks.pages || 0;
      },
      rejectedSubtasksPages(state) {
        return state.rejectedSubtasks.pages || 0;
      },
      cancelledSubtasksPages(state) {
        return state.cancelledSubtasks.pages || 0;
      },
      newSubtasksPages(state) {
        return state.newSubtasks.pages || 0;
      },
      proactiveSubtasksPages(state) {
        return state.proactiveSubtasks.pages || 0;
      },
      finishedSubtasksPages(state) {
        return state.finishedSubtasks.pages || 0;
      },
      requestedReviewSubtasksPages(state) {
        return state.requestedReviewSubtasks.pages || 0;
      },
      changesRequiredSubtasksPages(state) {
        return state.changesRequiredSubtasks.pages || 0;
      },
    },
    actions: {
      fetchAcceptedSubtasks({commit}, params) {
      return new Promise((resolve, reject) => {
        fetchData.getAcceptedSubtasks(params).then((response) => {
          if (response?.code == 401) {
            commit('TTAuthStore/logOut', '', {root: true});
            commit('GlobalStore/remove404Page', '', {root: true});
            resolve('LOGOUT');
            return;
          }
          switch (params.status) {
            case 'active':
              commit('setActiveSubtasks', response);
              break;
            case 'review_requested':
              commit('setRequestedReviewSubtasks', response);
              break;
            case 'changes_required':
              commit('setChangesRequiredSubtasks', response);
              break;
            case 'finished':
              commit('setFinishedSubtasks', response);
              break;
          }
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
      fetchAvailableSubtasks({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getAvailableSubtasks(params).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            switch (params.status) {
              case 'proactive_access':
                commit('setProactiveSubtasks', response);
                break;
              case 'active':
                commit('setNewSubtasks', response);
                break;
              case 'rejected':
                commit('setRejectedSubtasks', response);
                break;
              case 'cancelled':
                commit('setCancelledSubtasks', response);
                break;
            }
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
    },
    mutations: {
      setProactiveSubtasks(state, data) {
        state.proactiveSubtasks = data;
      },
      setNewSubtasks(state, data) {
        state.newSubtasks = data;
      },
      setFinishedSubtasks(state, data) {
        state.finishedSubtasks = data;
      },
      setActiveSubtasks(state, data) {
        state.activeSubtasks = data;
      },
      setRequestedReviewSubtasks(state, data) {
        state.requestedReviewSubtasks = data;
      },
      setChangesRequiredSubtasks(state, data) {
        state.changesRequiredSubtasks = data;
      },
      setRejectedSubtasks(state, data) {
        state.rejectedSubtasks = data;
      },
      setCancelledSubtasks(state, data) {
        state.cancelledSubtasks = data;
      },
      startAvailableSubtaskProgress(state) {
        state.availableSubtaskProgress = true;
      },
      stopAvailableSubtaskProgress(state) {
        state.availableSubtaskProgress = false;
      },
      startAcceptedSubtaskProgress(state) {
        state.acceptedSubtaskProgress = true;
      },
      stopAcceptedSubtaskProgress(state) {
        state.acceptedSubtaskProgress = false;
      },
      removeActiveSubtasks(state) {
        state.activeSubtasks = [];
      },
      removeRejectedSubtasks(state) {
        state.rejectedSubtasks = [];
      },
      removeNewSubtasks(state) {
        state.newSubtasks = [];
      },
      removeFinishedSubtasks(state) {
        state.finishedSubtasks = [];
      },
      removeAllSubtasksData(state) {
        state.rejectedSubtasks = [];
        state.cancelledSubtasks = [];
        state.activeSubtasks = [];
        state.newSubtasks = [];
        state.proactiveSubtasks = [];
        state.finishedSubtasks = [];
        state.requestedReviewSubtasks = [];
        state.changesRequiredSubtasks = [];
        state.availableSubtaskProgress = false;
        state.acceptedSubtaskProgress = false;
      }
    }
  };
};
