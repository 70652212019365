<script>
  export default {
    data() {
      return {
        requestTimer: '',
        requestWasMade: false,
        delay: 500
      };
    },
    computed: {
      searchQuery() {} // should be rewrited in child component
    },
    methods: {
      setSearchQueryMutation() {}, // should be rewrited in child component
      cancelSearch() {}, // should be rewrited in child component
      submitAction() {}, // should be rewrited in child component
      startProgress() {
        this.$emit('startprogress');
      },
      stopProgress() {
        this.$emit('stopprogress');
      },
      resetErrors() {
        this.$emit('onreseterror');
      },
      startSearchRequest() {
        this.startProgress();
        this.requestWasMade = true;
      },
      handleSearchResponse() {
        this.resetErrors();
        this.stopProgress();
        this.requestWasMade = false;
      },
      setSearchQuery(value) {
        if (value !== this.searchQuery) {
          this.stopRequest();
          clearInterval(this.requestTimer);
          if (!value) {
            return this.resetFilter();
          }
          this.setSearchQueryMutation(value);
          this.requestTimer = setTimeout(() => {
            this.search(this.searchQuery);
          }, this.delay);
        }
      },
      search(value) {
        this.startSearchRequest();
        this.submitAction(value).then((data) => {
          this.handleSearchResponse();
          this.$emit('onsuccess', data);
        }).catch((error) => {
          this.handleSearchResponse();
          this.$emit('onerror', error);
        });
      },
      stopRequest() {
        if (this.requestWasMade) {
          this.cancelSearch();
          this.requestWasMade = false;
        }
      },
      resetFilter() {
        this.$emit('onresetfilter');
        this.setSearchQueryMutation('');
      }
    }
  };
</script>
