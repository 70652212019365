(<template>
  <post-accordion-section ref="latestSuppliers"
                          :state="suppliersListSelect"
                          :title="title"
                          :description="description"
                          class="suppliers-list"
                          @focusin="openLastSupplierHelper"
                          @statechanged="updateSuppliersListSelectState">
    <div v-if="suppliersListSelect"
         class="suppliers-list__list-wrapper">
      <div v-for="supplier in suppliersList"
           :key="supplier.uid"
           class="suppliers-list__list-item">
        <sk-checkbox :item-text="getTimelineTitle(supplier.name)"
                     :preselected-value="supplier.selected"
                     class="suppliers-list__checkbox"
                     @checkboxvaluechanged="checkSupplierFromList(supplier.uid)" />
        <availability-timeline :events-list="getTimelineEvents(supplier.timelineEvents)"
                               :selected-event="selectedEventData"
                               :date-range-from="assignmentStartTime"
                               :date-range-to="assignmentFinishTime"
                               :col-width="40"
                               :timeline-step="60"
                               :enable-event-description="true"
                               class="suppliers-list__timeline" />
      </div>
    </div>
  </post-accordion-section>
</template>)

<script>
  import {mapMutations, mapState} from 'vuex';
  import PostingSuppliersListPrototype from '@/prototypes/PostingSuppliersListPrototype';
  import PostAccordionSection from '@/components/booking_components/PostAccordionSection';

  export default {
    components: {
      'post-accordion-section': PostAccordionSection,
    },
    extends: PostingSuppliersListPrototype,
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('PostingStore/SuppliersListStore', {
        suppliersListSelect: ({latestSuppliersListSelect}) => latestSuppliersListSelect,
        suppliersList: ({latestSuppliersList}) => latestSuppliersList
      }),
      title() {
        return this.$gettext('Previously used interpreters');
      },
      description() {
        return this.$gettext('Show available interpreters that I have used for this language before');
      },
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    methods: {
      ...mapMutations({
        setSuppliersListSelectState: 'PostingStore/SuppliersListStore/setLatestSuppliersListSelectState',
        checkSupplierFromList: 'PostingStore/SuppliersListStore/checkLatestSupplier',
      }),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
        'setReferenceToBuyerInfoSection',
      ]),
      openLastSupplierHelper() {
        if (this.showAssignmentHelper) {
          this.setPopoverReference(this.$refs.latestSuppliers);
          this.openHelper('latestSuppliers');
        }
      },
      updateSuppliersListSelectState(value) {
        if (!value) {
          this.setReferenceToBuyerInfoSection();
          this.openHelper('');
        }
        this.setSuppliersListSelectState(value);
      }
    }
  };
</script>
