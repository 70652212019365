import Vue from 'vue';
import Router from 'vue-router';
import authRoutes from './authRoutes';
import wizardRoutes from './wizardRoutes';
import bigMoveRedirects from './bigMoveRedirects';
import buyerRoutes from './buyer_routes/buyerRoutes';
import managerRoutes from './manager_routes/managerRoutes';
import serverRoutes from './server_routes/serverRoutes';
import openBookingRoutes from './openBookingRoutes';

Vue.use(Router);

export function createRouter() {
  return new Router({
    mode: 'history',
    routes: [
      {
        path: '/:lang/:country/norsk/auth/sign_in',
        redirect: {name: 'SignIn'}
      },
      {
        path: '/:lang/:country/english/auth/sign_in',
        redirect: {name: 'SignIn'}
      },
      {
        path: '/norsk/auth/sign_in',
        redirect: {name: 'SignIn'}
      },
      {
        path: '/english/auth/sign_in',
        redirect: {name: 'SignIn'}
      },
      {
        path: '/:lang/:country/norsk/auth/restore_password',
        redirect: {name: 'RestorePassword'}
      },
      {
        path: '/:lang/:country/english/auth/restore_password',
        redirect: {name: 'RestorePassword'}
      },
      {
        path: '/:lang/:country/english/auto_join',
        redirect: {name: 'AutoJoin'}
      },
      {
        path: '/:lang/:country/norsk/auto_join',
        redirect: {name: 'AutoJoin'}
      },
      {
        path: '/:lang/:country',
        name: 'Landing',
        meta: {guestOnly: true},
        component: () => import('@/pages/shared_pages/sh_Landing')
      },
      {
        path: '/:lang/:country/force/logout',
        name: 'ForceLogout',
        component: () => import('@/pages/shared_pages/sh_ForceLogout')
      },
      {
        path: '/:lang/:country/sms',
        redirect: {name: 'SignIn'}
      },
      {
        path: '/:lang/:country/sms/:token',
        name: 'MagicLink',
        component: () => import('@/pages/shared_pages/sh_MagicLink')
      },
      {
        path: '/:lang/:country/norsk/sms/:token',
        redirect: {name: 'MagicLink'},
      },
      {
        path: '/:lang/:country/english/sms/:token',
        redirect: {name: 'MagicLink'},
      },
      {
        path: '/:lang/:country/system_log/:id',
        name: 'SystemLog',
        component: () => import('@/pages/shared_pages/system_log_pages/sh_SystemLog')
      },
      {
        path: '/:lang/:country/settings/notifications',
        name: 'PreferencesNotifications',
        component: () => import('@/pages/shared_pages/preferences/sh_NotificationSettings')
      },
      {
        path: '/:lang/:country/admin_chat/:id',
        meta: {adminChat: true, preventMobileAppRedirect: true},
        name: 'AdminChat',
        component: () => import('@/pages/shared_pages/admin_chat/sh_AdminChat')
      },
      ...bigMoveRedirects,
      authRoutes,
      wizardRoutes,
      buyerRoutes,
      managerRoutes,
      serverRoutes,
      openBookingRoutes,
      {
        path: '/:lang/:country/book-interpreter/:organization/success',
        name: 'SuccessOpenBookingInterpretation',
        meta: {guestOnly: true},
        component: () => import('@/pages/shared_pages/open_booking/sh_SuccessOpenBookingInterpretation')
      },
      {
        path: '/:lang/:country/order-translation/:organization/success',
        name: 'SuccessOpenBookingTranslation',
        meta: {guestOnly: true},
        component: () => import('@/pages/shared_pages/open_booking/sh_SuccessOpenBookingTranslation')
      },
      {
        path: '/:lang/:country/assignments/book_directly',
        name: 'PublicDirbook',
        component: () => import('@/pages/shared_pages/assignment_pages/sh_OpenDirbook')
      },
      {
        path: '/:lang/:country/accept_invitation/:token',
        name: 'AcceptInvite',
        component: () => import('@/pages/shared_pages/sh_AcceptInvite')
      },
      {
        path: '/:lang/:country/:int_country/:slug',
        name: 'PublicProfile',
        component: () => import('@/pages/shared_pages/profile/sh_PublicProfile')
      },
      {
        path: '/:lang/:country/auto_join',
        name: 'AutoJoin',
        component: () => import('@/pages/BuyerPages/enterprise/b_AutoJoin')
      },
      {
        path: '*',
        name: '404',
        component: () => import('@/pages/shared_pages/errors/404')
      }
    ]
  });
}
