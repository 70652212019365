export default () => {
  return {
    namespaced: true,
    state: {accessibility: 'private'},
    mutations: {
      setAccessibility(state, event) {
        state.accessibility = event.target.value;
      },
      clearStore(state) {
        state.accessibility = 'private';
      }
    }
  };
};
