export default () => {
  return {
    namespaced: true,
    state: {staffingJob: ''},
    mutations: {
      setStaffingJobInfo(state, data) {
        state.staffingJob = data;
      },
      clearStore(state) {
        state.staffingJob = '';
      }
    }
  };
};
