(<template>
  <div class="post-form__field confirmation-by-section">
    <sk-checkbox :item-text="confirmationByCheckboxDescription"
                 :preselected-value="confirmationBySelect"
                 class="confirmation-by-section__checkbox"
                 @checkboxvaluechanged="catchConfirmationBySelectState" />
    <template v-if="confirmationBySelect">
      <div class="post-form__field-group confirmation-by-section__cont">
        <sk-datepicker :preselected-value="date"
                       :default-value="'--/--/----'"
                       :full-calendar="false"
                       :prevent-call="true"
                       :description="dateText"
                       class="post-form__field confirmation-by-section__datepicker"
                       @changeday="setDeadlineDate" />
        <sk-timepicker :preselected-value="time"
                       :default-value="'--:--'"
                       :start-select-from="allowPastBooking ? '' : startTimeBeginning"
                       :description="timeSelectText"
                       :validation-name="deadlineTimeErrorName"
                       :time-step="$timepickerStep()"
                       :mob-numeric-keyboard="true"
                       class="post-form__field confirmation-by-section__timepicker"
                       @timepickervaluechanged="setDeadlineTime" />
      </div>
    </template>
  </div>
</template>)

<script>
  import helpers from '@/helpers';
  import {mapState, mapMutations, mapActions} from 'vuex';

  export default {
    computed: {
      ...mapState('PostingStore/ConfirmationByStore', {
        deadline: (state) => state.deadline,
        confirmationBySelect: (state) => state.confirmationBySelect,
        // error names
        deadlineTimeErrorName: (state) => state.errors.deadlineTime.name
      }),
      confirmationByCheckboxDescription() { return this.$gettext('When will the assignment be last confirmed?'); },
      dateText() { return this.$gettext('Date'); },
      timeSelectText() { return this.$gettext('Time'); },
      startTimeBeginning() { return this.date === this.$moment().format('YYYY-MM-DD') ? helpers.dateTime.convertCurrentTime(this.$moment) : ''; },
      date() { return this.deadline ? this.$moment(this.deadline).format('YYYY-MM-DD') : ''; },
      time() { return this.deadline ? this.$moment(this.deadline).format('HH:mm') : ''; },
      allowPastBooking() { return this.$isGodmode(); },
    },
    methods: {
      ...mapMutations('PostingStore/ConfirmationByStore', [
        'setDeadlineDate',
        'setDeadlineTime'
      ]),
      ...mapActions('PostingStore/ConfirmationByStore', ['catchConfirmationBySelectState'])
    }
  };
</script>

<style>
  .confirmation-by-section .sk-time-picker,
  .confirmation-by-section .sk-date-picker {
    background-color: #fff;
  }
</style>

<style scoped>
  .confirmation-by-section__cont {
    flex-wrap: nowrap;
    margin-top: 10px;
    margin-bottom: -15px;
  }

  .confirmation-by-section__datepicker {
    width: 100%;
    max-width: 100px;
  }

  .confirmation-by-section__timepicker {
    width: 100%;
    max-width: 70px;
  }
</style>
