export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      // interpretation page tabs
      interpretationTabs: {
        // --- assignable tabs ----- //
        urgent: {
          jobIds: [],
          managers: [],
          count: 0,
        },
        nextUp: {
          jobIds: [],
          managers: [],
          count: 0
        },
        otherPublished: {
          jobIds: [],
          managers: [],
          count: 0
        },
        vip: {
          jobIds: [],
          managers: [],
          count: 0
        },
        // --- Non assignable tabs ----- //
        allToday: {
          count: 0
        },
        allNextUp: {
          count: 0
        },
        all: {
          count: 0
        },
      },
      interpretationTabsCopy: {
        // --- assignable tabs ----- //
        urgent: {
          jobIds: [],
          managers: [],
          count: 0,
        },
        nextUp: {
          jobIds: [],
          managers: [],
          count: 0
        },
        otherPublished: {
          jobIds: [],
          managers: [],
          count: 0
        },
        vip: {
          jobIds: [],
          managers: [],
          count: 0
        },
        // --- Non assignable tabs ----- //
        allToday: {
          count: 0
        },
        allNextUp: {
          count: 0
        },
        all: {
          count: 0
        },
      },
      // translation page tabs
      translationTabs: {
        // --- assignable tabs ----- //
        thisWeek: {
          subtaskIds: [],
          managers: [],
          count: 0,
        },
        nextWeek: {
          subtaskIds: [],
          managers: [],
          count: 0
        },
        thisMonth: {
          subtaskIds: [],
          managers: [],
          count: 0
        },
        // --- Non assignable tabs ----- //
        all: {
          count: 0
        },
      },
      translationTabsCopy: {
        // --- assignable tabs ----- //
        thisWeek: {
          subtaskIds: [],
          managers: [],
          count: 0,
        },
        nextWeek: {
          subtaskIds: [],
          managers: [],
          count: 0
        },
        thisMonth: {
          subtaskIds: [],
          managers: [],
          count: 0
        },
        // --- Non assignable tabs ----- //
        all: {
          count: 0
        },
      },
      viewingManagers: {}
    },
    actions: {
      getManagerJobStats({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getManagerJobStats().then((data) => {
            commit('setManagerJobStats', data);
            commit('setCopyInterpretationStats', data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getManagerJobAssignments({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getManagerJobAssignments().then((data) => {
            commit('setManagerJobAssignments', data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      updateManagerJobAssignment({commit}, param) {
        return new Promise((resolve, reject) => {
          fetchData.updateManagerJobAssignment(param).then((data) => {
            commit('setManagerJobAssignments', data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getManagerJobActivity({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getManagerJobActivity().then((data) => {
            commit('setManagerJobActivity', data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      assignManagerToJobAssignment(context, data) {
        return new Promise((resolve, reject) => {
          fetchData.assignManagerToJobAssignment(data.id, data.payload).then((res) => {
            resolve(res);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      assignManagerToSingleSubtask(context, data) {
        return new Promise((resolve, reject) => {
          fetchData.assignManagerToSingleSubtask(data.id, data.payload).then((res) => {
            resolve(res);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getManagerSubtaskStats({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getManagerSubtaskStats().then((data) => {
            commit('setManagerSubtaskStats', data);
            commit('setCopySubtaskStats', data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getManagerSubtaskTabsAssignments({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getManagerSubtaskTabsAssignments().then((data) => {
            commit('setManagerSubtaskTabsAssignments', data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      updateManagerSubtaskTabsAssignment({commit}, param) {
        return new Promise((resolve, reject) => {
          fetchData.updateManagerSubtaskTabsAssignment(param).then((data) => {
            commit('setManagerSubtaskTabsAssignments', data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
    },
    mutations: {
      setManagerJobStats(state, data) {
        // assignable tabs
        state.interpretationTabs.urgent.jobIds = data.urgentIds ? data.urgentIds.split(',') : [];
        state.interpretationTabs.urgent.count = data.urgent;
        state.interpretationTabs.otherPublished.jobIds = data.otherPublishedIds ? data.otherPublishedIds.split(',') : [];
        state.interpretationTabs.otherPublished.count = data.otherPublished;
        state.interpretationTabs.nextUp.jobIds = data.nextUpIds ? data.nextUpIds.split(',') : [];
        state.interpretationTabs.nextUp.count = data.nextUp;
        state.interpretationTabs.vip.jobIds = data.vipIds ? data.vipIds.split(',') : [];
        state.interpretationTabs.vip.count = data.vip;

        // Non-assignable tabs
        state.interpretationTabs.allToday.count = data.allToday;
        state.interpretationTabs.allNextUp.count = data.allNextUp;
        state.interpretationTabs.all.count = data.all;
      },
      setManagerJobAssignments(state, data) {
        state.interpretationTabs.urgent.managers = data.urgent ? data.urgent.split(',') : [];
        state.interpretationTabs.otherPublished.managers = data.otherPublished ? data.otherPublished.split(',') : [];
        state.interpretationTabs.nextUp.managers = data.nextUp ? data.nextUp.split(',') : [];
        state.interpretationTabs.vip.managers = data.vip ? data.vip.split(',') : [];
      },
      setCopyInterpretationStats(state, data) {
        state.interpretationTabsCopy.urgent.jobIds = data.urgentIds ? data.urgentIds.split(',') : [];
        state.interpretationTabsCopy.urgent.count = data.urgent;
        state.interpretationTabsCopy.otherPublished.jobIds = data.otherPublishedIds ? data.otherPublishedIds.split(',') : [];
        state.interpretationTabsCopy.otherPublished.count = data.otherPublished;
        state.interpretationTabsCopy.nextUp.jobIds = data.nextUpIds ? data.nextUpIds.split(',') : [];
        state.interpretationTabsCopy.nextUp.count = data.nextUp;
        state.interpretationTabsCopy.vip.jobIds = data.vipIds ? data.vipIds.split(',') : [];
        state.interpretationTabsCopy.vip.count = data.vip;

        // Non-assignable tabs
        state.interpretationTabsCopy.allToday.count = data.allToday;
        state.interpretationTabsCopy.allNextUp.count = data.allNextUp;
        state.interpretationTabsCopy.all.count = data.all;
      },
      setCopyUrgentStats(state, data) {
        state.interpretationTabsCopy.urgent.jobIds = data.ids;
        state.interpretationTabsCopy.urgent.count = data.count;
      },
      setCopyNextUpStats(state, data) {
        state.interpretationTabsCopy.nextUp.jobIds = data.ids;
        state.interpretationTabsCopy.nextUp.count = data.count;
      },
      setCopyOtherPublishedStats(state, data) {
        state.interpretationTabsCopy.otherPublished.jobIds = data.ids;
        state.interpretationTabsCopy.otherPublished.count = data.count;
      },
      setCopyVipStats(state, data) {
        state.interpretationTabsCopy.vip.jobIds = data.ids;
        state.interpretationTabsCopy.vip.count = data.count;
      },
      setManagerJobActivity(state, data) {
        state.viewingManagers = data;
      },
      setManagerSubtaskStats(state, data) {
        // assignable tabs
        state.translationTabs.thisWeek.subtaskIds = data.thisWeekIds ? data.thisWeekIds.split(',') : [];
        state.translationTabs.thisWeek.count = data.thisWeek;
        state.translationTabs.nextWeek.subtaskIds = data.nextWeekIds ? data.nextWeekIds.split(',') : [];
        state.translationTabs.nextWeek.count = data.nextWeek;
        state.translationTabs.thisMonth.subtaskIds = data.thisMonthIds ? data.thisMonthIds.split(',') : [];
        state.translationTabs.thisMonth.count = data.thisMonth;

        // Non-assignable tabs
        state.translationTabs.all.count = data.all;
      },
      setCopySubtaskStats(state, data) {
        // assignable tabs
        state.translationTabsCopy.thisWeek.subtaskIds = data.thisWeekIds ? data.thisWeekIds.split(',') : [];
        state.translationTabsCopy.thisWeek.count = data.thisWeek;
        state.translationTabsCopy.nextWeek.subtaskIds = data.nextWeekIds ? data.nextWeekIds.split(',') : [];
        state.translationTabsCopy.nextWeek.count = data.nextWeek;
        state.translationTabsCopy.thisMonth.subtaskIds = data.thisMonthIds ? data.thisMonthIds.split(',') : [];
        state.translationTabsCopy.thisMonth.count = data.thisMonth;

        // Non-assignable tabs
        state.translationTabsCopy.all.count = data.all;
      },
      setManagerSubtaskTabsAssignments(state, data) {
        state.translationTabs.thisWeek.managers = data.thisWeek ? data.thisWeek.split(',') : [];
        state.translationTabs.nextWeek.managers = data.nextWeek ? data.nextWeek.split(',') : [];
        state.translationTabs.thisMonth.managers = data.thisMonth ? data.thisMonth.split(',') : [];
        state.translationTabs.all.managers = data.all ? data.all.split(',') : [];
      },
      setCopyThisWeekStats(state, data) {
        state.translationTabsCopy.thisWeek.subtaskIds = data.ids;
        state.translationTabsCopy.thisWeek.count = data.count;
      },
      setCopyNextWeekStats(state, data) {
        state.translationTabsCopy.nextWeek.subtaskIds = data.ids;
        state.translationTabsCopy.nextWeek.count = data.count;
      },
      setThisMonthStats(state, data) {
        state.translationTabsCopy.thisMonth.subtaskIds = data.ids;
        state.translationTabsCopy.thisMonth.count = data.count;
      },
      clearStore(state) {
        state.interpretationTabs = {
          urgent: {
            jobIds: [],
              managers: [],
              count: 0,
          },
          nextUp: {
            jobIds: [],
              managers: [],
              count: 0
          },
          otherPublished: {
            jobIds: [],
              managers: [],
              count: 0
          },
          vip: {
            jobIds: [],
              managers: [],
              count: 0
          },
          allToday: {
            count: 0
          },
          allNextUp: {
            count: 0
          },
          all: {
            count: 0
          },
        };
        state.interpretationTabsCopy = {
          // --- assignable tabs ----- //
          urgent: {
            jobIds: [],
              managers: [],
              count: 0,
          },
          nextUp: {
            jobIds: [],
              managers: [],
              count: 0
          },
          otherPublished: {
            jobIds: [],
              managers: [],
              count: 0
          },
          vip: {
            jobIds: [],
              managers: [],
              count: 0
          },
          // --- Non assignable tabs ----- //
          allToday: {
            count: 0
          },
          allNextUp: {
            count: 0
          },
          all: {
            count: 0
          },
        };
        state.translationTabs = {
          thisWeek: {
            subtaskIds: [],
            managers: [],
            count: 0,
          },
          nextWeek: {
            subtaskIds: [],
            managers: [],
            count: 0
          },
          thisMonth: {
            subtaskIds: [],
            managers: [],
            count: 0
          },
          all: {
            count: 0
          },
        };
        state.translationTabsCopy = {
          thisWeek: {
            subtaskIds: [],
            managers: [],
            count: 0,
          },
          nextWeek: {
            subtaskIds: [],
            managers: [],
            count: 0
          },
          thisMonth: {
            subtaskIds: [],
            managers: [],
            count: 0
          },
          all: {
            count: 0
          },
        };
        state.viewingManagers = {};
      }
    }
  };
};
