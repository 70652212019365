import Vue from 'vue';

export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      profileData: '',
      settings: '',
      modalProfileData: '',
      // interpreter profile data
      tools: [],
      experiences: [],
      translationMethods: [],
      publicFeedbacks: ''
    },
    getters: {
      getPersonInfoModalData(state) {
        return state.profileData ? {
          gender: state.profileData.person.sex || '',
          dob: state.profileData.person.dob || '',
          email: state.profileData.person.email || '',
          phoneCode: state.profileData.person.phone.code || '+47',
          phoneNumber: state.profileData.person.phone.number || '',
          tolkId: state.profileData.tolkId || '',
          alternativePhoneCode: state.profileData.person?.alternativePhone?.code || '+47',
          alternativePhoneNumber: state.profileData.person?.alternativePhone?.number || '',
          line1: state.profileData.address ? state.profileData.address.line_1 || '' : '',
          line2: state.profileData.address ? state.profileData.address.line_2 || '' : '',
          county: state.profileData.address ? state.profileData.address.county || '' : '',
          postcode: state.profileData.address ? state.profileData.address.postcode || '' : '',
          city: state.profileData.address ? state.profileData.address.city || '' : '',
          country: state.profileData.address ? state.profileData.address.country || '' : '',
          longitude: state.profileData.address ? state.profileData.address.longitude || '' : '',
          latitude: state.profileData.address ? state.profileData.address.latitude || '' : ''
        } : {};
      },
      getUserRole(state) {
        return state.profileData ? state.profileData.userRole || '' : '';
      },
      getLanguageAssistant(state) {
        return state.profileData ? state.profileData.languageAssistant || '' : '';
      },
      supplierAllowedSessionTypes(state) {
        if (state.profileData?.sessionTypes) {
          const interpreterSessionTypes = state.profileData?.sessionTypes || [];
          return interpreterSessionTypes.filter((type) => {
            return type && type.checked && ['active', 'paused'].includes(type.status);
          }) || [];
        } else {
          return [];
        }
      },
      isProactiveUser(state) {
        return state.profileData.proactiveUser;
      }
    },
    actions: {
      getUserProfile({commit}, {id, country, slug}) {
        return new Promise((resolve, reject) => {
          fetchData.getUserProfile({id, country, slug}).then((data) => {
            if (data.code == 404) {
              commit('GlobalStore/set404Page', '', {root: true});
              resolve();
              return;
            }
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setUserProfile', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      getModalProfile({commit}, {id, country, slug}) {
        return new Promise((resolve, reject) => {
          fetchData.getUserProfile({id, country, slug}).then((data) => {
            if (data.code == 401 || data.code == 404) {
              commit('GlobalStore/set404Page', '', {root: true});
              resolve();
              return;
            }
            commit('setModalProfileData', data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      updateUserProfile({commit, rootGetters}, {id, form}) {
        return new Promise((resolve, reject) => {
          fetchData.updateUserProfile(id, form, rootGetters['UserInfoStore/currentRole']).then((data) => {
            commit('setUserProfile', data);
            const obj = data.interpreter || data.buyer || data.manager;
            if (obj.person.avatar !== rootGetters['UserInfoStore/userAvatar']) {
              commit('UserInfoStore/setUserInfo', {userInfoObj: {avatar: obj.person.avatar}}, {root: true});
            }
            if (obj.person.phone.code !== rootGetters['UserInfoStore/userPhone'].code
              || obj.person.phone.number !== rootGetters['UserInfoStore/userPhone'].number) {
              commit('UserInfoStore/setUserInfo', {
                userInfoObj: {
                  phone: {
                    code: obj.person.phone.code,
                    number: obj.person.phone.number,
                    verified: obj.person.phone.verified
                  },
                }
              }, {root: true});
            }
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      changePublicFeedbackStatus(context, {feedbackId, status}) {
        return new Promise((resolve, reject) => {
          fetchData.changePublicFeedbackStatus(feedbackId, {status}).then((data) => {
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      addSchool({commit}, {id, form}) {
        return new Promise((resolve, reject) => {
          fetchData.addSchool(id, form).then((data) => {
            commit('setSchool', data);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      editSchool({commit}, {id, form, schoolId}) {
        return new Promise((resolve, reject) => {
          fetchData.editSchool(id, form, schoolId).then((data) => {
            commit('setSchool', data);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      removeSchool({commit, rootGetters}, id) {
        return new Promise((resolve, reject) => {
          fetchData.removeSchool(rootGetters['UserInfoStore/userUid'], id).then(() => {
            commit('deleteSchool', id);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      addTools({dispatch}, {id, params}) {
        return new Promise((resolve, reject) => {
          fetchData.addTools(id, params).then((data) => {
            dispatch('EditProfileStore/getTools', id, {root: true});
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      getTools({commit}, id) {
        return new Promise((resolve, reject) => {
          fetchData.getTools(id).then((data) => {
            commit('setTools', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      removeTool({commit, rootGetters}, id) {
        return new Promise((resolve, reject) => {
          fetchData.removeTool(rootGetters['UserInfoStore/userUid'], id).then(() => {
            commit('deleteTool', id);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      getExperiences({commit}, id) {
        return new Promise((resolve, reject) => {
          fetchData.getExperiences(id).then((data) => {
            commit('setExperiences', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      addExperience({dispatch}, {uid, payload}) {
        return new Promise((resolve, reject) => {
          fetchData.addExperience(uid, payload).then((data) => {
            dispatch('EditProfileStore/getExperiences', uid, {root: true});
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      editExperience({dispatch}, {uid, payload, experienceId}) {
        return new Promise((resolve, reject) => {
          fetchData.editExperience(uid, payload, experienceId).then((data) => {
            dispatch('EditProfileStore/getExperiences', uid, {root: true});
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      removeExperience({dispatch}, {uid, experienceId}) {
        return new Promise((resolve, reject) => {
          fetchData.removeExperience(uid, experienceId).then((data) => {
            dispatch('EditProfileStore/getExperiences', uid, {root: true});
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      updateAreas({commit}, {id, form}) {
        return new Promise((resolve, reject) => {
          fetchData.updateAreas(id, form).then((data) => {
            commit('setAreas', data);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      getInterpreterFeedback({commit}, {id, params, country, slug}) {
        return new Promise((resolve, reject) => {
          if (slug) {
            fetchData.getInterpreterFeedbackLogout({country, params, slug}).then((data) => {
              commit('setInterpreterFeedback', data);
              resolve(data);
            }).catch((response) => {
              reject(response);
            });
          } else {
            fetchData.getInterpreterFeedback(id, params).then((data) => {
              commit('setInterpreterFeedback', data);
              resolve(data);
            }).catch((response) => {
              reject(response);
            });
          }
        });
      },
      updateTypes({commit}, {id, form}) {
        return new Promise((resolve, reject) => {
          fetchData.updateTypes(id, form).then((data) => {
            commit('setTypes', data);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      updateMethods({commit}, {id, form}) {
        return new Promise((resolve, reject) => {
          fetchData.updateMethods(id, form).then((data) => {
            commit('setMethods', data);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      updateTranslationMethods({commit}, payload) {
        return new Promise((resolve, reject) => {
          fetchData.updateTranslationMethods(payload).then((data) => {
            commit('setTranslationMethods', data);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      // createSkill({commit}, {id, form}) {
      //   return new Promise((resolve, reject) => {
      //     fetchData.createSkill(id, form).then((data) => {
      //       commit('setSkill', data);
      //       resolve();
      //     }).catch((response) => {
      //       reject(response);
      //     });
      //   });
      // },
      // updateSkill({commit}, {id, form, skillId}) {
      //   return new Promise((resolve, reject) => {
      //     fetchData.updateSkill(id, form, skillId).then((data) => {
      //       commit('setSkill', data);
      //       resolve();
      //     }).catch((response) => {
      //       reject(response);
      //     });
      //   });
      // },
      // removeSkill({commit, rootGetters}, id) {
      //   return new Promise((resolve, reject) => {
      //     fetchData.removeSkill(rootGetters['UserInfoStore/userUiduserUid'], id).then(() => {
      //       commit('deleteSkill', id);
      //       resolve();
      //     }).catch((response) => {
      //       reject(response);
      //     });
      //   });
      // },
      // sendCertificate({rootGetters}, file) {
      //   return new Promise((resolve, reject) => {
      //     fetchData.sendCertificate(rootGetters['UserInfoStore/userUid'], file).then((data) => {
      //       resolve(data);
      //     }).catch((response) => {
      //       reject(response);
      //     });
      //   });
      // },
      getSettings({commit, rootGetters}, id) {
        return new Promise((resolve, reject) => {
          fetchData.getSettings(id || rootGetters['UserInfoStore/userUid']).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setSettings', data);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      updateSettings({commit, rootGetters}, form) {
        return new Promise((resolve, reject) => {
          fetchData.updateSettings(rootGetters['UserInfoStore/userUid'], form).then((data) => {
            commit('setSettings', data);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      checkAvailableSlug(context, slug) {
        return new Promise((resolve, reject) => {
          fetchData.checkAvailableSlug(slug).then((data) => {
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      changeSlug({commit}, form) {
        return new Promise((resolve, reject) => {
          fetchData.changeSlug(form).then((data) => {
            commit('setSlug', data.slug);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      createFavouriteForm(context, interId) {
        return new Promise((resolve) => {
          const form = new FormData();

          form.append('relation[interpreter]', interId);
          form.append('relation[attitude_type]', 'favourite');

          resolve(form);
        });
      },
      sendUpdatedRelation({commit}, {form, relation}) {
        return new Promise((resolve, reject) => {
          fetchData.sendUpdatedRelation(form).then(() => {
            commit('setRelation', relation);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      removeRelation({commit}, id) {
        return new Promise((resolve, reject) => {
          fetchData.removeRelation(id).then(() => {
            commit('deleteRelation');
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      fetchExpertiseAreas({commit}, locale) {
        return new Promise((resolve, reject) => {
          fetchData.fetchExpertiseAreas(locale).then((data) => {
            commit('setAreas', data);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      fetchInterpretationMethods({commit}, locale) {
        return new Promise((resolve, reject) => {
          fetchData.fetchInterpretationMethods(locale).then((data) => {
            commit('setMethods', data);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      fetchTranslationMethods({commit}, locale) {
        return new Promise((resolve, reject) => {
          fetchData.fetchTranslationMethods(locale).then((data) => {
            commit('setTranslationMethods', data);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      deleteCurrentUserAccount({commit, rootGetters}) {
        return new Promise((resolve, reject) => {
          fetchData.deleteCurrentUserAccount(rootGetters['UserInfoStore/userUid']).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      }
    },
    mutations: {
      setSettings(state, data) {
        state.settings = data;
      },
      setSlug(state, slug) {
        state.settings.setting.slug = slug;
      },
      setRelation(state, relation) {
        state.profileData ? Vue.set(state.profileData, 'relation', relation) : '';
      },
      deleteRelation(state) {
        state.profileData ? state.profileData.relation = '' : '';
      },
      setUserProfile(state, data) {
        if (data.interpreter) {
          state.profileData = data.interpreter;
          state.profileData.translator = data.translator;
          state.profileData.userRole = 'server';
          state.profileData.proactiveUser = data?.translator?.isProactive;
        } else if (data.buyer) {
          state.profileData = data.buyer;
          state.profileData.userRole = 'buyer';
          // response for managers on GET wrapped with person on PUT response wrapper with managers
        } else if (data.person?.role === 'manager' || data.manager) {
          state.profileData = data.manager ? data.manager : data;
          state.profileData.userRole = 'manager';
        }
        if (state.profileData && state.profileData.person && state.profileData.person.sex === 0) {
          state.profileData.person.sex = String(state.profileData.person.sex);
        }
      },
      setModalProfileData(state, data) {
        if (data.interpreter) {
          state.modalProfileData = data.interpreter;
          state.modalProfileData.userRole = 'server';
        } else {
          state.modalProfileData = data.buyer;
          state.modalProfileData.userRole = 'buyer';
        }
        if (state.modalProfileData && state.modalProfileData.person && state.modalProfileData.person.sex === 0) {
          state.modalProfileData.person.sex = String(state.modalProfileData.person.sex);
        }
      },
      WSUpdateUserProfile(state, {userInfoObj}) {
        if (userInfoObj.firstName !== undefined) {
          state.profileData.person.firstName = userInfoObj.firstName;
        }
        if (userInfoObj.lastName !== undefined) {
          state.profileData.person.lastName = userInfoObj.lastName;
        }
        if (userInfoObj.avatar !== undefined) {
          state.profileData.person.avatar = userInfoObj.avatar;
        }
        if (userInfoObj.email !== undefined) {
          state.profileData.person.email = userInfoObj.email;
        }
        if (userInfoObj.uid !== undefined) {
          state.profileData.person.uid = userInfoObj.uid;
        }
        if (userInfoObj.jobEntity !== undefined) {
          state.profileData.person.jobEntity = userInfoObj.jobEntity;
        }
        if (userInfoObj.jobEntityId !== undefined) {
          state.profileData.person.jobEntityId = userInfoObj.jobEntityId;
        }
        if (userInfoObj.phone) {
          state.profileData.person.phone = JSON.parse(JSON.stringify(userInfoObj.phone));
        }
        if (userInfoObj.alternativePhone) {
          state.profileData.person.alternativePhone = JSON.parse(JSON.stringify(userInfoObj.alternativePhone));
        }
        if (userInfoObj.phoneVerified) {
          state.profileData.person.phone.verified = true;
        }
      },
      setSchool(state, school) {
        for (let i = 0, lim = state.profileData.educations.length; i < lim; i += 1) {
          if (state.profileData.educations[i].id == school.id) {
            state.profileData.educations.splice(i, 1, school);
            return;
          }
        }
        state.profileData.educations.push(school);
      },
      deleteSchool(state, id) {
        for (let i = 0, lim = state.profileData.educations.length; i < lim; i += 1) {
          if (state.profileData.educations[i].id == id) {
            state.profileData.educations.splice(i, 1);
            return;
          }
        }
      },
      setTools(state, tools) {
        state.tools = tools;
      },
      deleteTool(state, id) {
        for (let i = 0, lim = state.profileData.tools.length; i < lim; i += 1) {
          if (state.profileData.tools[i].id == id) {
            state.profileData.tools.splice(i, 1);
            return;
          }
        }
      },
      setExperiences(state, experiences) {
        state.experiences = experiences;
      },
      setSkill(state, skill) {
        if (state.profileData && state.profileData.skills) {
          for (let i = 0, lim = state.profileData.skills.length; i < lim; i += 1) {
            if (state.profileData.skills[i].id == skill.id) {
              state.profileData.skills.splice(i, 1, skill);
              return;
            }
          }
          state.profileData.skills.push(skill);
        }
      },
      setTranslationSkill(state, skill) {
        if (state.profileData && state.profileData.translator?.skills) {
          const len = state.profileData.translator?.skills.length;
          for (let i = 0, lim = len; i < lim; i += 1) {
            if (state.profileData.translator.skills[i].id == skill.id) {
              state.profileData.translator.skills.splice(i, 1, skill);
              return;
            }
          }
          state.profileData.translator.skills.push(skill);
        }
      },
      deleteSkill(state, id) {
        if (state.profileData && state.profileData.skills) {
          for (let i = 0, lim = state.profileData.skills.length; i < lim; i += 1) {
            if (state.profileData.skills[i].id == id) {
              state.profileData.skills.splice(i, 1);
              return;
            }
          }
        }
      },
      deleteTranslationSkill(state, id) {
        if (state.profileData && state.profileData.translator?.skills) {
          for (let i = 0, lim = state.profileData.translator.skills.length; i < lim; i += 1) {
            if (state.profileData.translator.skills[i].id == id) {
              state.profileData.translator.skills.splice(i, 1);
              return;
            }
          }
        }
      },
      setAreas(state, data) {
        if (state.profileData) {
          state.profileData.expertiseAreas = data.expertiseAreas;
        }
      },
      setMethods(state, data) {
        if (state.profileData) {
          state.profileData.interpretationMethods = data.interpretationMethods;
        }
      },
      setTranslationMethods(state, data) {
        if (state.profileData.translator) {
          state.profileData.translator.translationMethods = data.translationMethods;
        }
      },
      setInterpreterFeedback(state, data) {
        state.publicFeedbacks = data;
      },
      setTypes(state, data) {
        state.profileData.sessionTypes = data.sessionTypes;
      },
      removeUserProfile(state) {
        state.profileData = '';
      },
      removeModalProfileData(state) {
        state.modalProfileData = '';
      },
      removeSettings(state) {
        state.settings = '';
      },
      removeTools(state) {
        state.tools = '';
      },
      removeExperiences(state) {
        state.experiences = [];
      },
      setLanguageAssistant(state, value) {
        state.profileData.languageAssistant = value;
      },
      clearStore(state) {
        state.profileData = '';
        state.modalProfileData = '';
        state.settings = '';
        state.tools = [];
        state.experiences = [];
        state.translationMethods = [];
        state.publicFeedbacks = '';
      }
    }
  };
};
