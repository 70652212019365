(<template>
  <section class="inter-info post-form__field">
    <router-link :to="compLink"
                 class="inter-info__link">
      <tt-avatar :avatar="avatar"
                 class="inter-info__avatar" />
    </router-link>
    <div class="inter-info__name-wrapper">
      <router-link :to="compLink">
        <p class="inter-info__name">{{ interpreterName }}</p>
      </router-link>
      <p class="inter-info__location">{{ computedGender }} {{ computedAddress }}</p>
    </div>
  </section>
</template>)

<script>
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('EditProfileStore', {
        person: (state) => {
          return state.profileData && state.profileData.person ? state.profileData.person : '';
        },
        address: (state) => {
          return state.profileData && state.profileData.address ? state.profileData.address : '';
        }
      }),
      compLink() {
        return this.interpreterUid ? this.$makeRoute({name: 'BuyerUserProfile', params: {id: this.interpreterUid}}) : '';
      },
      interpreterUid() {
        return this.person && this.person.uid ? this.person.uid : '';
      },
      avatar() {
        return this.person && this.person.avatar ? this.person.avatar : '';
      },
      interpreterName() {
        return this.person ? `${this.person.firstName || ''} ${this.person.lastName || ''}` : '';
      },
      gender() {
        return this.person ? this.person.sex : '';
      },
      city() {
        return this.address && this.address.city ? this.address.city : '';
      },
      country() {
        return this.address && this.address.country ? this.address.country : '';
      },
      computedAddress() {
        if (this.city || this.country) {
          if (this.city && !this.country) {
            return `${this.city}`;
          } else if (!this.city && this.country) {
            return `${this.country}`;
          } else if ((this.city && this.country)) {
            return `${this.city}, ${this.country}`;
          }
        } else {
          return '';
        }
      },
      computedGender() {
        let gender = '';
        if ((this.gender || this.gender === 0) && this.$helperData.getSelectGenders(this) && this.$helperData.getSelectGenders(this).length) {
          for (const g of this.$helperData.getSelectGenders(this)) {
            if (this.gender == g.id) {
              gender = g.name;
            }
          }
        }
        if (this.city || this.country) {
          return `${gender} |`;
        } else {
          return gender;
        }
      }
    }
  };
</script>

<style scoped>
  .inter-info {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
  }

  .inter-info__avatar {
    flex-shrink: 0;
    width: 60px;
    height: 60px;
  }

  .inter-info__name-wrapper {
    display: block;
    flex-grow: 1;
    padding-left: 10px;
  }

  .inter-info__link {
    border-radius: 50%;
  }

  .inter-info__name {
    display: inline-block;
    width: auto;
    font-weight: bold;
    font-size: 18px;
  }

  .inter-info__location {
    display: block;
    width: 100%;
    font-size: 10px;
  }

  @media (max-width: 767px) {
    .inter-info {
      padding-top: 20px;
    }
  }
</style>
