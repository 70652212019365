export default ({$moment: moment}) => {
  return {
    namespaced: true,
    getters: {
      bookingFormProgress(state, getters, rootState) {
        const {departmentsProgress, attendersProgress} = rootState.PostingStore.DepartmentAttendersStore;
        return departmentsProgress || attendersProgress;
      }
    },
    actions: {
      createForm({rootState, dispatch, rootGetters}) {
        return new Promise((resolve) => {
          rootState.PostingStore.AssignmentTypeStore.assignmentType = 'phone';
          dispatch('PostInterpretationStore/createForm', null, {root: true})
            .then((form) => {
              const deadline = rootState.PostingStore.DeadlineStore.deadline;
              const defaultJobTypeParams = rootGetters['UserInfoStore/userAssignmentTypeDuration'].find((type) => type.name == 'phone');
              const minJobDuration = defaultJobTypeParams ? defaultJobTypeParams.minDuration : null;
              const defaultJobDuration = rootGetters['UserInfoStore/defaultJobDuration'];
              const duration = minJobDuration > defaultJobDuration ? minJobDuration : defaultJobDuration;
              const startTime = moment(deadline).subtract(duration, 'minutes');
              const customerWantCallback = rootState.PostingStore.SecretaryStore.secretaryCallbackPhoneSelect;

              form.set('job[interpretation_requirement][start_time]', startTime);
              form.set('job[interpretation_requirement][finish_time]', deadline);
              form.append('job[interpretation_requirement][deadline]', deadline);
              form.append('job[interpretation_requirement][job_type]', 'secretary');
              form.append('job[interpretation_requirement][session_type]', rootState.PostingStore.AssignmentTypeStore.assignmentType);
              form.append('job[info][secretary_contact_name]', rootState.PostingStore.SecretaryStore.secretaryContactName);
              form.append('job[info][secretary_contact_phone_number]', rootState.PostingStore.SecretaryStore.secretaryContactPhone);
              form.append('job[info][secretary_contact_phone_code]', rootState.PostingStore.SecretaryStore.secretaryContactPhoneCode);
              form.append('job[info][secretary_info]', rootState.PostingStore.SecretaryStore.secretaryInfo);
              if (customerWantCallback) {
                form.append('job[info][secretary_callback_phone_number]', rootState.PostingStore.SecretaryStore.secretaryCallbackPhone);
                form.append('job[info][secretary_callback_phone_code]', rootState.PostingStore.SecretaryStore.secretaryCallbackPhoneCode);
              }
              resolve(form);
            });
        });
      },
      createJob({rootState, dispatch}) {
        return new Promise((resolve, reject) => {
          dispatch('createForm')
            .then((form) => {
              const params = {
                supportMode: rootState.TTAuthStore.godmode,
                jobForm: form
              };
              dispatch('postAnAssignment', params, {root: true})
                .then((job) => {
                  resolve(job);
                })
                .catch((error) => {
                  reject(error);
                });
            });
        });
      },
      editJob({rootState, dispatch}) {
        return new Promise((resolve, reject) => {
          dispatch('createForm')
            .then((form) => {
              const params = {
                supportMode: rootState.TTAuthStore.godmode,
                jobForm: form,
                id: rootState.PostInterpretationStore.job.job_id
              };
              dispatch('editAssignment', params, {root: true})
                .then((job) => {
                  resolve(job);
                })
                .catch((error) => {
                  reject(error);
                });
            });
        });
      },
      clearPersonalInfoAndInvoiceStores({commit}) {
        commit('PostingStore/BookerStore/clearStore', null, {root: true});
        commit('PostingStore/DepartmentAttendersStore/clearStore', null, {root: true});
        commit('PostingStore/BookingRefStore/clearStore', null, {root: true});
        commit('PostingStore/CaseNumberStore/clearStore', null, {root: true});
        commit('PostingStore/ClientStore/clearStore', null, {root: true});
        commit('PostingStore/PaymentMethodStore/clearStore', null, {root: true});
        commit('PostingStore/AssignmentTypeStore/clearContactAddresses', null, {root: true});
        commit('PostingStore/OwnerRealNameStore/clearStore', null, {root: true});
        commit('EnterpriseStore/clearStore', null, {root: true});
      },
      clearAssignmentInfoStores({commit}) {
        commit('PostingStore/SecretaryStore/clearStore', null, {root: true});
        commit('EditProfileStore/clearStore', null, {root: true});
        commit('PostingStore/DateTimeStore/clearStore', null, {root: true});
        commit('PostingStore/AssignmentTypeStore/clearStore', null, {root: true});
        commit('PostingStore/AutoInviteStore/clearStore', null, {root: true});
        commit('PostingStore/AutoApproveStore/clearStore', null, {root: true});
        commit('PostingStore/DeadlineStore/clearStore', null, {root: true});
        commit('PostingStore/LanguagesStore/clearStore', null, {root: true});
        commit('PostingStore/SecretaryStore/clearStore', null, {root: true});
        commit('PostingStore/SubjectStore/clearStore', null, {root: true});
      },
      clearStore({dispatch}) {
        dispatch('clearPersonalInfoAndInvoiceStores');
        dispatch('clearAssignmentInfoStores');
      }
    }
  };
};
