<template>
  <div class="basic-job-info-section__wrapper">
    <table class="basic-job-info-section">
      <tr>
        <th v-for="(column, idx) in columns"
            :key="idx">{{ column }}</th>
      </tr>
      <tr>
        <td class="basic-job-info-section__job-id">{{ jobId }}</td>
        <td class="basic-job-info-section__duration">
          <c-start-time :data="startTimeData" />
        </td>
        <td class="basic-job-info-section__created-at">
          <c-created-at :data="createdAtData" />
        </td>
        <td class="basic-job-info-section__owner">
          <c-person-info :data="ownerInfoData" />
        </td>
        <td class="basic-job-info-section__languages">
          <c-language-pair :data="languagePairInfo" />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
  import CStartTime from '@/components/grid/cells/job/CStartTime';
  import CPersonInfo from '@/components/grid/cells/shared/CPersonInfo';
  import CLanguagePair from '@/components/grid/cells/job/CLanguagePair';
  import {mapState} from 'vuex';
  import CCreatedAt from '@/components/grid/cells/job/CCreatedAt';

  export default {
    components: {CStartTime, CCreatedAt, CPersonInfo, CLanguagePair},
    computed: {
      ...mapState('OneAssignmentStore', {
        storeJobObj: (state) => state.storeJobObj || {},
        managerJobObj: (state) => state.managerJobObj || {}
      }),
      jobId() { return this.storeJobObj.id || ''; },
      interpretationRequirements() { return this.storeJobObj.interpretationRequirement || {}; },
      startTime() { return this.storeJobObj.interpretationRequirement.startTime; },
      finishTime() { return this.storeJobObj.interpretationRequirement.finishTime; },
      actualCreatedAt() { return this.storeJobObj.actualCreatedAt; },
      createdAt() { return this.managerJobObj.createdAt; },
      bookingMechanism() { return this.storeJobObj.bookingMechanism; },
      columns() {
        return [
          this.$gettext('ID'),
          this.$gettext('Start time'),
          this.$gettext('Created at'),
          this.$gettext('Owner & Participants'),
          this.$gettext('Language Pair'),
        ];
      },
      startTimeData() {
        return {
          classes: ['start-time--narrow'],
          startTime: this.startTime,
          finisTime: this.finisTime,
          noActionOnClick: true
        };
      },
      createdAtData() {
        return {
          bookingMechanism: this.bookingMechanism,
          actualCreatedAt: this.actualCreatedAt,
          createdAt: this.createdAt,
          hideHintBtn: true,
          noActionOnClick: true
        };
      },
      ownerInfoData() {
        return {
          owner: this.managerJobObj.owner,
          ownerEmail: this.managerJobObj.ownerEmail,
          ownerPhone: this.managerJobObj.ownerPhone,
          participantsCount: this.managerJobObj.participantsCount,
          enterprise: this.managerJobObj.enterprise,
          department: this.managerJobObj.department,
          noActionOnClick: true
        };
      },
      languagePairInfo() {
        return {
          langToId: this.managerJobObj.langToId,
          langFromId: this.managerJobObj.langFromId,
          alternativeLangToId: this.managerJobObj.alternativeLangToId,
          alternativeLanguageProcessing: this.managerJobObj.alternativeLanguageProcessing,
          alternativeLanguageActivated: this.managerJobObj.alternativeLanguageActivated,
          noActionOnClick: true
        };
      },
      isMobileView() { return this.$store.state.GlobalStore.clientWidth < 768; }
    },
    watch: {
      isMobileView(newValue) { if (newValue) this.setMobileTable(); }
    },
    methods: {
      setMobileTable() {
        // if (window.innerWidth > 600) return false;
        const tableEl = document.querySelector('table');
        const thEls = tableEl.querySelectorAll('th');
        const tdLabels = Array.from(thEls).map((el) => el.innerText);
        tableEl.querySelectorAll('tr').forEach((tr) => {
          Array.from(tr.children).forEach(
            (td, ndx) => td.setAttribute('label', tdLabels[ndx])
          );
        });
      }
    },
  };
</script>

<style>
.start-time--narrow .start-time__cont {
  padding: 5px 10px;
}
</style>

<style scoped>
.basic-job-info-section__wrapper {
  margin-bottom: 35px;
}

.basic-job-info-section {
  width: 100%;
  border-radius: 8px;
  background-color: #f4f5f7;
}

.basic-job-info-section__owner {
  max-width: 200px;
}

.basic-job-info-section__duration {
  padding-left: 0;
}

table {
  width: 100%;
}

th {
  vertical-align: middle;
  padding: 10px;
  border-collapse: collapse;
  border-bottom: 1px solid #e1dede;
  color: #636161;
  font-weight: normal;
  text-align: left;
}

td {
  padding: 2px 10px;
  color: #636161;
}

.start-time--narrow {
  padding: 0;
}

@media (max-width: 767px) {
  .basic-job-info-section {
    padding: 10px;
  }

  table th {
    display: none;
  }

  table tr {
    margin-bottom: 8px;
    border-bottom: 1px solid #ddd;
  }

  table td {
    display: flex;
    border-bottom: none;
  }

  table td::before {
    content: attr(label);
    width: 120px;
    min-width: 120px;
    font-weight: bold;
  }
}
</style>
