const orders = {
 methods: {
   /*  ================= Translation Project Calls  ================  */

   createTranslationProject(jobForm, commit, support) {
     const self = this;
     const path = support ? '/v1/support/orders' : '/v1/orders';
     return new Promise((resolve, reject) => {
       self.ajax.post(path, jobForm, {
         onUploadProgress: (progressEvent) => {
           const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
           commit('setUploadPercentage', percentage, {root: true});
         }
       }).then((response) => {
         resolve(response?.data);
       }).catch((error) => {
         reject(error);
       });
     });
   },
   createLogoutTranslationProject(jobForm, commit, support) {
     const self = this;
     const path = support ? '/v1/support/logout_orders' : '/v1/logout_orders';
     return new Promise((resolve, reject) => {
       self.ajax.post(path, jobForm, {
         onUploadProgress: (progressEvent) => {
           const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
           commit('setUploadPercentage', percentage, {root: true});
         }
       }).then((response) => {
         resolve(response?.data);
       }).catch((error) => {
         reject(error);
       });
     });
   },
   getActiveTranslationProjects(params) {
     return new Promise((resolve, reject) => {
       this.ajax.get('/v1/dashboards/orders/active', {params}).then((response) => {
         resolve(response?.data);
       }).catch((error) => {
         this.resolveError403(error, resolve);
         reject(error);
       });
     });
   },
   getPastTranslationProjects(params) {
     return new Promise((resolve, reject) => {
       this.ajax.get('/v1/dashboards/orders/past', {params}).then((response) => {
         resolve(response?.data);
       }).catch((error) => {
         this.resolveError403(error, resolve);
         reject(error);
       });
     });
   },
   getProject(projId) {
     return new Promise((resolve, reject) => {
       this.ajax.get(`/v1/orders/${projId}`).then((response) => {
         resolve(response?.data);
       }).catch((error) => {
         // eslint-disable-next-line no-console
         const mes = `Get order with id: ${projId} responded with ${error?.status} code`;
         this.resolveError403(error, resolve, mes);
         this.resolveError404(error, resolve, mes);
         reject(error);
       });
     });
   },
   sendEditProject(projectForm, id, supportMode) {
     const self = this;
     if (supportMode) {
       return new Promise((resolve, reject) => {
         self.ajax.put(`/v1/support/orders/${id}`, projectForm).then((response) => {
           resolve(response?.data);
         }).catch((error) => {
           reject(error);
         });
       });
     } else {
       return new Promise((resolve, reject) => {
         self.ajax.put(`/v1/orders/${id}`, projectForm).then((response) => {
           resolve(response?.data);
         }).catch((error) => {
           reject(error);
         });
       });
     }
   },
   changeProjectStatus(id, status) {
     const self = this;

     return new Promise((resolve, reject) => {
       self.ajax.put(`/v1/orders/${id}/change_status`, {id, status}).then((response) => {
         resolve(response?.data);
       }).catch((error) => {
         reject(error);
       });
     });
   },
   getProjectLastUpdatedData(orderId) {
     const self = this;
     return new Promise((resolve, reject) => {
       self.ajax.get(`/v1/orders/${orderId}/last_updated_data`).then((response) => {
         resolve(response?.data);
       }).catch((error) => {
         reject(error);
       });
     });
   },
   fetchProjectSystemLogs(orderId, params = {}) {
     return new Promise((resolve, reject) => {
       this.ajax.get(`/v1/support/orders/${orderId}/logs`, {params})
         .then((response) => {
           resolve(response?.data);
         })
         .catch((error) => {
           this.resolveError403(error, resolve);
           reject(error, resolve);
         });
     });
   },
 }
};

export default orders;
