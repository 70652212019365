import constants from '@/modules/constants';
import {cookieManager} from '@skiwo/sk-basic-components';

const sseRequest = ({request, onmessage, onopen, onerror, url = null, params}) => {
  let apiName = '';
  switch (request) {
    case 'suitableJobs':
       apiName = '/v1/dashboards/suitable_jobs_async';
       break;
    case 'dashboardJobs':
       apiName = '/v1/dashboards/jobs_async';
       break;
  }

  const allParams = {
    ...params,
    locale: cookieManager.getCookie('locale')
  };

  const path = `${constants.API_PATH}${url || apiName}?${new URLSearchParams(allParams).toString()}`;
  const source = new EventSource(path);

  source.onopen = (event) => {
    if (onopen) onopen(event);
  };
  source.onmessage = (event) => {
    if (onmessage) onmessage(event);
    if (event.data) source.close();
  };

  source.onerror = (err) => {
    if (err.data === 'timeout') {
      source.close();
      // eslint-disable-next-line no-console
      console.error('EventSource timeout', err);
    } else {
      // eslint-disable-next-line no-console
      console.error('EventSource error', err);
    }
    if (onerror) onerror(err);
  };
};

export default sseRequest;
