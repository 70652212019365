// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/rotate_button_default.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".rotate-btn {\n  display: block;\n  width: 30px;\n  height: 30px;\n  border: 0;\n  border-radius: 50%;\n  background-color: transparent;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-image: var(--image-rotate-button);\n  background-position: 50% 50%;\n  background-size: 30px 30px;\n  background-repeat: no-repeat;\n  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);\n          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);\n}\n.rotate-btn:focus {\n  -webkit-box-shadow: var(--default-focus-shadow);\n          box-shadow: var(--default-focus-shadow);\n  outline: none;\n}\n", ""]);
// Exports
module.exports = exports;
