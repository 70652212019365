const projectStatus = (context, projectStatus) => {
  switch (projectStatus) {
    case 'draft':
      return context.$gettext('Draft');
    case 'transferred':
      return context.$gettext('Transferred');
    case 'proactive_access':
      return context.$gettext('Proactive access');
    case 'invited':
      return context.$gettext('Invited');
    case 'applied':
      return context.$gettext('Applied');
    case 'accepted':
      return context.$gettext('Accepted');
    case 'finished':
      return context.$gettext('Finished');
    case 'rejected':
      return context.$gettext('Rejected');
    case 'in_progress':
      return context.$gettext('In progress');
    case 'review_requested':
      return context.$pgettext('translation', 'Review requested');
    case 'changes_required':
      return context.$pgettext('translation', 'Changes required');
    case 'closed':
      return context.$gettext('Closed');
    case 'cancelled':
      return context.$gettext('Cancelled');
    default:
      return '';
  }
};

const getProjectStatus = (context, {status}) => {
  switch (status) {
    case 'quote_requested':
    case 'quote_sent':
    case 'quote_accepted':
    case 'quote_rejected':
      return context.$pgettext('translation', 'Assignment Received');
    case 'accepted':
      return context.$pgettext('translation', 'Accepted');
    case 'draft':
      return context.$pgettext('translation', 'Draft');
    case 'published':
      return context.$pgettext('translation', 'Published');
    case 'invited':
      return context.$pgettext('translation', 'Available');
    case 'proactive_access':
      return context.$pgettext('translation', 'Available');
    case 'applied':
      return context.$pgettext('translation', 'Offer Sent');
    case 'rejected':
      return context.$pgettext('translation', 'Rejected');
    case 'in_progress':
      return context.$pgettext('translation', 'In Progress');
    case 'waiting_for_customer':
      return context.$pgettext('translation', 'Waiting for customer');
    case 'review_requested':
      return context.$pgettext('translation', 'Review Requested');
    case 'changes_required':
      return context.$pgettext('translation', 'Requires Changes');
    case 'completed':
      return context.$pgettext('translation', 'Completed');
    case 'finished':
      return context.$pgettext('translation', 'Finished');
    case 'cancelled':
      return context.$pgettext('translation', 'Cancelled');
  }
};

const getSubtaskStatus = (context, status, isSuitableSeller) => {
  switch (status) {
    case 'accepted':
      return context.$pgettext('translation', 'Accepted');
    case 'draft':
      return context.$pgettext('translation', 'Draft');
    case 'published':
      if (isSuitableSeller) return context.$pgettext('translation', 'Available for you');
      else return context.$pgettext('translation', 'Not relevant for you');
    case 'invited':
      if (isSuitableSeller) return context.$pgettext('translation', 'Available for you');
      else return context.$pgettext('translation', 'Not relevant for you');
    case 'proactive_access':
      return context.$pgettext('translation', 'Available');
    case 'applied':
      return context.$pgettext('translation', 'Offer Sent');
    case 'rejected':
      return context.$pgettext('translation', 'Rejected');
    case 'in_progress':
      return context.$pgettext('translation', 'In Progress');
    case 'review_requested':
      return context.$pgettext('translation', 'Review Requested');
    case 'changes_required':
      return context.$pgettext('translation', 'Requires Changes');
    case 'finished':
      return context.$pgettext('translation', 'Finished');
    case 'cancelled':
      return context.$pgettext('translation', 'Cancelled');
  }
};

const getTaskStatus = (context, {status}) => {
  switch (status) {
    case 'published':
      return context.$pgettext('translation', 'Published');
    case 'finished':
      return context.$pgettext('translation', 'Finished');
  }
};

const accordionStatusStyles = (status) => {
  const statuses = ['published', 'invited', 'applied', 'proactive_access'];
  if (statuses.includes(status)) {
    return {
      color: '#003855',
      'background-color': '#99afbb'
    };
  } else if (status === 'in_progress' || status === 'accepted') {
    return {
      color: '#008489',
      'background-color': '#b2dadc'
    };
  } else if (status === 'draft') {
    return {
      color: '#000',
      'background-color': '#b2b2b2'
    };
  } else if (status === 'review_requested' || status === 'changes_required') {
    return {
      color: '#b68e56',
      'background-color': '#e9ddcc'
    };
  } else if (status === 'finished') {
    return {
      color: '#2b255c',
      'background-color': '#d5d3de'
    };
  } else if (status === 'cancelled' || status === 'rejected') {
    return {
      color: '#e52322',
      'background-color': '#f5a7aa'
    };
  } else {
    return {
      color: '#003855',
      'background-color': '#99afbb'
    };
  }
};

const subtaskType = (context, subtaskType) => {
  switch (subtaskType) {
    case 'translation':
      return context.$pgettext('translation', 'Translation');
    case 'correction':
      return context.$pgettext('translation', 'Correction');
    case 'in_person':
      return context.$pgettext('translation', 'In person');
  }
};

const subtasksTypes = (context, subtasksTypes) => {
  const typesMap = {
    translation: 'Translation',
    correction: 'Correction',
    in_person: 'In person',
    other: 'Other',
  };

  const subtasksTypesNames = subtasksTypes
    .filter((type) => typesMap[type])
    .map((type) => context.$pgettext('translation', typesMap[type]))
    .join(', ');

  return subtasksTypesNames;
};

const projectStatusObjManagers = (context, jobStatus) => {
  switch (jobStatus) {
    case 'draft':
      return {
        text: context.$pgettext('translation', 'Draft'),
        class: 'white'
      };
    case 'published':
      return {
        text: context.$pgettext('translation', 'Published'),
        class: 'grey'
      };
    case 'quote_requested':
      return {
        text: context.$pgettext('translation', 'Quote requested'),
        class: 'orange'
      };
    case 'quote_sent':
      return {
        text: context.$pgettext('translation', 'Quote sent'),
        class: 'white'
      };
    case 'quote_accepted':
      return {
        text: context.$pgettext('translation', 'Quote accepted'),
        class: 'blue'
      };
    case 'quote_rejected':
      return {
        text: context.$pgettext('translation', 'Quote rejected'),
        class: 'red'
      };
    case 'in_progress':
      return {
        text: context.$pgettext('translation', 'In Progress'),
        class: 'orange'
      };
    case 'finished':
      return {
        text: context.$pgettext('translation', 'Finished'),
        class: 'blue'
      };
    case 'cancelled':
      return {
        text: context.$pgettext('translation', 'Cancelled'),
        class: 'red'
      };
    case 'completed':
      return {
        text: context.$pgettext('translation', 'Completed'),
        class: 'green'
      };
    default:
      return {};
  }
};

const subtaskStatusObjManagers = (context, jobStatus) => {
  switch (jobStatus) {
    case 'draft':
      return {
        text: context.$pgettext('translation', 'Draft'),
        class: 'white'
      };
    case 'archived':
      return {
        text: context.$pgettext('translation', 'Archived'),
        class: 'grey'
      };
    case 'published':
      return {
        text: context.$pgettext('translation', 'Published'),
        class: 'grey'
      };
    case 'transferred':
      return {
        text: context.$pgettext('translation', 'Transferred'),
        class: 'grey'
      };
    case 'proactive_access':
      return {
        text: context.$pgettext('translation', 'Public access'),
        class: 'dark-blue'
      };
    case 'invited':
      return {
        text: context.$pgettext('translation', 'Invited'),
        class: 'dark-blue'
      };
    case 'applied':
      return {
        text: context.$pgettext('translation', 'Applied'),
        class: 'blue'
      };
    case 'accepted':
      return {
        text: context.$pgettext('translation', 'Accepted'),
        class: 'green'
      };
    case 'rejected':
      return {
        text: context.$pgettext('translation', 'Rejected'),
        class: 'green'
      };
    case 'in_progress':
      return {
        text: context.$pgettext('translation', 'In progress'),
        class: 'orange'
      };
    case 'review_requested':
      return {
        text: context.$pgettext('translation', 'Review Requested'),
        class: 'blue'
      };
    case 'changes_required':
      return {
        text: context.$pgettext('translation', 'Changes Required'),
        class: 'purple'
      };
    case 'finished':
      return {
        text: context.$pgettext('translation', 'Finished'),
        class: 'green'
      };
    case 'cancelled':
      return {
        text: context.$pgettext('translation', 'Cancelled'),
        class: 'red'
      };
    default:
      return {};
  }
};

export default {
  projectStatus,
  projectStatusObjManagers,
  getProjectStatus,
  getTaskStatus,
  getSubtaskStatus,
  subtaskStatusObjManagers,
  accordionStatusStyles,
  subtaskType,
  subtasksTypes
};
