(<template>
  <div class="post-form__section">
    <sk-input v-if="showClientNameField"
              :description="clientNameText"
              :preselected-value="clientName"
              :placeholder="clientNameText"
              class="post-form__field post-form__field--half-width"
              @fieldvaluechanged="setClientName" />
    <sk-input v-if="showClientIdField"
              :description="clientIdText"
              :preselected-value="clientId"
              :placeholder="clientIdText"
              class="post-form__field post-form__field--half-width"
              @fieldvaluechanged="setClientId" />
  </div>
</template>)

<script>
  import {mapGetters, mapState, mapMutations} from 'vuex';

  export default {
    computed: {
      ...mapGetters('PostingNotifierStore', [
        'showClientNameField',
        'showClientIdField'
      ]),
      ...mapState('PostingStore/ClientStore', {
        clientName: (state) => state.clientName,
        clientId: (state) => state.clientId
      }),
      clientNameText() { return this.$gettext('Client name'); },
      clientIdText() { return this.$gettext('Client ID'); }
    },
    methods: {
      ...mapMutations('PostingStore/ClientStore', [
        'setClientName',
        'setClientId'
      ])
    }
  };
</script>
