<template>
  <label :class="{'sk-checkbox--to-right': toRight,
                  'is-disabled': disabled}"
         class="sk-checkbox"
         @click.stop="">
    <span v-if="itemText"
          class="sk-checkbox__text"
          v-html="itemText"></span>
    <tt-avatar class="sk-checkbox__avatar"
               :avatar="avatarPath" />
    <input ref="checkbox"
           :class="{'is-with-error-field': errors}"
           :value="itemValue"
           :checked="checkboxValue"
           :autofocus="autofocus"
           :disabled="disabled"
           type="checkbox"
           class="sk-checkbox__input"
           @change="changeValue" />
    <span :class="{'sk-checkbox__imitation--star': starCheckbox}"
          class="sk-checkbox__imitation"></span>
  </label>
</template>

<script>
  import {skCheckbox} from '@skiwo/sk-basic-components';

  export default {
    name: 'avatar-checkbox',
    extends: skCheckbox,
    props: {
      avatarPath: {
        type: String,
        default: ''
      },
    }
  };
</script>
