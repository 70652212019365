import axios from 'axios';
// ==================================================
// ==================================================
// ENTERPRISE CALLS
// ==================================================
// ==================================================
const enterprises = {
  methods: {
    createEnterprise(form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('v1/enterprises', form, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          if (error?.status == 403) {
            resolve({code: 403});
            return;
          }
          reject(error);
        });
      });
    },
    getEnterprise(id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/enterprises/${id}`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    updateEnterprise(id, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`v1/enterprises/${id}`, form, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getCategories(enterpriseId, departmentId, headers) {
      const params = departmentId ? {department_id: departmentId} : {};
      return new Promise((resolve, reject) => {
        this.ajax.get(`v1/enterprises/${enterpriseId}/categories`, {params, headers}).then((response) => {
          const resp = response ? response?.data || {} : {};
          resolve(resp);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getOpenCategories(enterpriseId, departmentId, organizationName) {
      const params = {};

      if (enterpriseId) {
        params.enterprise_id = enterpriseId;
      }
      if (departmentId) {
        params.department_id = departmentId;
      }
      if (organizationName) {
        params.logout_page_name = organizationName;
      }
      return new Promise((resolve, reject) => {
        this.ajax.get('v1/enterprises/categories/logout_index', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getAllCategories() {
      return new Promise((resolve, reject) => {
        this.ajax.get('v1/job_categories').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getTranslationCategories() {
      return new Promise((resolve, reject) => {
        this.ajax.get('v1/sellers/translation_methods').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getManagerEnterprises(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/enterprises/managers_list', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getEnterprises() {
      return new Promise((resolve, reject) => {
        this.ajax.get('v1/enterprises/list').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
        // setTimeout(() => {
        //   resolve(faker.enterprises);
        // }, 1500);
      });
    },
    getEmployees(id, uid, params) {
      const self = this;
      const headers = {};
      if (uid) {
        headers.AuthImpersonationUID = uid;
      }
      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/enterprises/${id}/members`, {headers, params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getCurrentEmployee(id, uid, params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/enterprises/${id}/members/${uid}`, {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    searchEmployees(id, params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/enterprises/${id}/members/search`, {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    addExistingEmployee(entId, userId) {
      return new Promise((resolve, reject) => {
        this.ajax.post(`v1/enterprises/${entId}/members/${userId}/add_member`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    updateEmployee(entId, userId, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`v1/enterprises/${entId}/members/${userId}`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    removeEmployee(entId, userId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.delete(`v1/enterprises/${entId}/members/${userId}`).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    inviteEmployee(id, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/enterprises/${id}/members`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    checkEmployeeEmail(email) {
      const self = this;
      const paramsObj = {
        params: {email},
        cancelToken: this.cancelationSource.token
      };

      return new Promise((resolve, reject) => {
        self.ajax.get('v1/people/check_email', paramsObj).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    cancelEmployeeEmailCheck() {
      return new Promise((resolve) => {
        this.cancelationSource.cancel();
        this.cancelationSource = '';
        this.cancelationSource = axios.CancelToken.source();
        resolve();
      });
    },
    getDepartments(entId, params) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/enterprises/${entId}/departments`, {params: params}).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getDepartment(entId, depId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/enterprises/${entId}/departments/${depId}`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    createDepartment(entId, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/enterprises/${entId}/departments`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    updateDepartment(entId, depId, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`v1/enterprises/${entId}/departments/${depId}`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    removeDepartment(entId, depId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.delete(`v1/enterprises/${entId}/departments/${depId}`).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    resendInvite(id, uid) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/enterprises/${id}/members/${uid}/resend_invite`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAddresses(entId, departmentId) {
      const params = departmentId ? {department_id: departmentId} : '';

      return new Promise((resolve, reject) => {
        this.ajax.get(`/v1/enterprises/${entId}/departments/contact_addresses`, {params})
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            this.resolveError403(error, resolve);
            reject(error);
          });
      });
    },
    createAddress(entId, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/enterprises/${entId}/departments/contact_addresses`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    updateAddress(entId, addressId, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`v1/enterprises/${entId}/departments/contact_addresses/${addressId}`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    removeAddress(entId, addressId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.delete(`v1/enterprises/${entId}/departments/contact_addresses/${addressId}`).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    // token validation
    checkJwtToken() {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('v1/jwt/validate').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getGodModeToken(params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('v1/accounts/godmode', params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getHotlinePinCodes(uid) {
      return new Promise((resolve, reject) => {
        this.ajax.get(`v1/enterprises/${uid}/hotline_pin_codes`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getHotlinePhones() {
      return new Promise((resolve, reject) => {
        this.ajax.get('v1/hotlines/phones').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    updateHotlineDepartment(form, departmentId, enterpriseId) {
      return new Promise((resolve, reject) => {
        this.ajax.put(`v1/enterprises/${enterpriseId}/departments/${departmentId}`, form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getOrganizations() {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.get('/v1/enterprises').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    sendAdvertisementShownAt(id, uid) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/enterprises/${id}/members/${uid}/tip_shown`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    setPriceModel(form, id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/enterprises/${id}/settings/prices`, form).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    updateJobsScope(enterpriseId, uid, params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/enterprises/${enterpriseId}/members/${uid}/update_jobs_scope`, params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    getEnterpriseSecurity(id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`v1/enterprises/${id}/security`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          if (error?.status == 401) {
            resolve({code: 401});
            return;
          }
          reject(error);
        });
      });
    },
    updateEnterpriseSecurity(id, form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`v1/enterprises/${id}/security`, form, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    searchEnterprises(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('v1/enterprises/search', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    moveCustomerEnterprise(params) {
      return new Promise((resolve, reject) => {
        this.ajax.post('v1/enterprises/members/move', params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    managerCreateEnterprise(params) {
      return new Promise((resolve, reject) => {
        this.ajax.post('v1/enterprises/managers_create', params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    addMemberAsManager(params) {
      return new Promise((resolve, reject) => {
        this.ajax.post('v1/enterprises/members/add_as_manager', params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    createCustomer(params) {
      return new Promise((resolve, reject) => {
        this.ajax.post('v1/customers', params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    }
  }
};

export default enterprises;
