export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      demanderTemplates: {},
      supplierTemplates: {},
      demanderInternalTemplate: '',
      demanderExternalTemplate: '',
      supplierHonorarTemplate: '',
      supplierCompanyTemplate: '',
      supplierEmployeeTemplate: ''
    },
    getters: {
      getDemanderInternalTemplate(state) {
        return state.demanderInternalTemplate;
      },
      getDemanderExternalTemplate(state) {
        return state.demanderExternalTemplate;
      },
      getSupplierHonorarTemplate(state) {
        return state.supplierHonorarTemplate;
      },
      getSupplierCompanyTemplate(state) {
        return state.supplierCompanyTemplate;
      },
      getSupplierEmployeeTemplate(state) {
        return state.supplierEmployeeTemplate;
      },
    },
    actions: {
      getTemplates({commit}, type) {
        return new Promise((resolve, reject) => {
          fetchData.getTemplates(type).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setTemplates', {type, templates: data.templates});
            resolve(data);
          }).catch((data) => {
            reject(data);
          });
        });
      },
      updateCurrentTemplate({commit}, data) {
        return new Promise((resolve, reject) => {
          const form = new FormData();
          form.append(data.type, data.id);
          fetchData.updateCurrentTemplate(data.jobId, form).then(() => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            resolve();
          }).catch((data) => {
            reject(data);
          });
        });
      }
    },
    mutations: {
      setTemplate(state, data) {
        if (data.roleType === 'demander') {
          switch (data.financeType) {
            case 'internal':
              state.demanderInternalTemplate = data.id;
              break;
            case 'external':
              state.demanderExternalTemplate = data.id;
              break;
          }
        } else {
          switch (data.financeType) {
            case 'honorar':
              state.supplierHonorarTemplate = data.id;
              break;
            case 'company':
              state.supplierCompanyTemplate = data.id;
              break;
            case 'employee':
              state.supplierEmployeeTemplate = data.id;
              break;
          }
        }
      },
      setTemplates(state, data) {
        if (data.type === 'demander') {
          state.demanderTemplates = data.templates;
        } else {
          state.supplierTemplates = data.templates;
        }
      },
      clearStore(state) {
        state.demanderTemplates = {};
        state.supplierTemplates = {};
        state.demanderInternalTemplate = '';
        state.demanderExternalTemplate = '';
        state.supplierHonorarTemplate = '';
        state.supplierCompanyTemplate = '';
        state.supplierEmployeeTemplate = '';
      }
    }
  };
};
