<template>
  <div class="basic-customer-info__wrapper">
    <table class="basic-customer-info">
      <tr>
        <th v-for="(column, idx) in columns"
            :key="idx">{{ column }}</th>
      </tr>
      <tr>
        <td class="basic-customer-info__job-id">{{ id }}</td>
        <td class="basic-customer-info__name">{{ name }}</td>
        <td class="basic-customer-info__email">{{ email }}</td>
        <td class="basic-customer-info__phone">{{ phone }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('AllCustomersStore', {
        selectedCustomer: (state) => state.selectedCustomer || {}
      }),
      id() { return this.selectedCustomer.id || ''; },
      name() { return this.selectedCustomer.name || '-'; },
      email() { return this.selectedCustomer.email || '-'; },
      phone() { return this.selectedCustomer.phone || '-'; },
      columns() {
        return [
          this.$gettext('Id'),
          this.$gettext('Name'),
          this.$gettext('Email'),
          this.$gettext('Phone')
        ];
      },
      isMobileView() { return this.$store.state.GlobalStore.clientWidth < 768; }
    },
    watch: {
      isMobileView(newValue) { if (newValue) this.setMobileTable(); }
    },
    methods: {
      setMobileTable() {
        // if (window.innerWidth > 600) return false;
        const tableEl = document.querySelector('table');
        const thEls = tableEl.querySelectorAll('th');
        const tdLabels = Array.from(thEls).map((el) => el.innerText);
        tableEl.querySelectorAll('tr').forEach((tr) => {
          Array.from(tr.children).forEach(
            (td, ndx) => td.setAttribute('label', tdLabels[ndx])
          );
        });
      }
    },
  };
</script>

<style>
.start-time--narrow .start-time__cont {
  padding: 5px 10px;
}
</style>

<style scoped>
.basic-customer-info__wrapper {
  margin-bottom: 35px;
}

.basic-customer-info {
  width: 100%;
  border-radius: 8px;
  background-color: #f4f5f7;
}

.basic-customer-info__phone {
  max-width: 200px;
}

table {
  width: 100%;
}

th {
  vertical-align: middle;
  padding: 10px;
  border-collapse: collapse;
  border-bottom: 1px solid #e1dede;
  color: #555;
  font-weight: normal;
  text-align: left;
}

td {
  padding: 14px 10px;
  color: #333;
}

@media (max-width: 767px) {
  .basic-customer-info {
    padding: 10px;
  }

  table th {
    display: none;
  }

  table tr {
    margin-bottom: 8px;
    border-bottom: 1px solid #ddd;
  }

  table td {
    display: flex;
    border-bottom: none;
  }

  table td::before {
    content: attr(label);
    width: 120px;
    min-width: 120px;
    font-weight: bold;
  }
}
</style>
