(<template>
  <section class="apply-error">
    <p class="apply-error__text">{{ $gettext('Some of our customers require additional signed documents before they can consider you for their assignments.') }}</p>
    <p class="apply-error__text">{{ $gettext('For this assignment we need you to review and sign the following documents before applying for the job:') }}</p>
    <ol class="apply-error__text">
      <li v-for="doc in notSignedDocs"
          :key="doc.id">{{ doc.name }}</li>
    </ol>
    <p class="apply-error__text">{{ $gettext('Go to the signature page for more information.') }}</p>
    <button :class="[showTemporaryOption ? 'sk-btn--white' : 'sk-btn--default']"
            class="sk-btn apply-error__btn"
            @click="goToLink">{{ $gettext('Signatures page') }}</button>
    <div v-if="showTemporaryOption"
         class="apply-error__temporary-block">
      <h3 class="apply-error__temporary-title">{{ $gettext('Temporary 7-day option.') }}</h3>
      <sk-checkbox :preselected-value="confirmationValue"
                   :validation-name="confirmationValidationName"
                   :item-text="confirmationText"
                   class="apply-error__confirmation"
                   @checkboxvaluechanged="setConfirmationValue" />
      <button class="sk-btn sk-btn--default apply-error__confirmation-btn"
              @click="confirmationSubmit">{{ $gettext('Confirm') }}</button>
    </div>
  </section>
</template>)

<script>
  import {mapState, mapActions} from 'vuex';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        confirmationValue: false,
        confirmationValidationName: 'confirmationValidation',
        confirmationErrors: []
      };
    },
    computed: {
      ...mapState('TTSignaturesStore', {
        documentsForSigning: (state) => state.documentsForSigning
      }),
      notSignedDocs() {
        return this.documentsForSigning && this.documentsForSigning.length && this.documentsForSigning.filter((document) => {
          return this.data.notSignedDocs.includes(document.type);
        });
      },
      showTemporaryOption() {
        // return !this.$store.getters['UserInfoStore/usedPolitiAttestedOption'] &&
        //        this.data.notSignedDocs.length === 1 &&
        //        this.data.notSignedDocs.includes('police_attested');
        return false;
      },
      confirmationText() { return this.$gettext('<span class="apply-error__confirmation-error">IMPORTANT</span> I have started the process and promise to upload the result within 7 working days.'); }
    },
    methods: {
      ...mapActions('TTSignaturesStore', ['confirmTemporaryPoliceAttestation']),
      closeModal() {
        this.$emit('closemodal');
      },
      goToLink() {
        this.closeModal();
        this.$router.push(this.$makeRoute({name: 'ServerSignatures'}));
      },
      setConfirmationValue(value) {
        this.confirmationValue = value;
      },
      confirmationSubmit() {
        this.confirmationErrors = [];
        if (!this.confirmationValue) {
          this.confirmationErrors.push(this.$gettext('Confirmation is required'));
          this.$store.commit('ErrorsStore/setError', {name: this.confirmationValidationName, errors: this.confirmationErrors});
          return;
        }
        this.confirmTemporaryPoliceAttestation().then(() => {
          this.$store.commit('UserInfoStore/setUsedPolitiAttestedOption', true);
          this.data.context.makeApply();
          this.closeModal();
        });
      }
    }
  };
</script>

<style>
  .apply-error__confirmation-error {
    color: #646d8c;
    color: var(--color-primary);
  }
</style>

<style scoped>
  .apply-error {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 10px 30px 30px;
  }

  .apply-error__text {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  ol.apply-error__text {
    padding-left: 15px;
  }

  .apply-error__btn {
    width: auto;
    margin: 10px auto 0;
    padding: 0 20px;
  }

  .apply-error__confirmation-btn {
    width: auto;
    margin-top: 20px;
    margin-left: auto;
    padding: 0 20px;
  }

  .apply-error__temporary-title {
    margin: 30px 0 20px;
  }

  @media (max-width: 767px) {
    .apply-error {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
</style>
