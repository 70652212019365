(<template>
  <section class="open-assign-modal">
    <p class="open-assign-modal__text">{{ $gettext('To post assignments you need to be logged in with a purchasing account. Would you like to login with another email address?') }}</p>
    <div class="open-assign-modal__btns-wrapper">
      <button class="sk-btn sk-btn--white open-assign-modal__btn"
              @click="logOut">{{ $gettext('Log out') }}</button>
      <button class="sk-btn sk-btn--default open-assign-modal__btn"
              @click="closeModal">{{ $gettext('Cancel') }}</button>
    </div>
  </section>
</template>)

<script>
  export default {
    methods: {
      closeModal() {
        this.$cookieManager.setCookie('openAssignInfo', '', {path: '/', expires: 2592000});
        this.$cookieManager.setCookie('addJobAfterSignIn', '', {path: '/', expires: 2592000});
        this.$emit('closemodal');
      },
      logOut() {
        this.$cookieManager.setCookie('addJobAfterSignIn', true, {path: '/', expires: 2592000});
        this.$store.dispatch('TTAuthStore/logOut').then(() => {
          this.$goToExternalLoginPage();
        });
      }
    }
  };
</script>

<style scoped>
  .open-assign-modal {
    display: block;
    width: 100%;
    padding: 35px;
    padding-top: 10px;
  }

  .open-assign-modal__text {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    font-size: 16px;
  }

  .open-assign-modal__btns-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .open-assign-modal__btn {
    display: inline-block;
    width: auto;
    margin-left: 15px;
    padding: 0 25px;
  }
</style>
