(<template>
  <article class="payment-tile payment-tile--new payment-tile--invoice">
    <section class="payment-tile__add-payment-text-cont">
      <p class="payment-tile__add-payment-text">
        {{ title }}
      </p>
    </section>
    <figure class="payment-tile__add-payment-icon-cont">
      <span class="payment-tile__add-payment-icon"
            :class="'payment-tile__add-payment-icon--'+type"></span>
    </figure>
    <section class="payment-tile__add-btn-cont">
      <button ref="addInvoiceBtn"
              autofocus="true"
              class="sk-btn sk-btn--default payment-tile__add-btn"
              @click="$emit('add')">
        {{ buttonText }}
      </button>
    </section>
  </article>
</template>)

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'organization'
      },
      buttonText: {
        type: String,
        default: ''
      },
      autofocus: {
        type: Boolean,
        default: false
      },
    },
    mounted() {
      this.autofocus
        ? this.$nextTick(() => this.$refs.addInvoiceBtn.focus())
        : '';
    }
  };
</script>
<style scoped src="@assets/css/options.css"></style>
