export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      payoutInfoWizard: {
        userHasCompany: true,
        userHasOwnProvider: true
      },
      newAccount: {
        firstName: '',
        lastName: '',
        bankAccountNumber: '',
        freelanceType: 'INDIVIDUAL',
        personalNumber: '',
        organizationNumber: '',
        address: {
          line1: '',
          postcode: '',
          countryCode: '',
          country: 'Norway',
          city: ''
        }
      },
      errors: {
        firstName: {errors: [], name: 'firstNameError'},
        lastName: {errors: [], name: 'lastNameError'},
        bankAccountNumber: {errors: [], name: 'bankAccountNumberError'},
        personalNumber: {errors: [], name: 'personalNumberError'},
        organizationNumber: {errors: [], name: 'organizationNumberError'},
        line1: {errors: [], name: 'line1NameError'},
        postcode: {errors: [], name: 'postcodeNameError'},
        city: {errors: [], name: 'cityNameError'}
      },
      accountDetails: [],
      userHasDippsAccount: null
    },
    getters: {
      userHasAccountDetails(state) {
        return state.accountDetails && Object.keys(state.accountDetails).length > 0;
      },
      getPayoutMethodType(state) {
        let payoutMethodType = '';
        if (state.payoutInfoWizard.userHasOwnProvider) {
          payoutMethodType = 'self_services';
        } else if (!state.payoutInfoWizard.userHasCompany) {
          payoutMethodType = 'payrolls';
        } else {
          payoutMethodType = 'dipps';
        }
        return payoutMethodType;
      }
    },
    actions: {
      // setAsDefaultAccount(_, id) {
      //   const payload = {
      //     profile: {
      //       id: id,
      //       default: true
      //     }
      //   };
      //   return new Promise((resolve, reject) => {
      //     fetchData.updateAccountDetails(id, payload).then((data) => {
      //       resolve(data);
      //     }).catch((error) => {
      //       reject(error);
      //     });
      //   });
      // },
      postAccountDetails({state}) {
        const postDetails = (resolve, reject, payload) => {
          fetchData.postAccountDetails(payload).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        };
        return new Promise((resolve, reject) => {
          const {freelanceType} = state.newAccount;
          const payload = {
            profile: {
              default: true,
              firstName: state.newAccount.firstName.trim(),
              lastName: state.newAccount.lastName.trim(),
              bankAccountNumber: state.newAccount.bankAccountNumber.trim(),
              freelanceType
            }
          };
          if (freelanceType === 'ORGANIZATION') {
            payload.profile.organizationNumber = state.newAccount.organizationNumber.trim();
            postDetails(resolve, reject, payload);
            return;
          }
          fetchData.getCountryCode(state.newAccount.address.country).then((countryCode) => {
            payload.profile.personalNumber = state.newAccount.personalNumber.trim();
            payload.address = {
              line1: state.newAccount.address.line1,
              postalCode: state.newAccount.address.postcode,
              country: countryCode,
              city: state.newAccount.address.city
            };
            postDetails(resolve, reject, payload);
          });
        });
      },
      // getAccountDetails({commit}) {
      //   return new Promise((resolve, reject) => {
      //     fetchData.getAccountDetails().then((response) => {
      //       if (response?.code == 404) {
      //         commit('setUserHasDippsAccount', false);
      //         resolve();
      //         return;
      //       }
      //       commit('setUserHasDippsAccount', true);
      //       commit('setAccountDetails', response);
      //       resolve();
      //     }).catch((error) => {
      //       reject(error);
      //     });
      //   });
      // },
      // signUpDipps({commit}) {
      //   return new Promise((resolve, reject) => {
      //     fetchData.signUpDipps().then((response) => {
      //       commit('setUserHasDippsAccount', true);
      //       resolve(response);
      //     }).catch((error) => {
      //       reject(error);
      //     });
      //   });
      // },
      setError({state, commit}, {fieldName, errorText}) {
        commit('setError', {fieldName, errorText});
        commit('ErrorsStore/setError', {name: state.errors[fieldName].name, errors: state.errors[fieldName].errors}, {root: true});
      },
      removeErrors({commit}) {
        commit('removeErrors');
        commit('ErrorsStore/removeAllErrors', null, {root: true});
      },
      createForm({state}) {
        const form = new FormData();

        if (state.payoutInfoWizard.userHasOwnProvider) {
          form.append('payout[has_company]', state.payoutInfoWizard.userHasCompany);
          if (state.payoutInfoWizard.userHasCompany) form.append('payout[org_number]', state.newAccount.organizationNumber);
        } else if (!state.payoutInfoWizard.userHasCompany) {
          form.append('payout[bank_account_num]', state.newAccount.bankAccountNumber);
          form.append('payout[personal_number]', state.newAccount.personalNumber);
        }

        return Promise.resolve(form);
      },
      newPayoutMethod({state}) {
        const form = new FormData();
        form.append('payout[bank_account_num]', state.newAccount.bankAccountNumber);
        form.append('payout[org_number]', state.newAccount.organizationNumber);
        form.append('payout[personal_number]', state.newAccount.personalNumber);
        return new Promise((resolve, reject) => {
          fetchData.newPayoutMethod(form).then((data) => {
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      postPayoutMethod({getters, dispatch}) {
        return new Promise((resolve, reject) => {
          const payoutMethodType = getters.getPayoutMethodType;

          if (payoutMethodType === 'dipps') {
            dispatch('postAccountDetails').then((data) => {
              resolve(data);
            }).catch((error) => {
              reject(error);
            });
            // dispatch('signUpDipps').then(() => {
            //   dispatch('postAccountDetails').then((data) => {
            //     resolve(data);
            //   }).catch((error) => {
            //     reject(error);
            //   });
            // }).catch((error) => {
            //   if (error.data
            //       && error.data.errors
            //       && error.data.errors.base === 'ACCOUNT_EXISTS') {
            //     dispatch('postAccountDetails').then((data) => {
            //       resolve(data);
            //     }).catch((error) => {
            //       reject(error);
            //     });
            //   } else {
            //     reject(error);
            //   }
            // });
          } else {
            dispatch('createForm').then((form) => {
              fetchData.postPayoutMethod(payoutMethodType, form).then((data) => {
                resolve(data);
              }).catch((error) => {
                reject(error.data);
              });
            });
          }
        });
      }
    },
    mutations: {
      setUserHasDippsAccount(state, value) {
        state.userHasDippsAccount = value;
      },
      setUserHasCompany(state, value) {
        state.payoutInfoWizard.userHasCompany = value;
      },
      setUserHasOwnProvider(state, value) {
        state.payoutInfoWizard.userHasOwnProvider = value;
      },
      setAccountDetails(state, value) {
        state.accountDetails = value.length ? value[0] : {};
      },
      setFirstName(state, value) {
        state.newAccount.firstName = value;
      },
      setLastName(state, value) {
        state.newAccount.lastName = value;
      },
      setBankAccountNumber(state, value) {
        state.newAccount.bankAccountNumber = value;
      },
      setPersonalNumber(state, value) {
        state.newAccount.personalNumber = value;
      },
      setOrganizationNumber(state, value) {
        state.newAccount.organizationNumber = value;
      },
      setFreelanceType(state, value) {
        state.newAccount.freelanceType = value;
      },
      setLine1(state, value) {
        state.newAccount.address.line1 = value;
      },
      setPostcode(state, value) {
        state.newAccount.address.postcode = value;
      },
      setCity(state, value) {
        state.newAccount.address.city = value;
      },
      setCountry(state, value) {
        state.newAccount.address.country = value;
      },
      setError(state, {fieldName, errorText}) {
        state.errors[fieldName].errors = [...state.errors[fieldName].errors, errorText];
      },
      removeOneError(state, fieldName) {
        state.errors[fieldName].errors = [];
      },
      removeErrors(state) {
        for (const key of Object.keys(state.errors)) {
          state.errors[key].errors = [];
        }
      },
      clearStore(state) {
        state.newAccount = {
          firstName: '',
          lastName: '',
          bankAccountNumber: '',
          freelanceType: 'INDIVIDUAL',
          personalNumber: '',
          organizationNumber: '',
          address: {
            line1: '',
            postcode: '',
            countryCode: '',
            country: 'Norway',
            city: ''
          }
        };
        state.accountDetails = [];
        state.userHasDippsAccount = null;
      }
    }
  };
};
