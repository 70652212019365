(<template>
  <div ref="dateTimeSection"
       :class="{'in-progress': progressActive}"
       class="assignment-date is-progress-bar"
       @focusin="openDateTimeHelper">
    <div class="assignment-date__cont">
      <div class="assignment-date__start-cont">
        <sk-datepicker :preselected-value="assignmentStartDate"
                       :description="startDateText"
                       :full-calendar="allowPastBooking"
                       :default-value="'--/--/----'"
                       :prevent-call="true"
                       :blocked-dates-array="blockedStartDatesArray"
                       :disabled="disabledFields"
                       :validation-name="startTimeValidation"
                       class="post-form__field assignment-date__datepicker"
                       @changeday="catchAssignmentStartDate" />
        <sk-timepicker :preselected-value="assignmentStartTime"
                       :start-select-from="allowPastBooking ? '' : startTimeBegining"
                       :auto-scroll="true"
                       :default-value="'--:--'"
                       :description="startTimeSelectText"
                       :time-step="$timepickerStep()"
                       :mob-numeric-keyboard="true"
                       :items-list="availableStartTime"
                       :disabled="disabledFields"
                       class="post-form__field assignment-date__timepicker"
                       @timepickervaluechanged="catchStartTime" />
      </div>
      <div class="assignment-date__finish-cont">
        <sk-timepicker :preselected-value="assignmentFinishTime"
                       :start-select-from="allowPastBooking ? '' : assignmentStartTime"
                       :auto-scroll="true"
                       :default-value="'--:--'"
                       :description="finishTimeSelectText"
                       :time-step="$timepickerStep()"
                       :mob-numeric-keyboard="true"
                       :items-list="availableFinishTime"
                       :validation-name="finishTimeValidation"
                       :disabled="disabledFields"
                       class="post-form__field assignment-date__timepicker"
                       @timepickervaluechanged="catchFinishTime" />
      </div>
    </div>
    <!-- ToDo: Delete availabilityWarning and related code with AvailabilityDateTimeSection.vue -->
    <div v-if="availabilityWarning"
         class="sk-note assignment-date__warnings">{{ availabilityWarning }}</div>
  </div>
</template>)

<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';

  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('PostingNotifierStore', [
        'showSeriesBooking'
      ]),
      ...mapGetters('PostingStore/DateTimeStore', ['minJobDuration']),
      ...mapState('PostingStore/DateTimeStore', {
        fullStartTime: (state) => state.startTime,
        fullFinishTime: (state) => state.finishTime,
        customDuration: (state) => state.customDuration,
        startTimeValidation: (state) => state.errors.startTime.name,
        finishTimeValidation: (state) => state.errors.finishTime.name
      }),
      ...mapState('TTInterBookingStore', [
        'availabilityWarning'
      ]),
      startDateText() { return this.$gettext('Date'); },
      startTimeSelectText() { return this.$gettext('Start time'); },
      finishTimeSelectText() { return this.$gettext('Finish time'); },
      paramsList() { return this.params || {}; },
      disabledFields() { return this.paramsList.disabledFields; },
      progressActive() { return this.paramsList.progress; },
      allowPastBooking() { return this.$isGodmode(); },
      assignmentStartDate() { return this.fullStartTime ? this.$moment(this.fullStartTime).format('YYYY-MM-DD') : ''; },
      assignmentFinishDate() { return this.fullFinishTime ? this.$moment(this.fullFinishTime).format('YYYY-MM-DD') : ''; },
      assignmentStartTime() { return this.fullStartTime ? this.$moment(this.fullStartTime).format('HH:mm') : ''; },
      assignmentFinishTime() { return this.fullFinishTime ? this.$moment(this.fullFinishTime).format('HH:mm') : ''; },
      isCurrentStartDay() { return this.assignmentStartDate === this.$moment().format('YYYY-MM-DD'); },
      isCurrentFinishDay() { return this.assignmentFinishDate === this.$moment().format('YYYY-MM-DD'); },
      startTimeBegining() {
        return this.isCurrentStartDay && !this.disabledFields
          ? helpers.dateTime.convertCurrentTime(this.$moment)
          : '';
      },
      blockedStartDatesArray() { return []; },
      availableStartTime() {},
      availableFinishTime() {},
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    methods: {
      ...mapActions('PostingStore/DateTimeStore', [
        'catchStartTime',
        'catchFinishTime',
        'catchAssignmentStartDate'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
      ]),
      openDateTimeHelper() {
        if (this.showAssignmentHelper) {
          this.setPopoverReference(this.$refs.dateTimeSection);
          this.openHelper('dateTime');
        }
      },
    }
  };
</script>

<style scoped src="@assets/css/assignment_dates.css">
.assignment-date__cont {
  margin-top: 10px;
}
</style>
