(<template>
  <div ref="dialect"
       class="post-form__section"
       @focusin="openDialectHelper">
    <sk-input :description="dialectText"
              :preselected-value="dialect"
              :placeholder="dialectPlaceholder"
              :max-length="15"
              :reg-exp="/[^a-zA-ZÆØÅæøå\- ]/g"
              class="post-form__field"
              @fieldvaluechanged="setDialect" />
    <p class="post-form__dialect-char-limit"
       v-html="dialectMessage"></p>
  </div>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';

  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('PostingStore/DialectStore', {
        dialect: (state) => state.dialect
      }),
      dialectText() { return this.$gettext('Dialect'); },
      dialectPlaceholder() { return this.$gettext('Write here ...'); },
      remainingChars() { return 15 - this.dialect.length; },
      dialectMessage() {
        const remChars = this.remainingChars;
        const charLimit = 15;
        const template = this.$gettext('Maximum <strong>%{charLimit}</strong> characters ( <strong> %{remChars} </strong> remaining )');
        return this.$gettextInterpolate(template, {remChars, charLimit});
      },
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    methods: {
      ...mapMutations('PostingStore/DialectStore', [
        'setDialect'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
      ]),
      openDialectHelper() {
        if (this.showAssignmentHelper) {
          this.setPopoverReference(this.$refs.dialect);
          this.openHelper('dialect');
        }
      },
    }
  };
</script>

<style scoped>
.post-form__section {
  position: relative;
}

.post-form__dialect-char-limit {
  position: absolute;
  top: 55px;
  left: 18px;
  font-size: 12px;
}

#app.salita-facelifted .post-form__dialect-char-limit {
  top: 55px;
  bottom: 0;
  left: 0;
}

</style>
