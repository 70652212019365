(<template>
  <div class="tooltip-root sk-tooltip"
       :style="tooltipPosition">
    <button class="sk-tooltip__btn not-click-blurable"
            type="button"
            @click.stop.prevent="onClick"
            @mouseleave="hoverHideTooltip"
            @mouseover="hoverShowTooltip"></button>
    <div ref="text"
         :class="positionClassName"
         class="sk-tooltip__text"
         style="width: max-content; min-width: 60px;"
         @click="onTooltipClick">
      <p v-if="toolTipText"
         class="sk-tooltip__text-content"
         v-html="toolTipText"></p>
    </div>
  </div>
</template>)

<script>
  import {skTooltip} from '@skiwo/sk-basic-components';

  export default {
    extends: skTooltip,
    computed: {
      tooltipData() {
        return this.$store.getters['TooltipRootStore/tooltipData'] || {};
      },
      tooltipPosition() {
        return this.$store.getters['TooltipRootStore/tooltipPosition'] || {};
      },
      progress() {
        return this.$store.getters['TooltipRootStore/tooltipProgress'];
      },
      tooltipClasses() {
        return this.$store.getters['TooltipRootStore/tooltipClasses'];
      },
      toolTipText() { return this.tooltipData.itemText; },
      relativePosition() { return this.tooltipData.relativePosition; },
      positionClassName() { return this.relativePosition ? `sk-tooltip__text--to-${this.relativePosition}` : 'sk-tooltip__text--to-right-center'; }
    },
  };
</script>

<style scoped>
.sk-tooltip {
  position: absolute;
  z-index: 100;
}

.tooltip-root .sk-tooltip__text {
  padding: 5px 10px;
  background-color: rgba(51, 51, 51, 0.95);
}
</style>
