(<template>
  <article class="rejection-reason">
    <p class="rejection-reason__description">{{ modalDescriptionText }}</p>
    <sk-select :items-list="rejectionReasons"
               :default-value="selectReasonsText"
               :description="reasonsText"
               :preselected-value="rejectionReason"
               class="rejection-reason__dropdown"
               @csvaluechanged="catchRejectionReason" />
    <div v-if="rejectionReason === 'deadline'">
      <p class="rejection-reason__description">{{ newDeadlineDescriptionText }}</p>
      <sk-datepicker :preselected-value="newDeadline"
                     :full-calendar="allowPastBooking"
                     :default-value="datePickerDefaultValue"
                     :prevent-call="true"
                     :icon="true"
                     :description="deadlineText"
                     class="rejection-reason__date-picker-deadline"
                     @changeday="catchDeadline" />
    </div>
    <div class="rejection-reason__actions">
      <button class="sk-btn--white rejection-reason__cancel"
              @click="closeModal">{{ $gettext('Cancel') }}</button>
      <button class="sk-btn--default rejection-reason__reject"
              :class="{'is-disabled': !rejectionReason}"
              @click="rejectSubtask">{{ submitButtonText }}</button>
    </div>
  </article>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        rejectionReason: '',
        newDeadline: ''
      };
    },
    computed: {
      allowPastBooking() { return this.$isGodmode(); },
      selectReasonsText() { return this.$gettext('Please select'); },
      reasonsText() { return this.$gettext('Reasons'); },
      deadlineText() { return this.$pgettext('translation', 'Deadline'); },
      modalDescriptionText() { return this.$gettext('Please select the reason why you want to reject this invitation.'); },
      newDeadlineDescriptionText() { return this.$gettext('You can propose a new deadline if you\'re still interested in this job.'); },
      datePickerDefaultValue() { return this.$gettext('Select deadline'); },
      submitButtonText() { return (this.rejectionReason === 'deadline' && this.newDeadline) ? this.$gettext('Submit proposal') : this.$gettext('Reject'); },
      rejectionReasons() {
        const reasons = [
          {id: 'deadline', name: this.$pgettext('translation', 'Deadline')},
          {id: 'language_pair', name: this.$pgettext('translation', 'Language pair')},
          {id: 'not_available', name: this.$pgettext('translation', 'Not Available')},
        ];

        return this.data.deadline ? reasons : reasons.slice(1);
      },
    },
    methods: {
      catchRejectionReason(value) {
        this.rejectionReason = value;
      },
      catchDeadline(value) {
        this.newDeadline = value;
      },
      closeModal() {
        this.$store.dispatch('ModalStore/closeModal');
      },
      rejectSubtask() {
        if (this.rejectionReason === 'deadline' && this.newDeadline) {
          this.data.context.requestNewDeadline(this.newDeadline);
          this.closeModal();
        } else if (this.rejectionReason) {
          this.data.context.setRejectionReason(this.rejectionReason);
          this.closeModal();
        }
      }
    }
  };
</script>

<style>
.rejection-reason {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 250px;
  padding: 20px 30px 30px;
}

.rejection-reason__description {
  color: var(--black-700);
}

.rejection-reason__dropdown {
  margin: 16px 0 24px 0;
}

.rejection-reason__date-picker-deadline {
  margin-top: 16px;
}

.rejection-reason__date-picker-deadline.is-active {
  height: 240px;
}

.rejection-reason__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.rejection-reason__actions button {
  padding: 0 12px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 33px;
  cursor: pointer;
}

.rejection-reason__reject {
  margin-left: 16px;
}
</style>
