import BookingHelper from '@/store/booking_stores/BookingHelper';

export default () => {
  return {
    namespaced: true,
    state: {
      accessLevel: '',
      accessLevelSelect: false,
      errors: {accessLevel: {name: 'accessLevelError', errors: []}}
    },
    actions: {
      changeAccessLevel({commit}, payload) {
        commit('setAccessLevel', payload);
      },
      changeAccessLevelSelectState({commit}, value) {
        commit('setAccessLevelSelectState', value);
        commit('setAccessLevel', '');
      },
      showDepartmentOrdersToAllMembers({rootState, rootGetters, dispatch}) {
        function findBookerDepartmentOrderAccessSetting(currentBooker, employeesList, bookerDepartment, resolve) {
          currentBooker = employeesList.find((employee) => employee.uid === rootGetters['UserInfoStore/userUid']);
          bookerDepartment = currentBooker.departments.find((department) => department.id === rootState.PostingStore.DepartmentAttendersStore.department);
          resolve(bookerDepartment?.show_department_orders_to_all_members);
          return {currentBooker, bookerDepartment};
        }

        return new Promise((resolve, reject) => {
          let employeesList = rootState.EnterpriseStore.employees;
          const currentBooker = '';
          const bookerDepartment = '';
          if (!employeesList || employeesList.length <= 0) {
            dispatch('EnterpriseStore/getEmployees', {root: true}).then((employees) => {
              employeesList = employees;
              findBookerDepartmentOrderAccessSetting(currentBooker, employeesList, bookerDepartment, resolve);
            }).catch((response) => {
              reject(response);
            });
          } else {
            findBookerDepartmentOrderAccessSetting(currentBooker, employeesList, bookerDepartment, resolve);
          }
        });
      }
    },
    mutations: {
      ...BookingHelper().mutations,
      setAccessLevel(state, value) {
        state.accessLevel = value;
      },
      setAccessLevelSelectState(state, value) {
        state.accessLevelSelect = value;
      },
      clearStore(state) {
        state.accessLevel = '';
        state.accessLevelSelect = false;
      }
    }
  };
};
