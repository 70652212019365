(<template>
  <section class="attenders-list">
    <div class="attenders-list__description-cont">
      <p class="attenders-list__text"
         v-html="listDescription"></p>
      <p class="attenders-list__text"
         v-html="employeeDescription"></p>
    </div>
    <div v-for="attender in allAttenders"
         :key="attender.uid"
         class="attenders-list__attender-wrapper">
      <div class="attenders-list__attender-row">
        <tt-avatar :avatar="attender.avatarUrl"
                   class="attenders-list__avatar" />
        <sk-checkbox :key="attender.uid"
                     :item-text="computedName(attender)"
                     :preselected-value="chosenAttenders.indexOf(attender.uid) != -1"
                     :item-value="attender.uid"
                     :to-right="true"
                     class="attenders-list__checkbox"
                     @checkboxvaluechanged="changeAttenderForJob" />
      </div>
      <div class="attenders-list__preview"
           v-html="getPreviewAttender(attender.uid)"></div>
    </div>
    <div class="attenders-list__btn-cont">
      <button class="sk-btn sk-btn--default attenders-list__btn"
              @click="saveNewAttendersList">{{ $gettext('Add attenders') }}</button>
    </div>
  </section>
</template>)

<script>
  import helpers from '@/helpers';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        chosenAttenders: (function makeChosenAttenders(self) {
          const res = [];
          for (const att of self.data.attenders) {
            res.push(att.uid);
          }
          return res;
        }(this)),
        departmentName: this.data.department.name || ''
      };
    },
    computed: {
      listDescription() {
        const template = this.$gettext('Here is a list of all your colleagues in <b>%{departmentName}</b>.');
        return this.$gettextInterpolate(template, {departmentName: this.departmentName});
      },
      employeeDescription() {
        const resolvedHref = this.$router.resolve(this.$makeRoute({name: 'BuyerEmployees'})).href;
        const template = this.$gettext('If you can not find your colleague in this list, then visit the <a class="sk-link sk-link--gray-bg" href="%{link}">Employees page</a> to first add your employee to your department.');
        return this.$gettextInterpolate(template, {link: resolvedHref});
      },
      allAttenders() {
        return this.$store.state.selectsData.attenders || [];
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      computedName(person) {
        return person.firstName || person.lastName ? `${person.firstName} ${person.lastName}` : person.email;
      },
      changeAttenderForJob(value, uid) {
        value ? this.chosenAttenders.push(uid) : this.chosenAttenders.splice(this.chosenAttenders.indexOf(uid), 1);
      },
      getPreviewAttender(attenderId) {
        const selectedItem = this.allAttenders.find((item) => item.uid == attenderId) || {};
        return helpers.getSelectedPreview(selectedItem);
      },
      saveNewAttendersList() {
        const attenders = [];
        for (const att of this.allAttenders) {
          if (this.chosenAttenders && this.chosenAttenders.indexOf(att.uid) != -1) {
            attenders.push({
              uid: att.uid,
              firstName: att.firstName,
              lastName: att.lastName,
              avatarUrl: att.avatarUrl
            });
          }
        }
        if (attenders.length) {
          this.$emit('startprogress');
          this.data.context.sendNewAttenders(attenders);
        } else {
          this.$scrollToErrors();
          this.$store.commit('ModalStore/setModalErrors', this.$gettext('There should be at least one attendee'));
        }
      }
    }
  };
</script>

<style>
  .attenders-list .sk-checkbox__text {
    word-break: break-word;
  }

  .attenders-list__preview .preview {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    text-overflow: ellipsis;
  }
</style>

<style scoped>
  .attenders-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 35px 40px 20px;
  }

  .attenders-list__attender-wrapper {
    width: 50%;
  }

  .attenders-list__description-cont {
    margin-bottom: 20px;
    font-size: 12px;
  }

  .attenders-list__btn-cont {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: 25px;
  }

  .attenders-list__btn {
    width: auto;
    padding: 0 15px;
  }

  .attenders-list__attender-row {
    display: flex;
    align-items: center;
    min-height: 35px;
    margin-bottom: 10px;
    padding-right: 15px;
    text-align: left;
  }

  .attenders-list__avatar {
    flex-shrink: 0;
    width: 35px;
    height: 35px;
    margin-right: 5px;
  }

  .attenders-list__checkbox {
    text-align: left;
  }

  .attenders-list__preview {
    margin-bottom: 15px;
    padding-left: 40px;
  }

  @media (max-width: 767px) {
    .attenders-list {
      padding: 20px;
    }

    .attenders-list__attender-wrapper {
      width: 100%;
    }

    .attenders-list__attender-row {
      position: relative;
      width: 100%;
      margin-bottom: 5px;
      padding-right: 0;
      padding-bottom: 5px;
      border-bottom: 1px solid #e9eaef;
    }
  }
</style>
