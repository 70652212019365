(<template>
  <div class="participants-section">
    <div class="participants-section__container">
      <div class="participants-section__input-cont">
        <sk-input :no-description="true"
                  :placeholder="inviteEmailPlaceholder"
                  :preselected-value="participantEmail"
                  :validation-name="participantEmailValidation"
                  :type="'email'"
                  class="participants-section__input"
                  @fieldvaluechanged="setInviteEmail" />
        <button type="button"
                class="sk-btn sk-btn--white participants-section__add-btn"
                @click="addPartnerEmail">{{ $gettext('Add') }}</button>
      </div>
      <div v-for="(invite, index) in participantEmails"
           :key="index"
           :class="{'is-with-error': showErrors(invite)}">
        <div class="participants-section__list-item">
          <template v-if="!invite.deleted">
            <p class="participants-section__item-text">{{ invite.email }}</p>
            <button type="button"
                    class="participants-section__remove-btn"
                    @click="removeOneEmail(index, invite.id)"></button>
          </template>
        </div>
        <p v-if="showErrors(invite)"
           class="participants-section__error">{{ invite.error }}</p>
      </div>
    </div>
  </div>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';
  import helpers from '@/helpers';

  export default {
    computed: {
      ...mapState('PostingStore/ParticipantsStore', {
        participantEmail: (state) => state.participantEmail,
        participantEmails: (state) => state.participantEmails,
        participantEmailValidation: ({errors}) => errors.participantEmail.name,
        participantEmailErrors: ({errors}) => errors.participantEmail.errors
      }),
      inviteEmailPlaceholder() { return this.$gettext('E-mail'); }
    },
    methods: {
      ...mapMutations('PostingStore/ParticipantsStore', [
        'setInviteEmail',
        'addInvitees',
        'removeInvitees',
        // errors
        'setError',
        'removeOneError'
      ]),
      validateEmail(email) {
        return helpers.validation.validateEmail(email);
      },
      addPartnerEmail() {
        this.removeParticipantEmailErrors();
        const existedEmails = this.participantEmails.map((obj) => obj.email);

        if (!this.participantEmail) return;
        if (!this.validateEmail(this.participantEmail)) {
          this.setError({
            fieldName: 'participantEmail',
            errorText: this.$gettext('Email is not valid')
          });
          this.$store.commit('ErrorsStore/setError', {name: this.participantEmailValidation, errors: this.participantEmailErrors});
          return;
        }
        if (existedEmails.includes(this.participantEmail)) {
          this.setError({
            fieldName: 'participantEmail',
            errorText: this.$gettext('Confirmation email should not be duplicated')
          });
          this.$store.commit('ErrorsStore/setError', {name: this.participantEmailValidation, errors: this.participantEmailErrors});
          return;
        }
        this.addInvitees({email: this.participantEmail, error: false});
        this.setInviteEmail('');
      },
      removeOneEmail(index, id) {
        this.removeInvitees({index, id});
      },
      removeParticipantEmailErrors() {
        this.removeOneError('participantEmail');
        this.$store.commit('ErrorsStore/setError', {name: this.participantEmailValidation, errors: []});
      },
      showErrors(invite = {}) {
        return invite.error && !invite.deleted;
      }
    }
  };
</script>

<style>
  .participants-section__input .sk-input__input-wrapper {
    background-color: #fff;
  }
</style>

<style scoped src="@assets/css/participants_section.css"></style>
