export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      enterprisesData: {
        enterprises: [],
        count: 0,
        pages: 1,
        page: 1
      },
    },
    actions: {
      getManagerEnterprises({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getManagerEnterprises(params).then((response) => {
            commit('setEnterprisesData', response);
            const resFormatted = response;
            resFormatted.data = response.enterprises;
            resolve(resFormatted);
          }).catch(() => {
            reject();
          });
        });
      }
    },
    mutations: {
      setEnterprisesData(state, data) {
        state.enterprisesData = data;
      },
      clearStore(state) {
        state.enterprisesData = {
          enterprises: [],
          count: 0,
          pages: 1,
          page: 1
        };
      }
    }
  };
};
