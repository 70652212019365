const customerType = (context, customerType) => {
  switch (customerType) {
    case 'private':
      return {
        text: context.$gettext('Private'),
        class: 'blue'
      };
    case 'enterprise':
      return {
        text: context.$gettext('Enterprise'),
        class: 'green'
      };
    case 'undefined':
      return {
        text: context.$gettext('Undecided'),
        class: 'white'
      };
    default:
       return {};
  }
};

const supplierType = (context, supplierType) => {
  switch (supplierType) {
    case 'interpreter':
      return {
        text: context.$gettext('interpreter'),
        color: 'green'
      };
    case 'translator':
      return {
        text: context.$gettext('Translator'),
        color: 'blue'
      };
    case 'both':
      return {
        text: context.$gettext('Both'),
        color: 'white'
      };
    case 'undefined':
      return {
        text: context.$gettext('Undecided'),
        color: 'grey'
      };
  }
};

const skillStatus = (context, supplierType) => {
  switch (supplierType) {
    case 'approved':
      return {
        text: context.$gettext('Approved'),
        color: 'green'
      };
    case 'learning':
      return {
        text: context.$gettext('Learning'),
        color: 'blue'
      };
    case 'pending':
      return {
        text: context.$gettext('Pending'),
        color: 'white'
      };
    case 'declined':
      return {
        text: context.$gettext('Declined'),
        color: 'red'
      };
  }
};

export default {
  customerType,
  supplierType,
  skillStatus
};
