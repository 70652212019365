export default () => {
  return {
    namespaced: true,
    state: {
      dialect: ''
    },
    actions: {
      setDialect({commit}, payload) {
        commit('setDialect', payload);
      }
    },
    mutations: {
      setDialect(state, value) {
        state.dialect = value;
      },
      clearStore(state) {
        state.dialect = '';
      }
    }
  };
};
