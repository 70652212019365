import BookingHelper from '@/store/booking_stores/BookingHelper';

export default () => {
  return {
    namespaced: true,
    state: {
      ownerRealName: '',
      errors: {ownerRealName: {name: 'ownerRealNameError', errors: []}}
    },
    mutations: {
      ...BookingHelper().mutations,
      setOwnerRealName(state, value) {
        state.ownerRealName = value;
      },
      clearStore(state) {
        state.ownerRealName = '';
      }
    }
  };
};
