// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/block_inter_icon_active_mob.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.interpreter-blocked-status[data-v-1871c032] {\n  position: relative;\n}\n.interpreter-blocked-status__tooltip-btn[data-v-1871c032] {\n  display: block;\n  width: 30px;\n  height: 30px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n.interpreter-blocked-status__tooltip-text[data-v-1871c032] {\n  position: absolute;\n  z-index: 1;\n  width: 400px;\n  padding: 10px;\n  border-radius: 3px;\n  background-color: #fff;\n  color: #f04;\n  -webkit-box-shadow: 0 0 9px 0 rgba(0, 34, 102, 0.3);\n          box-shadow: 0 0 9px 0 rgba(0, 34, 102, 0.3);\n  font-size: 12px;\n  text-align: left;\n}\n.interpreter-blocked-status__tooltip-text--hide-button[data-v-1871c032] {\n  position: static;\n  padding: 0;\n  -webkit-box-shadow: none;\n          box-shadow: none;\n}\n.interpreter-blocked-status__text--header[data-v-1871c032] {\n  font-weight: bold;\n}\n.interpreter-blocked-status__close-btn[data-v-1871c032] {\n  display: none;\n}\n.interpreter-blocked-status__link[data-v-1871c032] {\n  margin-top: 15px;\n  color: #333;\n  font-weight: bold;\n}\n@media (max-width: 767px) {\n.interpreter-blocked-status__close-btn[data-v-1871c032] {\n    display: block;\n}\n.interpreter-blocked-status__tooltip-text[data-v-1871c032] {\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 20;\n    width: 100%;\n    height: 100%;\n    padding-top: 30px;\n}\n}\n", ""]);
// Exports
module.exports = exports;
