(<template>
  <div ref="secretaryContactInfo"
       class="post-form__section secretary-section">
    <h3 class="post-form__field secretary-section__title">{{ title }}</h3>
    <sk-input :description="secretaryContactNameDescription"
              :placeholder="secretaryContactNamePlaceholder"
              :preselected-value="secretaryContactName"
              :validation-name="secretaryContactNameErrorName"
              class="post-form__field post-form__field--half-width secretary-section__field"
              @focus="openContactInfoHelper"
              @fieldvaluechanged="setSecretaryContactName" />
    <div class="post-form__field secretary-section__field">
      <sk-select :type="'countryCodes'"
                 :description="secretaryContactPhoneCodeText"
                 :default-value="secretaryContactPhoneCodeText"
                 :preselected-value="secretaryContactPhoneCode"
                 :mob-numeric-keyboard="true"
                 class="secretary-section__code-field"
                 @focus="openContactInfoHelper"
                 @csvaluechanged="setSecretaryContactPhoneCode" />
      <sk-input :description="secretaryContactPhoneDescription"
                :placeholder="secretaryContactPhonePlaceholder"
                :reg-exp="/[^0-9]/g"
                :mob-numeric-keyboard="true"
                :preselected-value="secretaryContactPhone"
                :validation-name="secretaryContactPhoneErrorName"
                class="post-form__field--half-width secretary-section__input"
                @focus="openContactInfoHelper"
                @fieldvaluechanged="setSecretaryContactPhone" />
    </div>
    <sk-textarea :description="secretaryInfoDescription"
                 :placeholder="secretaryInfoPlaceholder"
                 :preselected-value="secretaryInfo"
                 :validation-name="secretaryInfoErrorName"
                 class="post-form__field secretary-section__textarea"
                 @focus="openContactInfoHelper"
                 @fieldvaluechanged="setSecretaryInfo" />
    <secretary-callback-phone-section :show-assignment-helper="showAssignmentHelper" />
  </div>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';
  import SecretaryCallbackPhoneSection from '@/components/booking_components/parts/secretary_section/SecretaryCallbackPhoneSection';

  export default {
    components: {
      'secretary-callback-phone-section': SecretaryCallbackPhoneSection
    },
    props: {
      params: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    computed: {
      ...mapState('PostingStore/SecretaryStore', {
        secretaryContactName: ({secretaryContactName}) => secretaryContactName || '',
        secretaryContactPhone: ({secretaryContactPhone}) => secretaryContactPhone || '',
        secretaryContactPhoneCode: ({secretaryContactPhoneCode}) => secretaryContactPhoneCode || '',
        secretaryInfo: ({secretaryInfo}) => secretaryInfo || '',
        // errors
        secretaryContactNameErrorName: ({errors}) => errors.secretaryContactName.name,
        secretaryContactPhoneErrorName: ({errors}) => errors.secretaryContactPhone.name,
        secretaryInfoErrorName: ({errors}) => errors.secretaryInfo.name,
      }),
      title() {
        return this.$gettext('Contact information and message to be conveyed:');
      },
      secretaryContactNameDescription() {
        return this.$gettext('Name of client / patient to be contacted');
      },
      secretaryContactNamePlaceholder() {
        return this.$gettext('Name');
      },
      secretaryContactPhoneDescription() {
        return this.$gettext('Client\'s / patient\'s telephone number');
      },
      secretaryContactPhoneCodeText() {
        return this.$gettext('Telephone code');
      },
      secretaryContactPhonePlaceholder() {
        return this.$gettext('Phone');
      },
      secretaryInfoDescription() {
        return this.$gettext('Information for the interpreter');
      },
      secretaryInfoPlaceholder() {
        return this.$gettext('Ex. You have received a new appointment for an orthopedic outpatient clinic at St. Olav\'s hospital 07 June 2021, at 10.30');
      },
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    methods: {
      ...mapMutations('PostingStore/SecretaryStore', [
        'setSecretaryContactName',
        'setSecretaryContactPhone',
        'setSecretaryContactPhoneCode',
        'setSecretaryInfo',
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
      ]),
      openContactInfoHelper() {
        if (this.showAssignmentHelper) {
          this.setPopoverReference(this.$refs.secretaryContactInfo);
          this.openHelper('secretaryContactInfo');
        }
      },
    },
  };
</script>

<style>
  .secretary-section__textarea textarea {
    min-height: 80px;
  }
</style>

<style scoped>
  .secretary-section {
    margin-top: 20px;
  }

  .secretary-section__title {
    color: #000;
    font-size: 18px;
  }

  .secretary-section__field {
    display: flex;
  }

  .secretary-section__code-field {
    width: 90px;
    min-width: 90px;
    margin-right: 15px;
  }

  .secretary-section__input {
    margin-bottom: 0;
  }
</style>
