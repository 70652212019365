const sendHTMLPost = ({url, payload, method = 'post'}) => {
  const form = document.createElement('form');
  form.method = method;
  form.action = url;

  for (const key in payload) {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = payload[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
};

export default {
  sendHTMLPost,
};
