// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/assignment_types/posting/video.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/assignment_types/posting/phone.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@assets/imgs/assignment_types/posting/inperson.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@assets/imgs/assignment_types/posting/videoroom.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "\n.assignment-type[data-v-6b316d0b] {\n  display: block;\n  width: 100%;\n}\n.assignment-type__wrapper[data-v-6b316d0b] {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  width: 100%;\n  min-height: 30px;\n  padding: 10px;\n  border-radius: 3px;\n  background-color: #e6ebee;\n}\n.assignment-type__wrapper[data-v-6b316d0b]::before {\n  content: '';\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 15px;\n  display: block;\n  width: 30px;\n  background-position: 50% 50%;\n  background-repeat: no-repeat;\n}\n.assignment-type__wrapper--video[data-v-6b316d0b]::before {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-image: var(--image-assignment-type-video-icon-dark-grey);\n  background-position: 0 50%;\n  background-size: 25px auto;\n}\n.assignment-type__wrapper--phone[data-v-6b316d0b]::before {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-image: var(--image-assignment-type-phone-icon-dark-grey);\n  background-size: 22px auto;\n}\n.assignment-type__wrapper--in_person[data-v-6b316d0b]::before {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-image: var(--image-assignment-type-in-person-icon-dark-grey);\n  background-size: auto 20px;\n}\n.assignment-type__wrapper--videoroom[data-v-6b316d0b]::before {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n  background-image: var(--image-assignment-type-videoroom-icon-dark-grey);\n  background-size: auto 20px;\n}\n.assignment-type__data[data-v-6b316d0b] {\n  margin: 0 auto;\n  font-weight: bold;\n  font-size: 14px;\n}\n@media screen and (max-width: 767px) {\n.assignment-type[data-v-6b316d0b] {\n    margin-bottom: 0;\n}\n.assignment-type__data[data-v-6b316d0b] {\n    margin: 0 0 0 50px;\n}\n}\n", ""]);
// Exports
module.exports = exports;
