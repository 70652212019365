const translationJobs = {
  methods: {
    createTranslationJob(jobForm, commit, support) {
      const self = this;
      const path = support ? '/v1/support/translation_projects' : '/v1/translation_projects';
      return new Promise((resolve, reject) => {
        self.ajax.post(path, jobForm, {
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            commit('setUploadPercentage', percentage, {root: true});
          }
        }).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    }
  }
};

export default translationJobs;
