const translationSkills = {
  // ==================================================
  // ==================================================
  // Translation Skill CALLS
  // ==================================================
  // ==================================================
  methods: {
    createTranslatorSkill(form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/translation_skills', form).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error.data || {});
        });
      });
    },
    updateTranslatorSkill(form, skillId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/translation_skills/${skillId}`, form).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error.data || {});
        });
      });
    },
    removeTranslationSkill(id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.delete(`/v1/translation_skills/${id}`).then(() => {
          resolve();
        }).catch((error) => {
          this.resolveError404(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    getTranslationSkills(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/translation_skills', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
  }
};

export default translationSkills;
