(<template>
  <div class="data-section">
    <section v-for="unit in data.data"
             :key="unit.title"
             class="data-section__wrapper">
      <h2 class="data-section__title">{{ unit.title }}</h2>
      <p class="data-section__data"
         :class="[{'data-section__data--light': data.lighter}, unit.classes]"
         v-html="unit.data"></p>
      <a class="data-section__data"
         :href="unit.urlData"
         target="_blank">{{ unit.urlData }}</a>
      <div v-if="data.preview"
           class="data-section__preview"
           v-html="data.preview"></div>
    </section>
  </div>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    }
  };
</script>

<style>
  .data-section__preview .preview {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    text-overflow: ellipsis;
  }
</style>

<style src="@assets/css/data_section.css" scoped></style>
