(<template>
  <div class="open-booking-section">
    <sk-input v-if="showBookingRef"
              :description="bookingRefText"
              :preselected-value="bookingRef"
              :placeholder="bookingRefText"
              :validation-name="bookingRefErrorName"
              class="post-form__field post-form__field--half-width"
              @fieldvaluechanged="setBookingRef" />
    <sk-input v-if="showPaymentBookingRef"
              :description="paymentBookingRefText"
              :preselected-value="paymentBookingRef"
              :placeholder="paymentBookingRefText"
              :validation-name="paymentBookingRefErrorName"
              class="post-form__field post-form__field--half-width"
              @fieldvaluechanged="setPaymentBookingRef" />
    <div v-if="showCaseNumber"
         class="post-form__field post-form__field--half-width open-booking-section__field">
      <sk-input :description="caseNumberText"
                :preselected-value="caseNumber"
                :placeholder="caseNumberText"
                :validation-name="caseNumberErrorName"
                @fieldvaluechanged="setCaseNumber" />
      <p v-if="caseNumberDescription"
         class="open-booking-section__field-description">{{ caseNumberDescription }}</p>
    </div>
    <div v-if="showPaymentMethod"
         class="open-booking-section__field-group">
      <sk-select :items-list="companiesList"
                 :preselected-value="paymentCompany"
                 :description="paymentCompanyText"
                 :default-value="paymentCompanyText"
                 :validation-name="paymentCompanyErrorsName"
                 class="post-form__field post-form__field--half-width"
                 @csvaluechanged="setPaymentCompany" />
      <org-number-field v-if="showPaymentOrgNumber"
                        :description="paymentOrgNumberText"
                        :placeholder="paymentOrgNumberText"
                        :preselected-value="paymentOrgNumber"
                        :country="'Norway'"
                        :validation-name="paymentOrgNumberErrorsName"
                        class="post-form__field post-form__field--half-width"
                        @fieldvaluechanged="setPaymentOrgNumber" />
    </div>
  </div>
</template>)

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';
  import OrgNumberField from '@/components/enterprise/form_parts/OrgNumberField';

  export default {
    components: {
      'org-number-field': OrgNumberField
    },
    props: {
      params: {
        type: Object,
        default() {
          return {
            fieldsConfig: {},
            showPaymentMethodSection: false
          };
        }
      }
    },
    computed: {
      ...mapGetters('OpenBookingStore', ['organizationsList']),
      ...mapState('OpenBookingStore', {
        bookingRef: ({openBooking}) => openBooking.bookingRef,
        paymentBookingRef: ({openBooking}) => openBooking.paymentBookingRef,
        caseNumber: ({openBooking}) => openBooking.caseNumber,
        paymentCompany: ({openBooking}) => openBooking.paymentCompany,
        paymentOrgNumber: ({openBooking}) => openBooking.paymentOrgNumber,
        organization: ({openBooking}) => openBooking.organization,
        // errors
        paymentBookingRefErrorName: ({errors}) => errors.paymentBookingRef.name,
        bookingRefErrorName: ({errors}) => errors.bookingRef.name,
        caseNumberErrorName: ({errors}) => errors.caseNumber.name,
        paymentCompanyErrorsName: ({errors}) => errors.paymentCompany.name,
        paymentOrgNumberErrorsName: ({errors}) => errors.paymentOrgNumber.name,
      }),
      fieldsConfig() { return this.params.fieldsConfig || {}; },
      caseNumberText() { return (this.fieldsConfig.caseNumber && this.fieldsConfig.caseNumber.customName) || this.$gettext('Case number'); },
      caseNumberDescription() { return this.fieldsConfig.caseNumber?.customDescription || ''; },
      bookingRefText() { return (this.fieldsConfig.bookingRef && this.fieldsConfig.bookingRef.customName) || this.$gettext('Booking ref.'); },
      paymentBookingRefText() { return (this.fieldsConfig.paymentBookingRef && this.fieldsConfig.paymentBookingRef.customName) || this.$gettext('Invoice ref.'); },
      showBookingRef() { return this.fieldsConfig.bookingRef; },
      showPaymentBookingRef() { return this.fieldsConfig.paymentBookingRef; },
      showCaseNumber() { return this.fieldsConfig.caseNumber; },
      showPaymentMethod() { return this.fieldsConfig.paymentMethod && this.params.showPaymentMethodSection; },
      showPaymentOrgNumber() { return this.paymentCompany === 'someone_else'; },
      orgName() {
        const selectedOrganizationName = this.organizationsList.find((org) => this.organization == org.id)?.name || '';
        return selectedOrganizationName || this.$gettext('My company');
      },
      paymentCompanyText() { return this.$gettext('Send invoice to:'); },
      paymentOrgNumberText() { return this.$gettext('Norwegian organisation number'); },
      companiesList() {
        return [{
          id: 'my_company',
          name: this.orgName
        }, {
          id: 'someone_else',
          name: this.$gettext('Someone else')
        }];
      },
    },
    methods: {
      ...mapMutations('OpenBookingStore', [
        'setBookingRef',
        'setPaymentBookingRef',
        'setCaseNumber',
        'setPaymentCompany',
        'setPaymentOrgNumber'
      ])
    }
  };
</script>

<style scoped src="@assets/css/open_booking_section.css"></style>
