(<template>
  <div class="contract-section">
    <button class="sk-link contract-section__link"
            @click="decriptFile">{{ linkText }}</button>
  </div>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';

  export default {
    props: {
      linkName: {
        type: String,
        default: ''
      },
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userEntity']),
      ...mapState('OneAssignmentStore', {
        assignmentObj: (state) => state.storeJobObj || {}
      }),
      currentContract() {
        const pricingTemplates = this.assignmentObj.signedContract ? this.assignmentObj.signedContract.pricingTemplates || [] : [];
        return pricingTemplates.find((template) => template.key == this.userEntity) || {};
      },
      contractName() { return this.currentContract.name || ''; },
      linkText() {
        if (this.linkName) { return this.linkName; }
        const template = this.$gettext('Click here to view the payment terms for this assignment: %{fileName}');
        return this.$gettextInterpolate(template, {fileName: this.contractName});
      }
    },
    methods: {
      decriptFile() {
        this.$store.dispatch('decriptFile', this.currentContract.downloadLink).then((newLink) => {
          window.location.href = newLink;
        });
      }
    }
  };
</script>

<style scoped>
  .contract-section {
    display: block;
    width: 100%;
    margin-top: 20px;
    font-size: 12px;
  }

  .contract-section__link {
    text-align: left;
  }
</style>
