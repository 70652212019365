(<template>
  <section class="prof-tools-modal__content-wrapper">
    <sk-input v-for="(tool, index, key) in copiedTools"
              :id="index"
              :key="key"
              :description="toolNameText"
              :autofocus="key == 0"
              :preselected-value="tool"
              :margin-bottom="true"
              :placeholder="toolNameHint"
              :validation-name="toolValidationName"
              @fieldvaluechanged="catchTool" />
    <button class="sk-btn sk-btn--white prof-tools-modal__add-tool-btn"
            @click="increaseNewToolsCount">{{ $gettext("Add more tools") }}</button>
    <submit-btns :submit-handler="submitHandler"
                 :cancel-handler="closeModal" />
  </section>
</template>)

<script>
  import SubmitBtns from '@/components/profile_components/edit/ModalSubmitBtns';

  export default {
    components: {
      'submit-btns': SubmitBtns
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        newToolsCount: this.data.data.tools.length || 1,
        copiedTools: {},
        toolValidationName: 'toolName',
        toolErrors: []
      };
    },
    computed: {
      toolNameText() { return this.$gettext('Tool name'); },
      toolNameHint() { return this.$gettext('Enter tool name'); }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      catchTool(value, index) {
        this.copiedTools[index] = value;
      },
      closeModal() {
        this.$emit('closemodal');
      },
      stopProgress() {
        this.$emit('stopprogress');
      },
      submitHandler() {
        const finalArr = [];
        this.toolErrors = [];
        let translationTools = [];

        for (let i = 0, lim = Object.keys(this.copiedTools).length; i < lim; i += 1) {
          if (this.copiedTools[i]) {
            finalArr.push({tool: this.copiedTools[i]});
          }
        }
        if (finalArr) translationTools = {translation_tools: finalArr};

        if (!finalArr.length) {
          this.toolErrors.push(this.$gettext('Must be filled'));
        }

        this.$store.commit('ErrorsStore/setError', {name: this.toolValidationName, errors: this.toolErrors});

        if (!this.toolErrors.length) {
          this.$emit('startprogress');
          this.data.context.updateTools(translationTools);
          this.data.context.$on('updatefailed', this.stopProgress);
          this.data.context.$on('updatesuccess', this.closeModal);
        }
      },
      increaseNewToolsCount() {
        this.newToolsCount += 1;
        this.$set(this.copiedTools, this.newToolsCount - 1, '');
      }
    },
    beforeMount() {
      if (this.data.data.tools.length) {
        for (let i = 0, lim = this.data.data.tools.length; i < lim; i += 1) {
          this.$set(this.copiedTools, i, this.data.data.tools[i]);
        }
      } else {
        this.$set(this.copiedTools, 0, '');
      }
    }
  };
</script>

<style scoped>
.prof-tools-modal__content-wrapper {
  display: block;
  width: 100%;
  padding: 35px;
  padding-top: 10px;
}

.prof-tools-modal__add-tool-btn {
  display: inline-block;
  width: auto;
  margin-top: 10px;
  padding: 0 10px;
}
</style>
