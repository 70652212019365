const payoutMethods = {
  methods: {
    // ==================================================
    // ==================================================
    // PAYOUTS
    // ==================================================
    // ==================================================
    getPayouts() {
      const self = this;

      return new Promise((resolve, reject) => {
        self.financeAjax.get('payout_methods').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    setDefaultPayoutMethod(id, type) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.financeAjax.put(`payout_methods/${type}/${id}`, {payout: {default: true}}).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    newPayoutMethod(form) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.financeAjax.post('payout_methods/dipps_profiles', form).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    deletePayout(id, type) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.financeAjax.delete(`payout_methods/${type}/${id}`).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getRates() {
      return new Promise((resolve, reject) => {
        this.ajax.get('v1/publications').then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          const resp = error ? error.response : {};
          this.resolveError403(error, resolve);
          reject(resp);
        });
      });
    },
    getRatesDoc(id) {
      return new Promise((resolve, reject) => {
        this.ajax.get(`/v1/publications/${id}/download_link`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          const resp = error ? error.response : {};
          reject(resp.data);
        });
      });
    },
  }
};

export default payoutMethods;
