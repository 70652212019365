const convertCurrentTime = (moment) => {
  let res = moment().minute(+moment().minute() + 5).format('HH:mm');
  const [hours, minutes] = res.split(':');
  if (Number(minutes) >= 0 && Number(minutes) <= 30) {
    res = `${hours}:30`;
  } else if (Number(minutes) > 30) {
    res = `${Number(hours) + 1}:00`;
  }
  return res;
};

const setDate = (moment, {currentTime, value}) => {
  const valueArr = value.split('-');
  valueArr[1] -= 1;
  const hours = currentTime ? moment(currentTime).hours() : 0;
  const minutes = currentTime ? moment(currentTime).minutes() : 0;
  const momentInst = moment(valueArr);
  return moment(momentInst)
    .hours(+hours)
    .minutes(+minutes)
    .seconds(0)
    .milliseconds(0);
};

const setTime = (moment, {currentTime, value}) => {
  const [hours, minutes] = value.split(':');
  const momentInst = currentTime ? moment(currentTime) : moment();
  return moment(momentInst)
    .hours(+hours)
    .minutes(+minutes)
    .seconds(0)
    .milliseconds(0);
};

export default {
  convertCurrentTime,
  setDate,
  setTime,
};
