<template>
  <div :class="{'in-progress': progressActive}"
       class="video-form is-progress-bar">
    <div class="video-form__radios-wrapper">
      <div v-for="choice in choicesList"
           :key="choice.id"
           class="video-form__radio-wrapper">
        <sk-radio :item-name="'videoPlatform'"
                  :preselected-value="videoPlatformType"
                  :item-value="choice.id"
                  :item-text="choice.title"
                  class="video-form__radio"
                  @radiochanged="setVideoPlatformType" />
        <div v-if="choice.subtitle"
             class="video-form__radio-subtitle"
             v-html="choice.subtitle"></div>
      </div>
    </div>
    <template v-if="videoPlatformType === 'own'">
      <div class="video-form__section">
        <sk-select :items-list="computedVideoPlatformList"
                   :description="videoPlatformText"
                   :default-value="videoPlatformText"
                   :preselected-value="videoPlatformId"
                   :validation-name="videoPlatformIdErrorName"
                   class="video-form__item"
                   @csvaluechanged="setVideoPlatformId" />
        <sk-checkbox :item-text="videoConfirmationText"
                     :preselected-value="videoConfirmationValue"
                     :validation-name="videoConfirmationValueErrorName"
                     :class="{'is-with-error': showVideoConfirmationErrors}"
                     class="sk-checkbox--top-alignment video-form__item"
                     @checkboxvaluechanged="setVideoPlatformConfirmation" />
        <div v-if="showVideoConfirmationErrors"
             class="sk-input__errors-cont">
          <span v-for="(error, index) in videoConfirmationValueErrors"
                :key="index"
                v-html="(videoConfirmationValueErrors.length === 1) ? error : `${index + 1}. ${error}`"></span>
        </div>
        <sk-textarea :description="videoInstructionsText"
                     :placeholder="videoInstructionsPlaceholder"
                     :preselected-value="videoPlatformInstructions"
                     class="video-form__textarea"
                     @fieldvaluechanged="setVideoPlatformInstructions" />
      </div>
      <sk-input :preselected-value="videoPlatformUrl"
                :description="videoPlatformUrlText"
                :placeholder="videoPlatformUrlText"
                class="video-form__input"
                @fieldvaluechanged="setVideoPlatformUrl" />
    </template>
    <check-video v-else
                 class="video-form__item" />
  </div>
</template>

<script>
  import {mapActions, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import CheckVideo from '@/components/assignment_components/one_assignment/CheckVideo';

  export default {
    components: {
      'check-video': CheckVideo,
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapState('PostingStore/AssignmentTypeStore', {
        videoPlatformsList: ({videoPlatformsList}) => videoPlatformsList || [],
        videoPlatformType: ({videoPlatform}) => videoPlatform.type,
        videoPlatformId: ({videoPlatform}) => videoPlatform.id,
        videoPlatformInstructions: ({videoPlatform}) => videoPlatform.instructions,
        videoPlatformUrl: ({videoPlatform}) => videoPlatform.url || '',
        videoConfirmationValue: ({videoPlatform}) => videoPlatform.confirmationValue,
        // errors
        videoPlatformIdErrorName: ({errors}) => errors.videoPlatformId.name,
        videoConfirmationValueErrorName: ({errors}) => errors.videoPlatformConfirmationValue.name,
        videoConfirmationValueErrors: ({errors}) => errors.videoPlatformConfirmationValue.errors
      }),
      choicesList() {
        return [{
          id: 'default',
          title: helpers.getJobInfo.videoPlatformType(this, 'default'),
        }, {
          id: 'own',
          title: helpers.getJobInfo.videoPlatformType(this, 'own'),
        }];
      },
      computedVideoPlatformList() {
        if (this.videoPlatformsList.length) {
          // Add other option
          let copyList = [...this.videoPlatformsList];
          copyList.push({id: 'other', name: this.$gettext('Other'), default: false});
          // remove default platform
          copyList = copyList.filter((platform) => platform.default === false);
          return copyList;
        }
        return this.videoPlatformsList;
      },
      videoPlatformText() {
        return this.$gettext('Which video system do you want to use?');
      },
      videoConfirmationText() {
        return this.$gettext('I will provide a video link and instructions to the interpreter. <b>Either through the text field below</b> or by emailing them the instructions after the interpreter is confirmed.');
      },
      videoInstructionsText() {
        return this.$gettext('Instructions for the interpreter');
      },
      videoInstructionsPlaceholder() {
        return this.$gettext('Write here...');
      },
      videoPlatformUrlText() { return this.$gettext('Video platform URL'); },
      showVideoConfirmationErrors() {
        return !!this.videoConfirmationValueErrors.length;
      }
    },
    methods: {
      ...mapActions('PostingStore', [
        'removeOneError'
      ]),
      ...mapActions('PostingStore/AssignmentTypeStore', [
        'getVideoPlatformsList'
      ]),
      ...mapMutations('PostingStore/AssignmentTypeStore', [
        'setVideoPlatformType',
        'setVideoPlatformId',
        'setVideoPlatformInstructions',
        'setVideoPlatformUrl',
        'setVideoPlatformConfirmationValue'
      ]),
      setVideoPlatformConfirmation(value) {
        this.removeOneError({
          storeName: 'PostingStore',
          subStoreName: 'AssignmentTypeStore',
          fieldName: 'videoPlatformConfirmationValue'
        });
        this.setVideoPlatformConfirmationValue(value);
      },
      setConfirmationValue(value) {
        this.confirmationValue = value;
      }
    },
    beforeMount() {
      if (!this.videoPlatformsList.length) {
        this.progressActive = true;
        this.getVideoPlatformsList().then(() => {
          this.progressActive = false;
        }).catch(() => {
          this.progressActive = false;
        });
      }
    }
  };
</script>

<style>
  .video-form__textarea .sk-input__textarea {
    min-height: 80px;
  }

  .video-form .sk-select,
  .video-form .sk-input__textarea,
  .video-form .sk-input__input {
    background-color: #fff;
  }
</style>

<style scoped>
  .video-form {
    width: 100%;
    text-align: left;
  }

  .video-form__radios-wrapper {
    padding: 20px 0;
    border-bottom: 1px solid #d3d5de;
  }

  #app.salita-facelifted .video-form__radios-wrapper {
    border-bottom: 0;
  }

  .video-form__radio-wrapper {
    margin-top: 10px;
  }

  .video-form__radio-subtitle {
    padding-left: 25px;
    color: #747577;
    font-size: 12px;
  }

  .video-form__textarea-title {
    margin-top: 20px;
    font-size: 12px;
  }

  .video-form__textarea {
    margin-top: 10px;
  }

  .video-form__section {
    padding: 1px 10px;
    border-radius: 5px;
    background-color: var(--color-warning-200, #e2d2bb);
  }

  #app.salita-facelifted .video-form__section {
    margin: 20px 0;
    border-radius: 4px;
  }

  .video-form__item {
    margin-top: 20px;
  }
</style>
