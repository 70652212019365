(<template>
  <div class="search-by-descr"
       :class="{'lang-filter': languagesFilter}">
    <div v-for="filter in appliedFiltersList"
         :key="filter.label"
         class="search-by-descr__filter">
      <span v-if="filter.label">{{ filter.label }}</span>
      <b v-if="filter.value">{{ filter.value }}</b>
    </div>
  </div>
</template>)

<script>
  import helpers from '@/helpers';
  import {mapGetters, mapState} from 'vuex';

  export default {
    computed: {
      ...mapGetters('PostingStore/ProcessRequirementStore', ['userNeedSpecificQualificationLevel']),
      ...mapState('PostingStore/LanguagesStore', {
        langFrom: (state) => state.languageFrom,
        langTo: (state) => state.languageTo
      }),
      ...mapState('PostingStore/QualificationStore', {
        assignmentQualification: (state) => state.qualification
      }),
      ...mapState('PostingStore/GenderStore', {
        assignmentGender: (state) => state.sex
      }),
      qualificationName() {
        return helpers.getJobInfo.getQualificationName(this, {id: this.assignmentQualification});
      },
      selectedGenderText() {
        return helpers.getJobInfo.getGenderName(this, this.assignmentGender);
      },
      languagesFilter() {
        return this.langFrom || this.langTo;
      },
      appliedFilters() {
        return [
          {
            label: this.$gettext('From:'),
            value: this.$getLangName(this.langFrom),
            condition: this.langFrom
          },
          {
            label: this.$gettext('to:'),
            value: this.$getLangName(this.langTo),
            condition: this.langTo
          },
          {
            label: this.$gettext('Qualification level:'),
            value: this.qualificationName,
            condition: this.userNeedSpecificQualificationLevel && this.assignmentQualification
          },
          {
            label: this.$gettext('Gender:'),
            value: this.selectedGenderText,
            condition: this.selectedGenderText
          }
        ];
      },
      appliedFiltersList() {
        return this.appliedFilters.filter(({condition}) => condition);
      }
    }
  };
</script>

<style scoped>
  .search-by-descr__text {
    margin-bottom: 15px;
  }

  .search-by-descr__filter {
    margin-bottom: 5px;
    font-size: 12px;
  }

  .lang-filter {
    display: flex;
    gap: 3px;
  }

  .search-by-descr__filter:last-child {
    margin-bottom: 0;
  }
</style>
