<template>
  <div class="post-assignment">
    <template v-if="userCanCreateEditJobs">
      <div class="post-assignment__helper-wrapper">
        <div>
          <div class="post-assignment__wrapper is-progress-bar"
               :class="{'in-progress': postAssignmentProgress}">
            <!-- main fields  -->
            <div class="post-assignment__header-wrapper">
              <h1 class="post-assignment__title">{{ title }}</h1>
              <div v-if="!isMobileView"
                   class="assignment-helper-toggler">
                <sk-toggler :preselected-value="assignmentHelperEnabled"
                            @togglerchanged="toggleAssignmentHelper" />
                <div class="assignment-helper-toggler__description">
                  <span>{{ $gettext('Show help') }}</span>
                  <sk-tooltip v-if="!enableFaceliftedDesign"
                              :item-text="assignmentHelperTooltipText"
                              class="helper-toggler__tooltip" />
                </div>
              </div>
            </div>
            <div ref="buyerInfoSectionRef">
              <buyer-info-section v-if="showBuyerInfoSection"
                                  :title="customerInfoSectionTitle"
                                  :progress="bookingFormProgress || postAssignmentProgress"
                                  class="post-assignment__buyer-section" />
            </div>
            <invoice-info-section :title="invoiceInfoSectionTitle"
                                  class="post-assignment__invoice-section"
                                  :progress="bookingFormProgress || postAssignmentProgress" />
            <send-by-mail-section v-if="isTranslationBooking && showSendByPostTranslation" />
            <post-section>
              <booking-tabs v-if="!mainLinesTitle"
                            :is-translation="isTranslationBooking"
                            class="post-assignment__tabs" />
              <sk-note v-if="mainLinesNote"
                       :text="mainLinesNote"
                       class="post-assignment__note" />
              <booking-group-fields :title="mainLinesTitle"
                                    :lines-list="mainLines" />
            </post-section>
            <!-- additional fields  -->
            <div v-if="showAdditionalLines"
                 class="post-assignment__subsection">
              <h2 class="post-assignment__subtitle"
                  v-html="subTitle"></h2>
              <booking-group-fields :lines-list="additionalLines"
                                    class="post-assignment__subfields" />
            </div>
            <!-- admin fields  -->
            <post-section v-if="showAdminLines"
                          class="post-section--dark">
              <booking-group-fields :title="adminInfoSectionTitle"
                                    :lines-list="adminLines" />
            </post-section>
          </div>
          <booking-footer :progress="bookingFormProgress"
                          :submit-button-text="submitBtnText"
                          :text-params="footerTextParams"
                          :validate-func="validatePage"
                          :error-handling-func="handleError"
                          :success-handling-func="handleSuccess"
                          :send-info-func="sendInfoFunc"
                          :summary-obj="summaryObj" />
        </div>
        <div v-if="assignmentHelperEnabled && !isMobileView"
             class="post-assignment-helper__wrapper">
          <post-assignment-helper v-if="isHelperVisible && !isMobileView"
                                  :style="helperCardStyle"
                                  class="post-assignment-helper" />
        </div>
      </div>
    </template>
    <template v-else>
      <no-rights />
    </template>
  </div>
</template>

<script>
  import PostingCommon from '@/prototypes/PostingCommon';
  import PostSection from '@/components/booking_components/PostSection';
  import BookingTabs from '@/components/booking_components/BookingTabs';
  import BookingGroupFields from '@/components/booking_components/BookingGroupFields';
  import BookingFooter from '@/components/booking_components/BookingFooter';
  import BuyerInformationSection from '@/components/booking_components/BuyerInformationSection';
  import PostAssignmentHelper from '@/components/booking_components/PostAssignmentHelper';
  import InvoiceInformationSection from '@/components/booking_components/InvoiceInformationSection';
  import SendByMailSection from '@/components/booking_components/parts/extra_info_section/SendByMailSection';
  import NoRights from '@/components/shared_components/NoRights';
  import {mapMutations, mapState, mapGetters} from 'vuex';

  export default {
    components: {
      'booking-footer': BookingFooter,
      'booking-tabs': BookingTabs,
      'buyer-info-section': BuyerInformationSection,
      'invoice-info-section': InvoiceInformationSection,
      'post-section': PostSection,
      'booking-group-fields': BookingGroupFields,
      'no-rights': NoRights,
      'post-assignment-helper': PostAssignmentHelper,
      'send-by-mail-section': SendByMailSection
    },
    extends: PostingCommon,
    data() {
      return {
        postAssignmentProgress: false,
        assignmentHelperEnabled: true
      };
    },
    computed: {
      ...mapState('PostAssignmentHelperStore', {
        isHelperVisible: ({isHelperVisible}) => isHelperVisible || false,
        popoverReference: ({popoverReference}) => popoverReference || {}
      }),
      ...mapGetters('UserInfoStore', ['showSendByPostTranslation']),
      ...mapGetters('FeatureFlagsStore', ['enableFaceliftedDesign']),
      helperCardStyle() {
        let topOffset = 0;

        let focusedElement = '';
        if (Array.isArray(this.popoverReference) && this.popoverReference[0]?.$el) {
          focusedElement = this.popoverReference[0].$el;
        } else if (Array.isArray(this.popoverReference) && !this.popoverReference[0]?.$el) {
          focusedElement = this.popoverReference[0];
        } else if (this.popoverReference[0]?.$el) {
          focusedElement = this.popoverReference.$el;
        } else if (this.popoverReference.$el) {
          focusedElement = this.popoverReference.$el;
        } else if (this.popoverReference) {
          focusedElement = this.popoverReference;
        }

        if (focusedElement) {
          const focusedElementRect = focusedElement?.getBoundingClientRect ? focusedElement?.getBoundingClientRect() : undefined;
          topOffset = (focusedElementRect?.top || 0) + window.pageYOffset - 58.5;
        }

        if (topOffset < 0) {
          return {
            top: '105px'
          };
        }

        return {
          top: `${topOffset}px`
        };
      },
      title() {
        return this.$gettext('Order form');
      },
      subTitle() {
        return this.$gettext('<b>Additional options</b> (Not required)');
      },
      customerInfoSectionTitle() {
        return this.$gettext('Customer information');
      },
      invoiceInfoSectionTitle() {
        return this.$gettext('Invoice information');
      },
      mainLinesNote() {
        return '';
      },
      personalInfoWarning() {
        return this.$gettext('Please do not provide any personal information while placing the order. This information is shared with several freelancers as they decide if they would like to take on the assignment.');
      },
      mainLinesTitle() {
        return '';
      },
      showAdditionalLines() {
        return this.checkShowingLines(this.additionalLines);
      },
      showBuyerInfoSection() {
        return this.defaultPaymentMethodData.id
          || this.$store.getters['UserInfoStore/userIsEnterpriseMember'];
      },
      showBlockedCitiesSection() {
        return this.$isGodmode();
      },
      showAdminLines() {
        return this.checkShowingLines(this.adminLines);
      },
      submitBtnText() {
        return this.$route.query.dangerousEdit === 'true'
          ? this.$gettext('Rebook Assignment')
          : this.$gettext('Book');
      },
      assignmentHelperTooltipText() {
        return this.$gettext('Activate or deactivate help text here.');
      },
      isMobileView() { return this.$store.state.GlobalStore.clientWidth < 768; },
      isTranslationBooking() { return this.$route.path.includes('projects'); }
    },
    watch: {
      isMobileView() {
        if (this.isHelperVisible) {
          setTimeout(() => {
            this.closeHelper();
            this.setPopoverReference(this.$refs.buyerInfoSectionRef);
            this.openHelper('');
          });
        }
      }
    },
    methods: {
      ...mapMutations('PostAssignmentHelperStore', [
        'closeHelper',
        'openHelper',
        'setPopoverReference',
        'setIsHelperVisible'
      ]),
      checkShowingLines(lines) {
        if (lines && lines.length) {
          return lines.find((line = {}) => {
            if (line.components && line.components.length) {
              return line.components.find((component) => {
                return component.condition !== undefined ? component.condition : true;
              });
            }
          });
        }
      },
      openEmptyAssignmentHelper(assignmentHelperEnabled) {
        if (assignmentHelperEnabled) {
          this.setPopoverReference(this.$refs.buyerInfoSectionRef);
          this.openHelper('');
        }
      },
      toggleAssignmentHelper() {
        this.closeHelper();
        this.assignmentHelperEnabled = !this.assignmentHelperEnabled;
        this.$cookieManager.setCookie('assignmentHelperEnabled', this.assignmentHelperEnabled, {path: '/', expires: 2592000});
        this.setIsHelperVisible(this.assignmentHelperEnabled);
        if (this.assignmentHelperEnabled) {
          setTimeout(() => {
            this.openEmptyAssignmentHelper(this.assignmentHelperEnabled);
          });
        }
      },
      handleWindowResize() {
        this.closeHelper();
        this.setPopoverReference(this.$refs.buyerInfoSectionRef);
        this.openHelper('');
      }
    },
    mounted() {
      if (this.$cookieManager.getCookie('assignmentHelperEnabled')) {
        const assignmentHelperEnabled = JSON.parse(this.$cookieManager.getCookie('assignmentHelperEnabled'));
        this.assignmentHelperEnabled = assignmentHelperEnabled;
        this.setIsHelperVisible(assignmentHelperEnabled);
        if (this.dataPromise) {
          this.dataPromise.then(() => {
            setTimeout(() => {
              this.openEmptyAssignmentHelper(assignmentHelperEnabled);
            }, 500);
          });
        } else {
          this.openEmptyAssignmentHelper(assignmentHelperEnabled);
        }
      }
      window.addEventListener('resize', this.handleWindowResize);
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('PostAssignmentHelperStore/clearStore');
      window.removeEventListener('resize', this.handleWindowResize);
      next();
    },
  };
</script>

<style>
.post-assignment__subtitle b {
  color: #333;
}
</style>

<style scoped>
.post-assignment {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: #fff;
}

#app.salita-facelifted .post-assignment {
  background-color: inherit;
}

.post-assignment::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 200px;
  background-color: #f4f5f7;
}

.post-assignment__header-wrapper {
  display: inline-flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.post-assignment__title {
  margin-top: 20px;
  font-size: 25px;
}

.post-assignment__subtitle {
  margin-bottom: 20px;
  color: #999;
}

.post-assignment__subsection {
  padding-top: 20px;
  padding-bottom: 30px;
}

.post-assignment__subfields {
  padding: 0 15px 5px;
}

#app.salita-facelifted .post-assignment__subfields {
  padding: 0;
}

.post-assignment__buyer-section,
.post-assignment__invoice-section {
  margin-top: 20px;
}

.post-assignment__note {
  margin-bottom: 20px;
}

.post-assignment-helper {
  position: absolute;
  margin-left: 25px;
}

.post-assignment-helper__wrapper {
  width: 400px;
}

.assignment-helper-toggler {
  display: flex;
  align-items: center;
}

.assignment-helper-toggler__description {
  display: flex;
  align-items: center;
}

.assignment-helper-toggler__description span {
  margin: 0 4px 0 8px;
  color: #000;
}

.helper-toggler__tooltip {
  width: 18px;
  height: 18px;
  background-image: url(~@assets/imgs/undefined_imgs/info_icon_big.svg);
  background-position: 50% 50%;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .post-assignment__subfields {
    padding: 0 10px 5px;
  }
}
</style>
