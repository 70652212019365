(<template>
  <div class="post-form__section edit-note-section">
    <div class="post-form__field">{{ noteText }}</div>
  </div>
</template>)

<script>
  import helpers from '@/helpers';

  export default {
    props: {
      params: {
        type: Object,
        default: () => {
          return {
            jobStatus: ''
          };
        }
      }
    },
    computed: {
      noteText() {
        const restrictEditingImportantFields = helpers.editAssignmentRestrictions.restrictEditingImportantFields(
          this.params.jobStatus,
          this.$store.getters['UserInfoStore/userIsNotInterpreter'],
          this.$isGodmode()
        );
        const commonText = this.$gettext('This assignment is billable and some fields cannot be edited.');
        const specificText = !restrictEditingImportantFields
          ? this.$gettext('Please cancel this assignment and order a new one if you wish to change the date and/or time.')
          : '';

        return `${commonText} ${specificText}`.trim();
      }
    }
  };
</script>

<style scoped>
  .edit-note-section {
    font-weight: bold;
  }
</style>
