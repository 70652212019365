(<template>
  <div class="phone-modal">
    <div v-if="stepText"
         class="phone-modal__cont">
      <div class="phone-modal__indicator-wrapper">
        <div :class="{'completed': numberStep}"
             class="indicator-item">
          <div class="indicator-counter">{{ $gettext('1') }}</div>
          <div class="indicator-name">{{ $gettext('Phone number') }}</div>
        </div>
        <div :class="{'completed': codeStep}"
             class="indicator-item">
          <div class="indicator-counter">{{ $gettext('2') }}</div>
          <div class="indicator-name">{{ $gettext('Verification code') }}</div>
        </div>
      </div>
    </div>
    <p v-if="stepText"
       class="phone-modal__explanation"
       v-html="stepText"></p>
    <form v-if="numberStep"
          class="phone-modal__step"
          @submit.stop.prevent="sendPhone">
      <div class="phone-modal__fields-cont">
        <sk-select :type="'countryCodes'"
                   :description="text1"
                   :default-value="text1"
                   :preselected-value="telCode"
                   :validation-name="telCodeErrorsName"
                   :disabled="disablePhoneCodeField"
                   :mob-numeric-keyboard="true"
                   class="phone-modal__code-select"
                   @csvaluechanged="catchTelCode" />
        <sk-input :description="text2"
                  :preselected-value="telNumber"
                  :placeholder="text2"
                  :autofocus="true"
                  :validation-name="telNumberErrorsName"
                  :type="'tel'"
                  :reg-exp="/[^0-9]/g"
                  :mob-numeric-keyboard="true"
                  class="phone-modal__number-input"
                  @fieldvaluechanged="catchTelNumber" />
      </div>
      <button :class="{'is-disabled': disableNumberBtn}"
              type="submit"
              class="sk-btn sk-btn--default phone-modal__submit-btn">{{ $gettext('Verify mobile number') }}</button>
    </form>
    <form v-if="codeStep"
          class="phone-modal__step"
          @submit.stop.prevent="sendCode">
      <div class="phone-modal__fields-cont">
        <sk-input :description="text3"
                  :preselected-value="smsCode"
                  :placeholder="text4"
                  :autofocus="true"
                  :validation-name="smsCodeErrorsName"
                  :type="'tel'"
                  :reg-exp="/[^0-9]/g"
                  :mob-numeric-keyboard="true"
                  class="phone-modal__number-input"
                  @fieldvaluechanged="catchSMSCode" />
      </div>
      <button :class="{'is-disabled': disableCodeBtn}"
              class="sk-btn sk-btn--default phone-modal__submit-btn">{{ $gettext('Confirm my mobile number') }}</button>
      <p class="phone-modal__resend-text">
        {{ $gettext('Didn\'t receive an SMS? Enter your mobile number again') }}
        <button class="sk-link"
                @click="stepBack">{{ $gettext('here') }}</button>
      </p>
    </form>
    <div v-if="!stepText"
         class="phone-modal__step">
      <div class="phone-modal__success-img"></div>
      <p class="phone-modal__success-text"
         v-html="successText"></p>
      <button class="sk-btn sk-btn--default phone-modal__submit-btn"
              @click="closeModal">{{ $gettext('Ok') }}</button>
    </div>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        telCode: this.data.data.phoneCode || '+47',
        telCodeErrors: [],
        telCodeErrorsName: 'codeError',
        telNumber: this.data.data.phoneNumber || '',
        telNumberErrors: [],
        telNumberErrorsName: 'numberError',
        smsCode: '',
        smsCodeErrors: [],
        smsCodeErrorsName: 'smsError',
        currentStep: 'number'
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid', 'userIsInterpreter']),
      stepText() {
        if (this.numberStep) {
          return this.userIsInterpreter ? this.interpreterVerifyText : this.customerVerifyText;
        } else if (this.codeStep) {
          return this.$gettext('We have sent you an SMS.<br>Please enter the code:');
        }
      },
      disableNumberBtn() {
        return !(this.telCode.trim()
          && !this.telCodeErrors.length
          && this.telNumber.trim()
          && !this.telNumberErrors.length);
      },
      disableCodeBtn() {
        return this.smsCode.trim().length < 4;
      },
      disablePhoneCodeField() {
        return this.userIsInterpreter && this.telCode === '+47';
      },
      successText() {
        const customerSuccess = this.$gettext('Congratulations! Your mobile number has been verified.');
        const interpreterSuccess = this.userIsInterpreter ? this.$gettext('</br>You can now accept assignments') : '';
        return customerSuccess + interpreterSuccess;
      },
      interpreterVerifyText() {
        return this.$gettext('Please enter and verify your mobile number to be able to accept assignments.');
      },
      customerVerifyText() {
        return this.$gettext('Please confirm your phone number to continue. It is important for us to be able to contact you at short notice in the event of any changes to your assignments.');
      },
      numberStep() {
        return this.currentStep == 'number';
      },
      codeStep() {
        return this.currentStep == 'code';
      },
      text1() { return this.$gettext('Code'); },
      text2() { return this.$gettext('Phone number'); },
      text3() { return this.$gettext('Code from SMS'); },
      text4() { return this.$gettext('Enter code here'); }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.telCodeErrors = [];
        this.telNumberErrors = [];
        this.smsCodeErrors = [];
        this.$emit('closemodal');
        if (!this.stepText && this.data.data.callback) {
          this.data.context[this.data.data.callback](this.data.data.price);
        }
      },
      stopProgress() {
        this.$emit('stopprogress');
      },
      catchTelCode(value) {
        this.telCodeErrors = [];
        this.telCode = value;
      },
      catchTelNumber(value) {
        this.telNumberErrors = [];
        this.telNumber = value;
      },
      catchSMSCode(value) {
        this.smsCodeErrors = [];
        this.smsCode = value;
      },
      sendPhone() {
        this.telCodeErrors = [];
        this.telNumberErrors = [];
        if (!this.telCode.trim()) this.telCodeErrors.push(this.$gettext('Please choose a country code or skip this step for now.'));
        if (!this.telNumber.trim()) this.telNumberErrors.push(this.$gettext('Enter a phone number or skip this step for now.'));
        this.$store.commit('ErrorsStore/setError', {
          name: this.telCodeErrorsName,
          errors: this.telCodeErrors
        });
        this.$store.commit('ErrorsStore/setError', {
          name: this.telNumberErrorsName,
          errors: this.telNumberErrors
        });

        if (this.telCode.trim()
          && !this.telCodeErrors.length
          && this.telNumber.trim()
          && !this.telNumberErrors.length) {
          this.$emit('startprogress');
          const form = new FormData();
          form.append('phone_code', this.telCode.trim());
          form.append('phone_number', this.telNumber.trim());
          if (this.data.data.isAlternative) {
            form.append('alternative_phone', true);
          }
          this.$store.dispatch('sendPhoneVerificationSMS', form).then(() => {
            this.stopProgress();
            this.currentStep = 'code';
          }).catch((error) => {
            this.stopProgress();
            if (error && error.errors && error.errors.person) {
              error.errors = error.errors.person;
            }
            if (error
              && error.errors
              && error.errors.phone_number
              && error.errors.phone_number.length
              && error.errors.phone_number.indexOf('Invalid phone') !== -1) {
              this.telNumberErrors.push(this.$gettext('Please enter a valid phone number.'));
              this.$store.commit('ErrorsStore/setError', {name: this.telNumberErrorsName, errors: this.telNumberErrors});
            }
          });
        }
      },
      sendCode() {
        this.smsCodeErrors = [];
        if (this.smsCode.trim().length < 4) this.smsCodeErrors.push(this.$gettext('The code should contain 4 digits'));
        this.$store.commit('ErrorsStore/setError', {
          name: this.smsCodeErrorsName,
          errors: this.smsCodeErrors
        });
        if (!this.smsCodeErrors.length) {
          this.$emit('startprogress');
          this.$store.dispatch('sendSMSCode', this.smsCode).then((result) => {
            if (result.verified) {
              this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid).then(() => {
                this.$store.dispatch('EditProfileStore/getUserProfile', {id: this.userUid}).then(() => {
                  this.stopProgress();
                  this.currentStep = '';
                });
              });
            } else {
              this.handleCodeError();
            }
          }).catch(() => {
            this.handleCodeError();
          });
        }
      },
      handleCodeError() {
        this.stopProgress();
        this.smsCodeErrors.push(this.$gettext('The code is not valid'));
        this.$store.commit('ErrorsStore/setError', {name: this.smsCodeErrorsName, errors: this.smsCodeErrors});
      },
      stepBack() {
        this.smsCodeErrors = [];
        this.smsCode = '';
        this.currentStep = 'number';
      }
    }
  };
</script>

<style>
  .phone-modal__code-select .sk-select__list {
    top: auto;
    bottom: 100%;
    max-height: 200px;
  }
</style>

<style scoped>
  .phone-modal {
    margin-top: 20px;
  }

  .phone-modal__cont {
    padding: 10px 0;
    background-color: #f8f8fa;
  }

  .phone-modal__explanation {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding: 0 40px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
    text-align: center;
  }

  .phone-modal__step {
    position: relative;
    display: block;
    width: 100%;
    padding: 30px;
  }

  .phone-modal__fields-cont {
    display: flex;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .phone-modal__code-select {
    flex-shrink: 0;
    width: 150px;
    max-width: 40%;
    margin-right: 10px;
  }

  .phone-modal__number-input {
    flex-grow: 1;
  }

  .phone-modal__submit-btn {
    display: block;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
  }

  .phone-modal__resend-text {
    display: block;
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    padding-top: 20px;
    text-align: center;
  }

  .phone-modal__success-text {
    display: block;
    width: 100%;
    padding: 30px;
    font-size: 18px;
    text-align: center;
  }

  .phone-modal__success-img {
    width: 150px;
    height: 150px;
    margin: auto;
    background-image: url(~@assets/imgs/undefined_imgs/add_phone_step_3.svg);
  }

  .phone-modal__indicator-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: 20px;
  }

  .indicator-item {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }

  .indicator-item::before {
    content: "";
    position: absolute;
    top: 20px;
    left: -50%;
    z-index: 2;
    width: 100%;
    border-bottom: 2px solid #ccc;
  }

  .indicator-item::after {
    content: "";
    position: absolute;
    top: 20px;
    left: 50%;
    z-index: 2;
    width: 100%;
    border-bottom: 2px solid #ccc;
  }

  .indicator-item .indicator-counter {
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-bottom: 6px;
    border: solid 1px #ccc;
    border-radius: 50%;
    background-color: #fff;
  }

  .indicator-item.active {
    font-weight: bold;
  }

  .indicator-item.completed .indicator-counter {
    background-color: #008489;
    color: white;
  }

  .indicator-item.completed::after {
    content: "";
    position: absolute;
    top: 20px;
    left: 50%;
    z-index: 3;
    width: 100%;
  }

  .indicator-item:first-child::before {
    content: none;
  }

  .indicator-item:last-child::after {
    content: none;
  }

  @media (max-width: 768px) {
    .indicator-item {
      font-size: 12px;
    }
  }
</style>
