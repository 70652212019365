(<template>
  <post-accordion-section ref="accessLevelSection"
                          :state="accessLevelSelect"
                          :title="title"
                          :description="description"
                          :disabled="disabledFields"
                          class="access-level-section"
                          @focusin="openAccessLevelHelper"
                          @statechanged="updateAccessLevel">
    <div v-if="accessLevelSelect"
         class="post-form__field-group access-level__select-wrapper">
      <sk-select :items-list="accessLevels"
                 :description="accessLevelText"
                 :preselected-value="accessLevel"
                 :default-value="placeholder"
                 :validation-name="accessLevelErrorsName"
                 :disabled="disabledFields"
                 class="post-form__field post-form__field--half-width access-level__select"
                 @csvaluechanged="setAccessLevel" />
    </div>
  </post-accordion-section>
</template>)

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import PostAccordionSection from '@/components/booking_components/PostAccordionSection';

  export default {
    components: {
      'post-accordion-section': PostAccordionSection
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'enterpriseId',
        'isEmailVerificationRequired'
      ]),
      ...mapState('PostingStore/AccessLevelStore', {
        accessLevel: ({accessLevel}) => accessLevel,
        accessLevelSelect: ({accessLevelSelect}) => accessLevelSelect,
        // errors
        accessLevelErrorsName: ({errors}) => errors.accessLevel.name
      }),
      paramsList() { return this.params || {}; },
      disabledFields() { return this.paramsList.disabledFields; },
      accessLevelText() { return this.$gettext('Access Level'); },
      placeholder() { return this.$gettext('Select a level'); },
      accessLevels() {
        if (this.enterpriseId && this.isEmailVerificationRequired) {
          return [{
            id: 'owner',
            name: this.$gettext('Booker'),
          }];
        }

        return [{
                  id: 'owner',
                  name: this.$gettext('Booker'),
                  condition: true
                },
                {
                  id: 'department',
                  name: this.$gettext('Department'),
                  condition: this.enterpriseId
                },
                {
                  id: 'enterprise',
                  name: this.$gettext('Enterprise'),
                  condition: this.enterpriseId
                }
        ].filter((accessLevel) => accessLevel.condition);
      },
      title() { return this.$gettext('Access Level'); },
      description() { return this.$gettext('Choose access level for the project'); },
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    methods: {
      ...mapActions('PostingStore/AccessLevelStore', [
        'changeAccessLevelSelectState'
      ]),
      ...mapMutations('PostingStore/AccessLevelStore', [
        'setAccessLevel'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setContext',
        'setPopoverReference',
        'setReferenceToBuyerInfoSection',
      ]),
      openAccessLevelHelper() {
        if (this.showAssignmentHelper) {
          this.setContext('translation');
          this.setPopoverReference(this.$refs.accessLevelSection);
          this.openHelper('accessLevel');
        }
      },
      updateAccessLevel(value) {
        if (!value) {
          this.setReferenceToBuyerInfoSection();
          this.openHelper('');
        }
        this.changeAccessLevelSelectState(value);
      }
    }
  };
</script>

<style scoped>
  .access-level__select {
    margin-top: 15px;
    margin-bottom: 0;
  }
</style>
