(<template>
  <div :class="[{'person--blocked': isBlockedRelation},
                {'in-progress': progressActive}]"
       class="person-table__row is-progress-bar">
    <div class="person__cell">
      <admin-actions v-if="!isBlockedInterpreter && !isBlockedRelation"
                     :interpreter="interpreter"
                     @invited="makeInterInvite"
                     @applied="makeInterApply"
                     @declined="makeInterDecline"
                     @blocked="makeInterJobBlock"
                     @changerating="changeInterpreterRating" />
      <p v-if="isBlockedRelation"
         class="person-blocked-by__name"
         @click="openIntBlockedByList">{{ fullName }} {{ noOfBlockedBy }}</p>
    </div>
    <div class="person__cell">
      <div class="person__cell--name">
        <div :class="{'is-favourite favourite-icon': interpreter.favourite,
                      'is-blocked': interpreter.blocked,
                      'is-attested-by-police attested-by-police-icon': interpreter.showPoliceLogo,
                      'is-attested-by-police attested-by-police-temporary-icon': interpreter.showTemporaryPoliceLogo}"
             class="person__avatar-cont"
             @click="isCompact ? null : goToProfile()">
          <tt-avatar :avatar="interpreter.avatarUrl"
                     class="person__avatar" />
        </div>
        <span v-html="computedName"></span>
        <button class="sk-link sk-link--default person__support-link"
                @click="loginAsInterpreter">{{ $gettext('[T]') }}</button>
      </div>
    </div>
    <div class="person__cell">
      <span class="js-copy-to-clipboard">{{ interpreter.phoneNumber }}</span>
      <span v-if="$isGodmode()"
            class="js-copy-to-clipboard alternative-phone-number">{{ interpreter.alternativePhoneNumber }}</span>
    </div>
    <div class="person__cell">
      <availability-timeline :events-list="timelineEvents"
                             :selected-event="selectedEventData"
                             :col-width="12"
                             :date-range-from="assignmentStartTime"
                             :date-range-to="assignmentFinishTime"
                             :timeline-step="60"
                             :hide-time="true"
                             @onclick="openEventsModal" />
    </div>
    <template v-if="!isCompact">
      <div class="person__cell">
        <p v-for="qualification in qualifications"
           :key="qualification.id"
           :class="{'person__qualification--light': useLightColorForQualification(qualification)}"
           class="person__qualification">{{ qualification.name }}</p>
      </div>
      <div class="person__cell">{{ interpreter.city }}</div>
      <div v-if="showDistance"
           class="person__cell">{{ distance }}</div>
      <div class="person__cell person__cell--red-text"
           :class="{'person__cell--blue-text': interpreter.suitable}">{{ suitabilityText }}</div>
      <div class="person__cell"
           v-html="unsuitabilityReasons"></div>
      <div v-if="interpreter.lastInvitedAt"
           class="person__cell person__cell--invited">
        {{ $moment(interpreter.lastInvitedAt).format('DD.MM.YYYY HH:mm') }}
      </div>
      <div v-else
           class="person__cell person__cell--invited"></div>
      <div class="person__cell person__cell--info">
        <button class="person__info-btn"
                @click="showInterpreterShortInfo"></button>
      </div>
    </template>
  </div>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import SuitableSupplierPrototype from '@/prototypes/SuitableSupplierPrototype';
  import FAIAdminActions
    from '@/components/assignment_components/one_assignment/interactive_info/invite_suppliers/FAIAdminActions';
  import AvailabilityTimeline from '@/components/shared_components/availability/AvailabilityTimeline';

  export default {
    components: {
      'admin-actions': FAIAdminActions,
      'availability-timeline': AvailabilityTimeline
    },
    extends: SuitableSupplierPrototype,
    props: {
      interpreter: {
        type: Object,
        default() {
          return {};
        },
      },
      isCompact: {
        type: Boolean,
        default: false
      },
      showDistance: Boolean
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        jobId: ({storeJobObj}) => storeJobObj.id || ''
      }),
      ...mapGetters('EnterpriseStore', ['getOwnerUid']),
      assignmentStartTime() { return this.assignmentRequirements.startTime ? this.$moment(this.assignmentRequirements.startTime).format('YYYY-MM-DD HH:mm') : ''; },
      assignmentFinishTime() { return this.assignmentRequirements.finishTime ? this.$moment(this.assignmentRequirements.finishTime).format('YYYY-MM-DD HH:mm') : ''; },
      unsuitabilityReasons() {
        const reasons = this.interpreter.unsuitabilityReason;
        if (reasons && reasons.length) {
          return reasons.map((reason) => this.getUnsuitabilityReasonText(reason)).join('<br />');
        }
      },
      availability() {
        return this.interpreter.availability || {};
      },
      interpreterJobs() {
        return this.availability.jobs || [];
      },
      travelTimes() {
        return this.availability.travelTimes || [];
      },
      blockedByList() {
        return this.interpreter?.blockedBy || [];
      },
      selectedEvent() {
        const startTime = this.assignmentRequirements.startTime;
        const finishTime = this.assignmentRequirements.finishTime;
        const sessionType = this.assignmentRequirements.sessionType;
        if (startTime && finishTime && sessionType) {
          return {
            startTime,
            finishTime,
            sessionType
          };
        }
      },
      selectedEventData() {
        return this.selectedEvent ? [this.selectedEvent, 'job'] : null;
      },
      timelineEvents() {
        return [
          [this.availability.events || [], 'event'],
          [this.availability.jobs || [], 'job'],
          [this.availability.offTimes || [], 'offtime'],
          [this.travelTimes[0] || [], 'travelFromTime'],
          [this.travelTimes[1] || [], 'travelToTime']
        ];
      },
      isBlockedRelation() { return this.interpreter.relation === 'blocked'; },
      noOfBlockedBy() {
        const leng = this.blockedByList.length;
        return leng > 0 ? `+${this.blockedByList.length}` : null;
      }
    },
    methods: {
      makeInterInvite() { this.inviteInt(this.interpreter.uid); },
      makeInterApply() { this.applyByInt(this.interpreter.uid, this.interpreter.id, this.fullName); },
      makeInterDecline() { this.declineInvite(this.interpreter.uid, this.interpreter.id); },
      makeInterJobBlock() { this.blockInterpreterFromJob(this.jobId, this.interpreter.uid); },
      changeInterpreterRating(action) { this.$emit('changeinterpreterrating', action, this.interpreter.uid); },
      loginAsInterpreter() {
        this.$emit('loginasinterpreter', this.interpreter.id, this.interpreter.uid);
      },
      openEventsModal() {
        if (this.interpreterJobs.length) {
          this.$store.commit('ModalStore/setModal', {
            component: 'availability-events-modal',
            classes: ['availability-modal'],
            width: 600,
            data: {
              data: {
                interpreter: this.interpreter,
                interpreterJobs: this.interpreterJobs
              },
              context: this
            }
          });
        }
      },
      openIntBlockedByList() {
        if (this.blockedByList && this.blockedByList.length > 0) {
          this.$store.commit('ModalStore/setModal', {
            component: 'int-blocked-by-modal',
            classes: ['sk-modal--new'],
            width: 600,
            data: {
              title: this.$gettext('Blocked by:'),
              customers: this.blockedByList,
              fullName: this.fullName
            }
          });
        }
      },
      showInterpreterShortInfo() {
        this.progressActive = true;
        this.$store.dispatch('FAIListStore/getInterpreterShortInfo', this.interpreter.uid)
          .then(() => {
            this.progressActive = false;
            this.openSidebar();
          })
          .catch((error) => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          });
      },
      openSidebar() {
        this.$store.commit('SidebarStore/setSidebar', {
          component: 'interpreter-short-info',
          data: {
            title: this.fullName
          }
        });
      },
      getUnsuitabilityReasonText(reason) {
        switch (reason) {
          case 'qualification':
            return this.$gettext('Qualification');
          case 'other_jobs':
            return this.$gettext('Other assignments');
          case 'session_type':
            return this.$gettext('Assignment type');
          case 'availability':
            return this.$gettext('Availability');
          case 'payout_method':
            return this.$gettext('Payout method');
          case 'blocked_for_assignment':
            return this.$gettext('Blocked for assignment');
          case 'session_type_status':
            return this.$gettext('Assignment type status');
          case 'account_status':
            return this.$gettext('Account status');
          case 'blocked_city':
            return this.$gettext('Blocked city');
          case 'declined_invitation':
            return this.$gettext('Declined invitation');
          case 'gender':
            return this.$gettext('Gender');
          default:
            return reason;
        }
      }
    }
  };
</script>

<style>
.person .availability-timeline__row--date {
  position: absolute;
  top: 0;
}
</style>

<style scoped src="@assets/css/interpreter_for_admin_fai_block.css"></style>
