export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      payoutMethods: {
        data: '',
        progress: true
      },
      onePayout: '',
      paymentMethods: ''
    },
    getters: {
      payouts(state) {
        return state.payoutMethods.data || [];
      },
      onePayout(state) {
        return state.onePayout || {};
      },
      payoutsProgress(state) {
        return state.payoutMethods.progress;
      },
      defaultPaymentMethod(state) {
        const paymentMethods = state.paymentMethods || [];

        return paymentMethods.find((paymentMethod) => paymentMethod.default);
      },
      activePaymentMethods(state) {
        return Array.isArray(state.paymentMethods)
          ? state.paymentMethods.filter((payment) => !payment.archived) || []
          : [];
      }
    },
    actions: {
      // PAYMENTS
      getPayments({rootGetters, commit}) {
        return new Promise((resolve, reject) => {
          const userIsBusiness = rootGetters['UserInfoStore/userIsBusiness'];
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'];
          const uid = rootGetters['UserInfoStore/userUid'];
          const promise = userIsBusiness
            ? fetchData.getBuyerPayments(uid)
            : fetchData.getEnterprisePayments(enterpriseId);

          promise.then((data = {}) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('savePaymentMethods', data.paymentMethods);
            resolve(data);
          }).catch((data) => {
            reject(data);
          });
        });
      },
      createPayment({rootGetters, commit}, {form}) {
        const enterpriseId = rootGetters['UserInfoStore/enterpriseId'];
        const uid = rootGetters['UserInfoStore/userUid'];
        const userIsBusiness = rootGetters['UserInfoStore/userIsBusiness'];
        const promise = userIsBusiness
          ? fetchData.createBuyerPayment(uid, form)
          : fetchData.createEnterprisePayment(enterpriseId, form);

        return new Promise((resolve, reject) => {
          promise.then((data) => {
            commit('addNewPayment', data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      updatePayment({rootGetters, commit}, {paymentId, form}) {
        const enterpriseId = rootGetters['UserInfoStore/enterpriseId'];
        const uid = rootGetters['UserInfoStore/userUid'];
        const userIsBusiness = rootGetters['UserInfoStore/userIsBusiness'];
        const promise = userIsBusiness
          ? fetchData.updateBuyerPayment(uid, paymentId, form)
          : fetchData.updateEnterprisePayment(enterpriseId, paymentId, form);

        return new Promise((resolve, reject) => {
          promise.then((data) => {
            commit('updatePayment', data);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      deletePayment({rootGetters, commit}, {paymentId, form}) {
        const enterpriseId = rootGetters['UserInfoStore/enterpriseId'];
        const uid = rootGetters['UserInfoStore/userUid'];
        const userIsBusiness = rootGetters['UserInfoStore/userIsBusiness'];
        const promise = userIsBusiness
          ? fetchData.deleteBuyerPayment(uid, paymentId, form)
          : fetchData.deleteEnterprisePayment(enterpriseId, paymentId, form);

        return new Promise((resolve, reject) => {
          promise.then((data) => {
            commit('updatePayment', data);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },

      // PAYOUTS
      getPayouts({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getPayouts().then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('savePayouts', data.payouts);
            resolve(data);
          }).catch((data) => {
            reject(data);
          });
        });
      },
      setDefaultPayoutMethod({commit}, {id, type}) {
        return new Promise((resolve, reject) => {
          if (type !== 'dipps') {
            fetchData.setDefaultPayoutMethod(id, type).then(() => {
              commit('setDefaultPayoutMethod', {id, type});
              resolve();
            }).catch((error) => {
              reject(error);
            });
          } else {
            resolve();
          }
        });
      },
      deletePayout({commit}, {id, type}) {
        return new Promise((resolve, reject) => {
          fetchData.deletePayout(id, type).then(() => {
            commit('deletePayoutMethod', {id, type});
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      }
    },
    mutations: {
      // PAYMENTS
      savePaymentMethods(state, data) {
        state.paymentMethods = data;
      },
      addNewPayment(state, data) {
        if (Array.isArray(state.paymentMethods)) {
          // reset all payments to not default
          if (data?.default) {
            state.paymentMethods?.map((item) => {
              item.default = false;
            });
          }
          state.paymentMethods?.unshift(data);
        }
      },
      updatePayment(state, data) {
        state.paymentMethods = state.paymentMethods?.map((method) => {
          if (data.default) { method.default = false; }
          return data.id == method.id ? data : method;
        }) || [];
      },
      // PAYOUTS
      savePayouts(state, data) {
        state.payoutMethods.data = data;
        state.payoutMethods.progress = false;
      },
      setPayoutProgress(state, value) {
        state.payoutMethods.progress = value;
      },
      setDefaultPayoutMethod(state, {id, type}) {
        if (state.payoutMethods.data) {
          state.payoutMethods.data.map((item) => {
            item.default = +item.id === +id && item.type === type;
          });
        }
      },
      deletePayoutMethod(state, {id, type}) {
        if (state.payoutMethods.data) {
          state.payoutMethods.data.forEach((item, index, arr) => {
            +item.id === +id && item.type === type ? arr.splice(index, 1) : '';
          });
        }
      },
      removePayouts(state) {
        state.payoutMethods.data = '';
      },
      removePayments(state) {
        state.paymentMethods = '';
      }
    }
  };
};
