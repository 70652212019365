import helpers from '@/helpers';

export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      trackType: '',
      copiedProject: '',
      subtaskType: ''
    },
    getters: {
      bookingFormProgress(state, getters, rootState) {
        const {departmentsProgress, attendersProgress} = rootState.PostingStore.DepartmentAttendersStore;
        return departmentsProgress || attendersProgress;
      }
    },
    actions: {
      createForm({rootState, state}) {
        return new Promise((resolve) => {
          const form = new FormData();
          // Order subtask type
          form.append('order[subtask_type]', state.subtaskType);

          // MY INFORMATION SECTION
          // if (rootState.PostingStore.BookerStore.booker) form.append('order[booker_uid]', rootState.PostingStore.BookerStore.booker);
          if (rootState.PostingStore.DepartmentAttendersStore.department) form.append('order[department_id]', rootState.PostingStore.DepartmentAttendersStore.department);

          if (rootState.PostingStore.CaseNumberStore.caseNumber) form.append('order[case_number]', rootState.PostingStore.CaseNumberStore.caseNumber);
          if (rootState.PostingStore.BookingRefStore.bookingReference) form.append('order[booking_reference]', rootState.PostingStore.BookingRefStore.bookingReference);
          if (rootState.PostingStore.BookingRefStore.paymentBookingReference) form.append('order[payment_booking_reference]', rootState.PostingStore.BookingRefStore.paymentBookingReference);
          if (rootState.PostingStore.PaymentMethodStore.paymentMethod) form.append('order[payment_method_id]', rootState.PostingStore.PaymentMethodStore.paymentMethod);
          if (rootState.PostingStore.ClientStore.clientName) form.append('order[client_name]', rootState.PostingStore.ClientStore.clientName);
          if (rootState.PostingStore.ClientStore.clientId) form.append('order[client_id]', rootState.PostingStore.ClientStore.clientId);
          if (rootState.PostingStore.LanguagesStore.alternativeTargetLanguage && rootState.PostingStore.LanguagesStore.targetLanguages.length === 1) {
            form.append('order[alternative_target_language_id]', rootState.PostingStore.LanguagesStore.alternativeTargetLanguage);
          }
          //* ***** Send By Post *******//
          if (rootState.PostingStore.SendMailStore.sendByPost || rootState.OneProjectStore.project.deliveryMethod === 'post') {
            form.append('order[delivery_method]', 'post');
            if (rootState.PostingStore.SendMailStore.name) form.append('order[send_by_post_name]', rootState.PostingStore.SendMailStore.name);
            if (rootState.PostingStore.SendMailStore.company) form.append('order[send_by_post_enterprise_name]', rootState.PostingStore.SendMailStore.company);
            if (rootState.FullAddressStore.line1) {
              form.append('order[send_by_post_address]', helpers.getFullAddress(rootState.FullAddressStore));
            } else if (rootState.PostingStore.SendMailStore.address) form.append('order[send_by_post_address]', rootState.PostingStore.SendMailStore.address);
            //* **** Cover letter ****//
            const coverLetters = rootState.PostingStore.CoverLetterStore.attachments;
            if (coverLetters.length) {
              for (const file of coverLetters) {
                if (file.deleted) {
                  form.append('order[cover_letters][][id]', file.id);
                  form.append('order[cover_letters][][archived]', true);
                } else if (!file.id) {
                  form.append('order[cover_letters][][file]', file.file);
                  // form.append('job[attachments][][comment]', file.name);
                } else if (file.id) {
                  form.append('order[cover_letters][][id]', file.id);
                }
              }
            }
          } else form.append('order[delivery_method]', 'email');
          const {participantEmails} = rootState.PostingStore.ParticipantsStore;
          if (participantEmails && participantEmails.length) {
            for (const email of participantEmails) {
              form.append('order[cc_emails][]', email.email);
            }
          } else {
            form.append('order[cc_emails]', null);
          }
          // PROJECT INFORMATION SECTION
          form.append('order[source_language_id]', rootState.PostingStore.LanguagesStore.languageFrom);
          const targetLanguages = rootState.PostingStore.LanguagesStore.targetLanguages;
          if (targetLanguages && targetLanguages.length) {
            targetLanguages.forEach((lang) => {
              form.append('order[target_languages][][id]', lang);
            });
          }

          const {category, subject} = rootState.PostingStore.SubjectStore;
          if (category === 'other') {
            form.append('order[other_subject]', subject);
          } else if (category) {
            form.append('order[translation_method_id]', category);
          }

          // short noticed deadline
          form.append('order[requested_short_notice]', rootState.PostingStore.DeadlineStore.shortNoticedOrder);

          form.append('order[external_deadline]', rootState.PostingStore.DeadlineStore.deadline);
          form.append('order[instructions_from_demander]', rootState.PostingStore.ExtraInfoStore.extraInfo);
          if (!rootState.OneProjectStore.project) form.append('order[send_quote]', rootState.PostingStore.ExtraInfoStore.priceQoute);
          if (rootState.PostingStore.BookerStore.booker) form.append('order[owner_uid]', rootState.PostingStore.BookerStore.booker);
          const attachments = rootState.PostingStore.AttachmentsStore.attachments;
          if (rootState.OneProjectStore.project) {
            if (!(rootState.OneProjectStore?.project?.status === 'in_progress')) {
              if (attachments.length) {
                for (const file of attachments) {
                  if (file.deleted) {
                    // copied files not achieved
                    if (!state.copiedProject) {
                      form.append('order[attachments][][id]', file.id);
                      form.append('order[attachments][][archived]', true);
                    }
                  } else if (!file.id) {
                    form.append('order[attachments][][file]', file.file);
                    // form.append('job[attachments][][comment]', file.name);
                  } else if (file.id) {
                    form.append('order[attachments][][id]', file.id);
                  }
                }
              }
            }
          } else {
            if (attachments.length) {
              for (const file of attachments) {
                if (file.deleted) {
                  // copied files not achieved
                  if (!state.copiedProject) {
                    form.append('order[attachments][][id]', file.id);
                    form.append('order[attachments][][archived]', true);
                  }
                } else if (!file.id) {
                  form.append('order[attachments][][file]', file.file);
                  // password for police order
                  if (file.file_password) {
                    form.append('order[attachments][][file_password]', file.file_password);
                  }
                  // form.append('job[attachments][][comment]', file.name);
                } else if (file.id) {
                  form.append('order[attachments][][id]', file.id);
                }
              }
            }
          }

          // PARAMS FOR OPEN BOOKING(Logged-out Pages)
          const openBookingObj = rootState.OpenBookingStore.openBooking;
          if (openBookingObj.openBookingType) {
            form.append('booker[first_name]', openBookingObj.firstName.trim());
            form.append('booker[last_name]', openBookingObj.lastName.trim());
            form.append('booker[phone_code]', openBookingObj.phoneCode);
            form.append('booker[phone_number]', openBookingObj.phoneNumber.trim());
            form.append('booker[email]', openBookingObj.email.trim());
            form.append('booker[existing_user]', !!openBookingObj.isExistingCustomer);
            if (openBookingObj.orgNumber) form.append('booker[org_number]', openBookingObj.orgNumber);
            if (openBookingObj.organization) form.append('booker[enterprise_id]', openBookingObj.organization);
            if (openBookingObj.department) form.append('order[department_id]', openBookingObj.department);
            if (openBookingObj.bookingRef) form.append('order[booking_reference]', openBookingObj.bookingRef.trim());
            if (openBookingObj.paymentBookingRef) form.append('order[payment_booking_reference]', openBookingObj.paymentBookingRef.trim());
            if (openBookingObj.caseNumber) form.append('order[case_number]', openBookingObj.caseNumber.trim());
            form.append('page_key_name', openBookingObj.openBookingType);
          }

          //* ***** Access Level *******//
          if (rootState.PostingStore.AccessLevelStore.accessLevel) form.append('order[access_level]', rootState.PostingStore.AccessLevelStore.accessLevel);

          // COPIED JOB
          if (state.copiedProject) {
            form.append('order[original_order_id]', state.copiedProject);
          }

          resolve(form);
        });
      },
      createFormPartially({state, rootState, dispatch}, fields = {}) {
        return new Promise((resolve) => {
            dispatch('createForm').then((form) => {
            const storeObj = rootState.OneProjectStore.project || {};
            const getFieldValue = (field, initialFieldValue) => {
              return field !== undefined ? field : initialFieldValue;
            };

            form.append('id', getFieldValue(fields.id, storeObj.id));
            const paymentMethodId = getFieldValue(fields.paymentMethodId, storeObj.paymentMethod?.id);
            // const actualSessionType = getFieldValue(fields.actualSessionType, storeJobInterpretationRequirement.actualSessionType);
            const attachments = getFieldValue(fields.attachments, storeObj.attachments);
            const targetLanguageIds = getFieldValue(fields.targetLanguages, storeObj.targetLanguages);
            // form.append('order[attachments]', getFieldValue(fields.attachments, storeObj.attachments));
            if (rootState.OneProjectStore?.project?.status !== 'finished' && rootState.OneProjectStore?.project?.status !== 'completed') {
              if (attachments) {
                for (const file of attachments) {
                  if (file.deleted) {
                    form.append('order[attachments][][id]', file.id);
                    form.append('order[attachments][][archived]', true);
                  } else if (!file.id) {
                    form.append('order[attachments][][file]', file.file);
                    // form.append('job[attachments][][comment]', file.name);
                  } else if (file.id) {
                    form.append('order[attachments][][id]', file.id);
                  }
                }
              }
            }
            const coverLetters = storeObj.coverLetters;
            if (rootState.PostingStore.CoverLetterStore.attachments && rootState.PostingStore.CoverLetterStore.attachments.length) {
              for (const file of coverLetters) {
                form.append('order[cover_letters][][id]', file.id);
                form.append('order[cover_letters][][archived]', true);
              }
              rootState.PostingStore.CoverLetterStore.attachments.forEach((attachment) => {
                form.append('order[cover_letters][][file]', attachment.file);
              });
            }
            if (rootState.PostingStore.LanguagesStore.alternativeTargetLanguage && rootState.PostingStore.LanguagesStore.targetLanguages.length === 1) {
              form.append('order[alternative_target_language_id]', rootState.PostingStore.LanguagesStore.alternativeTargetLanguage);
            }
            form.append('order[count_of_words]', getFieldValue(fields.countOfWords, storeObj.countOfWords));
            form.append('order[created_at]', getFieldValue(fields.createdAt, storeObj.createdAt));
            // form.append('order[booking_reference]', getFieldValue(fields.bookingReference, storeObj.bookingReference));
            // form.append('order[payment_booking_reference]', getFieldValue(fields.paymentBookingReference, storeObj.paymentBookingReference));
            // form.append('order[case_number]', getFieldValue(fields.caseNumber, storeObj.caseNumber));
            form.append('order[client_name]', getFieldValue(fields.clientName, storeObj.clientName));
            form.append('order[client_id]', getFieldValue(fields.clientId, storeObj.clientId));
            form.append('order[demander_feedback]', getFieldValue(fields.demanderFeedback, storeObj.demanderFeedback));
            form.append('order[demander_quote_amount]', getFieldValue(fields.demanderQuoteAmount, storeObj.demanderQuoteAmount));
            // form.append('order[department_id]', getFieldValue(fields.departmentId, storeObj.department.id));
            form.append('order[enterprise_id]', getFieldValue(fields.enterpriseId, storeObj.enterpriseId));
            // short noticed deadline
            form.append('order[requested_short_notice]', rootState.PostingStore.DeadlineStore.shortNoticedOrder);
            form.append('order[external_deadline]', getFieldValue(fields.externalDeadline, storeObj.externalDeadline));
            form.append('order[instructions_from_demander]', getFieldValue(fields.instructionsFromDemander, storeObj.instructionsFromDemander));
            form.append('order[name]', getFieldValue(fields.name, storeObj.name));
            form.append('order[owner_uid]', getFieldValue(fields.uid, storeObj.owner?.uid));
            form.append('order[source_language_id]', getFieldValue(fields.sourceLanguageId, storeObj.sourceLanguageId));
            form.append('order[status]', getFieldValue(fields.status, storeObj.status));
            if (rootState.PostingStore.SendMailStore.address) {
              form.append('order[send_by_post_address]', rootState.PostingStore.SendMailStore.address);
            }
            if (targetLanguageIds) {
              targetLanguageIds.forEach((id) => {
                form.append('order[target_languages][][id]', id);
              });
            }
            const {participantEmails} = rootState.PostingStore.ParticipantsStore;
            if (participantEmails && participantEmails.length) {
             for (const email of participantEmails) {
                form.append('order[cc_emails][]', email.email);
              }
           } else {
              form.append('order[cc_emails]', null);
            }
            if (paymentMethodId) form.append('order[payment_method_id]', paymentMethodId);
            if (state.trackType) form.append('order[track_type]', state.trackType);
            resolve(form);
          });
        });
      },
      createProject({commit, dispatch}) {
        return new Promise((resolve, reject) => {
          dispatch('createForm').then((form) => {
            commit('setUploadProgress', true, {root: true});
            fetchData.createTranslationProject(form, commit, false).then((newJobObj) => {
              commit('OneProjectStore/setProject', newJobObj.order, {root: true});
              commit('setUploadProgress', false, {root: true});
              commit('setUploadPercentage', 0, {root: true});
              resolve(newJobObj);
            }).catch((error) => {
              commit('setUploadProgress', false, {root: true});
              commit('setUploadPercentage', 0, {root: true});
              reject(error);
            });
          });
        });
      },
      createLogoutProject({commit, dispatch}) {
        return new Promise((resolve, reject) => {
          dispatch('createForm').then((form) => {
            commit('setUploadProgress', true, {root: true});
            fetchData.createLogoutTranslationProject(form, commit, false).then((newJobObj) => {
              commit('OneProjectStore/setProject', newJobObj.order, {root: true});
              commit('setUploadProgress', false, {root: true});
              commit('setUploadPercentage', 0, {root: true});
              resolve(newJobObj);
            }).catch((error) => {
              commit('setUploadProgress', false, {root: true});
              commit('setUploadPercentage', 0, {root: true});
              reject(error);
            });
          });
        });
      },
      editProjectPartially({rootState, dispatch}, fields = {}) {
        return new Promise((resolve, reject) => {
          dispatch('createFormPartially', fields)
            .then((form) => {
              const params = {
                supportMode: rootState.TTAuthStore.support,
                projectForm: form,
                id: rootState.OneProjectStore.project.id
              };
              dispatch('editProject', params, {root: true})
                .then((project) => {
                  resolve(project);
                })
                .catch((error) => {
                  reject(error);
                });
            });
        });
      },
      // eslint-disable-next-line no-unused-vars
      editProject({rootState, state, dispatch}, router) {
        return new Promise((resolve, reject) => {
          dispatch('createForm')
            .then((form) => {
              const params = {
                supportMode: rootState.TTAuthStore.support,
                projectForm: form,
                id: rootState.OneProjectStore.project.id
              };
          dispatch('editProject', params, {root: true})
            .then((project) => {
              resolve(project);
            })
            .catch((error) => {
              reject(error);
            });
        });
    });
  },
      getCopyProject({commit, rootState, rootGetters}, {copyProjectId}) {
        return new Promise((resolve, reject) => {
          fetchData.getProject(copyProjectId)
            .then((response) => {
              if (response.code && response.code == 401) {
                commit('GlobalStore/set404Page', '', {root: true});
                resolve();
                return;
              }
              commit('OneProjectStore/setProject', response.order, {root: true});
              commit('setProjectInfo', {
                  project: {
                    copiedProject: copyProjectId,
                    ...response.order
                  },
                  rootGetters,
                  rootState
              });
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      clearPersonalInfoAndInvoiceStores({commit}) {
        commit('PostingStore/BookerStore/clearStore', null, {root: true});
        commit('PostingStore/DepartmentAttendersStore/clearStore', null, {root: true});
        commit('PostingStore/BookingRefStore/clearStore', null, {root: true});
        commit('PostingStore/CaseNumberStore/clearStore', null, {root: true});
        commit('PostingStore/ClientStore/clearStore', null, {root: true});
        commit('EnterpriseStore/clearStore', null, {root: true});
      },
      clearAssignmentInfoStores({commit}) {
        commit('PostingStore/AttachmentsStore/clearStore', null, {root: true});
        commit('PostingStore/DeadlineStore/clearStore', null, {root: true});
        commit('PostingStore/ExtraInfoStore/clearStore', null, {root: true});
        commit('PostingStore/LanguagesStore/clearStore', null, {root: true});
        commit('PostingStore/SubjectStore/clearStore', null, {root: true});
        commit('PostingStore/SendMailStore/clearStore', null, {root: true});
        commit('PostingStore/CoverLetterStore/clearStore', null, {root: true});
        commit('PostingStore/AccessLevelStore/clearStore', null, {root: true});
        commit('PostingStore/ParticipantsStore/clearStore', null, {root: true});
        commit('clearStore');
      },
      clearStore({dispatch}) {
        dispatch('clearPersonalInfoAndInvoiceStores');
        dispatch('clearAssignmentInfoStores');
      },
      clearEditProjectInformationStore({commit}) {
        commit('PostingStore/LanguagesStore/clearStore', null, {root: true});
        commit('PostingStore/DeadlineStore/emptyDeadline', null, {root: true});
        commit('PostingStore/ExtraInfoStore/clearStore', null, {root: true});
        commit('PostingStore/AttachmentsStore/clearStore', null, {root: true});
        commit('PostingStore/ParticipantsStore/clearStore', null, {root: true});
      }
    },
    mutations: {
      setProjectInfo(state, {project, rootGetters, rootState, dispatch}) {
        state.copiedProject = project.copiedProject || '';
        // my information block
        rootState.PostingStore.DepartmentAttendersStore.department = project.department ? project.department.id : '';
        rootState.PostingStore.CaseNumberStore.caseNumber = project.caseNumber || '';
        rootState.PostingStore.ClientStore.clientName = project.clientName || '';
        rootState.PostingStore.ClientStore.clientId = project.clientId || '';
        rootState.PostingStore.BookingRefStore.bookingReference = project.bookingReference || '';
        rootState.PostingStore.BookingRefStore.paymentBookingReference = project.paymentBookingReference || '';
        const defaultPaymentMethodId = rootGetters['PostingStore/PaymentMethodStore/defaultPaymentMethodData']?.id || '';
        rootState.PostingStore.PaymentMethodStore.paymentMethod = project.paymentMethod
          ? project.paymentMethod.id || defaultPaymentMethodId
          : defaultPaymentMethodId;
        rootState.PostingStore.BookerStore.booker = project.owner.uid || rootGetters['UserInfoStore/userUid'];
        rootState.PostingStore.OwnerRealNameStore.ownerRealName = project.owner?.name || '';
        // Project info section
        rootState.PostingStore.LanguagesStore.languageFrom = project.sourceLanguageId || '';
        rootState.PostingStore.LanguagesStore.targetLanguages = project.targetLanguages || [];
        rootState.PostingStore.DeadlineStore.deadline = project.externalDeadline || null;
        rootState.PostingStore.ExtraInfoStore.extraInfo = project.instructionsFromDemander || '';
        // Attachments
        const attachments = project.attachments;
        if (attachments && attachments.length) {
          rootState.PostingStore.AttachmentsStore.attachmentsSelectState = true;
          rootState.PostingStore.AttachmentsStore.attachments = JSON.parse(JSON.stringify(attachments));
        }

        // Send by Post
        if (project.sendByPostName) {
          rootState.PostingStore.SendMailStore.sendByPost = true;
          rootState.PostingStore.SendMailStore.name = project.sendByPostName;
          rootState.PostingStore.SendMailStore.company = project.sendByPostEnterpriseName || '-';
          rootState.PostingStore.SendMailStore.address = project.sendByPostAddress || '-';
        }
        // Cover letter
        const coverLetters = project?.coverLetters;
        if (coverLetters && coverLetters.length) {
          rootState.PostingStore.SendMailStore.sendByPost = true;
          rootState.PostingStore.CoverLetterStore.attachments = JSON.parse(JSON.stringify(coverLetters));
        }

        // CCEmails
        const ccEmails = project?.ccEmails;
        if (ccEmails && ccEmails.length) {
          rootState.PostingStore.ParticipantsStore.participantsSelect = true;
          ccEmails.forEach((email) => {
            rootState.PostingStore.ParticipantsStore.participantEmails.push({
              email,
              error: false
            });
          });
        }
        // Access Level
        if (project.accessLevel) {
          rootState.PostingStore.AccessLevelStore.accessLevel = project.accessLevel;
          rootState.PostingStore.AccessLevelStore.accessLevelSelect = true;
        } else {
          dispatch('PostingNotifierStore/preselectProjectDefaultAccessLevel', {root: true});
        }
      },
      setSubtaskType(state, value) {
        state.subtaskType = value;
      },
      setTrackType(state, value) {
        state.trackType = value;
      },
      clearStore(state) {
        state.trackType = '';
        state.copiedProject = '';
      }
    }
  };
};
