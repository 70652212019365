(<template>
  <post-inner-section class="suppliers-list">
    <sk-checkbox :item-text="suppliersListCheckboxDescription"
                 :preselected-value="suppliersListSelect"
                 class="suppliers-list__checkbox"
                 @checkboxvaluechanged="setSuppliersListSelectState" />
    <div v-if="suppliersListSelect"
         class="suppliers-list__list-wrapper">
      <div v-for="supplier in suppliersList"
           :key="supplier.uid"
           class="suppliers-list__list-item">
        <sk-checkbox :item-text="getTimelineTitle(supplier.name)"
                     :preselected-value="supplier.selected"
                     class="suppliers-list__checkbox"
                     @checkboxvaluechanged="checkSupplierFromList(supplier.uid)" />
        <availability-timeline :events-list="getTimelineEvents(supplier.timelineEvents)"
                               :selected-event="selectedEventData"
                               :date-range-from="assignmentStartTime"
                               :date-range-to="assignmentFinishTime"
                               :col-width="40"
                               :timeline-step="60"
                               :enable-event-description="true"
                               class="suppliers-list__timeline" />
      </div>
    </div>
  </post-inner-section>
</template>)

<script>
  import {mapMutations, mapState} from 'vuex';
  import PostingSuppliersListPrototype from '@/prototypes/PostingSuppliersListPrototype';
  import PostInnerSection from '@/components/booking_components/PostInnerSection';

  export default {
    components: {
      'post-inner-section': PostInnerSection
    },
    extends: PostingSuppliersListPrototype,
    computed: {
      ...mapState('PostingStore/SuppliersListStore', {
        suppliersListSelect: ({sameLocationSuppliersListSelect}) => sameLocationSuppliersListSelect,
        suppliersList: ({sameLocationSuppliersList}) => sameLocationSuppliersList
      }),
      suppliersListCheckboxDescription() {
        return this.$gettext('Show me interpreters that are available and present at my address');
      }
    },
    methods: {
      ...mapMutations({
        setSuppliersListSelectState: 'PostingStore/SuppliersListStore/setSameLocationSuppliersListSelectState',
        checkSupplierFromList: 'PostingStore/SuppliersListStore/checkSameLocationSupplier',
      })
    }
  };
</script>
