(<template>
  <div class="post-form__section post-form__section--underline admin-confirmations">
    <p class="post-form__section-title admin-confirmations__title">{{ $gettext('Confirmations and communications') }}</p>
    <confirmation-by-phone-section />
    <confirmation-by-section />
  </div>
</template>)

<script>
  import ConfirmationByPhoneSection from '@/components/booking_components/parts/ConfirmationByPhoneSection';
  import ConfirmationBySection from '@/components/booking_components/parts/ConfirmationBySection';

  export default {
    components: {
      'confirmation-by-phone-section': ConfirmationByPhoneSection,
      'confirmation-by-section': ConfirmationBySection
    }
  };
</script>
