const policeConfig = (context) => {
  return {
    translationPage: true,
    title: context.$gettext('For deg som jobber i Politiet'),
    contentItems: [
      context.$gettext('Denne siden er for ansatte i Politiet. Dersom du ikke er ansatt i Politiet, vennligst besøk vår <a class="sk-link sk-link--default" href="https://salita.no/">hjemmeside</a> for å finne den beste måten å bestille på.'),
      context.$gettext('Dersom noe er uklart eller det er noe du lurer på, besøk vårt hjelpesenter <a class="sk-link sk-link--default" href="https://support.salita.no/">https://support.salita.no/</a>')
    ],
    newCustomerFields: {
      organization: {required: false},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      bookingRef: {required: true, customName: context.$gettext('BID')},
      paymentBookingRef: {required: true, customName: context.$gettext('Attestant-BID')},
      caseNumber: {required: false},
      email: {required: true},
      phone: {required: true},
      orgNumber: {required: true},
      paymentMethod: {required: true}
    },
    existingCustomerFields: {
      organization: {required: true},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      bookingRef: {required: true, customName: context.$gettext('BID')},
      paymentBookingRef: {required: true, customName: context.$gettext('Attestant-BID')},
      caseNumber: {required: true},
      email: {required: true},
      phone: {required: true},
      paymentMethod: {required: true}
    }
  };
};

const navConfig = (context) => {
  return {
    translationPage: true,
    title: context.$gettext('For deg som jobber i NAV'),
    contentItems: [
      context.$gettext('Denne siden er for ansatte i NAV. Dersom du ikke er ansatt i NAV, vennligst besøk vår <a class="sk-link sk-link--default" href="https://salita.no/">hjemmeside</a> for å finne den beste måten å bestille på.'),
      context.$gettext('Dersom noe er uklart eller det er noe du lurer på, besøk vårt hjelpesenter <a class="sk-link sk-link--default" href="https://support.salita.no/">https://support.salita.no/</a>')
    ],
    newCustomerFields: {
      organization: {required: false},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      bookingRef: {required: true, customName: context.$gettext('Bestillerref. eller NAV Ident')},
      paymentBookingRef: {required: true, customName: context.$gettext('Fakturaref.')},
      email: {required: true},
      phone: {required: true},
      orgNumber: {required: true},
      paymentMethod: {required: true}
    },
    existingCustomerFields: {
      organization: {required: true},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      bookingRef: {required: true, customName: context.$gettext('Bestillerref. eller NAV Ident')},
      paymentBookingRef: {required: true, customName: context.$gettext('Fakturaref.')},
      email: {required: true},
      phone: {required: true},
      paymentMethod: {required: true}
    }
  };
};

const navTranslationsConfig = (context) => {
  return {
    translationPage: true,
    title: context.$gettext('For deg som jobber i NAV'),
    contentItems: [
      context.$gettext('Denne siden er for ansatte i NAV. Dersom du ikke er ansatt i NAV, vennligst besøk vår <a class="sk-link sk-link--default" href="https://salita.no/">hjemmeside</a> for å finne den beste måten å bestille på.'),
      context.$gettext('Dersom noe er uklart eller det er noe du lurer på, besøk vårt hjelpesenter <a class="sk-link sk-link--default" href="https://support.salita.no/">https://support.salita.no/</a>')
    ],
    newCustomerFields: {
      organization: {required: false},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      bookingRef: {required: true, customName: context.$gettext('NAV Ident eller bestillerref.')},
      paymentBookingRef: {required: false, customName: context.$gettext('Fakturaref.')},
      email: {required: true},
      phone: {required: true},
      orgNumber: {required: true},
      paymentMethod: {required: true}
    },
    existingCustomerFields: {
      organization: {required: true},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      bookingRef: {required: true, customName: context.$gettext('NAV Ident eller bestillerref.')},
      paymentBookingRef: {required: false, customName: context.$gettext('Fakturaref.')},
      email: {required: true},
      phone: {required: true},
      paymentMethod: {required: true}
    }
  };
};

const healthConfig = (context) => {
  return {
    translationPage: true,
    title: context.$gettext('For deg som jobber i et Helsefortak'),
    contentItems: [
      context.$gettext('Denne siden er for ansatte i et Helseforetak. Dersom du ikke er ansatt i et Helseforetak, vennligst besøk vår <a class="sk-link sk-link--default" href="https://salita.no/">hjemmeside</a> for å finne den beste måten å bestille på.'),
      context.$gettext('Dersom noe er uklart eller det er noe du lurer på, besøk vårt hjelpesenter <a class="sk-link sk-link--default" href="https://support.salita.no/">https://support.salita.no/</a>')
    ],
    newCustomerFields: {
      organization: {required: false},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      caseNumber: {required: false, customName: context.$gettext('Faktura referanse'), customDescription: context.$gettext('*Merida nr, reshnr., Innkjøpsnr., Kostnadsnr.')},
      email: {required: true},
      phone: {required: true},
      orgNumber: {required: true},
      paymentMethod: {required: true}
    },
    existingCustomerFields: {
      organization: {required: true},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      caseNumber: {required: false, customName: context.$gettext('Faktura referanse'), customDescription: context.$gettext('*Merida nr, reshnr., Innkjøpsnr., Kostnadsnr.')},
      email: {required: true},
      phone: {required: true},
      paymentMethod: {required: true}
    }
  };
};

const municipalityConfig = (context) => {
  return {
    translationPage: true,
    title: context.$gettext('For deg som er ansatt i en kommune'),
    contentItems: [
      context.$gettext('Denne siden er for deg som er ansatt i en Kommune. Dersom du ikke er ansatt i en kommune, vennligst besøk vår <a class="sk-link sk-link--default" href="https://salita.no/">hjemmeside</a> for å finne den beste måten å bestille på.'),
      context.$gettext('Dersom noe er uklart eller det er noe du lurer på, besøk vårt hjelpesenter <a class="sk-link sk-link--default" href="https://support.salita.no/">https://support.salita.no/</a>')
    ],
    newCustomerFields: {
      organization: {required: false},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      bookingRef: {required: false},
      paymentBookingRef: {required: false},
      email: {required: true},
      phone: {required: true},
      orgNumber: {required: true},
      paymentMethod: {required: true}
    },
    existingCustomerFields: {
      organization: {required: true},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      bookingRef: {required: false},
      paymentBookingRef: {required: false},
      email: {required: true},
      phone: {required: true},
      paymentMethod: {required: true}
    }
  };
};

const childServicesConfig = (context) => {
  return {
    translationPage: true,
    title: context.$gettext('For deg som jobber i Barnevernet'),
    contentItems: [
      context.$gettext('Denne siden er for deg som er ansatt i Barnevernet. Dersom du ikke er ansatt i Barnevernet, vennligst besøk vår <a class="sk-link sk-link--default" href="https://salita.no/">hjemmeside</a> for å finne den beste måten å bestille på.'),
      context.$gettext('Dersom noe er uklart eller det er noe du lurer på, besøk vårt hjelpesenter <a class="sk-link sk-link--default" href="https://support.salita.no/">https://support.salita.no/</a>')
    ],
    newCustomerFields: {
      organization: {required: false},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      bookingRef: {required: false},
      caseNumber: {required: false, customName: context.$gettext('Pasientnr.')},
      email: {required: true},
      phone: {required: true},
      orgNumber: {required: true},
      paymentMethod: {required: true}
    },
    existingCustomerFields: {
      organization: {required: true},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      bookingRef: {required: false},
      paymentBookingRef: {required: false},
      caseNumber: {required: false, customName: context.$gettext('Pasientnr.')},
      email: {required: true},
      phone: {required: true},
      paymentMethod: {required: true}
    }
  };
};

const courtConfig = (context) => {
  return {
    translationPage: true,
    title: context.$gettext('For deg som jobber i Rettsvesenet'),
    contentItems: [
      context.$gettext('Denne siden er for deg som jobber i Rettsvesenet. Dersom du ikke jobber i Rettsvesenet, vennligst besøk vår <a class="sk-link sk-link--default" href="https://salita.no/">hjemmeside</a> for å finne den beste måten å bestille på.'),
      context.$gettext('Dersom noe er uklart eller det er noe du lurer på, besøk vårt hjelpesenter <a class="sk-link sk-link--default" href="https://support.salita.no/">https://support.salita.no/</a>')
    ],
    newCustomerFields: {
      organization: {required: false},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      caseNumber: {required: true},
      email: {required: true},
      phone: {required: true},
      orgNumber: {required: true},
      paymentMethod: {required: true}
    },
    existingCustomerFields: {
      organization: {required: true},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      caseNumber: {required: true},
      email: {required: true},
      phone: {required: true},
      paymentMethod: {required: true}
    }
  };
};

const businessConfig = (context) => {
  return {
    translationPage: true,
    title: context.$gettext('For deg som jobber i Privat Næringsliv'),
    contentItems: [
      context.$gettext('Denne siden er for deg som jobber i Privat Næringsliv.'),
      context.$gettext('Dersom noe er uklart eller det er noe du lurer på, besøk vårt hjelpesenter <a class="sk-link sk-link--default" href="https://support.salita.no/">https://support.salita.no/</a>')
    ],
    newCustomerFields: {
      organization: {required: false},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      caseNumber: {required: false},
      email: {required: true},
      phone: {required: true},
      orgNumber: {required: true},
      paymentMethod: {required: true}
    },
    existingCustomerFields: {
      organization: {required: true},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      caseNumber: {required: false},
      email: {required: true},
      phone: {required: true},
      paymentMethod: {required: true}
    }
  };
};

const allConfig = (context) => {
  return {
    translationPage: true,
    title: '',
    contentItems: [
      context.$gettext('Dersom noe er uklart eller det er noe du lurer på, besøk vårt hjelpesenter <a class="sk-link sk-link--default" href="https://support.salita.no/">https://support.salita.no/</a>')
    ],
    newCustomerFields: {
      ...businessConfig(context).newCustomerFields,
      bookingRef: {required: true},
      paymentBookingRef: {required: true}
    },
    existingCustomerFields: {
      ...businessConfig(context).existingCustomerFields,
      bookingRef: {required: true},
      paymentBookingRef: {required: true},
      loginLink: true
    }
  };
};

const driversLicenseExamConfig = (context) => {
  return {
    translationPage: false,
    title: context.$gettext('For deg som skal bestille tolk til teoriprøve'),
    contentItems: [
      context.$gettext('Dersom noe er uklart eller det er noe du lurer på, besøk vårt hjelpesenter <a class="sk-link sk-link--default" href="https://support.salita.no/">https://support.salita.no/</a>')
    ],
    specialSessionTypes: ['videoroom'],
    newCustomerFields: {
      organization: {required: false},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      email: {required: true},
      phone: {required: true},
      paymentMethod: {required: true}
    },
    existingCustomerFields: {
      organization: {required: true},
      department: {required: false},
      firstName: {required: true},
      lastName: {required: true},
      email: {required: true},
      phone: {required: true},
      paymentMethod: {required: true}
    }
  };
};

export default (type, context) => {
  switch (type) {
    case 'police':
      return policeConfig(context);
    case 'nav':
      return navConfig(context);
    case 'nav-translations':
      return navTranslationsConfig(context);
    case 'health':
      return healthConfig(context);
    case 'municipality':
      return municipalityConfig(context);
    case 'child-services':
      return childServicesConfig(context);
    case 'court':
      return courtConfig(context);
    case 'business':
      return businessConfig(context);
    case 'all':
      return allConfig(context);
    case 'drivers-license-exam':
      return driversLicenseExamConfig(context);
    default:
      return {};
  }
};
