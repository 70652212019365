import BookingHelper from '@/store/booking_stores/BookingHelper';

export default () => {
  return {
    namespaced: true,
    state: {
      participantsSelect: false,
      participantEmail: '',
      participantEmails: [],
      participantPhone: '',
      participantPhones: [],
      errors: {
        participantEmail: {name: 'participantEmailValidation', errors: []},
        participantPhone: {name: 'participantPhoneValidation', errors: []},
        participantPhones: {name: 'participantPhonesValidation', errors: []}
      }
    },
    actions: {
      catchParticipantsSelect({commit}, value) {
        commit('setParticipantsSelect', value);
        if (!value) commit('clearStore');
      }
    },
    mutations: {
      ...BookingHelper().mutations,
      setParticipantPhone(state, value) {
        state.participantPhone = value;
      },
      addParticipantPhones(state, value) {
        state.participantPhones.push(value);
      },
      removeParticipantPhone(state, index) {
        state.participantPhones.splice(index, 1);
      },
      setInviteEmail(state, value) {
        state.participantEmail = value;
      },
      addInvitees(state, value) {
        state.participantEmails.push(value);
      },
      removeInvitees(state, {index, id}) {
        if (id) {
          state.participantEmails.splice(index, 1, {id: state.participantEmails[index].id, deleted: true});
        } else {
          state.participantEmails.splice(index, 1);
        }
      },
      setInviteesErrors(state, {data, context}) {
        Object.keys(data).forEach((index) => {
          if (data[+index].email.includes('Sorry, we are not able to add this person to your booking. Please contact customer support if urgent.')) {
            state.participantEmails[+index].error = context.$gettext('User you\'re inviting is already a member of other company. Please contact customer support if urgent.');
          } else if (data[+index].email.includes('Invalid email')) {
            state.participantEmails[+index].error = context.$gettext('Please check the email address you have provided for spelling errors, and try again.');
          } else if (data[+index].email.includes('Not allowed role for person in cc-emails')) {
            state.participantEmails[+index].error = context.$gettext('Sorry, you can not add this email.');
          } else {
            state.participantEmails[+index].error = false;
          }
        });
      },
      removeInviteesErrors(state) {
        state.participantEmails.forEach((item) => { item.error = false; });
      },
      setParticipantsSelect(state, value) {
        state.participantsSelect = value;
      },
      clearStore(state) {
        state.participantsSelect = false;
        state.participantEmail = '';
        state.participantEmails = [];
        state.participantPhone = '';
        state.participantPhones = [];
        state.errors = {
          participantEmail: {name: 'participantEmailValidation', errors: []},
          participantPhone: {name: 'participantPhoneValidation', errors: []},
          participantPhones: {name: 'participantPhonesValidation', errors: []}
        };
      }
    }
  };
};
