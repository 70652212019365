<script>
  // ToDo: Delete this component in future, also delete direct booking page,
  // add query param with interpreter uid for interpreter preselection on the Posting page,
  // use new flow and new availability component for direct booking jobs
  import {mapState, mapMutations} from 'vuex';
  import DateTimeSection from '@/components/booking_components/parts/DateTimeSection';

  export default {
    extends: DateTimeSection,
    computed: {
      ...mapState('TTInterBookingStore', [
        'availableDates',
        'availableTimes',
        'relatedAvailableTimes'
      ]),
      maxMonth() { return this.$moment().add(3, 'month'); },
      allowPastBooking() { return false; },
      canShowFinishDate() { return false; },
      relatedAvailableTimesList() {
        return this.assignmentStartDate === this.$moment().format('YYYY-MM-DD')
          ? this.relatedAvailableTimes
          : this.availableTimes;
      },
      blockedStartDatesArray() {
        const res = [];
        const allDates = [];
        const finishMonth = this.$moment().add(4, 'month').month();
        let iterationDate = this.$moment();
        while (iterationDate.month() != finishMonth) {
          allDates.push(iterationDate.format('YYYY-MM-DD'));
          iterationDate = iterationDate.add(1, 'day');
        }
        if (this.availableDates) {
          for (const formatedDate of allDates) {
            if (this.availableDates.indexOf(formatedDate) == -1) {
              res.push(formatedDate);
            }
          }
          return res;
        }
        return allDates;
      },
      lastAvailableTimeInRange() {
        if (this.availableTimes) {
          const res = [];
          let startTime = this.$moment(this.fullStartTime);
          while (this.isInTimeRange(startTime.format('HH:mm'))) {
            res.push(startTime);
            startTime = startTime.add(5, 'minutes');
          }
          return res[res.length - 1];
        }
      },
      availableStartTime() {
        if (this.relatedAvailableTimesList) {
          return this.relatedAvailableTimesList.map((item) => {
            return {id: item, name: item};
          });
        }
        return [];
      },
      availableFinishTime() {
        if (this.availableTimes) {
          const res = [];
          const itemsInDuration = this.minJobDuration / 5;
          const lastTimeInRange = this.lastAvailableTimeInRange ? this.lastAvailableTimeInRange.add(this.minJobDuration, 'minutes') : this.$moment();
          let startTime = this.$moment(this.fullStartTime).add(this.minJobDuration, 'minutes');

          do {
            res.push(this.convertMidnight(startTime.format('HH:mm')));
            startTime = startTime.add(5, 'minutes');
          } while (this.isInTimeRange(startTime.format('HH:mm')));

          if (itemsInDuration && this.availableTimes.length) {
            for (let i = 0; i < itemsInDuration; i += 1) {
              if (startTime.date() !== this.$moment(this.fullStartTime).date()) break;
              if (startTime.isBefore(lastTimeInRange)) {
                res.push(this.convertMidnight(startTime.format('HH:mm')));
                startTime = startTime.add(5, 'minutes');
              }
            }
          }

          return res.map((item) => {
            return {id: item, name: item};
          });
        }
        return [];
      }
    },
    watch: {
      assignmentFinishTime() {
        this.finishTimeErrorHandling();
      }
    },
    methods: {
      ...mapMutations('TTInterBookingStore', [
        'setAvailabilityWarning',
        'clearAvailabilityWarning'
      ]),
      finishTimeErrorHandling() {
        if (!this.assignmentFinishTime) return;
        const finishTime = this.convertMidnight(this.assignmentFinishTime);
        this.clearAvailabilityWarning();
        if (this.availableFinishTime.length && !this.isInAvailableFinishTimes(finishTime)) {
          this.setAvailabilityWarning(this.$gettext('The interpreter is unavailable during chosen period. Please pick the one of the offered items.'));
        }
      },
      convertMidnight(time) { return time == '00:00' ? '23:59' : time; },
      isInAvailableTimes(time) { return this.relatedAvailableTimesList.find((item) => item == time); },
      isInTimeRange(time) { return this.isInAvailableTimes(this.convertMidnight(time)); },
      isInAvailableFinishTimes(time) { return this.availableFinishTime.find((item) => item.name == time); }
    }
  };
</script>
