export default ({$fetcher: fetchData, $moment: moment}) => {
  const getTimeFromHours = (hours) => {
    if (hours == 24) return moment('23:59', 'HH:mm');
    return moment('00:00', 'HH:mm').add(hours, 'hours');
  };
  const getHoursFromTime = (time) => {
    const hours = moment(time, 'HH:mm').hours();
    const minutes = moment(time, 'HH:mm').minutes();
    return hours + ((minutes == 59 ? 60 : minutes) / 60);
  };

  return {
    namespaced: true,
    state: {workingHoursData: ''},
    actions: {
      fetchWorkingHours({commit, rootGetters}) {
        return new Promise((resolve, reject) => {
          fetchData.fetchWorkingHours(rootGetters['UserInfoStore/userUid']).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setWorkingHours', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      sendWorkingHours({state, commit, rootGetters}) {
        const form = new FormData();
        const workingHours = state.workingHoursData || [];
        for (const day of workingHours) {
          if (day.isBlockedDay) {
            form.append('interpreter_offtimes[working_hours][][day]', day.day);
            form.append('interpreter_offtimes[working_hours][][from]', 0);
            form.append('interpreter_offtimes[working_hours][][to]', 0);
          } else {
            form.append('interpreter_offtimes[working_hours][][day]', day.day);
            form.append('interpreter_offtimes[working_hours][][from]', day.from);
            form.append('interpreter_offtimes[working_hours][][to]', day.to === 0 ? 24 : day.to);
          }
        }
        return new Promise((resolve, reject) => {
          fetchData.sendWorkingHours(form, rootGetters['UserInfoStore/userUid']).then((data) => {
            commit('setWorkingHours', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      }
    },
    mutations: {
      setError(state, {fieldName, errorText}) {
        state.errors[fieldName].errors = [...state.errors[fieldName].errors, errorText];
      },
      removeErrors(state) {
        for (const key of Object.keys(state.errors)) {
          state.errors[key].errors = [];
        }
      },
      setWorkingHoursStartTime(state, {id, value}) {
        if (state.workingHoursData) {
          for (const day of state.workingHoursData) {
            if (day.day == id) {
              day.startTime = value;
              day.from = getHoursFromTime(value);
            }
          }
        }
      },
      setWorkingHoursFinishTime(state, {id, value}) {
        if (state.workingHoursData) {
          for (const day of state.workingHoursData) {
            if (day.day == id) {
              day.finishTime = value;
              day.to = getHoursFromTime(value);
            }
          }
        }
      },
      setWorkingHoursBlockDay(state, {id, value}) {
        if (state.workingHoursData) {
          for (const day of state.workingHoursData) {
            if (day.day == id) {
              day.isBlockedDay = value;
            }
          }
        }
      },
      setWorkingHours(state, data) {
        const res = [];
        if (data.workingHours && data.workingHours.length) {
          for (const day of data.workingHours) {
            const weekDay = moment().day(day.day).day();
            const startTime = getTimeFromHours(day.from).format('HH:mm');
            const finishTime = day.to === 0 ? '23:59' : getTimeFromHours(day.to).format('HH:mm');
            const objForPush = {
              day: day.day,
              dayOrder: weekDay > 0 ? weekDay - 1 : 6,
              startTime,
              finishTime,
              to: day.to,
              from: day.from,
              isBlockedDay: day.from == day.to
            };
            res.push(objForPush);
          }
        }
        state.workingHoursData = res.sort((a, b) => a.dayOrder - b.dayOrder);
      },
      clearStore(state) {
        state.workingHoursData = '';
      }
    }
  };
};
