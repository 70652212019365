(<template>
  <div class="interpreter-blocked-status">
    <button v-if="!hideButton"
            class="interpreter-blocked-status__tooltip-btn"
            @click.stop.prevent="toggleTooltip"></button>
    <div v-if="showTooltip"
         :class="{'interpreter-blocked-status__tooltip-text--hide-button': hideButton}"
         class="interpreter-blocked-status__tooltip-text">
      <button class="interpreter-blocked-status__close-btn sk-modal__close-btn"
              @click.stop.prevent="toggleTooltip"></button>
      <p class="interpreter-blocked-status__text interpreter-blocked-status__text--header">{{ tooltipHeader }}</p>
      <p v-for="(paragraph, index) in tooltipParagraphs"
         :key="index">{{ paragraph.text }}</p>
      <div v-if="$isGodmode()"
           class="interpreter-blocked-status__link"
           v-html="adminPanelLink"></div>
    </div>
  </div>
</template>)

<script>
  import helpers from '@/helpers';
  import constants from '@/modules/constants';

  export default {
    props: {
      interpreterData: {
        type: Object,
        default: () => {
          return {
            id: '',
            sessionTypeStatus: {
              video: 'active',
              phone: 'active',
              videoroom: 'active',
              'in-person': 'active'
            },
            blockedForEnterprises: [],
            accountStatus: 'active'
          };
        }
      },
      hideButton: Boolean
    },
    data() {
      return {
        showTooltip: this.hideButton
      };
    },
    computed: {
      tooltipHeader() {
        return this.$gettext('This interpreter is blocked for:');
      },
      accountStatus() {
        return this.interpreterData.accountStatus || '';
      },
      userId() {
        return this.interpreterData.id;
      },
      adminPanelLink() {
        const template = this.$gettext('Go to <a href="%{link}" target="_blank" class="sk-link sk-link--default">the admin panel</a> for more details');
        const link = `${constants.ADMIN_PATH}/radmin/person/${this.userId}/statuses`;

        return this.$gettextInterpolate(template, {
          link
        });
      },
      blockedForEnterprises() {
        const enterprises = this.interpreterData.blockedForEnterprises || [];

        return enterprises.map((ent) => ` ${ent}`) || [];
      },
      blockedAssignmentTypes() {
        const sessionTypes = this.interpreterData.sessionTypeStatus || {};

        return Object.entries(sessionTypes)
          // eslint-disable-next-line no-unused-vars
          .filter(([key, value]) => helpers.interpreterInfo.isBlockedSessionType(value))
          .map(([key, value]) => ` ${helpers.getJobInfo.jobType(key, this)} (${this.getBlockedStatusText(value)})`);
      },
      tooltipParagraphsList() {
        return [{
          condition: this.blockedAssignmentTypes.length,
          text: this.$gettextInterpolate(this.$gettext('- The assignment type(s): %{types}'), {
            types: this.blockedAssignmentTypes
          })
        }, {
          condition: this.blockedForEnterprises.length,
          text: this.$gettextInterpolate(this.$gettext('- Enterprises: %{enterprises}'), {
            enterprises: this.blockedForEnterprises
          })
        }, {
          condition: helpers.interpreterInfo.isBlockedAccountStatus(this.accountStatus),
          text: this.$gettextInterpolate(this.$gettext('- Account: %{accountStatus}'), {
            accountStatus: helpers.interpreterInfo.getAccountStatus(this, this.accountStatus)
          })
        }];
      },
      tooltipParagraphs() {
        return this.tooltipParagraphsList.filter((paragraph) => paragraph.condition);
      }
    },
    methods: {
      toggleTooltip() {
        this.showTooltip = !this.showTooltip;
      },
      getBlockedStatusText(status) {
        switch (status) {
          case 'paused':
            return this.$gettext('paused');
          case 'blocked':
            return this.$gettext('blocked');
          default:
            return '';
        }
      }
    }
  };
</script>

<style scoped>
  .interpreter-blocked-status {
    position: relative;
  }

  .interpreter-blocked-status__tooltip-btn {
    display: block;
    width: 30px;
    height: 30px;
    background-image: url(~@assets/imgs/undefined_imgs/block_inter_icon_active_mob.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .interpreter-blocked-status__tooltip-text {
    position: absolute;
    z-index: 1;
    width: 400px;
    padding: 10px;
    border-radius: 3px;
    background-color: #fff;
    color: #f04;
    box-shadow: 0 0 9px 0 rgba(0, 34, 102, 0.3);
    font-size: 12px;
    text-align: left;
  }

  .interpreter-blocked-status__tooltip-text--hide-button {
    position: static;
    padding: 0;
    box-shadow: none;
  }

  .interpreter-blocked-status__text--header {
    font-weight: bold;
  }

  .interpreter-blocked-status__close-btn {
    display: none;
  }

  .interpreter-blocked-status__link {
    margin-top: 15px;
    color: #333;
    font-weight: bold;
  }

  @media (max-width: 767px) {
    .interpreter-blocked-status__close-btn {
      display: block;
    }

    .interpreter-blocked-status__tooltip-text {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 20;
      width: 100%;
      height: 100%;
      padding-top: 30px;
    }
  }
</style>
