// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/new_assignment_plus.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".new-assignment-btn {\n  position: relative;\n  display: inline-block;\n  -ms-flex-item-align: center;\n      align-self: center;\n  height: 35px;\n  margin-left: 10px;\n  padding: 0 10px 0 30px;\n  border-radius: 3px;\n  background-color: var(--color-primary);\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 8px 50%;\n  background-repeat: no-repeat;\n  color: #fff;\n  font-weight: bold;\n  line-height: 34px;\n  white-space: nowrap;\n}\n.new-assignment-btn:focus {\n  z-index: 1;\n  outline: 2px solid #3af;\n}\n.new-assignment-btn:hover {\n  opacity: 0.65;\n}\n.new-assignment-btn:active {\n  color: #fff;\n  outline: none;\n  opacity: 1;\n}\n@media (max-width: 767px) {\n.new-assignment-btn {\n    display: none;\n}\n}\n", ""]);
// Exports
module.exports = exports;
