const camelToSnakeCase = (key) => {
  const result = key.replace(/([A-Z])/g, ' $1');
  return result.split(' ').join('_').toLowerCase();
};

const mapToRansackPredicates = (pred) => {
  switch (pred) {
    case 'contains':
      return '_cont';
    case 'notContains':
      return '_not_cont';
    case 'equal':
      return '_eq';
    case 'notEqual':
      return '_not_eq';
    case 'startsWith':
      return '_start';
    case 'endsWith':
      return '_end';
    case 'blank':
      return '_blank';
    // -- BE uses _blank for blank & notBlank -- //
    case 'notBlank':
      return '_blank';
    case 'greaterThan':
      return '_gteq';
    case 'lessThan':
      return '_lteq';
    case 'inRange':
      return '_range';
    case 'inList':
      return '_in';
    default:
      return '_eq';
  }
};

export default {
  camelToSnakeCase,
  mapToRansackPredicates
};
