// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/loading_animation.gif");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".is-progress-bar {\n  position: relative;\n}\n.is-progress-bar::after {\n  content: '';\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 10;\n  display: none;\n  background-color: #fff9;\n}\n.is-progress-bar::before,\n.is-small-progress.is-progress-bar::before {\n  content: '';\n  position: absolute;\n  top: 50%;\n  z-index: 11;\n  display: none;\n  width: 100%;\n  height: 100%;\n  max-height: 70px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-image: var(--image-loader);\n  background-position: 50% 50%;\n  background-size: contain;\n  background-repeat: no-repeat;\n  -webkit-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n}\n.no-events.is-progress-bar {\n  pointer-events: none;\n}\n.in-progress.is-progress-bar.pointer::before {\n  top: 0;\n}\n.in-progress.is-progress-bar::before {\n  display: block;\n}\n.in-progress.is-progress-bar::after {\n  display: block;\n}\n", ""]);
// Exports
module.exports = exports;
