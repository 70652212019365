import Vue from 'vue';

export default new Vue({
  methods: {
    setDataFromSockets(context, data) {
      if (data
          && data.device !== undefined
          && data.browser !== undefined
          && data.video !== undefined) {
        if (context.$route.name.indexOf('TestVideo') != -1
            && !context.$store.state.TTTestVideoStore.wantToStart
            && context.$store.state.TTTestVideoStore.activeRecording == data.id) {
          context.$store.dispatch('TTTestVideoStore/getOneRecording', data.id);
        }
      }
      const route = context.$router.currentRoute.name;
      const routeAssignID = context.$router.currentRoute.params.id || '';
      let eventType = '';
      data?.jobInfo ? eventType = 'chatEvent' : data?.person ? eventType = 'updateUserInfo' : '';

      if (eventType === 'chatEvent' && (route === 'BuyerOneAssignment' || route === 'ServerOneAssignment') && routeAssignID == data.jobInfo.jobId) {
        context.$store.commit('OneAssignmentStore/setJobStatus', data.jobInfo.jobStatus);
        if (data.jobInfo.jobStatus && context.$store.getters['OneAssignmentStore/storeJob']) {
          if (data.jobInfo.jobStatus === 'accepted') {
            if (data.event.type === 'auto_award') {
              // Buyer side
              if (context.$store.getters['UserInfoStore/userIsNotInterpreter']) {
                context.$store.dispatch('OneAssignmentStore/getOneDiscussion', data.jobInfo.discussionId);
              }
              // Supplier side
              if (context.$store.getters['UserInfoStore/userIsInterpreter']) {
                context.$store.dispatch('OneAssignmentStore/getDiscussions', routeAssignID);
              }
            }
            context.$store.dispatch('OneAssignmentStore/getJobById', data.jobInfo.jobId).then(() => {
              if (data.event.data && data.event.data.length) {
                context.$store.commit('OneAssignmentStore/setAttachments', JSON.parse(JSON.stringify(data.event.data)));
              }
            });
          } else if (data.jobInfo.jobStatus === 'cancelled') {
            context.$store.dispatch('OneAssignmentStore/updateCancelInfo', {
              cancelledReason: data.jobInfo.cancelledReason,
              cancellerName: data.jobInfo.cancellerName,
              cancelledAt: data.jobInfo.cancelledAt
            });
          } else {
            if (data.jobInfo.discussionId) {
              // Buyer side
              if (context.$store.getters['UserInfoStore/userIsNotInterpreter']) {
                context.$store.dispatch('OneAssignmentStore/getOneDiscussion', data.jobInfo.discussionId);
              }
              // Supplier side
              if (context.$store.getters['UserInfoStore/userIsInterpreter']) {
                context.$store.dispatch('OneAssignmentStore/getDiscussions', routeAssignID);
              }
            }
          }
        }
        if (context.$store.state.OneAssignmentStore.currentDiscussionId == data.jobInfo.discussionId
           || (context.$store.getters['UserInfoStore/userIsInterpreter'] && context.$store.state.OneAssignmentStore.chatDiscussions
               && context.$store.state.OneAssignmentStore.chatDiscussions?.discussion?.id)) {
          context.$store.commit('OneAssignmentStore/setNewDiscussionEvent', data.event);
          context.$store.commit('OneAssignmentStore/setNewKeyEvent', data);
          data.jobInfo.discussionStatus ? context.$store.commit('OneAssignmentStore/setNewDiscussionStatus', data.jobInfo.discussionStatus) : '';
        } else {
          let existingDiscussion = false;
          const discussions = context.$store.getters['OneAssignmentStore/discussionsList'];
          if (discussions) {
            for (let i = 0; i < discussions.length; i += 1) {
              if (data.jobInfo.discussionId == context.$store.getters['OneAssignmentStore/discussionsList'][i].id) {
                existingDiscussion = true;
              }
            }
          }
          existingDiscussion ? context.$store.commit('OneAssignmentStore/setNewKeyEvent', data) : context.$store.dispatch('OneAssignmentStore/getDiscussions', routeAssignID);
        }
      } else if (eventType === 'chatEvent') {
        const jobInfo = {id: data.jobInfo.jobId};
        jobInfo.status = (data.jobInfo.discussionStatus === 'applied' || data.jobInfo.discussionStatus === 'invited')
          ? data.jobInfo.discussionStatus : data.jobInfo.jobStatus;
        if (route === 'BuyerAllAssignments' || route === 'BuyerHome') {
          if (route === 'BuyerHome') {
            context.$store.dispatch('b_DashboardStore/b_getTodayCalendarJobs', {
              view: 'day',
              date: context.$moment().format('YYYY-MM-DD')
            });
            context.$store.dispatch('b_DashboardStore/b_getTomorrowCalendarJobs', {
              view: 'day',
              date: context.$moment(context.$moment().add(1, 'day')).format('YYYY-MM-DD')
            });
          }
          if (jobInfo.status === 'invited' || (jobInfo.status === 'published' && (!data.event || !data.event.type))) {
            if (route === 'BuyerAllAssignments') {
              context.$store.dispatch('AllAssignmentsStore/getMyAllAssignments', jobInfo);
            } else {
              context.$store.dispatch('b_DashboardStore/getDashboardFutureAssignments');
            }
          }
          if (route === 'BuyerAllAssignments') {
            context.$store.commit('AllAssignmentsStore/updateBuyerJobInfo', jobInfo);
          } else {
            context.$store.commit('b_DashboardStore/updateBuyerDashboardJobInfo', jobInfo);
          }
        } else if (route === 'ServerAllAssignments' || route === 'ServerHome' || route === 'ServerAllInvitations') {
          if (route === 'ServerHome') {
            context.$store.dispatch('s_DashboardStore/s_getTodayCalendarJobs', {
              view: 'day',
              date: context.$moment().format('YYYY-MM-DD')
            });
            context.$store.dispatch('s_DashboardStore/s_getTomorrowCalendarJobs', {
              view: 'day',
              date: context.$moment(context.$moment().add(1, 'day')).format('YYYY-MM-DD')
            });
          }
          if (data.jobInfo.discussionStatus === 'abandoned' || data.jobInfo.discussionStatus === 'hidden') {
            jobInfo.status = data.jobInfo.discussionStatus;
          }
          if (jobInfo.status === 'invited' || (jobInfo.status === 'published' && (!data.event || !data.event.type))) {
            if (route === 'ServerAllAssignments') {
              context.$store.dispatch('AllAssignmentsStore/getAllAvailableAssignments');
            } else if (route === 'ServerAllInvitations') {
              context.$store.dispatch('s_DashboardStore/getInvitedAssignmentDashboard', null);
            } else {
              context.$store.dispatch('s_DashboardStore/getAvailableAssignmentsDashboard', {items: 20, page: 1});
            }
            if (route !== 'ServerAllInvitations') context.$store.commit('s_DashboardStore/setNewInvitationFlag', true);
          }
          if (route === 'ServerAllAssignments') {
            context.$store.commit('AllAssignmentsStore/updateServerJobInfo', jobInfo);
          } else if (route === 'ServerAllInvitations') {
            context.$store.commit('s_DashboardStore/updateServerAvailableJobInfo', jobInfo);
          } else {
            context.$store.commit('s_DashboardStore/updateServerDashboardJobInfo', jobInfo);
          }
        }
        // if (data.event && !data.event.own) {
        //   const template = context.$gettext('You have a new message from %{n} for assignment %{m}');
        //   const message = context.$gettextInterpolate(template, {n: data.jobInfo.actorName, m: data.jobInfo.jobId});
        //   if (Notification.permission === 'granted') {
        //     // eslint-disable-next-line
        //     const notification = new Notification(message);
        //     notification.onclick = function focusOnTab() {
        //       const routeName = context.$store.getters['UserInfoStore/userIsInterpreter'] ? 'ServerOneAssignment' : 'BuyerOneAssignment';
        //       context.$router.push(context.$makeRoute({name: routeName, params: {id: data.jobInfo.jobId}}));
        //       window.focus();
        //       this.close();
        //     };
        //   } else if (Notification.permission !== 'denied') {
        //     Notification.requestPermission((permission) => {
        //       if (permission === 'granted') {
        //         // eslint-disable-next-line
        //         const notification = new Notification(message);
        //       }
        //     });
        //   }
        // }
      }
      if ((data.feedbackActualStartTime || data.feedbackActualFinishTime)
        && (route === 'BuyerOneAssignment' || route === 'ServerOneAssignment')) {
        context.$store.dispatch('OneAssignmentStore/setOtherSideFeedbackTimes', data);
      }

      if (eventType === 'updateUserInfo' && context.$store.getters['UserInfoStore/userUid'] === data.person.uid) {
        context.$store.commit('UserInfoStore/setUserInfo', {userInfoObj: data.person});
        if (context.$store.state.EditProfileStore.profileData && context.$store.state.EditProfileStore.profileData.person) {
          context.$store.commit('EditProfileStore/WSUpdateUserProfile', {userInfoObj: data.person});
        }
      }

      if (data?.suitableInterpreters
          && context.$store.getters['UserInfoStore/userIsNotInterpreter']
          && context.$store.getters['OneAssignmentStore/jobId'] === data.jobId) {
        context.$store.commit('FAIListStore/setSuitableInterpretersIds', data.suitableInterpreters);
      }

        if (data?.new_frontend_app_version) {
          // new version published notify users
          context.$store.commit('ModalStore/setModal', {
            component: 'outdated-client-modal',
            width: 410
          });
        }
    },
    setDataFromJobChannel(context, data) {
      if (data) context.$store.dispatch('ViewersStore/setViewers', data);
    },
    setDataFromAdminDiscussionChannel(context, data) {
      if (data?.entityId) context.$store.dispatch('AdminChatStore/fetchAdminMessages', {entityId: data?.entityId, entityType: data?.entityType});
    },
    // Manager Interpretation Jobs Page Sockets Date
    setDataFromManagersAssignmentsChannel(context, data) {
       context.$store.commit('ManagerSocketDataStore/setManagerJobAssignments', data);
    },
    setDataFromManagerJobStatsChannel(context, data) {
      context.$store.commit('ManagerSocketDataStore/setManagerJobStats', data);
    },
    setDataFromManagerJobActivityChannel(context, data) {
      context.$store.commit('ManagerSocketDataStore/setManagerJobActivity', data);
    },
    // Manager Translation Jobs Page Sockets Data
    setDataFromSubtasksStatisticsChannel(context, data) {
      context.$store.commit('ManagerSocketDataStore/setManagerSubtaskStats', data);
    },
    setDataFromSubtasksScopesManagersAssignmentsChannel(context, data) {
      context.$store.commit('ManagerSocketDataStore/setManagerSubtaskTabsAssignments', data);
    },
  }
});
