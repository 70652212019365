const suppliers = {
  methods: {
    // ==================================================
    // ==================================================
    // SUPPLIERS CALLS
    // ==================================================
    // ==================================================
    getSuppliers(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/managers/suppliers', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    createSupplier(params) {
      return new Promise((resolve, reject) => {
        this.ajax.post('v1/managers/suppliers', params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    editSupplier(id, params) {
      return new Promise((resolve, reject) => {
        this.ajax.put(`v1/managers/suppliers/${id}`, params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
  }
};

export default suppliers;
