import Vue from 'vue';

export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      customersData: {
        customers: [],
        count: 0,
        page: 1,
        pages: 1,
      },
      selectedCustomer: {},
      updateCustomerPageGrid: '',
      searchCustomerList: [],
      customerSearchQuery: ''
    },
    actions: {
      getCustomers({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getCustomers(params).then((response) => {
            commit('setCustomersData', response);
            const resFormatted = response;
            resFormatted.data = response.customers;
            resolve(resFormatted);
          }).catch(() => {
            reject();
          });
        });
      },
      moveCustomerEnterprise(context, params) {
        return new Promise((resolve, reject) => {
          fetchData.moveCustomerEnterprise(params).then((response) => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      upgradeBuyerToEnterprise(context, params) {
        return new Promise((resolve, reject) => {
          fetchData.managerCreateEnterprise(params).then((response) => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      convertBuyerToEnterprise(context, params) {
        return new Promise((resolve, reject) => {
          fetchData.addMemberAsManager(params).then((response) => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      searchEnterCustomersByName({commit}, searchName) {
        return new Promise((resolve, reject) => {
          const params = {
            's[name_cont]': searchName,
            's[enterprise_membership_id_not_null]': 1
          };
          fetchData.getCustomers(params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setSearchCustomersList', data.customers);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      cancelSearchCustomers() {
        return new Promise((resolve, reject) => {
          fetchData.cancelSearchCustomers().then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      mergeDuplicateCustomers(context, params) {
        return new Promise((resolve, reject) => {
          fetchData.mergeDuplicateAccounts(params).then((response) => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      createCustomer(context, params) {
        return new Promise((resolve, reject) => {
          fetchData.createCustomer(params).then((response) => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        });
      },
    },
    mutations: {
      setCustomersData(state, data) {
        state.customersData = data;
      },
      setSelectedCustomer(state, customer) {
        state.selectedCustomer = customer;
      },
      refreshCustomerGrid(state) {
        state.updateCustomerPageGrid = Date.now();
      },
      setCustomerSearchQuery(state, value) {
        state.customerSearchQuery = value;
      },
      setSearchCustomersList(state, data) {
        Vue.set(state, 'searchCustomerList', data);
      },
      clearSelectedCustomer(state) {
        state.selectedCustomer = {};
      },
      clearStore(state) {
        state.customersData = {
          customers: [],
          count: 0,
          page: 1,
          pages: 1,
        };
        state.selectedCustomer = {};
        state.searchCustomerList = [];
        state.customerSearchQuery = '';
      }
    }
  };
};
