export default () => {
  return {
    namespaced: true,
    state: {
      extraInfo: '',
      extraInfoSelectState: false,
      priceQoute: false,
    },
    mutations: {
      setExtraInfo(state, value) {
        state.extraInfo = value;
      },
      setExtraInfoSelectState(state, value) {
        state.extraInfoSelectState = value;
      },
      setPriceQoute(state, data) {
        state.priceQoute = data;
      },
      clearStore(state) {
        state.extraInfo = '';
        state.extraInfoSelectState = false;
        state.priceQoute = false;
      }
    }
  };
};
