const sellers = {
  methods: {
    // ==================================================
    // ==================================================
    // SELLER CALLS
    // ==================================================
    // ==================================================
    addTools(id, params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/sellers/${id}/translation_tools`, params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    getTools(id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/sellers/${id}/translation_tools`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    removeTool(userId, toolId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.delete(`/v1/interpreters/${userId}/tools/${toolId}`).then(() => {
          resolve();
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    getExperiences(uid) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/sellers/${uid}/translation_experiences`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    addExperience(uid, payload) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/sellers/${uid}/translation_experiences`, payload).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    editExperience(uid, payload, experienceId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/sellers/${uid}/translation_experiences/${experienceId}`, payload).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    removeExperience(uid, experienceId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.delete(`/v1/sellers/${uid}/translation_experiences/${experienceId}`).then(() => {
          resolve();
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    fetchTranslationMethods(locale) {
      return new Promise((resolve, reject) => {
        this.ajax.get('v1/sellers/translation_methods', {params: {locale}}).then((response) => {
          const resp = response ? response?.data || {} : {};
          resolve(resp);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          this.resolveError500(error, resolve);
          reject(error?.data || {});
        });
      });
    },
    updateTranslationMethods(payload) {
      return new Promise((resolve, reject) => {
        this.ajax.post(`/v1/sellers/${payload.uid}/translation_methods`, payload).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    }
  }
};

export default sellers;
