<script>
  import {mapActions, mapState} from 'vuex';
  import PostingTogglePrototype from '@/prototypes/PostingTogglePrototype';

  export default {
    extends: PostingTogglePrototype,
    computed: {
      ...mapState('OneAssignmentStore', {
        storeJobObj: (state) => state.storeJobObj || {}
      }),
      toggleValue() {
        return this.storeJobObj.autoAwardAllowed === undefined ? true : this.storeJobObj.autoAwardAllowed;
      },
      toggleDescription() { return this.$gettext('Auto award'); }
    },
    methods: {
      ...mapActions('OneAssignmentStore', ['updateAutoApprove']),
      toggleHandler(value) {
        this.progressActive = true;
        this.updateAutoApprove(value).then(() => {
          this.progressActive = false;
        }).catch(() => {
          this.progressActive = false;
        });
      }
    }
  };
</script>
