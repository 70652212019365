(<template>
  <div class="person-table">
    <filters
      :class="{'fai-filters-wrapper': isCompact}"
      @filterinterpreters="filterInterpreters"
      @searchinterpreters="searchInterpreters" />
    <div v-if="interpretersList.length"
         :class="{'person-table__wrapper--big': inPersonJob && isCompact}"
         class="person-table__wrapper">
      <div class="person-table__header">
        <div class="person-table__row">
          <td v-for="cell in cells"
              :key="cell.key"
              class="person__cell"
              :class="cell.classes">{{ cell.text }}</td>
        </div>
      </div>
      <one-person v-for="interpreter in interpretersList"
                  :key="interpreter.uid"
                  :interpreter="interpreter"
                  :show-distance="inPersonJob"
                  :is-compact="isCompact"
                  @invite="inviteInterpreter"
                  @applyasinterpreter="applyAsInterpreter"
                  @declineinvite="declineInvite"
                  @changeinterpreterrating="changeInterpreterRating"
                  @loginasinterpreter="loginAsInterpreter"
                  @refetchassignment="refetchAssignment"
                  @blockinterpreter="blockInterpreterFromJob" />
    </div>
    <div v-else
         class="person-table__no-inters-text">{{ noInterpretersText }}</div>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';
  import OnePersonForAdmin from '@/components/assignment_components/one_assignment/interactive_info/invite_suppliers/InterpreterForAdminFAIBlock';
  import FAIFilters
    from '@/components/assignment_components/one_assignment/interactive_info/invite_suppliers/FAIFilters';

  export default {
    components: {
      'one-person': OnePersonForAdmin,
      filters: FAIFilters
    },
    props: {
      interpretersList: {
        type: Array,
        required: true,
        default: () => []
      },
      isCompact: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        assignmentRequirements: ({storeJobObj}) => storeJobObj.interpretationRequirement || {}
      }),
      inPersonJob() {
        const assignmentType = this.assignmentRequirements.sessionType;
        return assignmentType && (assignmentType === 'in_person' || assignmentType === 'videoroom');
      },
      cellsList() {
        if (this.isCompact) {
          return [
            {id: 'actions', text: ''},
            {id: 'name', text: this.$gettext('Name')},
            {id: 'phone', text: this.$gettext('Phone')},
            {id: 'availability', text: this.$gettext('Availability')},
          ];
        } else {
          return [
            {id: 'actions', text: ''},
            {id: 'name', text: this.$gettext('Name')},
            {id: 'phone', text: this.$gettext('Phone')},
            {id: 'availability', text: this.$gettext('Availability')},
            {id: 'level', text: this.$gettext('Level')},
            {id: 'city', text: this.$gettext('City')},
            {id: 'distance', text: this.$gettext('Distance'), condition: this.inPersonJob},
            {id: 'status', text: this.$gettext('Availability')},
            {id: 'reason', text: this.$gettext('Reason(s)')},
            {id: 'last_invited', text: this.$gettext('Last invited'), classes: ['person__cell--invited']},
            {id: 'info', text: '', classes: ['person__cell--info']},
          ];
        }
      },
      cells() {
        return this.cellsList.filter((cell) => {
          return cell.condition !== undefined ? cell.condition : true;
        });
      },
      noInterpretersText() {
        return this.$gettext('There are no interpreters available who are suitable for this assignment. You can change the filters above to see more interpreters');
      }
    },
    methods: {
      inviteInterpreter(interpreterUid, invitesCount) {
        this.$emit('invite', interpreterUid, invitesCount);
      },
      applyAsInterpreter(interpreterUid, interpreterId, interpreterName) {
        this.$emit('applyasinterpreter', interpreterUid, interpreterId, interpreterName);
      },
      declineInvite(declineObj) {
        this.$emit('declineinvite', declineObj);
      },
      loginAsInterpreter(interpreterUid, interpreterId) {
        this.$emit('loginasinterpreter', interpreterUid, interpreterId);
      },
      filterInterpreters() {
        this.$emit('filterinterpreters');
      },
      searchInterpreters(showInvitationQueue) {
        this.$emit('searchinterpreters', showInvitationQueue);
      },
      changeInterpreterRating(action, interpreterId) {
        this.$emit('changeinterpreterrating', action, interpreterId);
      },
      refetchAssignment() {
        this.$emit('refetchassignment');
      },
      blockInterpreterFromJob(blockObj) {
        this.$emit('blockinterpreter', blockObj);
      }
    }
  };
</script>

<style scoped src="@assets/css/interpreter_for_admin_fai_block.css"></style>
<style scoped>
.fai-filters-wrapper {
  padding: 0 30px 15px;
}
</style>
