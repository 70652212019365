(<template>
  <div class="permission-modal">
    <div v-for="(paragraph, index) in modalParagraphs"
         :key="index"
         class="permission-modal__paragraph"
         v-html="paragraph"></div>
    <template v-if="!profileDob">
      <p class="pers-info-modal__section-text">{{ $gettext('When is your birthday?') }}</p>
      <div class="permission-modal__dob-selects-cont">
        <sk-select :default-value="dateDefaultValue"
                   :description="dateText"
                   :type="'day'"
                   :preselected-value="birthDay"
                   class="permission-modal__dob-select"
                   @csvaluechanged="catchChosenBirthDay" />
        <sk-select :default-value="monthDefaultValue"
                   :description="monthText"
                   :type="'month'"
                   :preselected-value="birthMonth"
                   class="permission-modal__dob-select"
                   @csvaluechanged="catchChosenBirthMonth" />
        <sk-select :default-value="yearDefaultValue"
                   :description="yearText"
                   :type="'birthYear'"
                   :preselected-value="birthYear"
                   class="permission-modal__dob-select"
                   @csvaluechanged="catchChosenBirthYear" />
      </div>
      <div v-if="dobErrors.length"
           class="sk-input__errors-cont">
        <span v-for="(error, index) in dobErrors"
              :key="index"
              v-html="(dobErrors.length === 1) ? error : `${index + 1}. ${error}`"></span>
      </div>
    </template>
    <div class="permission-modal__btn-wrapper">
      <button class="sk-btn sk-btn--white permission-modal__btn"
              @click="submitForm(false)">{{ $gettext('I do not consent') }}</button>
      <button class="sk-btn sk-btn--default permission-modal__btn"
              @click="submitForm(true)">{{ $gettext('I grant consent') }}</button>
    </div>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        birthDay: '',
        birthMonth: '',
        birthYear: '',
        dobErrors: []
      };
    },
    computed: {
      ...mapState('EditProfileStore', {
        profileDob: ({profileData}) => profileData.person.dob || ''
      }),
      dateDefaultValue() { return this.$gettext('DD'); },
      dateText() { return this.$gettext('Day'); },
      monthDefaultValue() { return this.$gettext('MM'); },
      monthText() { return this.$gettext('Month'); },
      yearDefaultValue() { return this.$gettext('YYYY'); },
      yearText() { return this.$gettext('Year'); },
      modalParagraphs() {
        return [
          this.$gettext('The police requires all interpreters undertaking their assignments to acquire a criminal record certificate and a signed non-disclosure agreement. The police will send you information themselves about how to do this. Before we forward your contact information — name, email, address, and birth date — we ask for your consent to do so.'),
          this.$gettext('Please fill out your birth date, 8 digits, by using the dropdown menu below.')
        ];
      },
      dateOfBirth() { return [this.birthDay, this.birthMonth, this.birthYear].join('.'); }
    },
    methods: {
      catchChosenBirthDay(value) {
        this.birthDay = value;
      },
      catchChosenBirthMonth(value) {
        this.birthMonth = value;
      },
      catchChosenBirthYear(value) {
        this.birthYear = value;
      },
      closeModal() {
        this.$emit('closemodal');
      },
      submitForm(value) {
        this.dobErrors = [];
        if (value) {
          if (!this.profileDob) {
            if (this.birthDay && this.birthMonth && this.birthYear) {
              const dobMoment = this.$moment(this.dateOfBirth, 'DD.MM.YYYY');
              if (!dobMoment.isValid()) {
                this.dobErrors.push(this.$gettext('Please check the date.'));
                return;
              }
            } else {
              this.dobErrors.push(this.$gettext('Please enter your date of birth.'));
              return;
            }
          }
          const form = new FormData();
          form.append('interpreter[data_sharing_consent_for_police]', value);
          if (!this.profileDob) form.append('interpreter[person][birth]', this.dateOfBirth);
          this.$emit('startprogress');
          this.$store.dispatch('updateProfileData', form).then(() => {
            this.$store.dispatch('EditProfileStore/getUserProfile', {id: this.$store.getters['UserInfoStore/userUid']}).then(() => {
              this.$emit('stopprogress');
              this.closeModal();
              this.data.context.handleApply(this.data.applyModalParams);
            });
          });
          return;
        }
        this.closeModal();
      }
    }
  };
</script>

<style>
  .permission-modal__dob-select .sk-select__list {
    top: -2px;
    transform: translateY(-100%);
  }
</style>

<style scoped>
  .permission-modal {
    padding: 10px 30px 20px;
  }

  .permission-modal__paragraph {
    padding-bottom: 20px;
  }

  .permission-modal__btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 5px -5px 0;
  }

  .permission-modal__btn {
    width: 100%;
    max-width: 160px;
    margin: 5px;
  }

  .permission-modal__dob-selects-cont {
    display: flex;
    margin-bottom: 12px;
  }

  .permission-modal__dob-select {
    margin-right: 10px;
  }

  @media (max-width: 767px) {
    .permission-modal {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  @media (max-width: 400px) {
    .permission-modal__btn {
      max-width: 100%;
    }
  }
</style>
