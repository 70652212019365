<script>
  import {skSelect} from '@skiwo/sk-basic-components';

  export default {
    extends: skSelect,
    props: {
      itemsList: { // array of objects which will become the list of values for the select
        type: Array, // should have unique ids for categories and sub-categories
        default: () => [],
        validator: (value) => {
          return value && Array.isArray(value);
        }
      }
    },
    computed: {
      constList() {
        if (this.itemsList && this.itemsList.length) {
          return JSON.parse(JSON.stringify(this.itemsList));
        } else if (this.type) {
          switch (this.type) {
            case 'day':
              return JSON.parse(JSON.stringify(this.$helperData.getSelectDays()));
            case 'month':
              return JSON.parse(JSON.stringify(this.$helperData.getSelectMonthes(this)));
            case 'futureYear':
              return JSON.parse(JSON.stringify(this.$helperData.getSelectFutureYears()));
            case 'fromTTyear':
              return JSON.parse(JSON.stringify(this.$helperData.getSelectTTStartYears()));
            case 'pastYear':
              return JSON.parse(JSON.stringify(this.$helperData.getSelectPastYears()));
            case 'birthYear':
              return JSON.parse(JSON.stringify(this.$helperData.getSelectBirthYears()));
            case 'hour':
              return JSON.parse(JSON.stringify(this.$helperData.getSelectHours()));
            case 'minute':
              return JSON.parse(JSON.stringify(this.$helperData.getSelectMinutes()));
            case 'timePeriods':
              return JSON.parse(JSON.stringify(this.$helperData.getSelectTimePeriods()));
            case 'gender':
              return JSON.parse(JSON.stringify(this.$helperData.getSelectGenders(this)));
            case 'countryCodes':
              return JSON.parse(JSON.stringify(this.$helperData.getSelectCountryCodesLong(this)));
            case 'countries':
              return JSON.parse(JSON.stringify(this.$helperData.getSelectCountries(this)));
            case 'language':
              return JSON.parse(JSON.stringify(this.$store.state.selectsData.language));
            case 'language_from':
              return JSON.parse(JSON.stringify(this.$store.state.selectsData.language));
            case 'language_to':
              return JSON.parse(JSON.stringify(this.$store.state.selectsData.language));
            case 'qualification':
              return JSON.parse(JSON.stringify(this.$store.state.selectsData.qualification)).filter((item) => {
                return item.enabled && item.name;
              });
            case 'departments':
              return JSON.parse(JSON.stringify(this.$store.state.selectsData.departments));
            case 'staffs':
              return JSON.parse(JSON.stringify(this.$store.state.selectsData.staffs));
            case 'clients':
              return JSON.parse(JSON.stringify(this.$store.state.selectsData.clients));
          }
        }
      },
    },
    methods: {
      // overridden method from skSelect
      getSearchResults({data, searchStr}) {
        const res = [];
        for (const value of data) {
          // eslint-disable-next-line
          if (!value.name && !value.desktop) continue;
          if (!((value.name || value.desktop).toLowerCase().indexOf(searchStr.toLowerCase()) < 0)) {
            res.push(value);
          } else if (value.email && (!((value.email || value.desktop).toLowerCase().indexOf(searchStr.toLowerCase()) < 0))) {
            res.push(value);
          } else if (value.phone && !((value.phone || value.desktop).toLowerCase().indexOf(searchStr.toLowerCase()) < 0)) {
            res.push(value);
          }
        }
        res.sort(this.letterSort(searchStr));
        return res;
      },

    }
  };
</script>
