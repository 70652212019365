<script>
  import {mapState, mapMutations} from 'vuex';
  import PostingTogglePrototype from '@/prototypes/PostingTogglePrototype';

  export default {
    extends: PostingTogglePrototype,
    computed: {
      ...mapState('PostingStore/AutoInviteStore', {
        toggleValue: (state) => state.infiniteAutoInvite
      }),
      toggleDescription() { return this.$gettext('Forever'); }
    },
    methods: {
      ...mapMutations({
        toggleHandler: 'PostingStore/AutoInviteStore/setInfiniteAutoInvite'
      })
    }
  };
</script>
