(<template>
  <article class="confirmation">
    <section class="confirmation__wrapper">
      <div v-if="modalText"
           class="confirmation__text">
        {{ modalText }}
        <sk-checkbox :item-text="confirmationText"
                     :preselected-value="checkboxFlag"
                     class="apply-actions__checkbox"
                     @checkboxvaluechanged="setCheckboxFlag" /></div>
      <div class="confirmation__btns-wrapper">

        <button class="sk-btn sk-btn--white confirmation__btn"
                type="button"
                @click.prevent="closeModal">{{ modalCancelBtnText || $gettext('Cancel') }}</button>
        <button class="sk-btn sk-btn--default confirmation__btn"
                type="button"
                :disabled="!checkboxFlag"
                @click="confirm">{{ modalActionBtnText || $gettext('Confirm') }}</button>
      </div>
    </section>
  </article>
</template>)

<script>

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        checkboxFlag: false
      };
    },
    computed: {
      confirmationText() {
        return this.$gettext('I agree to the terms of this pop-up');
      },
      modalData() {
        return this.data || {};
      },
      modalText() {
        return this.modalData.text || '';
      },
      modalCallback() {
        return this.modalData.modalCallback;
      },
      modalCallbackParams() {
        return this.modalData.callbackParams;
      },
      modalBtnTexts() {
        return this.modalData.btnTexts || {};
      },
      modalActionBtnText() {
        return this.modalBtnTexts.actionBtnText || '';
      },
      modalCancelBtnText() {
        return this.modalBtnTexts.cancelBtnText || '';
      }
    },
    methods: {
      closeModal() {
        this.$store.dispatch('ModalStore/closeModal');
      },
      confirm() {
        this.modalCallback(this.modalCallbackParams);
        this.closeModal();
      },
      setCheckboxFlag() {
        this.checkboxFlag = !this.checkboxFlag;
      }
    }
  };
</script>

<style scoped>
.confirmation {
  padding: 20px 30px;
}

.apply-actions__checkbox {
  margin-top: 20px;
}

.confirmation__btns-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 20px -5px 0;
}

.confirmation__btn {
  width: auto;
  margin: 0 5px;
  padding: 0 10px;
}
</style>
