(<template>
  <post-accordion-section ref="participantSection"
                          :state="participantsSelect"
                          :title="title"
                          :description="description"
                          class="participants"
                          @focusin="openParticipantSectionHelper"
                          @statechanged="updateParticipantsSelect">
    <p class="participants-section__description">{{ inviteDescription }}</p>
    <div v-if="participantsSelect"
         class="participants-section__wrapper">
      <participant-emails class="participants__section" />
      <participant-phones v-if="!hidePhoneParticipants"
                          class="participants__section" />
    </div>
  </post-accordion-section>
</template>)

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import ParticipantPhones from '@/components/booking_components/parts/participants_section/ParticipantPhones';
  import ParticipantEmail from '@/components/booking_components/parts/participants_section/InviteEmail';
  import PostAccordionSection from '@/components/booking_components/PostAccordionSection';

  export default {
    components: {
      'post-accordion-section': PostAccordionSection,
      'participant-emails': ParticipantEmail,
      'participant-phones': ParticipantPhones
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('PostingStore/ParticipantsStore', {
        participantsSelect: ({participantsSelect}) => participantsSelect
      }),
      hidePhoneParticipants() { return this.params.hidePhoneParticipants; },
      title() {
        return this.$gettext('CC');
      },
      description() {
        switch (this.$route.name) {
          case 'BuyerPostTranslationProject':
            return this.$gettext('Send link to the finished translation to an alternative email address.');
          default:
            return this.$gettext('Send the confirmation to another contact person');
        }
      },
      inviteDescription() {
        switch (this.$route.name) {
          case 'BuyerPostTranslationProject':
            return this.$gettext('The link can only be opened once.');
          default:
            return this.$gettext('Want to send a confirmation and other notices to other participants? Add their email');
        }
      },
      showAssignmentHelper() { return this.params.showAssignmentHelper; },
      context() { return this.params.context; }
    },
    methods: {
      ...mapActions('PostingStore/ParticipantsStore', [
        'catchParticipantsSelect'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setContext',
        'setPopoverReference',
        'setReferenceToBuyerInfoSection',
      ]),
      openParticipantSectionHelper() {
        if (this.showAssignmentHelper && this.context == 'translation') {
          this.setContext('translation');
          this.setPopoverReference(this.$refs.participantSection);
          this.openHelper('participant');
        } else {
          this.setContext('interpretation');
          this.setPopoverReference(this.$refs.participantSection);
          this.openHelper('participant');
        }
      },
      updateParticipantsSelect(value) {
        if (!value) {
          this.setReferenceToBuyerInfoSection();
          this.openHelper('');
        }
        this.catchParticipantsSelect(value);
      }
    }
  };
</script>

<style>
  .participants .sk-input__description {
    min-height: 22px;
  }

  @media (min-width: 768px) {
    .participants .sk-input__description {
      min-height: 52px;
    }
  }
</style>

<style scoped>
  .participants {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .participants__section {
    margin-top: 20px;
  }

  .participants-section__wrapper {
    width: 100%;
  }

  .participants-section__description {
    margin-top: 15px;
  }
</style>
