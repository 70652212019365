import BookingHelper from '@/store/booking_stores/BookingHelper';

export default ({$moment: moment} = {}) => {
  return {
    namespaced: true,
    state: {
      actualCreatedDate: '',
      actualCreatedTime: '',
      bookingMechanism: 'platform',
      // errors
      errors: {actualCreatedAt: {name: 'actualCreatedAtError', errors: []}}
    },
    getters: {
      actualCreatedAt(state) {
        if (state.actualCreatedDate && state.actualCreatedTime) {
          const actualCreatedAt = `${state.actualCreatedDate} ${state.actualCreatedTime}`;
          return moment(actualCreatedAt, 'YYYY-MM-DD HH:mm');
        }
      }
    },
    mutations: {
      ...BookingHelper().mutations,
      setActualCreatedDate(state, value) {
        state.actualCreatedDate = value;
      },
      setActualCreatedTime(state, value) {
        state.actualCreatedTime = value;
      },
      setBookingMechanism(state, value) {
        state.bookingMechanism = value;
      },
      clearStore(state) {
        state.actualCreatedDate = '';
        state.actualCreatedTime = '';
        state.bookingMechanism = 'platform';
      }
    }
  };
};
