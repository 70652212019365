<template>
  <div class="tickets-list">
    <li v-for="ticketId in data.ticketsList"
        :key="ticketId"
        class="sk-link ticket"
        @click="openTicket(ticketId)">
      <span>{{ $gettext('Ticket') }} - {{ ticketId }}</span>
    </li>
  </div>
</template>

<script>
  export default {
    name: 'tickets-list',
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    methods: {
      openTicket(ticketId) {
        this.$emit('closemodal');
        return this.$router.push(
          this.$makeRoute({name: 'BuyerOneTicket', params: {id: ticketId}})
        );
      }
    }
  };
</script>

<style scoped>
.tickets-list {
  padding: 20px 30px;
  list-style-type: none;
}

.ticket {
  width: 100%;
  margin: 5px 0;
  padding: 10px;
  border-radius: 4px;
  background-color: #c9c9cc;
}

.ticket:nth-child(even) {
  background-color: #f4f5f7;
}
</style>
