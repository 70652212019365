(<template>
  <section class="job-preview">
    <div class="job-preview__btns-wrapper">
      <button v-for="obj in buttons"
              :key="obj.type"
              :class="`job-preview__btn--${obj.type}`"
              type="button"
              class="job-preview__btn"
              @click="obj.clickHandler"></button>
    </div>
    <div class="job-preview__sections-wrapper">
      <component :is="obj.componentName"
                 v-for="obj in sections"
                 :key="obj.key"
                 :data="obj"
                 class="job-preview__section" />
    </div>
    <div :class="jobStatusClass"
         class="job-preview__status">{{ jobStatusText }}</div>
    <inter-info v-if="jobData.supplierInfo"
                :supplier-info="jobData.supplierInfo"
                class="job-preview__inter" />
    <div v-if="showCancellationInfo"
         class="job-preview__sections-wrapper">
      <component :is="obj.componentName"
                 v-for="obj in cancellationSections"
                 :key="obj.key"
                 :data="obj"
                 class="job-preview__section" />
    </div>
  </section>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import DataSection from '@/components/assignment_components/one_assignment/assignment_info/DataSection';
  import AssignmentTypeSection from '@/components/assignment_components/one_assignment/assignment_info/AssignmentTypeSection';
  import InterpreterInfo from '@/components/shared_components/InterpreterInfo';
  import JobInfoPrototype from '@/prototypes/JobInfoPrototype';

  export default {
    components: {
      'data-section': DataSection,
      'assignment-type': AssignmentTypeSection,
      'inter-info': InterpreterInfo
    },
    extends: JobInfoPrototype,
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter',
        'userFullPhone',
        'userEmail'
      ]),
      jobData() { return this.data.data || {}; },
      jobId() { return this.jobData.id; },
      jobStatus() { return this.jobData.status; },
      jobStatusObj() { return this.getJobStatus(this.jobStatus); },
      jobStatusClass() { return this.jobStatusObj.class || ''; },
      jobStatusText() { return this.jobStatusObj.text || ''; },
      showCancellationInfo() { return this.jobStatus === 'cancelled'; },
      publishedAtObj() { return this.getJobPublishedAtObj(this.jobData); },
      jobStartTimeObj() { return this.getJobStartTimeObj(this.jobData.startTime); },
      jobFinishTimeObj() { return this.getJobFinishTimeObj(this.jobData.finishTime); },
      jobLangsObj() { return this.getLangObj(this.jobData); },
      jobAlternativeLanguage() {
        const langId = this.jobData.alternativeLanguageToId;

        return langId
          ? this.$getLangName(langId)
          : '';
      },
      buttonsList() {
        return [{
          condition: !this.userIsInterpreter,
          type: 'copy',
          clickHandler: this.copyAssignment
        }, {
          condition: this.isAwardedJob,
          type: 'sms',
          clickHandler: this.openSendSms
        }, {
          condition: this.isAwardedJob,
          type: 'email',
          clickHandler: this.openSendDetails
        }, {
          condition: true,
          type: 'view',
          clickHandler: this.goToAssignment
        }];
      },
      buttons() { return this.buttonsList.filter((btn) => btn.condition); },
      sectionsList() {
        return [{
          condition: true,
          componentName: 'data-section',
          key: Math.random(),
          data: [this.publishedAtObj]
        }, {
          condition: true,
          componentName: 'data-section',
          key: Math.random(),
          data: [{title: this.$gettext('Customer'), data: this.jobData.customer}]
        }, {
          condition: this.jobData.department,
          componentName: 'data-section',
          key: Math.random(),
          data: [{
            title: this.$gettext('Department'),
            data: this.jobData.department ? this.jobData.department.name : ''
          }]
        }, {
          condition: true,
          componentName: 'data-section',
          key: Math.random(),
          data: [this.jobStartTimeObj, this.jobFinishTimeObj]
        }, {
          condition: true,
          componentName: 'data-section',
          key: Math.random(),
          data: [this.jobLangsObj]
        }, {
          condition: this.jobAlternativeLanguage,
          componentName: 'data-section',
          key: Math.random(),
          data: [{
            title: this.$gettext('Alternative language'),
            data: this.jobAlternativeLanguage
          }]
        }, {
          condition: true,
          componentName: 'assignment-type',
          key: Math.random(),
          type: this.jobData.sessionType,
          typeText: this.getJobType(this.jobData.sessionType)
        }];
      },
      sections() { return this.sectionsList.filter((section) => section.condition); },
      cancellationSectionsList() {
        return [{
          condition: true,
          componentName: 'data-section',
          key: Math.random(),
          data: [{
            title: this.$gettext('Cancelled at'),
            data: this.$moment(this.jobData.cancelledAt).format('DD.MM.YYYY HH:mm')
          }]
        }, {
          condition: !this.userIsInterpreter || this.$isGodmode(),
          componentName: 'data-section',
          key: Math.random(),
          data: [{
            title: this.$gettext('Cancelled by'),
            data: this.$isGodmode() ? this.jobData.cancelledBy : this.$getString('domainData', 'platformName')
          }]
        }];
      },
      cancellationSections() { return this.cancellationSectionsList.filter((section) => section.condition); }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      goToAssignment() {
        this.closeModal();
        const routeObj = {
          name: this.userIsInterpreter ? 'ServerOneAssignment' : 'BuyerOneAssignment',
          params: {
            id: this.jobData.id
          }
        };
        window.open(this.$router.resolve(this.$makeRoute(routeObj)).href, '_blank');
      },
      copyAssignment() {
        this.closeModal();
        this.$store.commit('GlobalStore/startProgress');
        this.$store.dispatch('OneAssignmentStore/getJobById', this.jobData.id).then((assignmentObj) => {
          this.$store.commit('GlobalStore/stopProgress');
          const jobObj = assignmentObj ? assignmentObj.job || {} : {};
          const routeObj = jobObj.direct && jobObj.directInterUid
            ? {name: 'BuyerPostInterpretation', query: {interpreterid: jobObj.directInterUid, copy: jobObj.id}}
            : {name: 'BuyerPostInterpretation', query: {copy: jobObj.id}};
          window.open(this.$router.resolve(this.$makeRoute(routeObj)).href, '_blank');
        });
      },
      openSendSms() {
        this.$store.commit('ModalStore/setModal', {
          component: 'send-sms',
          classes: ['send-sms-modal'],
          width: 400,
          data: {
            jobId: this.jobId,
            phone: this.userIsInterpreter ? this.userFullPhone : this.jobData.customerPhone
          }
        });
      },
      openSendDetails() {
        this.$store.commit('ModalStore/setModal', {
          component: 'send-details',
          width: 400,
          data: {
            jobId: this.jobId,
            email: this.userIsInterpreter ? this.userEmail : this.jobData.customerEmail
          }
        });
      }
    }
  };
</script>

<style>
  .job-preview__inter .inter-info__avatar-cont {
    margin-left: 15px;
  }

  .job-preview__inter .inter-info__info-cont {
    margin-top: 10px;
  }

  .job-preview__inter .inter-info__data-cont {
    flex-direction: column;
  }

  .job-preview-modal .sk-modal__close-btn {
    top: 10px;
    right: 10px;
  }
</style>

<style scoped>
  .job-preview {
    padding: 0 15px 25px;
  }

  .job-preview__sections-wrapper {
    margin-top: 20px;
  }

  .job-preview__btn {
    width: 40px;
    height: 40px;
    margin: 0 5px;
    border-radius: 50%;
    background-position: center center;
    background-size: initial;
    background-repeat: no-repeat;
  }

  .job-preview__btn:hover {
    background-color: rgba(0, 56, 85, 0.1);
  }

  .job-preview__btn--copy {
    background-image: url(~@assets/imgs/preview_job_icons/copy.svg);
  }

  .job-preview__btn--sms {
    background-image: url(~@assets/imgs/preview_job_icons/sms.svg);
  }

  .job-preview__btn--email {
    background-image: url(~@assets/imgs/preview_job_icons/email.svg);
  }

  .job-preview__btn--view {
    background-image: url(~@assets/imgs/preview_job_icons/view.svg);
  }

  .job-preview__btns-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: -5px 40px 0 -5px;
  }

  .job-preview__section {
    margin-bottom: 20px;
  }

  .job-preview__section:last-child {
    margin-bottom: 0;
  }

  .job-preview__status {
    width: calc(100% + 30px);
    margin: 20px -15px 0;
    padding: 7px 15px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
  }

  .job-preview__status.assignment__yellow-status {
    background-color: #ffe1c4;
    color: #f93;
  }

  .job-preview__status.assignment__green-status {
    background-color: #b2dadc;
    color: #008489;
  }

  .job-preview__status.assignment__dark-blue-status {
    background-color: #99afbb;
    color: #003855;
  }

  .job-preview__status.assignment__purple-status {
    background-color: #bfbece;
    color: #2b255c;
  }

  .job-preview__status.assignment__red-status {
    background-color: #ffccda;
    color: #f04;
  }

  .job-preview__inter {
    margin-top: 20px;
  }
</style>
