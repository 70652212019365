<template>
  <div class="sk-select__wrapper">
    <p v-if="description"
       class="sk-select__description">{{ description }}</p>
    <div :class="{'is-opened': isSelectOpened}"
         class="sk-select"
         @click="toggleSelectState"
         @touchstart="touchStartHandler"
         @touchend="touchHandler">
      <input ref="typingInput"
             v-model="typingInputValue"
             class="sk-select__typing-input"
             type="text"
             @keyup.40="downArrowHandler"
             @keyup.38="upArrowHandler"
             @keyup.13="pressEnterLogic"
             @keydown.9="pressTabLogic"
             @focus="focusLogic">
      <span v-if="!isSelectOpened || headerLangSelect"
            :title="currentValue"
            class="sk-select__value">{{ currentValue }}</span>
      <span class="sk-select__arrow"></span>
      <ul ref="csList"
          :data-description="description"
          class="sk-select__list">
        <li v-for="item in renderedList"
            :key="item.id"
            :class="{'is-highlighted': item.id == currentHighlightedItemObj.id}"
            :data-id="item.id"
            class="sk-select__list-item js-list-item"
            @click="itemClickLogic(item.id, $event)">{{ item.name }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
  import {mapMutations} from 'vuex';
  import {skSelect} from '@skiwo/sk-basic-components';
  import constants from '@/modules/constants';

  export default {
    extends: skSelect,
    props: {
      headerLangSelect: Boolean,
      defaultValue: {
        type: String,
        default: '',
        required: false
      },
      preselectedValue: {
        type: [String, Array, Number],
        default: '',
        required: false
      }
    },
    computed: {
      constList() {
        return [
          {id: 'en', name: 'English'},
          {id: 'nb', name: 'Norsk'}
        ];
      },
      currentValue() {
        for (const lang of this.constList) {
          if (lang.id == this.$language.current) {
            return lang.name;
          }
        }
      }
    },
    methods: {
      ...mapMutations('UserInfoStore', ['setLocale']),
      setDomain() {
        if (window.location.hostname.includes('herokuapp.com')) return window.location.hostname;
        if (window.location.hostname.includes('salita.no')) return '.salita.no';
        return constants.COOKIE_PATH || '.tikktalk.com';
      },
      setValue(id) {
        if (id != this.$language.current) {
          const cookieParams = {path: '/', expires: constants.COOKIE_EXPIRATION_TIME};
          this.$language.current = id;
          if (window.location.hostname !== 'localhost') {
            cookieParams.domain = this.setDomain(); // ie reject cookies with domain: 'localhost'
          }
          this.$cookieManager.setCookie('locale', this.$language.current, cookieParams);
          this.$cookieManager.setCookie('serverLocale', this.$language.current, cookieParams);
          this.$fetcher.setLocale(id, this.$store.state.TTAuthStore.loggedIn).then(() => {
            this.setLocale(this.$language.current);
          });
        }
      }
    },
    beforeMount() {
      for (const lang of this.constList) {
        if (lang.id == this.$route.params.lang) {
          this.setValue(lang.id, lang.name);
        }
      }
    }
  };
</script>
