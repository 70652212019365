import BookingHelper from '@/store/booking_stores/BookingHelper';

export default ({$moment: moment} = {}) => {
  return {
    namespaced: true,
    state: {
      deadline: '',
      shortNoticedOrder: false,
      errors: {deadlineTime: {name: 'deadlineTimeError', errors: []}}
    },
    mutations: {
      ...BookingHelper().mutations,
      setDeadline(state, value) {
        state.deadline = value;
      },
      setDeadlineDate(state, value) {
        const valueArr = value.split('-');
        valueArr[1] -= 1;
        const timeHours = state.deadline ? moment(state.deadline).hours() : 0;
        const timeMinutes = state.deadline ? moment(state.deadline).minutes() : 0;
        const momentInst = moment(valueArr);
        state.deadline = moment(momentInst).hours(timeHours).minutes(timeMinutes);
      },
      setDeadlineTime(state, value) {
        const valueArr = value.split(':');
        const hours = valueArr[0];
        const minutes = valueArr[1];
        const momentInst = state.deadline ? moment(state.deadline) : moment();
        state.deadline = moment(momentInst).hours(+hours).minutes(+minutes);
      },
      setInitialDeadlineTime(state, {duration = 0}) {
        const deadlineMoment = moment().add(5, 'minutes').add(duration, 'minutes');
        const periodArray = deadlineMoment.format('HH:mm').split(':');

        if (Number(periodArray[1]) >= 0 && Number(periodArray[1]) <= 30) {
          periodArray[1] = 30;
        } else if (Number(periodArray[1]) > 30) {
          periodArray[0] = Number(periodArray[0]) + 1;
          periodArray[1] = 0;
        }
        state.deadline = moment().hours(periodArray[0]).minutes(periodArray[1]);
      },
      setShortNoticed(state, value) {
        state.shortNoticedOrder = value;
      },
      clearStore(state) {
        state.shortNoticedOrder = false;
        state.deadline = '';
      },
      emptyDeadline(state) {
        state.deadline = '';
      }
    }
  };
};
