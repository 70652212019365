(<template>
  <div class="owner is-progress-bar"
       :class="{'in-progress': progressActive, 'pointer': !noActionOnClick}"
       @click="openEditModal">
    <div class="owner-info">
      <div class="owner__wrapper">
        <div class="email-phone">
          <span v-if="ownerPhone"
                class="phone-icon"
                @mouseleave="closeTooltip"
                @mouseover="openTooltip(ownerPhone, $event)"
                @click="copyToClipboard(ownerPhone)"></span>
          <span v-else
                class="phone-icon--grey"></span>
          <span v-if="ownerEmail"
                class="email-icon"
                @mouseleave="closeTooltip"
                @mouseover="openTooltip(ownerEmail, $event)"
                @click="copyToClipboard(ownerEmail)"></span>
          <span v-else
                class="email-icon---grey"></span>
        </div>
        <p class="owner-name"
           @mouseleave="closeTooltip"
           @mouseover="openTooltip(firstRow, $event)">{{ firstRow }}</p>
      </div>
      <p v-if="secondRow && secondRow !== '-'"
         class="second-row__text"
         @mouseleave="closeTooltip"
         @mouseover="openTooltip(secondRow, $event)">{{ secondRow }}</p>
      <p v-else
         class="second-row__text">{{ secondRow }}</p>
    </div>
    <div v-if="extraParticipantsCount"
         class="participants_wrapper">
      <div class="participants">{{ '+' + extraParticipantsCount }}</div>
    </div>
  </div>
</template>)

<script>
  import helpers from '@/helpers';
  import ManagerJobEditInfoMixin from '@/mixins/ManagerJobEditInfoMixin';

  export default {
    mixins: [ManagerJobEditInfoMixin],
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      classes() { return this.propData?.classes; },
      owner() { return this.propData?.owner; },
      noActionOnClick() { return !!this.propData?.noActionOnClick; },
      ownerEmail() { return this.propData?.ownerEmail; },
      ownerPhone() { return this.propData?.ownerPhone; },
      extraParticipantsCount() { return this.propData?.extraParticipantsCount; },
      enterprise() { return this.propData?.enterprise; },
      department() { return this.propData?.department; },
      departmentId() { return this.propData?.departmentId; },
      secondRow() { return this.enterprise + ', ' + this.department; },
      firstRow() { return this.owner; }
    },
    methods: {
      openEditModal() {
        if (!this.noActionOnClick) this.openEditCustomerInformation();
      },
      itemPosition(event) {
        const {top, left} = helpers.getElementPosition(event.target);
        return {top: (top) + 'px', left: (left + 20) + 'px'};
      },
      openTooltip(text, event) {
        this.$store.commit('TooltipRootStore/setTooltip', {
          data: {
            itemText: text,
            relativePosition: 'top-center'
          },
          absolutePosition: this.itemPosition(event)
        });
      },
      closeTooltip() { this.$store.dispatch('TooltipRootStore/closeTooltip'); },
      copyToClipboard(value) { navigator.clipboard.writeText(value); }
    }
  };
</script>
<style scoped>
.owner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.owner-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}

.owner__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.owner-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.second-row__text {
  overflow: hidden;
  width: 100%;
  color: #555;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.participants_wrapper {
  width: 20%;
  margin-left: 10px;
}

.participants {
  width: 28px;
  height: 28px;
  padding-top: 4px;
  border-radius: 50%;
  background-color: #fff4f0;
  color: #ff5b24;
  font-weight: 600;
  text-align: center;
}
</style>
<style scoped src="@assets/css/phone_email_icon.css" />
