(<template>
  <article class="system-log-modal">
    <vue-friendly-iframe :src="iframeSrc"
                         class="system-log-modal__iframe" />
  </article>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    computed: {
      iframeSrc() { return this.data.data?.iframeSrc; }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
    }
  };
</script>

<style>
  .system-log-modal .sk-modal__wrapper {
    display: flex;
    flex-direction: column;
    min-width: 80%;
    height: 100%;
    background-color: #e9ebef;
  }

  .system-log-modal iframe {
    width: 100%;
    height: 100%;
  }
</style>

<style scoped>
  .system-log-modal {
    height: 100%;
  }

  .system-log-modal__iframe {
    height: 100%;
  }
</style>
