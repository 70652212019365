export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      travelExpenses: [],
      expense: {
        expenseType: '',
        expenseAmount: '',
        receiptFile: '',
        demanderApprovedAmount: '',
        supplierApprovedAmount: ''
      },
      errors: {
        expenseType: {name: 'expenseTypeValidationName', errors: []},
        expenseAmount: {name: 'expenseAmountValidationName', errors: []},
      }
    },
    actions: {
      fetchTravelExpensesList({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.fetchTravelExpensesList(params).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setTravelExpenses', response.expenses);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendTravelExpense({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.addTravelExpense(params).then((data) => {
            commit('setTravelExpenses', data.expenses);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      updateTravelExpense({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.updateTravelExpense(params).then((data) => {
            commit('setTravelExpenses', data.expenses);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      createForm({state}, isAdminMode) {
        const form = new FormData();

        form.append('expense[expense_type]', state.expense.expenseType);
        form.append('expense[amount]', state.expense.expenseAmount);
        if (isAdminMode) form.append('expense[supplier_approved_amount]', state.expense.supplierApprovedAmount || 0);
        if (isAdminMode) form.append('expense[demander_approved_amount]', state.expense.demanderApprovedAmount || 0);
        if (state.expense.receiptFile?.size) {
          form.append('expense[receipt]', state.expense.receiptFile);
        }

        return Promise.resolve(form);
      },
      removeExpense({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.removeTravelExpense(params).then((data) => {
            commit('setTravelExpenses', data.expenses);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
    },
    mutations: {
      setError(state, {fieldName, errorText}) {
        state.errors[fieldName].errors = [...state.errors[fieldName].errors, errorText];
      },
      removeOneError(state, fieldName) {
        state.errors[fieldName].errors = [];
      },
      removeErrors(state) {
        for (const key of Object.keys(state.errors)) {
          state.errors[key].errors = [];
        }
      },
      setTravelExpenses(state, data) {
        state.travelExpenses = data;
      },
      setExpenseType(state, value) {
        state.expense.expenseType = value;
      },
      setExpenseAmount(state, value) {
        state.expense.expenseAmount = value;
      },
      setExpenseSupplierApprovedAmount(state, data) {
        state.expense.supplierApprovedAmount = data;
      },
      setExpenseDemanderApprovedAmount(state, data) {
        state.expense.demanderApprovedAmount = data;
      },
      setReceiptFile(state, value) {
        state.expense.receiptFile = value;
      },
      removeReceiptFile(state) {
        state.expense.receiptFile = '';
      },
      clearModalData(state) {
        state.expense = {
          expenseType: '',
          expenseAmount: '',
          receiptFile: '',
          demanderApprovedAmount: '',
          supplierApprovedAmount: '',
        };
      },
      clearStore(state) {
        state.travelExpenses = '';
        state.expense = {
          expenseType: '',
          expenseAmount: '',
          receiptFile: '',
          demanderApprovedAmount: '',
          supplierApprovedAmount: '',
        };
      }
    }
  };
};
