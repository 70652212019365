(<template>
  <section class="accessibility">
    <h2 class="accessibility__title">{{ $gettext('Who should see your assignment?') }}</h2>
    <div class="accessibility__radios-cont">
      <label class="accessibility__radio-label">
        <input :checked="assignmentAccessibility === 'private'"
               class="accessibility-btn__input"
               type="radio"
               name="accessibility"
               value="private"
               @change="setAccessibility">
        <p class="accessibility__icon accessibility-btn accessibility-btn--private"></p>
        <p class="accessibility__radio-name">{{ $gettext('Private') }}</p>
      </label>
      <label class="accessibility__radio-label">
        <input :checked="assignmentAccessibility === 'public'"
               class="accessibility-btn__input"
               type="radio"
               name="accessibility"
               value="public"
               @change="setAccessibility">
        <p class="accessibility__icon accessibility-btn accessibility-btn--public"></p>
        <p class="accessibility__radio-name">{{ $gettext('Public') }}</p>
      </label>
    </div>
    <p class="accessibility__description">{{ optionDescription }}</p>
  </section>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';

  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('PostingStore/AccessibilityStore', {
        assignmentAccessibility: (state) => state.accessibility
      }),
      optionDescription() {
        return this.params && this.params.optionDescription ? this.params.optionDescription : this.accessibilityOptionText;
      },
      accessibilityOptionText() {
        if (this.assignmentAccessibility == 'public') {
          return this.$gettext('Public assignments are available to all interpreters. We individually contact the interpreters with matching qualifications and availability, and encourage them to apply for your assignment.');
        } else if (this.assignmentAccessibility == 'private') {
          return this.$gettext('Private assignments are hidden from the interpreters. On the assignment page, you can invite interpreters that match your requirements one by one. This gives you greater control over who you book, but may take longer to confirm.');
        }
      }
    },
    methods: {
      ...mapMutations('PostingStore/AccessibilityStore', ['setAccessibility'])
    }
  };
</script>

<style>
  .accessibility__radio-label input {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 1px;
    height: 1px;
    opacity: 0.00000001;
  }
</style>

<style scoped>
  .accessibility {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 200px;
    margin-bottom: 20px;
    padding: 20px 15px;
    background-color: #e9eaef;
    font-size: 12px;
    text-align: center;
  }

  .accessibility__title {
    margin: 0;
    font-weight: bold;
    font-size: 12px;
  }

  .accessibility__radios-cont {
    display: flex;
    justify-content: space-between;
    width: 190px;
    margin: 15px 0 auto;
  }

  .accessibility__radio-label {
    position: relative;
    display: inline-block;
    width: initial;
    cursor: pointer;
  }

  .accessibility__radio-name {
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .accessibility__icon {
    margin: 0 auto;
    margin-bottom: 5px;
  }

  .accessibility__description {
    width: 100%;
    font-size: 12px;
  }

  @media (max-width: 767px) {
    .accessibility {
      width: calc(100% + 30px);
      min-height: 200px;
      margin-bottom: 10px;
      margin-left: -15px;
    }
  }
</style>
