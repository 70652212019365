(<template>
  <div :class="[{'in-progress' : progress}, sidebarClasses]"
       :style="sidebarStyle"
       class="sidebar is-progress-bar">
    <section class="sidebar__wrapper">
      <h1 class="sidebar__title">
        <span>{{ sidebarData.title }}</span>
        <button class="sidebar__close-btn sk-modal__close-btn"
                type="button"
                @click.prevent="closeSidebar"></button>
      </h1>
      <slot></slot>
    </section>
  </div>
</template>)

<script>
  export default {
    computed: {
      sidebarData() {
        return this.$store.getters['SidebarStore/sidebarData'] || {};
      },
      progress() {
        return this.$store.getters['SidebarStore/sidebarProgress'];
      },
      sidebarClasses() {
        return this.$store.getters['SidebarStore/sidebarClasses'];
      },
      sidebarStyle() {
        const headerElement = document.querySelector('.logged-in-root');
        const headerStyle = headerElement ? getComputedStyle(headerElement) : {};
        const top = headerStyle?.paddingTop || 0;
        const footerElement = document.querySelector('.logged-in-root__footer') || {};
        const viewHeight = document.getElementsByTagName('body')[0]?.clientHeight || 0;
        const headerHeight = parseInt(top) || 0;
        const footerHeight = footerElement.clientHeight || 0;
        const height = viewHeight - headerHeight - footerHeight;

        return {top, height: `${height}px`};
      }
    },
    methods: {
      closeSidebar() {
        this.$store.dispatch('SidebarStore/closeSidebar');
      }
    }
  };
</script>

<style scoped>
  .sidebar {
    position: fixed;
    right: 0;
    z-index: 10;
    width: 420px;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 0 9px 0 rgba(0, 34, 102, 0.3);
  }

  .sidebar__wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    padding: 20px;
  }

  .sidebar__title {
    margin-bottom: 10px;
  }
</style>
