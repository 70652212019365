const invoicing = {
  methods: {
    // ==================================================
    // ==================================================
    // INVOICE SETTINGS
    // ==================================================
    // ==================================================
    getInvoicingDepartmentsList(enterpriseId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/enterprises/${enterpriseId}/invoicing/departments`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getFilteredInvoicingDepartmentsList(enterpriseId, departmentId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/enterprises/${enterpriseId}/invoicing/departments/${departmentId}`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getInvoicingEmployeesList(enterpriseId) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/enterprises/${enterpriseId}/invoicing/members`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getFilteredInvoicingEmployeesList(enterpriseId, employeeUid) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/enterprises/${enterpriseId}/invoicing/members/${employeeUid}`).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    saveDepartmentInvoiceSettings(enterpriseId, departmentId, params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/enterprises/${enterpriseId}/invoicing/departments/${departmentId}`, params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    saveEmployeeInvoiceSettings(enterpriseId, employeeUid, params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/enterprises/${enterpriseId}/invoicing/members/${employeeUid}`, params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
  }
};

export default invoicing;
