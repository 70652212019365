(<template>
  <post-accordion-section ref="blockedCitiesSection"
                          :state="blockedCitiesSelect"
                          :title="title"
                          :description="description"
                          class="blocked-cities"
                          @focusin="openBlockedCitiesHelper"
                          @statechanged="catchBlockedCitiesSelect">
    <template v-if="blockedCitiesSelect">
      <p class="blocked-cities__text">{{ blockedText }}</p>
      <sk-multiselect :value-class="valueClass"
                      :items-list="[]"
                      :preselected-value="blockedCities"
                      :description="blockedCitiesText"
                      :dynamic-search="true"
                      :default-value="blockedCitiesDefaultText"
                      :tags="blockedCitiesFullList"
                      class="blocked-cities__select"
                      @checkboxeschanged="setBlockedCities" />
    </template>
  </post-accordion-section>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';
  import PostAccordionSection from '@/components/booking_components/PostAccordionSection';

  export default {
    components: {
      'post-accordion-section': PostAccordionSection
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() { return {valueClass: 'blocked-cities'}; },
    computed: {
      ...mapState('PostingStore/BlockedCitiesStore', {
        blockedCitiesSelect: ({blockedCitiesSelect}) => blockedCitiesSelect,
        blockedCities: ({blockedCities}) => blockedCities,
        blockedCitiesFullList: ({blockedCitiesFullList}) => blockedCitiesFullList
      }),
      title() {
        return this.$gettext('Block cities');
      },
      description() {
        return this.$gettext('I need to avoid interpreters from one or more cities');
      },
      blockedText() {
        return this.$gettext('Search and tick cities to avoid interpreters from them. Interpreters from these cities will not be invited and thus will be unable to apply.');
      },
      blockedCitiesText() { return this.$gettext('Select norwegian city(es)'); },
      blockedCitiesDefaultText() { return this.$gettext('City(es)'); },
      isMobileView() { return this.$store.state.GlobalStore.clientWidth < 768; },
      searchInputClass() {
        return this.isMobileView ? '.sk-mobile-select .sk-input__input' : '.blocked-cities .sk-select__typing-input';
      },
      mobileMultiSelectData() { return this.$store.state.MobileComponentStore.mobileFullHeight.data; },
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    watch: {
      mobileMultiSelectData(newVal) {
        if (newVal && newVal.context && newVal.context.valueClass === this.valueClass) {
          this.addAutocomplete();
        }
      }
    },
    methods: {
      ...mapMutations('PostingStore/BlockedCitiesStore', [
        'setBlockedCities',
        'addBlockedCity',
        'setBlockedCitiesSelect'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
        'setReferenceToBuyerInfoSection'
      ]),
      openBlockedCitiesHelper() {
        if (this.showAssignmentHelper) {
          this.setPopoverReference(this.$refs.blockedCitiesSection);
          this.openHelper('blockedCities');
        }
      },
      placeChangedHandler(place = {}) {
        if (place.address_components?.length) {
          const {
            city,
            county,
            country
          } = place.address_components.reduce((acc, {types, long_name: longName}) => {
            if (types.includes('locality') || types.includes('postal_town')) {
              return {...acc, city: longName};
            }
            if (types.includes('administrative_area_level_1')) {
              return {...acc, county: longName};
            }
            if (types.includes('country')) {
              return {...acc, country: longName};
            }
            return acc;
          }, {});
          const cityObj = {
            id: place.place_id,
            name: place.formatted_address,
            city,
            county,
            country,
            latitude: place.geometry?.location?.lat(),
            longitude: place.geometry?.location?.lng()
          };
          this.addBlockedCity(cityObj);
          // close multiselect
          this.$store.commit('SelectsStore/closeOpenedSelect');
          this.$store.commit('MobileComponentStore/closeMobileComponent');
        }
      },
      addAutocomplete() {
        setTimeout(() => {
          if (window && window.google && window.google.maps) {
            const autocompleteInput = document.querySelector(this.searchInputClass);
            if (autocompleteInput) {
              this.autocomplete = new window.google.maps.places.Autocomplete(
                (autocompleteInput),
                {types: ['(cities)'],
                 componentRestrictions: {country: ['no']}}
              );
              this.autocomplete.addListener('place_changed', () => {
                const place = this.autocomplete.getPlace();
                this.placeChangedHandler(place);
              });
            }
          }
        });
      },
      catchBlockedCitiesSelect(value) {
        if (!value) {
          this.setReferenceToBuyerInfoSection();
          this.openHelper('');
        }
        this.setBlockedCitiesSelect(value);
        this.addAutocomplete();
      }
    },
    mounted() {
      if (this.blockedCitiesSelect) this.addAutocomplete();
    }
  };
</script>
<style>
  .blocked-cities .sk-select__typing-input,
  .blocked-cities .sk-select__typing-input:focus {
    top: auto;
    bottom: 0;
    width: 100%;
    pointer-events: none;
  }

  .blocked-cities .is-opened .sk-select__typing-input {
    pointer-events: initial;
  }
</style>

<style scoped>
  .blocked-cities__text {
    margin-top: 15px;
  }

  .blocked-cities__select {
    margin-top: 20px;
  }
</style>
