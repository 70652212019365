import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      progressActive: false
    };
  },
  computed: {
    ...mapGetters('EnterpriseStore', ['getOwnerUid']),
    singleManagerJobObj() { return this.propData; },
    jobId() { return this.propData.id; },
  },
  methods: {
    openEditProcessRequirement() {
      this.progressActive = true;
      this.$store.commit('OneAssignmentStore/setManagerJob', this.singleManagerJobObj);
      this.$store.dispatch('OneAssignmentStore/getJobById', this.jobId).then(() => {
        this.progressActive = false;
        this.$store.commit('ModalStore/setModal', {
          component: 'assignment-info-edit-modal',
          classes: ['sk-modal--new', 'sk-modal--manager-edit-job'],
          data: {
            sectionName: 'managerProcessRequirementEditSection',
            showActionButtons: false,
            context: this,
            closeHandlingCallbackName: 'refreshManagerJobs'
          }
        });
      }).catch(() => {
        this.progressActive = false;
      });
    },
    openEditAdminTools() {
      this.progressActive = true;
      this.$store.commit('OneAssignmentStore/setManagerJob', this.singleManagerJobObj);
      this.$store.dispatch('PostInterpretationStore/getJob', this.jobId).then(() => {
        this.progressActive = false;
        this.$store.commit('ModalStore/setModal', {
          component: 'assignment-info-edit-modal',
          classes: ['sk-modal--new', 'sk-modal--manager-edit-job'],
          data: {
            sectionName: 'managerAdminInformationSection',
            validateFuncName: 'validateAdminSection',
            successHandlingFunc: this.successHandlingFunc,
            sendInfoFunc: this.editJob,
            submitButtonText: this.$gettext('Confirm changes'),
            context: this,
            closeHandlingCallbackName: 'clearStores'
          }
        });
      }).catch(() => {
        this.progressActive = false;
      });
    },
    openEditCustomerInformation() {
      this.progressActive = true;
      this.$store.commit('OneAssignmentStore/setManagerJob', this.singleManagerJobObj);
      this.$store.commit('EnterpriseStore/setManagerJobEnterpriseId', this.singleManagerJobObj?.enterpriseId);
      this.$store.commit('EnterpriseStore/setOwnerUid', this.singleManagerJobObj?.ownerUID);
      const headers = {AuthImpersonationUID: this.getOwnerUid};
      const promiseArr = [
        this.$store.dispatch('EnterpriseStore/getEmployees'),
        this.$store.dispatch('PostingStore/DepartmentAttendersStore/fetchDepartments', headers),
        this.$store.dispatch('PostingStore/DepartmentAttendersStore/fetchAttenders', {department: this.departmentId, headers})
      ];

      Promise.all(promiseArr).then(() => {
        this.$store.dispatch('PostInterpretationStore/getJob', this.jobId).then(() => {
          this.progressActive = false;
          this.$store.commit('ModalStore/setModal', {
            component: 'assignment-info-edit-modal',
            classes: ['sk-modal--new'],
            width: 750,
            data: {
              sectionName: 'managerCustomerInformationSection',
              validateFuncName: 'validateAdminSection',
              successHandlingFunc: this.successHandlingFunc,
              sendInfoFunc: this.editJob,
              submitButtonText: this.$gettext('Confirm changes'),
              context: this,
              closeHandlingCallbackName: 'clearStores'
            }
          });
        }).catch(() => {
          this.progressActive = false;
        });
      });
    },
    openEditAssignmentInformation() {
      const statuses = ['cancelled', 'finished', 'closed'];
      if (!statuses.includes(this.singleManagerJobObj.status)) {
        this.$store.commit('EnterpriseStore/setOwnerUid', this.singleManagerJobObj?.ownerUID);
        const headers = {AuthImpersonationUID: this.getOwnerUid};
        this.progressActive = true;
        const promiseArr = [
          this.$store.commit('OneAssignmentStore/setManagerJob', this.singleManagerJobObj),
          this.$store.dispatch('PostingStore/AssignmentTypeStore/setAllowedAssignmentTypes', null, {root: true}),
          this.$store.dispatch('PostingStore/SubjectStore/getCustomerCategories', {
            enterpriseId: this.singleManagerJobObj?.enterpriseId,
            departmentId: this.singleManagerJobObj?.departmentId,
            headers,
          }, {root: true}),
          this.$store.dispatch('PostInterpretationStore/getJob', this.singleManagerJobObj.id)
        ];
        Promise.all(promiseArr).then(() => {
          this.$store.dispatch('PostingNotifierStore/fetchDataForMainAssignmentInformationSectionEdit', headers);
          this.$store.commit('ModalStore/setModal', {
            component: 'assignment-info-edit-modal',
            classes: ['sk-modal--new'],
            width: 750,
            data: {
              sectionName: 'managerAssignmentsInformationSection',
              validateFuncName: 'validateMainAssignmentInfoSection',
              successHandlingFunc: this.successHandlingFunc,
              sendInfoFunc: this.editJob,
              submitButtonText: this.$gettext('Confirm changes'),
              context: this,
              closeHandlingCallbackName: 'clearStores'
            }
          });
          this.progressActive = false;
        }).catch(() => { this.progressActive = false; });
      }
    },
    openInvitationsAndApplications() {
      this.progressActive = true;
      this.$store.commit('OneAssignmentStore/setManagerJob', this.singleManagerJobObj);
      this.$store.dispatch('PostInterpretationStore/getJob', this.jobId).then(() => {
        this.progressActive = false;
        this.$store.commit('ModalStore/setModal', {
          component: 'assignment-info-edit-modal',
          classes: ['sk-modal--new', 'sk-modal--manager-edit-job'],
          width: 860,
          data: {
            sectionName: 'managerInvitationsAndApplicationsSection',
            sendInfoFunc: this.editJob,
            context: this,
            closeHandlingCallbackName: 'clearStores',
            showActionButtons: false
          }
        });
      }).catch(() => {
        this.progressActive = false;
      });
    },
    editJob() {
      this.$store.commit('ModalStore/startModalProgress');
      this.$store.commit('PostInterpretationStore/setTrackType', 'job_updated');
      this.$store.state.PostInterpretationStore.job.job_id = this.jobId;

      return this.$store.getters['PostSecretaryStore/isSecretaryAssignment']
        ? this.$store.dispatch('PostSecretaryStore/editJob')
        : this.$store.dispatch('PostInterpretationStore/editJob');
    },
    clearStores() {
      this.$store.commit('OneAssignmentStore/clearAssignmentStore');
      this.$store.dispatch('PostInterpretationStore/clearAssignmentInfoStores');
    },
    successHandlingFunc() {
      this.$store.dispatch('ModalStore/closeModal');
      this.$store.commit('ModalStore/stopModalProgress');
      this.clearStores();
      this.refreshManagerJobs();
    },
    refreshManagerJobs() {
      this.$store.commit('OneAssignmentStore/clearAssignmentStore');
      this.$store.state.AllAssignmentsStore.updateManagerJobGrid = Date.now();
    }
  }
};
