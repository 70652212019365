(<template>
  <article class="confirmation">
    <section class="confirmation__wrapper">
      <div v-if="modalText"
           class="confirmation__text"
           v-html="modalText"></div>
      <div class="confirmation__btns-wrapper">
        <button v-if="!hideCancelBtn"
                class="sk-btn sk-btn--white confirmation__btn"
                type="button"
                @click.prevent="closeModal">{{ modalCancelBtnText || $gettext('Cancel') }}</button>
        <button v-if="!hideActionBtn"
                class="sk-btn sk-btn--default confirmation__btn"
                type="button"
                @click.prevent="confirm">{{ modalActionBtnText || $gettext('Confirm') }}</button>
      </div>
    </section>
  </article>
</template>)

<script>

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      modalData() {
        return this.data || {};
      },
      modalText() {
        return this.modalData.text || '';
      },
      modalCallback() {
        return this.modalData.modalCallback;
      },
      modalCallbackParams() {
        return this.modalData.callbackParams;
      },
      modalBtnTexts() {
        return this.modalData.btnTexts || {};
      },
      modalBtnVisibility() {
        return this.modalData.visibility || {};
      },
      modalActionBtnText() {
        return this.modalBtnTexts.actionBtnText || '';
      },
      modalCancelBtnText() {
        return this.modalBtnTexts.cancelBtnText || '';
      },
      hideActionBtn() {
        return this.modalBtnVisibility.hideActionBtn || false;
      },
      hideCancelBtn() {
        return this.modalBtnVisibility.hideCancelBtn || false;
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$store.dispatch('ModalStore/closeModal');
      },
      confirm() {
        this.modalCallback(this.modalCallbackParams);
        this.closeModal();
      }
    }
  };
</script>

<style scoped>
  .confirmation {
    padding: 20px 30px;
  }

  .confirmation__btns-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 20px -5px 0;
  }

  .confirmation__btn {
    width: auto;
    margin: 0 5px;
    padding: 0 10px;
  }
</style>
