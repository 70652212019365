// TODO: Remove/Refactor if state required Use Documents Center Store
export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      docs: '',
      newDoc: {
        title: '',
        file: ''
      }
    },
    actions: {
      getDocs({rootGetters, commit}) {
        return new Promise((resolve, reject) => {
          const uid = rootGetters['UserInfoStore/userUid'];
          fetchData.getDocs(uid).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setDocs', data);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      deleteDoc({commit, rootGetters}, id) {
        return new Promise((resolve, reject) => {
          const uid = rootGetters['UserInfoStore/userUid'];
          fetchData.deleteDoc(uid, id).then((data) => {
            if (data && data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('deleteDoc', id);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      uploadDoc({commit, rootGetters, state}) {
        return new Promise((resolve) => {
          const uid = rootGetters['UserInfoStore/userUid'];
          fetchData.uploadDoc(uid, state.newDoc).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('pushOneDoc', data);
            resolve();
          });
        });
      },
      getDoc({commit, rootGetters}, id) {
        return new Promise((resolve, reject) => {
          const uid = rootGetters['UserInfoStore/userUid'];
          fetchData.getDoc(uid, id).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      }
    },
    mutations: {
      deleteDoc(state, id) {
        for (let i = 0, lim = state.docs.length; i < lim; i += 1) {
          if (state.docs[i].id == id) {
            state.docs.splice(i, 1);
            break;
          }
        }
      },
      setDocs(state, data) { state.docs = data.attachments || []; },
      pushOneDoc(state, doc) { state.docs.push(doc.attachment || {}); },
      setNewDocTitle(state, value) { state.newDoc.title = value; },
      setNewDocFile(state, file) { state.newDoc.file = file; },
      clearNewFile(state) {
        state.newDoc = {
          title: '',
          file: ''
        };
      },
      clearStore(state) {
        state.docs = '';
        state.newDoc = {
          title: '',
          file: ''
        };
      }
    }
  };
};
