(<template>
  <section class="skill-modal">
    <p v-if="!skillId"
       class="skill-modal__hint-text">{{ hintText }}</p>
    <div class="skill-modal__type-cont">
      <button v-if="showInterpretationBtn"
              class="sk-btn skill-type-selector"
              :class="{'sk-btn--default': isInterpretation, 'sk-btn--white': !isInterpretation,}"
              @click="changeSkillType('interpretation')">{{ $gettext('Interpreting knowledge') }}</button>
      <button v-if="showTranslationBtn"
              class="sk-btn skill-type-selector"
              :class="{'sk-btn--default': isTranslation, 'sk-btn--white': !isTranslation,}"
              @click="changeSkillType('translation')">{{ $gettext('Translating knowledge') }}</button>
    </div>
    <skill-form v-if="skillType"
                :skill-type="skillType"
                @startprogress="startModalProgress"
                @stopprogress="stopModalProgress"
                @formsubmitted="catchSubmit">
      <div class="skill-modal__btns-wrapper">
        <button v-if="!!skillId"
                class="sk-btn sk-btn--white skill-modal__btn"
                type="button"
                @click="catchRemoval">{{ $gettext('Remove skill') }}</button>
        <button v-else
                class="sk-btn sk-btn--white skill-modal__btn"
                type="button"
                @click="closeModal">{{ $gettext('Cancel') }}</button>
        <template v-if="$isGodmode() && !skillId && isInterpretation">
          <button class="sk-btn sk-btn--orange skill-modal__btn"
                  @click="setSilently(true)">{{ $gettext('Create and approve silently') }}</button>
          <button class="sk-btn sk-btn--default skill-modal__btn"
                  @click="setSilently(false)">{{ $gettext('Create and approve') }}</button>
        </template>
        <template v-else>
          <button v-if="showSubmitBtn"
                  class="sk-btn sk-btn--default skill-modal__btn">{{ $gettext('Submit') }}</button>
        </template>
      </div>
    </skill-form>
  </section>
</template>)

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import SkillForm from '@/components/shared_components/SkillForm';

  export default {
    components: {
      'skill-form': SkillForm
    },
    data() {
      return {
        silently: false
      };
    },
    computed: {
      ...mapState('TTManageSupplierSkill', {
        skillType: ({skillType}) => skillType || '',
        skillData: ({skillData}) => skillData || {},
      }),
      ...mapGetters('TTManageSupplierSkill', [
        'isTranslation', 'isInterpretation'
      ]),
      isSkillApproved() { return this.skillData.approved; },
      skillId() { return this.$store.state.TTManageSupplierSkill.skillData.id; },
      industry() { return this.skillData.industry || 'interpretation'; },
      hintText() {
        return this.$gettext('Select if you want to register an Interpreter or a translator.\n'
          + '      You can always add more skills.');
      },
      showTranslationBtn() { return (this.skillId && this.isTranslation) || !this.skillId; },
      showInterpretationBtn() { return (this.skillId && this.isInterpretation) || !this.skillId; },
      showSubmitBtn() { return this.isTranslation ? !this.isSkillApproved : true; }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      ...mapActions('TTManageSupplierSkill', [
        'createSkill',
        'updateSkill',
        'removeSkill',
        'createTranslationSkill',
        'updateTranslationSkill',
        'removeTranslationSkill',
      ]),
      ...mapMutations('EditProfileStore', ['setSkill', 'deleteSkill', 'setTranslationSkill', 'deleteTranslationSkill']),
      ...mapMutations('TTManageSupplierSkill', ['clearStore', 'setLangFrom', 'setSkillType', 'clearSkillType']),
      startModalProgress() { this.$emit('startprogress'); },
      stopModalProgress() { this.$emit('stopprogress'); },
      closeModal() { this.$emit('closemodal'); },
      catchRemoval() {
        this.startModalProgress();
        let promise = '';
        if (this.isTranslation) {
          promise = this.removeTranslationSkill(this.skillId);
        } else {
          promise = this.removeSkill(this.skillId);
        }
        promise.then(() => {
          if (this.isTranslation) this.deleteTranslationSkill(this.skillId);
          else this.deleteSkill(this.skillId);
          this.$store.dispatch('EditProfileStore/getUserProfile', {id: this.$store.getters['UserInfoStore/userUid']});
          this.stopModalProgress();
          this.closeModal();
        }).catch((error) => {
          this.$store.commit('ModalStore/removeModalErrors');
          this.stopModalProgress();
          if (error
            && error.errors
            && (error.errors?.language_pair_id?.includes('You have accepted jobs')
              || error.errors?.language_pair_id?.includes('You have applied jobs'))) {
            this.$store.commit('ModalStore/setModalErrors', this.$gettext('You have applied/accepted to job(s) using this language combination. Please visit your assignments page.'));
            this.$scrollToErrors();
          } else {
            this.closeModal();
            setTimeout(() => {
              this.$store.commit('ModalStore/setModal', {
                component: 'error-modal',
                data: {
                  error
                }
              });
            });
          }
        });
      },
      catchSubmit() {
        this.startModalProgress();
        let prom = '';
        if (this.isTranslation) {
          prom = this.skillId
            ? this.updateTranslationSkill({id: this.skillId})
            : this.createTranslationSkill();
        } else {
          prom = this.skillId
            ? this.updateSkill({id: this.skillId})
            : this.createSkill({silent: this.silently});
        }
        prom.then((data) => {
          if (this.isTranslation) this.setTranslationSkill(data);
          else this.setSkill(data);
          this.$store.dispatch('EditProfileStore/getUserProfile', {id: this.$store.getters['UserInfoStore/userUid']});
          this.stopModalProgress();
          this.closeModal();
        }).catch((response) => {
          this.stopModalProgress();
          if ((response
            && response.errors
            && response.errors?.language_pair_id?.includes('skill is already exists'))
            || response.errors?.includes('Skill with given languages already exists')) {
            this.$store.commit('ModalStore/removeModalErrors');
            this.$store.commit('ModalStore/setModalErrors', this.$gettext('You have already added this language combination. Please visit your profile page to edit it further.'));
            this.$scrollToErrors();
          } else {
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.$store.commit('ModalStore/setModal', {
                component: 'error-modal',
                data: {
                  response
                }
              });
            });
          }
        });
      },
      setSilently(value) {
        this.silently = value;
      },
      changeSkillType(value) {
        if (value != this.skillType) {
          this.setSkillType(value);
          this.clearStore();
          this.$store.commit('PostingStore/AttachmentsStore/clearStore');
          if (value == 'translation') this.setLangFrom('');
        }
      },
    },
    mounted() {
      if (this.skillId) this.setSkillType(this.industry);
    },
    beforeDestroy() {
      this.clearStore();
      this.clearSkillType();
      this.$store.commit('PostingStore/AttachmentsStore/clearStore');
      this.$store.commit('ErrorsStore/removeAllErrors');
      this.$store.commit('ModalStore/removeModalErrors');
    }
  };
</script>

<style scoped>
  .skill-modal {
    width: 100%;
    padding: 20px 25px;
  }

  .skill-modal__hint-text {
    padding: 10px 0;
    color: #000;
    opacity: 0.5;
  }

  .skill-modal__type-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
  }

  .skill-type-selector:first-child {
    margin-right: 10px;
  }

  .skill-modal__btns-wrapper {
    display: flex;
    justify-content: flex-end;
    width: calc(100% + 10px);
    margin: 30px -5px 0;
  }

  .skill-modal__btn {
    display: inline-block;
    overflow: unset;
    width: auto;
    min-width: 90px;
    height: auto;
    min-height: 35px;
    margin: 0 5px;
    padding: 5px;
    line-height: normal;
    white-space: normal;
  }
</style>
