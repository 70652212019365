<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
  import AddEvent from '@/components/preferences/private_events/AddEvent';

  export default {
    components: {
      'add-event': AddEvent
    },
    computed: {
      ...mapGetters('TTPrivateEventsStore', [
        'showTravelFields'
      ]),
      ...mapState('TTPrivateEventsStore', {
        privateEvent: ({privateEvent}) => privateEvent || {},
        standByEvent: ({standByPrivateEvent}) => standByPrivateEvent || {},
        // validation names
        titleValidationName: ({errors}) => errors.title.name,
        startTimeValidationName: ({errors}) => errors.startTime.name,
        sessionTypeValidationName: ({errors}) => errors.sessionType.name,
        travelMethodValidationName: ({errors}) => errors.travelMethod.name,
        distanceValidationName: ({errors}) => errors.distance.name,
        // errors array
        titleErrors: ({errors}) => errors.title.errors,
        startTimeErrors: ({errors}) => errors.startTime.errors,
        sessionTypeErrors: ({errors}) => errors.sessionType.errors,
        travelMethodErrors: ({errors}) => errors.travelMethod.errors,
        distanceErrors: ({errors}) => errors.distance.errors
      }),
      fullStartDate() { return this.$moment(`${this.privateEvent.startDate} ${this.privateEvent.startTime}`); }
    },
    methods: {
      ...mapActions('TTPrivateEventsStore', [
        'savePrivateEvent'
      ]),
      ...mapMutations('TTPrivateEventsStore', [
        'setStartTime',
        'setFinishTime',
        'setError',
        'removeErrors',
        'setInitPrivateEvent'
      ]),
      submitData(isForceSubmit = false) {
        if (this.isValidForm()) {
          this.updateAllDayTime();
          this.setProgress(true);
          this.savePrivateEvent(isForceSubmit).then(() => {
            this.successHandling();
            this.setProgress(false);
          }).catch((error) => {
            this.setProgress(false);
            this.errorHandling(error);
          });
        }
      },
      isValidPrivateEventForm() {
        let isValidForm = true;

        this.removeErrors();
        if (!this.privateEvent.title.trim()) {
          isValidForm = false;
          this.setError({
            fieldName: 'title',
            errorText: this.$gettext('Event title is required.')
          });
        }
        if (this.$moment().isAfter(this.fullStartDate)) {
          isValidForm = false;
          this.setError({
            fieldName: 'startTime',
            errorText: this.$gettext('Start time shouldn\'t be in the past.')
          });
        }
        this.$store.commit('ErrorsStore/setError', {name: this.titleValidationName, errors: this.titleErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.startTimeValidationName, errors: this.startTimeErrors});

        return isValidForm;
      },
      isValidStandByEventForm() {
        let isValidForm = true;

        this.removeErrors();
        if (this.$moment().isAfter(this.fullStartDate)) {
          isValidForm = false;
          this.setError({
            fieldName: 'startTime',
            errorText: this.$gettext('Start time shouldn\'t be in the past')
          });
        }
        if (!this.standByEvent.sessionType) {
          isValidForm = false;
          this.setError({
            fieldName: 'sessionType',
            errorText: this.$gettext('Please select assignment type')
          });
        }
        if (this.showTravelFields) {
          if (!this.standByEvent.travelMethod) {
            isValidForm = false;
            this.setError({
              fieldName: 'travelMethod',
              errorText: this.$gettext('Please select travel method')
            });
          }
          if (!this.standByEvent.distance) {
            isValidForm = false;
            this.setError({
              fieldName: 'distance',
              errorText: this.$gettext('Please select the maximum distance')
            });
          }
        }
        this.$store.commit('ErrorsStore/setError', {name: this.startTimeValidationName, errors: this.startTimeErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.sessionTypeValidationName, errors: this.sessionTypeErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.travelMethodValidationName, errors: this.travelMethodErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.distanceValidationName, errors: this.distanceErrors});

        return isValidForm;
      },
      isValidForm() {
        return this.privateEvent.eventType == 1
          ? this.isValidPrivateEventForm()
          : this.isValidStandByEventForm();
      },
      updateAllDayTime() {
        if (this.privateEvent.allDay) {
          this.setStartTime({value: '00:00', step: this.timeStep});
          this.setFinishTime({value: '23:59', step: this.timeStep});
        }
      },
      errorHandling(error) {
        if (error?.data?.errors?.accepted_jobs) {
          this.$store.commit('ModalStore/setModal', {
            component: 'has-confirmed-jobs-modal',
            width: 410,
            data: {
              title: this.$gettext('Warning!'),
              context: this
            }
          });
        } else if (error?.data?.errors?.applied_jobs) {
          this.$store.commit('ModalStore/setModal', {
            component: 'has-applied-jobs-modal',
            width: 410,
            data: {
              title: this.$gettext('Warning!'),
              context: this
            }
          });
        } else if (error.data?.errors?.can_standby?.includes('Can\'t create standby events')) {
          this.showMoreDates = true;
          this.$store.commit('InfoModalStore/setInfoModal', {
            text: this.$gettext('Unfortunately, you can\'t create standby events')
          });
        } else if (error.data?.errors?.allowed_to_add_new_standby?.includes('You are already on Standby at this time. Please delete the previous standby setting before making a new one')) {
          this.showMoreDates = true;
          this.$store.commit('InfoModalStore/setInfoModal', {
            text: this.$gettext('You are already on Standby at this time. Please delete the previous standby setting before making a new one')
          });
        } else if (error.data?.errors?.session_type?.includes('You can\'t create standby event with on site session type without an address')) {
          this.showMoreDates = true;
          this.$store.commit('InfoModalStore/setInfoModal', {
            text: this.$gettext('Looks like you don\'t have a saved address yet. Please add your home address from the My Profile page to be able to take in-person jobs and create remote Standby events.')
          });
        } else {
          this.$store.commit('ModalStore/setModal', {
            component: 'error-modal',
            data: {
              error
            }
          });
        }
      }
    }
  };
</script>
