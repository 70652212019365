(<template>
  <section class="apply-error">
    <p class="apply-error__text">{{ $gettext('This is a video assignment. Video assignments are conducted from your laptop.') }}</p>
    <p class="apply-error__text">{{ $gettext('In order to apply for this assignment, we need to be sure that you have a computer and an internet connection that can handle video connections.') }}</p>
    <p class="apply-error__text">{{ $gettext('After passing the test, you can return and apply for this assignment.') }}</p>
    <p v-if="isMobileApp"
       class="apply-error__text"><strong>{{ $gettext('Please conduct a video test from your computer, and then apply for this assignment.') }}</strong></p>
    <button v-else
            class="sk-btn sk-btn--default apply-error__btn"
            @click="goToLink">{{ $gettext('Click here to test your system') }}</button>
  </section>
</template>)

<script>
  export default {
    computed: {
      isMobileApp() { return this.$store.state.mobileApp; },
      link() {
        const prefix = this.$store.getters['UserInfoStore/userIsInterpreter'] ? 'Server' : 'Buyer';
        return this.$makeRoute({name: `${prefix}TestVideo`});
      }
    },
    methods: {
      goToLink() {
        this.$emit('closemodal');
        this.$router.push(this.link);
      }
    }
  };
</script>

<style scoped>
  .apply-error {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 20px;
  }

  .apply-error__text {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }

  .apply-error__btn {
    display: inline-block;
    width: auto;
    padding: 0 15px;
  }
</style>
