(<template>
  <article class="message">
    <section class="message__wrapper">
      <div v-if="modalText"
           class="message__text"
           v-html="modalText"></div>
    </section>
  </article>
</template>)

<script>

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      modalData() {
        return this.data || {};
      },
      modalText() {
        return this.modalData.text || '';
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      }
    }
  };
</script>

<style scoped>
  .message {
    padding: 30px 30px;
  }
</style>
