<template>
  <div :class="[typeClass,
                {'is-opened': isSelectOpened},
                {'is-disabled': disabled},
                {'is-with-error': (errors && errors.length)}]"
       class="secandary-select"
       @click="toggleSelectState"
       @touchstart="touchStartHandler"
       @keyup.enter="toggleSelectState"
       @keyup.space="toggleSelectState"
       @touchend="touchHandler">
    <input ref="openerCheckbox"
           v-model="isSelectOpened"
           class="secandary-select__hidden-checkbox"
           type="checkbox"
           @click.stop="">
    <div :class="{'secandary-select__value-wrapper--filters': filters}"
         class="secandary-select__value-wrapper">
      <p :class="{'secandary-select__description--filters': filters && !secandaryValue}"
         class="secandary-select__description">{{ description }}</p>
      <p v-if="!filters || secandaryValue"
         :title="currentValue"
         class="secandary-select__value"
         v-html="currentValue"></p>
      <span class="secandary-select__arrow"></span>
    </div>
    <div :class="{'secandary-select__list-cont--filters': filters}"
         class="secandary-select__list-cont">
      <label class="secandary-select__label">
        <input ref="typingInput"
               v-model="typingInputValue"
               :class="{'is-not-focusable': disabled}"
               :maxlength="typeMaxlength"
               type="search"
               class="secandary-select__input"
               @keyup.40="downArrowHandler"
               @keyup.38="upArrowHandler"
               @keydown.13.stop.prevent="pressEnterLogic"
               @keydown.9="pressTabLogic">
      </label>
      <ul ref="csList"
          class="secandary-select__list">
        <li v-for="item in renderedList"
            :key="makeUniqueKey(item.id)"
            :class="{'is-highlighted': item.id == currentHighlightedItemObj.id,
                     'secandary-select__list-item--filters': filters,
                     'selected-filter': item.id == preselectedValue}"
            class="secandary-select__list-item js-list-item"
            @click="itemClickLogic(item.id, $event)"
            v-html="item.name"></li>
      </ul>
    </div>
  </div>
</template>

<script>
  import {skSelect} from '@skiwo/sk-basic-components';

  export default {
    extends: skSelect,
    props: {
      filters: Boolean,
      secandaryValue: Boolean
    },
    watch: {
      isSelectOpened() {
        this.$refs.openerCheckbox.blur();
      },
    },
    methods: {
      pressTabLogic() {
        this.toggleSelectState();
      },
      toggleSelectState(eve) {
        // open and close the select
        if (this.disabled) return;
        if (!this.$store.state.GlobalStore.touchScreen && eve && eve.target.className.indexOf('secandary-select__input') == -1 && eve.target.className.indexOf('secandary-select__label') == -1) {
          if (this.isSelectOpened
            && this.$refs.typingInput
            && this.$refs.typingInput.selectionStart !== this.$refs.typingInput.selectionEnd) return;
          this.isSelectOpened = !this.isSelectOpened;
          if (this.$refs.typingInput) {
            this.isSelectOpened ? this.$refs.typingInput.focus() : this.$refs.typingInput.blur();
          }
        } else if (eve === false) {
          this.isSelectOpened = !this.isSelectOpened;
        }
        this.$nextTick(() => {
          this.$refs.typingInput.focus();
        });
      },
    },
  };
</script>
