export default ({$fetcher: fetchData, $moment: moment}) => {
  const filterDates = {
    startDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  };
  return {
    namespaced: true,
    state: {
      jobInvoices: '',
      customerInvoices: {
        invoices: '',
        count: 0,
        currentPage: 0,
        pages: 0
      },
      invoicesFilters: {
        sortBy: 'date desc',
        type: '',
        service: '',
        dateFrom: filterDates.startDate,
        dateTo: filterDates.endDate,
      },
      documentIdSearchQuery: '',
      assignmentIdSearchQuery: '',
      orgNumberSearchQuery: '',
      bookerSearchQuery: '',
      selectedBookerRefFilter: 'starts_with',
      orderSearchQuery: '',
      selectedOrderRefFilter: 'starts_with'
    },
    getters: {
      getBookerReferenceField(state) {
        return state.selectedBookerRefFilter === 'starts_with' ? 'bookerReferenceStart' : 'bookerReferenceEq';
      },
      getOrderReferenceField(state) {
        return state.selectedOrderRefFilter === 'starts_with' ? 'orderReferenceStart' : 'orderReferenceEq';
      },
      getFilterQueryParams: (state, getters) => (params) => {
        const queryParams = {...params};

        if (state.documentIdSearchQuery) {
          queryParams['s[documentId]'] = state.documentIdSearchQuery;
        }
        if (state.assignmentIdSearchQuery) {
          queryParams['s[assignmentId]'] = state.assignmentIdSearchQuery;
        }
        if (state.orgNumberSearchQuery) {
          queryParams['s[orgNumber]'] = state.orgNumberSearchQuery;
        }
        if (state.bookerSearchQuery) {
          queryParams[`s[${getters.getBookerReferenceField}`] = state.bookerSearchQuery;
        }
        if (state.orderSearchQuery) {
          queryParams[`s[${getters.getOrderReferenceField}`] = state.orderSearchQuery;
        }
        if (state.invoicesFilters.sortBy) {
          queryParams['s[s]'] = state.invoicesFilters.sortBy;
        }
        if (state.invoicesFilters.type) {
          queryParams['s[type]'] = state.invoicesFilters.type;
        }
        if (state.invoicesFilters.service) {
          queryParams['s[service]'] = state.invoicesFilters.service;
        }
        if (state.invoicesFilters.dateFrom) {
          queryParams['s[dateFrom]'] = state.invoicesFilters.dateFrom;
        }
        if (state.invoicesFilters.dateTo) {
          queryParams['s[dateTo]'] = state.invoicesFilters.dateTo;
        }

        return queryParams;
      },
    },
    actions: {
      getInvoices({commit, rootGetters}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getInvoices(rootGetters['UserInfoStore/enterpriseId'], params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setInvoices', data);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getCustomerInvoices({commit, rootGetters, getters}, params) {
        const queryParams = getters.getFilterQueryParams(params);

        return new Promise((resolve, reject) => {
          fetchData.getCustomerInvoices(rootGetters['UserInfoStore/enterpriseId'], queryParams).then((data) => {
            commit('setCustomerInvoices', {
              invoices: data.collection,
              pagination: data.pagination
            });
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      downloadInvoices({rootGetters, getters}) {
        const queryParams = getters.getFilterQueryParams({page: 1, items: 10});

        return new Promise((resolve, reject) => {
          fetchData.downloadInvoices(rootGetters['UserInfoStore/enterpriseId'], queryParams).then((data) => {
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
    },
    mutations: {
      setInvoices(state, data) {
        state.jobInvoices = data;
      },
      setCustomerInvoices(state, {invoices, pagination}) {
        state.customerInvoices = {
          ...state.customerInvoices,
          invoices,
          ...pagination
        };
      },
      // filters for customer invoices
      changeSortBy(state, data) {
        state.invoicesFilters.sortBy = data;
      },
      changeTypeFilter(state, data) {
        state.invoicesFilters.type = data;
      },
      changeServiceFilter(state, data) {
        if (data === 'all') {
          state.invoicesFilters.service = '';
        } else {
          state.invoicesFilters.service = data;
        }
      },
      changeDocumentIdSearch(state, data) {
        state.documentIdSearchQuery = data;
      },
      changeOrgNumberSearch(state, data) {
        state.orgNumberSearchQuery = data;
      },
      changeAssignmentIdSearch(state, data) {
        state.assignmentIdSearchQuery = data;
      },
      changeBookerRefSearch(state, data) {
        state.bookerSearchQuery = data;
      },
      changeBookerRefFilter(state, data) {
        state.selectedBookerRefFilter = data;
      },
      changeOrderRefSearch(state, data) {
        state.orderSearchQuery = data;
      },
      changeOrderRefFilter(state, data) {
        state.selectedOrderRefFilter = data;
      },
      setDateRange(state, {startDate, endDate}) {
        state.invoicesFilters.dateFrom = startDate;
        state.invoicesFilters.dateTo = endDate;
      },
      // end of filters
      clearFilters(state) {
        state.documentIdSearchQuery = '';
        state.assignmentIdSearchQuery = '';
        state.orgNumberSearchQuery = '';
        state.bookerSearchQuery = '';
        state.selectedBookerRefFilter = 'starts_with';
        state.orderSearchQuery = '';
        state.selectedOrderRefFilter = 'starts_with';
        state.invoicesFilters = {
          sortBy: 'date desc',
          type: '',
          service: '',
          dateFrom: filterDates.startDate,
          dateTo: filterDates.endDate,
        };
      },
      removeInvoicesData(state) {
        state.jobInvoices = '';
      },
      removeCustomerInvoicesData(state) {
        state.customerInvoices.invoices = '';
      },
    }
  };
};
