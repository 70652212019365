<template>
  <div class="attached-files-list">
    <attached-file v-for="file in filesList"
                   :key="file.id"
                   :file="file"
                   :is-selected="isSelected(file.id)"
                   @selected="emitItemSelect"
                   @unselected="emitItemSelect" />
  </div>
</template>

<script>
  import AttachedFile from '@/components/documents/file_selector/AttachedFile';

  export default {
    components: {
      'attached-file': AttachedFile
    },
    props: {
      filesList: {
        type: Array,
        default: () => [],
        required: true
      },
      selectedFiles: {
        type: [Array, String, Number],
        default: () => []
      },
      isSingle: { // select one file only at a time
        type: Boolean,
        default: false
      }
    },
    methods: {
      emitItemSelect(file) {
        const selectedItemsExists = this.selectedFiles && this.selectedFiles.length;
        const emitSelection = () => {
          if (!this.isSelected(file.id)) this.$emit('selected', file);
          else this.$emit('unselected', file);
        };
        if (this.isSingle) return selectedItemsExists && !this.isSelected(file.id) ? null : emitSelection();
        else return emitSelection();
      },
      isSelected(id) {
        return this.selectedFiles?.some((selectedFile) => selectedFile.id == id);
      }
    }
  };
</script>

<style scoped>
.attached-files-list {
  display: flex;
  flex-wrap: wrap;
}

.attached-files-list > .attached-file:not(:last-child) {
  margin-right: 16px;
}

.attached-files-list > .attached-file {
  margin-bottom: 16px;
}

</style>
