(<template>
  <section class="prof-about-modal__main-wrapper">
    <sk-textarea :description="text1"
                 :placeholder="text2"
                 :autofocus="true"
                 class="prof-about-modal__textarea"
                 :validation-name="textAboutValidationName"
                 :preselected-value="newText"
                 @fieldvaluechanged="catchAboutText" />
    <submit-btns :submit-handler="submitHandler"
                 :cancel-handler="closeModal" />
  </section>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import SubmitBtns from '@/components/profile_components/edit/ModalSubmitBtns';

  export default {
    components: {
      'submit-btns': SubmitBtns
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        newText: this.data.data.about,
        textAboutValidationName: 'textAbout',
        textAboutErrors: []
      };
    },
    computed: {
      text1() { return this.$gettext('Tell your prospective customers about yourself'); },
      text2() { return this.$gettext('I became an interpreter because...'); },
      ...mapGetters({
        userIsInterpreter: 'UserInfoStore/userIsInterpreter'
      })
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      catchAboutText(value) {
        this.newText = value;
      },
      closeModal() {
        this.$emit('closemodal');
      },
      stopProgress() {
        this.$emit('stopprogress');
      },
      submitHandler() {
        this.textAboutErrors = [];
        const form = new FormData();
        const string = this.userIsInterpreter ? 'interpreter[person]' : 'person';

        if (!this.newText || !this.newText.trim()) {
          this.textAboutErrors.push(this.$gettext('Tell your prospective customers about yourself'));
        }

        this.$store.commit('ErrorsStore/setError', {name: this.textAboutValidationName, errors: this.textAboutErrors});

        if (!this.textAboutErrors.length) {
          form.append(`${string}[about]`, this.newText.trim());
          this.$emit('startprogress');
          this.data.context.updateAboutInfo(form);
          this.data.context.$on('updatefailed', this.stopProgress);
          this.data.context.$on('updatesuccess', this.closeModal);
        }
      }
    }
  };
</script>

<style scoped>
  .prof-about-modal__main-wrapper {
    display: block;
    width: 100%;
    padding: 35px;
    padding-top: 10px;
  }

  .prof-about-modal__textarea {
    height: 100px;
  }
</style>
