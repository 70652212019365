(<template>
  <div ref="paymentMethodRef"
       class="buyer-info__item post-form__section payment-method">
    <template v-if="paymentMethodsList.length <= 6">
      <ul class="payment-method__list"
          @focusin="openPaymentMethodHelper">
        <li v-for="payment in paymentMethodsListCard"
            :key="payment.id"
            :class="payment.id === paymentMethod ? 'payment-method__item--active': ''"
            class="payment-method__item"
            tabindex="0"
            @click="handleAddingPaymentMethod(payment)">
          <template v-if="isOrganizationalMethod(payment)">
            <h3 class="payment-method__name">{{ payment.orgName }}</h3>
            <p class="payment-method__number">Org.: {{ payment.orgNumber }}</p>
          </template>
          <template v-else>
            <h3 class="payment-method__email">{{ payment.email }}</h3>
            <p class="payment-method__address">Add: {{ fullAddress(payment.address) }}</p>
          </template>
          <span v-if="payment.id === paymentMethod"
                class="payment-method__selected-icon"></span>
        </li>
        <button class="sk-btn sk-btn--white payment-method__btn--add"
                :class="{'payment-method__btn--add-first': paymentMethodsList.length}"
                @click="openAddPaymentModal">{{ $gettext('Add new payment method') }}</button>
      </ul>
    </template>
    <template v-if="paymentMethodsList.length >= 7">
      <div class="payment-method__title-wrapper">
        <button v-if="isHelperVisible"
                class="payment-method__help-icon"
                @click="openPaymentMethodHelper"></button>
        <div v-if="!noDescription"
             class="payment-method__title">{{ paymentMethodDescription }}</div>
      </div>
      <div class="payment-method__select-wrapper">
        <template v-if="!userCanChange && paymentMethodText">
          <div class="payment-method__select-text">{{ paymentMethodText }}</div>
          <button class="sk-link payment-method__link"
                  @click="userCanChange = true">{{ $gettext('Change') }}</button>
        </template>
        <div v-else
             class="payment-method__select-wrapper"
             @focusin="openPaymentMethodHelper">
          <sk-select :no-description="true"
                     :default-value="selectPaymentText"
                     :items-list="paymentMethodsList"
                     :preselected-value="paymentMethod"
                     class="payment-method__select"
                     @csvaluechanged="changePaymentMethod" />
          <button class="sk-select-btn payment-method__btn"
                  @click.prevent.stop="openAddPaymentModal"></button>
        </div>
      </div>
    </template>
    <p v-if="paymentMethodErrors.length"
       class="payment-method__error">{{ paymentMethodErrorText }}</p>
  </div>
</template>)

<script>
  import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
  import helpers from '@/helpers';

  export default {
    props: {
      noDescription: {
        type: Boolean
      },
      showAssignmentHelper: {
        type: Boolean,
      }
    },
    data() {
      return {
        userCanChange: false
      };
    },
    computed: {
      ...mapGetters({
        paymentMethods: 'PostingStore/PaymentMethodStore/currentPaymentMethods'
      }),
      ...mapState('PostingStore/PaymentMethodStore', {
        allPaymentMethods: (state) => state.allPaymentMethods || [],
        paymentMethod: (state) => state.paymentMethod || '',
        paymentMethodErrors: (state) => state.errors.paymentMethod.errors
      }),
      ...mapState('PostingStore/DepartmentAttendersStore', {
        department: (state) => state.department || '',
        departmentsList: (state) => state.departmentsList || []
      }),
      ...mapState('PostAssignmentHelperStore', {
        isHelperVisible: ({isHelperVisible}) => isHelperVisible || false
      }),
      selectPaymentText() { return this.$gettext('Select payment method'); },
      paymentMethodDescription() {
        return this.$gettext('Send invoice to:');
      },
      paymentMethodText() {
        return !this.userCanChange ? this.paymentMethodName : '';
      },
      paymentMethodErrorText() { return this.paymentMethodErrors[0]; },
      paymentMethodName() {
        const paymentMethodData = this.paymentMethods.find((payment) => payment.id == this.paymentMethod);

        return helpers.getJobInfo.getPaymentMethodName(this, {
          paymentMethodData
        });
      },
      paymentMethodsList() {
        if (this.paymentMethods.length) {
          return this.paymentMethods.map((paymentMethodData) => {
            return {
              id: paymentMethodData.id,
              name: helpers.getJobInfo.getPaymentMethodName(this, {
                paymentMethodData
              })
            };
          });
        }
        return [];
      },
      paymentMethodsListCard() {
        if (this.paymentMethods.length) {
          return this.paymentMethods.filter((value) => !value.archived);
        }
        return [];
      },
      showDefaultCheckbox() {
        return this.allPaymentMethods.filter((paymentMethod) => paymentMethod.department_id === this.department).length > 0;
      }
    },
    methods: {
      ...mapActions('PostingStore/PaymentMethodStore', [
        'changePaymentMethod',
        'getPayments',
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
      ]),
      openPaymentMethodHelper() {
        if (this.showAssignmentHelper) {
          this.setPopoverReference(this.$refs.paymentMethodRef);
          this.openHelper('paymentMethod');
        }
      },
      isOrganizationalMethod(payment) {
        return payment.methodType === 'organization';
      },
      handleAddingPaymentMethod(paymentData = {}) {
        this.getPayments();
        this.changePaymentMethod(paymentData.id);
      },
      openAddPaymentModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'payment-method-option',
          width: 510,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Payment Methods'),
            context: this,
            successHandlingCallback: 'handleAddingPaymentMethod'
          }
        });
      },
      fullAddress(address) {
        return helpers.getFullAddress(address);
      }
    }
  };
</script>

<style scoped>
.payment-method {
  padding: 12px 0 10px 0;
}

#app.salita-facelifted .payment-method {
  flex-wrap: nowrap;
  padding: 0 0 10px;
}

.payment-method__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  width: 100%;
  list-style-type: none;
}

.payment-method__item {
  position: relative;
  padding: 8px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

#app.salita-facelifted .payment-method__item {
  display: flex;
  flex-direction: column;
  gap: 2px;
  border: 2px solid transparent;
  border-radius: 8px;
  box-shadow: var(--shadow-and-blur);
}

.payment-method__item:focus {
  border-color: var(--color-primary);
  box-shadow: inset 0 0 0 2px #3af;
}

.payment-method__title-wrapper {
  display: inline-flex;
  gap: 8px;
  align-items: center;
}

.payment-method__help-icon {
  width: 20px;
  height: 20px;
  background-image: var(--image-question-mark-icon-black);
  background-position: 50% 50%;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.payment-method__help-icon:focus {
  box-shadow: inset 0 0 0 2px #3af;
}

.payment-method__title {
  width: 160px;
  min-width: 160px;
  padding-right: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.14;
}

.payment-method__select-text {
  margin-right: 10px;
  font-size: 14px;
}

.payment-method__select {
  width: 100%;
  margin-left: 10px;
  font-size: 13px;
}

#app.salita-facelifted .payment-method__select {
  margin-left: 0;
}

#app.salita-facelifted .payment-method__select >>> .sk-select {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.payment-method__select-wrapper {
  display: flex;
  width: 100%;
  max-width: 360px;
  margin-bottom: 5px;
}

#app.salita-facelifted .payment-method__select-wrapper {
  max-width: unset;
  margin-bottom: 0;
}

.payment-method__item--active {
  border-color: #ff5b24;
}

#app.salita-facelifted .payment-method__item--active {
  border-color: var(--color-black);
}

#app.salita-facelifted .payment-method__link {
  margin: 0 0 0 auto;
}

.payment-method__name,
.payment-method__email,
.payment-method__address,
.payment-method__number {
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  font-size: 14px;
  line-height: 1.43;
}

#app.salita-facelifted .payment-method__name,
#app.salita-facelifted .payment-method__email {
  color: var(--color-black);
}

.payment-method__number,
.payment-method__address {
  font-weight: normal;
}

.payment-method__item--active .payment-method__name,
.payment-method__item--active .payment-method__email {
  color: #000;
}

.payment-method__selected-icon {
  position: absolute;
  right: 8px;
  bottom: 8px;
  width: 16px;
  height: 16px;
  background-image: url(~@assets/imgs/undefined_imgs/selected_payment_icon.svg);
}

#app.salita-facelifted .payment-method__selected-icon {
  top: 8px;
  bottom: auto;
  background-image: url(~@assets/imgs/undefined_imgs/selected_payment_icon_black.svg);
}

.payment-method__btn.sk-select-btn {
  margin-top: 0;
}

#app.salita-facelifted .payment-method__btn.sk-select-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.payment-method__btn--add {
  max-width: 200px;
}

.payment-method__btn--add-first {
  margin-left: auto;
}

.payment-method__error {
  margin: 15px auto 0 auto;
  color: #f04;
}

@media (max-width: 767px) {
  .payment-method__list {
    display: block;
  }

  .payment-method__item:not(:first-child) {
    margin-top: 10px;
  }

  .payment-method__btn--add {
    margin-top: 10px;
    margin-left: 0;
  }

  .payment-method__help-icon {
    display: none;
  }
}

@media (max-width: 550px) {
  .payment-method__select-wrapper {
    max-width: 260px;
    margin-bottom: 0;
  }

  .payment-method__select {
    margin-left: 0;
  }
}
</style>
