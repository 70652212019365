import Vue from 'vue';
import sseRequest from '../../api/sse-request';

export default ({$fetcher: fetchData, $moment: moment}) => {
  return {
    namespaced: true,
    state: {
      storeJobObj: '',
      managerJobObj: '',
      chatDiscussions: '',
      currentDiscussion: '',
      currentDiscussionId: '',
      jobCallDetails: '',
      progressActive: false,
      mobileChatOpened: false,
      mobileInteractionContentOpened: false
    },
    getters: {
      storeJob(state) {
        return state.storeJobObj || {};
      },
      jobId(state, getters) {
        return getters.storeJob.id;
      },
      jobFeedback(state, getters, rootState, rootGetters) {
        const jobSupplierFeedback = getters.storeJob.jobSupplierFeedback || {};
        const jobDemanderFeedback = getters.storeJob.jobDemanderFeedback || {};

        return rootGetters['UserInfoStore/userIsInterpreter']
          ? jobSupplierFeedback
          : jobDemanderFeedback;
      },
      cancelledJobFeedback(state, getters) {
        return getters.storeJob.jobSupplierFeedbackForCancelledJob || {};
      },
      currentDiscussion(state, getters, rootState, rootGetters) {
        const currentInterDiscussion = state.chatDiscussions.discussion || {};
        const currentBusinessDiscussion = state.currentDiscussion.discussion || {};

        return rootGetters['UserInfoStore/userIsInterpreter']
          ? currentInterDiscussion
          : currentBusinessDiscussion;
      },
      sessionType(state, getters) {
        return getters.storeJob.interpretationRequirement?.sessionType || '';
      },
      seriesJobs(state, getters) {
        return getters.storeJob.seriesJobs || [];
      },
      seriesJobsForSupplier(state, getters) {
        return getters.isSeriesJob
          ? getters.seriesJobs.filter((job) => {
          const isNeedsFeedbackJob = getters.storeJob.status === 'needsFeedback';
          const isFinishedJob = getters.storeJob.status === 'finished';
          const isInvitedJob = getters.storeJob.status === 'invited' || getters.storeJob.status === 'withdrawn';
          const isLockedJob = getters.storeJob.locked;
          const isNotLockedFinishJob = isFinishedJob && !isLockedJob;
          // return only current job for feedback draft invoice block
          if (isNeedsFeedbackJob || isNotLockedFinishJob) {
            return job.id == getters.jobId;
          // return appliable series jobs only if it's invited
          } else if (isInvitedJob) {
            return !['cancelled', 'closed', 'rejected', 'unsuitable', 'finished', 'needsFeedback', 'applied', 'accepted', 'in_progress'].includes(job.status);
          } else {
            return !['cancelled', 'closed', 'rejected', 'unsuitable', 'finished', 'needsFeedback'].includes(job.status);
          }
        }) || []
          : [];
      },
      appliableSeriesJobsForSupplier(state, getters) {
        return getters.seriesJobsForSupplier.filter((job) => job.status != 'accepted');
      },
      isSeriesJob(state, getters) {
        return getters.seriesJobs.length > 1;
      },
      isSameSupplierSeriesJob(state, getters) {
        return getters.isSeriesJob
          && getters.storeJob.processRequirement?.seriesJobProcessing === 'same_int';
      },
      isSecretaryAssignment(state, getters) {
        return getters.storeJob.interpretationRequirement?.jobType === 'secretary';
      },
      discussionsList(state) {
        return state.chatDiscussions.discussions;
      },
      interDiscussion(state) {
        return state.chatDiscussions.discussion;
      },
      chatEvents(state, getters, rootState, rootGetters) {
        if (rootGetters['UserInfoStore/userIsNotInterpreter']) {
          return state.currentDiscussion ? state.currentDiscussion.discussion.events : '';
        } else {
          return state.chatDiscussions ? state.chatDiscussions.discussion.events : '';
        }
      },
      callDetails(state) {
        return state.jobCallDetails;
      },
      suitableInterpreters(state) {
        return state.suitableInterpreters;
      },
      travelToAddress(state, getters) {
        const interpretationRequirement = getters.storeJob.interpretationRequirement || {};
        const contactAttributes = interpretationRequirement.contactAttributes || {};
        const address = contactAttributes.address || {};
        const {line1, line2, city, postcode, country} = address;
        return line1 && city && postcode && country
          ? ''.concat(`${line1}, `, (line2 ? `${line2}, ` : ''), `${postcode} `, `${city}, `, country)
          : '';
      },
      customerUseOwnVideoSystem(state) {
        const videoPlatform = state.storeJobObj?.interpretationRequirement?.videoPlatform || {};
        return !videoPlatform.default;
      }
    },
    actions: {
      getDiscussions({state, commit, rootGetters}, id) {
        return new Promise((resolve, reject) => {
          fetchData.getDiscussions(id, rootGetters['UserInfoStore/userEntity'], rootGetters['UserInfoStore/userEntityId']).then((discussions) => {
            if (discussions.code && (discussions.code == 403 || discussions.code == 500 || discussions.code == 404)) {
              commit('GlobalStore/set404Page', '', {root: true});
              resolve();
              return;
            }
            if (discussions.discussions) {
              discussions.discussions.sort((a, b) => {
                if (moment(b.keyEvent.date).isAfter(moment(a.keyEvent.date))) {
                  return 1;
                }
                if (moment(a.keyEvent.date).isAfter(moment(b.keyEvent.date))) {
                  return -1;
                }
                return 0;
              });
              for (let i = 0, lim = discussions.discussions.length; i < lim; i += 1) {
                if (discussions.discussions[i].status == 'accepted') {
                  const acceptedDiscussion = discussions.discussions.splice(i, 1);
                  discussions.discussions.unshift(acceptedDiscussion[0]);
                  break;
                }
              }
            }
            commit('setChatDiscussions', discussions);
            if (rootGetters['UserInfoStore/userIsNotInterpreter'] && discussions.discussions[0] && !state.currentDiscussionId) {
              fetchData.getOneDiscussion(discussions.discussions[0].id, rootGetters['UserInfoStore/userEntity'], rootGetters['UserInfoStore/userEntityId']).then((oneDiscussion) => {
                if (oneDiscussion.code == 401) {
                  commit('TTAuthStore/logOut', '', {root: true});
                  commit('GlobalStore/remove404Page', '', {root: true});
                  resolve('LOGOUT');
                  return;
                }
                commit('setOneDiscussion', oneDiscussion);
                commit('setCurrentDiscussionId', discussions.discussions[0].id);
                resolve(discussions, oneDiscussion);
              });
            } else {
              resolve(discussions);
            }
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getCallDetails({commit}, id) {
        return new Promise((resolve, reject) => {
          fetchData.getCallDetails(id).then((callDetails) => {
            if (callDetails.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setCallDetails', callDetails);
            resolve(callDetails);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getJobDetailsById(context, id) {
        return new Promise((resolve, reject) => {
          fetchData.getJobDetailsById(id)
            .then((data) => {
              resolve(data.job);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      getJobById({commit, dispatch, rootGetters, rootState}, id) {
        const setNewJob = (newJobObj, resolve, reject) => {
          if (newJobObj.code && (newJobObj.code == 401 || newJobObj.code == 404)) {
            commit('GlobalStore/set404Page', '', {root: true});
            resolve();
            return;
          }
          commit('setStoreJob', newJobObj);
          const jobStatus = newJobObj.job.status;
          if (rootGetters['UserInfoStore/userIsInterpreter']
            && newJobObj.job
            && newJobObj.job.usesPricingTemplate
            && !newJobObj.job.locked
            && ((jobStatus === 'cancelled' && newJobObj.job.interpretationRequirement.sessionType === 'in_person')
            || !['cancelled', 'closed', 'rejected', 'unsuitable'].includes(jobStatus)
          )) {
            dispatch('AssignmentFlowStore/getInterInvoicePreviewData', {jobId: id}, {root: true});
          }
          const currentUserAwarded = newJobObj.job.awardedInterpreterUid === rootGetters['UserInfoStore/userUid'];
          if (newJobObj.job
            && newJobObj.job.locked
            && newJobObj.job.usesPricingTemplate
            && ['finished', 'cancelled'].includes(jobStatus)) {
            if (rootState.TTAuthStore.godmode || (rootGetters['UserInfoStore/userIsInterpreter'] && currentUserAwarded)) {
              dispatch('AssignmentFlowStore/getFinalInvoice', {jobId: id, jobStatus: newJobObj.job.status}, {root: true});
            }
          }
          if (newJobObj.job
            && ['needsFeedback', 'finished'].includes(jobStatus)) {
            dispatch('FeedbackStore/getLastUpdatedData', {jobId: id}, {root: true});
            if (rootGetters['UserInfoStore/userIsEnterpriseMember'] && !newJobObj.job.locked) {
              const bookerUid = newJobObj.job.interpretationRequirement?.bookerUid || '';
              const departmentId = newJobObj.job.interpretationRequirement?.department?.id || '';

              dispatch('FeedbackStore/getInvoiceSettingsForParticularBooker', {bookerUid, departmentId}, {root: true});
            }
          }
          if (!rootState.PostInterpretationStore.copiedJob) dispatch('preselectPricingTemplate');
          if (newJobObj.job
            && ['accepted', 'in_progress', 'needsFeedback', 'finished'].includes(jobStatus)) {
            fetchData.getCallDetails(id).then((callDetails) => {
              if (callDetails.code == 401) {
                commit('TTAuthStore/logOut', '', {root: true});
                commit('GlobalStore/remove404Page', '', {root: true});
                resolve('LOGOUT');
                return;
              }
              commit('setCallDetails', callDetails);
              if (newJobObj.job
                && newJobObj.job.interpretationRequirement
                && newJobObj.job.interpretationRequirement.department
                && newJobObj.job.interpretationRequirement.department.id
                && rootGetters['UserInfoStore/userIsEnterpriseMember']) {
                dispatch('fetchAttendersList', newJobObj.job.interpretationRequirement.department.id, {root: true}).then(() => {
                  resolve(newJobObj);
                });
              } else if (rootGetters['UserInfoStore/userIsEnterpriseMember']) {
                dispatch('fetchSelectList', 'attenders', {root: true}).then(() => {
                  resolve(newJobObj);
                });
              } else {
                resolve(newJobObj);
              }
            }).catch((error) => {
              reject(error);
            });
          } else {
            if (newJobObj.job
              && newJobObj.job.interpretationRequirement
              && newJobObj.job.interpretationRequirement.department
              && newJobObj.job.interpretationRequirement.department.id
              && rootGetters['UserInfoStore/userIsEnterpriseMember']) {
              dispatch('fetchAttendersList', newJobObj.job.interpretationRequirement.department.id, {root: true}).then(() => {
                resolve(newJobObj);
              });
            } else if (rootGetters['UserInfoStore/userIsEnterpriseMember']) {
              dispatch('fetchSelectList', 'attenders', {root: true}).then(() => {
                resolve(newJobObj);
              });
            } else {
              resolve(newJobObj);
            }
          }
        };

          const fallBackRequest = (resolve, reject) => {
            // ------ Fall back Ajax Request  ------ //
            fetchData.getAnAssignment(id).then((newJobObj) => {
              setNewJob(newJobObj, resolve, reject);
            }).catch((error) => {
              reject(error);
            });
          };

          return new Promise((resolve, reject) => {
            dispatch('getShortLivedToken', '', {root: true})
              .then((res) => {
                const params = {token: res.token};
                // ------ SSE Request  ------ //
                sseRequest(
                  {
                    onmessage: (event) => {
                      const data = JSON.parse(event.data);
                      if (data) {
                        setNewJob(data, resolve, reject);
                      }
                    },
                    onerror: () => {
                      fallBackRequest(resolve, reject);
                    },
                    url: `/v1/jobs/${id}/async`,
                    params
                  }
                );
              })
              .catch(() => {
                fallBackRequest(resolve, reject);
              });
          });
      },
      preselectPricingTemplate({commit, rootState}) {
        if (rootState.TTAuthStore.godmode && rootState.OneAssignmentStore?.storeJobObj?.usesPricingTemplate) {
          const demanderInternalTemplate = rootState.OneAssignmentStore.storeJobObj.demanderPricingTemplates?.internal?.id;
          const demanderExternalTemplate = rootState.OneAssignmentStore.storeJobObj.demanderPricingTemplates?.external?.id;
          const supplierHonorarTemplate = rootState.OneAssignmentStore.storeJobObj.supplierPricingTemplates?.honorar?.id;
          const supplierCompanyTemplate = rootState.OneAssignmentStore.storeJobObj.supplierPricingTemplates?.company?.id;
          const supplierEmployeeTemplate = rootState.OneAssignmentStore.storeJobObj.supplierPricingTemplates?.employee?.id;
          if (demanderInternalTemplate) commit('PricingTemplatesStore/setTemplate', {roleType: 'demander', id: demanderInternalTemplate, financeType: 'internal'}, {root: true});
          if (demanderExternalTemplate) commit('PricingTemplatesStore/setTemplate', {roleType: 'demander', id: demanderExternalTemplate, financeType: 'external'}, {root: true});
          if (supplierHonorarTemplate) commit('PricingTemplatesStore/setTemplate', {roleType: 'supplier', id: supplierHonorarTemplate, financeType: 'honorar'}, {root: true});
          if (supplierCompanyTemplate) commit('PricingTemplatesStore/setTemplate', {roleType: 'supplier', id: supplierCompanyTemplate, financeType: 'company'}, {root: true});
          if (supplierEmployeeTemplate) commit('PricingTemplatesStore/setTemplate', {roleType: 'supplier', id: supplierEmployeeTemplate, financeType: 'employee'}, {root: true});
        }
      },
      getOneDiscussion({commit, rootGetters}, id) {
        return new Promise((resolve, reject) => {
          fetchData.getOneDiscussion(id, rootGetters['UserInfoStore/userEntity'], rootGetters['UserInfoStore/userEntityId']).then((oneDiscussion) => {
            if (oneDiscussion.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setOneDiscussion', oneDiscussion);
            commit('setCurrentDiscussionId', id);
            resolve(oneDiscussion);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      makeApply({commit, rootGetters}, {id, params}) {
        params.job_entity_id = rootGetters['UserInfoStore/userEntityId'];
        params.job_entity_type = rootGetters['UserInfoStore/userEntity'];
        return new Promise((resolve, reject) => {
          fetchData.makeApply(id, params).then((data) => {
            commit('setNewDiscussionStatus', 'applied');
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      makeProactiveApply({rootGetters}, {params}) {
        params.job_entity_id = rootGetters['UserInfoStore/userEntityId'];
        params.job_entity_type = rootGetters['UserInfoStore/userEntity'];
        return new Promise((resolve, reject) => {
          fetchData.makeProactiveApply(params).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      makeSeriesApply({commit, rootGetters}, {id, params = []}) {
        const paramsArr = params.map((params) => {
          params.job_entity_id = rootGetters['UserInfoStore/userEntityId'];
          params.job_entity_type = rootGetters['UserInfoStore/userEntity'];
          return params;
        });
        return new Promise((resolve, reject) => {
          fetchData.makeSeriesApply(id, paramsArr).then((data) => {
            commit('setNewDiscussionStatus', 'applied');
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      makeAward({rootGetters}, {discussionId, force}) {
        return new Promise((resolve, reject) => {
          const params = {
            job_entity_id: rootGetters['UserInfoStore/userEntityId'],
            job_entity_type: rootGetters['UserInfoStore/userEntity']
          };
          const forceParams = rootGetters['TTAuthStore/isGodmode']
            ? {force_award: force}
            : {};

          fetchData.makeAward(discussionId, {...params, ...forceParams}).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      makeReject({rootGetters}, params) {
        return new Promise((resolve, reject) => {
          fetchData.makeReject(params, rootGetters['UserInfoStore/userEntity'], rootGetters['UserInfoStore/userEntityId']).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      makeDecline({commit, rootGetters}, id) {
        return new Promise((resolve, reject) => {
          fetchData.makeDecline(id, rootGetters['UserInfoStore/userEntity'], rootGetters['UserInfoStore/userEntityId']).then((data) => {
            commit('setNewDiscussionStatus', 'declined');
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      makeWithdraw(context, params) {
        return new Promise((resolve, reject) => {
          fetchData.makeWithdraw(params).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      makeAbandon({commit}, id) {
        return new Promise((resolve, reject) => {
          fetchData.makeJobAbandon(id).then(() => {
            commit('setNewDiscussionStatus', 'abandoned');
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      makeWithdrawAsSupplier(context, {jobId, discussionId, params}) {
        return new Promise((resolve, reject) => {
          fetchData.makeWithdrawAsSupplier(jobId, discussionId, params).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendMessage(context, {id, msg}) {
        return new Promise((resolve, reject) => {
          fetchData.sendMessage(id, msg).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendFile(context, {id, form}) {
        return new Promise((resolve, reject) => {
          fetchData.sendFile(id, form).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      applyAsInterpreter({commit}, {jobId, interpreterUid}) {
        return new Promise((resolve, reject) => {
          fetchData.makeApplyAsInterpreter(jobId, interpreterUid).then((data) => {
            commit('setJobStatus', 'applied');
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      awardAsCustomer(context, {jobId, supplierId}) {
        return new Promise((resolve, reject) => {
          fetchData.makeAwardAsCustomer(jobId, supplierId).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendCancel({commit}, {jobId, reason, withoutCharge, withNotifications}) {
        return new Promise((resolve, reject) => {
          fetchData.sendCancel(jobId, reason, withoutCharge, withNotifications).then((data) => {
            commit('setJobStatus', 'cancelled');
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendCancelNotShown({commit}, jobId) {
        return new Promise((resolve, reject) => {
          fetchData.sendCancelNotShown(jobId).then((data) => {
            commit('setJobStatus', 'cancelled');
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      lockJob({dispatch}, jobId) {
        return new Promise((resolve, reject) => {
          fetchData.lockJob(jobId).then((data) => {
            dispatch('getJobById', jobId);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      makeHide(context, id) {
        return new Promise((resolve, reject) => {
          fetchData.sendHide(id).then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      saveNewAttendersList({commit}, {jobID, array}) {
        return new Promise((resolve, reject) => {
          fetchData.saveNewAttendersList(jobID, array).then((data) => {
            commit('updateJobParticipants', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      sendJobDetails(context, {form, id}) {
        return new Promise((resolve, reject) => {
          fetchData.sendJobDetails(form, id).then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      updateAutoInvite({state, commit}, value) {
        return new Promise((resolve, reject) => {
          const form = new FormData();
          const {id} = state.storeJobObj;
          const adminAutoInviteProcessing = value ? 'admin_auto_invite_start' : 'admin_auto_invite_stop';

          form.append('job[process_requirement][admin_auto_invite_processing]', adminAutoInviteProcessing);
          fetchData.updateAssignmentProcessRequirement(form, id).then((job) => {
            commit('setStoreJob', job);
            resolve(job);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      updateAutoApprove({state, commit}, value) {
        return new Promise((resolve, reject) => {
          const form = new FormData();
          const {id} = state.storeJobObj;

          form.append('job[allow_auto_award]', value);
          fetchData.updateAssignmentPriceRequirement(form, id).then((job) => {
            commit('setStoreJob', job);
            resolve(job);
          })
            .catch((error) => {
              reject(error);
            });
        });
      },
      updateStandBy({state, commit}, value) {
        return new Promise((resolve, reject) => {
          const form = new FormData();
          const {id} = state.storeJobObj;

          form.append('job[process_requirement][standby_processing]', value ? 'turned_on' : 'turned_off');
          fetchData.updateAssignmentProcessRequirement(form, id).then((job) => {
            commit('setStoreJob', job);
            resolve(job);
          })
            .catch((error) => {
              reject(error);
            });
        });
      },
      updateInfiniteAutoInvite({state, commit}, value) {
        return new Promise((resolve, reject) => {
          const {id} = state.storeJobObj;
          const form = new FormData();

          form.append('job[process_requirement][infinite_auto_invite]', value);
          fetchData.updateAssignmentProcessRequirement(form, id).then((job) => {
            commit('setStoreJob', job);
            resolve(job);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      updateNote({commit}, {jobId, note}) {
        return new Promise((resolve, reject) => {
          const form = new FormData();
          form.append('note', note);
          fetchData.updateAssignmentNote(jobId, form).then((data) => {
            commit('setAssignmentNote', data.note);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      downgradeProcessRequirements(_, {jobId, requirement}) {
        return new Promise((resolve, reject) => {
          fetchData.downgradeProcessRequirements(jobId, requirement).then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      finishJob(context, jobId) {
        return new Promise((resolve, reject) => {
          fetchData.finishJob(jobId).then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getSupplierLink(context, {interpreterUid, jobId}) {
        return new Promise((resolve, reject) => {
          fetchData.getSupplierLink(interpreterUid, jobId).then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      setOtherSideFeedbackTimes({state, rootGetters}, data) {
        const currentActor = rootGetters['UserInfoStore/userIsInterpreter'] ? 'supplier' : 'demander';
        const feedBackExists = state.chatDiscussions.discussion?.feedbacks;
        const feedbacks = [{
          actualFinishTime: data.feedbackActualFinishTime,
          actualStartTime: data.feedbackActualStartTime,
          author: data.feedbackAuthor
        }];
        if (currentActor !== data.feedbackAuthor) {
          if (currentActor === 'supplier') {
            if (feedBackExists) {
              state.chatDiscussions.discussion.feedbacks.find(({author}) => author !== currentActor).actualStartTime = data.feedbackActualStartTime;
              state.chatDiscussions.discussion.feedbacks.find(({author}) => author !== currentActor).actualFinishTime = data.feedbackActualFinishTime;
            } else {
              Vue.set(state.chatDiscussions.discussion, 'feedbacks', feedbacks);
            }
          } else if (currentActor === 'demander') {
            if (feedBackExists) {
              state.currentDiscussion.discussion.feedbacks.find(({author}) => author !== currentActor).actualStartTime = data.feedbackActualStartTime;
              state.currentDiscussion.discussion.feedbacks.find(({author}) => author !== currentActor).actualFinishTime = data.feedbackActualFinishTime;
            } else {
              Vue.set(state.currentDiscussion.discussion, 'feedbacks', feedbacks);
            }
          }
        }
      },
      updateCancelInfo({commit}, info) {
        commit('setCancelReason', info.cancelledReason);
        commit('setCanceller', info.cancellerName);
        commit('setCancelledAt', info.cancelledAt);
      }
    },
    mutations: {
      setAttachments(state, data) {
        state.storeJobObj.attachments = data;
      },
      startAssignmentProgress(state) {
        state.progressActive = true;
      },
      stopAssignmentProgress(state) {
        state.progressActive = false;
      },
      setChatDiscussions(state, data) {
        state.chatDiscussions = data;
      },
      setCallDetails(state, data) {
        state.jobCallDetails = data;
      },
      setStoreJob(state, data) {
        state.storeJobObj = data.job;
      },
      setManagerJob(state, data) {
        state.managerJobObj = data;
      },
      setOneDiscussion(state, data) {
        state.currentDiscussion = data;
      },
      setNewDiscussionEvent(state, data) {
        let allowToPush = true;
        if (state.currentDiscussion && state.currentDiscussion.discussion) {
          // state.currentDiscussion.discussion is true only for buyer side
          if (state.currentDiscussion.discussion.events && Array.isArray(state.currentDiscussion.discussion.events)) {
            for (const eve of state.currentDiscussion.discussion.events) {
              if (eve.notification_identifier == data.notification_identifier) {
                allowToPush = false;
                break;
              }
            }
          }
          if (allowToPush) {
            if (!Array.isArray(state.currentDiscussion.discussion.events)) state.currentDiscussion.discussion.events = [];
            state.currentDiscussion.discussion.events.push(data);
            state.currentDiscussion.discussion.keyEvent = data;
          }
        } else if (state.chatDiscussions && state.chatDiscussions.discussion) {
          if (state.chatDiscussions.discussion.events && Array.isArray(state.chatDiscussions.discussion.events)) {
            for (const eve of state.chatDiscussions.discussion.events) {
              if (eve.notification_identifier == data.notification_identifier) {
                allowToPush = false;
                break;
              }
            }
          }
          if (allowToPush) {
            if (!Array.isArray(state.chatDiscussions.discussion.events)) state.chatDiscussions.discussion.events = [];
            state.chatDiscussions.discussion.events.push(data);
            state.chatDiscussions.discussion.keyEvent = data;
            if (data.type === 'apply') {
              state.chatDiscussions.discussion.offer = data.data;
            }
          }
        }
      },
      setNewDiscussionStatus(state, data) {
        if (state.currentDiscussion.discussion && state.chatDiscussions.discussions && Array.isArray(state.chatDiscussions.discussions)) {
          // state.currentDiscussion.discussion is true only for buyer side
          state.currentDiscussion.discussion.status = data;
          for (const disc of state.chatDiscussions.discussions) {
            if (disc.id == state.currentDiscussion.discussion.id) {
              disc.status = data;
              break;
            }
          }
        } else if (state.chatDiscussions && state.chatDiscussions.discussion) {
          state.chatDiscussions.discussion.status = data;
        }
      },
      setStoreJobStatus(state, data) {
        if (state.storeJobObj) {
          state.storeJobObj.status = data;
        }
      },
      setNewKeyEvent(state, data) {
        if (state.chatDiscussions && state.chatDiscussions.discussions && data && data.jobInfo) {
          for (let i = 0; i < state.chatDiscussions.discussions.length; i += 1) {
            if (state.chatDiscussions.discussions[i].id == data.jobInfo.discussionId) {
              state.chatDiscussions.discussions[i].keyEvent = data.event;
              state.chatDiscussions.discussions[i].status = data.jobInfo.discussionStatus;
              data.event && data.event.type === 'apply' ? state.chatDiscussions.discussions[i].offer = data.event.data : '';
              return;
            }
          }
        }
      },
      setCurrentDiscussionId(state, id) {
        state.currentDiscussionId = id;
      },
      setJobFeedback(state, data) {
        Vue.set(state.storeJobObj, 'jobSupplierFeedback', data);
        Vue.set(state.storeJobObj, 'jobDemanderFeedback', data);
      },
      setJobPublicFeedback(state, data) {
        Vue.set(state.storeJobObj, 'publicFeedback', data);
      },
      setAutoInviteDelay(state, value) {
        state.storeJobObj.processRequirement.invitationDelay = value;
      },
      updateJobParticipants(state, data) {
        state.storeJobObj.interpretationRequirement.attenders = data.attenders;
      },
      setJobStatus(state, status) {
        if (state.storeJobObj) {
          state.storeJobObj.status = status;
        }
      },
      setCanceller(state, cancellerName) {
        if (state.storeJobObj) {
          state.storeJobObj.cancellerName = cancellerName;
        }
      },
      setCancelReason(state, cancelReason) {
        if (state.storeJobObj) {
          Vue.set(state.storeJobObj, 'cancelledReason', cancelReason);
        }
      },
      setCancelledAt(state, cancelledAt) {
        if (state.storeJobObj) {
          Vue.set(state.storeJobObj, 'cancelledAt', cancelledAt);
        }
      },
      setAssignmentNote(state, assignmentNote) {
        if (state.storeJobObj) {
          state.storeJobObj.assignmentNote = assignmentNote;
        }
      },
      setJobLocked(state, value) {
        state.locked = value;
      },
      openMobileInteractionContent(state) {
        state.mobileInteractionContentOpened = true;
      },
      closeMobileInteractionContent(state) {
        state.mobileInteractionContentOpened = false;
      },
      openMobileChat(state) {
        state.mobileChatOpened = true;
      },
      closeMobileChat(state) {
        state.mobileChatOpened = false;
      },
      clearAssignmentStore(state) {
        state.storeJobObj = '';
        state.managerJobObj = '';
        state.chatDiscussions = '';
        state.currentDiscussion = '';
        state.currentDiscussionId = '';
        state.jobCallDetails = '';
      }
    }
  };
};
