(<template>
  <div class="created-at is-progress-bar"
       :class="{'in-progress': progressActive, 'pointer': !noActionOnClick}"
       @click="onClick">
    <p class="created-date">{{ actualCreatedAtDate }}</p>
    <div class="created-time__wrapper">
      <p ref="createdTimeCellElem"
         class="assignment__duration">{{ actualCreatedAtTime }}</p>
      <p v-if="!hideHintBtn"
         class="created-at__tooltip"
         @mouseleave="closeTooltip"
         @mouseover="openTooltip"></p>
    </div>
  </div>
</template>)

<script>
  import helpers from '@/helpers';
  import ManagerJobEditInfoMixin from '@/mixins/ManagerJobEditInfoMixin';

  export default {
    mixins: [ManagerJobEditInfoMixin],
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      classes() { return this.propData?.classes; },
      hideHintBtn() { return !!this.propData?.hideHintBtn; },
      noActionOnClick() { return !!this.propData?.noActionOnClick; },
      actualCreatedAt() { return this.$moment(this.propData?.actualCreatedAt); },
      bookingMechanism() { return this.propData?.bookingMechanism; },
      bookingMechanismText() {
        switch (this.bookingMechanism) {
          case 'phone':
            return this.$gettext('Phone');
          case 'chat':
            return this.$gettext('Chat');
          case 'platform':
            return this.$gettext('Platform');
          default:
            return this.$gettext('Email');
        }
      },
      createdAt() { return this.$moment(this.propData?.createdAt).format('DD.MM.YY HH:mm'); },
      actualCreatedAtDate() { return this.actualCreatedAt.format('DD.MM.YY'); },
      actualCreatedAtTime() { return this.actualCreatedAt.format('HH:mm') || '-'; },
      requestedBy() {
        return `
             <di>
               <h4>${this.$gettext('Requested By') + ' ' + this.bookingMechanismText}</h4>
               <p>${this.createdAt}</p>
            </di>
        `;
      },
      computedStyle() {
        const {top, left} = helpers.getElementPosition(this.$refs.createdTimeCellElem);
        return {
          position: 'absolute',
          top: (top) + 'px',
          left: (left + 20) + 'px'
        };
      }
    },
    methods: {
      onClick() {
        if (!this.noActionOnClick) this.openEditAdminTools();
      },
      openTooltip() {
        this.$store.commit('TooltipRootStore/setTooltip', {
          data: {
            itemText: this.requestedBy,
            relativePosition: 'top-center'
          },
          absolutePosition: this.computedStyle
        });
      },
      closeTooltip() {
        this.$store.dispatch('TooltipRootStore/closeTooltip');
      }
    }
  };
</script>
<style>
.created-at__tooltip {
  width: 15px;
  height: 15px;
  margin-top: 5px;
  margin-left: 5px;
  background-image: url(~@assets/imgs/salita/job_grid/info_grey_icon.svg);
  background-repeat: no-repeat;
  line-height: 15px;
  cursor: pointer;
}
</style>
<style scoped>
.created-at {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  border-radius: 8px;
}

.created-time__wrapper {
  display: flex;
  align-items: center;
}
</style>
