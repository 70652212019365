import Vue from 'vue';

export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      logs: {
        logs: '',
        pages: 0
      }
    },
    getters: {
      pageTotal(state) {
        return state.logs.pages || 0;
      },
      logsList(state) {
        return state.logs.logs || [];
      }
    },
    actions: {
      fetchSystemLogsList({commit}, {jobId, params}) {
        return new Promise((resolve, reject) => {
          fetchData.fetchSystemLogs(jobId, params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setLogsList', data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      fetchSystemLogDetails({commit}, logId) {
        return new Promise((resolve, reject) => {
          fetchData.fetchSystemLogDetails(logId).then((data) => {
            if (data?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setLogDetails', {logId, data});
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      fetchProjectSystemLogsList({commit}, {orderId, params}) {
        return new Promise((resolve, reject) => {
          fetchData.fetchProjectSystemLogs(orderId, params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setLogsList', data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
    },
    mutations: {
      setLogsList(state, data) { state.logs = data || ''; },
      setLogDetails(state, {logId, data}) {
        const logsList = state.logs.logs || [];
        logsList.forEach((log, index) => {
          if (log.id === logId) {
            Vue.set(logsList[index], 'details', data);
          }
        });
      },
      setShowingDetails(state, {logId, value}) {
        const logsList = state.logs.logs || [];
        logsList.forEach((log, index) => {
          if (log.id === logId) { Vue.set(logsList[index], 'showDetails', value); }
        });
      },
      clearStore(state) {
        state.logs = {
          logs: '',
          pages: 0
        };
      }
    }
  };
};
