import 'core-js';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import Meta from 'vue-meta';
import moment from 'moment-timezone';
import GetTextPlugin from 'vue-gettext';
import Vue2TouchEvents from 'vue2-touch-events';
import {sync} from 'vuex-router-sync';
import SocialSharing from 'vue-social-sharing';
import npmTranslations from '@/modules/npm_translations';
import translations from '@/translations.json';
import {createRouter} from '@/router/ssr-router';
import {createStore} from '@/store/ssr-store';
import {createFetcher} from '@/api/fetchers';
import {createMobileTitle} from '@/router/route-mobile-titles';
import constants from '@/modules/constants';
import VueFriendlyIframe from 'vue-friendly-iframe';
// Apollo
import VueApollo from 'vue-apollo';
import {createApolloClient} from '@/graphql/apolloClient';
// FocusLoop
import VueFocusLoop from '@vue-a11y/focus-loop';
import vClickOutside from 'v-click-outside';
// ================================================================
import SkComponents, {cookieManager} from '@skiwo/sk-basic-components';
// ================================================================
import Note from '@/components/shared_components/Note';
import CustomSelect from '@/components/shared_components/CustomSelect';
import SocialLinks from '@/components/shared_components/SocialLinks';
import TermsCheck from '@/components/convention_components/TermsCheck';
import LangSelect from '@/components/shared_components/LangSelect';
import MainProgress from '@/components/shared_components/MainProgress';
import UserAvatar from '@/components/shared_components/UserAvatar';
import SecondarySelect from '@/components/shared_components/SecondarySelect';
import getProductConfig from '@/modules/productConfig';
import CompanyAvatar from '@/components/shared_components/CompanyAvatar';
import RadialProgress from '@/components/shared_components/RadialProgress';
import HorizontalProgress from '@/components/shared_components/HorizontalProgress';
import MultiselectTab from '@/components/shared_components/MultiselectTab';
import skChip from '@/components/shared_components/Chip';

import App from './App';

const availableLanguages = require('@skiwo/skiwo-available-langs');
const availableCountries = require('@skiwo/skiwo-available-countries');

const SKOptions = {
  storesRenaming: {
    skErrorsStore: 'ErrorsStore',
    skGlobalStore: 'GlobalStore',
    skInfoModalStore: 'InfoModalStore',
    skMobileComponentStore: 'MobileComponentStore',
    skModalStore: 'ModalStore',
    skSelectsStore: 'SelectsStore'
  },
  registerOnly: [
    'sk-checkbox',
    'sk-toggler',
    'sk-multiselect',
    'sk-input',
    'sk-textarea',
    'sk-radio',
    'sk-datepicker',
    'sk-timepicker',
    'sk-upload-file',
    'sk-pagination',
    'sk-tooltip',
    'sk-modal',
    'sk-info-modal',
    'sk-mob-select',
    'sk-mob-multiselect',
    'sk-mob-datepicker'
  ]
};

Vue.component('sk-note', Note);
Vue.component('sk-select', CustomSelect);
Vue.component('social-links', SocialLinks);
Vue.component('locale-select', LangSelect);
Vue.component('tt-progress', MainProgress);
Vue.component('tt-avatar', UserAvatar);
Vue.component('terms-check', TermsCheck);
Vue.component('secondary-select', SecondarySelect);
Vue.component('company-avatar', CompanyAvatar);
Vue.component('radial-progress', RadialProgress);
Vue.component('horizontal-progress', HorizontalProgress);
Vue.component('multiselect-tab', MultiselectTab);
Vue.component('sk-chip', skChip);

const gettextOptions = {
  translations: translations,
  defaultLanguage: 'nb',
  availableLanguages,
  silent: true
};
Vue.use(SkComponents, SKOptions);
Vue.use(GetTextPlugin, gettextOptions);
Vue.use(Vue2TouchEvents);
Vue.use(SocialSharing);
Vue.use(Meta);
Vue.use(VueApollo);
Vue.use(VueFriendlyIframe);
Vue.use(VueFocusLoop);
Vue.use(vClickOutside);

const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');

export function createApp({
  locale,
  country,
  userCountry,
  token,
  userInfo,
  uid,
  context,
  webSocket,
  godmode,
  mobileApp,
  filterItemsAmount,
  defaultSortBy,
  hardware,
  hostname,
  ssr
}) {
  const router = createRouter();
  const fetcher = createFetcher(locale);

  // define global properties for store
  Vue.prototype.$moment = moment;
  Vue.prototype.$fetcher = fetcher;
  let store = createStore(Vue.prototype);
  // create fetcher after store created
  Vue.prototype.$fetcher = createFetcher(locale, store);
  // update store which requires fetcher with the latest
  store = createStore(Vue.prototype);

  sync(store, router);
  SkComponents.mutateStore(store, SKOptions);

  // Apollo
  const apolloClient = createApolloClient(ssr, token);
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient
  });

  if (mobileApp !== undefined && mobileApp !== 'undefined') {
    store.commit('setMobileApp', mobileApp);
  }
  if (hardware !== undefined && hardware !== 'undefined') {
    store.commit('setHardware', hardware);
  }

  if (userCountry) {
    store.commit('setUserCountry', userCountry);
  }

  if (token && uid) {
    store.dispatch('TTAuthStore/logIn', {token, uid});
  }
  if (filterItemsAmount) {
    store.commit('setFilterItemsAmount', filterItemsAmount);
  }
  if (defaultSortBy) {
    store.commit('setDefaultSort', defaultSortBy);
  }

  if (locale) {
    Vue.config.language = locale;
    if (store.state.TTAuthStore.loggedIn) {
      fetcher.setLocale(locale);
    }
  }
  if (userInfo) {
    store.dispatch('UserInfoStore/setMainUserInfo', {
      userInfoObj: userInfo
    });
  }
  if (godmode) {
    store.commit('TTAuthStore/setGodMode', true);
  }
  router.afterEach(() => {
    if (isBrowser()) {
      store.commit('GlobalStore/remove404Page');
    }
  });

  router.beforeEach((to, from, next) => {
    const metaPresent = to.matched.some((record) => record.meta.requiresAuth || record.meta.guestOnly);
    const isMobileApp = isBrowser() ? cookieManager.getCookie('mobileApp') : store.state.mobileApp;
    const loggedInUser = isBrowser() ? !!cookieManager.getCookie('authToken') : store.state.TTAuthStore.loggedIn;
    const userObj = isBrowser()
      ? cookieManager.getCookie('userInfo')
        ? JSON.parse(cookieManager.getCookie('userInfo'))
        : {}
      : store.getters['UserInfoStore/mainUserInfo'];
    const isBuyer = userObj.role === 'buyer';
    const isEnterpriseMember = userObj.role === 'employee';
    const isNotSupplier = isBuyer || isEnterpriseMember;
    const isSupplier = userObj.role === 'server';
    const isManager = userObj.role === 'manager';
    const requiredSteps = [
      'ServerWizardEnterName',
      'BuyerWizardEnterName',
      'ServerWizardEnterPhone',
      'BuyerWizardEnterPhone',
      'BuyerWizardVerifyEmail',
      'BuyerRegisterEnterprise'
    ];
    let localLocale = to.params.lang;
    let localCountry = to.params.country;

    if (loggedInUser
      && localLocale
      && (localLocale != (locale || cookieManager.getCookie('serverLocale'))
        || localCountry != (country || cookieManager.getCookie('serverCountry')))) {
      next({
        replace: true,
        name: to.name,
        query: {...to.query},
        params: {
          ...to.params,
          lang: locale || cookieManager.getCookie('serverLocale'),
          country: country || cookieManager.getCookie('serverCountry')
        }
      });
      return;
    }
    if (isBrowser() && (!localLocale || Object.keys(availableLanguages)
      .indexOf(localLocale) === -1)) {
      localLocale = cookieManager.getCookie('serverLocale');
    } else if (!localLocale) {
      localLocale = locale;
    }
    if (isBrowser() && (!localCountry || Object.keys(availableCountries)
      .indexOf(localCountry) === -1)) {
      localCountry = cookieManager.getCookie('serverCountry');
    } else if (!localCountry) {
      localCountry = country;
    }
    if (to.fullPath
      && to.name.indexOf('Old') === -1
      && to.name.indexOf('Force') === -1) {
      const fullPath = to.fullPath.slice(1, to.fullPath.length);
      if (!fullPath || (fullPath && fullPath.split('/').length == 1)) {
        next(`/${localLocale}/${localCountry}`);
        return;
      }

      const pathArr = fullPath.split('/');
      let indicator = false;
      let landingQuery = '';
      if (pathArr.length === 2 && pathArr[1].indexOf('?') !== -1) {
        landingQuery = pathArr[1].split('?')[1];
      }
      if (Object.keys(availableLanguages)
        .indexOf(pathArr[0]) === -1) {
        pathArr[0] = localLocale;
        indicator = true;
      }
      if (Object.keys(availableCountries)
        .indexOf(pathArr[1]) === -1 && !landingQuery) {
        pathArr[1] = localCountry;
        indicator = true;
      }
      if (indicator) {
        next(`/${pathArr.join('/')}`);
        return;
      }
    }
    store.commit('ErrorsStore/removeAllErrors');
    store.commit('MobileComponentStore/closeMobileComponent');
    if (isBrowser()) {
      store.commit('setMobileTitle', createMobileTitle(to.name, cookieManager.getCookie('locale')));
    } else if (context) {
      store.commit('setMobileTitle', createMobileTitle(to.name, locale));
    }
    store.commit('toggleMobileNav', true);
    store.commit('toggleMobileDrawer', {force: true, drawerType: ''});
    if (to.meta.subPage) {
      store.commit('setSubpage');
    } else {
      store.commit('removeSubpage');
    }
    if (!store.state.mobileAppRedirectedLink) {
      store.commit('setMobileAppRedirectedLink', to.fullPath);
    }

    // if 'to' route has any rights restrictions
    if (metaPresent) {
      // if 'to' route is only for logged in users
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        // if the user is logged in
        if (loggedInUser) {
          // if 'to' route role access rights match with current user role
          // we allow navigation
          if ((to.matched.some((record) => record.meta.buyerOnly) && isNotSupplier)
            || (to.matched.some((record) => record.meta.serverOnly) && isSupplier)
            || (to.matched.some((record) => record.meta.managerOnly) && isManager)) {
            // if current user doesn't have first name or last name
            // we make a redirect to appropriate enter name wizard page
            if (!userObj.firstName
              || !userObj.lastName
              || (isSupplier && !userObj.phone.verified)) {
              if (isSupplier) {
                if (to.matched.some((record) => record.name == 'ServerWizardEnterName')
                  || (userObj.firstName
                    && userObj.lastName
                    && to.matched.some((record) => record.name == 'ServerWizardEnterPhone'))) {
                  next();
                } else if (!userObj.firstName || !userObj.lastName) {
                  next({
                    replace: true,
                    name: 'ServerWizardEnterName',
                    params: {
                      lang: localLocale,
                      country: localCountry
                    }
                  });
                } else {
                  next({
                    replace: true,
                    name: 'ServerWizardEnterPhone',
                    params: {
                      lang: localLocale,
                      country: localCountry
                    }
                  });
                }
              } else if (isNotSupplier) {
                if (to.matched.some((record) => record.name == 'BuyerWizardEnterName')
                  || (userObj.firstName
                    && userObj.lastName
                    && to.matched.some((record) => record.name == 'BuyerWizardEnterPhone'))) {
                  next();
                } else if (!userObj.firstName || !userObj.lastName) {
                  next({
                    replace: true,
                    name: 'BuyerWizardEnterName',
                    params: {lang: localLocale, country: localCountry}
                  });
                } else {
                  next({
                    replace: true,
                    name: 'BuyerWizardEnterPhone',
                    params: {
                      lang: localLocale,
                      country: localCountry
                    }
                  });
                }
              } else if (isManager) {
                if (to.matched.some((record) => record.name == 'ManagerWizardEnterName')
                  || (userObj.firstName
                    && userObj.lastName
                    && to.matched.some((record) => record.name == 'ManagerWizardEnterPhone'))) {
                  next();
                } else if (!userObj.firstName || !userObj.lastName) {
                  next({
                    replace: true,
                    name: 'ManagerWizardEnterName',
                    params: {lang: localLocale, country: localCountry}
                  });
                } else {
                  next({
                    replace: true,
                    name: 'ManagerWizardEnterPhone',
                    params: {
                      lang: localLocale,
                      country: localCountry
                    }
                  });
                }
              }
            } else {
              // If current user has first name or last name
              // we check 'to' and 'from' routes.
              //
              // If 'to' route is wizard route
              // and 'from' route in NOT wizard route
              // we make sure that 'to' route is appropriate 'enter name' wizard step
              // AND current user doesn't have first name or last name.
              //
              // Otherwise we prevent a navigation from non-wizard pages to the wizard.
              //
              // In other words we allow user to navigate from one wizard step to another
              // and prevent a navigation to wizard pages if user has already completed it.
              if (to.matched.some((record) => record.meta.wizardPage)
                && (from && !(from.matched.some((record) => record.meta.wizardPage)))) {
                if (to.matched.some((record) => requiredSteps.indexOf(record.name !== -1))
                  && (!userObj.firstName
                    || !userObj.lastName
                    || (isSupplier && !userObj.phone.verified)
                    || (isBuyer && !userObj.emailVerified))) {
                  next();
                } else {
                  // if user trying to go to the wizard pages, but all required user info have already set
                  if (isSupplier) {
                    next({
                      replace: true,
                      name: 'ServerHome',
                      params: {
                        lang: localLocale,
                        country: localCountry
                      }
                    });
                  } else if (isNotSupplier) {
                    next({
                      replace: true,
                      name: 'BuyerHome',
                      params: {
                        lang: localLocale,
                        country: localCountry
                      }
                    });
                  } else if (isManager) {
                    next({
                      replace: true,
                      name: 'ManagerAllAssignments',
                      params: {
                        lang: localLocale,
                        country: localCountry
                      }
                    });
                  }
                }
              } else if (!(to.matched.some((record) => record.meta.wizardPage))
                && isBuyer
                && !userObj.emailVerified) {
                // protect using platform if email not verificated
                if (userObj.forEnterprise) {
                  next({
                    replace: true,
                    name: 'BuyerRegisterEnterprise',
                    params: {
                      lang: localLocale,
                      country: localCountry
                    }
                  });
                } else {
                  next({
                    replace: true,
                    name: 'BuyerWizardVerifyEmail',
                    params: {
                      lang: localLocale,
                      country: localCountry
                    }
                  });
                }
              } else {
                // step for non-wizard pages
                if (to.matched.some((record) => record.meta.currentUserOnly)) {
                  if (userObj.uid == to.params.id) {
                    next();
                  } else {
                    if (isSupplier) {
                      next({
                        replace: true,
                        name: 'ServerHome',
                        params: {
                          lang: localLocale,
                          country: localCountry
                        }
                      });
                    } else if (isNotSupplier) {
                      next({
                        replace: true,
                        name: 'BuyerHome',
                        params: {
                          lang: localLocale,
                          country: localCountry
                        }
                      });
                    } else if (isManager) {
                      next({
                        replace: true,
                        name: 'ManagerAllAssignments',
                        params: {
                          lang: localLocale,
                          country: localCountry
                        }
                      });
                    }
                  }
                } else {
                  if (to.matched.some((record) => record.meta.enterpriseOnly) && !isEnterpriseMember) {
                    next({
                      replace: true,
                      name: '404',
                      params: {
                        lang: localLocale,
                        country: localCountry
                      }
                    });
                  } else if (to.matched.some((record) => record.meta.notEnterpriseOnly) && isEnterpriseMember) {
                    next({
                      replace: true,
                      name: '404',
                      params: {
                        lang: localLocale,
                        country: localCountry
                      }
                    });
                  } else {
                    next();
                  }
                }
              }

              // Check if Translation Service is allowed for current enterprise
              if (to.name === 'BuyerAllProjects' || to.name === 'BuyerPostTranslationProject') {
                next((vm) => {
                  if (vm.store.getters['UserInfoStore/allowNewTranslationServices']) next();
                  else {
                    next({
                      replace: true,
                      name: 'BuyerHome',
                      params: {
                        lang: localLocale,
                        country: localCountry
                      }
                    });
                  }
                });
              }
              // Check if Interpretation Service is allowed for current enterprise
            if (to.name === 'BuyerPostInterpretation') {
              next((vm) => {
                if (vm.store.getters['UserInfoStore/allowInterpretationServices'] || !isEnterpriseMember) next();
                else {
                  next({
                    replace: true,
                    name: 'BuyerHome',
                    params: {
                      lang: localLocale,
                      country: localCountry
                    }
                  });
                }
              });
              }
            }
          } else if ((to.matched.some((record) => record.meta.serverOnly)
            || to.matched.some((record) => record.meta.buyerOnly))
            && (userObj.role == 'rookie'
            || !userObj.role)) {
            // if 'to' route is specific for roles
            // but the current user doesn't have any of it
            // we force the user to choose a role
            // TODO: improve 'userObj.role' condition
            next({
              replace: true,
              name: 'WizardChooseRole',
              params: {
                lang: localLocale,
                country: localCountry
              }
            });
          } else if (to.matched.some((record) => record.meta.emptyRoleOnly)
            && !isSupplier
            && !isNotSupplier) {
            // if 'to' route is ChooseRole route
            // and current user doesn't have allowed role
            // we allow the navigation
            next();
          } else if (to.matched.some((record) => record.meta.emptyRoleOnly)
            && (isSupplier || isNotSupplier || isManager)) {
            // if 'to' route is ChooseRole route
            // and current user already have allowed role
            // we make a redirect to specific for current user's role home page
            if (isSupplier) {
              next({
                replace: true,
                name: 'ServerHome',
                params: {
                  lang: localLocale,
                  country: localCountry
                }
              });
            } else if (isNotSupplier) {
              next({
                replace: true,
                name: 'BuyerHome',
                params: {
                  lang: localLocale,
                  country: localCountry
                }
              });
            } else if (isManager) {
              next({
                replace: true,
                name: 'ManagerAllAssignments',
                params: {
                  lang: localLocale,
                  country: localCountry
                }
              });
            }
          } else {
            // if the user try to reach the page
            // which does not exist or for another role
            // we make a redirect to the 404 page
            next({
              replace: true,
              name: '404',
              params: {
                lang: localLocale,
                country: localCountry
              }
            });
          }
        } else {
          // if the user is not logged in
          // we make a redirect to the sign in page
          next({
            replace: true,
            name: 'SignIn',
            params: {
              lang: localLocale,
              country: localCountry
            }
          });
        }
        // if 'to' route for all users
      } else {
        // if 'to' route is for not logged in users only
        if (to.matched.some((record) => record.meta.guestOnly)) {
          // we check is current user logged in or not
          // if yes we make a redirect to specific for current user's role home page
          if (loggedInUser) {
            if (to.name == 'SignIn'
              && to.query.token
              && to.query.uid
              && to.query.godmode) {
              store.commit('TTAuthStore/logOut');
              store.commit('TTAuthStore/setGodMode', true);
              next();
              // allow redirection to sign-in for logged-in-user if it's punchout login URL
            } else if (to.name == 'SignIn'
              && to.query.token
              && to.query.punch_out_redirect_url) {
              store.commit('TTAuthStore/logOut');
              next();
            } else if (isSupplier) {
              next({
                replace: true,
                name: 'ServerHome',
                params: {
                  lang: localLocale,
                  country: localCountry
                }
              });
            } else if (isNotSupplier) {
              if (to.matched.some((record) => ['OpenBookingInterpretation', 'OpenBookingInterpretationNb'].includes(record.name))) {
                next({
                  replace: true,
                  name: 'BuyerPostInterpretation',
                  params: {
                    lang: localLocale,
                    country: localCountry
                  }
                });
              } else if (to.matched.some((record) => ['OpenBookingTranslation', 'OpenBookingTranslationNb'].includes(record.name))) {
                next({
                  replace: true,
                  name: 'BuyerPostTranslationProject',
                  params: {
                    lang: localLocale,
                    country: localCountry
                  }
                });
              } else {
                next({
                  replace: true,
                  name: 'BuyerHome',
                  params: {
                    lang: localLocale,
                    country: localCountry
                  }
                });
              }
            } else if (isManager) {
              next({
                replace: true,
                name: 'ManagerAllAssignments',
                params: {
                  lang: localLocale,
                  country: localCountry
                }
              });
            } else {
              next({
                replace: true,
                name: 'WizardChooseRole',
                params: {
                  lang: localLocale,
                  country: localCountry
                }
              });
            }
            // if this route should be redirected to their Salita counterpart
          } else if (Vue.prototype.$getMarketplaceName() === 'tikktalk') {
            if (!ssr) {
              const currentHost = window.location.host;
              const protocol = window.location.protocol;
              const host = currentHost.replace('tikktalk.com', 'salita.no');
              const redirectedLink = `${protocol}//${host}${to.path}`;
              redirectedLink !== window.location.href ? window.location.replace(redirectedLink) : next();
            } else {
              next();
            }
            // redirect from landing to salita pre-login page
          } else if (to.name == 'Landing' && Vue.prototype.$getMarketplaceName() !== 'tikktalk') {
            if (!ssr && constants.EXTERNAL_LOGIN_PAGE && !isMobileApp && Vue.prototype.$getMarketplaceName() === 'salita') {
              window.location.replace(constants.EXTERNAL_LOGIN_PAGE);
            } else {
              next({
                replace: true,
                name: 'SignIn',
                params: {
                  lang: localLocale,
                  country: localCountry
                }
              });
            }
            // if current user is not logged in we allow to visit guest only page
          } else {
            next();
          }
        } else {
          // TODO: why we make a redirect to sign in page? Should we allow user to visit requested page (use simple next())?
          next({
            replace: true,
            name: 'SignIn',
            params: {
              lang: localLocale,
              country: localCountry
            }
          });
        }
      }
    } else {
      // simple redirect path
      if (to.name === 'PreferencesNotifications') {
        const prefix = userObj.role === 'server' ? 'Server' : 'Buyer';
        next({
          replace: true,
          name: `${prefix}PreferencesNotifications`,
          params: {
            lang: localLocale,
            country: localCountry
          }
        });
      } else if (!ssr && Vue.prototype.$getMarketplaceName() === 'tikktalk') {
        const currentHost = window.location.host;
        const protocol = window.location.protocol;
        const host = currentHost.replace('tikktalk.com', 'salita.no');
        const redirectedLink = `${protocol}//${host}${to.path}`;
        redirectedLink !== window.location.href ? window.location.replace(redirectedLink) : next();
      } else {
        // if the page doesn't have restrictions
        // we simply allow navigation to the route
        next();
      }
    }
  });

  // set saved timezome from server as default
  moment.tz.setDefault(store.getters['UserInfoStore/userServerTimeZoneName']);
  Vue.prototype.$moment = moment;

  Vue.prototype.$getLangName = function getLangName(id) {
    if (this.$store.state.selectsData.language && this.$store.state.selectsData.language.length) {
      for (const lang of this.$store.state.selectsData.language) {
        if (lang.id == id) {
          return lang.name;
        }
      }
    }
  };
  Vue.prototype.$isGodmode = function getGodmodeState() {
    if (this.$store.state.TTAuthStore) {
      return this.$store.state.TTAuthStore.godmode;
    }
  };
  Vue.prototype.$timepickerStep = function getTimepickerStep() {
    return constants.TIMEPICKER_STEP;
  };
  Vue.prototype.$getMarketplaceName = function getMarketplaceName() {
    return 'salita';
  };
  Vue.prototype.$goToExternalLoginPage = function goToExternalLoginPage() {
    if (window) {
      if (constants.EXTERNAL_LOGIN_PAGE && Vue.prototype.$getMarketplaceName === 'salita') {
        window.location.href = constants.EXTERNAL_LOGIN_PAGE;
      } else {
        window.location.reload();
      }
    }
  };
  Vue.prototype.$isOnDomain = function detectCurrentDomain(provider) {
    let host = isBrowser() ? window.location.hostname : hostname;
    const domain = provider == 'skiwo' ? 'tikktalk' : provider;
    host = host === 'localhost' ? domain : host;
    return host.indexOf(domain) !== -1;
  };
  Vue.prototype.$npmTranslations = npmTranslations;
  Vue.prototype.$webSocket = webSocket;
  Vue.prototype.$makeRoute = function makeRoute(obj) {
    const res = {};
    if (obj.name) {
      res.name = obj.name;
    }
    if (obj.path) {
      res.path = obj.path;
    }
    if (obj.query) {
      res.query = {...obj.query};
    }
    res.params = {...obj.params, lang: this.$route.params.lang, country: this.$route.params.country};
    return res;
  };

  const app = new Vue({
    router,
    store,
    apolloProvider,
    render: (h) => h(App)
  });

  Vue.prototype.$getString = getProductConfig.bind(this, Vue.prototype.$gettext, Vue.prototype.$getMarketplaceName() || process.env.MARKETPLACE);
  Vue.prototype.$getProviderInfo = getProductConfig.bind(this, Vue.prototype.$gettext);

  return {app, router, store, apolloProvider};
}
