<script>
  import {mapState, mapActions} from 'vuex';
  import PostingTogglePrototype from '@/prototypes/PostingTogglePrototype';

  export default {
    extends: PostingTogglePrototype,
    computed: {
      ...mapState('OneAssignmentStore', {
        processRequirement: ({storeJobObj}) => storeJobObj.processRequirement || {}
      }),
      toggleValue() { return !!this.processRequirement.infiniteAutoInvite; },
      toggleDescription() { return this.$gettext('Forever'); }
    },
    methods: {
      ...mapActions('OneAssignmentStore', ['updateInfiniteAutoInvite']),
      toggleHandler(value) {
        this.progressActive = true;
        this.updateInfiniteAutoInvite(value).then(() => {
          this.progressActive = false;
        }).catch(() => {
          this.progressActive = false;
        });
      }
    }
  };
</script>
