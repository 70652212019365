export default {
  path: 'assignments',
  component: () => import('@/pages/ManagerPages/assignment_pages/m_AssignmentsRoot'),
  children: [
    {
      path: '/',
      redirect: {name: 'ManagerAllAssignments'}
    },
    {
      path: 'all',
      name: 'ManagerAllAssignments',
      meta: {subPage: true},
      component: () => import('@/pages/ManagerPages/assignment_pages/m_AllAssignments')
    },
    {
      path: 'all/:id',
      name: 'ManagerOneAssignment',
      meta: {subPage: true, viewers: true},
      component: () => import('@/pages/shared_pages/assignment_pages/sh_OneInterpretation'),
      props: true
    }
  ]
};
