export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      services: ''
    },
    actions: {
      getOtherServices({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.fetchOtherServices().then((response) => {
            commit('setOtherServices', response);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      createOtherServicesBooking(context, form) {
        return new Promise((resolve, reject) => {
          fetchData.createOtherServicesBooking(form).then(() => {
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
    },
    mutations: {
      setOtherServices(state, value) {
        state.services = value;
      },
      clearStore(state) {
        state.services = '';
      }
    }
  };
};
