import BookingHelper from '@/store/booking_stores/BookingHelper';

export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      organizationName: '',
      organizations: '',
      organizationsProgress: false,
      departmentsList: '',
      emailExisting: false,
      emailWhitelisted: false,
      departmentsProgress: false,
      openBooking: {
        isExistingCustomer: true,
        openBookingType: '',
        organization: '',
        department: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneCode: '+47',
        phoneNumber: '',
        bookingRef: '',
        paymentBookingRef: '',
        caseNumber: '',
        orgNumber: '',
        paymentCompany: '',
        paymentOrgNumber: '',
      },
      errors: {
        organization: {name: 'organizationError', errors: []},
        departments: {name: 'departmentsError', errors: []},
        caseNumber: {name: 'caseNumberError', errors: []},
        bookingRef: {name: 'bookingRefError', errors: []},
        paymentBookingRef: {name: 'paymentBookingRefError', errors: []},
        firstName: {name: 'firstNameError', errors: []},
        lastName: {name: 'lastNameError', errors: []},
        email: {name: 'emailError', errors: []},
        phoneCode: {name: 'phoneCodeError', errors: []},
        phoneNumber: {name: 'phoneNumberError', errors: []},
        orgNumber: {name: 'orgNumberError', errors: []},
        paymentCompany: {name: 'paymentCompanyError', errors: []},
        paymentOrgNumber: {name: 'paymentOrgNumberError', errors: []},
      }
    },
    getters: {
      bookingFormProgress(state) {
        const {organizationsProgress, departmentsProgress} = state;
        return organizationsProgress || departmentsProgress;
      },
      organizationsList(state) {
        return state.organizations ? state.organizations[state.organizationName] || [] : [];
      },
      currentOrgNumber(state, getters) {
        const organizationId = state.openBooking.organization;
        const organizationsList = getters.organizationsList;

        if (organizationsList.length && organizationId && organizationId !== 'other') {
          return organizationsList.find((org) => org.id == organizationId)?.orgNumber || '';
        }
        return state.openBooking.orgNumber;
      }
    },
    actions: {
      getOrganizations({commit}) {
        commit('setEnterprisesProgress', true);
        return new Promise((resolve, reject) => {
          fetchData.getOrganizations().then((response) => {
            commit('setEnterprises', response);
            commit('setEnterprisesProgress', false);
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getDepartments({commit}, enterpriseId) {
        commit('setDepartmentsProgress', true);
        return new Promise((resolve) => {
          fetchData.fetchSelectsData('departments', enterpriseId).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setDepartments', data);
            commit('setDepartmentsProgress', false);
            resolve(data);
          }).catch(() => {
            commit('setDepartments', []);
            commit('setDepartmentsProgress', false);
            resolve([]);
          });
        });
      },
      checkEmailExist({commit}, email) {
        return new Promise((resolve) => {
          fetchData.checkEmailExist(email).then((data) => {
            commit('setEmailExisting', data.exists);
            commit('setWhitelisted', data.whitelisted);
            resolve(data);
          }).catch(() => {
            commit('setEmailExisting', false);
            commit('setWhitelisted', false);
            resolve('');
          });
        });
      },
      updateOpenBookingData({commit, dispatch}, data) {
        if (data) {
          commit('setOpenBookingData', data);
          if (data.organization && data.organization !== 'other') {
            dispatch('OpenBookingStore/getDepartments', data.organization, {root: true});
            dispatch('PostingStore/SubjectStore/getCategories', {
              enterpriseId: data.organization,
              departmentId: data.department,
              organizationName: data.openBookingType,
              isOpenBooking: true
            }, {root: true});
          }
        }
      },
      catchOrganization({commit, dispatch}, {enterpriseId, organizationName}) {
        commit('setOrganization', enterpriseId);
        commit('setOrgNumber', '');
        commit('setDepartment', '');
        commit('setDepartments', '');
        commit('executeNotTrackedMutation', {
          mutationName: 'PostingStore/SubjectStore/setCategory',
          payload: ''
        }, {root: true});
        commit('PostingStore/SubjectStore/setCategories', '', {root: true});
        if (enterpriseId && enterpriseId !== 'other') {
          dispatch('OpenBookingStore/getDepartments', enterpriseId, {root: true});
          dispatch('PostingStore/SubjectStore/getCategories', {
            enterpriseId,
            departmentId: '',
            organizationName,
            isOpenBooking: true
          }, {root: true});
        }
      },
      catchDepartment({commit, dispatch}, {departmentId, enterpriseId, organizationName}) {
        commit('setDepartment', departmentId);
        commit('PostingStore/SubjectStore/setCategories', '', {root: true});
        dispatch('PostingStore/SubjectStore/getCategories', {departmentId, enterpriseId, isOpenBooking: true, organizationName}, {root: true});
      },
      setError({state, commit}, {fieldName, errorText, typeError}) {
        commit('PostingStore/setValidationResult', typeError, {root: true});
        commit('setError', {fieldName, errorText});
        commit('ErrorsStore/setError', {name: state.errors[fieldName].name, errors: state.errors[fieldName].errors}, {root: true});
      },
      removeErrors({commit}) {
        commit('removeErrors');
        commit('ErrorsStore/removeAllErrors', null, {root: true});
      }
    },
    mutations: {
      ...BookingHelper().mutations,
      setEmailExisting(state, value) { state.emailExisting = value; },
      setWhitelisted(state, value) { state.emailWhitelisted = value; },
      setOrganizationName(state, value) { state.organizationName = value; },
      setEnterprises(state, data) { state.organizations = data; },
      setEnterprisesProgress(state, data) { state.organizationsProgress = data; },
      setDepartments(state, data) { state.departmentsList = data; },
      setDepartmentsProgress(state, data) { state.departmentsProgress = data; },
      setIsExistingCustomerValue(state, value) { state.openBooking.isExistingCustomer = value; },
      setOrgNumber(state, value) { state.openBooking.orgNumber = value; },
      setOpenBooking(state, value) { state.openBooking.openBookingType = value; },
      setFirstName(state, value) { state.openBooking.firstName = value; },
      setLastName(state, value) { state.openBooking.lastName = value; },
      setEmail(state, value) { state.openBooking.email = value; },
      setPhoneCode(state, value) { state.openBooking.phoneCode = value; },
      setPhoneNumber(state, value) { state.openBooking.phoneNumber = value; },
      setBookingRef(state, value) { state.openBooking.bookingRef = value; },
      setPaymentBookingRef(state, value) { state.openBooking.paymentBookingRef = value; },
      setOrganization(state, value) { state.openBooking.organization = value; },
      setDepartment(state, value) { state.openBooking.department = value; },
      setCaseNumber(state, value) { state.openBooking.caseNumber = value; },
      setPaymentCompany(state, value) { state.openBooking.paymentCompany = value; },
      setPaymentOrgNumber(state, value) { state.openBooking.paymentOrgNumber = value; },
      // SET OPEN BOOKING DATA FROM COOKIE
      setOpenBookingData(state, data) {
        state.openBooking.isExistingCustomer = data.isExistingCustomer || true;
        state.openBooking.organization = data.organization || '';
        state.openBooking.department = data.department || '';
        state.openBooking.firstName = data.firstName || '';
        state.openBooking.lastName = data.lastName || '';
        state.openBooking.phoneCode = data.phoneCode || '+47';
        state.openBooking.phoneNumber = data.phoneNumber || '';
        state.openBooking.email = data.email || '';
        state.openBooking.bookingRef = data.bookingRef || '';
        state.openBooking.paymentBookingRef = data.paymentBookingRef || '';
        state.openBooking.caseNumber = data.caseNumber || '';
        state.openBooking.orgNumber = data.orgNumber || '';
        state.openBooking.paymentCompany = data.paymentCompany || '';
        state.openBooking.paymentOrgNumber = data.paymentOrgNumber || '';
      },
      resetOpenBookingJobInfo(state) {
        state.openBooking = {
          isExistingCustomer: true,
          openBookingType: '',
          organization: '',
          department: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneCode: '+47',
          phoneNumber: '',
          bookingRef: '',
          paymentBookingRef: '',
          caseNumber: '',
          orgNumber: '',
          paymentCompany: '',
          paymentOrgNumber: ''
        };
      },
      clearStore(state) {
        state.organizationName = '';
        state.organizations = '';
        state.organizationsProgress = false;
        state.departmentsList = '';
        state.emailExisting = false;
        state.emailWhitelisted = false;
        state.departmentsProgress = false;
        state.openBooking = {
          isExistingCustomer: true,
          openBookingType: '',
          organization: '',
          department: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneCode: '+47',
          phoneNumber: '',
          bookingRef: '',
          paymentBookingRef: '',
          caseNumber: '',
          orgNumber: '',
          paymentCompany: '',
          paymentOrgNumber: ''
        };
      }
    }
  };
};
