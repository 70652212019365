const subtask = {
  methods: {
    getAvailableSubtasks(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/dashboards/subtasks/invited', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    getAcceptedSubtasks(params) {
      return new Promise((resolve, reject) => {
        this.ajax.get('/v1/dashboards/subtasks/accepted', {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
    requestNewDeadline(params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/deadline_change_requests', params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    changeSubtaskStatus(params) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put(`/v1/subtasks/${params.id}/change_status`, params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    sendSubtaskAttachment(id, attachments) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.post(`/v1/subtasks/${id}/attachments`, attachments).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    deleteAttachment(params) {
      const self = this;
      return new Promise((resolve, reject) => {
        self.ajax.delete(`/v1/subtasks/${params.subtask_id}/attachments/${params.id}`, params).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    assignManagerToSingleSubtask(id, data) {
      return new Promise((resolve, reject) => {
        this.ajax.patch(`/v1/subtasks/${id}/assign_manager`, data).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          this.resolveError403(error, resolve);
          reject(error);
        });
      });
    },
  }
};

export default subtask;
