const profiles = {
  methods: {
    // ==================================================
    // ==================================================
    // PROFILE CALLS
    // ==================================================
    // ==================================================
    getUserProfile({id, country, slug}) {
      const self = this;
      let path = '';
      if (id) {
        path = `/v1/profiles/${id}`;
      } else {
        path = `/v1/${country}/${slug}`;
      }
      return new Promise((resolve) => {
        self.airbrakenAjax.get(path).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          const resp = error?.response || {};
          this.resolveError403(error, resolve, `Get profile with params id: ${id}, country: ${country} and slug ${slug} responded with ${resp?.status} code`);
          resolve({code: resp?.status});
        });
      });
    },
    checkAvailableSlug(slug) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get('/v1/slugs/available', {params: {slug}}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    changeSlug(form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.put('/v1/slugs', form).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    sendUpdatedRelation(form) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.post('/v1/person_relations', form).then(() => {
          resolve();
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    removeRelation(id) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.delete(`/v1/person_relations/${id}`).then(() => {
          resolve();
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    },
    getInterpreterFeedbackLogout({country, slug, params}) {
      const self = this;

      return new Promise((resolve, reject) => {
        self.ajax.get(`/v1/${country}/${slug}/public_feedbacks`, {params}).then((response) => {
          resolve(response?.data);
        }).catch((error) => {
          reject(error?.data || {});
        });
      });
    }
  }
};

export default profiles;
