const fileType = (fileType) => {
  const type = fileType.split('/')[0];
  if (type === 'image') return 'image';
  else if (type === 'audio') return 'audio';
  else return 'document';
};

export default {
  fileType
};
