import BookingHelper from '@/store/booking_stores/BookingHelper';

export default () => {
  return {
    namespaced: true,
    state: {
      directProcessing: '',
      sessionTypeProcessing: '', // flag values session_type_auto_update or session_type_cancel_not_found [Update or Cancel]
      autoUpdateToSessionType: '', // values video or phone [To which session type assignment will be updated]
      genderProcessing: '',
      qualificationProcessing: '',
      severalSuppliersProcessing: '',
      seriesJobProcessing: '',
      autoUpdateProcessingType: '', // bounded to radio button. Values video, phone, or session_type_cancel_not_found
      errors: {
        sessionTypeProcessing: {name: 'sessionTypeProcessingError', errors: []},
        directProcessing: {name: 'directProcessingError', errors: []},
        genderProcessing: {name: 'genderProcessingError', errors: []},
        qualificationProcessing: {name: 'qualificationProcessingError', errors: []},
        severalSuppliersProcessing: {name: 'severalSuppliersProcessingError', errors: []},
        autoUpdateToSessionType: {name: 'autoUpdateToSessionType', errors: []}
      }
    },
    getters: {
      userNeedSpecificQualificationLevel(state, getters, rootState) {
        return rootState.PostingStore.QualificationStore.qualificationSelect && state.qualificationProcessing !== 'qualification_auto_update';
      }
    },
    mutations: {
      ...BookingHelper().mutations,
      setDirectProcessing(state, value) {
        state.directProcessing = value;
      },
      setSessionTypeProcessing(state, value) {
        state.sessionTypeProcessing = value;
      },
      setAutoUpdateToSessionType(state, value) {
        state.autoUpdateToSessionType = value;
      },
      setGenderProcessing(state, value) {
        state.genderProcessing = value;
      },
      setQualificationProcessing(state, value) {
        state.qualificationProcessing = value;
      },
      setSeveralSuppliersProcessing(state, value) {
        state.severalSuppliersProcessing = value;
      },
      setSeriesJobProcessing(state, value) {
        state.seriesJobProcessing = value;
      },
      setAutoUpdateProcessingType(state, value) {
        state.autoUpdateProcessingType = value;
      },
      clearStore(state) {
        state.directProcessing = '';
        state.sessionTypeProcessing = '';
        state.genderProcessing = '';
        state.qualificationProcessing = '';
        state.severalSuppliersProcessing = '';
        state.seriesJobProcessing = '';
        state.autoUpdateToSessionType = '';
        state.autoUpdateProcessingType = '';
      }
    }
  };
};
