// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/salita/undefined_imgs/warning_icon_red.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.direct-booking__content[data-v-400817a7] {\n  margin-top: 15px;\n}\n.direct-booking__search-by-description[data-v-400817a7] {\n  margin-bottom: 15px;\n}\n.direct-booking__warning[data-v-400817a7] {\n  margin-top: 10px;\n}\n.direct-booking__availability[data-v-400817a7] {\n  padding-top: 25px;\n}\n.direct-booking__deadline-warning[data-v-400817a7] {\n  padding-left: 18px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 17px;\n  background-repeat: no-repeat;\n  color: #f04;\n  font-weight: bold;\n  font-size: 12px;\n  line-height: 16px;\n}\n", ""]);
// Exports
module.exports = exports;
