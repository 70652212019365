import BookingHelper from '@/store/booking_stores/BookingHelper';

export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      languageFrom: '',
      languageTo: '',
      alternativeTargetLanguage: '',
      alternativeLanguageToSelect: false,
      alternativeLanguageTo: '',
      targetLanguages: [],
      top10languages: '',
      nonLatinLanguage: false,
      showNotLatinError: false,
      noSourceLang: false,
      errors: {
        langFrom: {name: 'langFromError', errors: []},
        langTo: {name: 'langToError', errors: []},
        targetLangs: {name: 'targetLangsError', errors: []},
        alternativeLangTo: {name: 'alternativeLangToError', errors: []},
        nonLatinLang: {name: 'nonLatinLangError', errors: []}
      }
    },
    actions: {
      changeLangFrom({commit, dispatch}, langId) {
        commit('setLangFrom', langId);
        dispatch('PostingNotifierStore/langFromChanged', null, {root: true});
      },
      changeLangTo({commit, dispatch}, langId) {
        commit('setLangTo', langId);
        dispatch('PostingNotifierStore/langToChanged', null, {root: true});
      },
      changeAlternativeLangToSelectState({commit}, value) {
        commit('setAlternativeLangToSelectState', value);
        commit('setAlternativeLangTo', '');
      },
      getTopLanguages({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.fetchTopLanguages().then((data) => {
            commit('setTopLanguages', data);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      getTopTranslationLanguages({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.fetchTopTranslationLanguages().then((data) => {
            commit('setTopLanguages', data);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      // changeTargetLangs({commit}, langIds) {
      //   commit('setTargetLangs', langIds);
      //   TODO: Investigate Dispatch to PostingNotifierStore if needed
      //   dispatch('PostingNotifierStore/langToChanged', null, {root: true});
      // },
    },
    mutations: {
      ...BookingHelper().mutations,
      setLangFrom(state, value) {
        state.languageFrom = value;
      },
      setAlternativeTargetLanguage(state, value) {
        state.alternativeTargetLanguage = value;
      },
      setNoSourceLang(state, value) {
        state.noSourceLang = value;
      },
      setTopLanguages(state, value) {
        state.top10languages = value;
      },
      setLangTo(state, value) {
        state.languageTo = value;
      },
      setTargetLangs(state, value) {
        state.targetLanguages = value;
      },
      setAlternativeLangToSelectState(state, value) {
        state.alternativeLanguageToSelect = value;
      },
      setAlternativeLangTo(state, value) {
        state.alternativeLanguageTo = value;
      },
      setNotLatinError(state, value) {
        state.showNotLatinError = value;
      },
      setUsesLatinAlphabet(state, value) {
        state.nonLatinLanguage = value;
      },
      clearStore(state) {
        state.alternativeTargetLanguage = '';
        state.languageFrom = '';
        state.languageTo = '';
        state.targetLanguages = [];
        state.alternativeLanguageTo = '';
        state.alternativeLanguageToSelect = false;
        state.showNotLatinError = false;
        state.nonLatinLanguage = false;
        state.noSourceLang = false;
      }
    }
  };
};
