export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      pastProjects: '',
      activeProjects: '',
      progressActive: false,
      managerProjectSubtasks: '',
      updateManagerSubtasksGrid: '',
      filterOrderId: '',
      activeProjectsRequested: false,
      pastProjectsRequested: false
    },
    getters: {
      activeProjects(state) {
        return state.activeProjects.orders || [];
      },
      pastProjects(state) {
        return state.pastProjects.orders || [];
      },
      activeProjectsPages(state) {
        return state.activeProjects.pages || 0;
      },
      pastProjectsPages(state) {
        return state.pastProjects.pages || 0;
      }
    },
    actions: {
      fetchPastProjects({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getPastTranslationProjects(params).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setPastProjects', response);
            commit('stopProjectProgress');
            resolve();
          }).catch((error) => {
            commit('stopProjectProgress');
            reject(error);
          });
        });
      },
      fetchActiveProjects({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getActiveTranslationProjects(params).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setActiveProjects', response);
            commit('stopProjectProgress');
            resolve();
          }).catch((error) => {
            commit('stopProjectProgress');
            reject(error);
          });
        });
      },
      getManagerSubtasks({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getManagerSubtasks(params).then((response) => {
            if (response.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setManagersProjectSubtasks', response);
            const resFormatted = response;
            resFormatted.data = response.subtasks;
            resolve(resFormatted);
          }).catch(() => {
            reject();
          });
        });
      },
    },
    mutations: {
      setActiveProjectsRequested(state, value) {
        state.activeProjectsRequested = value;
      },
      setPastProjectsRequested(state, value) {
        state.pastProjectsRequested = value;
      },
      setAllMyAssignments(state, data) {
        state.myAllAssignments = data;
      },
      setActiveProjects(state, data) {
        state.activeProjects = data;
      },
      setPastProjects(state, data) {
        state.pastProjects = data;
      },
      setManagersProjectSubtasks(state, data) {
        state.managerProjectSubtasks = data;
      },
      removeActiveProjects(state) {
        state.activeProjects = [];
      },
      removePastProjects(state) {
        state.pastProjects = [];
      },
      removeAllProjectsData(state) {
        state.pastProjects = [];
        state.activeProjects = [];
      },
      startProjectProgress(state) {
        state.progressActive = true;
      },
      stopProjectProgress(state) {
        state.progressActive = false;
      },
      refreshSubtasksGrid(state) {
        state.updateManagerSubtasksGrid = Date.now();
      },
      setFilterOrderId(state, value) {
        state.filterOrderId = value;
      },
      clearStore(state) {
        state.pastProjects = '';
        state.activeProjects = '';
        state.progressActive = false;
        state.managerProjectSubtasks = '';
        state.updateManagerSubtasksGrid = '';
        state.filterOrderId = '';
      }
    }
  };
};
