(<template>
  <article class="export-confirmation">
    <section class="export-confirmation__wrapper">
      <p class="export-confirmation__datepicker-title">{{ $gettext('Date range') }}</p>
      <date-range-picker
        :date-range="dateRange"
        class="export-confirmation__datepicker"
        :config="dateRangeConfig"
        @update="onUpdate" />
      <sk-note class="export-confirmation__export-note"
               :text="exportNoteText" />
      <div class="export-confirmation__btn-cont">
        <button v-if="modalCancelBtnText"
                class="sk-btn sk-btn--white export-confirmation__btn export-confirmation__btn--cancel"
                type="button"
                @click.prevent="closeModal">{{ modalCancelBtnText }}</button>
        <button class="sk-btn sk-btn--white export-confirmation__btn export-confirmation__btn--remove"
                :disabled="!dateRange.startDate"
                type="button"
                @click.prevent="confirm">{{ modalRemoveBtnText || $gettext('Export') }}</button>
      </div>
    </section>
  </article>
</template>)

<script>
  import DateRangePicker from '@/components/shared_components/DateTimeRangePicker';

  export default {
    components: {
      DateRangePicker,
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        dateRange: {
          startDate: '',
          endDate: '',
        },
        dateRangeConfig: {
          appendToBody: true,
          opens: 'right'
        }
      };
    },
    computed: {
      exportNoteText() {
        return this.$gettext('Please note that your export runs in the background and might take a while. We\'ll notify you through email once it\'s completed.');
      },
      modalData() {
        return this.data || {};
      },
      modalText() {
        return this.data.text || '';
      },
      modalBtnText() {
        return this.data.btnTexts;
      },
      modalCallback() {
        return this.modalData.modalCallback;
      },
      modalCallbackParams() {
        return this.modalData.callbackParams;
      },
      modalRemoveBtnText() {
        return this.modalBtnText.submitBtnText || '';
      },
      modalCancelBtnText() {
        return this.modalBtnText.cancelBtnText || '';
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$store.dispatch('ModalStore/closeModal');
      },
      confirm() {
        this.modalCallback({...this.dateRange});
        this.closeModal();
      },
      onUpdate(data) {
        this.dateRange = data;
      }
    }
  };
</script>

<style scoped>
.export-confirmation {
  padding: 10px 35px 30px;
}

.export-confirmation__datepicker {
  width: 100%;
}

.export-confirmation__export-note {
  margin: 15px 0;
}

.export-confirmation__datepicker-title {
  margin-top: 15px;
}

.export-confirmation__btn-cont {
  display: flex;
  justify-content: flex-end;
}

.export-confirmation__btn {
  width: auto;
  padding: 0 15px;
}

.export-confirmation__btn--cancel {
  margin-right: 15px;
}
</style>
