<script>
  import {mapState, mapMutations, mapActions} from 'vuex';
  import InteractiveSearchSelect from '@/components/shared_components/form_fields/InteractiveSearchSelect';

  export default {
    extends: InteractiveSearchSelect,
    computed: {
      ...mapState('PostingStore/DirectBookingStore', {
        searchQuery: ({supplierSearchQuery}) => supplierSearchQuery
      })
    },
    methods: {
      ...mapActions({
        searchSupplierByName: 'PostingStore/DirectBookingStore/searchSupplierByName',
        cancelSearch: 'PostingStore/DirectBookingStore/cancelSearchInterpreters'
      }),
      ...mapMutations({
        setSearchQueryMutation: 'PostingStore/DirectBookingStore/setSupplierSearchQuery'
      }),
      submitAction(payload) {
        // trigger search supplier after 3 letters
        if (this.searchQuery && this.searchQuery.length > 2) return this.searchSupplierByName(payload);
        else return Promise.resolve();
      }
    }
  };
</script>
