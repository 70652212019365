export default ({$fetcher: fetchData, $moment: moment}) => {
  const initPrivateEvent = {
    finishDate: moment().format('YYYY-MM-DD'),
    startDate: moment().format('YYYY-MM-DD'),
    startTime: '00:00',
    finishTime: '00:15',
    eventType: 1,
    title: '',
    allDay: false
  };

  return {
    namespaced: true,
    state: {
      privateEvents: '',
      privateEvent: {},
      standByPrivateEvent: {
        sessionType: '',
        travelMethod: '',
        distance: ''
      },
      errors: {
        title: {name: 'titleValidation', errors: []},
        startTime: {name: 'startTimeValidation', errors: []},
        sessionType: {name: 'sessionTypeValidation', errors: []},
        travelMethod: {name: 'travelMethodValidation', errors: []},
        distance: {name: 'distanceValidation', errors: []},
      }
    },
    getters: {
      privateEvents(state) {
        if (Array.isArray(state.privateEvents)) {
          return state.privateEvents.filter((event) => event.eventType === 1);
        }
        return [];
      },
      standByEvents(state) {
        if (Array.isArray(state.privateEvents)) {
          return state.privateEvents.filter((event) => event.eventType === 3);
        }
        return [];
      },
      showTravelFields(state) {
        return state.standByPrivateEvent.sessionType === 'on_site';
      }
    },
    actions: {
      fetchPrivateEvents({commit, rootGetters}) {
        return new Promise((resolve, reject) => {
          fetchData.fetchPrivateEvents(rootGetters['UserInfoStore/userUid']).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page');
              resolve('LOGOUT');
              return;
            }
            commit('setPrivateEvents', data.events);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      removePrivateEvent({commit}, eventId) {
        return new Promise((resolve, reject) => {
          fetchData.removePrivateEvent(eventId).then((data) => {
            commit('setPrivateEvents', data.events);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      savePrivateEvent({state, commit, getters}, isForceSubmit) {
        const {privateEvent} = state;
        return new Promise((resolve, reject) => {
          const form = new FormData();
          const startTime = moment(`${privateEvent.startDate} ${privateEvent.startTime}`);
          const finishTime = moment(`${privateEvent.finishDate} ${privateEvent.finishTime}`);
          form.append('event[title]', privateEvent.title);
          form.append('event[start_time]', startTime);
          form.append('event[finish_time]', finishTime);
          form.append('event[address]', '');
          form.append('event[event_type]', privateEvent.eventType);
          form.append('event[all_day]', privateEvent.allDay);
          form.append('event[description]', '');
          form.append('event[force]', isForceSubmit);
          // standByPrivateEvent
          const {sessionType, travelMethod, distance} = state.standByPrivateEvent;
          if (sessionType) form.append('event[session_type]', sessionType);
          if (travelMethod && getters.showTravelFields) form.append('event[travel_type]', travelMethod);
          if (distance && getters.showTravelFields) form.append('event[distance]', distance);
          fetchData.savePrivateEvent(form).then((data) => {
            commit('setPrivateEvent', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      }
    },
    mutations: {
      setError(state, {fieldName, errorText}) {
        state.errors[fieldName].errors = [...state.errors[fieldName].errors, errorText];
      },
      removeErrors(state) {
        for (const key of Object.keys(state.errors)) {
          state.errors[key].errors = [];
        }
      },
      setPrivateEvents(state, data) { state.privateEvents = data; },
      setStartDate(state, data) {
        state.privateEvent.startDate = data;
        const startDate = moment(state.privateEvent.startDate);
        const finishDate = moment(state.privateEvent.finishDate);
        if (finishDate.diff(startDate, 'days') < 0) {
          state.privateEvent.finishDate = data;
        }
      },
      setFinishDate(state, data) {
        state.privateEvent.finishDate = data;
        const startDate = moment(state.privateEvent.startDate);
        const finishDate = moment(state.privateEvent.finishDate);
        if (finishDate.diff(startDate, 'days') < 0) {
          state.privateEvent.startDate = data;
        }
      },
      setStartTime(state, {value, step}) {
        state.privateEvent.startTime = value;
        const startTime = moment(state.privateEvent.startTime, 'HH:mm');
        const finishTime = moment(state.privateEvent.finishTime, 'HH:mm');
        if (finishTime.diff(startTime, 'minutes') <= 0 && state.privateEvent.startDate == state.privateEvent.finishDate) {
          const finishTime = startTime.add(step, 'minutes').format('HH:mm');
          state.privateEvent.finishTime = finishTime === '00:00' ? '23:59' : finishTime;
        }
      },
      setFinishTime(state, {value, step}) {
        state.privateEvent.finishTime = value;
        const startTime = moment(state.privateEvent.startTime, 'HH:mm');
        const finishTime = moment(state.privateEvent.finishTime, 'HH:mm');
        if (finishTime.diff(startTime, 'minutes') <= 0 && state.privateEvent.startDate == state.privateEvent.finishDate) {
          state.privateEvent.startTime = finishTime.subtract(step, 'minutes').format('HH:mm');
        }
      },
      setPrivateEvent(state, data) { state.privateEvents.push(data); },
      setTitle(state, data) { state.privateEvent.title = data; },
      setEventType(state, data) { state.privateEvent.eventType = data; },
      setSessionType(state, data) { state.standByPrivateEvent.sessionType = data; },
      setTravelMethod(state, data) { state.standByPrivateEvent.travelMethod = data; },
      setDistance(state, data) { state.standByPrivateEvent.distance = data; },
      setAllDay(state, data) { state.privateEvent.allDay = data; },
      setInitPrivateEvent(state, customInitData) {
        state.privateEvent = {...initPrivateEvent, ...customInitData};
      },
      clearStore(state) {
        state.privateEvents = '';
        state.privateEvent = {...initPrivateEvent};
        state.standByPrivateEvent = {
          sessionType: '',
          travelMethod: '',
          distance: ''
        };
      }
    }
  };
};
