(<template>
  <div class="tt-avatar tt-avatar--enterprise"
       :style="compAvatar"></div>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import UserAvatar from '@/components/shared_components/UserAvatar';

  export default {
    extends: UserAvatar,
    computed: {
      ...mapGetters({
        enterpriseLogo: 'UserInfoStore/enterpriseLogo'
      }),
      ownAvatar() {
        return this.enterpriseLogo || '';
      }
    }
  };
</script>

<style scoped>
  .tt-avatar {
    display: inline-block;
    border-radius: 50%;
    background-image: var(--image-user-icon);
    background-position: 50% 50%;
    background-clip: content-box;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }

  .tt-avatar--enterprise {
    background-image: var(--image-enterprise-icon);
  }
</style>
