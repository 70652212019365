<template>
  <div class="edit-modal">
    <booking-form :form-progress="bookingFormProgress"
                  :sections-list="sectionsList"
                  :validate-func="validateFunc"
                  :submit-button-text="submitButtonText"
                  :send-info-func="data.sendInfoFunc"
                  :success-handling-func="data.successHandlingFunc"
                  :error-handling-func="errorHandlingFunc">
      <button slot="closeButton"
              class="sk-btn sk-btn--white post-form__post-btn"
              @click="closeModal">{{ $gettext('Back') }}</button>
    </booking-form>
  </div>
</template>

<script>
  import PostProjectPrototype from '@/prototypes/PostProjectPrototype';

  export default {
    components: {
      'booking-form': () => import('@/components/booking_components/BookingForm')
    },
    extends: PostProjectPrototype,
    props: {
      data: {
        type: Object,
        default() {
          return {
            sectionName: '',
            validateFunc: '',
            submitButtonText: '',
            successHandlingFunc: () => {},
            sendInfoFunc: () => {},
            subtasksTypes: []
          };
        }
      }
    },
    computed: {
      bookingFormProgress() {
        return false;
      },
      sectionName() {
        return this.data.sectionName;
      },
      submitButtonText() {
        return this.data.submitButtonText;
      },
      sectionsList() {
        return [
          this[this.sectionName]
        ];
      },
      projectSubtaskType() {
        if (this.data.subtasksTypes.includes('correction')) return 'correction';
        if (this.data.subtasksTypes.includes('in_person')) return 'in_person';
      },
      validateFuncName() {
        return this.data.validateFuncName;
      },
      validateFunc() {
        return this[this.validateFuncName];
      },
      mainProjectInformationSection() {
        return {
          title: this.$gettext('Assignment information'),
          lines: [{
            components: [{
              name: 'multiple-language-selection',
              params: {
                showFromLang: true,
                assignmentType: this.projectSubtaskType
              }
            }]
          }, {
            components: [{
              name: 'subject-section',
              params: {
                onSeparateLines: true
              },
              condition: false
            }]
          }, {
            components: [{
              name: 'deadline-section',
              params: {
                isDateOptional: true,
                showSupportHint: true,
                showReset: true,
                miniDeadlineDays: 2,
                hideTime: true,
                tooltipText: this.$gettext('We will contact you as soon as possible, and will then confirm if we are able to deliver within your required deadline.'),
                assignmentType: this.projectSubtaskType
              }
            },
            ]
          }, {
            components: [{
              name: 'project-attachments-section',
              condition: this.projectSubtaskType != 'in_person'
            }]
          }, {
            components: [{
              name: 'project-extra-info-section',
              params: {
                extraInfoText: this.$gettext('Is there anything we should know about your document?')
              }
            }]
          }, {components: [{
            name: 'participants-section',
            params: {
              hidePhoneParticipants: true
            }
          }]}, {
            components: [{
              name: 'access-level-section'
            }]
          }]
        };
      },
      projectAttachmentsSection() {
        return {
          title: this.$gettext('Original documents'),
          lines: [{
            components: [{
              name: 'project-attachments-section'
            }]
          }]
        };
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      errorHandlingFunc(error) {
        this.$store.commit('ModalStore/stopModalProgress');
        this.$store.commit('ModalStore/setModal', {
          component: 'assignment-info-edit-error-modal',
          classes: ['sk-modal--new'],
          data: {
            title: this.$pgettext('translation', 'Validation error'),
            errors: error
          }
        });
      }
    },
    beforeDestroy() {
      this.$store.dispatch('PostTranslationProjectStore/clearEditProjectInformationStore');
    }
  };
</script>

<style>
.edit-modal .post-section {
  width: 100%;
  margin: 0;
}

.edit-modal .post-section__container {
  padding: 0 30px 10px;
  box-shadow: none;
}

.edit-modal .post-form__post-btn {
  width: auto;
  padding: 0 20px;
}
</style>

<style scoped>
.edit-modal {
  padding-bottom: 15px;
}
</style>
