export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      clients: [],
      currentClient: {
        id: '',
        firstName: '',
        lastName: '',
        orgName: '',
        orgNumber: '',
        email: '',
        phoneCode: '+47',
        phoneNumber: ''
      },
      errors: {
        firstName: {errors: [], name: 'firstNameError'},
        lastName: {errors: [], name: 'lastNameError'},
        orgName: {errors: [], name: 'orgNameError'},
        orgNumber: {errors: [], name: 'orgNumberError'},
        email: {errors: [], name: 'emailError'},
        phoneCode: {errors: [], name: 'phoneCodeError'},
        phoneNumber: {errors: [], name: 'phoneNumberError'}
      }
    },
    getters: {
      errorsState(state) {
        return Object.keys(state.errors).some((key) => state.errors[key].errors.length);
      }
    },
    actions: {
      createForm({state}) {
        const form = new FormData();
        const client = state.currentClient;

        if (client.id) {
          form.append('client[id]', client.id);
        }
        form.append('client[first_name]', client.firstName);
        form.append('client[last_name]', client.lastName);
        form.append('client[org_name]', client.orgName);
        form.append('client[org_number]', client.orgNumber);
        form.append('client[email]', client.email);
        form.append('client[phone_code]', client.phoneCode);
        form.append('client[phone_number]', client.phoneNumber);

        return Promise.resolve(form);
      },
      fetchInterClients({commit}, uid) {
        return new Promise((resolve, reject) => {
          fetchData.fetchInterClients(uid).then((data) => {
            commit('setClientsList', data);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      addClient({commit}, {uid, form}) {
        return new Promise((resolve, reject) => {
          fetchData.addClient(uid, form).then((data) => {
            commit('setNewClient', data);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      editClient({commit}, {uid, form, clientId, clientIndex}) {
        return new Promise((resolve, reject) => {
          fetchData.editClient(uid, form, clientId).then((data) => {
            commit('updateClient', {data, clientIndex});
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      deleteClient({state}, {uid, clientId, clientIndex}) {
        return new Promise((resolve, reject) => {
          fetchData.deleteClient(uid, clientId).then(() => {
            state.clients.splice(clientIndex, 1);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      }
    },
    mutations: {
      setError(state, {fieldName, errorText}) {
        state.errors[fieldName].errors = [...state.errors[fieldName].errors, errorText];
      },
      removeOneError(state, fieldName) {
        state.errors[fieldName].errors = [];
      },
      removeErrors(state) {
        for (const key of Object.keys(state.errors)) {
          state.errors[key].errors = [];
        }
      },
      setFirstName(state, value) { state.currentClient.firstName = value; },
      setLastName(state, value) { state.currentClient.lastName = value; },
      setOrgName(state, value) { state.currentClient.orgName = value; },
      setOrgNumber(state, value) { state.currentClient.orgNumber = value; },
      setEmail(state, value) { state.currentClient.email = value; },
      setPhoneCode(state, value) { state.currentClient.phoneCode = value; },
      setPhoneNumber(state, value) { state.currentClient.phoneNumber = value; },
      setClientsList(state, data) { state.clients = data.clients; },
      setNewClient(state, data) {
        if (data && data.client) {
          state.clients.push(data.client);
        }
      },
      updateClient(state, {data, clientIndex}) {
        if (data && data.client) {
          state.clients.splice(clientIndex, 1, data.client);
        }
      },
      setCurrentClient(state, data) { state.currentClient = data; },
      removeCurrentClient(state) {
        state.currentClient = {
          id: '',
          firstName: '',
          lastName: '',
          orgName: '',
          orgNumber: '',
          email: '',
          phoneCode: '+47',
          phoneNumber: ''
        };
      },
      resetStore(state) {
        state.clients = [];
        state.currentClient = {
          id: '',
          firstName: '',
          lastName: '',
          orgName: '',
          orgNumber: '',
          email: '',
          phoneCode: '+47',
          phoneNumber: ''
        };
      }
    }
  };
};
