(<template>
  <div class="step-3">
    <p v-if="!userHasCompany"
       class="step-3__text step-3__text--bold">{{ descriptionText }}</p>
    <ul class="step-3__list">
      <li v-for="item in contentItems"
          :key="item">{{ item }}</li>
    </ul>
    <org-number-field v-if="userHasCompany"
                      :preselected-value="organizationNumber"
                      :description="organizationNumberText"
                      :placeholder="organizationNumberText"
                      :validation-name="organizationNumberErrorName"
                      :country="'Norway'"
                      :reg-exp="/[^0-9]/g"
                      :max-length="9"
                      class="step-3__input"
                      autofocus="true"
                      @fieldvaluechanged="setOrganizationNumber" />
    <p class="step-3__text step-3__text--warning"
       v-html="warningText"></p>
    <a v-if="userHasCompany"
       href="https://payoutpartner.com"
       target="_blank"
       class="sk-link step-3__text">{{ $gettext('Read more about how you can automate all of this with PayoutPartner') }}</a>
    <div class="step-3__btn-wrapper">
      <button type="button"
              class="sk-btn sk-btn--white step-3__btn"
              @click="$emit('gotoback')">{{ $gettext('Go back') }}</button>
      <button class="sk-btn sk-btn--default step-3__btn"
              @click="submitForm">{{ $gettext('Save') }}</button>
    </div>
  </div>
</template>)

<script>
  import {mapMutations, mapState} from 'vuex';
  import OrgNumberField from '@/components/enterprise/form_parts/OrgNumberField';
  import PayoutValidation from '@/prototypes/PayoutValidation';

  export default {
    components: {
      'org-number-field': OrgNumberField
    },
    extends: PayoutValidation,
    computed: {
      ...mapState('TTPayoutStore', {
        userHasCompany: ({payoutInfoWizard}) => payoutInfoWizard.userHasCompany,
        organizationNumber: ({newAccount}) => newAccount.organizationNumber,
        // validation names
        organizationNumberErrorName: ({errors}) => errors.organizationNumber.name
      }),
      contentItems() {
        return [
          this.$gettext('Send invoices once per month before the 5th of the month for assignments in the previous month.'),
          this.$gettext('Include the needed information (job-id, date, amount, duration etc.) on every invoice.​'),
          this.$gettext('Will be paid on the 20th of every month.')
        ];
      },
      warningText() {
        return this.$gettext('All incomplete invoices will be rejected. Please see <a href="https://support.salita.no/article/368-retningslinjer-for-fakturering/" target="_blank">invoice guidelines</a> for further information​');
      },
      descriptionText() {
        return this.$gettext('Please remember that by doing this you will be required to:');
      },
      organizationNumberText() { return this.$gettext('Organisation number'); }
    },
    methods: {
      ...mapMutations('TTPayoutStore', ['setOrganizationNumber']),
      validateForm() {
        this.removeErrors();
        return this.userHasCompany ? this.validateOrganizationNumber() : true;
      },
      submitForm() {
        if (this.validateForm()) {
          this.$emit('gotonext');
        }
      }
    }
  };
</script>

<style>
  .step-3 a {
    text-decoration: underline;
  }
</style>

<style scoped>
  .step-3__text {
    margin-bottom: 20px;
  }

  .step-3__text--warning {
    color: #ff9a23;
  }

  .step-3__text--bold {
    font-weight: bold;
  }

  .step-3__list {
    margin-bottom: 20px;
    list-style-position: inside;
  }

  .step-3__btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 20px -10px 0;
  }

  .step-3__input {
    width: 50%;
  }

  .step-3__btn {
    max-width: 170px;
    margin: 10px 10px 0;
    padding: 0 20px;
  }

  @media (max-width: 500px) {
    .step-3__btn-wrapper {
      flex-wrap: wrap;
    }

    .step-3__btn {
      max-width: 100%;
    }
  }
</style>
