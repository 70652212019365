export default ({$fetcher: fetchData, $moment: moment}) => {
  return {
    namespaced: true,
    state: {
      lastUpdatedData: '',
      departmentInvoiceSettings: '',
      employeeInvoiceSettings: '',
      didTravelToAddress: '',
      // data for cancelled in person assignments
      feedbackCancelledAssignment: {
        travelTime: null,
        travelDistance: null,
        interpreterTravelled: null,
        cancelledAssignmentTravelType: null
      },
      feedback: {
        startTime: '',
        finishTime: '',
        isProblem: false,
        problems: [],
        progressActive: false,
        serviceRateId: '',
        timeStep: 5,
        otherFeedbackCheckbox: false,
        overtimeConfirmationCheckbox: false,
        otherFeedbackText: '',
        communicationRateId: '',
        professionalRateId: '',
        secretaryClientNotified: true,
        secretaryClientNeedMoreInfo: false,
        secretaryCalledBack: false,
        delayCausedBy: '',
        ownerRealName: '',
        publicFeedback: {
          id: '',
          comment: '',
          allowedByDemander: false,
          allowedBySupplier: false,
        },
        // errors
        durationValidationErrors: [],
        errors: {
          overtimeConfirmationCheckbox: {name: 'overtimeConfirmationCheckboxErrorName', errors: []},
          delayCausedBy: {name: 'delayCausedByErrorName', errors: []},
          ownerRealName: {name: 'ownerRealNameErrorName', errors: []},
          problems: {name: 'problemsErrorName', errors: []},
          communication: {name: 'communicationErrorName', errors: []},
          professionalism: {name: 'professionalismErrorName', errors: []},
          publicFeedbackComment: {name: 'publicCommentErrorName', errors: []},
        },
        jobCompleted: '',
        jobCompletedByColleague: false,
        showedUp: '',
        ticketComment: '',
        agreeWithReportedDate: null,
        anyOtherProblem: null,
        problemDescription: '',
        feedbackFinished: false,
      }
    },
    getters: {
      bookerDefaultBookingReference(state, getters, rootState, rootGetters) {
        if (state.employeeInvoiceSettings?.bookingReference) {
          return state.employeeInvoiceSettings?.bookingReference || '';
        } else if (state.departmentInvoiceSettings?.bookingReference) {
          return state.departmentInvoiceSettings?.bookingReference || '';
        } else {
          return rootGetters['UserInfoStore/enterpriseDefaultBookingReference'];
        }
      },
      bookerDefaultPaymentBookingReference(state, getters, rootState, rootGetters) {
        if (state.employeeInvoiceSettings?.paymentBookingReference) {
          return state.employeeInvoiceSettings?.paymentBookingReference || '';
        } else if (state.departmentInvoiceSettings?.paymentBookingReference) {
          return state.departmentInvoiceSettings?.paymentBookingReference || '';
        } else {
          return rootGetters['UserInfoStore/enterpriseDefaultPaymentBookingReference'];
        }
      },
      // Feedback Statuses
      isChoosenTravelTypeForCancelledAssignment(state) {
        return state.feedbackCancelledAssignment.cancelledAssignmentTravelType;
      },
      isTravelling(state) {
        return state.feedbackCancelledAssignment.interpreterTravelled === 'right';
      },
      isNotTravelling(state) {
        return state.feedbackCancelledAssignment.interpreterTravelled === 'wrong';
      },
      isJobCompleted(state) {
        return state.feedback.jobCompleted === 'right';
      },
      isJobNotCompleted(state) {
        return state.feedback.jobCompleted === 'wrong';
      },
      interpreterNotNeeded(state) {
        return state.feedback.jobCompleted === 'interpreter_not_needed';
      },
      showedUp(state) {
        return state.feedback.showedUp === true || false;
      },
      notShowedUp(state) {
        return state.feedback.showedUp === false || false;
      },
      jobCompletedByColleague(state) {
        return state.feedback.jobCompletedByColleague || false;
      },
      ticketComment(state) {
        return state.feedback.ticketComment || '';
      },
      agreeWithDate(state) {
        return !!state.feedback.agreeWithReportedDate;
      },
      disAgreeWithDate(state) {
        return !state.feedback.agreeWithReportedDate;
      },
      noProblem(state) {
        return !state.feedback.anyOtherProblem;
      },
      problemExists(state) {
        return !!state.feedback.anyOtherProblem;
      },
      otherSideFeedback(state, getters, rootState, rootGetters) {
        const currentActor = rootGetters['UserInfoStore/userIsInterpreter'] ? 'demander' : 'supplier';
        const currentDiscussion = rootGetters['OneAssignmentStore/currentDiscussion'];
        return currentDiscussion?.feedbacks?.find(({author}) => author === currentActor);
      },
      otherSideStartTime(state, getters) {
        return getters.otherSideFeedback?.actualStartTime || '';
      },
      otherSideFinishTime(state, getters) {
        return getters.otherSideFeedback?.actualFinishTime || '';
      },
      otherSideAgreedWithReportedDate(state, getters, rootState) {
        const {interpretationRequirement, jobDemanderFeedback} = rootState.OneAssignmentStore.storeJobObj || {};
        const supplierFeedback = rootState.OneAssignmentStore.storeJobObj ? rootState.OneAssignmentStore.storeJobObj?.jobSupplierFeedback : {};
        const format = 'YYYY-MM-DD HH:mm';
        if (supplierFeedback?.reportedStartTime && supplierFeedback?.reportedFinishTime) {
          const agreedStartTime = moment(supplierFeedback.reportedStartTime).format(format) === moment(interpretationRequirement.startTime).format(format);
          const agreedFinishTime = moment(supplierFeedback.reportedFinishTime).format(format) === moment(interpretationRequirement.finishTime).format(format);
          return agreedStartTime && agreedFinishTime;
        }
        if (!jobDemanderFeedback.reportedStartTime || !jobDemanderFeedback.reportedFinishTime) return true;
      },
      feedbackFinished(state) {
        return state.feedback.feedbackFinished || false;
      },
      didTravelToAddress(state) {
        return state.didTravelToAddress === 'yes' || state.didTravelToAddress === 'right';
      },
      didNotTravelToAddress(state) {
        return state.didTravelToAddress === 'no' || state.didTravelToAddress === 'wrong';
      },
      cancelledAssignmentTravelByDriving(state) {
        return state.feedbackCancelledAssignment.cancelledAssignmentTravelType === 'car';
      },
      cancelledAssignmentTravelByPublicTransport(state) {
        return state.feedbackCancelledAssignment.cancelledAssignmentTravelType === 'public_transport';
      },
      assignmentChangedToPhone(state) {
        return state.didTravelToAddress === 'assignment_changed_to_phone' || state.didTravelToAddress === 'changed_session_type';
      },
      didNotShowUOnTime(state) {
        return state.didTravelToAddress === 'i_did_not_show_up' || state.feedback.showedUp === false;
      }
    },
    actions: {
      sendFeedback({commit}, {id, feedback}) {
        return new Promise((resolve, reject) => {
          fetchData.sendFeedback(id, feedback).then((data) => {
            commit('OneAssignmentStore/setJobStatus', 'finished', {root: true});
            commit('OneAssignmentStore/setJobFeedback', data.data, {root: true});
            commit('OneAssignmentStore/setJobPublicFeedback', data.data?.publicFeedback, {root: true});
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendFeedbackForCancelledAssignment({commit, state}, discussionId) {
        const feedback = {
          interpreterTravelled: state.feedbackCancelledAssignment.interpreterTravelled,
          travelType: state.feedbackCancelledAssignment.cancelledAssignmentTravelType,
          travelTime: state.feedbackCancelledAssignment.travelTime,
          travelDistance: state.feedbackCancelledAssignment.travelDistance,
        };

        return new Promise((resolve, reject) => {
          fetchData.sendFeedbackForCancelledAssignment(discussionId, feedback).then((data) => {
            commit('setFeedbackForCancelledAssignment', data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      updateFeedback({commit}, feedbackForm) {
        return new Promise((resolve, reject) => {
          fetchData.updateFeedback(feedbackForm).then((data) => {
            commit('OneAssignmentStore/setJobFeedback', data.data, {root: true});
            commit('OneAssignmentStore/setJobPublicFeedback', data.data?.publicFeedback, {root: true});
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getLastUpdatedData({commit}, {jobId}) {
        return new Promise((resolve, reject) => {
          fetchData.getLastUpdatedData(jobId).then((data = {}) => {
            commit('setLastUpdatedData', data.last_updated);
            resolve(data.last_updated);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      getProjectLastUpdatedData({commit}, {orderId}) {
        return new Promise((resolve, reject) => {
          fetchData.getProjectLastUpdatedData(orderId).then((data = {}) => {
            commit('setLastUpdatedData', data.last_updated);
            resolve(data.last_updated);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      getInvoiceSettingsForParticularBooker({dispatch, state}, {bookerUid, departmentId}) {
        return new Promise((resolve, reject) => {
          const promisesArr = [];

          if (state.employeeInvoiceSettings?.bookingReference || state.employeeInvoiceSettings?.paymentBookingReference) {
            promisesArr.push(dispatch('getEmployeeInvoiceSettings', bookerUid));
          }

          if (state.departmentInvoiceSettings?.bookingReference || state.departmentInvoiceSettings?.paymentBookingReference) {
            promisesArr.push(dispatch('getDepartmentInvoiceSettings', departmentId));
          }

          Promise.all(promisesArr)
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      },
      getEmployeeInvoiceSettings({rootGetters, commit}, bookerUid) {
        return new Promise((resolve, reject) => {
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'];
          fetchData.getFilteredInvoicingEmployeesList(enterpriseId, bookerUid)
            .then((data = {}) => {
              commit('setEmployeeInvoiceSettings', data.employee);
              resolve(data.employee);
            })
            .catch((data) => {
              reject(data);
            });
        });
      },
      getDepartmentInvoiceSettings({rootGetters, commit}, departmentId) {
        return new Promise((resolve, reject) => {
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'];
          fetchData.getFilteredInvoicingDepartmentsList(enterpriseId, departmentId)
            .then((data = {}) => {
              commit('setDepartmentInvoiceSettings', data);
              resolve(data);
            })
            .catch((data) => {
              reject(data);
            });
        });
      },
      sendFeedbackV3({commit, dispatch, rootGetters}, {discussionId, feedback}) {
        return new Promise((resolve, reject) => {
          fetchData.sendFeedbackV3(discussionId, {feedback}).then((data) => {
            commit('OneAssignmentStore/setJobFeedback', data.data, {root: true});
            const id = rootGetters['OneAssignmentStore/jobId'];
            dispatch('OneAssignmentStore/getJobById', id, {root: true});
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      updateFeedbackV3({commit, dispatch, rootGetters}, {discussionId, feedbackId, feedback}) {
        return new Promise((resolve, reject) => {
          fetchData.updateFeedbackV3(discussionId, feedbackId, {feedback}).then((data) => {
            commit('OneAssignmentStore/setJobFeedback', data.data, {root: true});
            const id = rootGetters['OneAssignmentStore/jobId'];
            dispatch('OneAssignmentStore/getJobById', id, {root: true});
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      setFeedbackError({state, commit}, {fieldName, errorText}) {
        commit('setFeedbackError', {fieldName, errorText});
        commit('ErrorsStore/setError', {
          name: state.feedback.errors[fieldName].name,
          errors: state.feedback.errors[fieldName].errors
        }, {
          root: true
        });
      },
      removeOneFeedbackError({state, commit}, fieldName) {
        commit('removeOneFeedbackError', fieldName);
        commit('ErrorsStore/setError', {
          name: state.feedback.errors[fieldName].name,
          errors: []
        }, {
          root: true
        });
      },
      removeFeedbackErrors({commit}) {
        commit('removeFeedbackErrors');
      },
      setShowedUp({commit}, value) {
        commit('resetShowedUpFlags');
        commit('setShowedUp', value);
      },
      setJobCompletedByColleague({commit}, value) {
        commit('resetShowedUpFlags');
        commit('setJobCompletedByColleague', value);
      },
    },
    mutations: {
      setFeedbackError(state, {fieldName, errorText}) {
        state.feedback.errors[fieldName].errors = [
          ...state.feedback.errors[fieldName].errors,
          errorText
        ];
      },
      removeOneFeedbackError(state, fieldName) {
        state.feedback.errors[fieldName].errors = [];
      },
      removeFeedbackErrors(state) {
        for (const key of Object.keys(state.feedback.errors)) {
          state.feedback.errors[key].errors = [];
        }
      },
      setTravelDuration(state, value) {
        const calculatedValue = value != '' ? value : null;
        state.feedbackCancelledAssignment.travelTime = calculatedValue;
      },
      setTravelDistance(state, value) {
        const calculatedValue = value != '' ? value : null;
        state.feedbackCancelledAssignment.travelDistance = calculatedValue;
      },
      setDidTravelToAddress(state, value) {
        state.didTravelToAddress = value;
        localStorage.didTravelToAddress = value;
      },
      setCancelledAssignmentTravelType(state, value) {
        state.feedbackCancelledAssignment.cancelledAssignmentTravelType = value;
      },
      setLastUpdatedData(state, data) {
        state.lastUpdatedData = data;
      },
      setEmployeeInvoiceSettings(state, data) {
        state.employeeInvoiceSettings = data;
      },
      setDepartmentInvoiceSettings(state, data) {
        state.departmentInvoiceSettings = data;
      },
      setFeedbackInitialTime(state, {startTime, finishTime}) {
        state.feedback.startTime = startTime;
        state.feedback.finishTime = finishTime;
      },
      setFeedbackStartTime(state, value) {
        const valueArr = value.split(':');
        const [hours, minutes] = valueArr;
        state.feedback.startTime = moment(state.feedback.startTime).hours(+hours).minutes(+minutes);
        if (state.feedback.finishTime.diff(state.feedback.startTime, 'minutes') <= 0) {
          state.feedback.finishTime = moment(state.feedback.startTime).add(state.feedback.timeStep, 'minutes');
        }
      },
      setFeedbackForCancelledAssignment(state, value) {
        state.travelTime = value.travelTime;
        state.travelDistance = value.travelDistance;
        state.interpreterTravelled = value.interpreterTravelled;
        state.cancelledAssignmentTravelType = value.travelType;
      },
      setFeedbackFinishTime(state, value) {
        const valueArr = value.split(':');
        const [hours, minutes] = valueArr;
        state.feedback.finishTime = moment(state.feedback.finishTime).hours(+hours).minutes(+minutes);
      },
      setFeedbackFinishDate(state, value) {
        state.feedback.finishTime = value ? moment(value) : '';
      },
      setFeedbackDelayCausedBy(state, value) {
        state.feedback.delayCausedBy = value;
      },
      setFeedbackOvertimeConfirmationCheckbox(state, value) {
        state.feedback.overtimeConfirmationCheckbox = value;
      },
      setSecretaryClientNotified(state, value) {
        state.feedback.secretaryClientNotified = value;
      },
      setSecretaryClientNeedMoreInfo(state, value) {
        state.feedback.secretaryClientNeedMoreInfo = value;
      },
      setSecretaryCalledBack(state, value) {
        state.feedback.secretaryCalledBack = value;
      },
      setFeedbackIsProblem(state, value) {
        state.feedback.isProblem = value;
      },
      setFeedbackProblems(state, value) {
        state.feedback.problems = value;
      },
      setFeedbackOwnerRealName(state, value) {
        state.feedback.ownerRealName = value;
      },
      setFeedbackCommunicationRateId(state, value) {
        state.feedback.communicationRateId = value;
      },
      setFeedbackProfessionalRateId(state, value) {
        state.feedback.professionalRateId = value;
      },
      setFeedbackServiceRateId(state, value) {
        state.feedback.serviceRateId = value;
      },
      setOtherFeedbackText(state, value) {
        state.feedback.otherFeedbackText = value;
      },
      setOtherFeedbackCheckbox(state, value) {
        state.feedback.otherFeedbackCheckbox = value;
      },
      setPublicFeedbackId(state, value) {
        state.feedback.publicFeedback.id = value;
      },
      setPublicFeedbackComment(state, value) {
        state.feedback.publicFeedback.comment = value;
      },
      setPublicFeedbackAllowedByDemander(state, value) {
        state.feedback.publicFeedback.allowedByDemander = value;
      },
      setPublicFeedbackAllowedBySupplier(state, value) {
        state.feedback.publicFeedback.allowedBySupplier = value;
      },
      setTravelling(state, value) {
        state.feedbackCancelledAssignment.interpreterTravelled = value;
      },
      setNoTravelling(state) {
        state.feedbackCancelledAssignment.travelTime = null;
        state.feedbackCancelledAssignment.travelDistance = null;
        state.feedbackCancelledAssignment.cancelledAssignmentTravelType = null;
      },
      setJobCompleted(state, value) {
        state.feedback.jobCompleted = value;
        localStorage.jobCompleted = value;
      },
      setJobCompletedByColleague(state, value) {
        state.feedback.jobCompletedByColleague = value;
        localStorage.jobCompletedByColleague = value;
      },
      setShowedUp(state, value) {
        state.feedback.showedUp = value;
        localStorage.showedUp = value;
      },
      setTicketComment(state, value) {
        state.feedback.ticketComment = value;
        localStorage.ticketComment = value;
      },
      setAgreeWithReportedDate(state, value) {
        state.feedback.agreeWithReportedDate = value;
        localStorage.agreeWithReportedDate = value;
      },
      setAnyOtherProblem(state, value) {
        state.feedback.anyOtherProblem = value;
        localStorage.anyOtherProblem = value;
      },
      setProblemDescription(state, value) {
        state.feedback.problemDescription = value;
      },
      setFeedbackFinished(state, value) {
        state.feedback.feedbackFinished = value;
      },
      resetShowedUpFlags(state) {
        state.feedback.jobCompletedByColleague = false;
        state.feedback.showedUp = '';
      },
      clearStore(state) {
        state.lastUpdatedData = '';
        state.employeeInvoiceSettings = '';
        state.departmentInvoiceSettings = '';
        state.didTravelToAddress = '';
        state.feedbackCancelledAssignment = {
          travelTime: null,
          travelDistance: null,
          interpreterTravelled: null,
          cancelledAssignmentTravelType: null
        };
        state.feedback = {
          startTime: '',
          finishTime: '',
          isProblem: false,
          problems: [],
          progressActive: false,
          serviceRateId: '',
          timeStep: 5,
          otherFeedbackCheckbox: false,
          overtimeConfirmationCheckbox: false,
          otherFeedbackText: '',
          communicationRateId: '',
          professionalRateId: '',
          secretaryClientNotified: true,
          secretaryClientNeedMoreInfo: false,
          secretaryCalledBack: false,
          delayCausedBy: '',
          ownerRealName: '',
          publicFeedback: {
            id: '',
            comment: '',
            allowedByDemander: false,
            allowedBySupplier: false,
          },
          // errors
          durationValidationErrors: [],
          errors: {
            overtimeConfirmationCheckbox: {name: 'overtimeConfirmationCheckboxErrorName', errors: []},
            delayCausedBy: {name: 'delayCausedByErrorName', errors: []},
            ownerRealName: {name: 'ownerRealNameErrorName', errors: []},
            problems: {name: 'problemsErrorName', errors: []},
            communication: {name: 'communicationErrorName', errors: []},
            professionalism: {name: 'professionalismErrorName', errors: []},
            publicFeedbackComment: {name: 'publicCommentErrorName', errors: []},
          },
          jobCompleted: '',
          jobCompletedByColleague: false,
          showedUp: '',
          ticketComment: '',
          agreeWithReportedDate: null,
          anyOtherProblem: null,
          problemDescription: '',
          feedbackFinished: false,
        };
      }
    }
  };
};
