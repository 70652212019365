// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/salita/undefined_imgs/warning_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.full-address[data-v-7986fb72] {\n  width: 100%;\n}\n.full-address__location-radio[data-v-7986fb72] {\n  margin: 0 10px 12px 10px;\n}\n.full-address__attention[data-v-7986fb72] {\n  margin-bottom: 10px;\n  padding-left: 20px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 14px;\n  background-repeat: no-repeat;\n  line-height: 16px;\n}\n.full-address__section-text[data-v-7986fb72] {\n  display: block;\n  width: 100%;\n  margin: 0 0 10px;\n}\n.full-address__inputs-cont[data-v-7986fb72] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.full-address .full-address__post-code-select[data-v-7986fb72] {\n  -ms-flex-negative: 0;\n      flex-shrink: 0;\n  width: 100px;\n  margin-right: 10px;\n}\n.full-address .full-address__county-select[data-v-7986fb72] {\n  -ms-flex-negative: 0;\n      flex-shrink: 0;\n  width: 200px;\n  margin-right: 10px;\n}\n.sk-select__description[data-v-7986fb72] {\n  margin-bottom: 2px;\n}\n@media (max-width: 767px) {\n.full-address__inputs-cont[data-v-7986fb72] {\n    -ms-flex-wrap: wrap;\n        flex-wrap: wrap;\n}\n}\n", ""]);
// Exports
module.exports = exports;
