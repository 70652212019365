export default {
  path: 'enterprise',
  name: 'BuyerCompany',
  component: () => import('@/pages/BuyerPages/enterprise/b_EnterpriseRoot'),
  children: [
    {
      path: 'company_info',
      name: 'BuyerCompanyInfo',
      meta: {
        enterpriseOnly: true,
        subPage: true
      },
      component: () => import('@/pages/BuyerPages/enterprise/b_CompanyInfo')
    },
    {
      path: 'company_info/edit',
      name: 'BuyerCompanyInfoEdit',
      meta: {
        enterpriseOnly: true,
        subPage: true
      },
      component: () => import('@/pages/BuyerPages/enterprise/b_CompanyInfoEdit')
    },
    {
      path: 'manage_employees',
      name: 'BuyerEmployees',
      meta: {
        enterpriseOnly: true,
        subPage: true
      },
      component: () => import('@/pages/BuyerPages/enterprise/b_Employees')
    },
    {
      path: 'manage_departments',
      name: 'BuyerDepartments',
      meta: {
        enterpriseOnly: true,
        subPage: true
      },
      component: () => import('@/pages/BuyerPages/enterprise/b_Departments')
    },
    {
      path: 'manage_addresses',
      name: 'BuyerAddresses',
      meta: {
        enterpriseOnly: true,
        subPage: true
      },
      component: () => import('@/pages/BuyerPages/enterprise/b_Addresses')
    },
    {
      path: 'payment_methods',
      name: 'BuyerPaymentMethods',
      meta: {
        enterpriseOnly: true,
        subPage: true
      },
      component: () => import('@/pages/BuyerPages/b_PaymentMethods')
    },
    {
      path: 'customer_invoices',
      name: 'BuyerInvoices',
      meta: {
        enterpriseOnly: true,
        subPage: true
      },
      component: () => import('@/pages/BuyerPages/enterprise/b_Invoices')
    },
    {
      path: 'rates',
      name: 'BuyerCompanyRates',
      meta: {
        enterpriseOnly: true,
        subPage: true
      },
      component: () => import('@/pages/shared_pages/sh_Rates')
    },
    {
      path: 'security',
      name: 'BuyerSecurity',
      meta: {
        enterpriseOnly: true,
        subPage: true
      },
      component: () => import('@/pages/BuyerPages/enterprise/b_Security')
    },
    {
      path: 'security/edit',
      name: 'BuyerSecurityEdit',
      meta: {
        enterpriseOnly: true,
        subPage: true
      },
      component: () => import('@/pages/BuyerPages/enterprise/b_SecurityEdit')
    },
  ]
};
