import BookingHelper from '@/store/booking_stores/BookingHelper';

export default ({$fetcher: fetchData} = {}) => {
  return {
    namespaced: true,
    state: {
      paymentMethod: '',
      allPaymentMethods: '',
      errors: {
        paymentMethod: {name: 'paymentMethodError', errors: []},
      }
    },
    getters: {
      currentPaymentMethods(state) {
        return Array.isArray(state.allPaymentMethods)
          ? state.allPaymentMethods.filter((payment) => !payment.archived) || []
          : [];
      },
      defaultPaymentMethodData(state, getters) {
        return getters.currentPaymentMethods.find((payment) => payment.default) || {};
      },
      currentPaymentMethodData(state, getters) {
        return getters.currentPaymentMethods.find((payment) => payment.id == state.paymentMethod) || {};
      }
    },
    actions: {
      getPayments({rootGetters, commit}) {
        return new Promise((resolve, reject) => {
          const userIsEnterpriseMember = rootGetters['UserInfoStore/userIsEnterpriseMember'];
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'];
          const uid = rootGetters['UserInfoStore/userUid'];
          const promise = userIsEnterpriseMember
            ? fetchData.getEnterprisePayments(enterpriseId)
            : fetchData.getBuyerPayments(uid);

          promise.then((data = {}) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setAllPaymentMethods', data.paymentMethods);
            resolve(data);
          }).catch((data) => {
            reject(data);
          });
        });
      },
      addNewPaymentMethod({commit, dispatch}, data) {
        commit('addNewPaymentMethod', data);
        dispatch('PostingNotifierStore/preselectPaymentMethodData', null, {root: true});
      },
      changePaymentMethod({commit}, paymentMethod) {
        commit('setPaymentMethod', paymentMethod);
        commit('removePaymentMethodError');
      },
    },
    mutations: {
      ...BookingHelper().mutations,
      removePaymentMethodError(state) {
        state.errors.paymentMethod.errors = [];
      },
      setPaymentMethod(state, value) {
        state.paymentMethod = value;
      },
      setAllPaymentMethods(state, data) {
        state.allPaymentMethods = data;
      },
      addNewPaymentMethod(state, data) {
        if (!state.allPaymentMethods) {
          state.allPaymentMethods = [];
        }
        state.allPaymentMethods.push(data);
      },
      clearStore(state) {
        state.paymentMethod = '';
        state.allPaymentMethods = '';
        state.errors = {
          paymentMethod: {name: 'paymentMethodError', errors: []}
        };
      }
    }
  };
};
