<template>
  <article class="note">
    <p class="note__message">{{ note.note }}</p>
    <div class="note__bottom-wrapper">
      <p class="note__author">{{ note.author.name }}</p>
      <button class="note__edit-btn"
              @click="onEdit">{{ $gettext('Edit') }}</button>
    </div>
  </article>
</template>

<script>
  export default {
    props: {
      note: {
        type: Object,
        required: true,
        default: () => {}
      }
    },
    methods: {
      onEdit() {
        this.$emit('editnote', this.note);
      }
    }
  };
</script>
<style scoped>
.note {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #f4f5f7;
  line-height: 20px;
}

.note__bottom-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  color: #555;
}

.note__edit-btn::before {
  content: '';
  position: absolute;
  right: 0;
  width: 15px;
  height: 15px;
  padding-right: 30px;
  background-image: url(~@assets/imgs/undefined_imgs/rounded_new_edit_pencil_icon_grey.svg);
  background-repeat: no-repeat;
}
</style>
