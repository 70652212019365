export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      calendarJobs: '',
      queryDate: '',
      queryView: ''
    },
    actions: {
      getCalendarJobs({commit}, {date, view}) {
        return new Promise((resolve, reject) => {
          commit('setQueries', {date, view});
          fetchData.getCalendarJobs(date, view).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setCalendarJobs', data);
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      }
    },
    mutations: {
      setCalendarJobs(state, data) {
        state.calendarJobs = data;
      },
      setQueries(state, {date, view}) {
        state.queryDate = date;
        state.queryView = view;
      },
      clearStore(state) {
        state.calendarJobs = '';
        state.queryDate = '';
        state.queryView = '';
      }
    }
  };
};
