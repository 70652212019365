// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/interpreters_icons/police_logo_ico.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/interpreters_icons/police_logo_temporary_ico.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".attested-by-police-icon::after,\n.attested-by-police-temporary-icon::after {\n  content: '';\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  display: block;\n  background-position: 50% 50%;\n  background-size: 100%;\n  background-repeat: no-repeat;\n}\n.attested-by-police-icon::after {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-image: var(--image-police-active-badge);\n}\n.attested-by-police-temporary-icon::after {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-image: var(--image-police-temporary-active-badge);\n}\n", ""]);
// Exports
module.exports = exports;
