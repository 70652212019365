<script>
  import {mapState, mapMutations, mapActions} from 'vuex';
  import InteractiveSearchField from '@/components/shared_components/form_fields/InteractiveSearchField';

  export default {
    extends: InteractiveSearchField,
    computed: {
      ...mapState('EnterpriseStore', {
        searchQuery: ({emailQuery}) => emailQuery
      })
    },
    methods: {
      ...mapActions({
        submitAction: 'EnterpriseStore/checkEmployeeEmail',
        cancelSearch: 'EnterpriseStore/cancelEmployeeEmailCheck'
      }),
      ...mapMutations({
        setSearchQueryMutation: 'EnterpriseStore/setEmailQuery'
      })
    }
  };
</script>
