(<template>
  <section :class="{'info-box--opened': infoOpened}"
           class="info-box info-accordeon">
    <label class="info-box__label">
      <span>{{ accordeonTitle }}</span>
      <button class="info-box__arow-btn"
              type="button"
              autofocus="true"
              @click="toogleInfoBlock"></button>
    </label>
    <template v-if="accordeonText">
      <article v-if="infoOpened"
               class="info-box__text"
               v-html="accordeonText"></article>
    </template>
    <template v-else>
      <article v-if="infoOpened"
               class="info-box__text">
        <slot></slot>
      </article>
    </template>
  </section>
</template>)

<script>
  export default {
    props: {
      accordeonTitle: {
        type: String,
        default: ''
      },
      accordeonText: {
        type: String,
        default: ''
      },
      initialOpenState: Boolean
    },
    data() {
      return {
        infoOpened: this.initialOpenState || false
      };
    },
    methods: {
      toogleInfoBlock() {
        this.infoOpened = !this.infoOpened;
      }
    }
  };
</script>

<style scoped>
  .info-accordeon {
    margin-bottom: 12px;
  }

  .info-box {
    display: block;
    width: 100%;
    min-height: 40px;
    border-radius: 3px;
    background-color: #fff5d1;
  }

  .info-box__label {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    font-weight: normal;
    font-size: 14px;
  }

  .info-box__label::before {
    content: 'i';
    display: block;
    flex-shrink: 0;
    width: 15px;
    height: 15px;
    margin-right: 10px;
    border: 1px solid #333;
    border-radius: 50%;
    color: #333;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
  }

  .info-box__arow-btn,
  .info-box__arow-btn:hover,
  .info-box__arow-btn:focus,
  .info-box__arow-btn:active {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 30px;
    height: 40px;
    border: 0;
    background-color: transparent;
    outline: none;
  }

  .info-box__arow-btn::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 8px;
    box-sizing: border-box;
    width: 7px;
    border-top: 1px solid #333;
    box-shadow: none;
    transform: rotate(43deg);
  }

  .info-box__arow-btn::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    box-sizing: border-box;
    width: 7px;
    border-top: 1px solid #333;
    box-shadow: none;
    transform: rotate(-43deg);
  }

  .info-box__arow-btn:focus::before {
    box-shadow: 0 0 2px 1px #3af;
  }

  .info-box__arow-btn:focus::after {
    box-shadow: 0 0 2px 1px #3af;
  }

  .info-box__arow-btn:active::before,
  .info-box__arow-btn:hover::before {
    box-shadow: none;
  }

  .info-box__arow-btn:active::after,
  .info-box__arow-btn:hover::after {
    box-shadow: none;
  }

  .info-box--opened .info-box__arow-btn::before,
  .info-box--opened .info-box__arow-btn:hover::before {
    transform: rotate(-43deg);
  }

  .info-box--opened .info-box__arow-btn::after,
  .info-box--opened .info-box__arow-btn:hover::after {
    transform: rotate(43deg);
  }

  .info-box__text {
    display: block;
    width: 100%;
    padding: 20px;
    padding-bottom: 30px;
    border-top: 2px solid #f93;
    font-weight: normal;
    font-size: 14px;
  }

  .info-box--sized .info-box__text,
  .info-box--sized .info-box__label {
    font-size: 12px;
  }
</style>
