export default () => {
  return {
    namespaced: true,
    state: {
      sex: '2',
      genderSelect: false
    },
    actions: {
      changeGender({commit, dispatch}, gender) {
        commit('setGender', gender);
        dispatch('PostingNotifierStore/genderChanged', gender, {root: true});
      },
      changeGenderSelectState({commit}, value) {
        commit('setGenderSelectState', value);
        commit('PostingStore/ProcessRequirementStore/setGenderProcessing', '', {root: true});
      }
    },
    mutations: {
      setGender(state, value) {
        state.sex = value;
      },
      setDefaultGender(state, value) {
        state.genderSelect = true;
        state.sex = value;
      },
      setGenderSelectState(state, value) {
        state.genderSelect = value;
        state.sex = '2';
      },
      clearStore(state) {
        state.sex = '2';
        state.genderSelect = false;
      }
    }
  };
};
