import BookingHelper from '@/store/booking_stores/BookingHelper';

export default () => {
  return {
    namespaced: true,
    state: {
      bookingReference: '',
      initialBookingReference: '',
      fromBookingReference: '',
      paymentBookingReference: '',
      initialPaymentBookingReference: '',
      fromPaymentBookingReference: '',
      errors: {
        bookingRef: {name: 'bookingReferenceError', errors: []},
        paymentBookingRef: {name: 'paymentBookingReferenceError', errors: []}
      }
    },
    mutations: {
      ...BookingHelper().mutations,
      setBookingReference(state, value) {
        state.bookingReference = value;
      },
      setFromBookingReference(state, value) {
        state.fromBookingReference = value;
      },
      setInitialBookingReference(state, value) {
        state.initialBookingReference = value;
      },
      setPaymentBookingReference(state, value) {
        state.paymentBookingReference = value;
      },
      setFromPaymentBookingReference(state, value) {
        state.fromPaymentBookingReference = value;
      },
      setInitialPaymentBookingReference(state, value) {
        state.initialPaymentBookingReference = value;
      },
      clearStore(state) {
        state.bookingReference = '';
        state.initialBookingReference = '';
        state.paymentBookingReference = '';
        state.initialPaymentBookingReference = '';
      }
    }
  };
};
